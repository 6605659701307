import { Badge as BadgeAntd, BadgeProps as BadgePropsAntd } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'

import './styles.less'

type BadgeProps = BadgePropsAntd & {
    isMenu?: boolean
}
export const Badge: FC<BadgeProps> = ({ isMenu, ...props }) => {
    const className = clsx('dfa-badge', { 'dfa-badge--menu': isMenu })
    return <BadgeAntd {...props} className={className} />
}
