import { CertificateType } from '@dltru/dfa-models'
import React, { FC } from 'react'

import { Alert } from '../../../Alert'
import { Box } from '../../../Box'
import { PlusOutline } from '../../../Icons'
import { ButtonProps } from '../../../button'
import { CardField } from '../../../card'
import { CertificatesListItem } from './CertificatesListItem'

interface IUploadCertificateProps extends Partial<ButtonProps> {
    onAddCertificate: () => void
    onError: () => void
    userUuid?: string
}
interface ICertificatesListComponentProps extends IUploadCertificateProps {
    isLoadingCertificate: boolean
    certificates: CertificateType[] | null
    addCertificateHandler: () => void
    addCertificateErrorHandler: () => void
    removeCertificateAction: (uid: string) => void
    UploadCertificate: React.FC<IUploadCertificateProps>
    alertDescription?: string
    userUuid?: string
}
export const CertificatesListComponent: FC<ICertificatesListComponentProps> = ({
    isLoadingCertificate,
    certificates,
    addCertificateHandler,
    addCertificateErrorHandler,
    UploadCertificate,
    removeCertificateAction,
    alertDescription,
    userUuid,
}) => {
    if (isLoadingCertificate && !certificates?.length) {
        return null
    }
    if (!certificates?.length) {
        return (
            <Alert
                description={alertDescription || 'У вас еще нет ни одного сертификата подписи'}
                type="warning"
                width={500}
                action={
                    <UploadCertificate
                        onAddCertificate={addCertificateHandler}
                        onError={addCertificateErrorHandler}
                        type="link"
                        size="small"
                        userUuid={userUuid}
                    />
                }
            />
        )
    }
    const valueComponent = (
        <>
            {certificates.map((item) => (
                <CertificatesListItem
                    certificate={item}
                    key={item.uuid}
                    removeCertificateAction={removeCertificateAction}
                />
            ))}
            <Box margin={[16, 0, 0, 0]}></Box>
            <UploadCertificate
                onAddCertificate={addCertificateHandler}
                onError={addCertificateErrorHandler}
                icon={<PlusOutline />}
                userUuid={userUuid}
            />
        </>
    )
    return <CardField title="Используемые УНЭП" value={valueComponent} />
}
