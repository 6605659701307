import { LoadingOutlined } from '@ant-design/icons'
import { Spin, message as antMessage, notification as antNotification } from 'antd'

import { CloseIcon } from '../Icons'
import { NotificationIcon } from './NotificationIcon'
import './style.less'
import { IconType } from './types'

export interface IOpenNotification {
    type: IconType
    message?: string
    description?: React.ReactNode
    key?: string
}
export interface IOpenMessage {
    type: IconType
    message?: string
    key?: string
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const openNotification = ({ type, message, description, key }: IOpenNotification) => {
    if (type === 'loading') {
        antNotification.open({
            message: message,
            description: `${description}`,
            placement: 'topRight',
            duration: 0,
            key: key,
            className: 'dfa-notification',
            icon: <Spin indicator={antIcon} />,
            closeIcon: <CloseIcon />,
        })
    } else {
        antNotification[type]({
            message: message,
            description: description,
            placement: 'topRight',
            key: key,
            className: 'dfa-notification',
            icon: <NotificationIcon type={type} />,
            closeIcon: <CloseIcon />,
        })
    }
}

export const closeNotification = (key: string) => {
    antNotification.close(key)
}

export const openMessage = ({ type, message, key }: IOpenMessage) => {
    if (type === 'loading') {
        antMessage.loading({
            content: message,
            duration: 0,
            key: key,
        })
    } else {
        antMessage[type]({
            content: message,
            key: key,
        })
    }
}

export const closeMessage = (key: string) => {
    antMessage.destroy(key)
}
