import { FieldData } from 'rc-field-form/lib/interface'

import { ICheckList } from '../templateComponents'

export const getToken = () => {
    return localStorage.getItem('accessToken')
}

export const parseToken = (token: string | null) => {
    if (!token) {
        return null
    }
    const parsed = token
        .split('.')
        .slice(0, -1)
        .map((token_part) => JSON.parse(atob(token_part)))

    if (parsed[1].scope?.auth) {
        parsed[1].role = Object.keys(parsed[1].scope?.auth)[0]
    }

    return parsed
}

export const getPropFromToken = (token: string | null, prop: string, tokenPart: number) => {
    const parsedToken = parseToken(token)
    if (parsedToken) {
        try {
            const property = parsedToken[tokenPart][prop]
            return property ? property : new Error(`No ${prop} in JWT token`)
        } catch (error) {
            return new Error(`No block with ${prop} in JWT token`)
        }
    }
}

export const isTokenTemporary = (token: string) => {
    const parsedToken = parseToken(token)
    if (!parsedToken) {
        throw new Error("token can't be parsed")
    }
    return parsedToken[0].alg === 'RS384'
}

export const getRoleFromToken = (token: string | null) => {
    return getPropFromToken(token, 'role', 1)
}

export const getErrorStatusesOfPasswordField = (
    fieldStatuses: ICheckList['elements'],
    passwordField: FieldData,
) => {
    return fieldStatuses.map((status) => {
        if ('message' in status.rule) {
            status.checked = passwordField
                ? passwordField.errors && passwordField.touched && passwordField.value !== ''
                    ? !passwordField.errors.includes(status.rule.message as string)
                    : false
                : status.checked
        }

        return status
    })
}
