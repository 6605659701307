import { ReactNode } from 'react'

import { Typography } from '../../../Typography'
import { EMPTY_FIELD_VALUE } from '../../../constants'

const { Text } = Typography

export const DefaultUneditable = (prefix: ReactNode = '', value: ReactNode) => {
    return value !== undefined ? (
        <Text>
            {prefix}
            {value}
        </Text>
    ) : (
        <Text>{EMPTY_FIELD_VALUE}</Text>
    )
}
