import { RbacContext } from '@dltru/dfa-rbac'
import {
    Badge,
    Book,
    BookSaved,
    Box,
    CardAdd,
    DocumentText,
    Exchange,
    Layout,
    MasterchainLogo,
    Menu,
    Profile2User,
    ProfileAdd,
    SecurityUser,
    SettingsOutline,
    ShieldTick,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { notificationCounterSelector } from '@store/notificationCounter/selectors'

import { SidebarLink } from './components/SidebarLink'
import style from './style.m.less'

const { Sider } = Layout
const { Item } = Menu

export const Sidebar: FC = () => {
    const counter = useSelector(notificationCounterSelector.selectAll)
    const { isAllow } = useContext(RbacContext)

    return (
        <Sider width="240" className={style.sider}>
            <Link to="/">
                <Box direction="row" padding={[20, 24]} margin={[8, 0, 20, 0]}>
                    <div className={style.siderlogo}>
                        <MasterchainLogo />
                    </div>
                    <div className={style.siderlogoname}>
                        <span>Цифровые финансовые активы</span>
                    </div>
                </Box>
            </Link>
            <Menu mode="inline" className="flex1">
                {isAllow(['offer.read.all', 'asset.read.all']) && (
                    <>
                        {' '}
                        <Item key="offers" icon={<Exchange />}>
                            <SidebarLink to="/offers">Витрина предложений</SidebarLink>
                        </Item>
                        <Menu.Divider key={1} style={{ margin: '16px 12px' }} />
                    </>
                )}

                {isAllow(['asset.read.all']) && (
                    <Item key="registry" icon={<CardAdd />}>
                        <SidebarLink to="/registry">Реестр ЦФА</SidebarLink>
                    </Item>
                )}

                {isAllow(['user.read.all']) && (
                    <Item key="clients" icon={<Profile2User />}>
                        <SidebarLink to="/clients">Пользователи</SidebarLink>
                    </Item>
                )}

                <Item key="investors-deals" icon={<Book />}>
                    <SidebarLink to="/investors-deals">Операции с ЦФА</SidebarLink>
                </Item>

                {isAllow(['account.read.all']) && (
                    <Item key="personal-accounts" icon={<BookSaved />}>
                        <SidebarLink to="/personal-accounts">Реестр счетов</SidebarLink>
                    </Item>
                )}

                {isAllow(['employee.read.all']) && (
                    <Item key="employees" icon={<SecurityUser />}>
                        <SidebarLink to="/employees">Сотрудники</SidebarLink>
                    </Item>
                )}
                {/*<Item key="exchange-operators" icon={<Share />}>
                    <SidebarLink to="/exchange-operators">Операторы обмена</SidebarLink>
                </Item>*/}
                <Item key="system-management" icon={<SettingsOutline />}>
                    <SidebarLink to="/system-management">Управление системой</SidebarLink>
                </Item>
                <Menu.Divider key={2} style={{ margin: '16px 12px' }} />
                {/*<Item key="divider_2" disabled className={style.siderDivider}>
                    <Divider padding={[16, 12, 16, 24]} style={{ margin: 0 }} />
                </Item>*/}

                {isAllow(['application_form.read.all']) && (
                    <Item key="registration" icon={<ProfileAdd />}>
                        <SidebarLink to="/registration-requests">Регистрация</SidebarLink>
                    </Item>
                )}

                <Item key="qualificationStatements" icon={<ShieldTick />}>
                    <SidebarLink to="/qualification-statements">
                        Присвоение квалификации
                    </SidebarLink>
                </Item>

                {isAllow(['approval.operator', 'approval.lawyer', 'approval.aml']) && (
                    <Item key="emission" icon={<CardAdd />}>
                        <SidebarLink to="/emissions">
                            Выпуск ЦФА{' '}
                            <Badge count={counter.registrationDfaCount} showZero isMenu />
                        </SidebarLink>
                    </Item>
                )}

                {isAllow(['order.read.all', 'payment.read.all']) && (
                    <Item key="payment-orders" icon={<DocumentText />}>
                        <SidebarLink to="/payment-orders">Поручения пользователей</SidebarLink>
                    </Item>
                )}
            </Menu>
        </Sider>
    )
}
