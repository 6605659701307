import { Box, PageHeader, Tabs } from '@dltru/dfa-ui'
import { FC } from 'react'

import { UseTabsAndHash } from '../../hooks/useTabsAndHash'
import '../styles/style.less'
import { EmissionsTable } from './components/EmissionsTable'
import { IndividualOffersTable } from './components/IndividualOffersTable'
import { SecondaryOrdersTable } from './components/SecondaryOrdersTable'

const { TabPane } = Tabs

enum TabName {
    secondary = 'secondary',
    emission = 'emission',
    transfer = 'transfer',
}
const tabNames = Object.values(TabName)
const defaultTab = TabName.secondary

export const Offers: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(tabNames, defaultTab)

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Витрина предложений" />
            </Box>
            <Tabs onChange={onChangeTab} activeKey={activeTab}>
                <TabPane tab="Вторичное обращение" key={TabName.secondary}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <SecondaryOrdersTable />
                    </Box>
                </TabPane>
                <TabPane tab="Эмиссия" key={TabName.emission}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <EmissionsTable />
                    </Box>
                </TabPane>
                <TabPane tab="Оферты" key={TabName.transfer}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <IndividualOffersTable />
                    </Box>
                </TabPane>
            </Tabs>
        </>
    )
}
