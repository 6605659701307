import jwtDecode, { JwtPayload } from 'jwt-decode'

type ReloadTokenResponse = {
    accessToken: string | null
    refreshToken: string | null
    error: unknown | null
}

export interface CheckAndRefreshProps {
    token: string
    timeToCheck: number
    reloadToken: () => Promise<ReloadTokenResponse>
}

const HOUR_IN_MS = 60 * 60 * 1000
const TO_MS = 1000

export class CheckAndRefresh {
    #token: string | null = null
    #timer: ReturnType<typeof setTimeout> | null = null
    #timeToCheck: number = HOUR_IN_MS

    #reloadToken: () => Promise<ReloadTokenResponse> | null = null

    constructor(props: CheckAndRefreshProps) {
        this.#token = props.token
        this.#reloadToken = props.reloadToken
        this.#timeToCheck = props.timeToCheck

        this.timerHandler = this.timerHandler.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
    }

    get currentTimer() {
        return this.#timer
    }

    set token(value) {
        this.#token = value
    }

    start() {
        this.#timer = setTimeout(this.timerHandler, this.#timeToCheck)
    }

    stop() {
        clearTimeout(this.#timer)
    }

    timerHandler = () => {
        this.stop()

        const isExpired = this.isTokenExpired()
        if (isExpired) {
            this.#reloadToken().then((res) => {
                this.token = res.accessToken
                if (res.accessToken) {
                    this.start()
                }
                // TODO else {  }  как то оповещать пользователя что произошла ошбика и выкидывать на логин
            })
        } else {
            this.start()
        }
    }

    isTokenExpired() {
        if (this.#token) {
            const decoded = jwtDecode<JwtPayload>(this.#token)
            const currentDateAndHour = new Date().valueOf() + HOUR_IN_MS

            if (!decoded.exp) {
                return true
            }

            return decoded.exp * TO_MS < currentDateAndHour
        }
    }
}
