import { createSelector } from '@reduxjs/toolkit'

import IAppState from '@store/states'

export const selectWalletLocks = (state: IAppState) => state.walletLocks
const selectOrder = (state: IAppState) => state.walletLocks.order
const selectLimit = (state: IAppState) => state.walletLocks.limit
export const selectWalletLocksOrderFilters = createSelector(
    selectOrder,
    selectLimit,
    (order, limit) => ({ order, limit }),
)
