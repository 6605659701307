import { DocumentsEnum, ProfileStatus, ProfileType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import React, { FC, useContext } from 'react'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { SendToApprovedAction } from './actions/SendToApprovedAction'
import { SendToEditAction } from './actions/SendToEditAction'
import { SendToLawyerAction } from './actions/SendToLawyerAction'
import { SendToRefuseAction } from './actions/SendToRefuseAction'

interface IComponentProps {
    profileType: ProfileType
    profileStatus: ProfileStatus
    profileUuid: string
}
export const Footer: FC<IComponentProps> = (props) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isViewFooter = contentFooter && typeof contentFooter !== null
    const { isAllow } = useContext(RbacContext)

    const showDownloadButton =
        props.profileType !== ProfileType.AGNT && isAllow(['application_form.read.all'])

    return (
        <>
            {isViewFooter && (
                <Portal container={contentFooter}>
                    <ContentFooterBox
                        left={
                            showDownloadButton ? (
                                <DownloadBtn
                                    documentType={DocumentsEnum.user_profile}
                                    title="Скачать"
                                    params={{user_id: props.profileUuid}}
                                />
                            ) : undefined
                        }
                    >
                        <SendToRefuseAction {...props} />
                        <SendToLawyerAction {...props} />
                        <SendToEditAction {...props} />
                        <SendToApprovedAction {...props} />
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
