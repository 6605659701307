import IAppState from '../states'

const selectList = (state: IAppState) => state.tariffRelatedAssets.data?.items || []
const selectPaging = (state: IAppState) => state.tariffRelatedAssets.data?.paging
const selectParams = (state: IAppState) => state.tariffRelatedAssets.params
const selectIsLoading = (state: IAppState) => state.tariffRelatedAssets.isLoading

export const tariffRelatedAssetsSelector = {
    selectList,
    selectPaging,
    selectIsLoading,
    selectParams,
}
