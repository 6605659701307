import {
    BaseDescriptionEnum,
    BaseDescriptionTranslate,
    ClientType,
    ClientTypeTranslate,
    ProfileType,
    baseDescriptionAdmin,
} from '@dltru/dfa-models'
import {
    Button,
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    Divider,
    Form,
    IntegerInput,
    UploadFile,
    WrappedInput,
    WrappedSelect,
    dfaValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import { InnInput, UploadInput } from '@components/Form'

const clientTypeDescriptionOptions = Object.entries(ClientTypeTranslate).map(([value, label]) => ({
    value,
    label,
}))

const baseDescriptionOptions = Object.entries(BaseDescriptionTranslate)
    .filter(([value]) => baseDescriptionAdmin.includes(value as BaseDescriptionEnum))
    .map(([value, label]) => ({
        value,
        label,
    }))

type LocationState = {
    user_id: string
    user_full_name: string
}

export type FormDataType = {
    client_type: ClientType
    amount_dfa: string
    oferent: string
    ownership_receiver: string
    base_document_uuid: UploadFile[]
    base_description: BaseDescriptionEnum
}
interface IComponentProps {
    onOk: (values: FormDataType) => void
}
export const DFAOwnershipReceiverForm: FC<IComponentProps> = ({ onOk }) => {
    const [searchParams] = useSearchParams()
    const [uneditable, setUneditable] = useState(false)
    const location = useLocation()
    const state = location.state as LocationState
    const [form] = Form.useForm<FormDataType>()
    const [currentClientType, setCurrentClientType] = useState<ClientType>()

    const onValuesChange = (changedValues: Partial<FormDataType>) => {
        if ('client_type' in changedValues) {
            setCurrentClientType(changedValues.client_type)
            if (
                (currentClientType === ProfileType.LEGL &&
                    changedValues.client_type !== ProfileType.LEGL) ||
                (currentClientType !== ProfileType.LEGL &&
                    changedValues.client_type === ProfileType.LEGL)
            ) {
                form.resetFields(['ownership_receiver'])
            }
        }
    }
    const oferentRenderUneditable = () => (
        <Link to={`/clients/${state?.user_id}`}>{state?.user_full_name}</Link>
    )

    const onFinish = (values: FormDataType) => {
        setUneditable(true)
        onOk(values)
    }
    const maxCount = searchParams.get('amount') ? Number(searchParams.get('amount')) : 0

    return (
        <Form form={form} size="large" onValuesChange={onValuesChange} onFinish={onFinish}>
            <Card scrollIntoView uneditable={uneditable}>
                <CardHeader title="Условия передачи прав" />
                <CardContent divider={!uneditable}>
                    <div className="formRow">
                        <WrappedInput
                            name="oferent"
                            required
                            disabled
                            label="Текущий Владелец"
                            className="formComponentItem"
                            initialValue={state?.user_full_name}
                            uneditable={uneditable}
                            renderUneditable={oferentRenderUneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow">
                        <InnInput
                            label="ИНН Нового Владельца"
                            name="ownership_receiver"
                            required
                            isUl={currentClientType === ProfileType.LEGL}
                            disabled={!currentClientType}
                            checkInnExistenceFor={currentClientType as ProfileType}
                            uneditable={uneditable}
                        />
                    </div>
                    <div className="formRow">
                        <WrappedSelect
                            name="client_type"
                            required
                            label="Тип пользователя Нового Владелеца"
                            className="formComponentItem formComponentItem--md"
                            options={clientTypeDescriptionOptions}
                            uneditable={uneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow">
                        <IntegerInput
                            className="formComponentItem formComponentItem--md"
                            label="Количество ЦФА"
                            name="amount_dfa"
                            placeholder="В единицах"
                            required
                            rules={[
                                {
                                    validator: dfaValidators.biggerAmount(maxCount),
                                },
                            ]}
                            uneditable={uneditable}
                            maxLength={19}
                            disabled={!currentClientType}
                        />
                    </div>
                    <div className="formRow">
                        <WrappedSelect
                            className="formComponentItem formComponentItem--md"
                            label="Основание передачи прав"
                            name="base_description"
                            options={baseDescriptionOptions}
                            required
                            uneditable={uneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow">
                        <UploadInput
                            className="formComponentItem upload-form__styled"
                            name="base_document_uuid"
                            label="Загруженные документы"
                            required
                            labelOnlyUneditable={false}
                            uneditable={uneditable}
                            title="Загрузить фотографии документа. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                            maxSizeMb={5}
                            accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                        />
                    </div>
                </CardContent>
                {!uneditable && (
                    <CardFooter>
                        <div>
                            <Button
                                borderRadius={12}
                                type="primary"
                                size="middle"
                                htmlType="submit"
                            >
                                Далее
                            </Button>
                        </div>
                    </CardFooter>
                )}
            </Card>
        </Form>
    )
}
