import { FC, useEffect, useRef, useState } from 'react'

import { Box, Button, CloseCircleOutlined } from '..'
import { Space } from '../Space'
import { MiniModal } from '../components/Modal'
import { COUNTDOWN_TICK, MS, TIME_TO_LOGOUT } from './consts'

interface ICountdownModalProps {
    isShow: boolean
    onOk: () => void
    logout: () => void
}

export const CountdownModal: FC<ICountdownModalProps> = ({ isShow, onOk, logout }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    const [countdownTimer, setCountdownTimer] = useState(TIME_TO_LOGOUT / MS)
    const timer = useRef<ReturnType<typeof setTimeout>>()

    const reset = () => {
        setIsShowModal(false)
        setCountdownTimer(TIME_TO_LOGOUT / MS)
    }

    const onOkHandler = () => {
        reset()
        onOk()
    }

    useEffect(() => {
        if (countdownTimer === 0) {
            return
        }

        if (isShow) {
            !isShowModal && setIsShowModal(true)
            timer.current && clearTimeout(timer.current)
            timer.current = setTimeout(() => {
                setCountdownTimer(countdownTimer - 1)
                if (countdownTimer - 1 === 0) {
                    reset()
                    logout()
                }
            }, COUNTDOWN_TICK)
        }

        return () => {
            timer.current && clearTimeout(timer.current)
        }
    }, [countdownTimer, isShow])

    return (
        <MiniModal
            visible={isShowModal}
            width={600}
            title={
                <Space size={0}>
                    <CloseCircleOutlined className="modal-icon modal-error-icon" />
                    Ваш сеанс работы в системе будет прерван
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={onOkHandler}>
                    Продолжить работу
                </Button>,
            ]}
        >
            <Box padding={[0, 0, 8, 40]}>
                <p className="modal-content-text">
                    Ваш сеанс работы в системе будет прерван через {countdownTimer} секунд в связи с
                    отсутствием активности
                </p>
            </Box>
        </MiniModal>
    )
}
