import { Button, Divider, Modal } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import { IOption, UsersAutocomplete } from './UsersAutocomplete/UsersAutocomplete'
import style from './style.m.less'
import { useDispatch } from 'react-redux'
import { tariffRelatedUsersSlice } from '@store/tariffRelatedUsers'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    tariffId?: string
}

export const RelateModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    tariffId,
}) => {
    const reduxDispatch = useDispatch()
    const [selected, setSelected] = useState<IOption[]>([])

    const onCloseModal = () => {
        setIsModalVisible(false)
    }

    const onAddIssuesHandler = () => {
        if (!tariffId) {
            return
        }

        reduxDispatch(
            tariffRelatedUsersSlice.actions.linksUsers({ users: selected, tariff_id: tariffId }),
        )

        setIsModalVisible(false)
    }

    return (
        <Modal
            visible={isModalVisible}
            width={800}
            onCancel={onCloseModal}
            className={style.modal}
            destroyOnClose
        >
            <div className={style.wrapper}>
                <div className={style.header}>Привязка пользователей к тарифному плану</div>
                <div className={style.body}>
                    <p>
                        Выбранные пользователи будут добавлены к данному тарифному плану. Комиссии
                        по всем операциям будут расчитываться по данному тарифному плану.
                    </p>
                    <Divider margin={0} />

                    <div className={style.autocompleteContainer}>
                        <h6 className={style.autocompleteTitle}>Список пользователей</h6>
                        <UsersAutocomplete isEdit={false} name={''} onSelect={setSelected} />
                    </div>
                </div>
                <div className={style.footer}>
                    <Button style={{ marginRight: 16, borderRadius: 12 }} onClick={onCloseModal}>
                        Отмена
                    </Button>

                    <Button
                        borderRadius={16}
                        type="primary"
                        disabled={selected.length === 0}
                        onClick={onAddIssuesHandler}
                    >
                        Добавить пользователей
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
