import { Button } from 'antd'
import { FC, useContext } from 'react'

import { FiltersContext } from './FiltersContext'
import style from './style.m.less'

type FiltersResetProps = {
    disabled?: boolean
}

export const FiltersReset: FC<FiltersResetProps> = ({ disabled }) => {
    const { onReset, totalActiveFilters } = useContext(FiltersContext)
    if (!totalActiveFilters) {
        return null
    }

    return (
        <Button className={style.filtersReset} onClick={() => onReset()} disabled={disabled}>
            Сбросить
        </Button>
    )
}
