import { PaperClipOutlined } from '@ant-design/icons'
import classNames from 'classnames/bind'
import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Divider } from '../../../Divider'
import { Space } from '../../../Space'
import { splitNumberIntoThousands } from '../../../utils'
import style from './style.m.less'

type bodyFieldsFontSize = 'small' | 'default'

interface IGrayLabelDataField {
    label: string
    flexDirection?: 'row' | 'column'
}

interface IDigitsData {
    number: string | number
}

interface ILinkData {
    link: string
    linkText: string
    fontSize?: bodyFieldsFontSize
}

interface IDocumentLinkDataBox {
    links: ILinkData[]
    fontSize?: bodyFieldsFontSize
}

interface IBodyTitle {
    title: string
}

interface ITextParagraph {
    text: string
}

const cx = classNames.bind(style)

export const BodyTitle: FC<IBodyTitle> = ({ title }) => {
    return <span className={style.modalBodyTitle}>{title}</span>
}

export const ModalDivider: FC = () => <Divider className={style.modalBodyDivider} />

export const GrayLabelDataField: FC<IGrayLabelDataField> = ({
    label,
    children,
    flexDirection = 'row',
}) => {
    const grayLabelDataFieldClassName = cx({
        columnFlexDirection: flexDirection === 'column',
        grayLabelDataFieldContainer: true,
    })

    return (
        <div className={grayLabelDataFieldClassName}>
            <div className={style.grayLabel}>{label}</div>
            <div>{children}</div>
        </div>
    )
}

export const SemiboldDigitsData: FC<IDigitsData> = ({ number }) => {
    return (
        <div className={style.semiboldDigitsData}>{splitNumberIntoThousands(Number(number))}</div>
    )
}

export const SmallGreyData: FC = ({ children }) => {
    return <div className={style.smallGreyData}>{children}</div>
}

export const DocumentLinkData: FC<ILinkData> = ({ link, linkText, fontSize = 'default' }) => {
    const DocumentLinkDataClassName = cx({
        smallText: fontSize === 'small',
        documentLinkData: true,
    })

    return (
        <Link className={DocumentLinkDataClassName} to={link}>
            <Space size={6}>
                <PaperClipOutlined />
                {linkText}
            </Space>
        </Link>
    )
}

export const DocumentLinksDataBox: FC<IDocumentLinkDataBox> = ({ links, fontSize = 'default' }) => {
    return (
        <div className={style.documentLinkDataBox}>
            {links.map((link: ILinkData) => (
                <DocumentLinkData {...link} fontSize={fontSize} />
            ))}
        </div>
    )
}

export const TextParagraph: FC<ITextParagraph> = ({ text }) => {
    return <span className={style.textParagraph}>{text}</span>
}

export const BodyContainer: FC<{ className: string }> = ({ children, className }) => (
    <div className={clsx(style.bodyContainer, className)}>{children}</div>
)
