import { TariffPlaneEnum, tariffPlaneTypeTranslate } from '@dltru/dfa-models'
import { Form, Step, StepperContext, WrappedInput, WrappedSelect, useEditable } from '@dltru/dfa-ui'
import React, { FC, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { tariffSelector } from '@store/tariff/selectors'

const STEP_INDEX = 0
export const CommonInfoStep: FC = () => {
    const { editable, currentStep, stepsTotal } = useContext(StepperContext)
    const baseTariff = useSelector(tariffSelector.selectBaseTariff)
    const [form] = Form.useForm()

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(STEP_INDEX))

    const typeOptions = useMemo(() => {
        if (baseTariff) {
            form.resetFields(['type'])
            return Object.entries(tariffPlaneTypeTranslate)
                .filter(([value]) => value !== TariffPlaneEnum.BASE)
                .map(([value, label]) => ({
                    value,
                    label,
                }))
        } else {
            form.setFieldsValue({ type: TariffPlaneEnum.BASE })
            return [
                {
                    value: TariffPlaneEnum.BASE,
                    label: tariffPlaneTypeTranslate[TariffPlaneEnum.BASE],
                },
            ]
        }
    }, [baseTariff])

    return (
        <Step
            stepIndex={STEP_INDEX}
            form={form}
            stepTitle="Общая информация"
            allowEdit={allowEdit}
            saveAfterEdit
            disabledHeaderStepCounter
        >
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Наименование тарифного плана"
                    name="name"
                    placeholder="Максимум 200 символов"
                    autoComplete="title"
                    required
                    maxLength={200}
                    size="large"
                    uneditable={isEdit}
                />
            </div>
            <div className="formRow">
                <WrappedSelect
                    className="formComponentItem"
                    label="Тип"
                    name="type"
                    options={typeOptions}
                    size="large"
                    required
                    uneditable={isEdit}
                    disabled={currentStep !== STEP_INDEX || typeOptions.length === 1}
                />
            </div>
        </Step>
    )
}
