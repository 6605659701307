// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu__O5lO .ant-dropdown-menu {\n  padding: 8px 0;\n  border-radius: 16px;\n  overflow: hidden;\n}\n.menu__O5lO .ant-dropdown-menu-item:hover,\n.menu__O5lO .ant-dropdown-menu-submenu-title:hover {\n  background-color: #e3f6ff;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Tariff/View/components/HistoryTariffGrid/ActionCell/style.m.less"],"names":[],"mappings":"AAEA;EAGM,cAAA;EACA,mBAAA;EACA,gBAAA;AAHN;AAFA;;EASM,yBAAA;AAHN","sourcesContent":["@import 'style/palette';\n\n.menu {\n  :global {\n    .ant-dropdown-menu {\n      padding: 8px 0;\n      border-radius: 16px;\n      overflow: hidden;\n    }\n\n    .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {\n      background-color: @primary-1;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu__O5lO"
};
export default ___CSS_LOADER_EXPORT___;
