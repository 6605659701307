import { FC } from 'react'

import { Box, BoxProps } from '../Box'
import './styles.less'

export type CardFooterProps = Pick<BoxProps, 'padding' | 'margin'>
export const CardFooter: FC<CardFooterProps> = ({
    children,
    padding = [16, 0, 8, 0],
    margin = 0,
}) => {
    return (
        <Box padding={padding} margin={margin}>
            {children}
        </Box>
    )
}
