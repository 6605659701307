import { IDfaFront, IDfaOwnerBalance, RedemptionTypeDfa, SettlementsType } from '@dltru/dfa-models'
import { FC } from 'react'

import { Coins } from '../../../Icons'
import { Tooltip } from '../../../Tooltip'
import { EMPTY_FIELD_VALUE } from '../../../constants'
import { CaseBadge } from '../../../tags'
import { Milestone } from '../groupData'
import { ProgressDfa } from './ProgressDfa'
import style from './style.m.less'

export interface IDecisionHeaderProps {
    dfa: IDfaFront
    balance?: Partial<IDfaOwnerBalance>
}

const statusWithBalance = ['emitment_success', 'redeemed', 'redeem_started']

export const DecisionHeader: FC<IDecisionHeaderProps> = ({ dfa, balance }) => {
    const inCaseView = dfa.status === 'emitment_success' && balance !== undefined

    let originalAmount = dfa?.total_supply_invested ?? 0
    if (dfa.status === 'redeemed' || dfa.status === 'mature') {
        originalAmount = 0
    }
    const showRedeemPrice =
        dfa?.redemption_type === RedemptionTypeDfa.fixed || dfa.redeem_price_per_dfa

    const RedeemPriceComponent = showRedeemPrice ? (
        <span className={style.redeemPrice}>
            {dfa.redeem_price_per_dfa?.toLocaleString() ?? EMPTY_FIELD_VALUE}
            &nbsp;₽
        </span>
    ) : (
        <Tooltip title={dfa?.redemption_description} width={400}>
            <span className={style.dottedLink}>Порядок определения цены</span>
        </Tooltip>
    )

    return (
        <div className={style.edcHeader}>
            <div className={style.edcHeaderLeft}>
                <div className={style.edcTitleWrapper}>
                    <div className={style.edcTitle}>
                        <div>
                            <h4>
                                {dfa.price_per_dfa?.toLocaleString() ?? EMPTY_FIELD_VALUE}
                                <span className={style.grayText}> ₽ за ед.</span>
                            </h4>
                            {dfa.issue_settlements_type === SettlementsType.direct && (
                                <div
                                    className={style.directStatusContainer}
                                    style={{ marginRight: '24px' }}
                                >
                                    <Coins />
                                    <div className={style.directStatusLabel}>
                                        Расчеты при приобретении вне Платформы
                                    </div>
                                </div>
                            )}
                        </div>
                        <Milestone label="Цена погашения" value={RedeemPriceComponent} top />
                        {dfa.repayment_settlements_type === SettlementsType.direct && (
                            <div
                                className={style.directStatusContainer}
                                style={{ marginLeft: '24px' }}
                            >
                                <Coins />
                                <div className={style.directStatusLabel}>
                                    Расчеты при погашении <br /> осуществляются вне Платформы
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {statusWithBalance.includes(dfa.status) && (
                <div className={style.edcHeaderRight}>
                    {/* TODO: Временно прячем */}
                    {/*<Milestone
                        label="Заблокировано"
                        value={
                            <span className={style.borderBlock}>
                                {dfa.balance?.injunction_amount_dfa ?? 0}
                            </span>
                        }
                        centered
                        withGap
                    />*/}
                    <Milestone
                        label="В обороте"
                        value={<span className={style.borderBlock}>{originalAmount}</span>}
                        centered
                        withGap
                    />
                    {inCaseView && (
                        <Milestone
                            label="В кошельке"
                            value={
                                <CaseBadge
                                    count={balance?.active_amount_dfa ?? 0}
                                    buying={Boolean(balance?.active_amount_dfa)}
                                />
                            }
                            centered
                            withGap
                        />
                    )}
                </div>
            )}
            <ProgressDfa dfa={dfa} />
        </div>
    )
}
