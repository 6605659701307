import { CertificateType } from '@dltru/dfa-models'
import dayjs from 'dayjs'
import React, { FC, useMemo } from 'react'

import { Box } from '../../../Box'
import { StampIcon } from '../../../Icons'
import { Requisites } from '../../../Requisites'
import { Space } from '../../../Space'
import { Button } from '../../../button'
import { MiniModal } from '../../../components/Modal'
import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '../../../constants'
import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    certificate: CertificateType
}
export const CertificateDetailsModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    certificate,
}) => {
    const requisites = useMemo(() => {
        const data = [
            { label: 'Сертификат', value: certificate.skid ?? EMPTY_FIELD_VALUE },
            {
                label: 'Сертификат (публичный ключ)',
                value: certificate.public_key_sha1 ?? EMPTY_FIELD_VALUE,
            },
        ]
        if (certificate.meta?.SzOID_OGRN || certificate.meta?.SzOID_OGRNIP) {
            data.push({
                label: 'Организация',
                value: certificate.meta?.SzOID_OGRNIP
                    ? `ИП ${certificate.meta?.FullName}`
                    : certificate.meta?.SzOID_ORGANIZATION_NAME || EMPTY_FIELD_VALUE,
            })
        }
        data.push(
            {
                label: 'Владелец',
                value:
                    `${certificate.meta?.SzOID_SUR_NAME} ${certificate.meta?.SzOID_GIVEN_NAME}` ||
                    EMPTY_FIELD_VALUE,
            },
            {
                label: 'Действителен до',
                value: certificate.meta?.ExpireDate
                    ? dayjs(certificate.meta.ExpireDate).format(VIEW_DATE_FORMAT)
                    : EMPTY_FIELD_VALUE,
            },
        )

        return data
    }, [certificate])

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            title={
                <Space size={16}>
                    <StampIcon className={style.blueIcon} />
                    Усиленная неквалифицированная электронная подпись (УНЭП)
                </Space>
            }
            footer={
                <Button type="primary" onClick={() => setIsModalVisible(false)} borderRadius={12}>
                    ОК
                </Button>
            }
        >
            <Box margin={[24, 0, 24, 40]}>
                <Requisites requisites={requisites} size="large" />
            </Box>
        </MiniModal>
    )
}
