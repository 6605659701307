import { CSSProperties, PropsWithChildren, forwardRef } from 'react'

import { VHStyle, getStylesPropName } from '../utils/styles'
import './style.less'

export type BoxProps = {
    className?: string
    gap?: number
    padding?: VHStyle
    margin?: VHStyle
    direction?: CSSProperties['flexDirection']
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    width?: CSSProperties['width']
    height?: CSSProperties['height']
}

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
    ({ className, margin, padding, children, direction, justify, align, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={className}
                style={{
                    display: 'flex',
                    flexDirection: direction ?? 'column',
                    justifyContent: justify ?? 'normal',
                    alignItems: align ?? 'stretch',
                    ...getStylesPropName('padding', padding),
                    ...getStylesPropName('margin', margin),
                    ...props,
                }}
            >
                {children}
            </div>
        )
    },
)
