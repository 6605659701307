// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emissionCardHeading_rvLbY {\n  font-size: 16px;\n}\n.emissionCardHeading_rvLbY a {\n  font-weight: 600;\n}\n.cardRow_telM5 {\n  display: flex;\n  gap: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EmissionDecisionCard/style.m.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAFA;EAGQ,gBAAA;AAER;AAEA;EACI,aAAA;EACA,SAAA;AAAJ","sourcesContent":[".emissionCardHeading {\n    font-size: 16px;\n    a {\n        font-weight: 600;\n    }\n}\n\n.cardRow {\n    display: flex;\n    gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emissionCardHeading": "emissionCardHeading_rvLbY",
	"cardRow": "cardRow_telM5"
};
export default ___CSS_LOADER_EXPORT___;
