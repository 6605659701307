// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password_Cev3F {\n  color: #868991;\n  font-size: 20px;\n  letter-spacing: 2px;\n}\n.bold_J7PcK {\n  font-weight: 600;\n}\n.cardFieldWrapper_DoxO3 {\n  border-top: 1px solid transparent;\n  border-bottom: 1px solid transparent;\n}\n.cardFieldWrapperGray_CXWi0 {\n  background-color: #f8f8fa;\n  border-color: #ebecf0;\n}\n.grayText_UwVut {\n  margin: 0;\n  font-size: 14px;\n  line-height: 22px;\n  color: #555861;\n}\n.formWrapper_FCpx9 {\n  max-width: 400px;\n}\n.rowWrapper__HK6N {\n  width: 100%;\n}\n.mainWrapper_g_wdX {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AuthInfo/style.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,eAAA;EACA,mBAAA;AADJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,iCAAA;EACA,oCAAA;AAFJ;AAGI;EACI,yBAAA;EACA,qBAAA;AADR;AAIA;EACI,SAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAIA;EACI,gBAAA;AAFJ;AAIA;EACI,WAAA;AAFJ;AAIA;EACI,WAAA;AAFJ","sourcesContent":["@import 'style/palette';\n\n.password {\n    color: @gray-7;\n    font-size: 20px;\n    letter-spacing: 2px;\n}\n.bold {\n    font-weight: 600;\n}\n\n.cardFieldWrapper {\n    border-top: 1px solid transparent;\n    border-bottom: 1px solid transparent;\n    &Gray {\n        background-color: @gray-2;\n        border-color: @gray-4;\n    }\n}\n.grayText {\n    margin: 0;\n    font-size: 14px;\n    line-height: 22px;\n    color: @gray-8;\n}\n.formWrapper {\n    max-width: 400px;\n}\n.rowWrapper {\n    width: 100%;\n}\n.mainWrapper {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password": "password_Cev3F",
	"bold": "bold_J7PcK",
	"cardFieldWrapper": "cardFieldWrapper_DoxO3",
	"cardFieldWrapperGray": "cardFieldWrapperGray_CXWi0",
	"grayText": "grayText_UwVut",
	"formWrapper": "formWrapper_FCpx9",
	"rowWrapper": "rowWrapper__HK6N",
	"mainWrapper": "mainWrapper_g_wdX"
};
export default ___CSS_LOADER_EXPORT___;
