import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'

import { Tooltip } from '../../Tooltip'
import './Button.less'

export type ButtonProps = Omit<AntButtonProps, 'type'> & {
    type?: AntButtonProps['type'] | 'secondText' | 'danger' | 'linkText'
    borderRadius?: number
    tooltipOnDisable?: string
    tooltip?: string
    width?: string
}

function calcType(type: ButtonProps['type']): AntButtonProps['type'] {
    switch (type) {
        case 'secondText':
            return 'text'
        case 'danger':
            return 'primary'
        case 'linkText':
            return 'link'
        default:
            return type
    }
}

export const Button: FC<ButtonProps> = ({
    className,
    type = 'default',
    borderRadius = 0,
    width = 'inherit',
    size = 'middle',
    style = {},
    disabled,
    tooltipOnDisable,
    tooltip,
    ...rest
}) => {
    const button = (
        <AntButton
            danger={type === 'danger'}
            {...rest}
            style={{
                borderRadius: borderRadius + 'px',
                width: width,
                alignItems: 'center',
                justifyContent: 'center',
                ...style,
            }}
            type={calcType(type)}
            className={clsx('dfa-button', type, className)}
            size={size}
            disabled={disabled}
        />
    )
    const tooltipText = disabled && tooltipOnDisable ? tooltipOnDisable : tooltip
    return (
        <>
            {tooltipText ? (
                <Tooltip placement="top" title={tooltipText}>
                    {button}
                </Tooltip>
            ) : (
                button
            )}
        </>
    )
}
