import { XOR } from '../../utils'
import { ITransferOrder } from '../orders'
import { ICommonDeal } from './commonDealData'
import { IEmissionDeal, OnlyEmissionDealType } from './emission'
import { IRepaymentDeal, OnlyRepaymentDealType } from './repayment'
import { ISecondaryDeal, OnlySecondaryDealType } from './secondary'
import { ITransferDeal, OnlyTransferDealType } from './transfer'

export type UnionDealType = ICommonDeal &
    XOR<
        OnlyEmissionDealType,
        XOR<OnlySecondaryDealType, XOR<OnlyRepaymentDealType, OnlyTransferDealType>>
    >

export type UnionDealFront = {
    type?:
        | 'emission'
        | 'secondary'
        | 'buy'
        | 'sell'
        | 'repayment'
        | 'transfer'
        | 'transfer_buy'
        | 'transfer_sell'
        | 'transferV2'
} & UnionDealType

export const isEmissionDeal = (deal: UnionDealFront): deal is IEmissionDeal => {
    return deal.type === 'emission'
}
export const isSecondaryDeal = (deal: UnionDealFront): deal is ISecondaryDeal => {
    return deal.type === 'buy' || deal.type === 'sell' || deal.type === 'secondary'
}
export const isRepaymentDeal = (deal: UnionDealFront): deal is IRepaymentDeal => {
    return deal.type === 'repayment'
}
export const isTransferDeal = (deal: UnionDealFront): deal is ITransferDeal => {
    return deal.type === 'transfer_buy' || deal.type === 'transfer_sell' || deal.type === 'transfer'
}

export const getDealTypeTranslateClient = (
    type: UnionDealFront['type'] | undefined,
    record: UnionDealFront,
) => {
    switch (type) {
        case 'emission':
            return 'Сделка размещения'
        case 'buy':
            return 'Покупка'
        case 'sell':
            return 'Продажа'
        case 'transferV2':
            return 'Переход прав'
        case 'transfer_buy':
        case 'transfer_sell':
            return (record.order as ITransferOrder).create_initiator === 'operator'
                ? 'Переход прав'
                : 'Иные сделки с ЦФА'
        case 'repayment':
            return 'Погашение'
        default:
            return ''
    }
}

export const getDealTypeTranslateOperator = (
    type: UnionDealFront['type'] | undefined,
    record: UnionDealFront,
) => {
    switch (type) {
        case 'emission':
            return 'Сделка размещения'
        case 'secondary':
        case 'sell':
        case 'buy':
            return 'Купля-продажа'
        case 'transferV2':
            return 'Переход прав'
        case 'transfer':
        case 'transfer_buy':
        case 'transfer_sell':
            return (record.order as ITransferOrder)?.create_initiator === 'operator'
                ? 'Переход прав'
                : 'Иные сделки с ЦФА'
        case 'repayment':
            return 'Погашение'
        default:
            return ''
    }
}
