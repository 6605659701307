import clsx from 'clsx'
import { FC } from 'react'

import { Modal, ModalProps } from '../MainModal/Modal'
import './styles.less'

export const MiniModal: FC<ModalProps> = ({ width = 'fit-content', footer, title, ...rest }) => (
    <Modal
        closable={false}
        footer={footer ?? null}
        title={title}
        width={width}
        className={clsx('mini-modal', {
            'mini-modal--with-footer': Boolean(footer),
            'mini-modal--with-header': Boolean(title),
        })}
        {...rest}
    />
)
