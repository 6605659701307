import { UserRoles } from '../auth'

export enum RbacMatrixAction {
    ALL = 'ALL',
    READ = 'READ',
    WRITE = 'WRITE',
    ACTION = 'ACTION',
}

export type RbacMatrixScope = Record<string, RbacMatrixAction[]>

export type RbacMatrix = Record<UserRoles, RbacMatrixScope>

export interface IIsAllow {
    scope: string[]
}
