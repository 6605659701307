// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BalanceListGroup__HXZg {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/balance/BalanceGroup/style.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF","sourcesContent":["@import 'style/palette';\n\n.BalanceListGroup {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BalanceListGroup": "BalanceListGroup__HXZg"
};
export default ___CSS_LOADER_EXPORT___;
