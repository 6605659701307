import { InvestorRegistryApplication } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, DEFAULT_PER_PAGE, Filters, PageHeader, Table } from '@dltru/dfa-ui'
import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { getStatementQualify } from '@store/qualifyStatement'
import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'

import { filters } from './filters'
import { gridConfig } from './gridConfig'

export const QualificationStatementsList = () => {
    const { isAllow } = useContext(RbacContext)

    const reduxDispatch = useDispatch()
    const navigate = useNavigate()

    const { data, paging, isLoading } = useSelector(qualifyStatementSelector.selectPagedData)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(getStatementQualify({ limit }))
    }

    const onNext = () => {
        reduxDispatch(getStatementQualify({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(getStatementQualify({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(getStatementQualify({ limit: DEFAULT_PER_PAGE }))

        return () => {
            reduxDispatch(clientsSlice.actions.clearCurrentClientData())
        }
    }, [])

    const onRow = (data: InvestorRegistryApplication) => ({
        onClick: () => {
            if (isAllow(['qualified_investors_application.read.all'])) {
                reduxDispatch(clientsSlice.actions.getCurrentClient(data.user_uuid))
                navigate(`../qualification-statement/${data.uuid}`)
            }
        },
    })

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Заявки на признание квалифицированным инвестором" />
            </Box>
            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={data ?? []}
                    cursors={paging?.cursors}
                    onPerPage={onPerPageChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    onRow={onRow}
                    clickableRow
                    rowKey="id"
                    headerLeft={
                        <Filters
                            filters={filters}
                            onApplyFilters={function (filters) {
                                reduxDispatch(
                                    getStatementQualify({
                                        filters,
                                        limit: DEFAULT_PER_PAGE,
                                    }),
                                )
                            }}
                        />
                    }
                />
            </Box>
        </>
    )
}
