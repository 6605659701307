import { BankAccountDetailsFullModel } from '@dltru/dfa-models'

import { EMPTY_FIELD_VALUE } from './globalConstants'

export const orgReq = (bankDetails?: BankAccountDetailsFullModel) => {
    return bankDetails
        ? [
              {
                  label: 'Получатель',
                  value: bankDetails.recipient ?? 'ООО Системы распределенного реестра',
              },
              { label: 'ИНН', value: bankDetails.recipient_inn ?? '9704063885' },
          ]
        : [
              { label: 'Получатель', value: 'ООО Системы распределенного реестра' },
              {
                  label: 'Юр.адрес',
                  value: '121099, г. Москва, ул. Композиторская, д.17, этаж 7',
              },
              { label: 'ОГРН', value: '1217700216360' },
              { label: 'ИНН', value: '9704063885' },
              { label: 'КПП', value: '770401001' },
              { label: 'НДС', value: 'НДС не облагается' },
          ]
}

export const bankReq = (bankDetails?: BankAccountDetailsFullModel) => [
    { label: 'БИК банка получателя', value: bankDetails?.bic ?? '044525411' },
    { label: 'Номер счета', value: bankDetails?.account_number ?? '40702810624465210590' },
    {
        label: 'Банк получателя',
        value: bankDetails?.bank ?? 'ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)',
    },
    { label: 'Кор. счёт', value: bankDetails?.corr_account_number ?? '30101810145250000411' },
]
