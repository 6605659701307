import { IDfaFront } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell, Tag, Tooltip } from '@dltru/dfa-ui'
import { getTagProps } from '@resources/dfaState'

const ClientLinkWithTooltip = (value: string, record: IDfaFront) => {
    return (
        <Tooltip title={record.emitter_full_name} placement="top">
            {LinkCell(value, `/clients/${record.emitter_id}`)}
        </Tooltip>
    )
}

export const gridConfig: ColumnsType<IDfaFront> = [
    {
        title: 'Дата и время запроса на выпуск',
        dataIndex: 'issue_start_date',
        key: 'issue_start_date',
        sorter: true,
        render: (value?: number) => DateTimeCell(value ? value / 1000 : value),
    },
    {
        title: 'Тикер',
        dataIndex: 'ticker_symbol',
        key: 'ticker_symbol',
        sorter: true,
        render: (value, record) => LinkCell(value, `/offers/${record.id}`),
    },
    {
        title: 'Наименование ЦФА',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        render: ClientLinkWithTooltip,
    },

    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (value) => {
            const tagProps = getTagProps(value)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
