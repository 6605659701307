export enum TypeOfLaw {
    monetary_claim = 'monetary_claim',
    under_equity_securities = 'under_equity_securities',
    jsc_capitals_part = 'jsc_capitals_part',
    securities_transfer_demand = 'securities_transfer_demand',
}

export const typeOfLawTranslate = {
    [TypeOfLaw.monetary_claim]: 'Денежное требование',
    [TypeOfLaw.under_equity_securities]:
        'Возможность осуществления прав по эмиссионным ценным бумагам',
    [TypeOfLaw.jsc_capitals_part]: 'Право участия в капитале непубличного акционерного общества',
    [TypeOfLaw.securities_transfer_demand]: 'Право требовать передачи эмиссионных ценных бумаг',
}
