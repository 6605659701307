import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { tariffRelatedUsersSlice } from './index'
import IAppState from '@store/states'

function* getRelatedUsers({
    payload,
}: ReturnType<typeof tariffRelatedUsersSlice.actions.getRelatedUsers>) {
    try {
        yield* put(tariffRelatedUsersSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getUsersByTariffUuid, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }

        if (data?.items) {
            yield* put(tariffRelatedUsersSlice.actions.setItems(data))
        }
    } catch (error) {
        yield* put(tariffRelatedUsersSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении списка пользователей',
        })
    } finally {
        yield* put(tariffRelatedUsersSlice.actions.setIsLoading(false))
    }
}

function* linksUsers({ payload }: ReturnType<typeof tariffRelatedUsersSlice.actions.linksUsers>) {
    try {
        for (const user of payload.users) {
            const { data, error } = yield* call(api.lib.tariffLinkUser, {
                user_id: user.user_uuid,
                tariff_id: payload.tariff_id,
            })

            if (error || data?.error) {
                openMessage({
                    type: 'error',
                    message: `Возникли ошибки при дорбавлении пользователя пользователя ${user.full_name}`,
                })
            }
        }

        const limit = yield* select((state: IAppState) => state.tariffRelatedUsers.params.limit)
        yield* put(
            tariffRelatedUsersSlice.actions.getRelatedUsers({
                tariff_id: payload.tariff_id,
                params: { limit },
            }),
        )

        openMessage({
            type: 'success',
            message: `Пользователи успешно добавлены`,
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
        openMessage({ type: 'error', message: 'Возникли ошибки при добавлении пользователей' })
    }
}

function* unlinkUsers({ payload }: ReturnType<typeof tariffRelatedUsersSlice.actions.unlinkUsers>) {
    try {
        for (const user of payload) {
            const { data, error } = yield* call(api.lib.tariffUnlinkUser, { link_id: user.id })
            if (error || data?.error) {
                openMessage({
                    type: 'error',
                    message: `Возникли ошибки при удалении пользователя ${user.full_name}`,
                })
            }
        }

        const limit = yield* select((state: IAppState) => state.tariffRelatedUsers.params.limit)
        const tariff_id = yield* select(
            (state: IAppState) => state.tariffRelatedUsers.data?.items?.[0]?.tariff_id,
        )
        if (tariff_id) {
            yield* put(
                tariffRelatedUsersSlice.actions.getRelatedUsers({
                    tariff_id,
                    params: { limit },
                }),
            )
        }

        openMessage({
            type: 'success',
            message: `Пользователи успешно удалены`,
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
        openMessage({
            type: 'error',
            message: `Возникла ошибка при удалении пользователей`,
        })
    }
}

export function* watchTariffRelatedUsers(): Generator<StrictEffect> {
    yield* takeLatest(tariffRelatedUsersSlice.actions.getRelatedUsers.type, getRelatedUsers)
    yield* takeLatest(tariffRelatedUsersSlice.actions.linksUsers.type, linksUsers)
    yield* takeLatest(tariffRelatedUsersSlice.actions.unlinkUsers.type, unlinkUsers)
}
