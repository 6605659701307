import { FC } from 'react'

import { Box } from '../../../Box'
import { Space } from '../../../Space'
import { Button } from '../../../button'
import { AuthForm } from '../AuthForm'
import style from './style.m.less'

export interface IFullBlockingViewProps {
    onPrev: () => void
}
export const FullBlockingView: FC<IFullBlockingViewProps> = ({ onPrev }) => {
    return (
        <>
            <AuthForm title="Доступ к учётной записи заблокирован" style={{ maxWidth: '440px' }}>
                <Box width="360px">
                    <Space direction="vertical">
                        <span>
                            Для уточнения информации обратитесь <br /> в службу поддержки
                        </span>
                        <a href="tel:+74951207542" className={style.phoneText}>
                            +7 (495) 120 - 75 - 42
                        </a>
                        <br />
                        или по электронной почте{' '}
                        <a href="mailto:support@masterchain.ru">support@masterchain.ru</a>
                    </Space>
                </Box>
                <Box padding={[48, 0, 0, 0]} justify="left" direction="row">
                    <Button borderRadius={16} type="default" size="large" onClick={onPrev}>
                        Назад
                    </Button>
                </Box>
            </AuthForm>
        </>
    )
}
