import { FormInstance } from '../../../components/Form'
import { DataProviderResponse } from './types'

export type FilterState = {
    totalActiveFilters: number
    initialValues: Partial<DataProviderResponse>
    form?: FormInstance<any>
}

export enum FilterActions {
    show = 'show',
    clear = 'clear',
    setForm = 'setForm',
    deleteForm = 'deleteForm',
}

type ShowFilterAction = {
    type: FilterActions.show
    payload: FilterState
}

type ClearFilterAction = {
    type: FilterActions.clear
    payload?: undefined
}

type SetFormAction = {
    type: FilterActions.setForm
    payload?: FormInstance
}

type DeleteFormAction = {
    type: FilterActions.deleteForm
    payload: undefined
}

export type FilterAction = ShowFilterAction | ClearFilterAction | SetFormAction | DeleteFormAction

export const initFilterState: FilterState = {
    totalActiveFilters: 0,
    initialValues: {},
}

const filterReducer = (state: FilterState, action: FilterAction) => {
    switch (action.type) {
        case FilterActions.clear:
            return { ...initFilterState, form: undefined }
        case FilterActions.show:
            return { ...state, ...action.payload, form: undefined }
        case FilterActions.setForm:
            return { ...state, form: action.payload }
        case FilterActions.deleteForm:
            return { ...state, form: undefined }
        default:
            return state
    }
}

export default filterReducer
