/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    CreateEncumbranceType,
    EncumbranceFullType,
    EncumbranceShortType,
    IPagingResponse,
    RemoveEncumbranceType,
    TablePaginationParam,
} from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type CreateItemDataType = Omit<CreateEncumbranceType, 'skid'> & { skid?: string }
interface IWalletEncumbranceState {
    items: EncumbranceShortType[]
    paging: IPagingResponse | undefined
    item: EncumbranceFullType | null
    createItemData: CreateItemDataType | null
    isLoading: boolean
    error: string | null
}

const initialState: IWalletEncumbranceState = {
    items: [],
    paging: undefined,
    item: null,
    createItemData: null,
    isLoading: false,
    error: null,
}

export const walletEncumbranceSlice = createSlice({
    name: 'walletEncumbrance',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload || null
        },
        setData: (
            state,
            action: PayloadAction<Pick<IWalletEncumbranceState, 'items' | 'paging'>>,
        ) => {
            state.items = action.payload.items
            state.paging = action.payload.paging
        },
        setItem: (state, action: PayloadAction<EncumbranceFullType>) => {
            state.item = action.payload
        },
        setCreateItem: (state, action: PayloadAction<CreateItemDataType>) => {
            state.createItemData = action.payload
        },
        clearData: (state) => {
            state.items = []
            state.paging = undefined
            state.item = null
            state.createItemData = null
            state.isLoading = false
            state.error = null
        },
        getItem: (_, action: PayloadAction<string>) => {},
        createEncumbrance: (_, action: PayloadAction<string>) => {},
        removeEncumbrance: (_, action: PayloadAction<RemoveEncumbranceType>) => {},
        getItems: (_, action: PayloadAction<TablePaginationParam>) => {},
    },
})

export default walletEncumbranceSlice.reducer
