import {
    ExternalRateIndex,
    ICalcFeeRequestProps,
    ICalcFeeResponse,
    IIndexRate,
    ResponseItem,
    SignTransactionFullType,
} from '@dltru/dfa-models'
import { signTransaction, signTransactionWithData } from '@dltru/dfa-sign'
import { call, select } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'

import api from '@services/api'

export function* signData(
    status: string,
    payloadSkid?: string,
    data?: ResponseItem<SignTransactionFullType>,
) {
    if (status === 'new' && payloadSkid && data) {
        const userCertificates = yield* select(authSelector.selectUserCertificates)
        const findedCertificate = userCertificates?.find(
            ({ skid }) => payloadSkid.toLowerCase() === skid.toLowerCase(),
        )

        yield* call(signTransaction, findedCertificate, data.item, api.lib.putTransaction)
    }
}

type Data = Record<string, unknown> & { transaction: SignTransactionFullType }
export function* signCustomTransaction(payloadSkid: string, data: Data, api: (data: Data) => void) {
    if (data.transaction.status === 'new') {
        const userCertificates = yield* select(authSelector.selectUserCertificates)
        const findedCertificate = userCertificates?.find(
            ({ skid }) => payloadSkid.toLowerCase() === skid.toLowerCase(),
        )
        yield* call(signTransactionWithData, findedCertificate, data, api)
    }
}

export async function getCalculateFeeHelper(
    payload: ICalcFeeRequestProps,
): Promise<ResponseItem<ICalcFeeResponse>> {
    try {
        const { data, error } = await api.lib.calculateFeeService(payload)

        if (error) {
            throw new Error(error)
        }

        return data
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}

export async function getExternalRateHelper(
    index: ExternalRateIndex,
): Promise<ResponseItem<IIndexRate>> {
    try {
        const { data, error } = await api.lib.getExternalRates(index)

        if (error) {
            throw new Error(error)
        }

        return data
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}

export async function getWalletDfaOwnersCountByDfaHelper(
    asset_id: string,
): Promise<ResponseItem<IIndexRate>> {
    try {
        const { data, error } = await api.lib.getWalletDfaOwnersCountByDfa(asset_id)

        if (error) {
            throw new Error(error)
        }

        return data.item.count
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}
