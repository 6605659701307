import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const UnorderedListDot: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="4.00033" cy="3.99984" r="3.33333" fill="currentColor" />
            </svg>
        )}
        {...props}
    />
)
