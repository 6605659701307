import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const SaveAdd: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.3332 7.49168V16.9584C13.3332 18.1667 12.4665 18.675 11.4082 18.0917L8.13318 16.2667C7.78318 16.075 7.2165 16.075 6.8665 16.2667L3.5915 18.0917C2.53317 18.675 1.6665 18.1667 1.6665 16.9584V7.49168C1.6665 6.06668 2.83316 4.90002 4.25816 4.90002H10.7415C12.1665 4.90002 13.3332 6.06668 13.3332 7.49168Z"
                    fill="white"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3332 4.25828V13.725C18.3332 14.9333 17.4665 15.4416 16.4082 14.8583L13.3332 13.1416V7.49162C13.3332 6.06662 12.1665 4.89996 10.7415 4.89996H6.6665V4.25828C6.6665 2.83328 7.83316 1.66663 9.25816 1.66663H15.7415C17.1665 1.66663 18.3332 2.83328 18.3332 4.25828Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.8335 10H9.16683"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.5 11.6667V8.33337"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
