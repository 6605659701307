import { ITariffAssetLink } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { tariffRelatedAssetsSlice } from '@store/tariffRelatedAssets'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    dfaList: ITariffAssetLink[]
    setDfaList: (data: React.Key[]) => void
}

export const DropRelateModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    dfaList,
    setDfaList,
}) => {
    const reduxDispatch = useDispatch()

    const removeCertificate = () => {
        reduxDispatch(tariffRelatedAssetsSlice.actions.unlinkAssets(dfaList))
        setIsModalVisible(false)
        setDfaList([])
    }
    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            width={423}
            title={
                <Space size={16}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Отвязать выпуски от тарифного плана
                </Space>
            }
            footer={[
                <Button onClick={() => setIsModalVisible(false)} borderRadius={12} key="cancel">
                    Отмена
                </Button>,
                <Button type="primary" onClick={removeCertificate} borderRadius={12} key="ok">
                    Да, отвязать
                </Button>,
            ]}
        >
            <Box margin={[24, 0, 24, 40]}>
                <p>
                    Выбранные выпуски ЦФА будут отвязаны от данного тарифного плана. Комиссии по
                    всем операциям будут расчитываться по базовому тарифному плану или по
                    индивидуальному тарифному плану пользователя, если он назначен.
                </p>
                <p>Вы действительно хотите отвязать выпуски ЦФА от тарифного плана?</p>
            </Box>
        </MiniModal>
    )
}
