import { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'

import {
    BeneficialOwner,
    BusinessmanFormValues,
    DocumentType,
    HasBeneficiary,
    IBusinessmanProfile,
    UILicActivityType,
    UserAccountProfileDetails,
} from '../../models'
import { getDateWithoutTime, getDateWithoutTimeString } from '../../utils'
import { getInitialFile, getInitialFiles, mapFilesList } from '../common'
import { beneficiariesFrontToBack, beneficiaryBackToFront } from './beneficiary'
import {
    getBankAccountDetailsFromBackend,
    getBankAccountDetailsFromForm,
    getBankDetailsProfileFromForm,
    getBasicInformationFromBackend,
    getBasicInformationFromForm,
    getCommonDataFromBackend,
    getCommonDataFromForm,
    getInitialList,
    getLicenseFromForm,
    getLicensesFromBackend,
    getPublicOfficialFromBackend,
    getPublicOfficialFromForm,
} from './common'

export const businessmanFrontToBack = (
    valuesFromForm: BusinessmanFormValues,
): IBusinessmanProfile => {
    const passportData =
        valuesFromForm.documentType === DocumentType.passport
            ? {
                  identity_document: valuesFromForm.documentType,
                  passport: {
                      series: valuesFromForm.PassportSeries,
                      number: valuesFromForm.PassportNumber,
                      issue_date: valuesFromForm.issueDate.unix(),
                      issued_by: valuesFromForm.PassportIssuer,
                      issuer_department_code: valuesFromForm.PassportCode,
                      file_uuid: valuesFromForm.PassportPages?.map(({ uid }) => uid).join(),
                  },
              }
            : { identity_document: valuesFromForm.documentType }

    const person = {
        first_name: valuesFromForm.first_name,
        last_name: valuesFromForm.last_name,
        second_name: valuesFromForm.second_name,
        birthdate: getDateWithoutTime(valuesFromForm.birthdate) as unknown as number,
        place_birth: valuesFromForm.place_birth,
        citizenship: valuesFromForm.citizenship,
        registration_address: valuesFromForm['main_address.registration'],
        location_address:
            valuesFromForm['main_address.fact'] || valuesFromForm['main_address.registration'],
        post_address: valuesFromForm['main_address.index'],
        inn: valuesFromForm.inn,
        inn_file_uuid: valuesFromForm.inn_file?.map(({ uid }) => uid).join(),
        snils: valuesFromForm.snils,
        snils_file_uuid: valuesFromForm.snils_file?.map(({ uid }) => uid).join(),
        identity_document_file_uuid: valuesFromForm.PassportPages?.map(({ uid }) => uid).join(),
        ...passportData,
    }
    const business_info = {
        ogrn: valuesFromForm.ogrnip,
        state_registration_file_uuids: valuesFromForm.ogrn_file_uuid?.map(({ uid }) => uid),
        state_registration: valuesFromForm.registration_certificate,
        ogrn_date: valuesFromForm.ogrn_date?.unix(),
        registration_authority: valuesFromForm.registration_authority,
        //registration_address,
        websites: valuesFromForm.websites?.map(({ website }) => website).filter(Boolean),
        okved: valuesFromForm.okveds?.map(({ okved }) => okved).filter(Boolean),
        lic_activities: getLicenseFromForm(valuesFromForm.lic_activities),
        business_reputations: valuesFromForm.business_reputations?.map((item) => ({
            ...item,
            file_uuids: item.file_uuids?.map(({ uid }) => uid),
        })),
        basic_information: getBasicInformationFromForm(valuesFromForm),
    }

    return {
        person,
        business_info,
        bank_details: getBankDetailsProfileFromForm(valuesFromForm),
        bank_account_details: getBankAccountDetailsFromForm(
            valuesFromForm,
            `ИП ${valuesFromForm.last_name} ${valuesFromForm.first_name}${
                valuesFromForm.second_name ? ` ${valuesFromForm.second_name}` : ''
            }`,
        ),
        ...getCommonDataFromForm(valuesFromForm),
        public_official: getPublicOfficialFromForm(valuesFromForm),
    }
}

export const businessmanBackToFront = (
    anketaFromBackend: IBusinessmanProfile,
    beneficialOwners?: BeneficialOwner[],
): BusinessmanFormValues => {
    const passportData =
        anketaFromBackend.person?.identity_document === DocumentType.passport
            ? {
                  documentType: anketaFromBackend.person.identity_document,
                  PassportSeries: anketaFromBackend.person.passport.series,
                  PassportNumber: anketaFromBackend.person.passport.number,
                  issueDate: moment.unix(anketaFromBackend.person.passport.issue_date),
                  PassportIssuer: anketaFromBackend.person.passport.issued_by,
                  PassportCode: anketaFromBackend.person.passport.issuer_department_code,
                  PassportPages: mapFilesList(
                      'PassportPages',
                      anketaFromBackend.person.identity_document_file_uuid,
                  ),
              }
            : {
                  documentType: anketaFromBackend.person?.identity_document,
              }
    return {
        id: anketaFromBackend.id,
        last_name: anketaFromBackend.person.last_name,
        first_name: anketaFromBackend.person.first_name,
        second_name: anketaFromBackend.person.second_name ?? '',
        birthdate: getDateWithoutTimeString(anketaFromBackend.person.birthdate),
        place_birth: anketaFromBackend.person.place_birth,
        citizenship: anketaFromBackend.person.citizenship,
        'main_address.registration': anketaFromBackend.person.registration_address,
        'main_address.fact': anketaFromBackend.person.location_address,
        'main_address.index': anketaFromBackend.person.post_address,
        inn: anketaFromBackend.person.inn,
        inn_file: getInitialFile(anketaFromBackend.person.inn_file_uuid),
        snils: anketaFromBackend.person.snils,
        snils_file: getInitialFile(anketaFromBackend.person.snils_file_uuid),
        ogrnip: anketaFromBackend.business_info.ogrn,
        ogrn_date: moment.unix(anketaFromBackend.business_info.ogrn_date),
        registration_authority: anketaFromBackend.business_info.registration_authority,
        ogrn_file_uuid: getInitialFiles(
            anketaFromBackend.business_info.state_registration_file_uuids,
        ) as UploadFile[],
        ...passportData,
        registration_certificate: anketaFromBackend.business_info.state_registration,
        websites: getInitialList('website', anketaFromBackend.business_info.websites),
        okveds: getInitialList('okved', anketaFromBackend.business_info.okved),
        beneficiaries: beneficialOwners?.map(beneficiaryBackToFront),
        hasBeneficiary: anketaFromBackend.beneficial_owners_uuids?.length
            ? HasBeneficiary.YES
            : HasBeneficiary.NO,
        ...getBankAccountDetailsFromBackend(
            anketaFromBackend.bank_account_details ??
                anketaFromBackend?.user_bank_account_details?.bank_account_details,
        ),
        ...getPublicOfficialFromBackend(anketaFromBackend.public_official),
        ...getBasicInformationFromBackend(anketaFromBackend.business_info.basic_information),
        ...getCommonDataFromBackend(anketaFromBackend),
        lic_activities: getLicensesFromBackend(
            anketaFromBackend.business_info.lic_activities,
        ) as UILicActivityType[],
        business_reputations: anketaFromBackend.business_info.business_reputations?.map((item) => ({
            ...item,
            file_uuids: getInitialFiles(item.file_uuids) as UploadFile[],
        })),
    }
}

export const businessmanForUpdateData = (
    valuesFromForm: BusinessmanFormValues,
    oldProfileFromBackend: IBusinessmanProfile,
) => {
    const newProfile = businessmanFrontToBack(valuesFromForm)

    const account_details = {
        ...oldProfileFromBackend.user_bank_account_details?.bank_account_details,
        ...newProfile.bank_account_details,
    }

    return {
        businessman: {
            ...oldProfileFromBackend,
            ...newProfile,
            node: undefined,
        },
        linked_beneficiaries:
            valuesFromForm.beneficiaries && valuesFromForm.hasBeneficiary === HasBeneficiary.YES
                ? beneficiariesFrontToBack(valuesFromForm.beneficiaries)
                : undefined,
        user_account_details: [
            {
                ...oldProfileFromBackend.user_bank_account_details,
                bank_account_details: undefined,
                account_details,
            } as UserAccountProfileDetails,
        ],
    }
}
