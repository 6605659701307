import { IDfaFront } from '@dltru/dfa-models'
import { createSelector } from 'reselect'

import { clientsSelector } from '@store/clients/selectors'

import IAppState from '../../states'

const findDfaById =
    (dfaId: string) =>
    (state: IAppState): IDfaFront | undefined => {
        return state.dfaList.data?.items?.find((value) => value.id === dfaId)
    }
const selectIsLoading = (state: IAppState) => state.dfaList.isLoading
const selectList = (state: IAppState) => state.dfaList.data
const selectParams = (state: IAppState) => state.dfaList.params
const getDfasByCurrentClient = createSelector(
    selectList,
    clientsSelector.selectCurrentClientId,
    (dfas, сurrentClientId) => {
        if (сurrentClientId) {
            return dfas?.items?.filter((dfa) => dfa.emitter_id === сurrentClientId)
        }
        return []
    },
)

export const dfaListSelectors = {
    findDfaById,
    selectIsLoading,
    selectList,
    getDfasByCurrentClient,
    selectParams,
}
