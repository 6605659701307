import { UnionOrderType } from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateList as initialState } from '../../../../../dfa-web-client/src/store/orders/resources'

const orderListSlice = createSlice({
    name: 'orderList',
    initialState,
    reducers: {
        getOrderList: (state) => {
            return state
        },
        updateOrderList: (state, action) => {
            if (action.payload.data && action.payload.data.length) {
                action.payload.data.forEach((data: any) => {
                    data.Sum = data.CPE && data.Amount ? data.CPE * data.Amount : 0
                })
            }
            return { ...state, ...action.payload }
        },
        updateOrderByOrderId: (state, action: PayloadAction<UnionOrderType>) => {
            state.data = state.data?.map((order) =>
                order.id === action.payload.id
                    ? ({ ...order, ...action.payload } as UnionOrderType)
                    : (order as UnionOrderType),
            )
        },
        deleteOrderById: (state, action) => {
            if (state.data.length) {
                state.data = state.data.filter((v) => v.id !== action.payload)
            }
        },
        dropOrderList: () => {
            return { ...initialState }
        },
        updateErrorOrderList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingOrderList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadDfasOrders = createAction('dfaOrders/loadDfasOrders')
export const loadOrdersByDfaId = createAction('dfaOrders/loadOrdersByDfaId')
export const loadAllOrdersByUser = createAction<string>('dfaOrders/loadAllOrdersByUser')
export const {
    updateOrderList,
    getOrderList,
    dropOrderList,
    updateOrderByOrderId,
    updateErrorOrderList,
    updateLoadingOrderList,
    deleteOrderById,
} = orderListSlice.actions
export default orderListSlice.reducer
