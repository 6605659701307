import { TransactionDataFront } from '@dltru/dfa-models'
import { FC } from 'react'

import { getRublesFromPennies } from '../../../utils'
import style from './style.m.less'

const DEFAULT_SYMBOL = '₽'

type ComponentProps = {
    symbol?: string
} & TransactionDataFront['summary']

export const DateSummaryRow: FC<ComponentProps> = ({
    title,
    subtitle,
    total,
    symbol = DEFAULT_SYMBOL,
}) => {
    return (
        <div className={style.blRow}>
            <div className={style.blLeftWrapper}>
                <div className={style.blDateTitle}>{title}</div>
                {subtitle && <div className={style.blDateSubTitle}>{subtitle}</div>}
            </div>
            <div className={style.blRightWrapper}>
                <div className={style.blSummaryTotal}>
                    {total > 0 ? '+' : ''}
                    {getRublesFromPennies(total)}
                    {` ${symbol}`}
                </div>
            </div>
        </div>
    )
}
