import { ProfileStatus, ProfileType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, EditPenIcon } from '@dltru/dfa-ui'
import React, { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'

import { RevisionModal } from '../modals/RevisionModal'

interface IComponentProps {
    profileType: ProfileType
    profileStatus: ProfileStatus
    profileUuid: string
}
export const SendToEditAction: FC<IComponentProps> = ({
    profileType,
    profileStatus,
    profileUuid,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { id } = useParams()
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()

    const isVisibleBtn =
        id &&
        ((profileStatus === ProfileStatus.OPERATOR_APPROVAL && isAllow(['approval.operator'])) ||
            (profileStatus === ProfileStatus.LAWYER_APPROVAL && isAllow(['approval.lawyer'])) ||
            (profileStatus === ProfileStatus.PODFT_APPROVAL && isAllow(['approval.aml'])))

    if (!isVisibleBtn) {
        return null
    }
    const changeStatus = (comment: string) => {
        reduxDispatch(
            clientsSlice.actions.changeClientStatus({
                profileType,
                profileUuid,
                userUuid: id,
                comment,
                status: ProfileStatus.REWORK,
            }),
        )
        setIsModalVisible(false)
    }
    return (
        <>
            <Button
                icon={<EditPenIcon />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                На доработку
            </Button>
            <RevisionModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={changeStatus}
            />
        </>
    )
}
