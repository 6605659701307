import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const FileDone: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.5 7.75V6.25C17.5 6.1125 17.3875 6 17.25 6H5.25C5.1125 6 5 6.1125 5 6.25V7.75C5 7.8875 5.1125 8 5.25 8H17.25C17.3875 8 17.5 7.8875 17.5 7.75ZM5.25 10.5C5.1125 10.5 5 10.6125 5 10.75V12.25C5 12.3875 5.1125 12.5 5.25 12.5H11C11.1375 12.5 11.25 12.3875 11.25 12.25V10.75C11.25 10.6125 11.1375 10.5 11 10.5H5.25ZM17 14.125C13.2719 14.125 10.25 17.1469 10.25 20.875C10.25 24.6031 13.2719 27.625 17 27.625C20.7281 27.625 23.75 24.6031 23.75 20.875C23.75 17.1469 20.7281 14.125 17 14.125ZM20.3594 24.2344C19.4625 25.1313 18.2687 25.625 17 25.625C15.7312 25.625 14.5375 25.1313 13.6406 24.2344C12.7437 23.3375 12.25 22.1437 12.25 20.875C12.25 19.6063 12.7437 18.4125 13.6406 17.5156C14.5375 16.6187 15.7312 16.125 17 16.125C18.2687 16.125 19.4625 16.6187 20.3594 17.5156C21.2563 18.4125 21.75 19.6063 21.75 20.875C21.75 22.1437 21.2563 23.3375 20.3594 24.2344ZM19.7812 18.5H18.3969C18.3156 18.5 18.2406 18.5375 18.1938 18.6031L16.2094 21.3469L15.4875 20.35C15.4644 20.3178 15.4339 20.2916 15.3985 20.2737C15.3632 20.2557 15.324 20.2465 15.2844 20.2469H13.9062C13.7031 20.2469 13.5844 20.4781 13.7031 20.6437L16.0094 23.8344C16.1094 23.9719 16.3125 23.9719 16.4125 23.8344L19.9813 18.8969C20.1031 18.7313 19.9844 18.5 19.7812 18.5ZM9.75 24.625H2.5V2.625H20V13.375C20 13.5125 20.1125 13.625 20.25 13.625H22C22.1375 13.625 22.25 13.5125 22.25 13.375V1.375C22.25 0.821875 21.8031 0.375 21.25 0.375H1.25C0.696875 0.375 0.25 0.821875 0.25 1.375V25.875C0.25 26.4281 0.696875 26.875 1.25 26.875H9.75C9.8875 26.875 10 26.7625 10 26.625V24.875C10 24.7375 9.8875 24.625 9.75 24.625Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
