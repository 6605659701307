import { DatePicker } from 'antd'
import { FC } from 'react'

import { LOCALE } from '../config/locale'
import { datePeriodFilter } from '../utils/datePickerHelper'
import { DateRangeItemProps, PLACEHOLDER } from './type'

const DateRangeItem: FC<DateRangeItemProps> = ({
    disabled,
    getPopupContainer,
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
}) => {
    const filter = datePeriodFilter({
        onlyWorkDays,
        onlyPast,
        onlyFuture,
        onlyBeforeDate,
        onlyBeforeDateAndToday,
    })

    return (
        <DatePicker.RangePicker
            disabled={disabled}
            disabledDate={filter}
            placeholder={PLACEHOLDER}
            format="DD.MM.YYYY"
            locale={LOCALE}
            showTime
            getPopupContainer={getPopupContainer}
        />
    )
}

export default DateRangeItem
