import { Button, UnLockIcon, getFullName } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { UnBlockClientModal } from '../../BlockClient'

export const UnBlockClientAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const profile = useSelector(clientsSelector.selectProfile)
    const isCurrentClientPartlyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientPartlyBlocked,
    )
    const isCurrentClientFullyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientFullyBlocked,
    )
    const isVisibleUnblockClient = isCurrentClientPartlyBlocked || isCurrentClientFullyBlocked
    if (!isVisibleUnblockClient) {
        return null
    }
    return (
        <>
            <Button icon={<UnLockIcon />} borderRadius={12} onClick={() => setIsModalVisible(true)}>
                Снять блокирование
            </Button>
            <UnBlockClientModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                userFullName={getFullName(profile)}
                userUID={profile?.user_uuid}
            />
        </>
    )
}
