import { Box, MoneyOut } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from './priceInfoCard.m.less'

interface IPriceInfoCard {
    price?: number
}

export const PriceInfoCard: FC<IPriceInfoCard> = ({ price }) => {
    if (!price) {
        return null
    }

    return (
        <Box
            padding={[0, 24, 24, 24]}
            margin={[0, 0, 8, 0]}
            className="tablebox tablebox--auto-height tablebox--shadow"
        >
            <div style={{ marginTop: 16 }} className={style.priceContainer}>
                <div className={style.priceTitle}>
                    <MoneyOut />
                    <span style={{ marginLeft: 10 }}>{'Денежные средства'}</span>
                </div>
                <div className={style.priceValue}>
                    <span>{price} ₽</span>
                </div>
            </div>
        </Box>
    )
}
