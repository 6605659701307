import { createSelector } from '@reduxjs/toolkit'
import IAppState from 'store/states'

const selectData = (state: IAppState) => state.dfaOwnershipTransfer.data
const selectDealsIsLoading = (state: IAppState) => state.dfaOwnershipTransfer.dealsIsLoading
const selectBuyerData = (state: IAppState) => state.dfaOwnershipTransfer.buyerData
const selectOfferentOffer = (state: IAppState) => state.dfaOwnershipTransfer.offerentOffer
const selectAcceptorOffer = (state: IAppState) => state.dfaOwnershipTransfer.accepterOffer
const selectTransferDeals = (state: IAppState) => state.dfaOwnershipTransfer.deals

const selectOfferByType = createSelector(
    [selectOfferentOffer, selectAcceptorOffer, (_, type: 'acceptor' | 'offerent') => type],
    (offerentOffer, accepterOffer, type) => {
        return type === 'acceptor' ? accepterOffer : offerentOffer
    },
)

export const dfaTransferSelector = {
    selectData,
    selectBuyerData,
    selectOfferentOffer,
    selectAcceptorOffer,
    selectOfferByType,
    selectTransferDeals,
    selectDealsIsLoading,
}
