import {
    BusinessmanFormValues,
    HasBeneficiary,
    IBusinessmanProfile,
    ProfileStatus,
    ProfileType,
    afterApprovalProfileStatuses,
    businessmanForUpdateData,
} from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, CopyButton, PageHeader, Tag, checkBeneficialUuids, getFullName } from '@dltru/dfa-ui'
import { getAnketaTagProps } from '@resources/anketaStatus'
import { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { Footer } from '../components/Footer'
import { BusinessmanStepper } from './BusinessmanStepper'
import { disableFields } from './resources'

export const Businessman: FC = () => {
    const navigate = useNavigate()
    const { isAllow } = useContext(RbacContext)
    const initialData = useSelector(clientsSelector.selectBusinessmanFormValue)
    const oldProfile = useSelector(clientsSelector.selectProfile) as IBusinessmanProfile
    const reduxDispatch = useDispatch()

    const onStepperFinish = (values: BusinessmanFormValues) => {
        const dataForSend = businessmanForUpdateData(values, oldProfile)
        reduxDispatch(clientsSlice.actions.updateBusinessmanProfile(dataForSend))
    }

    useEffect(() => {
        if (initialData?.hasBeneficiary === HasBeneficiary.YES) {
            reduxDispatch(clientsSlice.actions.getProfilesBeneficiaries())
        }
    }, [JSON.stringify(oldProfile?.beneficial_owners_uuids)])

    if (
        !initialData ||
        !oldProfile?.user_uuid ||
        !oldProfile.profile_status ||
        (initialData.hasBeneficiary === HasBeneficiary.YES &&
            !checkBeneficialUuids(oldProfile?.beneficial_owners_uuids, initialData.beneficiaries))
    ) {
        return null
    }
    const tagProps = getAnketaTagProps(oldProfile.profile_status, isAllow)

    const isApprovedStatuses = afterApprovalProfileStatuses.includes(oldProfile.profile_status)
    const isEditAllow =
        (oldProfile.profile_status === ProfileStatus.OPERATOR_APPROVAL &&
            isAllow(['approval.operator'])) ||
        (oldProfile.profile_status === ProfileStatus.LAWYER_APPROVAL &&
            isAllow(['approval.lawyer'])) ||
        (oldProfile.profile_status === ProfileStatus.PODFT_APPROVAL && isAllow(['approval.aml'])) ||
        (isApprovedStatuses && isAllow(['user.write.all']))

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Анкета клиента: ${getFullName(oldProfile)}`}
                    tags={<Tag color={tagProps.color}>{tagProps.title}</Tag>}
                    onBack={() => navigate(-1)}
                    subTitle={
                        oldProfile?.user_uuid ? (
                            <>
                                ID {oldProfile.user_uuid}&ensp;
                                <CopyButton text={oldProfile.user_uuid} />
                            </>
                        ) : undefined
                    }
                />
            </Box>
            <BusinessmanStepper
                initialValues={initialData}
                onStepperFinish={onStepperFinish}
                initialStep={20}
                isEditAllow={isEditAllow}
                disableFields={isApprovedStatuses ? disableFields : undefined}
            />
            <Footer
                profileType={ProfileType.BUSN}
                profileUuid={oldProfile.user_uuid}
                profileStatus={oldProfile.profile_status}
            />
        </>
    )
}
