import { FC } from 'react'

import {
    CheckCircleIcon,
    CheckCircleOutlineIcon,
    CloseCircleIcon,
    CloseCircleOutlineIcon,
    ExclamationCircle,
    ExclamationCircleOutline,
    InfoCircle,
    InfoCircleOutline,
} from '../Icons'
import { AlertProps } from './Alert'

interface AlertIconProps {
    type: AlertProps['type']
    isBig?: boolean
}
export const AlertIcon: FC<AlertIconProps> = ({ type, isBig }) => {
    switch (type) {
        case 'success':
            return isBig ? (
                <CheckCircleOutlineIcon className="ant-alert-icon" />
            ) : (
                <CheckCircleIcon className="ant-alert-icon" />
            )
        case 'error':
            return isBig ? (
                <CloseCircleOutlineIcon className="ant-alert-icon" />
            ) : (
                <CloseCircleIcon className="ant-alert-icon" />
            )
        case 'warning':
            return isBig ? (
                <ExclamationCircleOutline className="ant-alert-icon" />
            ) : (
                <ExclamationCircle className="ant-alert-icon" />
            )
        case 'info':
            return isBig ? (
                <InfoCircleOutline className="ant-alert-icon" />
            ) : (
                <InfoCircle className="ant-alert-icon" />
            )
        default:
            return isBig ? (
                <InfoCircleOutline className="ant-alert-icon" />
            ) : (
                <InfoCircle className="ant-alert-icon" />
            )
    }
}
