import { MoneyAccountType } from '@dltru/dfa-models'
import { FC } from 'react'

import { Divider } from '../../../Divider'
import { Requisites } from '../../../Requisites/Requisites'
import { CopyButton } from '../../../button'
import { bankReq, orgReq } from '../../../constants/dltr.requisites'
import style from './infoStyle.m.less'

export const CommonInfoRefill: FC<{ account: MoneyAccountType }> = ({ account }) => {
    const purposePayment = `Пополнение собственного Лицевого счета № ${account?.id}. НДС не облагается`
    const bankRequisites = bankReq(account?.nominal_account?.account_details)
    const orgRequisites = orgReq(account?.nominal_account?.account_details)

    return (
        <>
            <div className={style.body}>
                <Requisites requisites={bankRequisites} copyEnabled />

                <Divider margin={[16, 0]} />
                <Requisites requisites={orgRequisites} copyEnabled />
                <Divider margin={[16, 0]} />
                <div className={style.wrapper}>
                    <div className={style.Row}>
                        <span className={style.RowLabel}>Назначение платежа</span>
                        <div className={style.RowValue}>
                            <div className={style.targetContainer}>
                                <span className={style.targetBill}>{purposePayment}</span>
                                <CopyButton text={purposePayment} color="volcano" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.footer}>
                <p>
                    Зачисление денежных средств на счёт в течение 1 рабочего дня. <br />
                    Комиссия не взимается.
                </p>
            </div>
        </>
    )
}
