import { getPennies } from '@dltru/dfa-models'
import { Button, Modal } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { reserveReleaseSau, reserveTransferSau } from '@store/accountSau'

import {
    DebitBankAccountBody,
    IDebitBankAccountValues,
} from '@pages/ClientCard/components/Balance/components/Debit/DebitBankAccountBody'
import {
    DebitUserSelectBody,
    IDebitUserSelectValues,
} from '@pages/ClientCard/components/Balance/components/Debit/DebitUserSelectBody'

import { DebitFormBody, DebitType, IDebitFormValues } from './DebitFormBody'
import style from './style.m.less'

const enum BodyType {
    'main',
    'bankRequisites',
    'userSelect',
}
export const Debit: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentBody, setCurrentBody] = useState(BodyType.main)
    const [bankAccount, setBankAccount] = useState<IDebitBankAccountValues>()
    const [userData, setUserData] = useState<IDebitUserSelectValues>()
    const [debitData, setDebitData] = useState<Partial<IDebitFormValues>>()
    const reduxDispatch = useDispatch()

    const onCloseModal = () => {
        setIsModalVisible(false)
        setBankAccount(undefined)
        setUserData(undefined)
        setDebitData(undefined)
        setCurrentBody(BodyType.main)
    }
    const sendDebit = (values: IDebitFormValues) => {
        if (values.debitType === DebitType.bankAccount && bankAccount) {
            reduxDispatch(
                reserveReleaseSau({
                    amount: getPennies(values.debitSum),
                    purpose: values.debitReason,
                    attachment_uuid: values.debitReasonFile?.[0]?.uid,
                    user_id: '',
                    ...bankAccount,
                }),
            )
            onCloseModal()
        }
        if (values.debitType === DebitType.moneyAccount && userData) {
            reduxDispatch(
                reserveTransferSau({
                    amount: getPennies(values.debitSum),
                    debiting_reason: values.debitReason,
                    debiting_reason_attachment_uuid: values.debitReasonFile?.[0]?.uid,
                    recipient_id: userData.user_uuid,
                    sender_id: '',
                }),
            )
            onCloseModal()
        }
    }

    return (
        <>
            <Button
                onClick={() => setIsModalVisible(true)}
                style={{ marginRight: 16, borderRadius: 12 }}
            >
                Списать средства
            </Button>
            <Modal
                visible={isModalVisible}
                width={522}
                onCancel={onCloseModal}
                className={style.modal}
                destroyOnClose
            >
                <div className={style.wrapper}>
                    <div className={style.header}>Списание средств</div>

                    <TransitionGroup className="slide-animation__block">
                        {currentBody === BodyType.main && (
                            <CSSTransition timeout={300} classNames="slide-animation">
                                <DebitFormBody
                                    sendDebit={sendDebit}
                                    setIsModalVisible={setIsModalVisible}
                                    changeBodyToBankAccount={() =>
                                        setCurrentBody(BodyType.bankRequisites)
                                    }
                                    changeBodyToSelectUser={() =>
                                        setCurrentBody(BodyType.userSelect)
                                    }
                                    isFilledBankRequisites={Boolean(bankAccount)}
                                    isFilledUserData={Boolean(userData)}
                                    saveDebitData={setDebitData}
                                    initialValues={debitData}
                                />
                            </CSSTransition>
                        )}

                        {currentBody === BodyType.bankRequisites && (
                            <CSSTransition timeout={300} classNames="slide-animation">
                                <DebitBankAccountBody
                                    saveBankAccount={setBankAccount}
                                    changeBodyToMain={() => setCurrentBody(BodyType.main)}
                                    initialValues={bankAccount ? bankAccount : {}}
                                />
                            </CSSTransition>
                        )}
                        {currentBody === BodyType.userSelect && (
                            <CSSTransition timeout={300} classNames="slide-animation">
                                <DebitUserSelectBody
                                    saveUserData={setUserData}
                                    changeBodyToMain={() => setCurrentBody(BodyType.main)}
                                    initialValues={userData ? userData : {}}
                                />
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                </div>
            </Modal>
        </>
    )
}
