import { DfaStatusEnum, SettlementsType } from '@dltru/dfa-models'
import { BrifcaseTick, Button } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { RepaymentMatureModal } from '../Modals'

export const RepaymentMatureAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    if (
        dfaDetails.status !== DfaStatusEnum.mature ||
        dfaDetails.repayment_settlements_type !== SettlementsType.direct
    ) {
        return null
    }

    return (
        <>
            <Button
                borderRadius={12}
                icon={<BrifcaseTick />}
                onClick={() => setIsModalVisible(true)}
            >
                Погасить выпуск
            </Button>
            <RepaymentMatureModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
