import { IInvestorDeals, InvestorDealsMeta, TablePaginationParam } from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

export interface IInvestorsDealState {
    isLoading: boolean
    data: IInvestorDeals | undefined
    error?: string
}

export const initialState: IInvestorsDealState = {
    isLoading: false,
    data: undefined,
    error: '',
}

export const investorsDealSlice = createSlice({
    name: 'investorDeals',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<IInvestorsDealState['error']>) {
            state.error = action.payload
        },
        dropError(state) {
            state.error = ''
        },
        setInvestorsDeal(state, action: PayloadAction<IInvestorDeals>) {
            state.error = ''
            state.isLoading = false
            state.data = action.payload
        },
        dropInvestorsDeals() {
            return { ...initialState }
        },
    },
})

export const getinvestorsDealByUuid = createAction<string>('investorsDeals/getinvestorsDealByUuid')

export default investorsDealSlice.reducer
