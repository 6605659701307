import { CertificateStatus, CertificateType } from '@dltru/dfa-models'
import clsx from 'clsx'
import { FC } from 'react'

import { ClockIcon, StampIcon } from '../../../Icons'
import { Button } from '../../../button'
import style from './CertificateButton.m.less'

export interface ICertificateButtonProps {
    certificate: CertificateType
    onClick?: () => void
}
export const CertificateButton: FC<ICertificateButtonProps> = ({ certificate, onClick }) => {
    const isWaiting = certificate.status === CertificateStatus.registered
    const nameClass = clsx(style.nameButton, { [style.grayButton]: isWaiting })
    return (
        <Button
            size="small"
            type="link"
            icon={isWaiting ? <ClockIcon /> : <StampIcon />}
            className={nameClass}
            onClick={onClick}
            tooltip={isWaiting ? 'Идет регистрация сертификата в системе.' : undefined}
        >
            {certificate.meta.SzOID_COMMON_NAME}
        </Button>
    )
}
