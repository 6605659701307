import { Modal as AntModal, ModalProps as AntModalProps } from 'antd'
import cn from 'classnames'
import { FC } from 'react'

import { CloseIcon } from '../../../Icons'
import './styles.less'

export type ModalProps = AntModalProps

export const Modal: FC<ModalProps> = ({
    visible,
    children,
    forceRender,
    className,
    footer,
    onOk,
    ...rest
}) => {
    const defaultFooter = onOk ? footer : null
    return (
        <AntModal
            {...rest}
            onOk={onOk}
            footer={footer ?? defaultFooter}
            className={cn('dfa-modal', className)}
            visible={visible}
            forceRender={forceRender}
            closeIcon={<CloseIcon className="dfa-modal__close-icon" />}
        >
            {forceRender ? children : visible && children}
        </AntModal>
    )
}
