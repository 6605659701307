import { Box, Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { rejectApplication } from '@store/dfa/details'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

type FormValuesType = {
    reason?: string
}
export const RejectApplicationModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const [form] = Form.useForm<FormValuesType>()

    const reduxDispatch = useDispatch()
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const onOk = (values: FormValuesType) => {
        reduxDispatch(rejectApplication(values))
        onCancel()
    }

    return (
        <Modal
            title="Отклонить заявку на выпуск ЦФА"
            visible={isModalVisible}
            onCancel={onCancel}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button borderRadius={12} type="danger" onClick={form.submit}>
                    Да, отклонить
                </Button>,
            ]}
        >
            <div>
                <p>Вы действительно хотите отклонить заявку на выпуск ЦФА?</p>
                <Divider />
                <Box padding={[0, 0, 16, 0]}>
                    <h3 className="page-content-header">Причина отказа</h3>
                </Box>
                <Form form={form} layout="vertical" onFinish={onOk}>
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="reason"
                        multiline
                        rows={5}
                    />
                </Form>
            </div>
        </Modal>
    )
}
