import { RbacContext } from '@dltru/dfa-rbac'
import { FC, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { clientsSlice } from '@store/clients'

import { CheckInList } from './CheckInList'

// import { CommonCheck } from './CommonCheck'
// import { BeneficiaryCheck } from './BeneficiaryCheck'
import { CheckRisk } from './CheckRisk'

export const AdditionalInfoForAdmin: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const showAdditionalInfo = isAllow(['approval.aml'])
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (showAdditionalInfo) {
            reduxDispatch(clientsSlice.actions.getCurrentClientAdditionalInfo())
        }
    }, [showAdditionalInfo])

    if (!showAdditionalInfo) {
        return null
    }

    return (
        <>
            {/*<CommonCheck />
            <BeneficiaryCheck />*/}
            {showAdditionalInfo && <CheckInList />}
            <CheckRisk />
        </>
    )
}
