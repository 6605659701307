import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const DocumentPrevious: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/*<path d="M10.8333 12.5H5.83331L7.49998 14.1667" fill="white" />*/}
                <path
                    d="M10.8333 12.5H5.83331L7.49998 14.1667"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83331 12.5L7.49998 10.8334"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3334 8.33335V12.5C18.3334 16.6667 16.6667 18.3334 12.5 18.3334H7.50002C3.33335 18.3334 1.66669 16.6667 1.66669 12.5V7.50002C1.66669 3.33335 3.33335 1.66669 7.50002 1.66669H11.6667"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3334 8.33335H15C12.5 8.33335 11.6667 7.50002 11.6667 5.00002V1.66669L15 5.00002L18.3334 8.33335Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
