export type InvestorRegistryApplicationStatus =
    | 'draft'
    | 'operator_approval'
    | 'lawyer_approval'
    | 'declined'
    | 'approved'
    | 'under_revision'

export const InvestorRegistryApplicationStatusTranslate: {
    [k in InvestorRegistryApplicationStatus]: string
} = {
    draft: 'Черновик',
    operator_approval: 'На согласовании ПОД/ФТ',
    lawyer_approval: 'На согласовании Юрист',
    declined: 'Отклонена',
    approved: 'Согласована',
    under_revision: 'На доработке',
}

export const InvestorRegistryApplicationStatusTranslateUser: {
    [k in InvestorRegistryApplicationStatus]: string
} = {
    draft: 'Черновик',
    operator_approval: 'На согласовании',
    lawyer_approval: 'На согласовании',
    declined: 'Отклонена',
    approved: 'Согласована',
    under_revision: 'На доработке',
}

export type InvestorRegistryApplicationInitiatorType = 'investor' | 'operator'

export type InvestorRegistryApplicationInitiatorApplicationType =
    | 'inclusion'
    | 'exclusion'
    | 'report'

//Информация о заявках из Реестра инвесторов
export interface InvestorRegistryApplication {
    //example: 00000000-1111-2222-3333-782b466ce913
    //Идентификатор заявки
    uuid?: string

    //example: 00000000-1111-2222-3333-782b466ce913
    //Идентификатор инвестора
    user_uuid: string

    //example: Обоснование заявки
    //Обоснование заявки
    reason_description: string

    //example: Иванов Иван Иванович
    //Наименование пользователя (ФИО либо название организации)
    user_name: string

    //example: operator
    //Тип инициатора заявки
    initiator_type: InvestorRegistryApplicationInitiatorType

    //Статус заявки
    status: InvestorRegistryApplicationStatus

    //Тип Заявки
    application_type: InvestorRegistryApplicationInitiatorApplicationType

    //Возможность редактирования заявки
    editable: boolean

    //Комментарий к заявке
    comment: string

    //Дата подачи заявки
    //example: 1661235116
    created_at: number

    // example: 1661235116
    // Дата обновления заявки
    updated_at: number

    //example: 1661235116
    //Дата закрытия заявки
    closed_at: number
}

//Заявка Реестра инвесторов
export interface InvestorRegistryApplicationEvent {
    // Идентификатор истории согласования заявки
    // example: 00000000-1111-2222-3333-782b466ce913
    uuid: string

    //Идентификатор заявки
    //example: 00000000-1111-2222-3333-782b466ce913
    application_uuid: string

    //Сотрудник, cогласующий заявку
    //example: 00000000-1111-2222-3333-782b466ce913
    operator_uuid: string

    //Роль сотрудника
    //example: operator
    operator_role: InvestorRegistryApplicationInitiatorType

    //Текущий статус заявки
    //example: approved
    current_status: InvestorRegistryApplicationStatus

    //Предыдущий статус заявки
    //example: approved
    previous_status: InvestorRegistryApplicationStatus

    //Комментарии к заявке
    //example: Комментарии к заявке
    comment: string

    //Дата подачи заявки
    //example: 1661235116
    created_at: number
}

export type InvestorRegistryApplicationDocumentItem = {
    link_uuid: string
    name: string
}

export interface IInvestorRegistryApplicationPost {
    user_uuid: string
    user_name: string
    initiator_type: InvestorRegistryApplicationInitiatorType
    application_type: InvestorRegistryApplicationInitiatorApplicationType
    reason_description: string
    documents: {
        items: InvestorRegistryApplicationDocumentItem[]
    }
}

export interface IInvestorRegistryApplicationPut {
    application_uuid: string
    operator_uuid: string
    operator_role: InvestorRegistryApplicationInitiatorType
    status: InvestorRegistryApplicationStatus
    comment: string
    documents: {
        items: InvestorRegistryApplicationDocumentItem[]
    }
}

export interface ISetQualifedResponse {
    user_uuid: string
    reason_description: string
    initiator_type: InvestorRegistryApplicationInitiatorType
    documents: string[]
}
