import {
    ISecondaryOrderShort,
    OrderTypeEnum,
    secondaryOrderStatusCancelable,
} from '@dltru/dfa-models'
import { BodyTitle, Box, Button, DeleteIcon, Space } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'

import { SecondaryRevokeModal } from '@components/Modals/Orders'

import { SecondaryCheckList } from './SecondaryCheckList'
import { SecondaryOrderStatusBlock } from './SecondaryOrderStatusBlock'

interface IComponentProps {
    setIsModalVisible: (value: boolean) => void
    secondaryDfaDetails?: ISecondaryOrderShort
}

export const SecondaryOrderDetailsModalBody: FC<IComponentProps> = ({
    secondaryDfaDetails,
    setIsModalVisible,
}) => {
    const [isRevokeConfirmShow, setIsRevokeConfirmShow] = useState(false)

    const endDate = new Date(secondaryDfaDetails?.created_at || 0)
    endDate.setDate(endDate.getDate() + 5)

    return (
        <>
            <BodyTitle
                title={`Детали ${
                    secondaryDfaDetails?.order_type === OrderTypeEnum.sell ? 'продажи' : 'покупки'
                }`}
                id={secondaryDfaDetails?.id}
            />
            <SecondaryCheckList data={secondaryDfaDetails} />
            <Box padding={[24, 0, 0, 0]}>
                <SecondaryOrderStatusBlock order={secondaryDfaDetails} />
            </Box>

            <Box padding={[24, 0, 0, 0]} align="end">
                <Space size={16}>
                    {secondaryDfaDetails?.status &&
                        secondaryOrderStatusCancelable.includes(secondaryDfaDetails?.status) && (
                            <Button
                                size="large"
                                color="default"
                                icon={<DeleteIcon />}
                                borderRadius={16}
                                onClick={() => {
                                    setIsRevokeConfirmShow(true)
                                }}
                            >
                                Снять заявку
                            </Button>
                        )}
                </Space>
            </Box>

            <SecondaryRevokeModal
                setIsParentModalVisible={setIsModalVisible}
                setIsModalVisible={setIsRevokeConfirmShow}
                isModalVisible={isRevokeConfirmShow}
                order={secondaryDfaDetails}
            />
        </>
    )
}
