import { BankAccountDetailsModel } from '@dltru/dfa-models'
import { StepperC } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { nominalAccountsSlice } from '@store/nominalAccounts'
import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import { NominalAccountFirstStep } from './NominalAccountFirstStep'

export const NominalAccountForm: FC = () => {
    const data = useSelector(nominalAccountsSelector.selectCurrentItem)
    const reduxDispatch = useDispatch()

    const onStepperFinish = (values: BankAccountDetailsModel) => {
        if (data?.id) {
            return // TODO Позже сделать PUT
        } else {
            reduxDispatch(nominalAccountsSlice.actions.setItem(values))
        }
    }

    return (
        <StepperC
            onStepperFinish={onStepperFinish}
            initialStep={data ? 2 : 0}
            initialValues={data || ({} as BankAccountDetailsModel)}
        >
            <NominalAccountFirstStep />
        </StepperC>
    )
}
