import { Box, PageHeader, Tabs } from '@dltru/dfa-ui'
import { UseTabsAndHash } from '../../hooks/useTabsAndHash'
import React, { FC } from 'react'

import { BlocksTable } from './components/BlocksTable'
import { ClientsTable } from './components/ClientsTable'
import { ISClientsTable } from './components/ISClientsTable'

const { TabPane } = Tabs

enum ClientListTabName {
    list = 'list',
    reestr = 'reestr',
    history = 'history',
}
const defaultTab = ClientListTabName.list

export const ClientList: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(Object.values(ClientListTabName), defaultTab)
    return (
        <>
            <Box padding={16}>
                <PageHeader title="Пользователи" />
            </Box>
            <Tabs onChange={onChangeTab} activeKey={activeTab}>
                <TabPane tab="Список всех пользователей" key={ClientListTabName.list}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <ClientsTable />
                    </Box>
                </TabPane>
                <TabPane tab="Реестр пользователей ИС" key={ClientListTabName.reestr}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <ISClientsTable />
                    </Box>
                </TabPane>
                <TabPane tab="История блокирования" key={ClientListTabName.history}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <BlocksTable />
                    </Box>
                </TabPane>
            </Tabs>
        </>
    )
}
