import { BankAccountDetailsModel } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    Divider,
    Form,
    KppInput,
    Step,
    StepperContext,
    WrappedInput,
    bankValidators,
    commonValidators,
    documentValidators,
    onlyDigits,
    useEditable,
} from '@dltru/dfa-ui'
import { ChangeEvent, FC, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '@services/api'

import { InnInput } from '@components/Form'

export const NominalAccountFirstStep: FC = () => {
    const { editable, currentStep, values } = useContext(StepperContext)
    const [bic, setBic] = useState<string>(values.bic)
    const { isAllow } = useContext(RbacContext)
    const allowEdit = isAllow(['nominal_account.write.all'])
    const backFromFirstStep = () => {
        navigate('/personal-accounts#bank')
    }

    const isEdit = useEditable(currentStep, 0, editable)

    const [form] = Form.useForm<BankAccountDetailsModel>()

    const onChangeBic = (e: ChangeEvent<HTMLInputElement>) => {
        setBic(e.target.value)
    }
    const navigate = useNavigate()
    return (
        <Step
            form={form}
            stepIndex={0}
            stepTitle="Реквизиты получателя"
            allowEdit={allowEdit}
            saveAfterEdit
            backFromFirstStep={backFromFirstStep}
            backCaption="Отмена"
        >
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Владелец счёта (получатель)"
                    name="recipient"
                    placeholder="Наименование получателя средств (владельца счета)"
                    required
                    size="large"
                    uneditable={isEdit}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="ИНН получателя"
                    name="recipient_inn"
                    placeholder="ИНН получателя средств"
                    required
                    maxLength={12}
                    normalize={onlyDigits}
                    size="large"
                    uneditable={isEdit}
                    rules={[{ validator: documentValidators.inn }]}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Номер счета (расчетный счет)"
                    name="account_number"
                    placeholder="Состоит из 20 цифр"
                    required
                    rules={[
                        {
                            validator: bankValidators.accountNumber({
                                checkExistenceOfAccount: api.lib.checkUniqueNominalAccount,
                                bic,
                            }),
                        },
                    ]}
                    maxLength={20}
                    normalize={onlyDigits}
                    size="large"
                    uneditable={isEdit}
                />
            </div>
            <Divider />
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Наименование банка получателя"
                    name="bank"
                    placeholder="Полное наименование банка"
                    required
                    maxLength={200}
                    uneditable={isEdit}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="БИК банка"
                    name="bic"
                    placeholder="Состоит из 9 цифр"
                    required
                    rules={[{ validator: commonValidators.number(9) }]}
                    maxLength={9}
                    normalize={onlyDigits}
                    uneditable={isEdit}
                    onChange={onChangeBic}
                />
            </div>
            <div className="formRow">
                <InnInput label="ИНН банка" name="bank_inn" required isUl uneditable={isEdit} />
            </div>
            <div className="formRow">
                <KppInput label="КПП банка" name="bank_kpp" required uneditable={isEdit} />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Корреспондентский счёт"
                    name="corr_account_number"
                    placeholder="Состоит из 20 цифр"
                    required
                    rules={[{ validator: commonValidators.number(20) }]}
                    maxLength={20}
                    normalize={onlyDigits}
                    uneditable={isEdit}
                />
            </div>
        </Step>
    )
}
