export type ModalType = 'set' | 'delete'

export type ModalResource = {
    title: string
    submitButtonLabel: string
    reasonTitle: string
}

export type ModalResources = {
    set: ModalResource
    delete: ModalResource
}

export const ChangeOperatorStatusModalResources = {
    set: {
        title: 'Присвоение статуса Оператора обмена',
        submitButtonLabel: 'Присвоить статус',
        reasonTitle: 'Основание присвоения статуса',
    },
    delete: {
        title: 'Отзыв статуса Оператора обмена',
        submitButtonLabel: 'Отозвать статус',
        reasonTitle: 'Основание отзыва статуса',
    },
} as ModalResources

export type ChangeOperatorStatusForm = {
    reasonText: string
    reasonDocs: { uid: string }[]
}
