import { FC } from 'react'

import styles from './styles.m.less'

type CheckUlItem = {
    title: string
    isCheck?: boolean
}
export interface ICheckUlProps {
    items: CheckUlItem[]
}
export const CheckUl: FC<ICheckUlProps> = ({ items }) => {
    return (
        <ul className={styles.checkList}>
            {items.map((item) => (
                <li key={item.title} className={item.isCheck ? styles.checked : undefined}>
                    {item.title}
                </li>
            ))}
        </ul>
    )
}
