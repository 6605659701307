import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CloseIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.60949 0.723898C2.08879 0.203199 1.24457 0.203199 0.723868 0.723898C0.203169 1.2446 0.203169 2.08882 0.723868 2.60952L9.11439 11L0.723868 19.3906C0.203169 19.9113 0.203169 20.7555 0.723868 21.2762C1.24457 21.7969 2.08879 21.7969 2.60949 21.2762L11 12.8857L19.3905 21.2762C19.9112 21.7969 20.7555 21.7969 21.2762 21.2762C21.7969 20.7555 21.7969 19.9113 21.2762 19.3906L12.8856 11L21.2762 2.60952C21.7969 2.08882 21.7969 1.2446 21.2762 0.723898C20.7555 0.203199 19.9112 0.203199 19.3905 0.723898L11 9.11442L2.60949 0.723898Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
