import { ITariffPlane, TariffPlaneEnum } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { tariffSlice } from '@store/tariff'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    data?: ITariffPlane
}
export const ArchivePlaneModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    data,
}) => {
    const reduxDispatch = useDispatch()
    const onCancel = () => {
        setIsModalVisible(false)
    }
    const onArchive = () => {
        if (!data?.id) {
            return
        }
        reduxDispatch(tariffSlice.actions.archiveTariffPlane(data.id))
        onCancel()
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={onCancel}
            width={423}
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Архивировать тарифный план
                </Space>
            }
            footer={[
                <Button onClick={onCancel} borderRadius={12} key="cancel">
                    Отмена
                </Button>,
                <Button type="primary" onClick={onArchive} borderRadius={12} key="ok">
                    Да, отправить в архив
                </Button>,
            ]}
        >
            <Box margin={[0, 0, 0, 40]}>
                {data?.type === TariffPlaneEnum.INDIVIDUAL ? (
                    <p>
                        При отправке тарифного плана в архив, пользователи будут отвязаны от данного
                        тарифного плана. Комиссии по всем операциям будут расчитываться по базовому
                        тарифному плану.
                    </p>
                ) : (
                    <p>
                        При отправке тарифного плана в архив, выпуски ЦФА будут отвязаны от данного
                        тарифного плана. Комиссии по всем операциям будут расчитываться по базовому
                        тарифному плану или по индивидуальному тарифному плану пользователя, если он
                        назначен.
                    </p>
                )}

                <p>Вы действительно хотите отправить тарифный план в архив?</p>
            </Box>
        </MiniModal>
    )
}
