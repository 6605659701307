import {
    Button,
    CONTENT_FOOTER_ID,
    ContentFooterBox,
    DeleteOutlined,
    PlusSquare,
    Portal,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { nominalAccountsSlice } from '@store/nominalAccounts'
import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import { RemoveNominalAccount } from './RemoveNominalAccount'

export const Footer: FC = () => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const data = useSelector(nominalAccountsSelector.selectCurrentItem)
    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
    const reduxDispatch = useDispatch()

    const postNominalAccount = () => {
        reduxDispatch(nominalAccountsSlice.actions.postItem())
    }

    if (!data) {
        return null
    }

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        <Button
                            icon={<DeleteOutlined />}
                            borderRadius={12}
                            onClick={() => setIsRemoveModalVisible(true)}
                        >
                            Удалить и выйти
                        </Button>
                        <Button
                            icon={<PlusSquare />}
                            type="primary"
                            borderRadius={12}
                            onClick={postNominalAccount}
                        >
                            Создать банковский счёт
                        </Button>
                    </ContentFooterBox>
                </Portal>
            )}
            <RemoveNominalAccount
                isModalVisible={isRemoveModalVisible}
                setIsModalVisible={setIsRemoveModalVisible}
            />
        </>
    )
}
