import { UnionDealFront } from '@dltru/dfa-models'
import { CardModal, getHeaderDealData, useModalState } from '@dltru/dfa-ui'
import React, { FC } from 'react'

import './style.less'

interface IComponentProps {
    deal: UnionDealFront
    onResolve: (res?: boolean) => void
}
export const BaseModal: FC<IComponentProps> = ({ deal, onResolve, children }) => {
    const [visible, resolve] = useModalState(onResolve)

    return (
        <CardModal
            isModalVisible={visible}
            setIsModalVisible={resolve}
            mini
            width={540}
            headerData={getHeaderDealData(deal)}
            BodyRenderProps={children}
        />
    )
}
