export const isAllowHelper = (scopes: string, scope: string[]) => {
    if (scopes === 'super') {
        return true
    }

    const _scopes = scopes.split(' ')
    const result = scope.reduce((acc, curr) => {
        if (_scopes.includes(curr)) {
            acc = true
        }

        return acc
    }, false)
    return result
}
