// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleContainer_r1RtA {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n.titleContainer_r1RtA .anticon {\n  color: #b9bac1;\n}\n.titleContainer_r1RtA .anticon:hover {\n  color: #303440;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/OpeningPanel/openingPanel.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AADF;AAHA;EAQM,cAAA;AAFN;AAIM;EACE,cAAA;EACE,eAAA;AAFV","sourcesContent":["@import '/src/assets/style/palette';\n\n.titleContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n\n  :global {\n    .anticon {\n      color: @gray-6;\n\n      &:hover {\n        color: @gray-10;\n          cursor: pointer;\n      }\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": "titleContainer_r1RtA"
};
export default ___CSS_LOADER_EXPORT___;
