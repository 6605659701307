import { RbacContext } from '@dltru/dfa-rbac'
import { Box, Card, CardContent, Col, Divider, Row, Space, TabButton } from '@dltru/dfa-ui'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setInjunctionModal, setLiftingOfInjunctionModal } from '@store/blocking'
import IAppState from '@store/states'
import { walletAssetsSlice } from '@store/wallet/assets'
import { walletHistorySlice } from '@store/wallet/history'

import { BalanceTable } from './BalanceTable'
import { DealsTable } from './DealsTable'
import { EncumbranceTable } from './EncumbranceTable'
import { InjunctionsTable } from './InjunctionsTable'
import { DFAInjunctionModal, DFALiftingOfInjunctionModal } from './modals'

enum Tab {
    ASSETS,
    OTHER,
    DEALS,
    INJUNCTIONS,
}

export const Wallet: React.FC<{
    userId: string | undefined
}> = ({ userId }) => {
    const dispatch = useDispatch()
    const { isAllow } = useContext(RbacContext)

    const [innerTab, setInnerTab] = useState<Tab>(Tab.ASSETS)

    const { injunctionModal, liftingOfInjunctionModal } = useSelector(
        (store: IAppState) => store.blocking,
    )

    useEffect(() => {
        if (userId) {
            dispatch(walletAssetsSlice.actions.loadWalletAssets(userId))
            dispatch(walletHistorySlice.actions.loadWalletHistory(userId))
        }
    }, [userId])

    return (
        <Card>
            <CardContent>
                <Row>
                    <Col span={12}>
                        <Box padding={[16, 0, 0, 0]}>
                            <Space>
                                <TabButton
                                    isActiveTab={innerTab === Tab.ASSETS}
                                    onClick={() => setInnerTab(Tab.ASSETS)}
                                >
                                    Активы
                                </TabButton>
                                <TabButton
                                    isActiveTab={innerTab === Tab.OTHER}
                                    onClick={() => setInnerTab(Tab.OTHER)}
                                >
                                    Прочие активы
                                </TabButton>
                                <TabButton
                                    isActiveTab={innerTab === Tab.DEALS}
                                    onClick={() => setInnerTab(Tab.DEALS)}
                                >
                                    История операций с ЦФА
                                </TabButton>

                                <TabButton
                                    isActiveTab={innerTab === Tab.INJUNCTIONS}
                                    onClick={() => setInnerTab(Tab.INJUNCTIONS)}
                                >
                                    Блокирования
                                </TabButton>
                            </Space>
                        </Box>
                    </Col>
                    {/*
                    <Col span={4}>
                        <CardVerticalField title="Оценка кошелька" value="5 856 125 230 ₽" />
                    </Col>
                    <Col span={4}>
                        <CardVerticalField title="ID Кошелька" value="2c3bb8443a1af" />
                    </Col>
                    <Col span={4}>
                        <CardVerticalField title="Создан" value="28.01.2022" />
                    </Col>
                    */}
                </Row>
                <Divider />
                {innerTab === Tab.ASSETS && <BalanceTable userId={userId} />}
                {innerTab === Tab.OTHER && <EncumbranceTable />}
                {innerTab === Tab.DEALS && <DealsTable />}
                {isAllow(['injunction.read.all']) && innerTab === Tab.INJUNCTIONS && (
                    <InjunctionsTable userId={userId} />
                )}
            </CardContent>
            <DFAInjunctionModal
                visible={injunctionModal}
                key={`${new Date().getTime()}` + 1}
                onClose={() => dispatch(setInjunctionModal(false))}
            />
            <DFALiftingOfInjunctionModal
                visible={liftingOfInjunctionModal}
                key={`${new Date().getTime()}` + 2}
                onClose={() => dispatch(setLiftingOfInjunctionModal(false))}
            />
        </Card>
    )
}
