import { ITransferOrder, TransferOrderStatus } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { loadTransferOrders, loadTransferOrdersDfa, updateTransferOrderList } from './index'

function* handleLoadTransferOrders({ payload }: ReturnType<typeof loadTransferOrders>) {
    try {
        yield* put(updateTransferOrderList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getTransferOrderService({ ...payload })
        if (error) {
            throw error
        }

        let filtered =
            data?.items?.filter((order: ITransferOrder) => order.is_reply === false) || []

        filtered = filtered.map((order: ITransferOrder) => {
            const replyOffer = data?.items?.find(
                (item: ITransferOrder) => item.global_id === order.global_id && item.is_reply,
            )

            let inProgressStatus = null

            if (order && replyOffer) {
                inProgressStatus =
                    (order?.status === TransferOrderStatus.executed &&
                        replyOffer.status !== TransferOrderStatus.executed) ||
                    (order?.status !== TransferOrderStatus.executed &&
                        replyOffer.status === TransferOrderStatus.executed)
                        ? TransferOrderStatus.ready
                        : null
            }

            order.status = inProgressStatus || order.status

            return order
        })

        yield* put(updateTransferOrderList({ isLoading: false, data: filtered }))
    } catch (error) {
        yield* put(updateTransferOrderList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadTransferOrdersDfa({ payload }: ReturnType<typeof loadTransferOrders>) {
    try {
        yield* put(updateTransferOrderList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getTransferOrderService({ ...payload })
        if (error) {
            throw error
        }
        const filtered =
            data?.items?.filter(
                (order: ITransferOrder) =>
                    order.is_reply === false &&
                    [TransferOrderStatus.accepted, TransferOrderStatus.placed].includes(
                        order.status,
                    ),
            ) || []
        yield* put(updateTransferOrderList({ isLoading: false, data: filtered }))
    } catch (error) {
        yield* put(updateTransferOrderList({ error, isLoading: false, data: [] }))
    }
}

export function* watchTransferOrdersDfa(): Generator<StrictEffect> {
    yield* takeLatest(loadTransferOrdersDfa.type, handleLoadTransferOrdersDfa)
    yield* takeLatest(loadTransferOrders.type, handleLoadTransferOrders)
}
