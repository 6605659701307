import { unionTypeBackToFront } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import { updateOrderByOrderId } from '@store/orders/list'

import api from '@services/api'

import {
    loadSecondaryDfaDeals,
    revokeSecondaryBuyDfa,
    revokeSecondarySellDfa,
    updateSecondaryDfaDealsList,
} from './index'

function* handleLoadSecondaryDfaDeals() {
    try {
        yield* put(updateSecondaryDfaDealsList({ error: '', isLoading: true }))
        const { data, error } = yield* call(api.lib.getSecondaryDfaDealsService)

        if (error) {
            throw error
        }

        yield* put(updateSecondaryDfaDealsList({ isLoading: false, data: data.items }))
    } catch (error) {
        yield* put(updateSecondaryDfaDealsList({ error, isLoading: false, data: [] }))
    }
}

function* handleBuyRevokeSecondary({ payload }: ReturnType<typeof revokeSecondaryBuyDfa>) {
    try {
        const { error, data } = yield api.lib.rejectSecondaryDfaBuyOrder(payload.data)

        if (error) {
            throw error
        }

        openMessage({
            type: 'success',
            message: 'Заявка отозвана',
        })
        if (payload.withUpdateOrder && data?.item) {
            yield put(updateOrderByOrderId(unionTypeBackToFront(data.item)))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось отозвать заявку',
        })
    }
}

function* handleSellRevokeSecondary({ payload }: ReturnType<typeof revokeSecondarySellDfa>) {
    try {
        const { error, data } = yield api.lib.rejectSecondaryDfaSellOrder(payload.data)

        if (error) {
            throw error
        }

        openMessage({
            type: 'success',
            message: 'Заявка отозвана',
        })
        if (payload.withUpdateOrder && data?.item) {
            yield put(updateOrderByOrderId(unionTypeBackToFront(data.item)))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось отозвать заявку',
        })
    }
}

export function* watchSecondaryDfaOrder(): Generator<StrictEffect> {
    yield* takeLatest(loadSecondaryDfaDeals.type, handleLoadSecondaryDfaDeals)
    yield* takeLatest(revokeSecondarySellDfa.type, handleSellRevokeSecondary)
    yield* takeLatest(revokeSecondaryBuyDfa.type, handleBuyRevokeSecondary)
}
