import { PaymentOrderExtensions, PaymentOrderStatuses } from '@dltru/dfa-models'
import {
    Box,
    Button,
    CheckIcon,
    CloseIcon,
    DocumentText,
    Dropdown,
    EllipsisOutlined,
    Menu,
    Space,
    Tag,
    VIEW_DATE_FORMAT,
    getRublesFromPennies,
} from '@dltru/dfa-ui'
import { getTagProps } from '@resources/paymentOrderState'
import classNames from 'classnames/bind'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { paymentOrdersSlice } from '@store/paymentOrders'
import { paymentOrdersSelector } from '@store/paymentOrders/selectors'

import style from '../../style.m.less'
import { DetailsCollapse } from './DetailsCollapse'

const cn = classNames.bind(style)

export const TransactionPaymentOrders: FC = () => {
    const paymentOrders = useSelector(paymentOrdersSelector.selectCurrentPaymentOrders)
    const transaction = useSelector(paymentOrdersSelector.selectCurrentTransaction)

    const reduxDispatch = useDispatch()
    const executePaymentOrder = (payment_order_uuid: string) => () => {
        if (transaction) {
            reduxDispatch(
                paymentOrdersSlice.actions.sendExecutedPaymentOrder({
                    payment_order_uuid,
                    transaction_uuid: transaction?.uuid,
                }),
            )
        } //TODO else alert
    }
    const notExecutePaymentOrder = (payment_order_uuid: string) => () => {
        if (transaction) {
            reduxDispatch(
                paymentOrdersSlice.actions.sendNotExecutedPaymentOrder({
                    payment_order_uuid,
                    transaction_uuid: transaction?.uuid,
                }),
            )
        } //TODO else alert
    }
    const downloadFile = (uuid: string, extension: PaymentOrderExtensions) => () => {
        reduxDispatch(paymentOrdersSlice.actions.getFilePaymentOrder({ uuid, extension }))
    }
    const getMenu = (uuid: string) => (
        <Menu>
            <Menu.Item key="1" onClick={downloadFile(uuid, 'docx')}>
                Скачать .docx
            </Menu.Item>
            <Menu.Item key="2" onClick={downloadFile(uuid, 'xml')}>
                Скачать .xml
            </Menu.Item>
        </Menu>
    )
    return (
        <>
            {paymentOrders.map((paymentOrder) => {
                const isStateWithAction = paymentOrder.status === PaymentOrderStatuses.created
                const tagInfo = getTagProps(paymentOrder.status)
                const infoBlockClass = cn(style.infoBlock, {
                    [style.infoBlockDisabled]:
                        paymentOrder.status === PaymentOrderStatuses.rejected,
                })
                const { uuid } = paymentOrder
                return (
                    <Box
                        padding={[24, 24, 16, 24]}
                        margin={[8, 0, 0, 0]}
                        className="boxContent"
                        key={uuid}
                    >
                        <div className={infoBlockClass}>
                            <Space size={12}>
                                <div className={style.iconWrapper}>
                                    <DocumentText />
                                </div>
                                <Space direction="vertical">
                                    <Space size={8}>
                                        <h6 className={style.headerSmall}>
                                            Платежное поручение №{paymentOrder.number} от&nbsp;
                                            {dayjs
                                                .unix(paymentOrder.created_at ?? 0)
                                                .format(VIEW_DATE_FORMAT)}
                                        </h6>
                                        <Tag color={tagInfo.color}>{tagInfo.title}</Tag>
                                    </Space>
                                    <Space size={16}>
                                        <p className={style.header}>
                                            {getRublesFromPennies(paymentOrder.amount)} руб.
                                        </p>
                                        <Dropdown.Button
                                            icon={<EllipsisOutlined />}
                                            overlay={getMenu(uuid)}
                                            className={style.dropdownButton}
                                            onClick={downloadFile(uuid, 'docx')}
                                        >
                                            Скачать
                                        </Dropdown.Button>
                                    </Space>
                                </Space>
                            </Space>
                            {isStateWithAction && (
                                <Space size={10}>
                                    <Button
                                        type="default"
                                        icon={<CloseIcon className={style.iconClose} />}
                                        borderRadius={12}
                                        onClick={notExecutePaymentOrder(uuid)}
                                    >
                                        Платеж не исполнен
                                    </Button>
                                    <Button
                                        type="primary"
                                        icon={<CheckIcon />}
                                        borderRadius={12}
                                        onClick={executePaymentOrder(uuid)}
                                    >
                                        Подтвердить платеж
                                    </Button>
                                </Space>
                            )}
                        </div>
                        <DetailsCollapse paymentOrder={paymentOrder} />
                    </Box>
                )
            })}
        </>
    )
}
