import { ITariffUserLink } from '@dltru/dfa-models'
import {
    Box,
    Button,
    Filters,
    MinusSquare,
    PlusSquare,
    SearchInput,
    Space,
    Table,
} from '@dltru/dfa-ui'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { tariffRelatedUsersSlice } from '@store/tariffRelatedUsers'
import { tariffRelatedUsersSelector } from '@store/tariffRelatedUsers/selectors'

import { DropRelateModal } from '../DropRelateModal'
import { RelateModal } from '../RelateModal'
import { filters } from './filters'
import { gridConfig } from './gridConfig'
import styles from './styles.m.less'
import { RbacContext } from '@dltru/dfa-rbac'

export const RelatedUsersTable: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()
    const { id } = useParams()

    const items = useSelector(tariffRelatedUsersSelector.selectList)
    const paging = useSelector(tariffRelatedUsersSelector.selectPaging)
    const isLoading = useSelector(tariffRelatedUsersSelector.selectIsLoading)
    const params = useSelector(tariffRelatedUsersSelector.selectParams)

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [isShowDropRelateModal, setIsShowDropRelateModal] = useState(false)
    const [isShowRelateModal, setIsShowRelateModal] = useState(false)

    const onPerPageChange = useCallback(
        (limit: number) => {
            if (!id) {
                return
            }
            reduxDispatch(
                tariffRelatedUsersSlice.actions.getRelatedUsers({
                    tariff_id: id,
                    params: { ...params, limit },
                }),
            )
        },
        [id, params],
    )

    const onNext = useCallback(() => {
        if (!id) {
            return
        }
        reduxDispatch(
            tariffRelatedUsersSlice.actions.getRelatedUsers({
                tariff_id: id,
                params: { cursor: paging?.cursors?.next },
            }),
        )
    }, [id, paging])

    const onPrev = useCallback(() => {
        if (!id) {
            return
        }
        reduxDispatch(
            tariffRelatedUsersSlice.actions.getRelatedUsers({
                tariff_id: id,
                params: { cursor: paging?.cursors?.prev },
            }),
        )
    }, [id, paging])

    const onApplyFilters = useCallback(
        (filters?: Partial<ITariffUserLink>) => {
            if (!id) {
                return
            }
            reduxDispatch(
                tariffRelatedUsersSlice.actions.getRelatedUsers({
                    tariff_id: id,
                    params: { ...params, ...filters },
                }),
            )
        },
        [id, params],
    )

    const onSearch = (query: string) => {
        /* TODO пока что не работает поиск */
        // eslint-disable-next-line no-console
        console.log(query)
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys as string[])
    }

    useEffect(() => {
        if (id) {
            reduxDispatch(
                tariffRelatedUsersSlice.actions.getRelatedUsers({ tariff_id: id, params }),
            )
        }
        return () => {
            reduxDispatch(tariffRelatedUsersSlice.actions.clearItems())
        }
    }, [id])

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const selectedUsers = items.filter((item) => selectedRowKeys.includes(item.id))

    const buttons = isAllow(['tariff.assign']) ? (
        <div className={styles.tableFooterContainer}>
            <Button
                disabled={!selectedRowKeys.length}
                className={styles.tableAction}
                icon={<MinusSquare />}
                borderRadius={12}
                onClick={() => setIsShowDropRelateModal(true)}
            >
                Отвязать от тарифного плана
            </Button>
            <Button
                className={styles.tableAction}
                icon={<PlusSquare />}
                borderRadius={12}
                onClick={() => setIsShowRelateModal(true)}
            >
                Добавить пользователей
            </Button>
        </div>
    ) : (
        <></>
    )

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                rowKey="id"
                rowSelection={rowSelection}
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                clickableRow
                headerLeft={
                    <Space>
                        <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                        <SearchInput onSearch={onSearch} initialValue={params.name ?? ''} />
                    </Space>
                }
                footerLeft={buttons}
            />

            {isShowRelateModal && (
                <RelateModal
                    tariffId={id}
                    isModalVisible={isShowRelateModal}
                    setIsModalVisible={setIsShowRelateModal}
                />
            )}
            {isShowDropRelateModal && (
                <DropRelateModal
                    isModalVisible={isShowDropRelateModal}
                    setIsModalVisible={setIsShowDropRelateModal}
                    usersList={selectedUsers}
                    setUserList={onSelectChange}
                />
            )}
        </Box>
    )
}
