import { CertificateType } from '@dltru/dfa-models'
import cn from 'classnames'
import dayjs from 'dayjs'
import { FC } from 'react'

import { Space } from '../../..'
import { EditSquareRounded, ExclamationCircleOutline } from '../../../Icons'
import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '../../../constants'
import style from './UserCertificateItem.m.less'

interface IComponentProps {
    certificate: CertificateType
    onClick: () => void
    isActive: boolean
}
export const UserCertificateItem: FC<IComponentProps> = ({ isActive, onClick, certificate }) => {
    const wrapperClassName = cn(style.wrapper, { [style.active]: isActive })
    const isExpired = certificate.expired_at && dayjs().isAfter(dayjs(certificate.expired_at))
    const isWarning = false // TODO нет критериев проверки использования на платформе
    const isNotValid = false // TODO нет критериев проверки на валидность
    const clickable = !(isExpired || isWarning || isNotValid)
    const isUl = certificate.meta.SzOID_OGRN && certificate.meta.SzOID_OGRN.length === 13

    return (
        <div className={wrapperClassName} onClick={clickable ? onClick : undefined}>
            <EditSquareRounded className={style.blueIcon} />
            <Space direction="vertical" className={style.growBlock}>
                {isUl ? (
                    <>
                        <div>
                            <strong>
                                {certificate.meta.SzOID_ORGANIZATION_NAME || EMPTY_FIELD_VALUE}
                            </strong>
                            <br />
                            <span>ОГРН {certificate.meta.SzOID_OGRN}</span>
                        </div>
                        <div>
                            <strong>{certificate.meta.FullName || EMPTY_FIELD_VALUE}</strong>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <strong>{certificate.meta.FullName || EMPTY_FIELD_VALUE}</strong>
                            {certificate.meta.SzOID_OGRNIP && (
                                <>
                                    <br />
                                    <span>ОГРНИП {certificate.meta.SzOID_OGRNIP}</span>
                                </>
                            )}
                        </div>
                    </>
                )}
                {isWarning && (
                    <span className={style.dangerSmallText}>
                        <ExclamationCircleOutline />
                        Не предназначен для использования на платформе
                    </span>
                )}
                {isNotValid && (
                    <span className={style.dangerSmallText}>
                        <ExclamationCircleOutline />
                        Сертификат не валиден
                    </span>
                )}
            </Space>
            <div className={style.rightBlock}>
                <span className={style.grayText}>действителен до</span>
                <br />
                <span className={isExpired ? style.dangerText : undefined}>
                    {dayjs(certificate.expired_at)?.format(VIEW_DATE_FORMAT)}
                </span>
                <br />
                {isExpired && <span className={style.dangerText}>Срок истёк</span>}
            </div>
        </div>
    )
}
