import {
    BeneficialOwner,
    IAgentProfile,
    IBusinessmanProfile,
    IIndividualProfile,
    ILegalEntityProfile,
    IRequest,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'

export async function storeAgentProfileService<T>(
    payload: IAgentProfile,
): Promise<IRequest<ResponseItem<T>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileAgent,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function storeLegalEntityService<T>(
    payload: ILegalEntityProfile,
): Promise<IRequest<ResponseItem<T>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileLegalEntity,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function storeBusinessmanService(data: IBusinessmanProfile): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.ProfileBusinessman,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function storeBeneficialOwnerService<T>(
    payload: BeneficialOwner,
): Promise<IRequest<ResponseItem<T>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileBeneficialOwner,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function storeIndividualForm<T>(
    payload: IIndividualProfile,
): Promise<IRequest<ResponseItem<T>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileIndividual,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}
