export enum ExternalRateIndex {
    RUGOLD = 'RUGOLD',
}
export interface IIndexRate {
    // example: RUGOLD
    // Код индекса
    index: ExternalRateIndex

    // example: 4554.08
    // Значение курса индекса
    rate: number

    // example: 2023-01-01T11:53:33.779Z
    // Дата последнего изменения. RFC 3339 date-time.
    updated_at: string
}
