// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".phoneText_P1ccR {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/auth/FullBlockingView/style.m.less"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AADJ","sourcesContent":["@import '/src/assets/style/palette';\n\n.phoneText {\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n    color: @gray-10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneText": "phoneText_P1ccR"
};
export default ___CSS_LOADER_EXPORT___;
