import { Box, Button, Divider, Form, Modal, UploadFile, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeDFAIssueFailed } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const FailedIssueModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [form] = Form.useForm()
    const reduxDispatch = useDispatch()

    const { id: dfaId } = useSelector(dfaDetailsSelector.selectDfaDetails)

    const closeModal = () => {
        setIsModalVisible(false)
    }

    const getDocumentsUids = (docs: UploadFile[]) => docs.map((doc) => doc.uid)

    return (
        <Modal
            title="Признание выпуска несостоявшимся"
            visible={isModalVisible}
            onCancel={closeModal}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="primary"
                    className={style.makeIssueFailedButton}
                    onClick={() => {
                        reduxDispatch(
                            makeDFAIssueFailed({
                                dfaId: dfaId as number,
                                reject_reason: form.getFieldValue('approval_document_comment'),
                                reject_reason_documents_uuid: getDocumentsUids(
                                    form.getFieldValue('approval_document_file_uuid'),
                                ),
                            }),
                        )
                        setIsModalVisible(false)
                    }}
                >
                    Признать выпуск несостоявшимся
                </Button>,
            ]}
        >
            <div>
                <p>
                    Признание выпуска ЦФА несостоявшимся влечет следующие последствия:
                    <ul className="list-without-shift">
                        <li>заявка на выпуск ЦФА снимается с Витрины предложений,</li>
                        <li>
                            информация о признании выпуска ЦФА несостоявшимся размещается на Сайте
                            Оператора,
                        </li>
                        <li>все обязательства по заключенным Сделкам размещения прекращаются.</li>
                    </ul>
                </p>
                <Divider margin={0} />
                <Box padding={[24, 0, 16, 0]}>
                    <h3 className="page-content-header">
                        Причина признания выпуска ЦФА несостоявшимся
                    </h3>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onFinish={() => {}}
                >
                    <UploadDocumentContainerWrapper
                        name="approval_document_file_uuid"
                        title="Загрузите фотографии или сканы документов"
                        required
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="approval_document_comment"
                            multiline
                            required
                            maxLength={200}
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
            </div>
        </Modal>
    )
}
