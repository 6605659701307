import { commonValidators } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & { name?: WrappedInputProps['name'] }

export const NameInput = ({ className, label, name, placeholder, ...rest }: ComponentProps) => {
    return (
        <WrappedInput
            {...rest}
            className={className ?? 'formComponentItem'}
            label={label ?? 'Имя'}
            name={name ?? 'first_name'}
            placeholder={placeholder ?? ''}
            rules={[{ validator: commonValidators.name() }]}
        />
    )
}
