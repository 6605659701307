// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noBorderButton_I9634 {\n  border: none !important;\n  color: #868991 !important;\n}\n.blueIcon_g7gsx {\n  color: #007ef2 !important;\n}\n.blueIcon_g7gsx svg {\n  width: 24px;\n  height: 24px;\n}\n.grayIcon_fbYaN {\n  color: #b9bac1 !important;\n}\n.grayIcon_fbYaN svg {\n  width: 24px;\n  height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/certifacate/CertificatesListComponent/style.m.less"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,yBAAA;AADF;AAGA;EACE,yBAAA;AADF;AAAA;EAGI,WAAA;EACA,YAAA;AAAJ;AAGA;EACE,yBAAA;AADF;AAAA;EAGI,WAAA;EACA,YAAA;AAAJ","sourcesContent":["@import 'style/palette';\n\n.noBorderButton {\n  border: none !important;\n  color: @gray-7 !important;\n}\n.blueIcon {\n  color: @primary-6 !important;\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}\n.grayIcon {\n  color: @gray-6 !important;\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noBorderButton": "noBorderButton_I9634",
	"blueIcon": "blueIcon_g7gsx",
	"grayIcon": "grayIcon_fbYaN"
};
export default ___CSS_LOADER_EXPORT___;
