import { Box, Button, Modal } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from './ApplyActionModal.m.less'

interface IConfirmModal {
    isModalVisible: boolean
    onOk: () => void
    onCancel: () => void
}

export const ApplyActionModal: FC<IConfirmModal> = ({ isModalVisible, onOk, onCancel }) => {
    return (
        <>
            <Modal
                visible={isModalVisible}
                width={522}
                title="Согласовать заявку"
                onCancel={onCancel}
            >
                <div className={style.confirmRepaymentModal}>
                    <Box direction="column" margin={[0, 0, 24, 0]}>
                        <p className="modal-content-text">
                            Вы действительно хотите согласовать заявку на присвоение квалификации?
                        </p>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 8, 16, 8]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={onCancel}>
                            Отмена
                        </Button>

                        <Button borderRadius={12} type="primary" onClick={() => onOk()}>
                            Да, согласовать
                        </Button>
                    </Box>
                </div>
            </Modal>
        </>
    )
}
