import { ChangeEvent, FC } from 'react'

import { Checkbox, CheckboxProps } from '../../../Checkbox'
import { FormItem, FormItemProps } from '../FormItem'

function getValueFromEvent(e: ChangeEvent<HTMLInputElement>) {
    return e.target.checked
}

export type CheckboxItemProps = FormItemProps &
    Pick<CheckboxProps, 'disabled' | 'onChange'> & {
        checkboxTitle?: string
    }

export const CheckboxItem: FC<CheckboxItemProps> = ({
    disabled,
    onChange,
    checkboxTitle,
    ...rest
}) => {
    const renderUneditable = () => <>{checkboxTitle}</>

    return (
        <FormItem
            {...rest}
            renderUneditable={renderUneditable as FormItemProps['renderUneditable']}
            valuePropName="checked"
            getValueFromEvent={getValueFromEvent}
        >
            <Checkbox disabled={disabled} onChange={onChange}>
                {checkboxTitle}
            </Checkbox>
        </FormItem>
    )
}
