import { FC } from 'react'

import { UploadInput, UploadInputProps } from '../UploadInput'

export const UploadDocumentContainerWrapper: FC<UploadInputProps> = ({ children, ...props }) => {
    return (
        <div className="upload-form__container__wrapper">
            <div className="upload-form__wrapper">
                {children}
                <UploadInput {...props} />
            </div>
        </div>
    )
}
