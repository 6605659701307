export interface ICalcFeeRequestProps {
    service_code: string
    operation_amount?: number
}

export interface ICalcFeeResponse {
    FeeId: string
    FeeAmount: number
}

export const ServiceCodeFee = {
    emissionBuy: 'CM-EM-011',
    createOffer: 'CM-OTH-026',
    createDfa: 'CM-EM-005',
    issueLong: 'CM-EM-007',
    issueShort: 'CM-EM-008',
    paymentOrder: 'CM-OTH-017',
    repaymentDfa: 'CM-EX-010',
    sellDfa: 'CM-PS-016',
    buyDfa: 'CM-PS-014',
}

export interface ICalcRepaymentFeeRequest {
    asset_id: number
    repayment_collect_type: string
    redeem_price_per_dfa?: number
    redemption_spread?: number
}

export interface ICalcRepaymentFeeResponse {
    fee_amount: number
    fee_id: string
    payment_order_fee_amount: number
}

export interface ICalcInvestorRepaymentFeeRequest {
    asset_id: number
    user_uuid: string
    amount: number
}
