import { UserRoles, employeeRoles, userRolesTranslate } from '@dltru/dfa-models'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    DefaultOptionType,
    Divider,
    EmailInput,
    FioForm,
    Form,
    LoginInput,
    PageHeader,
    PhoneInput,
    Space,
    WrappedInput,
    WrappedSelect,
} from '@dltru/dfa-ui'
import { sha256 } from 'js-sha256'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { employeeSlice } from '@store/employee'
import { employeeSelector } from '@store/employee/selectors'

const ROLE_OPTIONS: DefaultOptionType[] = employeeRoles.map((role) => ({
    value: role,
    label: userRolesTranslate[role],
}))

type FormDataType = {
    login: string
    password: string
    first_name: string
    last_name: string
    second_name: string
    email: string
    phone: string
    job_title: string
    organization_unit: string
    role: UserRoles
}

export const EmployeeEdit: FC = () => {
    const [form] = Form.useForm<FormDataType>()
    const navigate = useNavigate()
    const { id } = useParams()
    const isCreate = !id
    const initialData = useSelector(employeeSelector.selectItem)

    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (id) {
            reduxDispatch(employeeSlice.actions.getItem(id))
        }
        return () => {
            reduxDispatch(employeeSlice.actions.clearItem())
        }
    }, [id])

    if (id && !initialData.user_uuid) {
        return null
    }

    const saveEmployee = () => {
        const callback = () => navigate(-1)
        const values = form.getFieldsValue()
        if (isCreate) {
            reduxDispatch(
                employeeSlice.actions.createItem({
                    data: { ...values, password: sha256(values.password).toString() },
                    callback,
                }),
            )
        } else {
            reduxDispatch(
                employeeSlice.actions.updateItem({
                    user_uuid: id,
                    data: values,
                    callback,
                }),
            )
        }
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={id ? 'Редактирование профиля сотрудника' : 'Добавить нового сотрудника'}
                    onBack={() => navigate(-1)}
                />
            </Box>
            <Card>
                <CardHeader title="Основные сведения" />
                <CardContent divider>
                    <Form form={form} onFinish={saveEmployee} initialValues={initialData}>
                        <FioForm size="large" />
                        <div className="formRow">
                            <WrappedInput
                                className="formComponentItem"
                                label="Должность"
                                name="job_title"
                                required
                                size="large"
                            />
                        </div>
                        <div className="formRow">
                            <WrappedInput
                                className="formComponentItem"
                                label="Подразделение"
                                name="organization_unit"
                                required
                                size="large"
                            />
                        </div>
                        <div className="formRow">
                            <WrappedSelect
                                className="formComponentItem"
                                label="Роль"
                                name="role"
                                options={ROLE_OPTIONS}
                                required
                                placeholder="Выберите роль пользователя"
                            />
                        </div>
                        {isCreate && (
                            <>
                                <Divider />
                                <div className="formRow">
                                    <LoginInput
                                        required
                                        className="formComponentItem"
                                        placeholder=""
                                        size="large"
                                    />
                                </div>
                                <div className="formRow">
                                    <WrappedInput
                                        className="formComponentItem"
                                        label="Пароль"
                                        name="password"
                                        required
                                        size="large"
                                    />
                                </div>
                                <div className="formRow">
                                    <PhoneInput
                                        name="phone"
                                        className="formComponentItem"
                                        required
                                        label="Телефон"
                                        size="large"
                                    />
                                </div>
                                <div className="formRow">
                                    <EmailInput label="Корпоративная почта" required size="large" />
                                </div>
                            </>
                        )}
                    </Form>
                </CardContent>
                <CardFooter>
                    <Space>
                        <Button borderRadius={12} onClick={() => navigate(-1)}>
                            Отмена
                        </Button>
                        <Button borderRadius={12} type="primary" onClick={form.submit}>
                            Сохранить
                        </Button>
                    </Space>
                </CardFooter>
            </Card>
        </>
    )
}
