// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalBody__hLbm {\n  width: 100%;\n}\n.headerBold_k_5v_ {\n  padding: 0;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/RoleForms/forms/components/Footer/modals/style.m.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEA;EACI,UAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".modalBody {\n    width: 100%;\n}\n\n.headerBold {\n    padding: 0;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "modalBody__hLbm",
	"headerBold": "headerBold_k_5v_"
};
export default ___CSS_LOADER_EXPORT___;
