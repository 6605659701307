import { IDfaOwner, IRequest } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItems } from '../../types/types'
import { path } from '../../utils'

export async function getDfaOwners(dfaId: number): Promise<IRequest<ResponseItems<IDfaOwner>>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletDfaOwners, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
