import { Button, ShieldSecurity } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'

import { CancelRemoveUserModal } from '../../RemoveClient'

export const CancelRemoveClientAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const isVisibleBtn = false // TODO добавить рельную проверку когда будет возможность отменять удаление
    if (!isVisibleBtn) {
        return null
    }

    return (
        <>
            <Button
                icon={<ShieldSecurity />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                Отменить исключение из реестра пользователей
            </Button>

            <CancelRemoveUserModal visible={isModalVisible} setVisible={setIsModalVisible} />
        </>
    )
}
