import React, { ReactNode } from 'react'

import { Col } from '../../Col'
import { Row } from '../../Row'

const COL_SPAN_LABEL = { sm: 10, md: 10, lg: 7, xl: 6, xxl: 5 }
const COL_SPAN_ITEM = { sm: 14, md: 14, lg: 14, xl: 10, xxl: 6 }

export interface IFormRow {
    label?: ReactNode
}

const FormRow: React.FC<IFormRow> = ({ label, children }) => {
    return (
        <Row className="ant-form-item">
            <Col className="ant-form-item-label ant-form-item-label-left" {...COL_SPAN_LABEL}>
                {label}
            </Col>
            <Col className="ant-form-item-control" {...COL_SPAN_ITEM}>
                {children}
            </Col>
        </Row>
    )
}

export default FormRow
