import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const HierarchyIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.91666 5.25V9.33333"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.06249 4.95817C4.10953 4.95817 4.95832 4.10938 4.95832 3.06234C4.95832 2.0153 4.10953 1.1665 3.06249 1.1665C2.01545 1.1665 1.16666 2.0153 1.16666 3.06234C1.16666 4.10938 2.01545 4.95817 3.06249 4.95817Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.91666 12.8335C3.88315 12.8335 4.66666 12.05 4.66666 11.0835C4.66666 10.117 3.88315 9.3335 2.91666 9.3335C1.95016 9.3335 1.16666 10.117 1.16666 11.0835C1.16666 12.05 1.95016 12.8335 2.91666 12.8335Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.0833 12.8335C12.0498 12.8335 12.8333 12.05 12.8333 11.0835C12.8333 10.117 12.0498 9.3335 11.0833 9.3335C10.1168 9.3335 9.33334 10.117 9.33334 11.0835C9.33334 12.05 10.1168 12.8335 11.0833 12.8335Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.99249 5.25C3.25499 6.27083 4.18833 7.02917 5.29083 7.02334L7.29166 7.0175C8.81999 7.01167 10.1208 7.99167 10.5992 9.35667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
