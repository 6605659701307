import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const PercintageGrey: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="16" fill="#B9BAC1" />
                <path
                    d="M14 9.33331H18C21.3333 9.33331 22.6666 10.6666 22.6666 14V18C22.6666 21.3333 21.3333 22.6666 18 22.6666H14C10.6666 22.6666 9.33331 21.3333 9.33331 18V14C9.33331 10.6666 10.6666 9.33331 14 9.33331Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.7134 18.18L18.0734 13.82"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.9867 14.9133C14.4395 14.9133 14.8067 14.5462 14.8067 14.0933C14.8067 13.6404 14.4395 13.2733 13.9867 13.2733C13.5338 13.2733 13.1667 13.6404 13.1667 14.0933C13.1667 14.5462 13.5338 14.9133 13.9867 14.9133Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3467 18.7267C18.7996 18.7267 19.1667 18.3595 19.1667 17.9067C19.1667 17.4538 18.7996 17.0867 18.3467 17.0867C17.8938 17.0867 17.5267 17.4538 17.5267 17.9067C17.5267 18.3595 17.8938 18.7267 18.3467 18.7267Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
