import { CURRENT_USER_IS_FULLY_BLOCKED } from '@constants/authConstants'
import { Box, FraudView, FullBlockingView, openNotification } from '@dltru/dfa-ui'
import { useDispatch, useSelector } from 'react-redux'

import { dropAuth } from '@store/auth'
import IAppState from '@store/states'

import { AuthSideBox } from '@components/SideBox'

import { TwoFactorAuthForm } from './components/2FactorAuthForm'
import { LoginForm } from './components/LoginForm'
import { TelephoneAuthForm } from './components/TelephoneAuthForm'

export const Login = () => {
    const { is2Fa, status2Fa, isFirstTime, isFraud, fraudRemainingTimeInSec, error } = useSelector(
        (store: IAppState) => store.auth,
    )
    const reduxDispatch = useDispatch()
    const onFinish = () => reduxDispatch(dropAuth())
    const isFullyBlocked = error === CURRENT_USER_IS_FULLY_BLOCKED

    const isBlock = isFraud || isFullyBlocked

    const logoutAlarm = localStorage.getItem('logout-alarm')
    if (logoutAlarm) {
        openNotification({
            type: 'info',
            message:
                'Ваш сеанс работы в системе был завершен автоматически в связи с отсутствием активности',
        })

        localStorage.removeItem('logout-alarm')
    }

    return (
        <>
            <Box direction="row" align="center" justify="center" height="100%">
                <Box direction="row">
                    <AuthSideBox />
                    {!isBlock &&
                        ((isFirstTime && <TelephoneAuthForm />) ||
                            (is2Fa && status2Fa && <TwoFactorAuthForm />) ||
                            (!isFirstTime && !is2Fa && <LoginForm />))}
                    {isFraud && (
                        <FraudView
                            fraudRemainingTimeInSec={fraudRemainingTimeInSec}
                            onFinish={onFinish}
                        />
                    )}
                    {isFullyBlocked && <FullBlockingView onPrev={onFinish} />}
                </Box>
            </Box>
        </>
    )
}
