import { EmployeeStatus } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { employeeSlice } from './index'

function* getItems({ payload }: ReturnType<typeof employeeSlice.actions.getItems>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getEmployees, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.items) {
            yield* put(employeeSlice.actions.setItems(data))
        }
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при получении списка сотрудников' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}

function* getItem({ payload }: ReturnType<typeof employeeSlice.actions.getItem>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getEmployeeById, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.items?.length) {
            const item = data.items[0]
            yield* put(employeeSlice.actions.setItem(item))
            if (item.status === EmployeeStatus.blocked) {
                const { data } = yield* call(api.lib.getUsersBlockingEvents, {
                    user_uuid: payload,
                    order: 'desc(created_at)' as const,
                })
                if (data?.items) {
                    yield* put(employeeSlice.actions.setBlockingEvent(data.items[0]))
                }
            } else {
                yield* put(employeeSlice.actions.setBlockingEvent(null))
            }
        } else {
            openMessage({ type: 'error', message: 'Просмотр сотрудника недоступен' })
        }
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при получении сотрудника' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}
function* getAuthDataItem({ payload }: ReturnType<typeof employeeSlice.actions.getAuthDataItem>) {
    try {
        const { data, error } = yield* call(api.lib.fetchAuthData, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.item) {
            yield* put(employeeSlice.actions.setAuthDataItem(data.item))
        }
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении авторизационных данных сотрудника',
        })
    }
}

function* createItem({ payload }: ReturnType<typeof employeeSlice.actions.createItem>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.postEmployee, payload.data)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        openMessage({ type: 'success', message: 'Сотрудник успешно добавлен' })
        payload.callback()
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при создании сотрудника' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}

function* updateItem({ payload }: ReturnType<typeof employeeSlice.actions.updateItem>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.updateEmployee, payload.user_uuid, payload.data)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        openMessage({ type: 'success', message: 'Данные по сотруднику успешно изменены' })
        payload.callback()
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при изменении сотрудника' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}

function* archiveItem({ payload }: ReturnType<typeof employeeSlice.actions.archiveItem>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.archiveEmployee, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(employeeSlice.actions.getItem(payload))
        openMessage({ type: 'success', message: 'Сотрудник успешно отправлен в архив' })
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при отправке в архив' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}

function* blockItem({ payload }: ReturnType<typeof employeeSlice.actions.blockItem>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.blockEmployee, payload.user_uuid, payload.data)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(employeeSlice.actions.getItem(payload.user_uuid))
        openMessage({ type: 'success', message: 'Сотрудник успешно заблокирован' })
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при блокировке сотрудника' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}

function* unBlockItem({ payload }: ReturnType<typeof employeeSlice.actions.unBlockItem>) {
    try {
        yield* put(employeeSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(
            api.lib.unBlockEmployee,
            payload.user_uuid,
            payload.data,
        )
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(employeeSlice.actions.getItem(payload.user_uuid))
        openMessage({ type: 'success', message: 'Сотрудник успешно разблокирован' })
    } catch (error) {
        yield* put(employeeSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при разблокировке сотрудника' })
    } finally {
        yield* put(employeeSlice.actions.setIsLoading(false))
    }
}

export function* watchEmployee(): Generator<StrictEffect> {
    yield* takeLatest(employeeSlice.actions.getItems.type, getItems)
    yield* takeLatest(employeeSlice.actions.getItem.type, getItem)
    yield* takeLatest(employeeSlice.actions.getAuthDataItem.type, getAuthDataItem)
    yield* takeLatest(employeeSlice.actions.createItem.type, createItem)
    yield* takeLatest(employeeSlice.actions.updateItem.type, updateItem)
    yield* takeLatest(employeeSlice.actions.archiveItem.type, archiveItem)
    yield* takeLatest(employeeSlice.actions.blockItem.type, blockItem)
    yield* takeLatest(employeeSlice.actions.unBlockItem.type, unBlockItem)
}
