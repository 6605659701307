import { commonValidators, onlyDigits } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & { name?: WrappedInputProps['name'] }
export const KppInput = ({ className, label, name, placeholder, ...restProps }: ComponentProps) => {
    return (
        <WrappedInput
            {...restProps}
            className={className ?? 'formComponentItem'}
            label={label ?? 'КПП'}
            name={name ?? 'kpp'}
            placeholder={placeholder ?? 'Состоит из 9 цифр'}
            rules={[{ validator: commonValidators.number(9) }]}
            maxLength={9}
            normalize={onlyDigits}
        />
    )
}
