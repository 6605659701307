import { DEFAULT_PER_PAGE } from '@dltru/dfa-ui'
import { IInitialState } from './types'

export const initialState: IInitialState = {
    isLoading: false,
    data: {},
    params: { limit: DEFAULT_PER_PAGE },
    error: null,
    currentTx: null,
    summary: null,
}
