import { openMessage } from '@dltru/dfa-ui'
import qs from 'qs'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import {
    getCurrentStatementQualify,
    getStatementQualify,
    getStatementQualifyEvents,
    putStatementRequestQualify,
    qualifyStatementRequestQualify,
    qualifyStatementSlice,
} from '.'

function* qualifyStatementRequestHandler({
    payload,
}: ReturnType<typeof qualifyStatementRequestQualify>) {
    try {
        const { error, data } = yield* call(api.lib.requestQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            openMessage({
                type: 'success',
                message: 'Заявка отправлена на согласование',
            })
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка  при запросе для установки квалификации',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* getCurrentStatementQualifyHandler({
    payload,
}: ReturnType<typeof getCurrentStatementQualify>) {
    try {
        const { error, data } = yield* call(api.lib.getQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item && data?.item.items) {
            yield* put(qualifyStatementSlice.actions.setCurrentData(data?.item.items[0]))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка ',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* getStatementQualifyHandler({ payload }: ReturnType<typeof getStatementQualify>) {
    try {
        yield* put(qualifyStatementSlice.actions.setIsLoading(true))
        const _payload = { ...payload }
        const filters = { ..._payload.filters }
        delete _payload.filters

        const payloadStringify = qs.stringify(_payload, { arrayFormat: 'brackets', encode: false })

        let queryString = `?${payloadStringify}&application_type=inclusion`

        if (filters) {
            queryString = `?${Object.values(filters).join(
                '&',
            )}&${payloadStringify}&application_type=inclusion`
        }

        const { data } = yield* call(api.lib.getQualifyRegistryService, queryString)

        if (data.error || data.item?.error) {
            throw data.error || data.item?.error
        }

        if (data.item) {
            yield* put(qualifyStatementSlice.actions.setData(data.item))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка ',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* getStatementQualifyEventsHandler({
    payload,
}: ReturnType<typeof getStatementQualifyEvents>) {
    try {
        const { error, data } = yield* call(api.lib.getQualifyRegistryEventsService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* put(qualifyStatementSlice.actions.setEvents(data?.item.items))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка ',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* qualifyStatementPutHandler({ payload }: ReturnType<typeof putStatementRequestQualify>) {
    try {
        const { error, data } = yield* call(api.lib.updateQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            openMessage({
                type: 'success',
                message: 'Заявка отправлена на согласование',
            })
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка  при запросе для установки квалификации',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(true))
    }
}

export function* qualifyStatementWatch(): Generator<StrictEffect> {
    yield* takeLatest(qualifyStatementRequestQualify.type, qualifyStatementRequestHandler)
    yield* takeLatest(getCurrentStatementQualify.type, getCurrentStatementQualifyHandler)
    yield* takeLatest(getStatementQualify.type, getStatementQualifyHandler)
    yield* takeLatest(getStatementQualifyEvents.type, getStatementQualifyEventsHandler)
    yield* takeLatest(putStatementRequestQualify.type, qualifyStatementPutHandler)
}
