import { GetWorkdayParams, IRequest, ResponseItem } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'

export async function getCalendarWorkday(
    params: GetWorkdayParams,
): Promise<IRequest<ResponseItem<string>>> {
    return requestDecorator.call(
        this,
        this.paths.CalendarWorkday,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}
