import { FC, useEffect, useState } from 'react'

import { Input, SearchOutlined, useDebounce } from '../../..'
import styles from './styles.m.less'

interface ISearchInputProps {
    onSearch: (searchValue: string) => void
    maxWidth?: number
    initialValue?: string
}
export const SearchInput: FC<ISearchInputProps> = ({
    onSearch,
    maxWidth = 300,
    initialValue = '',
}) => {
    const [searchValue, setSearchValue] = useState(initialValue)
    useEffect(() => {
        if (!initialValue) {
            setSearchValue(initialValue)
        }
    }, [initialValue])

    const debouncedSearchTerm = useDebounce(searchValue)
    useEffect(() => {
        onSearch(searchValue)
    }, [debouncedSearchTerm])

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.currentTarget.value)
    }
    return (
        <Input
            value={searchValue}
            onChange={onChangeHandler}
            placeholder="Поиск"
            prefix={<SearchOutlined className={styles.searchIcon} />}
            style={{ maxWidth }}
        />
    )
}
