/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { CertificateType, IPagingResponse } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    items: CertificateType[]
    error: string | null
    isLoading: boolean
    paging: IPagingResponse | undefined
}
const initialState: IInitialState = {
    items: [],
    error: null,
    isLoading: false,
    paging: undefined,
}
export const certificateSlice = createSlice({
    name: 'certificateSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<IInitialState['error']>) {
            state.error = action.payload ?? null
        },
        setData(state, action: PayloadAction<Pick<IInitialState, 'items' | 'paging'>>) {
            state.items = action.payload.items
            state.paging = action.payload.paging
        },
        clearData(state) {
            state.items = []
            state.paging = undefined
            state.error = null
            state.isLoading = false
        },
        getItemsByUser(_, action: PayloadAction<string>) {},
        removeCertificate(_, action: PayloadAction<{ uuid: string; user_uuid: string }>) {},
    },
})

export default certificateSlice.reducer
