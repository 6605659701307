import { FC } from 'react'

import { WrappedInput, WrappedSelect } from '../../components/Form'
import { purposesOfActivityOptions, relationshipOfCompanyOptions } from './resources'

interface IComponentProps {
    uneditable: boolean
}
export const FinanceAndPlannedForm: FC<IComponentProps> = ({ uneditable }) => {
    return (
        <>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Сведения о планируемых операциях"
                    name="planned_operations"
                    uneditable={uneditable}
                    placeholder="Операции с ЦФА"
                    initialValue="Операции с ЦФА"
                    disabled
                    required
                />
            </div>
            <div className="formRow">
                <WrappedSelect
                    label="Предполагаемый характер деловых отношений с Компанией"
                    uneditable={uneditable}
                    className="formComponentItem"
                    name="business_relations_type"
                    options={relationshipOfCompanyOptions}
                />
            </div>
            <div className="formRow">
                <WrappedSelect
                    label="Сведения о целях финансово-хозяйственной деятельности"
                    uneditable={uneditable}
                    className="formComponentItem"
                    name="financial_activity_purpose"
                    options={purposesOfActivityOptions}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Сведения об источниках происхождения денежных средств"
                    name="sources_funds"
                    placeholder="Прибыль от коммерческой деятельности или иное (укажите свой вариант)"
                    uneditable={uneditable}
                    required
                    maxLength={100}
                />
            </div>
        </>
    )
}
