// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_JEitM .headerBold_oJj_G {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.modal_JEitM .grayBlock_MPtoq {\n  padding: 8px 16px;\n  background-color: #f8f8fa;\n  border: 1px solid #ebecf0;\n  border-radius: 12px;\n}\n.modal_JEitM .grayBlock_MPtoq .anticon {\n  margin-right: 6px;\n}\n.modal_JEitM .caption__qRzH {\n  display: block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: #868991;\n}\n.modal_JEitM .footer_zPdKN {\n  padding: 16px 0;\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/BlockClient/style.m.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAJA;EASI,iBAAA;EACA,yBAAA;EACA,yBAAA;EACA,mBAAA;AAFJ;AAVA;EAeQ,iBAAA;AAFR;AAbA;EAoBI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ;AApBA;EA4BI,eAAA;EACA,aAAA;EACA,yBAAA;EACA,QAAA;AALJ","sourcesContent":["@import 'style/palette';\n\n.modal {\n  .headerBold {\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n  }\n  .grayBlock {\n    padding: 8px 16px;\n    background-color: @gray-2;\n    border: 1px solid @gray-4;\n    border-radius: 12px;\n    :global {\n      .anticon {\n        margin-right: 6px;\n      }\n    }\n  }\n  .caption {\n    display: block;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    color: @gray-7;\n  }\n\n  .footer {\n    padding: 16px 0;\n    display: flex;\n    justify-content: flex-end;\n    gap: 8px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_JEitM",
	"headerBold": "headerBold_oJj_G",
	"grayBlock": "grayBlock_MPtoq",
	"caption": "caption__qRzH",
	"footer": "footer_zPdKN"
};
export default ___CSS_LOADER_EXPORT___;
