import { AppState } from '@store/index'

const selectItems = (state: AppState) => state.certificates.items
const selectPaging = (state: AppState) => state.certificates.paging
const selectIsLoading = (state: AppState) => state.certificates.isLoading
const selectError = (state: AppState) => state.certificates.error

export const certificateSelectors = {
    selectItems,
    selectPaging,
    selectIsLoading,
    selectError,
}
