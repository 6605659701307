import { IRequest, ITransferDeal, ITransferOrder, ITransferParams } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItems } from '../../types/types'

export async function getTransferOrderService(
    params: Partial<ITransferParams>,
): Promise<IRequest<ResponseItems<ITransferOrder>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrders,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getTransferDfaDeals(
    params?: Partial<ITransferParams>,
): Promise<IRequest<ResponseItems<ITransferDeal>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaDeals,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}
