import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Certificate: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14px"
                height="14px"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.5 2.5H1.5C1.22344 2.5 1 2.72344 1 3V13C1 13.2766 1.22344 13.5 1.5 13.5H14.5C14.7766 13.5 15 13.2766 15 13V3C15 2.72344 14.7766 2.5 14.5 2.5ZM13.875 12.375H2.125V3.625H13.875V12.375Z"
                    fill="currentColor"
                />
                <path
                    d="M9.53573 7.4375H11.4639C11.4842 7.4375 11.4998 7.38125 11.4998 7.3125V6.5625C11.4998 6.49375 11.4842 6.4375 11.4639 6.4375H9.53573C9.51542 6.4375 9.49979 6.49375 9.49979 6.5625V7.3125C9.49979 7.38125 9.51542 7.4375 9.53573 7.4375ZM9.61073 9.6875H12.5123C12.5732 9.6875 12.6232 9.63125 12.6232 9.5625V8.8125C12.6232 8.74375 12.5732 8.6875 12.5123 8.6875H9.61073C9.54979 8.6875 9.49979 8.74375 9.49979 8.8125V9.5625C9.49979 9.63125 9.54979 9.6875 9.61073 9.6875ZM3.49979 10.5156H4.18573C4.25135 10.5156 4.30448 10.4641 4.30917 10.3984C4.36854 9.60938 5.02792 8.98438 5.82792 8.98438C6.62792 8.98438 7.28729 9.60938 7.34667 10.3984C7.35135 10.4641 7.40448 10.5156 7.4701 10.5156H8.15604C8.173 10.5156 8.18978 10.5122 8.20536 10.5055C8.22095 10.4989 8.23502 10.4891 8.24671 10.4768C8.25841 10.4646 8.26748 10.45 8.27338 10.4341C8.27928 10.4182 8.28189 10.4013 8.28104 10.3844C8.23729 9.55156 7.78104 8.82656 7.11542 8.41406C7.40895 8.09139 7.57115 7.67058 7.5701 7.23438C7.5701 6.26719 6.79042 5.48438 5.82948 5.48438C4.86854 5.48438 4.08885 6.26719 4.08885 7.23438C4.08885 7.68906 4.26073 8.10156 4.54354 8.41406C4.20468 8.62405 3.92168 8.91293 3.7187 9.25603C3.51572 9.59914 3.3988 9.98627 3.37792 10.3844C3.37167 10.4562 3.42792 10.5156 3.49979 10.5156ZM5.82792 6.42188C6.27323 6.42188 6.63573 6.78594 6.63573 7.23438C6.63573 7.68281 6.27323 8.04688 5.82792 8.04688C5.3826 8.04688 5.0201 7.68281 5.0201 7.23438C5.0201 6.78594 5.3826 6.42188 5.82792 6.42188Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
