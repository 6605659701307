import { frontUrlWithLogout } from '@constants/authConstants'
import { DELETE_LAST_ENTER_TIMEOUT } from '@constants/common'
import { isAuthenticated } from '@utils/auth'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { logout } from '@store/auth'

import { UnauthorizedHeader } from '../Header'
import { ContentBox } from './ContentBox'

export const UnauthorizedLayout: FC = () => {
    const isAuth = isAuthenticated()
    const { pathname } = useLocation()
    const reduxDispatch = useDispatch()

    if (isAuth) {
        if (frontUrlWithLogout.some((url) => pathname.includes(url))) {
            reduxDispatch(logout())
        } else {
            const lastEnter = localStorage.getItem('last-enter') || '/offers'

            setTimeout(() => {
                localStorage.removeItem('last-enter')
            }, DELETE_LAST_ENTER_TIMEOUT)

            return <Navigate to={lastEnter} replace />
        }
    }

    return (
        <>
            <UnauthorizedHeader />
            <ContentBox type="unauthorized">
                <Outlet />
            </ContentBox>
        </>
    )
}
