import {
    ESpecialAccountType,
    GetTransactionParams,
    ReserveReleasePostData,
    ReserveTransferPostData,
    TransactionPostData,
} from '@dltru/dfa-models'
import { PayloadAction, createSlice, createAction } from '@reduxjs/toolkit'
import { initialState } from './constants'

export const accountSauSlice = createSlice({
    name: 'accountSau',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        clearItems: (state) => {
            state.error = ''
            state.isLoading = false
            state.data = {}
            state.params = initialState.params
        },
        setTsx: (state, action) => {
            state.data = action.payload
            state.isLoading = false
            state.error = null
        },

        setSummary: (state, action) => {
            state.summary = action.payload
            state.isLoading = false
            state.error = null
        },
    },
})

export const getSauAccountSummary = createAction<ESpecialAccountType>(
    'accountSau/getSauAccountSummary',
)
export const getSauAccountsTxs = createAction<GetTransactionParams>('accountSau/getSauAccountsTxs')
export const debitFromSauAccount = createAction('accountSau/debitFromSauAccount')

export const reserveTransferSau = createAction<ReserveTransferPostData>(
    'accountSau/reserveTransferSau',
)
export const reserveReleaseSau = createAction<ReserveReleasePostData>(
    'accountSau/reserveReleaseSau',
)
export const transactionCreditSau = createAction<TransactionPostData>(
    'accountSau/transactionCreditSau',
)
export const bindAccount = createAction('accountSau/bindAccount')

export default accountSauSlice.reducer
