import { Modal } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { tariffSelector } from '@store/tariff/selectors'

import { EditGridModalBody } from './EditGridModalBody'

export type PropsForModalType = {
    isCreate?: boolean
    gridId?: string
}
interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    props: PropsForModalType
}

export const EditGridModal: FC<IComponentProps> = ({
    props,
    isModalVisible,
    setIsModalVisible,
}) => {
    const currentPlane = useSelector(tariffSelector.selectItem)
    const lastNumber = currentPlane?.grids?.[0]?.code_id ?? 1
    const grid = currentPlane?.grids?.find(({ id }) => props.gridId === id)
    const onCancel = () => {
        setIsModalVisible(false)
    }
    if (!props.isCreate && !props.gridId) {
        return null
    }

    return (
        <Modal
            title={
                props.isCreate
                    ? `Создание тарифной сетки №${lastNumber + 1}`
                    : `Редактирование тарифной сетки №${grid?.code_id}`
            }
            visible={isModalVisible}
            onCancel={onCancel}
            destroyOnClose
            width={1200}
        >
            <EditGridModalBody {...props} onCancel={onCancel} gridForEdit={grid} />
        </Modal>
    )
}
