import { Alert } from '@dltru/dfa-ui'
import { FC } from 'react'

export const AlertAboutBlock: FC = () => (
    <Alert
        description={
            <>
                <p>
                    Полное блокирование означает запрет на доступ Пользователя в ЛК, на направление
                    Пользователем заявления о допуске ЦФА к выпуску, Заявки на приобретение, Заявки,
                    Оферты, Акцепта, а также на внесение в Реестр ЦФА по указанию Пользователя любой
                    записи в отношении ЦФА, учитываемых на Кошельке Пользователя и на направление
                    Пользователем Поручения о перечислении на Банковский счет такого Пользователя.
                </p>
                <p>
                    Частичное блокирование означает запрет на направление Пользователем заявления о
                    допуске ЦФА к выпуску, Заявки на приобретение, Заявки, Оферты, Акцепта, а также
                    на внесение в Реестр ЦФА по указанию Пользователя любой записи в отношении ЦФА,
                    учитываемых на Кошельке Пользователя и на направление Пользователем Поручения о
                    перечислении на Банковский счет такого Пользователя
                </p>
            </>
        }
        type="warning"
        showIcon
    />
)
