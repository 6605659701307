import { Form, IStep, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInput } from '@components/Form'

const STEP_INDEX = 2

export const Step3: FC<Partial<IStep>> = (props) => {
    const { editable, currentStep } = useContext(StepperContext)
    const [form] = Form.useForm()

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    return (
        <Step
            stepIndex={STEP_INDEX}
            form={form}
            stepTitle="Подписанное заявление на допуск ЦФА к выпуску"
            {...props}
        >
            <div className="formRow">
                <UploadInput
                    className="formComponentItem upload-form__styled"
                    name="admission_application"
                    uneditable={isEdit}
                    required
                    label="Файл заявления"
                    labelOnlyUneditable={false}
                    title="Загрузите подписанное Заявление о допуске ЦФА к выпуску. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    maxSizeMb={5}
                    maxCount={1}
                />
            </div>
            <div className="formRow">
                <UploadInput
                    className="formComponentItem upload-form__styled"
                    name="admission_application_signature"
                    uneditable={isEdit}
                    required
                    label="Файл подписи"
                    title="Загрузите файл подписи с расширением SIG, SGN"
                    accept=".sig,.sgn"
                    maxSizeMb={5}
                    maxCount={1}
                    labelOnlyUneditable={false}
                />
            </div>
        </Step>
    )
}
