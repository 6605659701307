import { DatePicker, DatePickerProps } from 'antd'
import moment, { Moment } from 'moment'
import { FC } from 'react'

import { LOCALE } from '../../config'
import { toDate, toDateTime } from '../../utils'
import { DefaultUneditable, FormItem } from '../Form'
import { FormItemProps } from '../Form'

type DatePeriod = {
    onlyPast?: boolean
    onlyBeforeDate?: Moment
    onlyBeforeDateAndToday?: Moment
    onlyWorkDays?: boolean
    onlyFuture?: boolean
    disableTime?: boolean
    showTime?: boolean
}

export type DateItemProps = FormItemProps &
    Pick<DatePickerProps, 'disabled' | 'onChange' | 'getPopupContainer'> &
    DatePeriod & {
        showQuickLinks?: boolean
    }

const endOfDay = () => moment().endOf('day')
const onlyPastFilter: DatePickerProps['disabledDate'] = (d) => d.endOf('day') >= endOfDay()
const onlyBeforeDateFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') >= date.endOf('day')
    }
const onlyBeforeDateAndTodayFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') > date.endOf('day')
    }
const onlyFutureFilter: DatePickerProps['disabledDate'] = (d) =>
    d.endOf('day') < endOfDay() && d.isoWeekday() > 5
const onlyPastWorkdaysFilter: DatePickerProps['disabledDate'] = (d) =>
    d.endOf('day') > endOfDay() || d.isoWeekday() > 5
const onlyFutureWorkdaysFilter: DatePickerProps['disabledDate'] = (d) =>
    d.endOf('day') < endOfDay() || d.isoWeekday() > 5
const onlyBeforeDateWorkdaysFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') >= date.endOf('day') || cloned.isoWeekday() > 5
    }
const onlyBeforeDateAndTodayWorkdaysFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') > date.endOf('day') || cloned.isoWeekday() > 5
    }

function datePeriodFilter({
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
}: DatePeriod) {
    if (onlyPast) {
        return onlyWorkDays ? onlyPastWorkdaysFilter : onlyPastFilter
    }

    if (onlyBeforeDate) {
        return onlyWorkDays
            ? onlyBeforeDateWorkdaysFilter(onlyBeforeDate)
            : onlyBeforeDateFilter(onlyBeforeDate)
    }

    if (onlyBeforeDateAndToday) {
        return onlyWorkDays
            ? onlyBeforeDateAndTodayWorkdaysFilter(onlyBeforeDateAndToday)
            : onlyBeforeDateAndTodayFilter(onlyBeforeDateAndToday)
    }

    if (onlyFuture) {
        return onlyWorkDays ? onlyFutureWorkdaysFilter : onlyFutureFilter
    }
}

export const DateAndTimeItem: FC<DateItemProps> = ({
    label,
    disabled,
    prefix,
    onChange,
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
    disableTime = false,
    getPopupContainer,
    ...rest
}) => {
    const filter = datePeriodFilter({
        onlyWorkDays,
        onlyPast,
        onlyFuture,
        onlyBeforeDate,
        onlyBeforeDateAndToday,
    })

    const renderUneditable = (value: any) =>
        DefaultUneditable(prefix, disableTime ? toDate(value) : toDateTime(value))

    return (
        <FormItem {...rest} label={label} renderUneditable={renderUneditable}>
            <DatePicker
                onChange={onChange}
                locale={LOCALE}
                disabled={disabled}
                disabledDate={filter}
                placeholder={disableTime ? 'дд.мм.гггг' : 'дд.мм.гггг чч:мм'}
                format={disableTime ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'}
                getPopupContainer={getPopupContainer}
                showTime={!disableTime}
            />
        </FormItem>
    )
}
