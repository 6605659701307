import { PaymentOrder } from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { paymentOrdersSlice } from '@store/paymentOrders'
import { paymentOrdersSelector } from '@store/paymentOrders/selectors'

import { gridConfig } from './gridConfig'

export const PaymentOrdersTable: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const { items, paging } = useSelector(paymentOrdersSelector.selectPaymentOrdersData)
    const isLoading = useSelector(paymentOrdersSelector.selectIsLoading)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(paymentOrdersSlice.actions.getPaymentOrders({ limit }))
    }

    const onNext = () => {
        reduxDispatch(
            paymentOrdersSlice.actions.getPaymentOrders({ cursor: paging?.cursors?.next }),
        )
    }

    const onPrev = () => {
        reduxDispatch(
            paymentOrdersSlice.actions.getPaymentOrders({ cursor: paging?.cursors?.prev }),
        )
    }

    useEffect(() => {
        reduxDispatch(paymentOrdersSlice.actions.getPaymentOrders({ limit: DEFAULT_PER_PAGE }))
    }, [])

    const onRow = (record: PaymentOrder) => ({
        onClick: () => {
            navigate(record.transaction_uuid)
        },
    })

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={items ?? []}
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            onRow={onRow}
            clickableRow
        />
    )
}
