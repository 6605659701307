/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    BlockEmployeeData,
    EditEmployeeType,
    GetEmployeesParams,
    IAuthData,
    IClientBlockListItem,
    ICreateEmployee,
    ICursorPaginationResponse,
    IEmployee,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: Partial<ICursorPaginationResponse<IEmployee>>
    error: string
    currentEmployee: IEmployee
    currentAuthDataEmployee: IAuthData
    currentBlockingEvent: IClientBlockListItem | null
    params: GetEmployeesParams
}
const initialState: IInitialState = {
    isLoading: false,
    error: '',
    data: {},
    currentEmployee: {} as IEmployee,
    currentAuthDataEmployee: {} as IAuthData,
    currentBlockingEvent: null,
    params: { limit: DEFAULT_PER_PAGE },
}

export const employeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        setItems: (state, action: PayloadAction<ICursorPaginationResponse<IEmployee>>) => {
            state.data.items = action.payload.items
            state.data.paging = action.payload.paging
        },
        setItem: (state, action: PayloadAction<IEmployee>) => {
            state.currentEmployee = action.payload
        },
        setAuthDataItem: (state, action: PayloadAction<IAuthData>) => {
            state.currentAuthDataEmployee = action.payload
        },
        setBlockingEvent: (state, action: PayloadAction<IClientBlockListItem | null>) => {
            state.currentBlockingEvent = action.payload
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        clear: (state) => {
            state.error = ''
            state.isLoading = false
            state.data = {}
            state.params = { limit: DEFAULT_PER_PAGE }
        },
        clearItem: (state) => {
            state.error = ''
            state.isLoading = false
            state.currentEmployee = {} as IEmployee
            state.currentAuthDataEmployee = {} as IAuthData
            state.currentBlockingEvent = null
        },
        createItem: (
            _,
            action: PayloadAction<{ data: ICreateEmployee; callback: () => void }>,
        ) => {},
        updateItem: (
            _,
            action: PayloadAction<{
                user_uuid: string
                data: EditEmployeeType
                callback: () => void
            }>,
        ) => {},
        getItems: (state, action: PayloadAction<GetEmployeesParams | undefined>) => {
            state.params = isEmptyValue(action.payload) ? initialState.params : action.payload
        },
        getItem: (_, action: PayloadAction<string>) => {},
        getAuthDataItem: (_, action: PayloadAction<string>) => {},
        archiveItem: (_, action: PayloadAction<string>) => {},
        blockItem: (_, action: PayloadAction<{ user_uuid: string; data: BlockEmployeeData }>) => {},
        unBlockItem: (
            _,
            action: PayloadAction<{ user_uuid: string; data: BlockEmployeeData }>,
        ) => {},
    },
})

export default employeeSlice.reducer
