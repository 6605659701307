import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Convert: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.0045 18.3202C14.5973 18.3202 18.3205 14.597 18.3205 10.0042C18.3205 5.41144 14.5973 1.68823 10.0045 1.68823C5.41168 1.68823 1.68848 5.41144 1.68848 10.0042C1.68848 14.597 5.41168 18.3202 10.0045 18.3202Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.11426 3.35132L11.9168 10.1704L11.9334 6.38666"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.8855 16.6487L8.08304 9.83789L8.06641 13.6134"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
