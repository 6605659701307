import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const EditSquare: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.6562 10H16.5625C16.4766 10 16.4062 10.0703 16.4062 10.1562V16.4062H3.59375V3.59375H9.84375C9.92969 3.59375 10 3.52344 10 3.4375V2.34375C10 2.25781 9.92969 2.1875 9.84375 2.1875H2.8125C2.4668 2.1875 2.1875 2.4668 2.1875 2.8125V17.1875C2.1875 17.5332 2.4668 17.8125 2.8125 17.8125H17.1875C17.5332 17.8125 17.8125 17.5332 17.8125 17.1875V10.1562C17.8125 10.0703 17.7422 10 17.6562 10Z"
                    fill="currentColor"
                />
                <path
                    d="M6.95119 10.4473L6.91408 12.7695C6.91213 12.9434 7.05275 13.0859 7.22658 13.0859H7.23439L9.53908 13.0293C9.57814 13.0273 9.61721 13.0117 9.64455 12.9844L17.7676 4.87891C17.8281 4.81836 17.8281 4.71875 17.7676 4.6582L15.3399 2.23242C15.3086 2.20117 15.2696 2.1875 15.2285 2.1875C15.1875 2.1875 15.1485 2.20313 15.1172 2.23242L6.99611 10.3379C6.9679 10.3674 6.95186 10.4065 6.95119 10.4473ZM8.19143 10.9082L15.2285 3.88672L16.1113 4.76758L9.07033 11.793L8.17775 11.8145L8.19143 10.9082Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
