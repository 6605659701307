// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".twoColumns_N4kMp {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/TwoColumns/style.m.less"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,qCAAA;AAFJ","sourcesContent":["@import '/src/assets/style/palette';\n\n// TWO_COLUMNS\n.twoColumns {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twoColumns": "twoColumns_N4kMp"
};
export default ___CSS_LOADER_EXPORT___;
