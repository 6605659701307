import { ITariffUserLink } from '@dltru/dfa-models'
import { ColumnsType, LinkCell, profileTypeTranslate } from '@dltru/dfa-ui'

export const gridConfig: ColumnsType<ITariffUserLink> = [
    {
        title: 'ИНН',
        dataIndex: 'inn',
        key: 'inn',
    },
    {
        title: 'Пользователь',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        ellipsis: true,
        render: (value: string, record: ITariffUserLink) =>
            LinkCell(value, `/clients/${record.user_id}`),
    },
    {
        title: 'Тип пользователя',
        dataIndex: 'user_type',
        key: 'user_type',
        sorter: true,
        ellipsis: true,
        render: (value) => <>{profileTypeTranslate[value as keyof typeof profileTypeTranslate]}</>,
    },
]
