import { Alert, Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import React, { FC } from 'react'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import style from './style.m.less'

interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
}

export const CancelRemoveUserModal: FC<IComponentProps> = ({ visible, setVisible }) => {
    const [form] = Form.useForm()

    const hide = () => {
        setVisible(false)
        form.resetFields()
    }

    return (
        <Modal
            title="Отмена процедуры исключения Пользователя из реестра пользователей"
            visible={visible}
            onCancel={hide}
            width={600}
            footer={[
                <Button borderRadius={12} onClick={hide}>
                    Закрыть
                </Button>,
                <Button type="primary" borderRadius={12}>
                    Отменить процедуру исключения
                </Button>,
            ]}
        >
            <Alert
                type="warning"
                showIcon
                description="Пользователь не будет исключен из реестра пользователей, но в отношении него останется полное блокирование."
            />
            <Divider margin={[24, 0]} />
            <Form form={form} layout="vertical" style={{ marginBottom: 24 }}>
                <h4 className={style.modalTitle}>Основание отмены</h4>
                <UploadDocumentContainerWrapper
                    name="docs"
                    title="Загрузите фотографии или сканы документов"
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png"
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="reason"
                        multiline
                        required
                        rows={4}
                    />
                </UploadDocumentContainerWrapper>
            </Form>
        </Modal>
    )
}
