import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const FileUnknown: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="22"
                height="28"
                viewBox="0 0 22 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.7062 7.02188L14.9813 0.29375C14.7938 0.10625 14.5375 0 14.2719 0H1C0.446875 0 0 0.446875 0 1V27C0 27.5531 0.446875 28 1 28H21C21.5531 28 22 27.5531 22 27V7.72812C22 7.4625 21.8937 7.20938 21.7062 7.02188ZM19.6938 8.1875H13.8125V2.30625L19.6938 8.1875ZM19.75 25.75H2.25V2.25H11.6875V9C11.6875 9.3481 11.8258 9.68194 12.0719 9.92808C12.3181 10.1742 12.6519 10.3125 13 10.3125H19.75V25.75ZM7.5625 15.1562C7.5625 15.325 7.7 15.4531 7.86875 15.4531H8.88125C9.05 15.4531 9.1875 15.3219 9.1875 15.1594C9.1875 14.2781 9.99375 13.5469 11 13.5469C12.0063 13.5469 12.8125 14.2781 12.8125 15.1562C12.8125 15.9469 12.1562 16.6313 11.2719 16.7469C10.6688 16.8344 10.1938 17.3813 10.1875 18V19C10.1875 19.1719 10.3281 19.3125 10.5 19.3125H11.5C11.6719 19.3125 11.8125 19.1719 11.8125 19V18.6187C11.8125 18.4312 11.9375 18.2594 12.1156 18.2031C13.5094 17.7531 14.4594 16.5156 14.4375 15.1125C14.4125 13.3781 12.9 11.9625 11.0469 11.9375C9.12813 11.9156 7.5625 13.3625 7.5625 15.1562ZM10 21.25C10 21.5152 10.1054 21.7696 10.2929 21.9571C10.4804 22.1446 10.7348 22.25 11 22.25C11.2652 22.25 11.5196 22.1446 11.7071 21.9571C11.8946 21.7696 12 21.5152 12 21.25C12 20.9848 11.8946 20.7304 11.7071 20.5429C11.5196 20.3554 11.2652 20.25 11 20.25C10.7348 20.25 10.4804 20.3554 10.2929 20.5429C10.1054 20.7304 10 20.9848 10 21.25Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
