import clsx from 'clsx'
import { FC } from 'react'

import { Button } from '../../../../../button'
import style from './style.m.less'

export interface ITradeButtonProps {
    color: 'red' | 'green'
    onClick: (e: React.SyntheticEvent) => void
    label: string
    disabled?: boolean
}
export const TradeButton: FC<ITradeButtonProps> = ({ color, onClick, label, disabled }) => {
    return (
        <Button
            className={clsx(style.tradeButton, style[color])}
            borderRadius={16}
            onClick={onClick}
            disabled={disabled}
            size="large"
            width="70%"
            block
        >
            {label}
        </Button>
    )
}
