import AntIcon from '@ant-design/icons'
import { ReactNode } from 'react'

import { Select } from '../../Select'
import { telephoneCodesDictionary } from './countriesFlags'

const { Option } = Select

export const prefixSelectorFactory: (
    defaultValue: string,
    onChange: (value: string) => void,
) => ReactNode = (defaultValue, onChange) => {
    return (
        <Select onChange={onChange} defaultValue={defaultValue} style={{ width: 70 }}>
            {telephoneCodesDictionary.map((element) => (
                <Option value={element.code} key={element.code}>
                    <AntIcon style={{ fontSize: '20px', padding: '4px' }} viewBox={'0 0 1024 1024'}>
                        {element.flag}
                    </AntIcon>
                </Option>
            ))}
        </Select>
    )
}
