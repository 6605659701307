import { ITransferOrderShort, TransferOrderStatus } from '@dltru/dfa-models'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormSpace,
    OrdinaryCardField,
    OrdinaryCardFieldContentColumn,
    getDateString,
} from '@dltru/dfa-ui'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { DfasInfoCard } from '@pages/OwnershipTransfer/components/DfasInfoCard/DfasInfoCard'
import { PriceInfoCard } from '@pages/OwnershipTransfer/components/PriceInfoCard/PriceInfoCard'

import { UneditableFile } from '@components/Form/components/UploadInput'

interface IComponentProps {
    data?: ITransferOrderShort
}

export const DFAOwnershipReceiver: FC<IComponentProps> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <>
            <Card>
                <CardHeader title="Условия оферты" />
                <Box direction="row" justify="space-between">
                    <CardContent>
                        <OrdinaryCardField label="Тип оферты">
                            {data?.is_public ? 'Публичная оферта' : 'Индивидуальное предложение'}
                        </OrdinaryCardField>
                        <OrdinaryCardField label="Оферент">
                            <Link to={`/clients/${data?.seller_id}`}>
                                {data?.seller_full_name || data.seller_id}
                            </Link>
                        </OrdinaryCardField>
                        <Divider />
                        {!data?.is_public && (
                            <>
                                <OrdinaryCardField label="Акцептант">
                                    <Link to={`/clients/${data.buyer_id}`}>
                                        {data?.buyer_full_name || data?.buyer_id}
                                    </Link>
                                </OrdinaryCardField>
                                <Divider />
                            </>
                        )}

                        <OrdinaryCardField label="Срок действия предложения">
                            {data?.created_at ? getDateString(data?.created_at) : ''} -{' '}
                            {data?.expired_at ? getDateString(data?.expired_at) : ''}
                        </OrdinaryCardField>
                        <OrdinaryCardField label="Загруженные документы">
                            <OrdinaryCardFieldContentColumn>
                                <UneditableFile uid={data?.base_document_uuid} />
                            </OrdinaryCardFieldContentColumn>
                        </OrdinaryCardField>

                        {data?.status === TransferOrderStatus.executed && (
                            <>
                                <Divider />
                                <OrdinaryCardField label="Дата исполнения">
                                    {data?.executed_at ? getDateString(data?.expired_at) : ''}
                                </OrdinaryCardField>
                            </>
                        )}
                    </CardContent>
                </Box>
            </Card>
            <Card>
                <CardHeader title="Встречное предоставление Оферента" />

                <CardContent>
                    <FormSpace>
                        {Boolean(data?.seller_asset_data?.asset_id) && (
                            <DfasInfoCard
                                dfaId={data?.seller_asset_data?.asset_id}
                                value={data?.seller_asset_data.amount_dfa}
                                type={'offerent'}
                            />
                        )}
                        {Boolean(data?.seller_asset_data?.total_price) && (
                            <PriceInfoCard
                                price={Number(data?.seller_asset_data?.total_price) / 100}
                            />
                        )}
                    </FormSpace>
                </CardContent>
            </Card>

            <Card>
                <CardHeader title="Встречное предоставление Акцептанта" />

                <CardContent>
                    <FormSpace>
                        {Boolean(data?.buyer_asset_data?.asset_id) && (
                            <DfasInfoCard
                                dfaId={data?.buyer_asset_data?.asset_id}
                                value={data?.buyer_asset_data.amount_dfa}
                                type={'acceptor'}
                            />
                        )}
                        {Boolean(data?.buyer_asset_data?.total_price) && (
                            <PriceInfoCard
                                price={Number(data?.buyer_asset_data?.total_price) / 100}
                            />
                        )}
                    </FormSpace>
                </CardContent>
            </Card>
        </>
    )
}
