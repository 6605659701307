import { FormItemProps as AntFormItemProps } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import cn from 'classnames'
import { Moment } from 'moment'
import { FC, ReactNode } from 'react'

import { useFormValues } from '../../../hooks'
import { REQUIRED } from '../../../utils'
import { Form } from '../Form'
import { DefaultUneditable } from './DefaultUneditable'
import './srtyle.less'

type Value = string | number | boolean | Moment | Moment[] | UploadFile[]
export type FormItemProps<T = Value> = AntFormItemProps & {
    prefix?: ReactNode
    uneditable?: boolean
    noRequiredSymbol?: boolean
    column?: boolean
    renderUneditable?: (value?: T) => ReactNode
    listName?: string
}

function defaultRules(isRequired: boolean) {
    return isRequired ? [REQUIRED] : []
}
export const FormItem: FC<FormItemProps> = ({
    name,
    className,
    children,
    prefix,
    uneditable = false,
    rules = [],
    required = false,
    noRequiredSymbol = false,
    listName,
    renderUneditable = (value) => DefaultUneditable(prefix, value),
    column,
    ...rest
}) => {
    const { getFormValue } = useFormValues({ name, listName })
    const formItemChildren = !uneditable ? children : renderUneditable(getFormValue())

    const classNameFormItem = cn('form-item', className, {
        'form-item--column': column,
        'form-item--uneditable': uneditable,
    })

    return (
        <Form.Item
            {...rest}
            name={name}
            rules={[...rules, ...defaultRules(required)]}
            className={classNameFormItem}
            required={noRequiredSymbol ? false : required}
        >
            {formItemChildren}
        </Form.Item>
    )
}
