export enum BaseDescriptionEnum {
    buy_sell_contract = 'buy_sell_contract',
    donation_contract = 'donation_contract',
    other = 'other',
    inheritance = 'inheritance',
    reorganization = 'reorganization',
    judicial_act = 'judicial_act',
}

export const BaseDescriptionTranslate = {
    [BaseDescriptionEnum.buy_sell_contract]: 'Договор купли-продажи',
    [BaseDescriptionEnum.donation_contract]: 'Договор дарения',
    [BaseDescriptionEnum.other]: 'Прочие',
    [BaseDescriptionEnum.inheritance]: 'Наследование',
    [BaseDescriptionEnum.reorganization]: 'Реорганизация',
    [BaseDescriptionEnum.judicial_act]: 'Судебный акт',
}
export const baseDescriptionUser = [
    BaseDescriptionEnum.donation_contract,
    BaseDescriptionEnum.buy_sell_contract,
    BaseDescriptionEnum.other,
]
export const baseDescriptionAdmin = [
    BaseDescriptionEnum.inheritance,
    BaseDescriptionEnum.reorganization,
    BaseDescriptionEnum.judicial_act,
]
