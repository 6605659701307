import clsx from 'clsx'
import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useState } from 'react'

import {
    Button,
    CHECK_STATUSES,
    CheckList,
    CheckListItem,
    authValidators,
    getErrorStatusesOfPasswordField,
} from '../../..'
import { Box } from '../../../Box'
import { Form, LoginInput, WrappedInput } from '../../../components/Form'
import { AuthForm } from '../AuthForm'
import './style.less'

type FormValuesType = {
    login: string
    password: string
    confirm_password: string
}

interface IComponentProps {
    sendNewAuthData: (login: string, password: string) => void
}
export const ChangeLoginForm: FC<IComponentProps> = ({ sendNewAuthData }) => {
    const [form] = Form.useForm<FormValuesType>()
    const [statuses, setStatuses] = useState(CHECK_STATUSES)
    const [step, setStep] = useState<'login' | 'password'>('login')
    const [loginIsComplete, setLoginIsComplete] = useState(false)
    const [passwordIsComplete, setPasswordIsComplete] = useState(false)
    const [passwordConfirmIsComplete, setPasswordConfirmIsComplete] = useState(false)
    const [passwordValue, setPasswordValue] = useState('')

    const onFieldsChange = (fields: FieldData[], allFields: FieldData[]) => {
        const loginChanges = fields.find((item) => (item.name as string[]).includes('login'))
        if (loginChanges) {
            setLoginIsComplete(Boolean(loginChanges.touched && !loginChanges.errors?.length))
            return
        }
        const passwordChanges = fields.find((item) => (item.name as string[]).includes('password'))

        if (passwordChanges) {
            setStatuses(getErrorStatusesOfPasswordField(statuses, passwordChanges))
            setPasswordIsComplete(
                Boolean(passwordChanges.touched && !passwordChanges.errors?.length),
            )
            setPasswordValue(passwordChanges.value)
            const passwordConfirmState = allFields.find((item) =>
                (item.name as string[]).includes('confirm_password'),
            )
            if (passwordConfirmState?.value) {
                form.validateFields(['confirm_password'])
            }
            return
        }
        const passwordConfirmChanges = fields.find((item) =>
            (item.name as string[]).includes('confirm_password'),
        )
        if (passwordConfirmChanges) {
            setPasswordConfirmIsComplete(
                Boolean(passwordConfirmChanges.touched && !passwordConfirmChanges.errors?.length),
            )
            return
        }
    }

    const disableSubmitBtn = !passwordIsComplete || !passwordConfirmIsComplete || !loginIsComplete

    const loginBoxClassName = clsx('change-auth-form', {
        'change-auth-form--hidden': step !== 'login',
    })
    const passwordBoxClassName = clsx('change-auth-form', {
        'change-auth-form--hidden': step !== 'password',
    })

    const sendNewAuthDataHandler = (values: FormValuesType) => {
        sendNewAuthData(values.login, values.password)
    }

    return (
        <AuthForm
            title={`Задайте ${step === 'password' ? 'пароль' : 'логин'}`}
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={sendNewAuthDataHandler}
        >
            <Box className={loginBoxClassName}>
                <LoginInput label="Новый логин" size="large" />
                <Box padding={[16, 24, 48, 24]} className="change-auth-form__gray-text">
                    Новый логин не должен совпадать ни с одним из старых. <br />
                    Логин должен быть длиной от 6 до 30 символов и может содержать только:
                    <ul className="change-auth-form__list">
                        <li>латинские прописные (A-Z) и строчных (a-z) буквы,</li>
                        <li>цифры, символы тире «-»,</li>
                        <li>нижнее подчеркивание «_» и точка «.».</li>
                    </ul>
                </Box>
                <Box direction="row" justify="right">
                    <Button
                        onClick={() => setStep('password')}
                        borderRadius={16}
                        type="primary"
                        size="large"
                        disabled={!loginIsComplete}
                    >
                        Далее
                    </Button>
                </Box>
            </Box>

            <Box className={passwordBoxClassName}>
                <WrappedInput
                    className="no-error-messages"
                    size="large"
                    name="password"
                    label="Новый пароль"
                    password
                    required
                    rules={[...statuses.map((status: CheckListItem) => status.rule)]}
                />
                <WrappedInput
                    className="change-auth-form__without-margin"
                    size="large"
                    name="confirm_password"
                    label="Подтверждение пароля"
                    password
                    required
                    rules={[
                        {
                            validator: authValidators.comparePassword(passwordValue),
                        },
                    ]}
                />
                <Box margin={[-8, 0, 24, 0]}>
                    <CheckList elements={statuses} />
                </Box>
                <Box direction="row" justify="space-between">
                    <Button
                        onClick={() => setStep('login')}
                        borderRadius={16}
                        type="primary"
                        size="large"
                    >
                        Назад
                    </Button>
                    <Button
                        onClick={form.submit}
                        borderRadius={16}
                        type="primary"
                        size="large"
                        disabled={disableSubmitBtn}
                    >
                        Далее
                    </Button>
                </Box>
            </Box>
        </AuthForm>
    )
}
