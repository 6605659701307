import { createAction, createSlice } from '@reduxjs/toolkit'

interface IBlocking {
    blockClientWalletModal: boolean
    unblockClientWalletModal: boolean
    injunctionModal: boolean
    liftingOfInjunctionModal: boolean
    modalTitle: string
    activeDFAs: number
    injunctionDFAs: number
    userUuid: string | null
    assetId: string | null
    error: string
}

export interface IInjunction {
    user_uuid: string | null
    asset_id: string | null
    amount_dfa: number
    executor_uuid?: string
    attachment_uuid: string
    description: string
    skid: string
}

const initialState = {
    blockClientWalletModal: false,
    unblockClientWalletModal: false,
    injunctionModal: false,
    liftingOfInjunctionModal: false,
    modalTitle: '',
    activeDFAs: 0,
    injunctionDFAs: 0,
    userUuid: null,
    assetId: null,
} as IBlocking

const blockingSlice = createSlice({
    name: 'blocking',
    initialState,
    reducers: {
        dropBlocking() {
            return { ...initialState }
        },
        updateBlocking(state, action) {
            return { ...state, ...action.payload }
        },
        setBlockClientWalletModal(state, action) {
            state.blockClientWalletModal = action.payload
        },
        setUnblockClientWalletModal(state, action) {
            state.unblockClientWalletModal = action.payload
        },
        setInjunctionModal(state, action) {
            state.injunctionModal = action.payload
        },
        setLiftingOfInjunctionModal(state, action) {
            state.liftingOfInjunctionModal = action.payload
        },
    },
})

export const {
    updateBlocking,
    setBlockClientWalletModal,
    setUnblockClientWalletModal,
    setInjunctionModal,
    setLiftingOfInjunctionModal,
    dropBlocking,
} = blockingSlice.actions

export const makeDFAInjunction = createAction<IInjunction>('blocking/makeDFAInjunction')
export const makeDFALiftingOfInjunction = createAction<IInjunction>(
    'blocking/makeDFALiftingOfInjunction',
)

export default blockingSlice.reducer
