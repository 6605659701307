import { BankAccountDetailsModel, BindBankAccountModel, MoneyAccountType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    DataProviderResponse,
    Divider,
    Form,
    Modal,
    MoneyAccountBlock,
    Requisites,
    WrappedAutocomplete,
    WrappedInput,
} from '@dltru/dfa-ui'
import { FC, useLayoutEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import api from '@services/api'

import style from './style.m.less'

//TODO может лишний компонент? Это почти полный дубль /ClientCard/components/Footer/modals/RelateAccountModal.tsx
interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    action: (data: BindBankAccountModel) => void
    balanceInfo?: Partial<
        Pick<MoneyAccountType, 'nominal_account' | 'created_at' | 'number' | 'balance'>
    >
    userName: string
    userUuid: string
}
type FormValues = {
    nominal_account_uuid: string
    user: string
}

type SelectDataType = { account_details: BankAccountDetailsModel } & DataProviderResponse
const AccountOption: FC<{ data?: SelectDataType }> = ({ data }) => {
    if (!data) {
        return null
    }
    return (
        <span>
            {data?.account_details?.account_number}
            <br />
            {data?.account_details?.bank}
        </span>
    )
}

export const RelateAccountModal: FC<IComponentProps> = ({
    visible,
    setVisible,
    action,
    balanceInfo,
    userName,
    userUuid,
}) => {
    const [selectedAccount, setSelectedAccount] = useState<SelectDataType[]>([])

    useLayoutEffect(() => {
        if (balanceInfo?.nominal_account && visible) {
            setSelectedAccount([
                {
                    account_details: balanceInfo.nominal_account.account_details,
                    label: balanceInfo.nominal_account.account_details.account_number,
                    value: balanceInfo.nominal_account.id,
                },
            ])
            form.setFieldsValue({ nominal_account_uuid: balanceInfo.nominal_account.id })
        }
    }, [balanceInfo?.nominal_account, visible])

    const [form] = Form.useForm<FormValues>()

    const onCancel = () => {
        setVisible(false)
        form.resetFields()
        setSelectedAccount([])
    }

    const onOk = (values: FormValues) => {
        action({
            nominal_account_uuid: values.nominal_account_uuid,
            user_uuid: userUuid as string,
        })
        onCancel()
    }

    const onSelectHandler = (selectedOptions: SelectDataType[]) => {
        setSelectedAccount(selectedOptions)
    }
    const getAccounts = async (value?: string) => {
        try {
            if (!value) {
                return []
            }
            const { data } = await api.lib.getNominalAccounts({
                account_number: `like(${value})`,
            })
            return (
                data?.items?.map((item) => ({
                    account_details: item.account_details,
                    label: item.account_details.account_number,
                    value: item.id,
                })) ?? []
            )
        } catch (error) {
            return []
        }
    }

    const initialValues = {
        user: userName,
    }

    const requisites = useMemo(
        () =>
            selectedAccount?.length
                ? [
                      {
                          label: 'Номер счёта',
                          value: selectedAccount[0].account_details.account_number,
                      },
                      {
                          label: 'Наименование банка',
                          value: selectedAccount[0].account_details.bank,
                      },
                  ]
                : undefined,
        [selectedAccount],
    )

    const disableBtn =
        !selectedAccount?.length || selectedAccount[0].value === balanceInfo?.nominal_account?.id

    return (
        <Modal
            title="Привязать Лицевой счёт к банковскому счёту"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button
                    type="primary"
                    borderRadius={12}
                    disabled={disableBtn}
                    onClick={form.submit}
                >
                    Привязать ЛС к банковскому счёту
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onOk} initialValues={initialValues}>
                <WrappedInput label="Пользователь" name="user" size="large" required disabled />
                {balanceInfo?.number && (
                    <MoneyAccountBlock
                        accountNumber={balanceInfo?.number}
                        balance={balanceInfo?.balance.available}
                        title="Лицевой счёт"
                    />
                )}
                <Divider margin={[balanceInfo?.number ? 24 : 0, 0, 24, 0]} />
                <h4 className={style.headerBold}>Банковский счёт</h4>
                <span className={style.label}>
                    Данный банковский счёт будет привязан к лицевому счёту после регистрации
                    пользователя в системе.
                </span>
                <div className={style.grayBlock}>
                    <span className={style.label}>
                        Выберите нужный счёт из списка банковских счетов.
                    </span>
                    <WrappedAutocomplete
                        dataProvider={getAccounts}
                        onSelect={onSelectHandler}
                        defaultOptions={selectedAccount}
                        renderElement={<AccountOption />}
                        name="nominal_account_uuid"
                        selectProps={{
                            placeholder: 'Начните вводить номер банковского счета...',
                            allowClear: true,
                        }}
                        required
                    />
                    {requisites && <Requisites requisites={requisites} />}
                </div>
                <Box padding={[24, 0, 0, 0]} direction="row" justify="right">
                    Не нашли банковский счёт?
                    <Link className={style.link} to="/nominal-account/create">
                        &nbsp;Создайте новый
                    </Link>
                    .
                </Box>
            </Form>
        </Modal>
    )
}
