import { BlackListType, IBlacklist, IRequest, ResponseItems } from '@dltru/dfa-models'

import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'
import { requestDecorator } from '../index'

export async function getBlacklistByUser(
    uuid: string,
): Promise<IRequest<ResponseItems<IBlacklist>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileBlacklistByUuid, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
export async function putBlacklist(data: IBlacklist[]): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileBlacklistByUuid, data?.[0]?.user_uuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function deleteBlacklistByUser(
    uuid: string,
    data: BlackListType[],
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileBlacklistByUuid, uuid),
        HttpMethod.DELETE,
        HttpCode.SUCCESS_200,
        { data },
    )
}
