import { GetTariffsParams, ITariffPlane } from '@dltru/dfa-models'
import { Box, Button, Filters, PlusSquare, SearchInput, Space, Table } from '@dltru/dfa-ui'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { tariffSlice } from '@store/tariff'
import { tariffSelector } from '@store/tariff/selectors'

import api from '@services/api'

import { ArchivePlaneModal } from '@pages/Tariff/View/components/Footer/ArchivePlaneModal'

import { BaseTariff } from './BaseTariff'
import { filters } from './filters'
import { getGridConfig } from './gridConfig'
import { RbacContext } from '@dltru/dfa-rbac'

export const TariffTable: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currenTariffPlane, setCurrenTariffPlane] = useState<ITariffPlane>()
    const items = useSelector(tariffSelector.selectList)
    const paging = useSelector(tariffSelector.selectPaging)
    const isLoading = useSelector(tariffSelector.selectIsLoading)
    const params = useSelector(tariffSelector.selectParams)
    const showCreateBtn = isAllow(['tariff.write.all'])

    useEffect(() => {
        reduxDispatch(tariffSlice.actions.getItems())
        return () => {
            reduxDispatch(tariffSlice.actions.clearItems())
        }
    }, [])

    const gridConfig = useMemo(() => {
        const createFromTariffPlane = async (tariff_id: string) => {
            const data = await api.lib.getTariffPlane({ tariff_id })
            if (data?.data?.items?.[0]) {
                navigate('/tariff/create', { state: data.data.items[0] })
            }
        }
        const archiveTariffPlane = (tariff: ITariffPlane) => {
            setCurrenTariffPlane(tariff)
            setIsModalVisible(true)
        }

        return getGridConfig({
            createFromTariffPlane,
            archiveTariffPlane,
        })
    }, [])

    const onPerPageChange = (limit: number) => {
        reduxDispatch(tariffSlice.actions.getItems({ ...params, limit }))
    }

    const onNext = () => {
        reduxDispatch(tariffSlice.actions.getItems({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(tariffSlice.actions.getItems({ cursor: paging?.cursors?.prev }))
    }

    const onRow = (record: ITariffPlane) => ({
        onClick: () => navigate(`/tariff/${record.id}`),
    })

    const onSearch = (query: string) => {
        /* TODO пока что не работает поиск */
        // eslint-disable-next-line no-console
        console.log(query)
    }

    const onApplyFilters = (filters?: Partial<GetTariffsParams>) => {
        reduxDispatch(tariffSlice.actions.getItems({ ...params, ...filters }))
    }

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <BaseTariff />
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items ?? []}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                onRow={onRow}
                rowKey="id"
                clickableRow
                headerLeft={
                    <Space>
                        <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                        <SearchInput onSearch={onSearch} initialValue={params.name ?? ''} />
                    </Space>
                }
                footerLeft={
                    showCreateBtn && (
                        <Button
                            icon={<PlusSquare />}
                            borderRadius={12}
                            onClick={() => navigate('/tariff/create')}
                        >
                            Добавить тарифный план
                        </Button>
                    )
                }
            />

            <ArchivePlaneModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                data={currenTariffPlane}
            />
        </Box>
    )
}
