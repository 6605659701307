import dayjs from 'dayjs'

export const enum DocumentsEnum {
    register_of_qualified_investors = 'register_of_qualified_investors',
    user_account_operations_history = 'user_account_operations_history',
    beneficiary_nominal_account_balance = 'beneficiary_nominal_account_balance',
    nominal_account_operations_history = 'nominal_account_operations_history',
    user_profile = 'user_profile',
    dfa_release_result = 'dfa_release_result',
    user_dfa_operations_history = 'user_dfa_operations_history',
    user_dfa_wallet = 'user_dfa_wallet',
}

export type GetDocumentsParams = {
    user_id?: string
    account_id?: string
    dfa_id?: number
    ticker?: string
}

type GetDocumentTitle = {
    userId?: string
    userName?: string
    ticker?: string
}

export const documentsEnumInfo = {
    [DocumentsEnum.register_of_qualified_investors]: {
        getTitle: ({ userId }: GetDocumentTitle) =>
            userId ? 'Выписка из реестра' : 'Реестр квалифицированных инвесторов',
        extension: '.pdf',
        contentType: 'application/pdf',
    },
    [DocumentsEnum.user_account_operations_history]: {
        getTitle: () => 'Выписка по лицевому счету',
        extension: '.pdf',
        contentType: 'application/pdf',
    },
    [DocumentsEnum.beneficiary_nominal_account_balance]: {
        getTitle: () => 'Выписка в разрезе бенефициаров',
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.nominal_account_operations_history]: {
        getTitle: () => 'Выписка по операциям',
        extension: '.pdf',
        contentType: 'application/pdf',
    },
    [DocumentsEnum.user_profile]: {
        getTitle: () => 'Анкета клиента',
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.dfa_release_result]: {
        getTitle: ({ ticker }: GetDocumentTitle) =>
            `Отчёт об итогах выпуска ЦФА_${dayjs().format('DD.MM.YYYY')}_${ticker}`,
        extension: '.docx',
        contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    [DocumentsEnum.user_dfa_operations_history]: {
        getTitle: ({ userName }: GetDocumentTitle) =>
            `Отчет об операциях ${userName}_${dayjs().format('DD.MM.YYYY')}`,
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.user_dfa_wallet]: {
        getTitle: ({ userName }: GetDocumentTitle) =>
            `Отчёт о ЦФА ${userName}_${dayjs().format('DD.MM.YYYY')}`,
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
}
