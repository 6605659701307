import {
    IInvestorRegistryApplicationPost,
    IInvestorRegistryApplicationPut,
    IRequest,
    InvestorRegistryApplication,
    QualifiedRegistryChangesType,
    QualifyInfoType,
    ResponseError,
    ResponseItem,
    SetQualifiedRequestType,
    SetSetQualifiedResponseType,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function getQualifyInfo(
    uid: string,
): Promise<IRequest<ResponseItem<QualifyInfoType, ResponseError<string>>>> {
    return requestDecorator.call(
        this,
        path(this.paths.QualifyInfo, uid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function createInvestorRegistryService<T>(
    params: QualifiedRegistryChangesType,
): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.QualifyRegistrySet,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: params },
    )
}

export async function deleteInvestorRegistryService<T>(
    params: QualifiedRegistryChangesType,
): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.QualifyRegistryDelete,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: params },
    )
}

export async function requestQualifyRegistryService(
    params: IInvestorRegistryApplicationPost,
): Promise<IRequest<SetSetQualifiedResponseType>> {
    return requestDecorator.call(
        this,
        this.paths.QualifyRegistryRequestPost,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: params },
    )
}

export async function updateQualifyRegistryService(
    params: IInvestorRegistryApplicationPut,
): Promise<IRequest<SetSetQualifiedResponseType>> {
    return requestDecorator.call(
        this,
        this.paths.QualifyRegistryRequestPost,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data: params },
    )
}

export async function getQualifyRegistryService(
    queryString: string,
): Promise<IRequest<InvestorRegistryApplication>> {
    /*const paramsWithOrder = {
        order: 'desc(id)',
    }*/

    return requestDecorator.call(
        this,
        `${this.paths.QualifyRegistryRequest}${queryString}`,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        //{ params: paramsWithOrder },
    )
}

export async function getQualifyRegistryEventsService(
    queryString: string,
): Promise<IRequest<InvestorRegistryApplication>> {
    return requestDecorator.call(
        this,
        `${this.paths.QualifyRegistryEvents}${queryString}`,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
