import { EmployeeStatus, userRolesTranslate } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    Box,
    Card,
    CardContent,
    CardField,
    CardHeader,
    CopyButton,
    DateTimeCell,
    Divider,
    PageHeader,
    Space,
    Tag,
} from '@dltru/dfa-ui'
import { getEmployeeStatusTagProps } from '@resources/employeeState'
import React, { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { employeeSlice } from '@store/employee'
import { employeeSelector } from '@store/employee/selectors'

import { AlertEmployeeBlock } from '@pages/Employee/View/components/AlertEmployeeBlock'

import { AuthInfo } from '@components/AuthInfo'

import { Footer } from './components/Footer'

export const EmployeeView: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const data = useSelector(employeeSelector.selectItem)
    const authData = useSelector(employeeSelector.selectAuthDataItem)
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            reduxDispatch(employeeSlice.actions.getItem(id))
            reduxDispatch(employeeSlice.actions.getAuthDataItem(id))
        }
        return () => {
            reduxDispatch(employeeSlice.actions.clearItem())
        }
    }, [id])

    if (!data?.user_uuid) {
        return null
    }

    const isActive = data.status === EmployeeStatus.active
    const tagProps = getEmployeeStatusTagProps(data.status)
    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`${data.last_name} ${data.first_name} ${data.second_name}`}
                    subTitle={
                        data.user_uuid ? (
                            <>
                                ID {data.user_uuid} <CopyButton text={data.user_uuid} />
                            </>
                        ) : undefined
                    }
                    tags={data.status && <Tag color={tagProps.color}>{tagProps.title}</Tag>}
                    onBack={() => navigate(-1)}
                />
            </Box>
            <Card>
                <CardHeader title="Информация о пользователе" />
                <AlertEmployeeBlock />
                <CardContent>
                    <CardField title="Должность" value={data.job_title} />
                    <CardField title="Подразделение" value={data.organization_unit} />
                    <CardField title="Роль" value={data.role && userRolesTranslate[data.role]} />
                    <Divider />
                    <CardField
                        title="Дата добавления в систему"
                        value={
                            <Space>
                                <span>{DateTimeCell(data.created_at)}</span>
                                <Link to={`/employees/${data.created_by}`}>{`${
                                    data.created_by_full_name || data.created_by
                                } ${
                                    data.created_by_role
                                        ? `(${userRolesTranslate[data.created_by_role]})`
                                        : ''
                                }`}</Link>
                            </Space>
                        }
                    />
                </CardContent>
            </Card>
            {id && <AuthInfo authData={authData} userUuid={id} isEmployee isActive={isActive} />}
            {isAllow(['employee.write.all']) && <Footer />}
        </>
    )
}
