import { IApprovalHistoryUI } from '@dltru/dfa-models'
import { Card, CardContent, CardHeader, Table } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { gridConfig } from './gridConfig'

export const ServiceHistory: FC = () => {
    const data = useSelector(clientsSelector.selectApprovalHistoryUI)
    const approvalHistoryProfile = useSelector(clientsSelector.selectCurrentClientApprovalHistory)
    const isLoading = useSelector(clientsSelector.selectIsLoading)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(clientsSlice.actions.getApprovalHistoryRepresentative())
    }, [])

    useEffect(() => {
        if (approvalHistoryProfile.length) {
            reduxDispatch(clientsSlice.actions.getOldApprovalHistoryRepresentative())
        }
    }, [approvalHistoryProfile])

    return (
        <Card className="boxContent">
            <CardHeader title="История согласования" />
            <CardContent>
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={data}
                    bordered
                    rowKey={(record: IApprovalHistoryUI) =>
                        `${record.user_uuid}${record.start_datetime}`
                    }
                />
            </CardContent>
        </Card>
    )
}
