import { Box, DecisionHeader, Divider, Milestone, Space, Tag, getDateHelper } from '@dltru/dfa-ui'
import { getTagProps } from '@resources/dfaState'
import { Link, useSearchParams } from 'react-router-dom'

import { IDfaDetailsState } from '@store/dfa/types'

import style from './style.m.less'

interface IEmissionDecisionCard {
    dfa: IDfaDetailsState
}

export const EmissionDecisionCardForOwnershipTransfer: React.FC<IEmissionDecisionCard> = ({
    dfa,
}) => {
    const [searchParams] = useSearchParams()
    const balance = searchParams.get('amount')
        ? {
              active_amount_dfa: Number(searchParams.get('amount')),
          }
        : undefined
    const infotags = [
        {
            value: getDateHelper(dfa.issue_date),
            label: 'Начало торгов',
        },
        { value: getDateHelper(dfa.redeem_date), label: 'ЦФА будут погашены' },
    ]
    const tagProps = getTagProps(dfa.status)

    return (
        <Box padding={[24, 24, 16, 24]}>
            <Box direction="row" justify="left" className={style.emissionCardHeading}>
                <Space size={[8, 0]}>
                    <Link to={`/offers/${dfa.id}`}>{dfa.ticker_symbol}</Link>
                    <Link to={`/offers/${dfa.id}`}>{dfa.title}</Link>
                    <Tag color={tagProps.color}>{tagProps.title}</Tag>
                </Space>
            </Box>
            <Divider margin={[16, 0]} />
            <DecisionHeader dfa={dfa} balance={balance} />
            <div>
                <div className={style.cardRow}>
                    {infotags.map((tagdata, index) => (
                        <Milestone key={index} {...tagdata} />
                    ))}
                </div>
            </div>
        </Box>
    )
}
