import { Empty, Select as SelectAntd } from 'antd'
import { SelectProps as SelectPropsAntd } from 'antd/lib/select'
import cn from 'classnames'

import { DownMini } from '../../../Icons'
import './styles.less'

const Select = ({ children, size = 'middle', ...restProps }: SelectPropsAntd) => {
    return (
        <SelectAntd
            className={cn('dfa-select', size)}
            suffixIcon={<DownMini />}
            size={size}
            {...restProps}
            notFoundContent={
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
            }
        >
            {children}
        </SelectAntd>
    )
}

Select.Option = SelectAntd.Option

export { Select }
