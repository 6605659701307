import { ITariffGrid, RateBaseType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    ColumnsType,
    Form,
    Moment,
    Space,
    Table,
    WrappedDateItem,
    commissionLabel,
    dateValidators,
    getTariffGridConfig,
} from '@dltru/dfa-ui'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { tariffSlice } from '@store/tariff'
import { tariffSelector } from '@store/tariff/selectors'

import { prepareGridFormValue } from '../../../../Create/helper'
import { useBaseRates } from '../../../../Create/hooks/useBaseRates'
import { EditableCell } from '../../../../components/EditableCell'

const components = {
    body: {
        cell: EditableCell,
    },
}

interface IComponentProps {
    onCancel: () => void
    isCreate?: boolean
    gridForEdit?: ITariffGrid
}

type FormValuesType = {
    active_from: Moment
} & Record<string, string>

export const EditGridModalBody: FC<IComponentProps> = ({ gridForEdit, isCreate, onCancel }) => {
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()
    const currentPlane = useSelector(tariffSelector.selectItem)
    const { feeList, initialValues } = useBaseRates(currentPlane.type, gridForEdit?.rates)
    const [currentRates, setCurrentRates] = useState(
        () => gridForEdit?.rates?.map(({ fee_code }) => fee_code) ?? ([] as string[]),
    )

    const columns = useMemo(
        () =>
            getTariffGridConfig({ isEditable: true }).map((col: ColumnsType<RateBaseType>) => {
                if (!col.editable) {
                    return col
                }
                return {
                    ...col,
                    onCell: (record: RateBaseType) => ({
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                    }),
                }
            }),
        [],
    )

    const onSave = (values: FormValuesType) => {
        if (!currentPlane.id) {
            return
        }
        const activeFrom = values.active_from.tz('Europe/Moscow').startOf('day').toISOString()
        if (isCreate) {
            reduxDispatch(
                tariffSlice.actions.postTariffGrid({
                    tariff_id: currentPlane.id,
                    active_from: activeFrom,
                    rates: prepareGridFormValue(currentRates, values),
                }),
            )
        } else if (!isCreate && gridForEdit?.id) {
            reduxDispatch(
                tariffSlice.actions.putTariffGrid({
                    grid_id: gridForEdit.id,
                    data: {
                        active_from: activeFrom,
                        rates: prepareGridFormValue(currentRates, values, gridForEdit.rates),
                    },
                }),
            )
        }
        onCancel()
    }

    const onSelectChange = (newSelectedRowKeys: string[]) => {
        setCurrentRates(newSelectedRowKeys)
    }
    const rowSelection = {
        selectedRowKeys: currentRates,
        onChange: onSelectChange,
    }
    return (
        <div>
            {isCreate && (
                <p>
                    Укажите Дату активации тарифной сетки, в эту дату тарифная сетка начнет
                    действовать, а предыдущая действующая перейдет в архив. Для формирования
                    тарифной сетки выберите комиссии и укажите для них параметры расчёта комиссии.
                </p>
            )}
            <Form form={form} onFinish={onSave} initialValues={initialValues}>
                <WrappedDateItem
                    label="Дата активации тарифной сетки"
                    className="formComponentItem"
                    name="active_from"
                    onlyFuture
                    disableTime
                    required
                    rules={[
                        {
                            validator: dateValidators.onlyFuture,
                        },
                    ]}
                    initialValue={
                        gridForEdit?.active_from ? moment(gridForEdit.active_from) : undefined
                    }
                />
                <Box margin={[-24, 0, 24, 0]}>
                    <Table
                        components={components}
                        columns={columns}
                        dataSource={feeList}
                        rowSelection={rowSelection}
                        rowKey="fee_code"
                        rowClassName={() => 'editable-row'}
                        noPagination
                    />
                </Box>
            </Form>
            <Space>
                <Button borderRadius={16} onClick={onCancel}>
                    Отмена
                </Button>
                <Button
                    borderRadius={12}
                    type="primary"
                    disabled={!currentRates.length}
                    onClick={form.submit}
                >
                    {isCreate ? 'Создать тарифную сетку' : 'Сохранить изменения'}
                </Button>
                <div>
                    Выбрано {currentRates.length} {commissionLabel(currentRates.length)}
                </div>
            </Space>
        </div>
    )
}
