import { ProfileStatus, ProfileType } from '../profile'

export const enum ProfileRequestType {
    INDIVIDUAL = 'individual',
    LEGAL_ENTITY = 'legal_entity',
    BUSINESSMAN = 'businessman',
    AGENT = 'agent',
    BENEFICIAL_OWNER = 'beneficial_owner',
}

export const enum ProfileCoreRequestType {
    INDIVIDUAL = 'individual',
    LEGAL_ENTITY = 'legal_entity',
    BUSINESSMAN = 'businessman',
}

export type ProfileTypeData = { type: ProfileType; user_uuid: string }

export type ReserveDetails = {
    uuid: string
    amount: number
    status: string
    account_uuid: string
    service_code: string
}

export type ProfileTypeFull = 'individual' | 'legal_entity' | 'businessman' | 'EMPL'
export interface IProfileType {
    [key: string]: ProfileTypeFull
}

export const ProfileTypes: IProfileType = {
    PRSN: 'individual',
    LEGL: 'legal_entity',
    BUSN: 'businessman',
    EMPL: 'EMPL',
}

export interface IChangeProfile {
    user_uuid: string
    employee_uuid?: string
    profile_status: ProfileStatus
    approval_datetime: number
    comment?: string
    profile_type: ProfileType
}

export type ClientAutocomplete = {
    user_uuid: string
    full_name: string
    inn: string
    type: string
}
