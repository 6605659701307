import {
    GetProfilesParams,
    IRegistrationApplication,
    approvalProfileStatuses,
} from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, Filters, PageHeader, Table } from '@dltru/dfa-ui'
import React, { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { registrationApplicationListSlice } from '@store/registrationRequests'
import { registrationRequestsSelector } from '@store/registrationRequests/selectors'

import { filters } from './filters'
import { getGridConfig } from './gridConfig'

export const RegistrationRequests: FC = () => {
    const navigate = useNavigate()
    const { isAllow } = useContext(RbacContext)
    const params = useSelector(registrationRequestsSelector.selectParams)
    const items = useSelector(registrationRequestsSelector.selectItems)
    const paging = useSelector(registrationRequestsSelector.selectPaging)
    const isLoading = useSelector(registrationRequestsSelector.selectIsLoading)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(registrationApplicationListSlice.actions.getItems(params))
        return () => {
            reduxDispatch(registrationApplicationListSlice.actions.clear())
        }
    }, [])

    const onPerPageChange = (limit: number) => {
        reduxDispatch(registrationApplicationListSlice.actions.getItems({ ...params, limit }))
    }

    const onNext = () => {
        reduxDispatch(
            registrationApplicationListSlice.actions.getItems({ cursor: paging?.cursors?.next }),
        )
    }

    const onPrev = () => {
        reduxDispatch(
            registrationApplicationListSlice.actions.getItems({ cursor: paging?.cursors?.prev }),
        )
    }

    const onRow = (record: IRegistrationApplication) => ({
        onClick: () => {
            navigate(`/clients/${record.user_uuid}`)
        },
    })

    const onApplyFilters = (filters?: Partial<GetProfilesParams>) => {
        const requestParams = {
            ...params,
            ...filters,
        }
        reduxDispatch(
            registrationApplicationListSlice.actions.getItems({
                ...requestParams,
                profile_status: requestParams.profile_status?.length
                    ? requestParams.profile_status
                    : approvalProfileStatuses,
            }),
        )
    }

    const gridConfig = getGridConfig(isAllow)

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Заявки на регистрацию" />
            </Box>
            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={items ?? []}
                    cursors={paging?.cursors}
                    onPerPage={onPerPageChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    onRow={onRow}
                    clickableRow
                    rowKey="user_uuid"
                    headerLeft={
                        <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                    }
                />
            </Box>
        </>
    )
}
