import { FC, useContext, useEffect, useState } from 'react'

import { Step, StepperContext, useEditable } from '../../../../Stepper'
import { CardDivider } from '../../../../card'
import {
    EmailInput,
    Form,
    FormSpace,
    PhoneInput,
    WebSiteInput,
    WrappedInput,
} from '../../../../components/Form'
import { commonValidators } from '../../../../utils'
import { FormListInput } from '../../FormListInput'
import { IRoleFormStepProps } from '../types'
import { NameWarningModal } from './NameWarningModal'
import { Warning } from '../../../../Icons'
import { Tooltip } from '../../../../Tooltip'

const STEP_INDEX = 0
const NAME_WARNING = `Обращаем ваше внимание, что информация, указанная в полях
"Полное наименование организации" и "Организационно-правовая форма",
должна быть строго идентична информации, указанной в ключе электронной
подписи и ЕГРЮЛ. В случае различия информации, присоединение ключа станет
невозможным.`

let isModalAlreadyShowName = false
let isModalAlreadyShowForm = false

export const LegalEntityStep1: FC<IRoleFormStepProps> = ({ isEditAllow, disableFields = [] }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowModalForm, setIsShowModalForm] = useState(false)

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow
    const phoneListName = 'phone_numbers'
    const emailListName = 'emails'
    const websiteListName = 'websites'

    useEffect(() => {
        if (!(values?.[phoneListName] as string[])?.length) {
            form.setFieldsValue({ [phoneListName]: [{}] })
        }
        if (!(values?.[emailListName] as string[])?.length) {
            form.setFieldsValue({ [emailListName]: [{}] })
        }
        if (!(values?.[websiteListName] as string[])?.length) {
            form.setFieldsValue({ [websiteListName]: [{}] })
        }
    }, [])

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Основные сведения"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FormSpace uneditable={isEdit}>
                <div className="formRow">
                    <WrappedInput
                        className="formComponentItem"
                        label={
                            <>
                                <Tooltip title={NAME_WARNING}>
                                    <Warning className="modal-icon modal-error-icon" />
                                </Tooltip>{' '}
                                Полное наименование организации
                            </>
                        }
                        name="title"
                        placeholder=""
                        uneditable={isEdit}
                        required
                        maxLength={200}
                        onFocus={() => {
                            !isModalAlreadyShowName && setIsShowModal(true)
                        }}
                    />
                </div>
                <div className="formRow">
                    <WrappedInput
                        className="formComponentItem"
                        label="Краткое наименование организации"
                        name="short_title"
                        placeholder=""
                        uneditable={isEdit}
                        required
                        maxLength={200}
                    />
                </div>
                <div className="formRow">
                    <WrappedInput
                        className="formComponentItem--xl"
                        label={
                            <>
                                <Tooltip title={NAME_WARNING}>
                                    <Warning className="modal-icon modal-error-icon" />
                                </Tooltip>{' '}
                                Организационно-правовая форма
                            </>
                        }
                        name="legal_form"
                        placeholder=""
                        uneditable={isEdit}
                        required
                        rules={[{ validator: commonValidators.stringWithSpaces() }]}
                        onFocus={() => {
                            !isModalAlreadyShowForm && setIsShowModalForm(true)
                        }}
                    />
                </div>
                <CardDivider />
                <FormListInput
                    listName={phoneListName}
                    uneditable={isEdit}
                    required
                    label="Телефон"
                    name="phone_number"
                    className="formComponentItem formComponentItem--md"
                    InputComponent={PhoneInput}
                    disabled={disableFields.includes(phoneListName)}
                />
                <FormListInput
                    listName={emailListName}
                    uneditable={isEdit}
                    required
                    label="Электронная почта"
                    name="email"
                    className="formComponentItem formComponentItem--lg"
                    InputComponent={EmailInput}
                    disabled={disableFields.includes(emailListName)}
                />
                <FormListInput
                    listName={websiteListName}
                    uneditable={isEdit}
                    label="Сайт"
                    name="website"
                    className="formComponentItem formComponentItem--xl"
                    InputComponent={WebSiteInput}
                />
            </FormSpace>

            {isShowModal && (
                <NameWarningModal
                    visible={isShowModal}
                    onCancel={() => {
                        setIsShowModal(false)
                        isModalAlreadyShowName = true
                    }}
                />
            )}
            {isShowModalForm && (
                <NameWarningModal
                    visible={isShowModalForm}
                    onCancel={() => {
                        setIsShowModalForm(false)
                        isModalAlreadyShowForm = true
                    }}
                />
            )}
        </Step>
    )
}
