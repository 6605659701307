import { UserRoles } from '../auth'
import { ClientBlockEventType } from './userBlocks'

export interface IUsersRegistry {
    user_identification_date: string //Дата начала отношений с Пользователем
    user_uuid: string //Идентификатор пользователя(ID //оффчейн Пользователя)
    user_full_name: string //Сокращенное наименование для ЮЛ ИЛИ Наименование ИП ИЛИ ФИО ФЛ
    is_node: boolean //является узлом(статус пользователя)
    is_exchange_operator: boolean //является Оператором обмена(статус пользователя)
    is_owner: boolean
    is_emitter: boolean
    address: string //Адрес кошелька ЦФА в Мастерчейне (ID-мастерчейн  Пользователя)
    user_role: UserRoles
}

export enum UsersRegistryStatus {
    // is_node = 'is_node', // @oldfox временно не используем
    is_exchange_operator = 'is_exchange_operator',
    is_owner = 'is_owner',
    is_emitter = 'is_emitter',
    is_member = 'is_member',
}

export type UsersRegistryStausTranslate = { [key in UsersRegistryStatus]: string }

export interface IUsersRegistryListItem extends IUsersRegistry {
    block_type?: UserRoles.CLIENT_PARTLY_BLOCK | UserRoles.CLIENT_FULL_BLOCK
    block_reason?: string
    status: UsersRegistryStatus[]
}

// * how to use
// UsersRegistryStausNameTranslate.is_exchange_operator
// UsersRegistryStausNameTranslate[UsersRegistryStatus.is_node]
export const UsersRegistryStausNameTranslate = {
    [UsersRegistryStatus.is_exchange_operator]: 'Оператор обмена',
    // [UsersRegistryStatus.is_node]: 'Узел', // @oldfox временно не используем
    [UsersRegistryStatus.is_owner]: 'Владелец',
    [UsersRegistryStatus.is_emitter]: 'Эмитент',
    [UsersRegistryStatus.is_member]: 'Участник',
}
