import { IEmissionDeal, IEmissionOrderDeal, unionTypeBackToFront } from '@dltru/dfa-models'
import { BodyTitle, Button, DateTime, Divider, LabelRow as Row } from '@dltru/dfa-ui'
import React, { useState } from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { Link } from 'react-router-dom'

import { EmissionOrderDetailsModal } from '@components/Modals/Orders'

import { BaseModal } from './Base'
import { CommonInfoBlock } from './CommonInfoBlock'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: IEmissionDeal
}

const EmissionModal: React.FC<IDealViewModal> = ({ deal, onResolve }) => {
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<IEmissionOrderDeal>()
    const openOrderModal = (order: IEmissionOrderDeal) => {
        setCurrentOrder(unionTypeBackToFront(order))
        setIsCardModalVisible(true)
    }

    return (
        <>
            <BaseModal deal={deal} onResolve={onResolve}>
                <BodyTitle title="Детали сделки размещения" id={deal.id} />
                <Row label="Дата и время сделки" className="label-row__space">
                    <DateTime value={deal.executed_at} />
                </Row>
                <Row label="Инвестор" className="label-row__space">
                    <Link to={`/clients/${deal.user_id}`}>{deal.user_full_name}</Link>
                </Row>
                <Row label="Заявка на покупку" className="label-row__space">
                    <Button
                        className="link-button"
                        type="link"
                        onClick={() => openOrderModal(deal.order)}
                    >
                        ID {deal.order.id}
                    </Button>
                </Row>
                <Divider />
                <CommonInfoBlock deal={deal} />
                {/* TODO комисии буду позже */}
                {/*
            <Divider />
            <Row label="Комиссии" />
            <Row label="Проведение сделки"><Price /></Row>*/}
            </BaseModal>
            <EmissionOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={currentOrder}
                secondModal
            />
        </>
    )
}

export const emissionModal = create(EmissionModal)
