import dayjs from 'dayjs'

import { VIEW_DATE_FORMAT } from '../constants'

export const getDateTimeString = (time?: number) => {
    if (typeof time !== 'number') {
        return ''
    }
    const date = dayjs.unix(time)
    return `${date.format(VIEW_DATE_FORMAT)}, ${date.format('hh:mm:ss')}`
}

export const getDateString = (timestamp?: number) => {
    if (typeof timestamp !== 'number') {
        return ''
    }
    const date = dayjs.unix(timestamp)
    return `${date.format(VIEW_DATE_FORMAT)}`
}

const fiveDaysInMs = 1000 * 60 * 60 * 24 * 5

export const isBefore = (
    currentMs: number,
    limitMs: number,
    gapMs: number = fiveDaysInMs,
): boolean => {
    return Math.abs(limitMs - currentMs) < gapMs
}
