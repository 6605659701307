import { ClientAutocomplete } from '@dltru/dfa-models'
import { EMPTY_FIELD_VALUE, WrappedAutocomplete } from '@dltru/dfa-ui'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

import api from '@services/api'

import { ClientOption } from './ClientOption'
import { InvestorList } from './InvestorsList'
import styles from './style.m.less'

interface IComponentProps {
    isEdit: boolean
}

const label = 'Список пользователей'
const fieldName = 'investors'

export const InvestorsBlock: FC<IComponentProps> = ({ isEdit }) => {
    const { getFieldValue } = useContext(FieldContext)
    const [selectedUser, setSelectedUser] = useState<ClientAutocomplete[]>([])

    const investorUuids = getFieldValue(fieldName)

    const getInitialClients = async () => {
        const investorsInitial = await api.lib.getProfileShortInfo({ user_uuid: investorUuids })
        setSelectedUser(
            investorsInitial?.data?.item?.items?.map((item) => ({
                ...item,
                label: item.full_name,
                value: item.user_uuid,
            })) ?? [],
        )
    }

    useEffect(() => {
        if (investorUuids?.length && !isEdit) {
            getInitialClients()
        } else if (!investorUuids.length) {
            setSelectedUser([])
        }
    }, [investorUuids, isEdit])

    if (isEdit) {
        return (
            <div className="ant-row ant-form-item form-item formComponentItem form-item--uneditable">
                <div className="ant-col ant-form-item-label">
                    <label>{label}</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    {investorUuids?.length > 0 ? (
                        <InvestorList investorUuids={investorUuids} />
                    ) : (
                        <div className="ant-form-item-control-input">{EMPTY_FIELD_VALUE}</div>
                    )}
                </div>
            </div>
        )
    }

    const getClients = async (value?: string) => {
        try {
            if (!value) {
                return []
            }
            const { data } = await api.lib.getClientsService({
                full_name: `like(${value})`,
            })
            return (
                data?.items?.map((item) => ({
                    ...item,
                    label: item.full_name,
                    value: item.user_uuid,
                })) ?? []
            )
        } catch (error) {
            return []
        }
    }

    return (
        <div className={styles.container}>
            <h4 className={styles.header}>Список пользователей</h4>
            <WrappedAutocomplete
                dataProvider={getClients}
                defaultOptions={selectedUser}
                name={fieldName}
                renderElement={<ClientOption />}
                selectProps={{
                    size: 'large',
                    placeholder: 'Укажите название организации или ИНН',
                    mode: 'multiple',
                }}
                required
            />
        </div>
    )
}
