import { UserRoles, userRolesTranslate } from '@dltru/dfa-models'
import {
    Card,
    CardContent,
    CardField,
    CardHeader,
    DateTimeCell,
    EMPTY_FIELD_VALUE,
    Space,
    Table,
    getTariffGridConfig,
} from '@dltru/dfa-ui'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { tariffSelector } from '@store/tariff/selectors'

export const DetailsTariffPlane: FC = () => {
    const data = useSelector(tariffSelector.selectItem)
    const feeNames = useSelector(tariffSelector.selectFeeNames)

    const gridConfig = useMemo(() => getTariffGridConfig({ feeNames }), [feeNames])
    const DataRow = (
        date: string | undefined,
        userUuid: string | undefined,
        userName?: string,
        userPosition?: UserRoles,
    ) => {
        if (!date) {
            return <span>{EMPTY_FIELD_VALUE}</span>
        }
        return (
            <Space>
                <span>{DateTimeCell(date)}</span>
                <span>
                    <Link to={`/employees/${userUuid}`}>{userName || userUuid}</Link>
                    {userPosition && <span>(${userRolesTranslate[userPosition]})</span>}
                </span>
            </Space>
        )
    }

    return (
        <div>
            <Card className="boxContent">
                <CardField
                    title="Создан"
                    value={DataRow(
                        data.created_at,
                        data.created_by,
                        data.created_by_name,
                        data.created_by_role,
                    )}
                    padding={16}
                />

                <CardField
                    title="Отправлен в архив"
                    value={DataRow(
                        data.archived_at,
                        data.archived_by,
                        data.archived_by_name,
                        data.archived_by_role,
                    )}
                    padding={16}
                />
            </Card>
            <Card className="boxContent">
                <CardHeader
                    title={
                        <span>
                            Тарифная сетка №{data?.active_grid?.code_id} действует с&nbsp;
                            {DateTimeCell(data?.active_grid?.active_from)}
                        </span>
                    }
                />
                <CardContent>
                    <Table
                        columns={gridConfig}
                        dataSource={data?.active_grid?.rates ?? []}
                        rowKey="fee_code"
                        uneditable
                        noPagination
                    />
                </CardContent>
            </Card>
        </div>
    )
}
