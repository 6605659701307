import { ITariffPlane, TariffPlaneEnum } from '@dltru/dfa-models'
import {
    Box,
    DateTimeCell,
    OpeningPanel,
    Space,
    Table,
    TariffBaseInfo,
    getTariffCode,
    getTariffGridConfig,
} from '@dltru/dfa-ui'
import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { tariffSelector } from '@store/tariff/selectors'

import { gridConfig } from './gridConfig'

export const RelatedTariff: FC = () => {
    // TODO по готовности бека и моделей возможно придется добавить пропс data
    const data = [] as ITariffPlane[] //TODO  забирать из стора, отсортрованный по code_id
    const baseTariff = useSelector(tariffSelector.selectBaseTariff)
    const feeNames = useSelector(tariffSelector.selectFeeNames)

    const getGridConfigRates = useMemo(() => getTariffGridConfig({ feeNames }), [feeNames])

    const title = data[0]
        ? `Тарифный план ${getTariffCode(data[0])} ${data[0].name} назначен с ${DateTimeCell(
              data[0].active_grid?.active_from,
          )}`
        : ''

    const expandable = useMemo(() => {
        return {
            expandedRowRender: (record: ITariffPlane) => (
                <Space direction="vertical">
                    <Table
                        columns={getGridConfigRates}
                        dataSource={record.active_grid?.rates}
                        rowKey="fee_code"
                        uneditable
                        noPagination
                        inner
                    />
                    {record.type !== TariffPlaneEnum.BASE && (
                        <TariffBaseInfo data={baseTariff} feeNames={feeNames} />
                    )}
                </Space>
            ),
            rowExpandable: (record: ITariffPlane) => record.active_grid?.rates?.length,
        }
    }, [])

    return (
        <OpeningPanel title={title}>
            <Box padding={[0, 24]} className="tablebox">
                <Table columns={gridConfig} dataSource={data} expandable={expandable} />
            </Box>
        </OpeningPanel>
    )
}
