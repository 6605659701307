import { PriceSource, RedemptionTypeDfa } from '@dltru/dfa-models'
import { Space } from 'antd'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useMemo, useState } from 'react'

import { WrappedRadioItem } from '../../../Radio'
import { StepperContext } from '../../../Stepper'
import { FormItemProps, WrappedInput } from '../../../components/Form'
import { currentFloatNumber, dfaValidators } from '../../../utils'
import styles from './style.m.less'

export interface IRedeemPricePerDfaProps {
    uneditable: boolean
    disabled?: boolean
}

const fieldName = 'redemption_type'
const defaultValue = RedemptionTypeDfa.fixed

export const RedeemPriceBlock: FC<IRedeemPricePerDfaProps> = ({ uneditable, disabled }) => {
    const { getFieldsValue, setFieldsValue } = useContext(FieldContext)

    const { values } = useContext(StepperContext)
    const valuesFromForm = getFieldsValue()

    const typeFromForm = uneditable ? values?.[fieldName] : valuesFromForm[fieldName]

    const [redemptionType, setRedemptionType] = useState(typeFromForm ?? defaultValue)

    const onChangeType = (type: RedemptionTypeDfa) => {
        setRedemptionType(type)
        if (type === RedemptionTypeDfa.fixed) {
            setFieldsValue({ redemption_description: '' })
        }
        if (type === RedemptionTypeDfa.flexible) {
            setFieldsValue({ redeem_price_per_dfa: '' })
        }
    }

    useEffect(() => {
        const priceTypeFromForm = uneditable
            ? values?.['price_source_type']
            : valuesFromForm['price_source_type']

        if (priceTypeFromForm === PriceSource.dynamic || !typeFromForm) {
            setFieldsValue({ [fieldName]: defaultValue })
            onChangeType(defaultValue)
        }
    }, [])

    const radioOptions = useMemo(() => {
        const isFixed = redemptionType === RedemptionTypeDfa.fixed
        const FixedComponent = (
            <>
                <label htmlFor={RedemptionTypeDfa.fixed}>Фиксированная, руб.</label>
                <WrappedInput
                    className={styles.mediumInput}
                    name="redeem_price_per_dfa"
                    placeholder="Цена единицы"
                    uneditable={uneditable}
                    disabled={disabled || !isFixed}
                    rules={isFixed ? [{ validator: dfaValidators.price }] : undefined}
                    normalize={currentFloatNumber}
                    required={isFixed}
                />
            </>
        )
        const isFlexible = redemptionType === RedemptionTypeDfa.flexible
        const FlexibleComponent = (
            <>
                <label htmlFor={RedemptionTypeDfa.flexible}>Определяемая</label>
                <WrappedInput
                    className={styles.largeInput}
                    name="redemption_description"
                    placeholder="Опишите порядок определения цены ЦФА при погашении"
                    uneditable={uneditable}
                    disabled={disabled || !isFlexible}
                    multiline
                    maxLength={1000}
                    required={isFlexible}
                />
            </>
        )
        return [
            { value: RedemptionTypeDfa.fixed, label: FixedComponent },
            { value: RedemptionTypeDfa.flexible, label: FlexibleComponent },
        ]
    }, [uneditable, disabled, redemptionType])

    const renderUneditable = (value?: RedemptionTypeDfa) => {
        if (value === RedemptionTypeDfa.fixed) {
            const price = values?.['redeem_price_per_dfa'] as number
            return (
                <Space direction="vertical">
                    <span>Фиксированная</span>
                    <span>
                        {price?.toLocaleString()}
                        &nbsp;&nbsp;рублей
                    </span>
                </Space>
            )
        }
        if (value === RedemptionTypeDfa.flexible) {
            return (
                <Space direction="vertical">
                    <span>Определяемая</span>
                    <span>{values?.['redemption_description'] as string}</span>
                </Space>
            )
        }
    }

    return (
        <WrappedRadioItem
            className="formComponentItem"
            name={fieldName}
            id={fieldName}
            label="Цена погашения ЦФА"
            uneditable={uneditable}
            required
            disabled={disabled}
            options={radioOptions}
            onChange={onChangeType}
            renderUneditable={renderUneditable as FormItemProps['renderUneditable']}
        />
    )
}
