import { BlackListStatus, IBlacklist, blackListTypeTranslate } from '@dltru/dfa-models'
import {
    Button,
    Divider,
    Form,
    FormItem,
    Modal,
    Radio,
    WrappedDateItem,
    WrappedSelect,
} from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

const typeOptions = Object.entries(blackListTypeTranslate).map(([value, label]) => ({
    value,
    label,
}))

type FormDataType = Pick<
    IBlacklist,
    'verification_date' | 'black_list_type' | 'black_list_date' | 'match_status'
>

export const AddCheckModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [form] = Form.useForm<FormDataType>()
    const reduxDispatch = useDispatch()
    const userUid = useSelector(clientsSelector.selectCurrentClientId)

    const handleCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }
    const handleOk = (values: FormDataType) => {
        if (!userUid) {
            return
        }

        reduxDispatch(
            clientsSlice.actions.putBlacklist([
                {
                    user_uuid: userUid,
                    ...values,
                },
            ]),
        )
        handleCancel()
    }

    return (
        <Modal
            title="Проверка по перечню"
            visible={isModalVisible}
            destroyOnClose
            onCancel={handleCancel}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={() => handleCancel()}>
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={form.submit}>
                    Сохранить
                </Button>,
            ]}
        >
            <div>
                <Form form={form} layout="vertical" onFinish={handleOk}>
                    <div className="formRow">
                        <WrappedDateItem
                            className="formComponentItem"
                            label="Дата проведения проверки"
                            name="verification_date"
                            required
                            size="large"
                        />
                    </div>

                    <div className="formRow">
                        <WrappedSelect
                            className="formComponentItem"
                            label="Тип проверки"
                            name="black_list_type"
                            options={typeOptions}
                            size="large"
                            required
                        />
                    </div>
                    <div className="formRow">
                        <WrappedDateItem
                            className="formComponentItem"
                            label="Дата перечня проверки"
                            name="black_list_date"
                            required
                            size="large"
                        />
                    </div>
                    <Divider />
                    <div className="formRow">
                        <FormItem
                            className="formComponentItem"
                            name="match_status"
                            label="Включен в перечень?"
                            required
                        >
                            <Radio.Group>
                                <Radio key="yes" value={BlackListStatus.EXISTS_IN_BLACK_LIST}>
                                    Да
                                </Radio>
                                <Radio key="no" value={BlackListStatus.REMOVED_FROM_BLACK_LIST}>
                                    Нет
                                </Radio>
                            </Radio.Group>
                        </FormItem>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}
