import { CitizenshipType } from '@dltru/dfa-models'
import {
    AddressForm,
    EmailInput,
    FioForm,
    Form,
    FormSpace,
    IRoleFormStepProps,
    PersonalForm,
    PhoneInput,
    Step,
    StepperContext,
    WrappedInput,
    commonValidators,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'

import { InnInput } from '@components/Form'
import { DocumentForm } from '@components/Form/components/DocumentForm'
import { UploadInputForm } from '@components/Form/components/UploadInputForm'

import { MigrationCardAndPermitBlock } from '../../components/MigrationCardAndPermitBlock'

const STEP_INDEX = 5

export const Step6: FC<IRoleFormStepProps> = ({ isEditAllow }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    const [migrationBlockView, setMigrationBlockView] = useState(
        values?.['sole_executive.citizenship'] &&
            values['sole_executive.citizenship'] !== CitizenshipType.RU,
    )

    const onChangeValues = (changedValues: Partial<typeof values>) => {
        if (changedValues && 'sole_executive.citizenship' in changedValues) {
            setMigrationBlockView(
                changedValues['sole_executive.citizenship'] &&
                    changedValues['sole_executive.citizenship'] !== CitizenshipType.RU,
            )
        }
    }

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Сведения о Единоличном исполнительном органе"
            allowEdit={allowEdit}
            saveAfterEdit
            onChangeValues={onChangeValues}
        >
            {/* <Notification
                        text={'Вспомогательный текст о том, что такое единый исполнительный орган'}
                        icon={<InfoCircle />}
                    /> */}
            <FormSpace uneditable={isEdit}>
                <div className="formRow">
                    <WrappedInput
                        className="formComponentItem"
                        label="Должность"
                        name="sole_executive.position"
                        placeholder=""
                        uneditable={isEdit}
                        required
                        rules={[{ validator: commonValidators.stringWithSpacesAndDash() }]}
                    />
                </div>
                <div className="formRow">
                    <WrappedInput
                        className="formComponentItem--xl"
                        label="Подтверждающий документ"
                        name="sole_executive.position_file_id_type"
                        placeholder=""
                        uneditable={isEdit}
                        required
                    />
                </div>
                <div className="formRow" style={{ marginLeft: 240 }}>
                    <UploadInputForm
                        name="sole_executive.position_file_id"
                        title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                        maxSizeMb={5}
                        accept=".pdf,.png,.jpeg, .jpg, .tiff"
                        uneditable={isEdit}
                        required
                    />
                </div>
                <FioForm uneditable={isEdit} group="sole_executive" />
                <PersonalForm uneditable={isEdit} group="sole_executive" />
                <AddressForm
                    uneditable={isEdit}
                    addressTitle="Место жительства (адрес регистрации по паспорту)"
                    group="sole_executive.address"
                />
                <DocumentForm uneditable={isEdit} group="sole_executive.person_document" />
                <InnInput name="sole_executive.inn" required uneditable={isEdit} />
                <PhoneInput
                    className="formComponentItem"
                    label="Телефон"
                    name="sole_executive.phone_number"
                    uneditable={isEdit}
                    required
                    size="large"
                />
                <EmailInput name="sole_executive.email" uneditable={isEdit} required />
                {migrationBlockView && (
                    <MigrationCardAndPermitBlock
                        uneditable={isEdit}
                        initialHasMigrationCard={
                            values?.['sole_executive.HasMigrationCard'] as boolean
                        }
                        initialHasMigrationPermit={
                            values?.['sole_executive.HasMigrationPermit'] as boolean
                        }
                        group="sole_executive"
                    />
                )}
            </FormSpace>
        </Step>
    )
}
