import { ServiceCode } from './serviceCodes'
import { TransactionStatuses } from './statuses'

export type TransactionType = {
    account_uuid: string
    amount: number
    created_at: number
    purpose: string
    recipient_uuid: string
    sender_uuid: string
    service_code: ServiceCode
    status: TransactionStatuses
    type: 'debit' | 'credit' | 'transfer' | 'payment' | 'any_payment'
    process_type: 'debit_transfer' | 'issue_dfa' | 'debit_transfer_operator' | 'secondary_dfa'
    updated_at: number
    uuid: string
    attachment_uuid: string
    recipient_full_name: string
    sender_full_name: string
    account_owner_full_name: string
    account_owner_uuid: string
    account_number: number
}

export enum ValueTypes {
    copied = 'copied',
    linked = 'linked',
    externalLink = 'externalLink',
    value = 'value',
    prime = 'prime',
    fiatValue = 'fiatValue',
}

export type RequisitesTransaction = {
    type: ValueTypes
    value: string
    label: string
    to?: string
    symbol?: string
}

export type TransactionRow = {
    title: string
    subtitle: string
    total: number
    type: string
    status: TransactionStatuses
    id: string
    requisites: RequisitesTransaction[]
    serviceCode: TransactionType['service_code']
    timestamp: number
}
export type TransactionDataFront = {
    summary: Pick<TransactionRow, 'title' | 'subtitle' | 'total'> & { dateStr: string }
    rows: TransactionRow[]
}
