import { MoneyAccountFull } from '@dltru/dfa-models'
import { ColumnsType, LinkCell, Wallet, getRublesFromPennies } from '@dltru/dfa-ui'

export const gridConfig: ColumnsType<MoneyAccountFull> = [
    {
        title: '№ счёта',
        dataIndex: 'number',
        render: (value, row) =>
            LinkCell(
                <>
                    <Wallet /> {value}
                </>,
                `/clients/${row.user_uuid}#account`,
            ),
    },
    {
        title: 'Пользователь',
        dataIndex: 'user_name',
        sorter: true,
        render: (value, row) => LinkCell(value, `/clients/${row.user_uuid}`),
    },
    {
        title: 'Всего средств, ₽',
        dataIndex: 'total',
        sorter: true,
        render: getRublesFromPennies,
    },
    {
        title: 'Из них зарезервировано средств, ₽',
        dataIndex: 'reserves',
        sorter: true,
        render: getRublesFromPennies,
    },
    {
        title: 'Из них свободных средств, ₽',
        dataIndex: 'balance',
        sorter: true,
        render: getRublesFromPennies,
    },
]
