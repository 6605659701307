import { ClientAutocomplete, CreateEncumbranceType } from '@dltru/dfa-models'
import {
    Button,
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    Divider,
    EMPTY_FIELD_VALUE,
    Form,
    IntegerInput,
    UploadFile,
    WrappedAutocomplete,
    WrappedInput,
    dfaValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import api from '@services/api'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

export type CreateEncumbranceFormType = {
    depositor: string
    pledgee: string
    amount_dfa: number
    set_reason: string
    set_attachment_uuid: UploadFile[]
}

type LocationState = {
    user_id: string
    user_full_name: string
}

interface IComponentProps {
    onFormSubmit: (value: Omit<CreateEncumbranceType, 'asset_id'>) => void
}
export const DFAEncumbranceCreateForm: FC<IComponentProps> = ({ onFormSubmit }) => {
    const [uneditable, setUneditable] = useState(false)
    const location = useLocation()
    const state = location.state as LocationState
    const [searchParams] = useSearchParams()
    const [selectedUser, setSelectedUser] = useState<ClientAutocomplete[]>([])
    const [form] = Form.useForm<CreateEncumbranceFormType>()

    const onSelectHandler = (selectedOptions: ClientAutocomplete[]) => {
        setSelectedUser(selectedOptions)
    }

    const getClients = async (value?: string) => {
        try {
            if (!value) {
                return []
            }
            const { data } = await api.lib.getClientsService({
                full_name: `like(${value})`,
            })
            return (
                data?.items?.map((item) => ({
                    ...item,
                    label: item.full_name,
                    value: item.user_uuid,
                })) ?? []
            )
        } catch (error) {
            return []
        }
    }

    const onFinish = (value: CreateEncumbranceFormType) => {
        setUneditable(true)
        onFormSubmit({
            depositor_uuid: state?.user_id,
            pledgee_uuid: selectedUser[0].user_uuid,
            amount_dfa: Number(value.amount_dfa),
            set_reason: value.set_reason,
            set_attachment_uuid: value.set_attachment_uuid?.map(({ uid }) => uid),
        })
    }
    const maxCount = searchParams.get('amount') ? Number(searchParams.get('amount')) : 0
    const pledgerRenderUneditable = () => (
        <Link to={`/clients/${state?.user_id}`}>{state?.user_full_name}</Link>
    )
    const mortgageeRenderUneditable = () =>
        selectedUser?.[0] ? (
            <Link to={`/clients/${selectedUser[0].user_uuid}`}>{selectedUser[0].full_name}</Link>
        ) : (
            EMPTY_FIELD_VALUE
        )

    return (
        <Form form={form} size="large" onFinish={onFinish}>
            <Card uneditable={uneditable}>
                <CardHeader title="Информация об обременении" />
                <CardContent divider={!uneditable}>
                    <div className="formRow" style={{ marginTop: 16 }}>
                        <WrappedInput
                            className="formComponentItem"
                            label="Залогодатель"
                            name="depositor"
                            required
                            initialValue={state?.user_full_name}
                            size="large"
                            disabled
                            uneditable={uneditable}
                            renderUneditable={pledgerRenderUneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow">
                        <WrappedAutocomplete
                            className="formComponentItem"
                            dataProvider={getClients}
                            onSelect={onSelectHandler}
                            defaultOptions={selectedUser}
                            name="pledgee"
                            label="Залогодержатель"
                            selectProps={{
                                size: 'large',
                                placeholder: 'Введите ФИО или название организации',
                            }}
                            required
                            uneditable={uneditable}
                            renderUneditable={mortgageeRenderUneditable}
                        />
                    </div>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow" style={{ marginBottom: 16 }}>
                        <IntegerInput
                            className="formComponentItem"
                            label="Количество ЦФА, в отношении которых накладывается обременение"
                            name="amount_dfa"
                            placeholder={`В кошельке доступно максимум ${maxCount}`}
                            required
                            rules={[
                                {
                                    validator: dfaValidators.biggerAmount(maxCount),
                                },
                            ]}
                            uneditable={uneditable}
                        />
                    </div>
                    <div className="formRow">
                        <WrappedInput
                            className="formComponentItem--xl"
                            label="Основание для наложения обременения"
                            name="set_reason"
                            required
                            placeholder="Кратко опишите основание для наложения обременения"
                            multiline
                            uneditable={uneditable}
                        />
                    </div>
                    <div className="formRow" style={{ marginLeft: 240 }}>
                        <UploadInputForm
                            className="formComponentItem"
                            name="set_attachment_uuid"
                            required
                            uneditable={uneditable}
                            title="Загрузите фотографии документов-оснований. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                            maxSizeMb={5}
                            accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                        />
                    </div>
                </CardContent>
                {!uneditable && (
                    <CardFooter>
                        <div>
                            <Button
                                borderRadius={12}
                                type="primary"
                                size="middle"
                                htmlType="submit"
                            >
                                Далее
                            </Button>
                        </div>
                    </CardFooter>
                )}
            </Card>
        </Form>
    )
}
