import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CONTENT_FOOTER_ID, ContentFooterBox, PlusCircle, Portal } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export const Footer: FC = () => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isViewFooter = contentFooter && typeof contentFooter !== null
    const navigate = useNavigate()

    const { isAllow } = useContext(RbacContext)

    const isShowAddButton = isAllow(['employee.write.all'])

    return (
        <>
            {isViewFooter && (
                <Portal container={contentFooter}>
                    <ContentFooterBox
                        left={
                            isShowAddButton ? (
                                <Button
                                    type="primary"
                                    icon={<PlusCircle width="18px" height="18px" />}
                                    borderRadius={12}
                                    onClick={() => navigate('create')}
                                >
                                    Добавить сотрудника
                                </Button>
                            ) : undefined
                        }
                    />
                </Portal>
            )}
        </>
    )
}
