import { IDfaFront } from '@dltru/dfa-models'
import { Box, DEFAULT_PER_PAGE, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { clientsSelector } from '@store/clients/selectors'
import { dropDfaDetails } from '@store/dfa/details'
import { dropDfaList, loadDfas } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'

import { gridConfig } from '../../Registry/gridConfig'

export const ClientDfas: FC = () => {
    const currentUser = useSelector(clientsSelector.selectCurrentClientId)
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()

    const onPerPageChange = (limit: number) => {
        reduxDispatch(loadDfas({ limit, emitter_id: currentUser }))
    }

    const onNext = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(loadDfas({ emitter_id: currentUser, limit: DEFAULT_PER_PAGE }))
        reduxDispatch(dropDfaDetails())
        return () => {
            reduxDispatch(dropDfaList())
        }
    }, [])

    const onRow = (record: IDfaFront) => ({
        onClick: () => {
            navigate(`/offers/${record.id}`)
        },
    })

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items ?? []}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                onRow={onRow}
                clickableRow
            />
        </Box>
    )
}
