import { Box, PageHeader } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { nominalAccountsSlice } from '@store/nominalAccounts'
import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import { NominalAccountForm } from '../components/NominalAccountForm'
import { Footer } from './components/Footer'

export const NominalAccountCreate: FC = () => {
    const navigate = useNavigate()
    const data = useSelector(nominalAccountsSelector.selectCurrentItem)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (data?.id) {
            navigate(`/nominal-account/${data?.id}`)
        }
    }, [data?.id])

    useEffect(
        () => () => {
            reduxDispatch(nominalAccountsSlice.actions.clearItem())
        },
        [],
    )

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Новый банковский счёт"
                    onBack={() => navigate('/personal-accounts#bank')}
                />
            </Box>
            <NominalAccountForm />
            <Footer />
        </>
    )
}
