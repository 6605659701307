import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { tariffSlice } from '@store/tariff'

type PropsForModalType = {
    gridId?: string
}
interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    props: PropsForModalType
}
export const StopActivateGridModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    props,
}) => {
    const reduxDispatch = useDispatch()
    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const handleOk = () => {
        if (!props.gridId) {
            return
        }
        reduxDispatch(tariffSlice.actions.deactivateTariffGrid(props.gridId))
        handleCancel()
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={423}
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Отменить активацию тарифной сетки
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={handleCancel} key="cancel">
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={handleOk} key="ok">
                    Да, отменить активацию
                </Button>,
            ]}
        >
            <Box padding={[16, 0, 0, 40]}>
                <p>
                    Тарифная сетка будет переведена в статус Черновик. В этом статусе при
                    наступлении даты активации сетки она не будет активирована.
                </p>
                <p> Вы действительно хотите отменить активацию?</p>
            </Box>
        </MiniModal>
    )
}
