import { EncumbranceShortType } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell, Tag } from '@dltru/dfa-ui'

export const encumbranceGridConfig: ColumnsType<EncumbranceShortType> = [
    {
        title: 'Дата и время',
        dataIndex: 'set_date',
        key: 'set_date',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Актив',
        dataIndex: 'ticker_symbol',
        key: 'ticker_symbol',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: 'Залогодатель',
        dataIndex: 'depositor_full_name',
        key: 'depositor_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/clients/${record.depositor_uuid}`),
    },
    {
        title: 'Залогодержатель',
        dataIndex: 'pledgee_full_name',
        key: 'pledgee_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/clients/${record.pledgee_uuid}`),
    },
    {
        title: 'Количество',
        dataIndex: 'amount_dfa',
        key: 'amount_dfa',
        align: 'right',
        sorter: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Статус обременения',
        dataIndex: 'is_active',
        key: 'is_active',
        sorter: true,
        fixed: 'right',
        render: (value) => <Tag color="default">{value ? 'Действующее' : 'Снято'}</Tag>,
    },
]
