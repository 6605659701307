// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".roundedBlock_I_qEn {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n  border: 1px solid #d3d5da;\n  border-radius: 16px;\n}\n.roundedBlockSuccess_Iafix {\n  background: #f6ffed;\n  border: 1px solid #d9f7be;\n}\n.roundedBlockSlice_w_1jf {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.roundedBlockSlice_w_1jf:last-child {\n  align-items: flex-end;\n  max-width: 60%;\n  text-align: right;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/BodyRoundedBlock/style.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AADJ;AAGI;EACI,mBAAA;EACA,yBAAA;AADR;AAII;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;AAFR;AAIQ;EACI,qBAAA;EACA,cAAA;EACA,iBAAA;AAFZ","sourcesContent":["@import '/src/assets/style/palette';\n\n.roundedBlock {\n    display: flex;\n    justify-content: space-between;\n    padding: 16px;\n    border: 1px solid @gray-5;\n    border-radius: 16px;\n\n    &Success {\n        background: @green-1;\n        border: 1px solid @green-2;\n    }\n\n    &Slice {\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n\n        &:last-child {\n            align-items: flex-end;\n            max-width: 60%;\n            text-align: right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roundedBlock": "roundedBlock_I_qEn",
	"roundedBlockSuccess": "roundedBlockSuccess_Iafix",
	"roundedBlockSlice": "roundedBlockSlice_w_1jf"
};
export default ___CSS_LOADER_EXPORT___;
