/* eslint-disable unused-imports/no-unused-vars */
import { IDfaOwner, PayoffAgreementsType } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { setDfaOwnersError, setDfaOwnersLoading, setDfaOwnersPage } from './index'
import { getPage } from './index'

function* loadDfaOwners({ payload }: ReturnType<typeof getPage>) {
    yield* put(setDfaOwnersLoading())

    const limit = payload.range?.limit || 15
    const page = (payload.range?.offset || 0) / limit

    const sort_by = payload.sort?.by || ''
    const order_by = payload.sort?.type || 'asc'

    const { data, error } = yield api.lib.getDfaOwnersService(
        payload.dfaId,
        page,
        limit,
        sort_by,
        order_by,
    )

    if (error) {
        yield* put(setDfaOwnersError(error))
    } else {
        if (data.items?.length) {
            let investorRepaymentConfirmed = [] as PayoffAgreementsType[]
            if (payload.withPayoffAgreements) {
                const { data: dataPayoffAgreements } = yield* call(api.lib.getPayoffAgreements, {
                    asset_id: payload.dfaId,
                    limit: 9999,
                })
                investorRepaymentConfirmed =
                    dataPayoffAgreements?.item?.items ?? ([] as PayoffAgreementsType[])
            }
            const _data = data.items.map((item: IDfaOwner) => ({
                ...item,
                ...item.balances[0],
                is_investor_repayment_confirmed: Boolean(
                    investorRepaymentConfirmed?.find(({ user_uuid }) => item.user_id === user_uuid),
                ),
            }))

            yield* put(setDfaOwnersPage({ data: _data, page, limit, sort_by, order_by }))
        } else {
            yield* put(setDfaOwnersPage({ data: [], page, limit, sort_by, order_by }))
        }
    }
}

export function* watchOwnerDfaById(): Generator<StrictEffect> {
    yield* takeLatest(getPage.type, loadDfaOwners)
}
