import classNames from 'classnames/bind'
import moment from 'moment'
import { FC } from 'react'

import style from './style.m.less'

export interface IBodyDate {
    date: number | Date
    white?: boolean
    time?: boolean
}

const cx = classNames.bind(style)

export const BodyDate: FC<IBodyDate> = ({ date, white, time }) => {
    const className = cx({
        date: true,
        dateWhite: white,
    })

    return (
        <p className={className}>
            {moment(date).format('DD.MM.yyyy')}
            {time && <span>, {moment(date).format('HH:mm:ss')}</span>}
        </p>
    )
}
