import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, EditPenIcon } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { SendToEditModal } from '../Modals/SendToEditModal'

export const SendToEditAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { isAllow } = useContext(RbacContext)

    const showButton =
        (dfaDetails.status === DfaStatusEnum.teller_approval && isAllow(['approval.operator'])) ||
        (dfaDetails.status === DfaStatusEnum.lawyer_approval && isAllow(['approval.lawyer'])) ||
        (dfaDetails.status === DfaStatusEnum.spec_approval && isAllow(['approval.aml']))

    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<EditPenIcon />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                На доработку
            </Button>
            <SendToEditModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
