import { FC } from 'react'

import { UploadInput, UploadInputProps } from './UploadInput'

// TODO:
export const UploadInputForm: FC<UploadInputProps & { noWrapper?: boolean }> = ({
    noWrapper,
    uneditable,
    children,
    ...props
}) => {
    if (noWrapper && uneditable) {
        return <UploadInput {...props} uneditable={uneditable} />
    }

    if (uneditable) {
        return <UploadInput {...props} uneditable={uneditable} />
    }

    return (
        <div className="upload-form__container">
            <div className="upload-form__wrapper">
                {children}
                <UploadInput {...props} className="form-item--upload" />
            </div>
        </div>
    )
}
