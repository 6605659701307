import {
    UnionDealFront,
    isEmissionDeal,
    isRepaymentDeal,
    isSecondaryDeal,
    isTransferDeal,
} from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { dfaDealsListSlice } from '@store/dfa/deals'
import { dealsSelector } from '@store/dfa/deals/selectors'

import { emissionModal } from '@components/Modals/Deals/View/Issue'
import { repaymentModal } from '@components/Modals/Deals/View/Repayment'
import { secondaryModal } from '@components/Modals/Deals/View/Secondary'
import { transferModal } from '@components/Modals/Deals/View/Transfer'

import { gridConfig } from './gridConfig'

export const DealsTable: React.FC = () => {
    const { dfa: urlDfaParam } = useParams()
    const data = useSelector(dealsSelector.selectData)
    const isLoading = useSelector(dealsSelector.selectIsLoading)

    const reduxDispatch = useDispatch()

    const onRow = (deal: UnionDealFront) => ({
        onClick: async () => {
            if (isEmissionDeal(deal)) {
                return emissionModal({ deal })
            }
            if (isSecondaryDeal(deal)) {
                return secondaryModal({ deal })
            }
            if (isRepaymentDeal(deal)) {
                return repaymentModal({ deal })
            }
            if (isTransferDeal(deal)) {
                return transferModal({ deal })
            }
        },
    })

    useEffect(() => {
        if (urlDfaParam) {
            reduxDispatch(dfaDealsListSlice.actions.getDfaDealsById(urlDfaParam))
        }
        return () => {
            reduxDispatch(dfaDealsListSlice.actions.clearData())
        }
    }, [urlDfaParam])

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={data}
            onRow={onRow}
            clickableRow
            rowKey={(record) => `${record.id}${record.type}`}
        />
    )
}
