import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CheckCircleIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 13.6668C10.6819 13.6668 13.6667 10.6821 13.6667 7.00016C13.6667 3.31826 10.6819 0.333496 7 0.333496C3.3181 0.333496 0.333328 3.31826 0.333328 7.00016C0.333328 10.6821 3.3181 13.6668 7 13.6668ZM10.2099 5.27433C10.4027 5.02609 10.3655 4.66232 10.1268 4.46182C9.88814 4.26132 9.53836 4.30001 9.34557 4.54825L6.61711 8.1618L5.32575 6.68765C5.13618 6.43674 4.78693 6.39316 4.54567 6.5903C4.30441 6.78745 4.2625 7.15067 4.45206 7.40158L6.17427 9.44602C6.27879 9.58436 6.43822 9.66564 6.60738 9.66682C6.77654 9.668 6.93701 9.58896 7.0433 9.45209L10.2099 5.27433Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
