import { ColumnsType, CountCell, DateTimeCell, InjunctionTickerCell } from '@dltru/dfa-ui'
import classNames from 'classnames'

import { WalletLimit } from '@store/wallet/locks/types'

export const injunctionsGridConfig: ColumnsType<WalletLimit> = [
    {
        title: 'Тикер',
        dataIndex: ['asset_ticker_symbol'],
        key: 'asset_ticker_symbol',
        width: '140px',
        sorter: true,
        render: (value, record) => (
            <div className={classNames({ injustionsLock: record.is_lock })}>
                {InjunctionTickerCell(record.is_lock, value)}
            </div>
        ),
    },
    {
        title: 'Количество ЦФА, ед.',
        dataIndex: ['amount_dfa'],
        key: 'amount_dfa',
        sorter: true,
        render: (value, record) => (
            <div className={classNames({ injustionsLock: record.is_lock })}>{CountCell(value)}</div>
        ),
    },
    {
        title: 'Дата и время блокировки/снятия блокировки',
        dataIndex: ['created_at'],
        key: 'created_at',
        sorter: true,
        render: (value, record) => (
            <div className={classNames({ injustionsLock: record.is_lock })}>
                {DateTimeCell(value)}
            </div>
        ),
    },
    {
        title: 'Основание блокировки/снятия блокировки',
        dataIndex: ['description'],
        key: 'description',
        render: (value, record) => (
            <div className={classNames({ injustionsLock: record.is_lock })}>{value}</div>
        ),
    },
]
