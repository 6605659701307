import { Modal } from '@dltru/dfa-ui'
import React, { FC } from 'react'

import { RemoveUserModalBody } from './RemoveUserModalBody'

interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
}

export const RemoveUserModal: FC<IComponentProps> = ({ visible, setVisible }) => (
    <Modal
        title="Исключение Пользователя из реестра пользователей"
        visible={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose
        width={600}
    >
        <RemoveUserModalBody setVisible={setVisible} />
    </Modal>
)
