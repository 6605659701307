import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const InfoCircle: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8C14.6666 4.3181 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.3181 1.33325 8C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667ZM8.3749 11.5C8.44365 11.5 8.4999 11.4438 8.4999 11.375V7.125C8.4999 7.05625 8.44365 7 8.3749 7H7.6249C7.55615 7 7.4999 7.05625 7.4999 7.125V11.375C7.4999 11.4438 7.55615 11.5 7.6249 11.5H8.3749ZM7.47939 5.775C7.61677 5.91522 7.80364 5.996 7.9999 6C8.19616 5.996 8.38304 5.91522 8.52042 5.775C8.6578 5.63479 8.73475 5.4463 8.73475 5.25C8.73475 5.0537 8.6578 4.86522 8.52042 4.725C8.38304 4.58479 8.19616 4.50401 7.9999 4.5C7.80364 4.50401 7.61677 4.58479 7.47939 4.725C7.342 4.86522 7.26506 5.0537 7.26506 5.25C7.26506 5.4463 7.342 5.63479 7.47939 5.775Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
