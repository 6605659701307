import { Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { toModificationDfa } from '@store/dfa/details'

import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}
type FormValuesType = {
    reason?: string
}
export const SendToEditModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [form] = Form.useForm<FormValuesType>()

    const [isFilledField, setIsFilledField] = useState(false)
    const reduxDispatch = useDispatch()

    const handleCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }
    const handleOk = (values: FormValuesType) => {
        reduxDispatch(toModificationDfa(values))
        handleCancel()
    }

    const onValuesChange = (changedValues: FormValuesType) => {
        setIsFilledField(Boolean(changedValues.reason))
    }

    return (
        <Modal
            title="Отправить на доработку"
            visible={isModalVisible}
            onCancel={handleCancel}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="primary"
                    onClick={form.submit}
                    disabled={!isFilledField}
                >
                    Да, отправить
                </Button>,
            ]}
        >
            <div>
                <p>
                    Рассмотрение заявки будет приостановлено.
                    <br />
                    Вы действительно хотите отправить заявку на выпуск на доработку?
                </p>
                <Divider />
                <h3 className={style.header}>Замечания и предложения для эмитента</h3>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOk}
                    onValuesChange={onValuesChange}
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="reason"
                        multiline
                        required
                        maxLength={200}
                    />
                </Form>
            </div>
        </Modal>
    )
}
