import cn from 'classnames'
import { FC, ReactNode } from 'react'

import { Box, BoxProps } from '../Box'
import './styles.less'

export type CardHeaderProps = {
    title: ReactNode
    className?: string
    stepIndex?: number
    stepsTotal?: number
    isNotCurrentStep?: boolean
    disabledHeaderStepCounter?: boolean
} & Pick<BoxProps, 'padding'>

export const CardHeader: FC<CardHeaderProps> = ({
    children,
    title,
    stepIndex,
    stepsTotal,
    isNotCurrentStep,
    className,
    disabledHeaderStepCounter = false,
    padding = [16, 0, 16, 16],
}) => (
    <Box
        padding={padding}
        direction="row"
        align="center"
        justify="flex-start"
        className={cn('card-header__container', className)}
    >
        {stepIndex === undefined || disabledHeaderStepCounter ? (
            <h5 className="card-header__title">{title}</h5>
        ) : (
            <>
                {!isNotCurrentStep && (
                    <h5 className="card-header__title card-header__title--sub">{`${
                        stepIndex + 1
                    } шаг из ${stepsTotal}`}</h5>
                )}
                <h5 className="card-header__title">
                    {`${
                        isNotCurrentStep && stepsTotal !== undefined && stepsTotal > 1
                            ? stepIndex + 1 + '. '
                            : ''
                    }` + title}
                </h5>
            </>
        )}

        {children}
    </Box>
)
