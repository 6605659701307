import { DfaStatusEnum, IDfaFront, IDfaOwner, SettlementsType } from '@dltru/dfa-models'
import {
    Button,
    CaseBadge,
    ColumnsType,
    ConfirmCell,
    DetailsAmountCell,
    DetailsAmountHeader,
    LinkCell,
    PercentCell,
} from '@dltru/dfa-ui'
import React from 'react'

const getTotalAmountDfa = (record: IDfaOwner) =>
    record.active_amount_dfa +
    record.blocked_amount_dfa +
    record.injunction_amount_dfa +
    record.encumbrance_amount_dfa +
    record.repayment_amount_dfa

const getTotalSum = (dfa: IDfaFront) => (value: number | undefined, record: IDfaOwner) => {
    if (value && dfa.redeem_price_per_dfa !== undefined) {
        return (value * dfa.redeem_price_per_dfa).toLocaleString()
    }

    if (dfa.redeem_price_per_dfa !== undefined) {
        return (getTotalAmountDfa(record) * dfa.redeem_price_per_dfa).toLocaleString()
    }

    return ''
}

const CaseCell = (value: number) => <CaseBadge count={value.toLocaleString()} />
const IsRedeemCell = (record: IDfaOwner, onClick: (record: IDfaOwner) => void) => {
    const onClickHandler = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        onClick(record)
    }
    const dfaForRepayment =
        record.active_amount_dfa +
        record.blocked_amount_dfa +
        record.injunction_amount_dfa +
        record.encumbrance_amount_dfa

    if (dfaForRepayment) {
        return (
            <Button borderRadius={8} onClick={onClickHandler} size="small" width="120px">
                Погасить ЦФА
            </Button>
        )
    } else if (record.repayment_amount_dfa > 0) {
        return ConfirmCell('Погашено')(true)
    }
}
interface IGridProps {
    dfa: IDfaFront
    onRedeemMature: (record: IDfaOwner) => void
}
export const getGridConfig = ({ dfa, onRedeemMature }: IGridProps): ColumnsType<IDfaOwner> => {
    const columns: ColumnsType<IDfaOwner> = [
        {
            title: 'Текущий владелец',
            dataIndex: 'user_full_name',
            key: 'user_full_name',
            width: '60%',
            sorter: true,
            ellipsis: true,
            render: (value: string, record: IDfaOwner) =>
                LinkCell(value || record.user_id, `/clients/${record.user_id}`),
        },
    ]
    if (dfa.status !== DfaStatusEnum.redeemed) {
        columns.push(
            {
                title: 'Доля, %',
                dataIndex: 'repurchase_percent',
                key: 'repurchase_percent',
                align: 'right',
                width: 170,
                sorter: true,
                ellipsis: true,
                render: (_, record: IDfaOwner) =>
                    PercentCell(getTotalAmountDfa(record) / dfa.total_supply_invested),
            },
            {
                title: 'Всего ЦФА, ед.',
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                width: 170,
                sorter: true,
                render: (_, record: IDfaOwner) => CaseCell(getTotalAmountDfa(record)),
            },
            {
                title: DetailsAmountHeader,
                dataIndex: 'details_amount_dfa',
                key: 'details_amount_dfa',
                width: '40%',
                render: DetailsAmountCell,
            },
        )
    }
    if (
        dfa.repayment_settlements_type === SettlementsType.direct &&
        dfa.status === DfaStatusEnum.mature
    ) {
        columns.push(
            {
                title: 'Сумма погашения,  ₽',
                dataIndex: 'sum',
                key: 'sum',
                align: 'right',
                width: 170,
                render: getTotalSum(dfa),
            },
            {
                title: 'Погашение выпуска, %',
                dataIndex: 'isRedeem',
                key: 'isRedeem',
                width: 160,
                render: (_, record: IDfaOwner) => IsRedeemCell(record, onRedeemMature),
            },
            {
                title: 'Согласие на погашение',
                dataIndex: 'is_investor_repayment_confirmed',
                key: 'is_investor_repayment_confirmed',
                width: 140,
                render: ConfirmCell('Согласен'),
            },
        )
        return columns
    }
    if (dfa.status === DfaStatusEnum.redeemed) {
        columns.push({
            title: 'Погашенные',
            dataIndex: 'repayment_amount_dfa',
            key: 'repayment_amount_dfa',
            width: '30%',
            sorter: true,
            ellipsis: true,
            render: (value: number, record: IDfaOwner) => value + record.active_amount_dfa,
        })
    }

    return columns
}
