import { PaymentOrder } from '@dltru/dfa-models'
import { Box, Col, Row } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from '../../style.m.less'

interface IComponentProps {
    paymentOrder: PaymentOrder
    type: 'recipient' | 'payer'
}
export const DetailsComponent: FC<IComponentProps> = ({ paymentOrder, type }) => {
    return (
        <div className={style.grayBlock}>
            <Box margin={[0, 0, 8, 0]} className={style.grayTextSmall}>
                {type === 'recipient' ? 'Получатель' : 'Плательщик'}
            </Box>
            <Box margin={[8, 0, 12, 0]} className={style.headerSmall}>
                {paymentOrder[type]}
            </Box>
            <Row gutter={16}>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={8} className={style.grayLabel}>
                            Полное наименование
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[type]}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={8} className={style.grayLabel}>
                            ИНН
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[`${type}_inn`]}
                        </Col>
                        <Col span={8} className={style.grayLabel}>
                            КПП
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[`${type}_kpp`]}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={8} className={style.grayLabel}>
                            Номинальный счёт
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[`${type}_account_number`]}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={8} className={style.grayLabel}>
                            Банк
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[`${type}_bank_name`]}
                        </Col>
                        <Col span={8} className={style.grayLabel}>
                            БИК банка
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[`${type}_bank_bic`]}
                        </Col>
                        <Col span={8} className={style.grayLabel}>
                            Кор. счёт
                        </Col>
                        <Col span={16} className={style.grayText}>
                            {paymentOrder[`${type}_bank_corr_account_number`]}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
