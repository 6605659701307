// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".copyButton_x7fxm {\n  color: #303440;\n  cursor: pointer;\n  line-height: inherit;\n}\n.copyButton_x7fxm.primary_u8uZB:hover {\n  color: #007ef2;\n}\n.copyButton_x7fxm.volcano_TJlLn:hover {\n  color: #fa541c;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/button/CopyButton/CopyButton.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,eAAA;EACA,oBAAA;AADJ;AAEI;EAEI,cAAA;AADR;AAII;EAEI,cAAA;AAHR","sourcesContent":["@import '/src/assets/style/palette';\n\n.copyButton {\n    color: @gray-10;\n    cursor: pointer;\n    line-height: inherit;\n    &.primary:hover\n    {\n        color: @primary-6;\n    }\n\n    &.volcano:hover\n    {\n        color: @volcano-6;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyButton": "copyButton_x7fxm",
	"primary": "primary_u8uZB",
	"volcano": "volcano_TJlLn"
};
export default ___CSS_LOADER_EXPORT___;
