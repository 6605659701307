import { useEffect, useState } from 'react'

import { MemoComponent } from './MemoComponent'
import { StepperProps, StepsValues } from './types'

export const Stepper = <I extends Partial<Record<string, unknown>>>({
    steps,
    initialValues = {} as I,
    whenChangeStepsValues,
    onStepperFinish = () => null,
    isNotCurrentStep = false,
    initStep = 0,
}: StepperProps<I>) => {
    const [stepsValues, setStepsValues] = useState(initialValues)
    const [currentStep, setCurrentStep] = useState(initStep)
    const [randomKey, setRandomKey] = useState(Math.random())

    useEffect(() => {
        setStepsValues(initialValues)
    }, [initialValues])

    useEffect(() => {
        if (whenChangeStepsValues) {
            whenChangeStepsValues(stepsValues)
        }
    }, [stepsValues, whenChangeStepsValues])

    function mergeValuesIntoState(values: StepsValues) {
        setStepsValues((prevState) => ({ ...prevState, ...values }))
    }

    function stepBack(values?: StepsValues) {
        if (values) {
            mergeValuesIntoState(values)
        }

        if (currentStep > 0) {
            setCurrentStep((step) => step - 1)
        }
    }

    function stepForward(values: StepsValues) {
        mergeValuesIntoState(values)

        if (currentStep < steps.length) {
            setCurrentStep((step) => step + 1)
        }

        if (currentStep + 1 === steps.length) {
            onStepperFinish({ ...stepsValues, ...values })
        }
    }

    function turnToFirstStep() {
        setCurrentStep(0)
    }

    function turnToFirstStepAndClearValues() {
        setStepsValues(initialValues)
        setRandomKey(Math.random())
        turnToFirstStep()
    }

    return (
        <>
            {steps
                .slice(0, currentStep + 1)
                .map(({ component, title, prefix, nextButtonTitle, options }, index) => {
                    return (
                        <MemoComponent
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${randomKey}${index}`}
                            component={component}
                            stepIndex={index}
                            stepsValues={stepsValues}
                            isNotCurrentStep={isNotCurrentStep || index !== currentStep}
                            stepBack={stepBack}
                            stepForward={stepForward}
                            turnToFirstStep={turnToFirstStep}
                            turnToFirstStepAndClearValues={turnToFirstStepAndClearValues}
                            stepsTotal={steps.length}
                            currentStep={currentStep}
                            prefix={prefix}
                            title={title}
                            nextButtonTitle={nextButtonTitle}
                            options={options}
                        />
                    )
                })}
        </>
    )
}
