import classNames from 'classnames/bind'

import style from './style.m.less'

const cn = classNames.bind(style)
export interface IMilestoneProps {
    label: string
    value: React.ReactChild
    centered?: boolean
    withGap?: boolean
    top?: boolean
}

export const Milestone: React.FC<IMilestoneProps> = ({ label, value, centered, withGap, top }) => {
    const wrapperClassName = cn(style.wrapperMilestone, {
        [style.wrapperMilestoneCentered]: centered,
        [style.wrapperMilestoneGap]: withGap,
        [style.wrapperMilestoneTop]: top,
    })
    return (
        <div className={wrapperClassName}>
            <div className={style.labelMilestone}>{label}</div>
            <div className={style.valueMilestone}>{value}</div>
        </div>
    )
}
