import { Box, Button, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { paymentOrdersSlice } from '@store/paymentOrders'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import style from './style.m.less'

interface IComponentProps {
    visible: boolean
    setVisible: (val: boolean) => void
    transactionUuid?: string
}

export const RejectPaymentOrderModal: FC<IComponentProps> = ({
    visible,
    setVisible,
    transactionUuid,
}) => {
    const [form] = Form.useForm()
    const reduxDispatch = useDispatch()

    const onClose = () => {
        form.resetFields()
        setVisible(false)
    }

    const onSubmit = () => {
        if (transactionUuid) {
            reduxDispatch(paymentOrdersSlice.actions.rejectPaymentOrder(transactionUuid))
        }
        onClose()
    }

    if (!visible) {
        return null
    }

    return (
        <>
            <Modal
                visible={visible}
                width={522}
                onCancel={onClose}
                title="Отказ в исполнении поручения"
                wrapClassName={style.modal}
                destroyOnClose
            >
                <Box margin={[0, 0, 24, 0]}>
                    <Box margin={[0, 0, 24, 0]}>
                        <p>Вы действительно хотите отказать в исполнении Поручения Пользователя?</p>
                    </Box>
                </Box>
                <Form form={form} layout="vertical" initialValues={{}} onFinish={onSubmit}>
                    <Box margin={[0, 0, 24, 0]}>
                        <h4 className={style.headerBold}>Основание для отказа</h4>
                    </Box>
                    <UploadDocumentContainerWrapper
                        name="reasonDocs"
                        title="Загрузите документы (при необходимости)"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="reasonText"
                            multiline
                        />
                    </UploadDocumentContainerWrapper>
                </Form>

                <div className={style.footer}>
                    <Button borderRadius={16} onClick={onClose}>
                        Отмена
                    </Button>
                    <Button borderRadius={16} type="danger" onClick={() => form.submit()}>
                        Да, отказать
                    </Button>
                </div>
            </Modal>
        </>
    )
}
