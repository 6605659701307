export type MetaInfoType = {
    ExpireDate: string
    FullName: string
    ISSUER_COMMON_NAME: string
    Issuer_COUNTRY_NAME: string
    Issuer_INN: string
    Issuer_OGRN: string
    Issuer_ORGANIZATION_NAME: string
    Issuer_SNILS: string
    Issuer_emailAddr: string
    Serial: string
    StartDate: string
    SzOID_COMMON_NAME: string
    SzOID_COUNTRY_NAME: string
    SzOID_GIVEN_NAME: string
    SzOID_OGRN: string
    SzOID_OGRNIP: string
    SzOID_ORGANIZATION_NAME: string
    SzOID_RSA_emailAddr: string
    SzOID_SNILS: string
    SzOID_SUR_NAME: string
}

export enum CertificateStatus {
    registered = 'registered',
    active = 'active',
    pending_creation = 'pending_creation',
    creation_failed = 'creation_failed',
    to_delete = 'to_delete',
    pending_deletion = 'pending_deletion',
    deletion_failed = 'deletion_failed',
    deleted = 'deleted',
}

export type CertificateType = {
    uuid: string
    created_at: string
    updated_at: string
    user_uuid: string
    skid: string
    meta: MetaInfoType
    expired_at: number
    sha1: string
    status: CertificateStatus
    purpose: string
    adapter_uuid: string
    public_key_sha1: string
}

export type UserCertificateType = {
    algorithmName: string
    checkAlgorithmValue: boolean
    checkExtendedKeyUsage: boolean
    checkIsValid: boolean
    checkKeyUsage: boolean
    checkNotAfterDateTo: boolean
    checkNotBeforeDateFrom: boolean
    checkPrivateKey: boolean
    text: string
    thumbprint: string
}
