import { useCallback, useState } from 'react'

type Resolver<Resolve> = (value?: Resolve) => void

export function useModalState<Resolve, Reject = Resolve>(
    onResolve: Resolver<Resolve>,
    onReject?: Resolver<Reject>,
): [boolean, Resolver<Resolve>] | [boolean, Resolver<Resolve>, Resolver<Reject>] {
    const [visible, setVisible] = useState(true)
    const resolve = useCallback(
        (result?: Resolve) => {
            setVisible(false)
            onResolve(result)
        },
        [onResolve],
    )

    if (onReject) {
        const reject = useCallback(
            (error?: Reject) => {
                setVisible(false)
                onReject(error)
            },
            [onReject],
        )

        return [visible, resolve, reject]
    }

    return [visible, resolve]
}
