// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".amount_hhRRN {\n  font-weight: 900;\n  font-size: larger;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/components/Markup/Amount/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".amount {\n    font-weight: 900;\n    font-size: larger;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amount": "amount_hhRRN"
};
export default ___CSS_LOADER_EXPORT___;
