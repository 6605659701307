import { InfoWidget, asMoney } from '@dltru/dfa-ui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions } from '@store/money/accounts/summary'

import styles from './style.m.less'
import { accountSauSelector } from '@store/accountSau/selectors'
import { Debit } from '../Debit/Debit'
import { AccountWidget } from '../AccountWidget'

export const TopLine = () => {
    const dispatch = useDispatch()
    const summary = useSelector(accountSauSelector.selectSummary)

    useEffect(() => {
        // Данные загружаются вместе со списком САУ
        return () => {
            dispatch(actions.clear())
        }
    }, [])

    return (
        <div className={styles.widgetsWrapper}>
            <div className={styles.right}>
                {summary && (
                    <AccountWidget
                        userName={summary.user_uuid}
                        userUuid={summary.user_uuid}
                        balanceInfo={{ ...summary }}
                    />
                )}
            </div>
            <div className={styles.left}>
                <InfoWidget
                    label="Свободных средств"
                    value={asMoney(summary.balance.available ?? 0)}
                    format="money"
                />
                <div className={styles.BalanceHeaderDivider}></div>
                <InfoWidget
                    label="Зарезервировано"
                    value={asMoney(summary?.balance.reserved ?? 0)}
                    format="money"
                />
                <div className={styles.BalanceHeaderDivider}></div>
                <InfoWidget
                    label="Всего"
                    value={asMoney(summary?.balance.total ?? 0)}
                    format="money"
                />
                <div className={styles.BalanceHeaderDivider}></div>
                <div style={{ marginLeft: 15 }}>
                    <Debit />
                </div>
            </div>
        </div>
    )
}
