import { CalendarOutlined } from '@ant-design/icons'
import { DatePicker, Input } from 'antd'
import locale from 'antd/lib/date-picker/locale/ru_RU'
import moment, { Moment } from 'moment'
import 'moment/locale/ru'
import { useCallback, useState } from 'react'
import NumberInput from 'react-number-format'

import { DateItemProps } from '../../../../DateItem'
import { VIEW_DATE_FORMAT } from '../../../../constants'
import { useFormValues } from '../../../../hooks'
import { NOT_EMPTY, dateValidators } from '../../../../utils'
import { FormItem } from '../../FormItem'
import { WrappedInputProps } from '../../Input'
import './style.less'

type ComponentProps = WrappedInputProps & Pick<DateItemProps, 'onlyPast' | 'onlyFuture'>
export const DateInputMask = ({
    rules = [],
    disabled,
    placeholder = 'дд.мм.гггг',
    initialValue,
    name,
    listName,
    onlyPast,
    onlyFuture,
    ...rest
}: ComponentProps) => {
    const { setFormValue, validateField } = useFormValues({ name, listName })
    const [currentMoment, setCurrentMoment] = useState(
        initialValue ? moment.utc(initialValue, VIEW_DATE_FORMAT) : moment.utc(),
    )
    const [showCalendar, changeShowCalendar] = useState(false)
    const CustomInput = useCallback(
        (props) => (
            <Input
                className="formComponentItem"
                suffix={
                    <CalendarOutlined
                        onClick={() => changeShowCalendar((prevState) => !prevState)}
                    />
                }
                allowClear
                {...props}
            />
        ),
        [],
    )
    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = moment.utc(event?.target?.value, VIEW_DATE_FORMAT, true)
        if (date.isValid()) {
            setCurrentMoment(date)
        }
    }
    const onChangeCalendar = (value: Moment | null, dateString: string) => {
        if (value) {
            setCurrentMoment(value)
        }
        setFormValue(dateString)
        validateField()
    }
    const disabledDate = (current: Moment) => {
        if (onlyPast) {
            return current && current > moment().endOf('day')
        }
        if (onlyFuture) {
            return current && current <= moment().endOf('day')
        }
        return false
    }
    return (
        <div className="date-picker-mask__wrapper">
            <FormItem
                {...rest}
                initialValue={initialValue}
                name={name}
                listName={listName}
                rules={[NOT_EMPTY, { validator: dateValidators.isValid }, ...rules]}
            >
                <NumberInput
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChangeInput}
                    customInput={CustomInput}
                    format="##.##.####"
                    mask="_"
                />
            </FormItem>
            <DatePicker
                open={showCalendar}
                format={VIEW_DATE_FORMAT}
                locale={locale}
                value={currentMoment}
                disabled={disabled}
                onChange={onChangeCalendar}
                onOpenChange={changeShowCalendar}
                disabledDate={disabledDate}
            />
        </div>
    )
}
