import { Box, Button, CloseCircleOutlined, MiniModal, ModalProps } from '@dltru/dfa-ui'
import { FC } from 'react'

interface ErrorModalProps extends ModalProps {
    onOk?: () => void
}

export const AuthCodeErrorModal: FC<ErrorModalProps> = ({ onOk, ...rest }) => {
    return (
        <MiniModal width={423} {...rest}>
            <div>
                <Box direction="row" align="center">
                    <Box className="modal-content-shift">
                        <CloseCircleOutlined className="modal-icon modal-error-icon" />
                    </Box>
                    <div className="ant-modal-title">
                        <span>Ошибка</span>
                    </div>
                </Box>
                <div className="modal-content">
                    <p className="modal-content-text">Неверный код. Введите код из СМС.</p>
                </div>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[0, 8, 8, 8]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} type="primary" onClick={() => onOk?.()}>
                        Ок
                    </Button>
                </Box>
            </div>
        </MiniModal>
    )
}
