import { RbacContext } from '@dltru/dfa-rbac'
import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { BlockClientAction } from './actions/BlockClientAction'
import { CancelRemoveClientAction } from './actions/CancelRemoveClientAction'
import { ForcedEndOfSessionAction } from './actions/ForcedEndOfSessionAction'
import { RelateAccountAction } from './actions/RelateAccountAction'
import { RemoveClientAction } from './actions/RemoveClientAction'
import { SendToRejectAction } from './actions/SendToRejectAction'
import { SetIdentificationAction } from './actions/SetIdentificationAction'
import { UnBlockClientAction } from './actions/UnBlockClientAction'

export const Footer: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isViewFooter = contentFooter && typeof contentFooter !== null

    return (
        <>
            {isViewFooter && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {isAllow(['session']) && <ForcedEndOfSessionAction />}
                        {isAllow(['user:status']) && <UnBlockClientAction />}
                        {isAllow(['user:status']) && <BlockClientAction />}
                        <SendToRejectAction />
                        <RelateAccountAction />
                        <SetIdentificationAction />
                        {isAllow(['user.write.all']) && <CancelRemoveClientAction />}
                        {isAllow(['user.write.all']) && <RemoveClientAction />}
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
