import cn from 'classnames'
import { FC, ReactNode } from 'react'

import '../style.less'

type TableHeaderProps = {
    classList?: string
    left?: ReactNode
    right?: ReactNode
}

const TableHeader: FC<TableHeaderProps> = ({ left, right, classList }) => (
    <div className={cn('table-dfa__header', classList)}>
        {left && <div className="table-dfa__header table-dfa__header--left">{left}</div>}

        {right && <div className="table-dfa__header table-dfa__header--right">{right}</div>}
    </div>
)

export default TableHeader
