import { FinanceAndPlannedForm, Form, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

interface IComponentProps {
    stepIndex: number
    isEditAllow: boolean
}

export const FinancialOperationsStep: FC<IComponentProps> = ({ stepIndex, isEditAllow }) => {
    const [form] = Form.useForm()

    const { editable, stepsTotal, currentStep } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle="Сведения о финансировании и планируемых операциях"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FinanceAndPlannedForm uneditable={isEdit} />
            <div className="formRow" style={{ marginLeft: 240 }}>
                <UploadInputForm
                    name="sources_funds_file_uuids"
                    uneditable={isEdit}
                    title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
        </Step>
    )
}
