import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import { FC } from 'react'

import { NOT_EMPTY } from '../../../utils'
import { FormItem, FormItemProps } from '../FormItem'

export type AddressInputProps = FormItemProps &
    Pick<InputProps, 'disabled' | 'onChange'> & {
        placeholder?: string
        maxLength?: number
        minLength?: number
        width?: number
    }

export const AddressInput: FC<AddressInputProps> = ({
    disabled,
    onChange,
    required = false,
    prefix,
    placeholder,
    maxLength,
    minLength,
    width,
    rules = [],
    ...rest
}) => (
    <FormItem
        {...rest}
        required={required}
        style={{ width: width + 'px' }}
        rules={[NOT_EMPTY, ...rules]}
    >
        <Input
            prefix={prefix}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
        />
    </FormItem>
)
