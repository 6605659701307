import {
    IEmployee,
    TariffPlaneEnum,
    tariffPlaneTypeShort,
    tariffPlaneTypeTranslate,
    userRolesTranslate,
} from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType, SelectData } from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

const typeOptions = (): SelectData[] =>
    Object.entries(tariffPlaneTypeTranslate).map(([key, value]) => ({
        value: key,
        label: `${tariffPlaneTypeShort[key as TariffPlaneEnum]}-${value}`,
    }))

const archiveOptions: SelectData[] = [
    { value: 'Активный', label: 'Активный' },
    { value: 'В архиве', label: 'В архиве' },
]

const EmployeeOption: FC<{ data?: DataProviderResponse & IEmployee }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <span>
                {data.role ? userRolesTranslate[data.role as IEmployee['role']] : data.role}
            </span>
            <span>{data.email}</span>
        </div>
    )
}

const autoGetEmployeeComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getEmployees({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Дата и время создания',
        name: 'created_at',
        filterType: FilterType.DATE_RANGE,
    },
    {
        label: 'Дата и время архивирования',
        name: 'archived_at',
        filterType: FilterType.DATE_RANGE,
        divider: true,
    },

    {
        label: 'Код TП',
        name: 'code',
        filterType: FilterType.TEXTINPUT,
    },
    {
        label: 'Тип',
        name: 'tariff_type',
        filterType: FilterType.SELECT,
        options: typeOptions(),
    },
    {
        label: 'Наименование TП',
        name: 'name',
        filterType: FilterType.TEXTINPUT,
        divider: true,
    },

    {
        label: 'Автор',
        name: 'created_by',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetEmployeeComplete,
        renderElement: <EmployeeOption />,
    },

    {
        label: 'Статус',
        name: 'archive',
        filterType: FilterType.SELECT,
        options: archiveOptions,
    },
]
