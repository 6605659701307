import { ApiPaths } from '../constants/ApiPaths'
import services, { ServicesType } from '../services'

export interface APIProps<A> {
    agent: A
}

export class API<A> {
    #agent: A
    #lib: ServicesType
    #paths: typeof ApiPaths

    constructor(props: APIProps<A>) {
        if (!props.agent) {
            throw new Error('API initialisation  error, lost agent')
        }

        this.#agent = props.agent
        this.#paths = ApiPaths

        for (let k in services) {
            services[k] = services[k].bind(this)
        }

        this.#lib = { ...services }
    }

    get requestAgent() {
        return this.#agent
    }

    get paths() {
        return this.#paths
    }

    get lib() {
        return this.#lib
    }
}
