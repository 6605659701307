import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const DownMini: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.58752 9.73932L3.48463 6.5185C3.26296 6.28841 3.26296 5.92421 3.48463 5.69412C3.7183 5.45156 4.10667 5.45156 4.34034 5.69412L7 8.45486L9.65967 5.69412C9.89334 5.45156 10.2817 5.45156 10.5154 5.69412C10.737 5.92421 10.737 6.28841 10.5154 6.5185L7.41248 9.73932C7.18468 9.97578 6.81533 9.97578 6.58752 9.73932Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
