import { GetNominalAccountsParams, NominalAccountModel } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, Button, Filters, PlusSquare, Table } from '@dltru/dfa-ui'
import { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { nominalAccountsSlice } from '@store/nominalAccounts'
import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import { filters } from './filters'
import { gridConfig } from './gridConfig'

export const NominalAccountsTable: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const showCreateBtn = isAllow(['nominal_account.write.all'])
    const isLoading = useSelector(nominalAccountsSelector.selectIsLoading)
    const items = useSelector(nominalAccountsSelector.selectList)
    const paging = useSelector(nominalAccountsSelector.selectPaging)
    const params = useSelector(nominalAccountsSelector.selectParams)
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(nominalAccountsSlice.actions.getItems(params))
        return () => {
            reduxDispatch(nominalAccountsSlice.actions.clearItems())
        }
    }, [])

    const onPerPageChange = (limit: number) => {
        reduxDispatch(nominalAccountsSlice.actions.getItems({ ...params, limit }))
    }

    const onNext = () => {
        reduxDispatch(nominalAccountsSlice.actions.getItems({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(nominalAccountsSlice.actions.getItems({ cursor: paging?.cursors?.prev }))
    }
    const onRow = (record: NominalAccountModel) => ({
        onClick: () => {
            navigate(`/nominal-account/${record.id}`)
        },
    })

    const onApplyFilters = (filters?: Partial<GetNominalAccountsParams>) => {
        reduxDispatch(
            nominalAccountsSlice.actions.getItems({
                ...params,
                ...filters,
            }),
        )
    }

    const onCreateAccount = () => {
        navigate('/nominal-account/create')
    }
    return (
        <Box padding={[8, 24, 24, 24]} className="boxContent">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items}
                onRow={onRow}
                clickableRow
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                rowKey="id"
                headerLeft={
                    <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                }
                footerLeft={
                    showCreateBtn && (
                        <Button
                            type="primary"
                            icon={<PlusSquare />}
                            onClick={onCreateAccount}
                            borderRadius={12}
                        >
                            Новый счёт
                        </Button>
                    )
                }
            />
        </Box>
    )
}
