import { PaymentOrder } from '@dltru/dfa-models'
import { Collapse, DownMini } from '@dltru/dfa-ui'
import classNames from 'classnames/bind'
import { FC, useState } from 'react'

import style from '../../style.m.less'
import { DetailsComponent } from './DetailsComponent'

const cn = classNames.bind(style)

const { Panel } = Collapse

interface IComponentProps {
    paymentOrder: PaymentOrder
}
const COLLAPSE_KEY = 'default'

export const DetailsCollapse: FC<IComponentProps> = ({ paymentOrder }) => {
    const [activeKey, setActiveKey] = useState<string | string[]>()
    const headerClass = cn(style.collapseBtn, {
        [style.collapseBtnActive]: activeKey?.includes(COLLAPSE_KEY),
    })
    return (
        <Collapse
            ghost
            activeKey={activeKey}
            onChange={setActiveKey}
            className={style.fullCollapse}
        >
            <Panel
                header={
                    <span className={headerClass}>
                        {activeKey?.includes(COLLAPSE_KEY)
                            ? 'Свернуть реквизиты'
                            : 'Реквизиты платежа'}
                        <DownMini />
                    </span>
                }
                key={COLLAPSE_KEY}
                showArrow={false}
            >
                <div className={style.grayBlockWrapper}>
                    <DetailsComponent paymentOrder={paymentOrder} type="payer" />
                    <DetailsComponent paymentOrder={paymentOrder} type="recipient" />
                </div>
            </Panel>
        </Collapse>
    )
}
