import { Box } from '@dltru/dfa-ui'
import { FC } from 'react'

import './index.less'

export const AuthSideBox: FC = () => {
    return (
        <Box className="authsidebox" margin={[0, 10, 0, 0]} padding={30}>
            <Box width={'124px'} className="authsidebox-title">
                СОТРУДНИКАМ
            </Box>
        </Box>
    )
}
