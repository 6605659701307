import { IDfaOwner } from '@dltru/dfa-models'
import {
    Box,
    BrifcaseTick,
    Button,
    Divider,
    Form,
    IColorModalProps,
    IntegerInput,
    MoneyAccountBlock,
    Price,
    LabelRow as Row,
    UploadFile,
    WrappedInput,
    dfaValidators,
} from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { repaymentMature } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import api from '@services/api'

import { UploadDocumentContainerWrapper } from '@components/Form'

import styles from './style.m.less'
import { ownersSelector } from '@store/dfa/owners/selectors'

type FormDataType = {
    repayment_document_uuid: UploadFile[]
    repayment_base: string
    dfa_count?: number
}

const getBalance = async (
    emitter_id: string,
    setBalance: (value: { balance: number; number: number }) => void,
) => {
    const { data } = await api.lib.getMoneyAccounts(emitter_id)
    if (data && data.items && data.items.length) {
        setBalance(data?.items?.[0] as { balance: number; number: number })
    }
}
interface IComponentProps extends Pick<IColorModalProps, 'setIsModalVisible'> {
    owner?: IDfaOwner
}
export const RepaymentMatureModalBody: FC<IComponentProps> = ({ setIsModalVisible, owner }) => {
    const [form] = Form.useForm<FormDataType>()
    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const dfaTotalForRepaymentFromAllOwners = useSelector(ownersSelector.selectDfaTotalForRepayment)
    const redeemPrice = dfaDetails?.redeem_price_per_dfa ?? 0

    const [balance, setBalance] = useState<{ balance: number; number: number }>()
    const [isBalanceError, setIsBalanceError] = useState(true)
    const dfaTotal = owner
        ? owner.active_amount_dfa +
          owner.injunction_amount_dfa +
          owner.blocked_amount_dfa +
          owner.encumbrance_amount_dfa
        : dfaTotalForRepaymentFromAllOwners
    const [dfaCount, setDfaCount] = useState(dfaTotal)
    const [repaymentSum, setRepaymentSum] = useState(redeemPrice * dfaCount)
    const [fee, setFee] = useState(0)

    const calculateFee = async () => {
        if (!dfaDetails.id) {
            return
        }

        if (owner && dfaCount) {
            const feeFromApi = await api.lib.calculateInvestorRepaymentFee({
                asset_id: dfaDetails.id,
                amount: dfaCount,
                user_uuid: owner.user_id,
            })
            setFee(feeFromApi?.data?.item ?? 0)
        } else if (!owner) {
            const feeFromApi = await api.lib.calculateMatureRepaymentFee(dfaDetails.id)
            setFee(feeFromApi?.data?.item ?? 0)
        }
    }

    useEffect(() => {
        if (dfaDetails.emitter_id && setBalance) {
            getBalance(dfaDetails.emitter_id, setBalance)
        }
    }, [dfaDetails.emitter_id, setBalance])

    useEffect(() => {
        calculateFee()
    }, [owner, dfaDetails.id, dfaCount])

    useEffect(() => {
        setIsBalanceError((repaymentSum ?? 0) * 100 + fee > (balance?.balance as number))
    }, [balance, repaymentSum, fee])

    const handleCancel = () => {
        form.resetFields()
        setIsModalVisible?.(false)
    }

    const handleOk = () => {
        form.submit()
    }

    const handleRepayment = (value: FormDataType) => {
        if (!dfaDetails.id) {
            return
        }
        reduxDispatch(
            repaymentMature({
                asset_id: dfaDetails.id,
                user_uuid: owner?.user_id,
                repayment_base: value.repayment_base,
                repayment_document_uuid: value.repayment_document_uuid?.[0]?.uid,
                amount: dfaCount,
                repayment_fee: fee,
            }),
        )
        handleCancel()
    }

    const onValuesChange = (changedValues: Partial<FormDataType>) => {
        if ('dfa_count' in changedValues) {
            const count = Number(changedValues.dfa_count || 0)
            setDfaCount(count)
            setRepaymentSum(redeemPrice * count)
        }
    }

    return (
        <>
            <h4 className={styles.headerBody}>Детали погашения</h4>
            <Form
                layout="vertical"
                form={form}
                onFinish={handleRepayment}
                onValuesChange={onValuesChange}
                scrollToFirstError
            >
                <div className={styles.scrollBody}>
                    {owner && (
                        <IntegerInput
                            label="Количество ЦФА"
                            name="dfa_count"
                            size="large"
                            required
                            disabled
                            /* TODO тут дб за вычетом зарезервированных под досрочное */
                            rules={[
                                {
                                    validator: dfaValidators.biggerAmount(dfaTotal),
                                },
                            ]}
                            initialValue={dfaTotal}
                        />
                    )}
                    <Box padding={[0, 16]}>
                        {owner && (
                            <>
                                <Row label="Владелец">
                                    <strong>{owner.user_full_name}</strong>
                                </Row>
                                <Divider margin={[10, 0]} />
                            </>
                        )}
                        <Row label="Сумма погашения">
                            <Price price={repaymentSum} />
                        </Row>
                        <Divider margin={[10, 0]} />
                        <Row label="Комиссия за погашение">
                            <Price price={(fee || 0) / 100} />
                        </Row>
                    </Box>
                    <Box padding={[16, 0, 0, 0]}>
                        <MoneyAccountBlock
                            title="Счёт Эмитента"
                            balance={balance?.balance}
                            accountNumber={balance?.number}
                            error={isBalanceError ? 'Недостаточно средств на счёте' : undefined}
                        />
                    </Box>
                    <Divider />
                    <h4 className={styles.headerBody}>Причина погашения</h4>
                    <UploadDocumentContainerWrapper
                        name="repayment_document_uuid"
                        title="Загрузите фотографии или сканы документов"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                        required
                    >
                        <WrappedInput
                            name="repayment_base"
                            placeholder="Ваш комментарий"
                            multiline
                            required
                            rows={5}
                        />
                    </UploadDocumentContainerWrapper>
                </div>

                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[48, 0, 0, 0]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={handleCancel}>
                        Отмена
                    </Button>
                    <Button
                        borderRadius={12}
                        icon={<BrifcaseTick />}
                        type="primary"
                        onClick={handleOk}
                        disabled={isBalanceError || !dfaCount}
                    >
                        Погасить {owner ? 'ЦФА' : 'выпуск'}
                    </Button>
                </Box>
            </Form>
        </>
    )
}
