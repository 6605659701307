import { DfaStatusEnum, UserRoles } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { approveDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const ConfirmModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const reduxDispatch = useDispatch()
    const { isAllow } = useContext(RbacContext)

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const handleOk = () => {
        let role: UserRoles | undefined = undefined
        if (isAllow(['approval.operator']) && dfaDetails.status === DfaStatusEnum.teller_approval) {
            role = UserRoles.TELLER
        } else if (
            isAllow(['approval.lawyer']) &&
            dfaDetails.status === DfaStatusEnum.lawyer_approval
        ) {
            role = UserRoles.LAWYER
        } else if (isAllow(['approval.aml']) && dfaDetails.status === DfaStatusEnum.spec_approval) {
            role = UserRoles.SPEC
        }

        if (role) {
            reduxDispatch(approveDfa(role))
            handleCancel()
        }
    }

    return (
        <MiniModal visible={isModalVisible} onCancel={handleCancel}>
            <div>
                <Box direction="row" align="center">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">Допустить ЦФА к выпуску</div>
                </Box>
                <Box direction="row" align="center" justify="right">
                    <div className="modal-content-shift">&nbsp;</div>
                    <p className="modal-content-text">
                        Вы действительно хотите допустить ЦФА к выпуску?
                    </p>
                </Box>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[0, 8, 8, 8]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={handleCancel}>
                        Отмена
                    </Button>
                    <Button borderRadius={12} type="primary" onClick={handleOk}>
                        Да, допустить
                    </Button>
                </Box>
            </div>
        </MiniModal>
    )
}
