/* eslint-disable unused-imports/no-unused-vars */
import { ExternalRateIndex, unionTypeBackToFront } from '@dltru/dfa-models'
import { sortByFieldName } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'typed-redux-saga'

import IAppState from '@store/states'

import api from '@services/api'

import { loadAllOrdersByUser, loadDfasOrders, loadOrdersByDfaId, updateOrderList } from './index'

function* handleLoadOrders() {
    try {
        yield* put(updateOrderList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getDfaOrdersService()
        if (error) {
            throw error
        }

        const { data: rateData } = yield api.lib.getExternalRates(ExternalRateIndex.RUGOLD)

        if (!rateData) {
            throw new Error()
        }

        const ordersDataItems = (data?.items || []).map((item) => {
            if (item.price_per_dfa) {
                return item
            }

            const price =
                rateData.item.rate +
                (rateData.item.rate / 100) * (item.asset_investment_spread / 1000)

            return {
                ...item,
                amount_dfa: Math.floor(item.order_price / price),
                price_per_dfa: price,
                total_price: item.order_price,
            }
        })

        yield* put(updateOrderList({ isLoading: false, data: ordersDataItems }))
    } catch (error) {
        yield* put(updateOrderList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadOrdersById() {
    try {
        const dfa = yield* select((store: IAppState) => store.dfaDetails)
        yield* put(updateOrderList({ error: '', isLoading: true }))

        if (!dfa.id) {
            throw new Error('missed dfa id')
        }

        const { data, error } = yield api.lib.getDfaOrdersByIdService(dfa.id)
        if (error) {
            throw error
        }

        const { data: rateData } = yield api.lib.getExternalRates(ExternalRateIndex.RUGOLD)

        if (!rateData) {
            throw new Error()
        }

        const ordersDataItems = (data?.items || []).map((item) => {
            if (item.price_per_dfa) {
                return item
            }

            const price =
                rateData.item.rate +
                (rateData.item.rate / 100) * (item.asset_investment_spread / 1000)

            return {
                ...item,
                amount_dfa: Math.floor(item.order_price / price),
                price_per_dfa: price,
                total_price: item.order_price,
            }
        })

        yield* put(
            updateOrderList({ isLoading: false, data: ordersDataItems.map(unionTypeBackToFront) }),
        )
    } catch (error) {
        yield* put(updateOrderList({ error, isLoading: false, data: [] }))
    }
}
function* handleLoadAllOrdersByUser({ payload }: ReturnType<typeof loadAllOrdersByUser>) {
    try {
        yield* put(updateOrderList({ error: '', isLoading: true }))
        const query = {
            user_id: payload,
        }

        const { data: ordersData, error: ordersError } = yield api.lib.getDfaOrders(query)
        if (ordersError) {
            throw ordersError
        }

        const { data: rateData } = yield api.lib.getExternalRates(ExternalRateIndex.RUGOLD)

        if (!rateData) {
            throw ordersError
        }

        const ordersDataItems = (ordersData?.items || []).map((item) => {
            if (item.price_per_dfa) {
                return item
            }

            const price =
                rateData.item.rate +
                (rateData.item.rate / 100) * (item.asset_investment_spread / 1000)

            return {
                ...item,
                amount_dfa: Math.floor(item.order_price / price),
                price_per_dfa: price,
                total_price: item.order_price,
            }
        })

        const { data: secondaryOrdersData, error: secondaryOrdersError } =
            yield api.lib.getSecondaryDfaOrders(query)

        if (secondaryOrdersError) {
            throw secondaryOrdersError
        }

        yield* put(
            updateOrderList({
                isLoading: false,
                data: [...ordersDataItems, ...(secondaryOrdersData.items || [])]
                    .sort(sortByFieldName('created_at', 'desc'))
                    .map(unionTypeBackToFront),
            }),
        )
    } catch (error) {
        yield* put(updateOrderList({ error, isLoading: false, data: [] }))
    }
}

export function* watchOrdersSaga(): Generator<StrictEffect> {
    yield* takeLatest(loadDfasOrders.type, handleLoadOrders)
    yield* takeLatest(loadOrdersByDfaId.type, handleLoadOrdersById)
    yield* takeLatest(loadAllOrdersByUser.type, handleLoadAllOrdersByUser)
}
