import { IEmployee, employeeStatusTranslate, userRolesTranslate } from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType, SelectData } from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

/*const roleOptions: SelectData[] = employeeRoles.map((role) => ({
    value: role,
    label: userRolesTranslate[role],
}))*/

const statusOptions: SelectData[] = Object.entries(employeeStatusTranslate).map(([key, value]) => ({
    value: key,
    label: value,
}))

const EmployeeOption: FC<{ data?: DataProviderResponse & IEmployee }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <span>{data.role ? userRolesTranslate[data.role] : data.role}</span>{' '}
            <span>{data.email}</span>
        </div>
    )
}

const autoGetEmployeeComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getEmployees({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    /*    TODO позже добавить, когда бэк поправит
{
        label: 'Дата и время создания учётной записи',
        name: 'created_at',
        filterType: FilterType.DATE_RANGE,
    },*/

    {
        label: 'Сотрудник',
        name: 'user_uuid',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetEmployeeComplete,
        renderElement: <EmployeeOption />,
    },
    /*    TODO позже добавить, когда бэк поправит
{
        label: 'Роль',
        name: 'role',
        filterType: FilterType.SELECT,
        options: roleOptions,
    },*/
    {
        label: 'Администратор, добавивший учётную запись',
        name: 'created_by',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetEmployeeComplete,
        renderElement: <EmployeeOption />,
        divider: true,
    },
    {
        label: 'Статус',
        name: 'status',
        filterType: FilterType.SELECT,
        options: statusOptions,
    },
]
