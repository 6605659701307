export const disableFields = [
    'birthdate',
    'place_birth',
    'inn',
    'snils',
    'phone',
    'email',
    'planned_operations',
]
export const clearFields = disableFields.reduce((acc, fieldName) => {
    acc[fieldName] = undefined
    return acc
}, {} as Record<string, undefined>)
