import { ProfileStatus } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CloseCircleOutlined } from '@dltru/dfa-ui'
import React, { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { RefuseModal } from '@pages/RoleForms/forms/components/Footer/modals/RefuseModal'

export const SendToRejectAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { id } = useParams()
    const profileStatus = useSelector(clientsSelector.selectMainStatus)
    const profileType = useSelector(clientsSelector.selectProfileTypes)
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()

    const isVisibleBtn =
        id &&
        profileType &&
        profileStatus === ProfileStatus.IDENTIFICATION &&
        isAllow(['approval.operator'])

    if (!isVisibleBtn) {
        return null
    }

    const changeStatus = (comment: string) => {
        reduxDispatch(
            clientsSlice.actions.changeClientStatus({
                profileType: profileType[0],
                profileUuid: id,
                userUuid: id,
                comment,
                status: ProfileStatus.REJECTED,
            }),
        )
        setIsModalVisible(false)
    }
    return (
        <>
            <Button
                icon={<CloseCircleOutlined />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                Отклонить
            </Button>
            <RefuseModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={changeStatus}
            />
        </>
    )
}
