import { ITariffGrid } from '@dltru/dfa-models'
import { Tag } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import React from 'react'

export const getStatusTagGrid = (grid: ITariffGrid) => {
    let color = 'success'
    let title = 'Активирована'
    if (!grid.is_active) {
        color = 'blue'
        title = 'Черновик'
    } else if (grid.deleted_at) {
        color = 'error'
        title = 'Удалена'
    } else if (grid.active_to && dayjs().isAfter(dayjs(grid.active_to))) {
        color = 'default'
        title = 'В архиве'
    } else if (grid.active_from && dayjs().isBefore(dayjs(grid.active_from))) {
        color = 'blue'
        title = 'Ожидает активации'
    }
    return <Tag color={color}>{title}</Tag>
}
