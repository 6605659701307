import { EmployeeStatus, employeeStatusTranslate } from '@dltru/dfa-models'

import { StatusDetails } from './commonState'

export const employeeStatus = {
    [EmployeeStatus.active]: {
        color: 'default',
        title: employeeStatusTranslate[EmployeeStatus.active],
    },
    [EmployeeStatus.archived]: {
        color: 'error',
        title: employeeStatusTranslate[EmployeeStatus.archived],
    },
    [EmployeeStatus.blocked]: {
        color: 'error',
        title: employeeStatusTranslate[EmployeeStatus.blocked],
    },
} as StatusDetails<EmployeeStatus>

export const getEmployeeStatusTagProps = (status: EmployeeStatus) =>
    employeeStatus[status] ?? { color: 'default', title: status }
