import { combineReducers } from '@reduxjs/toolkit'

import list from './list'
import * as listSagas from './sagas'
import summary from './summary'

export const sagas = {
    ...listSagas,
}

export default combineReducers({ list, summary })
