import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const SettingsOutline: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.50001 18.3334H12.5C16.6667 18.3334 18.3333 16.6667 18.3333 12.5V7.50002C18.3333 3.33335 16.6667 1.66669 12.5 1.66669H7.50001C3.33334 1.66669 1.66667 3.33335 1.66667 7.50002V12.5C1.66667 16.6667 3.33334 18.3334 7.50001 18.3334Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.975 15.4167V12.1667"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.975 6.20831V4.58331"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.975 10.5416C14.1716 10.5416 15.1417 9.5716 15.1417 8.37498C15.1417 7.17836 14.1716 6.20831 12.975 6.20831C11.7784 6.20831 10.8083 7.17836 10.8083 8.37498C10.8083 9.5716 11.7784 10.5416 12.975 10.5416Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.02499 15.4167V13.7917"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.02499 7.83331V4.58331"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.025 13.7916C8.22162 13.7916 9.19167 12.8216 9.19167 11.625C9.19167 10.4284 8.22162 9.45831 7.025 9.45831C5.82839 9.45831 4.85834 10.4284 4.85834 11.625C4.85834 12.8216 5.82839 13.7916 7.025 13.7916Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
