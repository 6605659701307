import { Box, Button, Divider, Form, Modal, Space, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

interface IComponentProps {
    isModalVisible: boolean

    onOk: (value: string) => void
    onCancel: () => void
}

type FormValueType = {
    reason_description: string
}

export const NeedChangeModal: FC<IComponentProps> = ({ isModalVisible, onOk, onCancel }) => {
    const [form] = Form.useForm<FormValueType>()
    const [isEmptyReason, setIsEmptyReason] = useState(true)

    const handleOk = () => {
        const { reason_description } = form.getFieldsValue()
        onOk(reason_description)
    }

    const onValuesChange = (_, allValues: FormValueType) => {
        setIsEmptyReason(!allValues.reason_description)
    }

    return (
        <Modal width={520} visible={isModalVisible} onCancel={onCancel} destroyOnClose>
            <div>
                <Box direction="row" align="center" padding={[0, 0, 24, 0]}>
                    <div className="ant-modal-title">Отправить на доработку</div>
                </Box>
                <Box
                    direction="column"
                    align="center"
                    justify="right"
                    padding={[24, 0, 0, 0]}
                    //className={styles.preLine}
                >
                    <p>
                        Вы действительно хотите отправить заявку о признании квалифицированным
                        инвестором на доработку?
                    </p>
                </Box>
                <Divider />
                <h4>Замечания и предложения для заявителя</h4>
                <Box margin={[24, 0]}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleOk}
                        onValuesChange={onValuesChange}
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="reason_description"
                            multiline
                            maxLength={200}
                            required
                        />
                    </Form>
                </Box>
                <Box padding={[24, 0, 0, 0]} direction="row" justify="right">
                    <Space>
                        <Button borderRadius={12} onClick={onCancel}>
                            Отмена
                        </Button>

                        <Button
                            borderRadius={12}
                            type="primary"
                            onClick={form.submit}
                            disabled={isEmptyReason}
                        >
                            Да, отправить
                        </Button>
                    </Space>
                </Box>
            </div>
        </Modal>
    )
}
