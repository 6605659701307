import { Box, Button, DownMini, Dropdown, Menu, UserOutlined } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { logout } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

export const PersonalAccountButton: FC = () => {
    const user = useSelector(authSelector.selectUserName)
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const logoutHandler = (e: React.MouseEvent) => {
        e.preventDefault()
        reduxDispatch(logout())
        navigate('/login')
    }
    const menu = (
        <Menu>
            <Menu.Item key="profile">
                <Link to="/profile">Профиль</Link>
            </Menu.Item>
            <Menu.Item>
                <a href="/" onClick={logoutHandler}>
                    Выход
                </a>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button type="text" className="antbuttoncontentheader">
                <Box direction="row" justify="space-between" align="center">
                    <UserOutlined />
                    <span>{user}</span>
                    <DownMini />
                </Box>
            </Button>
        </Dropdown>
    )
}
