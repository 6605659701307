import { DatePickerProps } from 'antd'
import moment, { Moment } from 'moment'

import { DatePeriod } from '../DateItem'

const endOfDay = () => moment().endOf('day')
const startOfDay = () => moment().startOf('day')

const onlyPastFilter: DatePickerProps['disabledDate'] = (d) => d >= endOfDay()

const onlyBeforeDateFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') >= date.endOf('day')
    }

const onlyBeforeDateAndTodayFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') > date.endOf('day')
    }
const onlyFutureFilter: DatePickerProps['disabledDate'] = (d) => {
    const cloned = d.clone()
    return cloned.endOf('day') <= endOfDay()
}
const onlyFutureAndTodayFilter: DatePickerProps['disabledDate'] = (d) => d <= startOfDay()

const onlyPastWorkdaysFilter: DatePickerProps['disabledDate'] = (d) => d > endOfDay()

const onlyFutureWorkdaysFilter: DatePickerProps['disabledDate'] = (d) =>
    d < endOfDay() && d.isoWeekday() > 5

const onlyBeforeDateWorkdaysFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') >= date.endOf('day') || cloned.isoWeekday() > 5
    }

const onlyBeforeDateAndTodayWorkdaysFilter =
    (beforeDate: Moment): DatePickerProps['disabledDate'] =>
    (d) => {
        const cloned = d.clone()
        const date = beforeDate.clone()

        return cloned.endOf('day') > date.endOf('day') || cloned.isoWeekday() > 5
    }

export function datePeriodFilter({
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyFutureAndToday,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
}: DatePeriod) {
    if (onlyPast) {
        return onlyWorkDays ? onlyPastWorkdaysFilter : onlyPastFilter
    }

    if (onlyBeforeDate) {
        return onlyWorkDays
            ? onlyBeforeDateWorkdaysFilter(onlyBeforeDate)
            : onlyBeforeDateFilter(onlyBeforeDate)
    }

    if (onlyBeforeDateAndToday) {
        return onlyWorkDays
            ? onlyBeforeDateAndTodayWorkdaysFilter(onlyBeforeDateAndToday)
            : onlyBeforeDateAndTodayFilter(onlyBeforeDateAndToday)
    }

    if (onlyFuture) {
        return onlyWorkDays ? onlyFutureWorkdaysFilter : onlyFutureFilter
    }

    if (onlyFutureAndToday) {
        return onlyFutureAndTodayFilter
    }
}
