import { commonValidators, onlyDigits } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & { name?: WrappedInputProps['name'] }
export const OkpoInput = ({
    className,
    label,
    name,
    placeholder,
    ...restProps
}: ComponentProps) => {
    return (
        <WrappedInput
            {...restProps}
            className={className ?? 'formComponentItem'}
            label={label ?? 'ОКПО'}
            name={name ?? 'okpo'}
            placeholder={placeholder ?? 'Состоит из 8 цифр'}
            rules={[{ validator: commonValidators.number(8) }]}
            maxLength={8}
            normalize={onlyDigits}
        />
    )
}
