import { createSelector } from '@reduxjs/toolkit'

import IAppState from '../states'

const selectQualifyStatements = (state: IAppState) => state.qualifyStatement.items
const selectCurrentQualifyStatement = (state: IAppState) => state.qualifyStatement.currentData
const selectCurrentQualifyStatementEvents = (state: IAppState) => state.qualifyStatement.events
const selectIsLoaded = (state: IAppState) => state.qualifyStatement.isLoading
const selectPaging = (state: IAppState) => state.qualifyStatement.paging
const selectPagedData = createSelector(
    selectQualifyStatements,
    selectIsLoaded,
    selectPaging,
    (data, isLoading, paging) => ({ data, isLoading, paging }),
)

export const qualifyStatementSelector = {
    selectQualifyStatements,
    selectCurrentQualifyStatement,
    selectCurrentQualifyStatementEvents,
    selectIsLoaded,
    selectPaging,
    selectPagedData,
}
