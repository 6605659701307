import { TransactionType, TransactionTypes } from '@dltru/dfa-models'
import {
    ColumnsType,
    DateTimeCell,
    LinkCell,
    Tag,
    Tooltip,
    getRublesFromPennies,
    isSRRUid,
} from '@dltru/dfa-ui'
import { getTagProps, transactionTypes } from '@resources/transactionState'

const ClientLinkWithTooltip = (uuid: string, title: string) => {
    if (isSRRUid(uuid)) {
        return 'ООО "СИСТЕМЫ РАСПРЕДЕЛЕННОГО РЕЕСТРА"'
    }
    return (
        <Tooltip title={title || uuid} placement="top">
            {LinkCell(title || uuid, `/clients/${uuid}`)}
        </Tooltip>
    )
}

export const gridConfig: ColumnsType<TransactionType> = [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Пользователь',
        dataIndex: 'account_owner_uuid',
        key: 'account_owner_uuid',
        sorter: true,
        ellipsis: true,
        render: (value, record) => ClientLinkWithTooltip(value, record.account_owner_full_name),
    },
    {
        title: 'Тип поручения',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        ellipsis: true,
        render: (value) => transactionTypes[value as TransactionTypes],
    },
    {
        title: 'Сумма платежа, ₽',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: getRublesFromPennies,
    },
    {
        title: 'Назначение платежа',
        dataIndex: 'purpose',
        key: 'purpose',
        sorter: true,
        ellipsis: true,
        render: (value) => (
            <Tooltip title={value} placement="top">
                {value}
            </Tooltip>
        ),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        render: (value) => {
            const tagProps = getTagProps(value)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
