import {
    IEmissionOrder,
    IRequest,
    PostEmissionOrderType,
    SignTransactionFullType,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem, ResponseItems } from '../../types/types'
import { path } from '../../utils'

export async function storeOrderService(
    order: Partial<IEmissionOrder>,
): Promise<IRequest<ResponseItem<IEmissionOrder>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaOrdersByDfaId, order.id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: order },
    )
}

export async function storeEmissionOrderServiceV3(
    data: PostEmissionOrderType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.DfaInvestOrdersV3,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getDfaOrdersService<T = IEmissionOrder[]>(): Promise<
    IRequest<ResponseItems<T>>
> {
    return requestDecorator.call(this, this.paths.DfaOrders, HttpMethod.GET, HttpCode.SUCCESS_200)
}

/*
export async function revokeOrderService<T>(order: unknown): Promise<IRequest>{
    try {
        const { data, status } = await request.patch(`/issue_dfa/order/revoke/`, order)

        if (status !== 200) {
            throw new Error('login error')
        }

        return { data: data.items, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}
*/

export async function getDfaOrdersByIdService<T>(
    dfaId: number,
): Promise<IRequest<ResponseItems<T>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaOrdersByDfaId, dfaId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function acceptedByEmitter<T>(order_uuid: string): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.DfaOrderAcceptedByEmitter,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { order_uuid } },
    )
}

export async function refusedByEmitter<T>(order_uuid: string): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.DfaOrderRefusedByEmitter,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: { order_uuid } },
    )
}

export async function getDfaOrderById(
    orderId: number,
): Promise<IRequest<ResponseItem<IEmissionOrder>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DfaOrdersByOrderId, orderId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getSecondaryDfaOrders(query?: {
    user_id?: string
}): Promise<IRequest<ResponseItem<IEmissionOrder>>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaOrders,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: query },
    )
}

export async function getDfaOrders(query?: {
    user_id?: string
}): Promise<IRequest<ResponseItem<IEmissionOrder>>> {
    return requestDecorator.call(this, this.paths.DfaOrders, HttpMethod.GET, HttpCode.SUCCESS_200, {
        params: query,
    })
}
