import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const BellPlus: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.75 23.0834H25.5C26.0531 23.0834 26.5 23.5302 26.5 24.0834V25.0834C26.5 25.2209 26.3875 25.3334 26.25 25.3334H19.5C19.5 27.2646 17.9313 28.8334 16 28.8334C14.0688 28.8334 12.5 27.2646 12.5 25.3334H5.75C5.6125 25.3334 5.5 25.2209 5.5 25.0834V24.0834C5.5 23.5302 5.94687 23.0834 6.5 23.0834H7.25V12.4584C7.25 8.049 10.5094 4.40525 14.75 3.799V2.58337C14.75 1.89275 15.3094 1.33337 16 1.33337C16.6906 1.33337 17.25 1.89275 17.25 2.58337V3.799C21.4906 4.40525 24.75 8.049 24.75 12.4584V23.0834ZM14.5 25.3334C14.5 26.1615 15.1719 26.8334 16 26.8334C16.8281 26.8334 17.5 26.1615 17.5 25.3334H14.5ZM9.5 12.4584V23.0834H22.5V12.4584C22.5 10.7209 21.825 9.08962 20.5969 7.8615C19.3687 6.63337 17.7375 5.95837 16 5.95837C14.2625 5.95837 12.6312 6.63337 11.4031 7.8615C10.175 9.08962 9.5 10.7209 9.5 12.4584ZM15 12H17V14.6667H19.6667V16.6667H17V19.3334H15V16.6667H12.3333V14.6667H15V12Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
