import { Box, Card, CardContent, PageHeader } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { employeeSlice } from '@store/employee'
import { employeeSelector } from '@store/employee/selectors'

import { EmployeeTable } from './components/EmployeeTable'
import { Footer } from './components/Footer'

export const EmployeeList: FC = () => {
    const reduxDispatch = useDispatch()
    const params = useSelector(employeeSelector.selectParams)

    useEffect(() => {
        reduxDispatch(employeeSlice.actions.getItems(params))
        return () => {
            reduxDispatch(employeeSlice.actions.clear())
        }
    }, [])

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Сотрудники" />
            </Box>
            <Card>
                <CardContent>
                    <EmployeeTable />
                </CardContent>
            </Card>
            <Footer />
        </>
    )
}
