import { MoneyAccountByNominal } from '@dltru/dfa-models'
import { Box, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { nominalAccountsSlice } from '@store/nominalAccounts'
import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import { gridConfig } from './gridConfig'

export const RelatedAccounts: FC = () => {
    const navigate = useNavigate()
    const data = useSelector(nominalAccountsSelector.selectRelatedAccount)
    const isLoading = useSelector(nominalAccountsSelector.selectIsLoading)

    const { id } = useParams()
    const reduxDispatch = useDispatch()
    useEffect(() => {
        if (id) {
            reduxDispatch(nominalAccountsSlice.actions.getRelatedAccountsByNumber(id))
        }
    }, [id])
    const onRow = (record: MoneyAccountByNominal) => ({
        onClick: () => {
            navigate(`/clients/${record.user_uuid}#account`)
        },
    })
    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                noPagination
                onRow={onRow}
                clickableRow
                rowKey="id"
            />
        </Box>
    )
}
