import { IInvestorDeals, Paging, SortType, TablePaginationParam } from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, FilterRecord } from '@dltru/dfa-ui'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

export interface IInvestorsDealsState {
    isLoading: boolean
    filters: FilterRecord
    sort: SortType
    paging: Paging | undefined
    limit: number
    data: IInvestorDeals[] | undefined
    error?: string
}
export interface IInvestorsDealsDataAndPaging {
    paging: Paging
    items: IInvestorDeals[]
}

export const initialState: IInvestorsDealsState = {
    isLoading: false,
    filters: {},
    sort: {},
    paging: undefined,
    limit: DEFAULT_PER_PAGE,
    data: undefined,
    error: '',
}

export const investorsDealsSlice = createSlice({
    name: 'investorsDeals',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<IInvestorsDealsState['error']>) {
            state.error = action.payload
        },
        dropError(state) {
            state.error = ''
        },
        setInvestorsDeals(state, action: PayloadAction<Partial<IInvestorsDealsDataAndPaging>>) {
            state.error = ''
            state.isLoading = false
            state.data = action.payload.items
            state.paging = action.payload.paging
        },
        updateInvestorsDealsFilters(state, action: PayloadAction<FilterRecord>) {
            state.filters = action.payload
        },
        updateInvestorsDealsSorts(state, action: PayloadAction<SortType>) {
            state.sort = action.payload
        },
        updateInvestorsDealsPaging(state, action: PayloadAction<Paging>) {
            state.paging = action.payload
        },
        dropInvestorsDeals() {
            return { ...initialState }
        },
    },
})

export const getinvestorsDeals = createAction<TablePaginationParam>(
    'investorsDeals/getInvestorsDeals',
)

export default investorsDealsSlice.reducer
