import { DatePickerProps } from 'antd/lib/date-picker'
import { Moment } from 'moment'

import { FormItemProps } from '../components/Form/FormItem'

export const MAIN_SELECTOR = '#main'
export const PLACEHOLDER: [string, string] = ['дд.мм.гггг', 'дд.мм.гггг']

export type DatePeriod = {
    onlyPast?: boolean
    onlyBeforeDate?: Moment
    onlyBeforeDateAndToday?: Moment
    onlyWorkDays?: boolean
    onlyFuture?: boolean
}

export type DateRangeItemProps = FormItemProps &
    Pick<DatePickerProps, 'disabled' | 'onChange' | 'getPopupContainer'> &
    DatePeriod & {
        showQuickLinks?: boolean
    }
