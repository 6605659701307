import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const MedalIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11 13.75C14.4173 13.75 17.1875 11.0824 17.1875 7.79168C17.1875 4.50098 14.4173 1.83334 11 1.83334C7.58274 1.83334 4.8125 4.50098 4.8125 7.79168C4.8125 11.0824 7.58274 13.75 11 13.75Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.8933 12.3933L6.88416 19.1583C6.88416 19.9833 7.46166 20.3867 8.17666 20.0475L10.6333 18.8833C10.835 18.7825 11.1741 18.7825 11.3758 18.8833L13.8417 20.0475C14.5475 20.3775 15.1342 19.9833 15.1342 19.1583V12.2283"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
