import { ITariffPlane, tariffPlaneTypeShort } from '@dltru/dfa-models'

import { EMPTY_FIELD_VALUE } from '../../constants'

export const getTariffCode = (plane?: ITariffPlane) => {
    if (!plane) {
        return EMPTY_FIELD_VALUE
    }
    return `${tariffPlaneTypeShort[plane.type]}-${plane.code_id}${
        plane.active_grid?.code_id ? `.${plane.active_grid?.code_id}` : ''
    }`
}
