import { CertificateType } from '@dltru/dfa-models'
import React, { FC } from 'react'

import { Box } from '../../../Box'
import { QuestionCircle } from '../../../Icons'
import { Space } from '../../../Space'
import { Button } from '../../../button'
import { MiniModal } from '../../../components/Modal'
import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    certificate: CertificateType
    removeCertificateAction: (uuid: string) => void
}
export const CertificateRemoveModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    certificate,
    removeCertificateAction,
}) => {
    const removeCertificate = () => {
        removeCertificateAction(certificate.uuid)
        setIsModalVisible(false)
    }
    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            width={423}
            title={
                <Space size={16}>
                    <QuestionCircle className={style.grayIcon} />
                    Удалить сертификат
                </Space>
            }
            footer={[
                <Button onClick={() => setIsModalVisible(false)} borderRadius={12}>
                    Отмена
                </Button>,
                <Button type="primary" onClick={removeCertificate} borderRadius={12}>
                    Да, удалить
                </Button>,
            ]}
        >
            <Box margin={[24, 0, 24, 40]}>
                <p>Вы действительно хотите удалить сертификат УНЭП?</p>
                <p>Без действующего сертификата УНЭП вы не сможете выполнять операции с ЦФА</p>
            </Box>
        </MiniModal>
    )
}
