import Flags from '../../../../assets/flags'

export const telephoneCodesDictionary = [
    {
        code: '7',
        flag: <Flags.RU />,
    },
    /*    {
        code: '375',
        flag: <Flags.BY />,
    },
    {
        code: '996',
        flag: <Flags.KG />,
    },
    {
        code: '994',
        flag: <Flags.AZ />,
    },*/
]
