import { IUsersRegistryListItem } from '@dltru/dfa-models'
import { LinkCell, Lock16, Tooltip } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from './userFullNameCell.m.less'

type UserFullNameCellProps = Pick<
    IUsersRegistryListItem,
    'full_name' | 'block_type' | 'user_uuid' | 'block_reason'
>

export const UserFullNameCell: FC<UserFullNameCellProps> = ({
    full_name,
    block_type,
    user_uuid,
    block_reason,
}) => {
    return (
        <div className={style.UserFullNameCell}>
            {block_type && (
                <Tooltip title={block_reason}>
                    <Lock16 className={style.UserFullNameCellBlock} />
                </Tooltip>
            )}
            {LinkCell(full_name, `/clients/${user_uuid}`)}
        </div>
    )
}
