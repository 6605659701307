import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Copy: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.50175 3C8.15898 3.06437 8.95214 3.97901 9.00175 6.5V9.5C9.04145 11.7099 8.48283 12.941 6.00195 13H4.00195C1.52108 13.0536 1.04165 11.7045 1.00195 9.5L1.00194 6.5C0.952326 3.97901 1.85463 3.13409 3.50193 3H6.50175Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.9995 10V5C10.9499 2.47901 9.1585 1.06437 7.50128 1H5.00031"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
