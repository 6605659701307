import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

interface IconCustomizableProps extends IconProps {
    width?: number
    height?: number
}

export const UnLockIcon: FC<IconCustomizableProps> = ({ ...rest }) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.68005 11.68C6.98672 12.0733 7.46672 12.3333 8.00005 12.3333C8.92005 12.3333 9.66672 11.5867 9.66672 10.6667C9.66672 10.2867 9.54005 9.93332 9.32672 9.65332"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.88004 13.8666C1.47337 13.36 1.33337 12.5533 1.33337 11.3333V9.99996C1.33337 7.33329 2.00004 6.66663 4.66671 6.66663H11.3334C11.5734 6.66663 11.7934 6.67329 12 6.68663C14.1134 6.80663 14.6667 7.57329 14.6667 9.99996V11.3333C14.6667 14 14 14.6666 11.3334 14.6666H4.66671C4.42671 14.6666 4.20671 14.66 4.00004 14.6466"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4 6.66671V5.33337C4 3.12671 4.66667 1.33337 8 1.33337C10.7667 1.33337 11.6933 2.25337 11.9333 3.70671"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6667 1.33337L1.33337 14.6667"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...rest}
    />
)
