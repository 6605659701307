import { GetEmployeesParams, IEmployee } from '@dltru/dfa-models'
import { Filters, SearchInput, Space, Table } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { employeeSlice } from '@store/employee'
import { employeeSelector } from '@store/employee/selectors'

import { filters } from './filters'
import { gridConfig } from './gridConfig'

export const EmployeeTable: FC = () => {
    const reduxDispatch = useDispatch()

    const items = useSelector(employeeSelector.selectList)
    const paging = useSelector(employeeSelector.selectPaging)
    const isLoading = useSelector(employeeSelector.selectIsLoading)
    const params = useSelector(employeeSelector.selectParams)
    const navigate = useNavigate()

    const onPerPageChange = (limit: number) => {
        reduxDispatch(employeeSlice.actions.getItems({ ...params, limit }))
    }

    const onNext = () => {
        reduxDispatch(employeeSlice.actions.getItems({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(employeeSlice.actions.getItems({ cursor: paging?.cursors?.prev }))
    }

    const onRow = (record: IEmployee) => ({
        onClick: () => navigate(record.user_uuid),
    })
    const onSearch = (query: string) => {
        reduxDispatch(
            employeeSlice.actions.getItems({
                ...params,
                full_name: query ? `like(${query})` : undefined,
            }),
        )
    }

    const onApplyFilters = (filters?: Partial<GetEmployeesParams>) => {
        reduxDispatch(
            employeeSlice.actions.getItems({
                ...params,
                ...filters,
            }),
        )
    }

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={items}
            rowKey="user_uuid"
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            onRow={onRow}
            clickableRow
            headerLeft={
                <Space>
                    <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                    <SearchInput onSearch={onSearch} initialValue={params.full_name ?? ''} />
                </Space>
            }
        />
    )
}
