import { Box, MasterchainLogo } from '@dltru/dfa-ui'
import { Link } from 'react-router-dom'

import style from './style.m.less'

export const LogoBox = () => {
    return (
        <Link to="/">
            <Box direction="row">
                <div className={style.logo}>
                    <MasterchainLogo />
                </div>
                <div className={style.logoname}>
                    <span>ЦФА</span>
                </div>
            </Box>
        </Link>
    )
}
