import { SettlementsType, issueAvailableInvestorsTypeLimitsTranslate } from '@dltru/dfa-models'
import {
    CardDivider,
    RedeemDateBlock,
    RedeemPriceBlock,
    WrappedDateItem,
    WrappedInput,
    WrappedSelect,
    currentFloatNumber,
    currentNumber,
    dfaValidators,
} from '@dltru/dfa-ui'
import moment, { Moment } from 'moment'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

interface IPriceSourceStaticProps {
    isQualifiedType: boolean
    isEdit: boolean
    isTellerApprovalStatus: boolean
    isManualIssue: boolean
    repaymentSettlementsType?: SettlementsType
}

const optionsIssueAvailableInvestorsLimitsType = Object.entries(
    issueAvailableInvestorsTypeLimitsTranslate,
).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

export const PriceSourceStatic: FC<IPriceSourceStaticProps> = ({
    isQualifiedType,
    isEdit,
    isTellerApprovalStatus,
    isManualIssue,
    repaymentSettlementsType,
}) => {
    const { getFieldValue, validateFields } = useContext(FieldContext)
    const [issueStartDate, setIssueStartDate] = useState<Moment | undefined>()
    const [issueEndDate, setIssueEndDate] = useState<Moment | undefined>()
    useEffect(() => {
        setIssueStartDate(getFieldValue('issue_start_date'))
        setIssueEndDate(getFieldValue('issue_end_date') ?? getFieldValue('issue_start_date'))
    }, [isEdit])
    const onChangeIssueStartDate = (value: Moment | null) => {
        setIssueStartDate(value ?? undefined)
        if (getFieldValue('issue_end_date')) {
            validateFields(['issue_end_date'])
        } else {
            setIssueEndDate(value ?? undefined)
            if (getFieldValue('redeem_date')) {
                validateFields(['redeem_date'])
            }
        }
    }
    const onChangeIssueEndDate = (value?: Moment | null) => {
        setIssueEndDate(value ?? undefined)
        if (getFieldValue('redeem_date')) {
            validateFields(['redeem_date'])
        }
    }

    return (
        <>
            {!isQualifiedType && (
                <div className="formRow">
                    <WrappedSelect
                        className="formComponentItem"
                        label="Приобретение ЦФА физическими лицами - неквалифицированными инвесторами"
                        name="issue_available_investors_type_limits"
                        uneditable={isEdit}
                        required
                        options={optionsIssueAvailableInvestorsLimitsType}
                    />
                </div>
            )}
            <CardDivider />
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Количество выпускаемых ЦФА"
                    name="original_amount"
                    placeholder="Укажите количества выпускаемых ЦФА в единицах"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.amount() }]}
                    normalize={currentNumber}
                    disabled={isTellerApprovalStatus}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Цена ЦФА, руб."
                    name="price_per_dfa"
                    placeholder="Цена единицы"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.price }]}
                    normalize={currentFloatNumber}
                    disabled={isTellerApprovalStatus}
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Дата начала размещения ЦФА"
                    name="issue_start_date"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.issueStartDate }]}
                    showTime={{ defaultValue: moment('10:00', 'HH:mm') }}
                    onChange={onChangeIssueStartDate}
                    disabled={isTellerApprovalStatus}
                />
            </div>
            {!isManualIssue && (
                <>
                    <div className="formRow">
                        <WrappedDateItem
                            className="formComponentItem"
                            label="Дата окончания размещения ЦФА"
                            name="issue_end_date"
                            required
                            uneditable={isEdit}
                            rules={[{ validator: dfaValidators.issueEndDate(issueStartDate) }]}
                            showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
                            onChange={onChangeIssueEndDate}
                            disabled={isTellerApprovalStatus}
                        />
                    </div>
                    <CardDivider />
                </>
            )}
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem formComponentItem--xl"
                    label="Условие признания выпуска состоявшимся"
                    name="issue_conditions"
                    uneditable={isEdit}
                    disabled={!isManualIssue || isTellerApprovalStatus}
                    multiline
                />
            </div>
            <CardDivider />
            <div className="formRow">
                <RedeemPriceBlock uneditable={isEdit} disabled={isTellerApprovalStatus} />
            </div>
            <div className="formRow">
                <RedeemDateBlock
                    uneditable={isEdit}
                    dateForValidate={issueEndDate}
                    disabled={isTellerApprovalStatus}
                    settlementsType={repaymentSettlementsType}
                />
            </div>
        </>
    )
}
