import { RateBaseFrontType, RateBaseType } from '../../models'
import { stringToNumber } from '../../utils'

export const rateBackToFront = (rate: RateBaseType): RateBaseFrontType => {
    return {
        ...rate,
        annual_percentage: stringToNumber(rate.annual_percentage) / 100,
        amount_percentage: stringToNumber(rate.amount_percentage) / 100,
        minimum: (rate.minimum ?? 0) / 100,
    }
}
