import {
    AuthForm,
    Box,
    Button,
    CHECK_STATUSES,
    CheckList,
    CheckListItem,
    Form,
    WrappedInput,
    areAllChecksValid,
    authValidators,
    getErrorStatusesOfPasswordField,
    openMessage,
} from '@dltru/dfa-ui'
import { sha256 } from 'js-sha256'
import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { resetPasswordInit } from '@store/auth'

type FormDataType = {
    password: string
    repeatPassword: string
}
export const RestorePasswordForm: FC = () => {
    const [form] = Form.useForm<FormDataType>()
    const reduxDispatch = useDispatch()
    const [statuses, setStatuses] = useState(CHECK_STATUSES)
    const params = useParams()
    const [passwordValue, setPasswordValue] = useState('')
    const [savePasswordButtonDisableStatus, setSavePasswordButtonDisableStatus] = useState(false)

    const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
        const passwordField = changedFields.find(
            (field) => Array.isArray(field.name) && field.name[0] === 'password',
        )
        if (passwordField) {
            setPasswordValue(passwordField.value)
            setStatuses(getErrorStatusesOfPasswordField(statuses, passwordField))
            if (form.isFieldTouched('repeatPassword')) {
                form.validateFields(['repeatPassword'])
            }
        }
        setSavePasswordButtonDisableStatus(!areAllChecksValid(allFields))
    }

    const onFinish = (values: FormDataType) => {
        if (params.token) {
            reduxDispatch(
                resetPasswordInit({
                    confirm_token: params.token,
                    password: sha256(values.password).toString(),
                }),
            )
        } else {
            openMessage({ type: 'error', message: 'Отсутсвует подтверждающий токен' })
        }
    }

    return (
        <AuthForm
            title="Задайте пароль"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={onFinish}
        >
            <WrappedInput
                className="no-error-messages"
                size="large"
                name="password"
                label="Новый пароль"
                password
                required
                rules={[...statuses.map((status: CheckListItem) => status.rule)]}
            />

            <WrappedInput
                className="change-auth-form__without-margin"
                size="large"
                name="repeatPassword"
                label="Подтверждение пароля"
                password
                required
                rules={[
                    {
                        validator: authValidators.comparePassword(passwordValue),
                    },
                ]}
            />
            <Box>
                <CheckList elements={statuses} />
            </Box>
            <Box direction="row" justify="right" padding={[32, 0, 0, 0]}>
                <Button
                    borderRadius={16}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={savePasswordButtonDisableStatus}
                >
                    Сохранить пароль
                </Button>
            </Box>
        </AuthForm>
    )
}
