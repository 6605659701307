import { TariffPlaneEnum, tariffPlaneTypeTranslate } from '@dltru/dfa-models'
import { Box, PageHeader, Tabs, getTariffCode } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { tariffSlice } from '@store/tariff'
import { tariffSelector } from '@store/tariff/selectors'

import { getStatusTagPlane } from '@pages/SystemManagement/components/TariffTable/helper'

import { DetailsTariffPlane } from './components/DetailsTariffPlane'
import { Footer } from './components/Footer'
import { HistoryTariffGrid } from './components/HistoryTariffGrid'
import { RelatedAssetsTable as RelatedAssets } from './components/RelatedAssets'
import { RelatedUsersTable as RelatedUsers } from './components/RelatedUsers'

const { TabPane } = Tabs

export const TariffPlaneView: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const data = useSelector(tariffSelector.selectItem)

    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (id) {
            reduxDispatch(tariffSlice.actions.getCurrentTariff(id))
            reduxDispatch(tariffSlice.actions.getFees())
            reduxDispatch(tariffSlice.actions.getBaseTariff())
        }
        return () => {
            reduxDispatch(tariffSlice.actions.clearItem())
        }
    }, [id])

    if (!data?.id || id !== data.id) {
        return null
    }

    return (
        <Box padding={16}>
            <PageHeader
                title={`${getTariffCode(data)} ${data.name}`}
                onBack={() => navigate(-1)}
                subTitle={tariffPlaneTypeTranslate[data.type]}
                tags={getStatusTagPlane(data)}
            />
            <Tabs>
                <TabPane tab="Детали тарифного плана" key="details">
                    <DetailsTariffPlane />
                </TabPane>
                <TabPane tab="История тарифных сеток" key="grid">
                    <HistoryTariffGrid />
                </TabPane>
                {data.type === TariffPlaneEnum.INDIVIDUAL && (
                    <TabPane tab="Пользователи" key="users">
                        <RelatedUsers />
                    </TabPane>
                )}
                {data.type === TariffPlaneEnum.ASSET && (
                    <TabPane tab="Выпуски ЦФА" key="assets">
                        <RelatedAssets />
                    </TabPane>
                )}
            </Tabs>
            <Footer />
        </Box>
    )
}
