import { ProfileType, QualifyInfoType } from '@dltru/dfa-models'
import { FC, useState } from 'react'

import { Divider } from '../../Divider'
import { Space } from '../../Space'
import { Button } from '../../button'
import { Price, QualifiedDetailsModal } from '../modals'
import { NotQualified, Qualified } from './QualificationLabels'
import styles from './style.m.less'

export interface IQualificationInfoProps {
    qualifyInfo?: QualifyInfoType
    action?: () => void
    actionTitle?: string
    isAdmin?: boolean
    profileType: ProfileType | undefined
}
export const QualificationInfo: FC<IQualificationInfoProps> = ({
    qualifyInfo,
    action,
    actionTitle,
    isAdmin,
    profileType,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const showLimit = qualifyInfo?.is_qualified === false && profileType === ProfileType.PRSN

    return (
        <div className={styles.equalWrapper}>
            <Space direction="vertical">
                {qualifyInfo?.is_qualified ? <Qualified /> : <NotQualified />}
                {action && Boolean(actionTitle) && (
                    <Button style={{ marginLeft: 24 }} type="link" onClick={action} size="small">
                        {actionTitle}
                    </Button>
                )}
            </Space>
            {showLimit && (
                <>
                    <Divider type="vertical" margin={[0, 32]} />
                    <Space direction="vertical">
                        <div className={styles.description}>
                            Остаток годового лимита <br /> неквалифицированного инвестора
                        </div>
                        <Space>
                            <Price
                                price={
                                    qualifyInfo.quota ? qualifyInfo.quota / 100 : qualifyInfo.quota
                                }
                            />
                            <Button
                                type="link"
                                size="small"
                                onClick={() => setIsModalVisible(true)}
                            >
                                Подробнее
                            </Button>
                        </Space>
                    </Space>
                </>
            )}
            <QualifiedDetailsModal
                isAdmin={isAdmin}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                qualifyInfo={qualifyInfo}
            />
        </div>
    )
}
