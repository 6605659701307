import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box } from '../../../Box'
import { DocumentAdd, DocumentText } from '../../../Icons'
import { Space } from '../../../Space'
import { Button } from '../../../button'
import './style.less'

interface IRoleFormCard {
    title: string
    iconBox?: React.ReactNode
    subtitle: string
    disabled?: boolean
    isCompleted?: boolean
    tag?: React.ReactNode
    to: string
    isCreate?: boolean
}

export const RoleFormCard: FC<IRoleFormCard> = ({
    title,
    subtitle,
    disabled,
    iconBox,
    isCompleted,
    tag,
    to,
    isCreate,
}) => {
    const navigate = useNavigate()

    return (
        <Box className={`primary-form ${disabled ? 'disabled' : ''}`} direction="row" padding={16}>
            {!iconBox ? (
                isCompleted ? (
                    <Box className="primary-form-done-box" padding={12}>
                        <DocumentText className="primary-form-card-icon" />
                    </Box>
                ) : (
                    <Box className="primary-form-unknown-box" padding={12}>
                        <DocumentAdd className="primary-form-card-icon" />
                    </Box>
                )
            ) : (
                iconBox
            )}
            <Box margin={[0, 0, 0, 16]}>
                <Box direction="row">
                    <Space size={16} align="start">
                        <span className="primary-form-title">{title}</span>
                        {tag && <span className="primary-form-title">{tag}</span>}
                    </Space>
                </Box>
                <span className="primary-form-subtitle">{subtitle}</span>
                <Box direction="row">
                    <Button
                        type="link"
                        onClick={() => navigate(to, isCreate ? { state: { isCreate } } : undefined)}
                    >
                        {isCompleted ? 'Посмотреть' : 'Заполнить анкету'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
