import IAppState from '@store/states'

const selectItems = (state: IAppState) => state.walletEncumbrance.items
const selectPaging = (state: IAppState) => state.walletEncumbrance.paging
const selectIsLoading = (state: IAppState) => state.walletEncumbrance.isLoading
const selectError = (state: IAppState) => state.walletEncumbrance.error
const selectItem = (state: IAppState) => state.walletEncumbrance.item
const selectCreateData = (state: IAppState) => state.walletEncumbrance.createItemData

export const encumbranceSelectors = {
    selectItems,
    selectPaging,
    selectIsLoading,
    selectError,
    selectItem,
    selectCreateData,
}
