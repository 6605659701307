// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qualifiedBlock_wubIq {\n  line-height: 22px;\n}\n.isQualifiedIcon_YXzhK svg {\n  color: #52c41a;\n  width: 24px;\n  height: 24px;\n}\n.isNotQualifiedIcon_Rj_sr svg {\n  color: #868991;\n  width: 24px;\n  height: 24px;\n}\n.equalWrapper_DyTQw {\n  display: flex;\n  flex-direction: row;\n}\n.description_YBqv_ {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: #868991;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/qualification/style.m.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAGA;EAEI,cAAA;EACA,WAAA;EACA,YAAA;AAFJ;AAMA;EAEI,cAAA;EACA,WAAA;EACA,YAAA;AALJ;AASA;EACE,aAAA;EACA,mBAAA;AAPF;AAUA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AARF","sourcesContent":["@import '/src/assets/style/palette';\n\n.qualifiedBlock {\n  line-height: 22px;\n}\n.isQualifiedIcon {\n  svg {\n    color: @green-6;\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.isNotQualifiedIcon {\n  svg {\n    color: @gray-7;\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.equalWrapper {\n  display: flex;\n  flex-direction: row;\n}\n\n.description {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: @gray-7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qualifiedBlock": "qualifiedBlock_wubIq",
	"isQualifiedIcon": "isQualifiedIcon_YXzhK",
	"isNotQualifiedIcon": "isNotQualifiedIcon_Rj_sr",
	"equalWrapper": "equalWrapper_DyTQw",
	"description": "description_YBqv_"
};
export default ___CSS_LOADER_EXPORT___;
