import { Box, PageHeader } from '@dltru/dfa-ui'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { TokenStatusEnum, checkConfirmToken } from '@store/auth/index'
import { authSelector } from '@store/auth/selectors'

import { RestorePassword } from './components/RestorePassword'

export const RestorePasswordPage = () => {
    const [text, setText] = useState('Ожидание результата...')
    const params = useParams()

    const changeAuthState = useSelector(authSelector.selectChangeAuthState)

    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (params.token && changeAuthState.tokenStatus !== TokenStatusEnum.verify) {
            reduxDispatch(checkConfirmToken(params.token))
        }
    }, [params.token, changeAuthState.tokenStatus])
    useEffect(() => {
        if (changeAuthState.tokenStatus) {
            setText(changeAuthState.tokenStatus)
        }
    }, [changeAuthState.tokenStatus])

    if (changeAuthState.tokenStatus !== TokenStatusEnum.verify) {
        return (
            <>
                <Box padding={16}>
                    <PageHeader title="Страница изменения  пароля" />
                </Box>
                <Box padding={24}>
                    <h3>{text}</h3>
                </Box>
            </>
        )
    }

    return <RestorePassword />
}
