import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const MedalStarIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.4167 8.24998C17.4167 9.57915 17.0225 10.7983 16.3442 11.8158C15.3542 13.2825 13.7867 14.3183 11.9625 14.5841C11.6509 14.6391 11.33 14.6666 11 14.6666C10.67 14.6666 10.3492 14.6391 10.0375 14.5841C8.21337 14.3183 6.64587 13.2825 5.65587 11.8158C4.97754 10.7983 4.58337 9.57915 4.58337 8.24998C4.58337 4.70248 7.45254 1.83331 11 1.83331C14.5475 1.83331 17.4167 4.70248 17.4167 8.24998Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.4792 16.9309L17.9667 17.2883C17.6275 17.3708 17.3617 17.6275 17.2883 17.9667L16.9675 19.3142C16.7933 20.0475 15.8583 20.2675 15.3725 19.69L11 14.6667L6.62749 19.6992C6.14166 20.2767 5.20666 20.0567 5.03249 19.3233L4.71166 17.9758C4.62916 17.6367 4.36332 17.3708 4.03332 17.2975L2.52082 16.94C1.82416 16.775 1.57666 15.9042 2.08082 15.4L5.65582 11.825C6.64582 13.2917 8.21332 14.3275 10.0375 14.5934C10.3492 14.6484 10.67 14.6759 11 14.6759C11.33 14.6759 11.6508 14.6484 11.9625 14.5934C13.7867 14.3275 15.3542 13.2917 16.3442 11.825L19.9192 15.4C20.4233 15.895 20.1758 16.7659 19.4792 16.9309Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5317 5.48169L12.0725 6.56335C12.1458 6.71001 12.3383 6.85669 12.5125 6.88419L13.4933 7.04918C14.1167 7.15001 14.2633 7.60835 13.8142 8.05752L13.0533 8.81834C12.925 8.94667 12.8517 9.19419 12.8975 9.37752L13.1175 10.3217C13.2917 11.0642 12.8975 11.3575 12.2375 10.9633L11.3208 10.4225C11.1558 10.3217 10.8808 10.3217 10.7158 10.4225L9.79918 10.9633C9.13918 11.3483 8.74502 11.0642 8.91918 10.3217L9.13918 9.37752C9.17585 9.20335 9.11168 8.94667 8.98335 8.81834L8.22252 8.05752C7.77335 7.60835 7.92002 7.15918 8.54335 7.04918L9.52418 6.88419C9.68918 6.85669 9.88168 6.71001 9.95502 6.56335L10.4958 5.48169C10.7617 4.89502 11.2383 4.89502 11.5317 5.48169Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
