import { UnionDealFront } from '@dltru/dfa-models'
import { Amount, LabelRow, Price } from '@dltru/dfa-ui'
import React, { FC } from 'react'

import './style.less'

interface IComponentProps {
    deal: UnionDealFront
    isRepayment?: boolean
}
export const CommonInfoBlock: FC<IComponentProps> = ({ deal, isRepayment }) => (
    <div>
        <LabelRow label="Цена" className="label-row__space">
            <Price price={(deal.price_per_dfa ?? 0) / 100} />
        </LabelRow>
        <LabelRow label="Количество, ед." className="label-row__space">
            <Amount value={deal.amount_dfa} />
        </LabelRow>
        <LabelRow
            label={isRepayment ? 'Сумма погашения' : 'Сумма сделки'}
            className="label-row__space"
        >
            <Price price={((deal.price_per_dfa ?? 0) * deal.amount_dfa) / 100} />
        </LabelRow>
    </div>
)
