import { Button, Divider, Modal } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { tariffRelatedAssetsSlice } from '@store/tariffRelatedAssets'

import { DfasAutocomplete, IOption } from './DfasAutocomplete/DfasAutocomplete'
import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const RelateModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const { id } = useParams()
    const reduxDispatch = useDispatch()
    const [selected, setSelected] = useState<IOption[]>([])

    const onCloseModal = () => {
        setIsModalVisible(false)
    }

    const onAddIssuesHandler = () => {
        if (!id) {
            return
        }

        reduxDispatch(
            tariffRelatedAssetsSlice.actions.linkAssets({ assets: selected, tariff_id: id }),
        )

        setIsModalVisible(false)
    }

    return (
        <Modal
            visible={isModalVisible}
            width={800}
            onCancel={onCloseModal}
            className={style.modal}
            destroyOnClose
        >
            <div className={style.wrapper}>
                <div className={style.header}>Привязка выпусков к тарифному плану</div>
                <div className={style.body}>
                    <p>
                        Выбранные выпуски ЦФА будут добавлены к данному тарифному плану. Комиссии по
                        всем операциям будут расчитываться по данному тарифному плану.
                    </p>
                    <Divider margin={0} />

                    <div className={style.autocompleteContainer}>
                        <h6 className={style.autocompleteTitle}>Список выпусков ЦФА</h6>
                        <DfasAutocomplete
                            isEdit={false}
                            name="assets"
                            selected={selected}
                            onSelect={setSelected}
                        />
                    </div>
                </div>
                <div className={style.footer}>
                    <Button style={{ marginRight: 16, borderRadius: 12 }} onClick={onCloseModal}>
                        Отмена
                    </Button>

                    <Button
                        borderRadius={16}
                        type="primary"
                        disabled={!selected.length}
                        onClick={onAddIssuesHandler}
                    >
                        Добавить выпуски
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
