import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { walletAssetsSlice } from './index'
import { getDfaByIdHelper } from '@store/dfa/details/helpers'

function* loadWalletAssets({
    payload,
}: ReturnType<typeof walletAssetsSlice.actions.loadWalletAssets>) {
    yield* put(walletAssetsSlice.actions.setLoading())

    const { data, error } = yield* call(api.lib.getWalletDfaBalances, payload)

    if (error) {
        yield* put(walletAssetsSlice.actions.setError(`${error}`))
    } else if (!data?.items) {
        yield* put(walletAssetsSlice.actions.setError('Empty response'))
    } else {
        for (const item of data.items) {
            try {
                const dfa = yield* call(getDfaByIdHelper, item.asset_id)
                if (dfa) {
                    item.dfaStatus = dfa.status
                }
            } catch (error) {
                // console.warn(error)
            }
        }

        yield* put(walletAssetsSlice.actions.setItems(data.items))
    }
}

export function* watchWalletAssets(): Generator<StrictEffect> {
    yield* takeLatest(walletAssetsSlice.actions.loadWalletAssets.type, loadWalletAssets)
}
