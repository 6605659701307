import { WrappedInput, WrappedInputProps, dfaValidators } from '@dltru/dfa-ui'

import api from '@services/api'

type ComponentProps = Omit<WrappedInputProps, 'name'> & {
    name?: WrappedInputProps['name']
    checkUnique?: boolean
}

export const TickerInput = ({
    label,
    name,
    placeholder = '',
    className,
    checkUnique = true,
    ...rest
}: ComponentProps) => {
    return (
        <WrappedInput
            {...rest}
            label={label ?? 'Тикер выпуска'}
            className={className ?? 'formComponentItem'}
            name={name ?? 'ticker_symbol'}
            placeholder={placeholder}
            rules={[
                { validator: dfaValidators.ticker(checkUnique ? api.lib.checkTicker : undefined) },
            ]}
            maxLength={6}
        />
    )
}
