// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BalanceListHeader_uQLik {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.BalanceListHeader_uQLik .LeftWrapper_EmY_Y {\n  display: flex;\n  align-items: center;\n}\n.BalanceListHeader_uQLik .RightWrapper_cIg1f {\n  display: flex;\n  align-items: center;\n}\n.BalanceListHeader_uQLik .BalanceListTitle_K42hS {\n  font-family: SF Pro Display;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 28px;\n  display: flex;\n  align-items: center;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/Balance/components/BalanceList/style.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAFA;EAMI,aAAA;EACA,mBAAA;AADJ;AANA;EAWI,aAAA;EACA,mBAAA;AAFJ;AAVA;EAgBI,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAHJ","sourcesContent":["@import 'style/palette';\n\n.BalanceListHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .LeftWrapper {\n    display: flex;\n    align-items: center;\n  }\n\n  .RightWrapper {\n    display: flex;\n    align-items: center;\n  }\n\n  .BalanceListTitle {\n    font-family: SF Pro Display;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 28px;\n    display: flex;\n    align-items: center;\n    color: @gray-10;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BalanceListHeader": "BalanceListHeader_uQLik",
	"LeftWrapper": "LeftWrapper_EmY_Y",
	"RightWrapper": "RightWrapper_cIg1f",
	"BalanceListTitle": "BalanceListTitle_K42hS"
};
export default ___CSS_LOADER_EXPORT___;
