import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Profile2User: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.63333 9.05834C7.55 9.05 7.45 9.05 7.35833 9.05834C5.375 8.99167 3.8 7.36667 3.8 5.36667C3.8 3.32501 5.45 1.66667 7.5 1.66667C9.54167 1.66667 11.2 3.32501 11.2 5.36667C11.1917 7.36667 9.61667 8.99167 7.63333 9.05834Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.675 3.33333C15.2917 3.33333 16.5917 4.64166 16.5917 6.25C16.5917 7.825 15.3417 9.10833 13.7833 9.16666C13.7167 9.15833 13.6417 9.15833 13.5667 9.16666"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.46667 12.1333C1.45 13.4833 1.45 15.6833 3.46667 17.025C5.75833 18.5583 9.51667 18.5583 11.8083 17.025C13.825 15.675 13.825 13.475 11.8083 12.1333C9.525 10.6083 5.76667 10.6083 3.46667 12.1333Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.2833 16.6667C15.8833 16.5417 16.45 16.3 16.9167 15.9417C18.2167 14.9667 18.2167 13.3583 16.9167 12.3833C16.4583 12.0333 15.9 11.8 15.3083 11.6667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
