// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_PkhLk > div .wrapper_hD_xg {\n  display: flex;\n  flex-direction: column;\n}\n.modal_PkhLk > div .header_d1Lg0 {\n  display: flex;\n  font-family: SF Pro Display;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n  margin-bottom: 40px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/Refill/style.m.less"],"names":[],"mappings":"AAEA;EAGI,aAAA;EACA,sBAAA;AAHJ;AADA;EAQI,aAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAJJ","sourcesContent":["@import 'style/palette';\n\n.modal>div {\n\n  .wrapper {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .header {\n    display: flex;\n    font-family: SF Pro Display;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n    margin-bottom: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_PkhLk",
	"wrapper": "wrapper_hD_xg",
	"header": "header_d1Lg0"
};
export default ___CSS_LOADER_EXPORT___;
