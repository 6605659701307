import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const BookSaved: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3333 3.89168V13.95C18.3333 14.75 17.6833 15.5 16.8833 15.6L16.6083 15.6334C14.7917 15.875 11.9916 16.8 10.3916 17.6833C10.175 17.8083 9.81667 17.8083 9.59167 17.6833L9.55831 17.6667C7.95831 16.7917 5.16669 15.875 3.35836 15.6334L3.11666 15.6C2.31666 15.5 1.66667 14.75 1.66667 13.95V3.88334C1.66667 2.89167 2.47498 2.14168 3.46664 2.22502C5.21664 2.36668 7.86664 3.25004 9.34998 4.17504L9.55831 4.30001C9.79998 4.45001 10.2 4.45001 10.4417 4.30001L10.5833 4.20835C11.1083 3.88335 11.775 3.55835 12.5 3.26668V6.6667L14.1667 5.55835L15.8333 6.6667V2.31672C16.0583 2.27505 16.275 2.25002 16.475 2.23336H16.525C17.5167 2.15002 18.3333 2.89168 18.3333 3.89168Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 4.57498V17.075"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.8333 2.31668V6.66666L14.1667 5.55831L12.5 6.66666V3.26664C13.5917 2.83331 14.8083 2.48335 15.8333 2.31668Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
