import {
    Box,
    Col,
    Divider,
    EMPTY_FIELD_VALUE,
    Row,
    Space,
    getRublesFromPennies,
    rublesLabel,
} from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { paymentOrdersSelector } from '@store/paymentOrders/selectors'

import style from '../style.m.less'

export const TransactionDetails: FC = () => {
    const transactionInfo = useSelector(paymentOrdersSelector.selectCurrentTransaction)

    if (!transactionInfo) {
        return null
    }

    return (
        <Box padding={[24, 24, 8, 24]} margin={[24, 0, 0, 0]} className="boxContent">
            <h5 className={style.header}>Детали поручения</h5>
            <Box padding={[24, 8]}>
                <Row gutter={[16, 16]}>
                    <Col span={5} className={style.label}>
                        Пользователь
                    </Col>
                    <Col span={19}>
                        <Link
                            to={`/clients/${transactionInfo.account_owner_uuid}`}
                            className={style.link}
                        >
                            {transactionInfo.account_owner_full_name || EMPTY_FIELD_VALUE}
                        </Link>
                    </Col>
                    <Col span={5} className={style.label}>
                        Номер САУ
                    </Col>
                    <Col span={19}>{transactionInfo.account_number || EMPTY_FIELD_VALUE}</Col>
                    <Col span={5} className={style.label}>
                        Получатель
                    </Col>
                    <Col span={19}>
                        <Link
                            to={`/clients/${transactionInfo.recipient_uuid}`}
                            className={style.link}
                        >
                            {transactionInfo.recipient_full_name || EMPTY_FIELD_VALUE}
                        </Link>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 16]}>
                    <Col span={5} className={style.label}>
                        Сумма платежа
                    </Col>
                    <Col span={19}>
                        <Space size={[0, 8]} direction="vertical">
                            <span>
                                {getRublesFromPennies(transactionInfo.amount)}{' '}
                                {rublesLabel(Math.trunc(transactionInfo.amount / 100))}
                            </span>
                        </Space>
                    </Col>
                    <Col span={5} className={style.label}>
                        Назначение платежа
                    </Col>
                    <Col span={19}>{transactionInfo.purpose}</Col>
                </Row>
            </Box>
        </Box>
    )
}
