import { ITransferOrder, UnionOrderType } from '@dltru/dfa-models'
import { Box, Table } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { clientsSelector } from '@store/clients/selectors'
import { dropTransferOrderList, loadTransferOrders } from '@store/orders/transfer'
import { transferOrderSelector } from '@store/orders/transfer/selectors'

import { gridConfig } from './gridConfig'

export const OtherDfaTab: FC = () => {
    const reduxDispatch = useDispatch()
    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const isLoading = useSelector(transferOrderSelector.selectIsLoading)
    const data = useSelector(transferOrderSelector.selectData)
    const navigate = useNavigate()

    useEffect(() => {
        if (currentClientId) {
            reduxDispatch(loadTransferOrders({ user_id: currentClientId }))
        }
        return () => {
            reduxDispatch(dropTransferOrderList())
        }
    }, [currentClientId])

    const onRow = (order: UnionOrderType) => ({
        onClick: () => {
            navigate(`/ownership-transfer-request/${order.global_id}`)
        },
    })

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data as ITransferOrder[]}
                onRow={onRow}
                clickableRow
                showSorterTooltip={false}
            />
        </Box>
    )
}
