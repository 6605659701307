export const disableFields = [
    'registration_authority',
    'ogrn_date',
    'birthdate',
    'place_birth',
    'registration_certificate',
    'ogrn_file_uuid',
    'inn',
    'inn_file',
    'snils_file',
    'snils',
    'ogrnip',
    'planned_operations',
    'phone',
    'email',
]
export const clearFields = disableFields.reduce((acc, fieldName) => {
    acc[fieldName] = undefined
    return acc
}, {} as Record<string, undefined>)
