import { RateBaseType, stringToNumber } from '@dltru/dfa-models'
import { ColumnsType } from 'antd/lib/table'

import { getRublesFromPennies } from '../../../utils'
import style from './style.m.less'

const renderEditableNumber = (isEditable?: boolean) => (value?: string) =>
    value ? (
        (isEditable ? value : stringToNumber(value) / 100).toLocaleString()
    ) : (
        <span className={style.grayText}>0</span>
    )
const renderPenniesNumber = (value?: number) =>
    value ? getRublesFromPennies(value) : <span className={style.grayText}>0</span>

type GridConfigProps = { feeNames?: Record<string, string>; isEditable?: boolean }
export const getTariffGridConfig = ({
    feeNames,
    isEditable,
}: GridConfigProps): ColumnsType<RateBaseType> & { editable?: boolean } => [
    {
        title: 'Код комиссии',
        dataIndex: 'fee_code',
    },
    {
        title: 'Наименование комиссии',
        dataIndex: 'name',
        render: (value: string, record: RateBaseType) => value || feeNames?.[record.fee_code],
    },
    {
        title: 'Минимальная сумма, ₽',
        dataIndex: 'minimum',
        editable: isEditable,
        render: renderPenniesNumber,
    },
    {
        title: 'Процент от суммы сделки, %',
        dataIndex: 'amount_percentage',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: 'Годовая процентная ставка, %',
        dataIndex: 'annual_percentage',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
]
