import { IRequest, ResponseItem, UploadResult } from '@dltru/dfa-models'

import { openMessage } from '../Notification'

export const downLoadFileBySrc = (file: string, name: string) => {
    const link = document.createElement('a')
    link.href = file
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link?.parentNode?.removeChild(link)
}

export const downLoadFile = async (
    uid: string,
    getBase64File: (uid: string) => Promise<IRequest<ResponseItem<UploadResult>>>,
) => {
    const { data, error } = await getBase64File(uid)
    if (data?.item) {
        downLoadFileBySrc(data.item.file, data.item.name)
    } else if (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось скачать файл',
        })
    }
}
