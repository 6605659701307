import axios, { AxiosInstance } from 'axios'

export const tokensRefresh = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken')
        const accessToken = localStorage.getItem('accessToken')

        if (!refreshToken || !accessToken) {
            throw new Error()
        }

        const rs = await axios.post(
            '/api/v1/users/refresh/',
            {},
            { headers: { jwt: accessToken, refresh: refreshToken } },
        )

        if (rs.data?.item) {
            const { jwt_token, refresh_token } = rs.data.item
            localStorage.setItem('accessToken', jwt_token)
            localStorage.setItem('refreshToken', refresh_token)

            return { accessToken, refreshToken, error: null }
        }

        return { accessToken: null, refreshToken: null, error: 'no-data' }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('refresh tokens error', error)
        return { accessToken: null, refreshToken: null, error }
    }
}

export const Agent = (baseURL?: string): AxiosInstance => {
    const headers = { 'Content-Type': 'application/json' }

    const _agent = axios.create({
        baseURL,
        headers,
        withCredentials: false,
    })

    _agent.interceptors.request.use((config) => {
        config.headers = {
            ...headers,
            ...config.headers,
        }

        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            config.headers.jwt = accessToken
        }

        return config
    })

    _agent.interceptors.response.use(
        (response) => {
            return response
        },
        async (error) => {
            const originalConfig = error.config
            if (
                !originalConfig.url.includes('auth') &&
                !originalConfig.url.includes('refresh') &&
                error.response
            ) {
                if (error.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true
                    const refreshToken = localStorage.getItem('refreshToken')
                    const accessToken = localStorage.getItem('accessToken')

                    if (!refreshToken || !accessToken) {
                        throw new Error()
                    }

                    const { error: _error } = await tokensRefresh()

                    if (error) {
                        localStorage.clear()
                        document.location.href = '/login'
                        return Promise.reject(_error)
                    }

                    return _agent(originalConfig)
                } else {
                    return Promise.reject(error)
                }
            } else {
                return Promise.reject(error)
            }
        },
    )

    return _agent
}
