import { RiskLevelProfile } from '@dltru/dfa-models'
import { Box, Button, Divider, Form, Modal, WrappedInput, WrappedSelect } from '@dltru/dfa-ui'
import { updateRiskLevel } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

const type = ['Низкий', 'Высокий']
const typeOptions = type.map((item) => ({ value: item, key: item, label: item }))

export const AddRiskModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [form] = Form.useForm()
    const reduxDispatch = useDispatch()
    const userUid = useSelector(clientsSelector.selectCurrentClientId)
    const profileType = useSelector(clientsSelector.selectProfilePath)

    const handleCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleOk = (values: RiskLevelProfile) => {
        if (!userUid || !profileType) {
            return
        }

        reduxDispatch(
            updateRiskLevel({
                user_uuid: userUid,
                type: profileType,
                data: {
                    risk_levels: [{ ...values, verification_datetime: dayjs().toISOString() }],
                },
            }),
        )
        handleCancel()
    }

    return (
        <Modal
            title="Присвоение уровня риска"
            visible={isModalVisible}
            destroyOnClose
            onCancel={handleCancel}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={() => handleCancel()}>
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={form.submit}>
                    Сохранить
                </Button>,
            ]}
        >
            <div>
                <Form form={form} layout="vertical" onFinish={handleOk}>
                    <WrappedSelect
                        className="formComponentItem"
                        label="Уровень риска"
                        name="risk_level"
                        options={typeOptions}
                        size="large"
                        required
                    />
                    <Divider />
                    <Box margin={[0, 0, 24, 0]}>
                        <strong>Обоснование</strong>
                    </Box>
                    <WrappedInput
                        className="formComponentItem"
                        label=""
                        placeholder="Ваш комментарий"
                        name="comment"
                        multiline
                        required
                    />
                </Form>
            </div>
        </Modal>
    )
}
