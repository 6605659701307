import { CertificateType, UserCertificateType } from '@dltru/dfa-models'
import { SignUtils } from '@dltru/dfa-sign'
import { Alert, UserCertificateItem } from '@dltru/dfa-ui'
import { FC, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUsersCertificates } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

interface IComponentProps {
    selectedCertificate?: CertificateType
    setSelectedCertificate: (value?: CertificateType) => void
}

export const UserCertificateList: FC<IComponentProps> = ({
    selectedCertificate,
    setSelectedCertificate,
}) => {
    const reduxDispatch = useDispatch()

    const [certificates, setCertificates] = useState<CertificateType[] | null>(null)
    const certificateFromStore = useSelector(authSelector.selectUserCertificates)
    const isCertificatesLoaded = useSelector(authSelector.selectUserCertificatesLoaded)

    const getCertificate = async () => {
        const allCertificates: UserCertificateType[] = await SignUtils.getAllCertificates()
        const userCertificates = certificateFromStore?.filter(({ sha1 }) => {
            const findedCertificate = allCertificates?.find(
                ({ thumbprint }) => thumbprint.toLowerCase() === sha1.toLowerCase(),
            )
            return findedCertificate?.checkPrivateKey
        })
        setCertificates(userCertificates ?? [])
    }

    useLayoutEffect(() => {
        reduxDispatch(getUsersCertificates())
    }, [])

    useLayoutEffect(() => {
        if (certificateFromStore?.length === 0 && isCertificatesLoaded) {
            setCertificates([])
        }

        if (certificateFromStore?.length && isCertificatesLoaded) {
            getCertificate()
        }
    }, [certificateFromStore, isCertificatesLoaded])

    if (certificates === null) {
        return <span>Поиск сертификатов...</span>
    }

    if (certificates?.length === 0) {
        return (
            <Alert showIcon description="Не найдено ни одного сертификата подписи" type="warning" />
        )
    }

    return (
        <>
            {certificates.map((item) => (
                <UserCertificateItem
                    key={item.uuid}
                    certificate={item}
                    isActive={item.uuid === selectedCertificate?.uuid}
                    onClick={() => setSelectedCertificate(item)}
                />
            ))}
        </>
    )
}
