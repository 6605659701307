import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const RightMini: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1307 8.49195L7.47062 12.172C7.19934 12.4447 6.75794 12.4447 6.48666 12.172C6.21746 11.9013 6.21746 11.464 6.48666 11.1933L9.66269 7.99996L6.48666 4.80659C6.21746 4.53592 6.21746 4.09863 6.48666 3.82796C6.75794 3.5552 7.19934 3.5552 7.47062 3.82796L11.1307 7.50797C11.4009 7.77969 11.4009 8.22023 11.1307 8.49195Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
