import { PaperClipOutlined } from '@ant-design/icons'
import { FC } from 'react'

import { Space } from '../../../Space'
import { DateTimeCell } from '../../../Table/components/Cell'
import { ColorModal, IColorModalProps } from '../../../components/Modal'
import {
    BodyTitle,
    GrayLabelDataField,
    ModalDivider,
    SemiboldDigitsData,
    SmallGreyData,
    TextParagraph,
} from '../components/body'
import { HeaderModalContainer, ModalHeaderLink, ModalTitle } from '../components/header'
import style from './style.m.less'

type WalletLimit = {
    id: number
    asset_id: number
    asset_title: string
    emitter_id: string
    amount_dfa: number
    is_lock: boolean
    created_at: number
    process_name: string
    executor: string
    description: string
    emitter_full_name: string
    attachment_id: string
}

interface IFactOfInjunctionModal extends Omit<IColorModalProps, 'header' | 'body' | 'headerColor'> {
    record: Partial<WalletLimit>
    onDownloadFile: (uuid: string) => void
}

export const FactOfInjunctionModal: FC<IFactOfInjunctionModal> = ({
    record,
    onDownloadFile,
    ...restProps
}) => {
    if (!record) {
        return null
    }

    const isLockModal = record.is_lock

    const Header = (
        <HeaderModalContainer>
            <ModalHeaderLink
                link={`/offers/${record.asset_id}`}
                linkText={record.asset_title || ''}
            />
            <ModalTitle title={record.asset_title || ''} />
            <ModalHeaderLink
                link={`/clients/${record.emitter_id}`}
                linkText={record.emitter_full_name || ''}
            />
        </HeaderModalContainer>
    )

    const Body = (
        <>
            <BodyTitle title={`Детали ${isLockModal ? '' : 'снятия '}блокировки`} />
            <div
                style={{
                    display: 'flex',
                    padding: '16px 0 0 16px',
                    flexDirection: 'column',
                }}
            >
                <GrayLabelDataField label="Количество ЦФА, ед.">
                    {record.amount_dfa && <SemiboldDigitsData number={+record.amount_dfa} />}
                </GrayLabelDataField>
                <ModalDivider />
                {record.created_at && (
                    <GrayLabelDataField
                        label={`Дата и время ${isLockModal ? '' : 'снятия '}блокировки`}
                    >
                        <SmallGreyData>{DateTimeCell(record.created_at)}</SmallGreyData>
                    </GrayLabelDataField>
                )}
                {record.attachment_id && (
                    <GrayLabelDataField
                        label={`Файл основания ${isLockModal ? '' : 'снятия '}блокировки`}
                    >
                        <span
                            className={style.documentLinkData}
                            onClick={() => {
                                if (record.attachment_id) {
                                    onDownloadFile(record.attachment_id)
                                }
                            }}
                        >
                            <Space size={6}>
                                <PaperClipOutlined />
                                Скачать
                            </Space>
                        </span>
                    </GrayLabelDataField>
                )}
                <ModalDivider />
                <GrayLabelDataField
                    label={`Основание ${isLockModal ? '' : 'снятия '}блокировки`}
                    flexDirection="column"
                >
                    <TextParagraph text={record.description || ''} />
                </GrayLabelDataField>
            </div>
        </>
    )

    return (
        <ColorModal
            {...restProps}
            header={Header}
            body={Body}
            width={540}
            headerColor={isLockModal ? 'red' : 'gray'}
        />
    )
}
