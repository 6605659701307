import {
    CertificateType,
    ICursorPaginationResponse,
    IRequest,
    TablePaginationParam,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

type GetCertificatesParamsType = TablePaginationParam & { user_uuid?: string }
export async function getCertificates(
    params: GetCertificatesParamsType,
): Promise<IRequest<ResponseItem<ICursorPaginationResponse<CertificateType>>>> {
    return requestDecorator.call(
        this,
        this.paths.CertificatesUserCertificates,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}
export async function getCertificateByUid(
    uuid: string,
): Promise<IRequest<ResponseItem<CertificateType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.CertificatesUserCertificateByUid, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
export async function removeCertificateByUid(uuid: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.CertificatesUserCertificateByUid, uuid),
        HttpMethod.DELETE,
        HttpCode.SUCCESS_200,
    )
}
