import { palette } from './palette'

export const config = {
    ...palette,

    // colors
    'primary-color': palette['primary-6'],
    'error-color': palette['red-5'],
    'info-color': palette['primary-5'],
    'processing-color': palette['primary-5'],

    // layout
    'body-background': palette['grey-1'],
    'layout-header-background': palette['primary-6'],
    'layout-body-background': palette['layout-bg'],
    'table-resize-handle': palette['primary-6'],
    'menu-item-boundary-margin': '0px',
    'menu-item-vertical-margin': '0px',
    get 'layout-header-height'(): string {
        return `${this.grid_cell_number * 7}px`
    },
    'layout-header-padding': '0',
    'layout-sider-background': palette['grey-1'],

    // components
    'border-radius-base': '4px',
    'btn-border-radius-base': '4px',
    'table-header-bg': palette['grey-1'],
    'border-radius-sm': '4px',
    'font-family': 'SF Pro Display',
    'text-color': palette['grey-9'],
    'text-color-inverse': palette['grey-1'],
    'form-item-margin-bottom': '0',
    get 'page-header-padding-vertical'(): string {
        return `${this.grid_cell_number * 3}px`
    },

    'round-button-bg-days': palette['primary-1'],

    grid_cell_number: 8,
    get grid_cell(): string {
        return `${this.grid_cell_number}px`
    },
}
