// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserFullNameCell_C3PDL {\n  display: flex;\n  align-items: center;\n}\n.UserFullNameCellBlock_oRTax {\n  margin-right: 10px;\n  width: auto !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientList/components/ISClientsTable/UserFullNameCell/userFullNameCell.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,kBAAA;EAEA,sBAAA;AADJ","sourcesContent":[".UserFullNameCell {\n    display: flex;\n    align-items: center;\n}\n\n.UserFullNameCellBlock {\n    margin-right: 10px;\n\n    width: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserFullNameCell": "UserFullNameCell_C3PDL",
	"UserFullNameCellBlock": "UserFullNameCellBlock_oRTax"
};
export default ___CSS_LOADER_EXPORT___;
