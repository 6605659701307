import {
    Button,
    CurrencyInput,
    Divider,
    Form,
    MoneyAccountBlock,
    RightMini,
    UploadFile,
    WrappedInput,
    WrappedSelect,
    moneyValidators,
} from '@dltru/dfa-ui'
import { FieldData } from 'rc-field-form/lib/interface'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form'

import style from './style.m.less'

export const enum DebitType {
    'bankAccount',
    'moneyAccount',
}

const defaultDebitType = DebitType.bankAccount

export interface IDebitFormValues {
    debitSum: string
    debitType: DebitType
    debitReason?: string
    debitReasonFile: UploadFile[]
}

const debitTypeOptions = [
    { label: 'На расчётный счёт', value: DebitType.bankAccount },
    { label: 'На другой САУ', value: DebitType.moneyAccount },
]

interface IComponentProps {
    sendDebit: (values: IDebitFormValues) => void
    setIsModalVisible: (value: boolean) => void
    changeBodyToBankAccount: () => void
    changeBodyToSelectUser: () => void
    saveDebitData: Dispatch<SetStateAction<Partial<IDebitFormValues>>>
    isFilledBankRequisites: boolean
    isFilledUserData: boolean
    initialValues?: Partial<IDebitFormValues>
}

export const DebitFormBody: FC<IComponentProps> = ({
    sendDebit,
    setIsModalVisible,
    changeBodyToBankAccount,
    changeBodyToSelectUser,
    isFilledBankRequisites,
    isFilledUserData,
    saveDebitData,
    initialValues = { debitType: defaultDebitType },
}) => {
    const [form] = Form.useForm()
    const account = useSelector(clientsSelector.selectBalanceInfo)
    const [isSumFilled, setIsSumFilled] = useState(Boolean(initialValues.debitSum))
    const [isFileFilled, setIsFileFilled] = useState(
        Boolean(initialValues?.debitReasonFile?.length),
    )
    const [currentDebitType, setCurrentDebitType] = useState(initialValues.debitType)

    const onAttachmentChange = (value?: UploadFile[]) => {
        saveDebitData((prevState) => ({ ...prevState, debitReasonFile: value }))
        setIsFileFilled(Boolean(value?.length))
    }
    const onFieldsChange = (changesField: FieldData[], allFields: FieldData[]) => {
        saveDebitData((prevState) => ({ ...prevState, ...form.getFieldsValue() }))
        setIsSumFilled(
            !allFields?.some(
                ({ errors, name, value }) =>
                    errors?.length || ((name as string)[0] === 'debitSum' && !value),
            ),
        )
        const debitTypeChange = changesField.find(({ name }) => (name as string)[0] === 'debitType')
        if (debitTypeChange) {
            setCurrentDebitType(debitTypeChange.value as DebitType)
        }
    }
    const btnIsDisabled =
        !isSumFilled ||
        !isFileFilled ||
        (currentDebitType === DebitType.bankAccount ? !isFilledBankRequisites : !isFilledUserData)

    const balance = account?.balance?.available
    return (
        <div>
            <div className={style.body}>
                <MoneyAccountBlock accountNumber={account?.number} balance={balance} />
                <Form
                    form={form}
                    layout="vertical"
                    style={{ marginBottom: 24, marginTop: 24 }}
                    onFieldsChange={onFieldsChange}
                    onFinish={sendDebit}
                    initialValues={initialValues}
                >
                    <CurrencyInput
                        label="Сумма списания, ₽"
                        name="debitSum"
                        required
                        size="large"
                        rules={[
                            {
                                validator:
                                    typeof balance === 'number'
                                        ? moneyValidators.maxSum(
                                              balance / 100,
                                              'Сумма списания превышает остаток средств на счете',
                                          )
                                        : undefined,
                            },
                        ]}
                    />

                    <WrappedSelect
                        label="Способ списания"
                        name="debitType"
                        options={debitTypeOptions}
                        size="large"
                    />

                    {currentDebitType === DebitType.bankAccount && (
                        <div>
                            <Button
                                type="link"
                                onClick={changeBodyToBankAccount}
                                className={style.smallLinkButton}
                            >
                                Указать реквизиты перевода <RightMini />
                            </Button>
                        </div>
                    )}
                    {currentDebitType === DebitType.moneyAccount && (
                        <div>
                            <Button
                                type="link"
                                onClick={changeBodyToSelectUser}
                                className={style.smallLinkButton}
                            >
                                Указать получателя средств <RightMini />
                            </Button>
                        </div>
                    )}
                    <Divider margin={[24, 0, 0, 0]} />
                    <h4>Назначение платежа</h4>
                    <UploadDocumentContainerWrapper
                        name="debitReasonFile"
                        title="Загрузите фотографии или сканы документов. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG. Общий вес не более 25 МБ"
                        onChange={onAttachmentChange}
                        required
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Укажите основания списания"
                            name="debitReason"
                            multiline
                            maxLength={200}
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
                {/*
                          <div className={style.warning}>
                            <div className={style.warningIcon}>
                                <ExclamationCircle />
                            </div>
                            <p className={style.warningText}>
                                Опционально. Текст о том, что списать средства со счета
                                заблокированного пользователя возможно не для всех случаев. Подсказка в
                                каких случаях это возможно.
                            </p>
                        </div>*/}
            </div>

            <div className={style.footer}>
                <Button
                    style={{ marginRight: 16, borderRadius: 12 }}
                    onClick={() => setIsModalVisible(false)}
                >
                    Отмена
                </Button>
                <Button
                    borderRadius={16}
                    type="primary"
                    disabled={btnIsDisabled}
                    onClick={form.submit}
                >
                    Списать денежные средства
                </Button>
            </div>
        </div>
    )
}
