import { IRequest, SignTransactionFullType, SignTransactionShortType } from '@dltru/dfa-models'

import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { requestDecorator } from '../index'

export async function putTransaction(
    data: SignTransactionShortType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.AdapterTransactionV2,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putTransferDfaOrderSign(
    data: SignTransactionShortType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrderSign,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}
