import { FC, useState } from 'react'

import { contactValidators, onlyDigits } from '../../../../utils'
import { FormItemProps } from '../../FormItem'
import { InputProps, WrappedInput } from '../../Input'
import { prefixSelectorFactory } from './helpers'

type PhoneInputProps = FormItemProps &
    Pick<
        InputProps,
        'disabled' | 'onChange' | 'addonBefore' | 'placeholder' | 'maxLength' | 'size'
    > & {
        removeChar?: string
    }

export const PhoneInput: FC<PhoneInputProps> = ({
    onChange,
    addonBefore,
    maxLength,
    validateTrigger,
    prefix,
    rules = [],
    ...rest
}) => {
    const [standartPrefix, setStandartPrefix] = useState('7')

    return (
        <WrappedInput
            {...rest}
            onChange={onChange}
            addonBefore={
                addonBefore ? addonBefore : prefixSelectorFactory(standartPrefix, setStandartPrefix)
            }
            prefix={prefix ? prefix : '+' + standartPrefix}
            rules={[
                ...rules,
                {
                    validateTrigger: validateTrigger || 'onChange',
                    validator: contactValidators.phone(11 - standartPrefix.length),
                },
            ]}
            maxLength={maxLength ? maxLength : 11 - standartPrefix.length}
            normalize={onlyDigits}
        />
    )
}
