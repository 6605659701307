import { getToken, parseToken } from '@dltru/dfa-ui'

export const getStatusesDfaByToken = () => {
    const token = getToken()
    const parsed = parseToken(token)
    const scope = parsed && parsed[1].scope?.split(' ')
    if (scope.includes('approval.operator')) {
        return 'teller_approval,preliminary_check'
    }
    if (scope.includes('approval.lawyer')) {
        return 'lawyer_approval'
    }
    if (scope.includes('approval.aml')) {
        return 'spec_approval'
    }
}
