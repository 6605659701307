import { Box, Card, CopyButton, PageHeader, Space, UserOutlined } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAuthProfile } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { CertificatesList } from './СertificatesBlock'

export const Profile: FC = () => {
    const profileUid = useSelector(authSelector.selectUserUid)
    const profile = useSelector(authSelector.selectProfile)
    const reduxDispatch = useDispatch()
    useEffect(() => {
        if (!profile && profileUid) {
            reduxDispatch(getAuthProfile())
        }
    }, [profile, profileUid])
    if (!profileUid) {
        return null
    }
    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={
                        <Space>
                            <UserOutlined />
                            <span>
                                {profile?.last_name} {profile?.first_name} {profile?.second_name}
                            </span>
                        </Space>
                    }
                    subTitle={
                        <>
                            ID {profileUid} <CopyButton text={profileUid} />
                        </>
                    }
                />
            </Box>
            <Card>
                <Box margin={[16, 8, 0, 8]}>
                    <CertificatesList />
                </Box>
            </Card>
        </>
    )
}
