import { IDfaOwnerBalance } from '@dltru/dfa-models'

import { Case, ClockIcon, LockIcon } from '../../../Icons'
import './style.less'

export const DetailsAmountCell = (_: string, record: IDfaOwnerBalance) => {
    const lockAmount = (
        (record.injunction_amount_dfa ?? 0) + (record.encumbrance_amount_dfa ?? 0)
    ).toLocaleString()
    return (
        <>
            <span className="amount-cell amount-cell--active">
                <Case />
                {record.active_amount_dfa.toLocaleString()}
            </span>
            {Boolean(record.blocked_amount_dfa) && (
                <span className="amount-cell amount-cell--passive">
                    <ClockIcon />
                    {record.blocked_amount_dfa.toLocaleString()}
                </span>
            )}
            {(Boolean(record.injunction_amount_dfa) || Boolean(record.encumbrance_amount_dfa)) && (
                <span className="amount-cell amount-cell--danger">
                    <LockIcon />
                    {lockAmount}
                </span>
            )}
        </>
    )
}
