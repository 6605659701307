import { FC } from 'react'

import WrappedDateItem from '../../DateItem'
import { WrappedInput } from '../../components/Form'

interface IComponentProps {
    isNotCurrentStep: boolean
    disableFields?: string[]
}

export const TaxRegistrationBlock: FC<IComponentProps> = ({
    isNotCurrentStep,
    disableFields = [],
}) => {
    return (
        <>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    name="ogrn_date"
                    label="Дата регистрации"
                    uneditable={isNotCurrentStep}
                    required
                    disableTime
                    onlyPast
                    disabled={disableFields.includes('ogrn_date')}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Наименование регистрирующего органа"
                    name="registration_authority"
                    uneditable={isNotCurrentStep}
                    required
                    maxLength={200}
                    disabled={disableFields.includes('registration_authority')}
                />
            </div>
        </>
    )
}
