export const validatorConstructor = (
    condition: (() => boolean) | boolean,
    errorMessage: string,
) => {
    return {
        validator: async () => {
            if (typeof condition === 'boolean' ? condition : condition()) {
                return Promise.reject(new Error(errorMessage))
            }
            Promise.resolve()
        },
    }
}
