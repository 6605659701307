/* eslint-disable no-console */
import { TransactionType } from '@dltru/dfa-models'
import { downLoadFileBySrc, openMessage, setSender } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import { paymentOrdersSlice } from '@store/paymentOrders/index'

import api from '@services/api'

function* getCurrentTransaction(
    action: ReturnType<typeof paymentOrdersSlice.actions.getCurrentTransaction>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getTransactionById, action.payload)

        if (error) {
            throw error
        }
        if (data?.item) {
            yield* put(paymentOrdersSlice.actions.setCurrentTransaction(data.item))
            yield* put(paymentOrdersSlice.actions.getPaymentOrdersByTransaction(data.item.uuid))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось открыть поручение пользователя',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* getPaymentOrdersByTransaction(
    action: ReturnType<typeof paymentOrdersSlice.actions.getPaymentOrdersByTransaction>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const result = yield* call(api.lib.getPaymentOrders, { transaction_uuid: action.payload })

        if (result.error) {
            throw result.error
        }
        yield* put(
            paymentOrdersSlice.actions.setPaymentOrdersByTransaction(result.data?.items ?? []),
        )
    } catch (error) {
        console.error(error)
        // TODO если платежки нет приходит 500, вероятно нужно полчать хотя бы 404
        /*openMessage({
        type: 'error',
        message: 'Не удалось получить список платежных поручений',
    })*/
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* createPaymentOrder(
    action: ReturnType<typeof paymentOrdersSlice.actions.createPaymentOrder>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const { error } = yield* call(api.lib.createPaymentOrder, action.payload)

        if (error) {
            throw error
        }

        yield* put(paymentOrdersSlice.actions.getCurrentTransaction(action.payload))
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось сформировать платежное поручение',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* getFilePaymentOrder(
    action: ReturnType<typeof paymentOrdersSlice.actions.getFilePaymentOrder>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const result = yield* call(api.lib.getFilePaymentOrder, action.payload)

        if (result.error) {
            throw result.error
        }
        if (result.data) {
            const data = result.data.item
            const file =
                action.payload.extension === 'xml'
                    ? window.URL.createObjectURL(new Blob([data.data], { type: 'text/plain' }))
                    : data.data
            downLoadFileBySrc(file, data.name)
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось скачать файл платежного поручения',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}
function* getPaymentOrders(action: ReturnType<typeof paymentOrdersSlice.actions.getPaymentOrders>) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const result = yield* call(api.lib.getPaymentOrders, action.payload)
        if (result.error) {
            throw result.error
        }
        if (result.data) {
            yield* put(paymentOrdersSlice.actions.setPaymentOrders(result.data))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось получить список платежных поручений',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* getTransactions({
    payload,
}: ReturnType<typeof paymentOrdersSlice.actions.getTransactions>) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const recipientAccountIds = []
        if (payload.account_id) {
            for (const uuid of payload.account_id) {
                const { data: account } = yield* call(api.lib.getMoneyAccounts, uuid)
                recipientAccountIds.push(account?.items[0]?.id)
            }
        }
        const { error, data } = yield* call(api.lib.getTransactions, {
            ...payload,
            account_id: recipientAccountIds?.length ? recipientAccountIds : undefined,
        })
        if (error || data?.error) {
            throw error || data?.err
        }
        if (data?.items) {
            data.items = data.items.map((v: TransactionType) => ({
                ...v,
                sender_uuid: setSender(v.sender_uuid),
            }))
        }
        yield* put(paymentOrdersSlice.actions.setTransactions(data ?? {}))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить список поручений пользователя',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* sendExecutedPaymentOrder(
    action: ReturnType<typeof paymentOrdersSlice.actions.sendExecutedPaymentOrder>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const { transaction_uuid } = action.payload
        const { error } = yield* call(api.lib.postExecutedPaymentOrder, action.payload)

        if (error) {
            throw error
        }

        if (transaction_uuid) {
            yield* put(paymentOrdersSlice.actions.getCurrentTransaction(transaction_uuid))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось исполнить платежное поручение',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* sendNotExecutedPaymentOrder(
    action: ReturnType<typeof paymentOrdersSlice.actions.sendNotExecutedPaymentOrder>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))
        const { transaction_uuid } = action.payload
        const { error } = yield* call(
            api.lib.moneyReservePaymentOrderCancelSauToBankAccount,
            action.payload,
        )

        if (error) {
            throw error
        }

        if (transaction_uuid) {
            yield* put(paymentOrdersSlice.actions.getCurrentTransaction(transaction_uuid))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить операцию',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

function* rejectPaymentOrder(
    action: ReturnType<typeof paymentOrdersSlice.actions.rejectPaymentOrder>,
) {
    try {
        yield* put(paymentOrdersSlice.actions.setIsLoading(true))

        const { error } = yield* call(api.lib.moneyReserveTransferCancelSauToBankAccount, {
            transaction_id: action.payload,
        })

        if (error) {
            throw error
        }

        if (action.payload) {
            yield* put(paymentOrdersSlice.actions.getCurrentTransaction(action.payload))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить операцию',
        })
        yield* put(paymentOrdersSlice.actions.setError(error as string))
    } finally {
        yield* put(paymentOrdersSlice.actions.setIsLoading(false))
    }
}

export function* paymentOrders(): Generator<StrictEffect> {
    yield* takeLatest(paymentOrdersSlice.actions.getCurrentTransaction.type, getCurrentTransaction)
    yield* takeLatest(paymentOrdersSlice.actions.getPaymentOrders.type, getPaymentOrders)
    yield* takeLatest(paymentOrdersSlice.actions.getTransactions.type, getTransactions)
    yield* takeLatest(
        paymentOrdersSlice.actions.getPaymentOrdersByTransaction.type,
        getPaymentOrdersByTransaction,
    )
    yield* takeLatest(paymentOrdersSlice.actions.createPaymentOrder.type, createPaymentOrder)
    yield* takeLatest(paymentOrdersSlice.actions.getFilePaymentOrder.type, getFilePaymentOrder)
    yield* takeLatest(
        paymentOrdersSlice.actions.sendExecutedPaymentOrder.type,
        sendExecutedPaymentOrder,
    )
    yield* takeLatest(
        paymentOrdersSlice.actions.sendNotExecutedPaymentOrder.type,
        sendNotExecutedPaymentOrder,
    )

    yield* takeLatest(paymentOrdersSlice.actions.rejectPaymentOrder.type, rejectPaymentOrder)
}
