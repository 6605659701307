import IAppState from '../states'

const selectCurrentTransaction = (state: IAppState) => state.paymentOrders.currentTransaction
const selectPaymentOrdersData = (state: IAppState) => state.paymentOrders.paymentOrderListData
const selectTransactionsData = (state: IAppState) => state.paymentOrders.transactionListData
const selectCurrentPaymentOrders = (state: IAppState) => state.paymentOrders.currentPaymentOrders
const selectIsLoading = (state: IAppState) => state.paymentOrders.isLoading
const selectTransactionsParams = (state: IAppState) => state.paymentOrders.transactionParams

export const paymentOrdersSelector = {
    selectCurrentTransaction,
    selectPaymentOrdersData,
    selectTransactionsData,
    selectCurrentPaymentOrders,
    selectIsLoading,
    selectTransactionsParams,
}
