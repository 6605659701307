import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { tariffRelatedAssetsSlice } from './index'
import IAppState from '@store/states'

function* getRelatedAssets({
    payload,
}: ReturnType<typeof tariffRelatedAssetsSlice.actions.getRelatedAssets>) {
    try {
        yield* put(tariffRelatedAssetsSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getAssetsByTariffUuid, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }

        if (data?.items) {
            yield* put(tariffRelatedAssetsSlice.actions.setItems(data))
        }
    } catch (error) {
        yield* put(tariffRelatedAssetsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении списка пользователей',
        })
    } finally {
        yield* put(tariffRelatedAssetsSlice.actions.setIsLoading(false))
    }
}

function* linkAssets({ payload }: ReturnType<typeof tariffRelatedAssetsSlice.actions.linkAssets>) {
    try {
        for (const asset of payload.assets) {
            if (!asset.id) {
                return
            }

            const { data, error } = yield* call(api.lib.tariffLinkAsset, {
                asset_id: asset.id,
                tariff_id: payload.tariff_id,
            })

            if (error || data?.error) {
                openMessage({
                    type: 'error',
                    message: `Возникли ошибки при добавлении выпуска ${asset.ticker_symbol}`,
                })
            }
        }

        const limit = yield* select((state: IAppState) => state.tariffRelatedUsers.params.limit)
        yield* put(
            tariffRelatedAssetsSlice.actions.getRelatedAssets({
                tariff_id: payload.tariff_id,
                params: { limit },
            }),
        )

        openMessage({
            type: 'success',
            message: `Выпуски успешно добавлены`,
        })
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли ошибки при добавлении выпуска' })
    }
}

function* unlinkAssets({
    payload,
}: ReturnType<typeof tariffRelatedAssetsSlice.actions.unlinkAssets>) {
    try {
        for (const asset of payload) {
            const { data, error } = yield* call(api.lib.tariffUnlinkAsset, { link_id: asset.id })
            if (error || data?.error) {
                openMessage({
                    type: 'error',
                    message: `Возникли ошибки при удалении выпуска ${asset.ticker_symbol}`,
                })
            }
        }

        const limit = yield* select((state: IAppState) => state.tariffRelatedAssets.params.limit)
        const tariff_id = yield* select(
            (state: IAppState) => state.tariffRelatedAssets.data?.items?.[0]?.tariff_id,
        )
        if (tariff_id) {
            yield* put(
                tariffRelatedAssetsSlice.actions.getRelatedAssets({
                    tariff_id,
                    params: { limit },
                }),
            )
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error)
        openMessage({ type: 'error', message: 'Возникли ошибки при удалении выпусков' })
    }
}

export function* watchTariffRelatedAssets(): Generator<StrictEffect> {
    yield* takeLatest(tariffRelatedAssetsSlice.actions.getRelatedAssets.type, getRelatedAssets)
    yield* takeLatest(tariffRelatedAssetsSlice.actions.linkAssets.type, linkAssets)
    yield* takeLatest(tariffRelatedAssetsSlice.actions.unlinkAssets.type, unlinkAssets)
}
