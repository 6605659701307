import { DocumentsEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Table } from '@dltru/dfa-ui'
import React, { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'
import { IClientListItem } from '@store/clients/types'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { gridConfig } from './gridConfig'

export const ClientsTable: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const navigate = useNavigate()
    const params = useSelector(clientsSelector.selectParams)
    const items = useSelector(clientsSelector.selectItems)
    const paging = useSelector(clientsSelector.selectPaging)
    const isLoading = useSelector(clientsSelector.selectIsLoading)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(clientsSlice.actions.getClients(params))
        return () => {
            reduxDispatch(clientsSlice.actions.clearData())
        }
    }, [])

    const onPerPageChange = (limit: number) => {
        reduxDispatch(clientsSlice.actions.getClients({ ...params, limit }))
    }

    const onNext = () => {
        reduxDispatch(clientsSlice.actions.getClients({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(clientsSlice.actions.getClients({ cursor: paging?.cursors?.prev }))
    }

    const onRow = (record: IClientListItem) => ({
        onClick: () => {
            navigate(`/clients/${record.user_uuid}`)
        },
    })
    const showDownloadButton = isAllow(['investor.read.all'])

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={items ?? []}
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            onRow={onRow}
            rowKey="user_uuid"
            clickableRow
            footerLeft={
                showDownloadButton && (
                    <DownloadBtn
                        documentType={DocumentsEnum.register_of_qualified_investors}
                        title="Реестр квалифицированных инвесторов"
                    />
                )
            }
        />
    )
}
