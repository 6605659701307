import { DfaStatusEnum, IDfaFront, PriceSource } from '@dltru/dfa-models'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { FC } from 'react'

import { ClockIcon } from '../../../Icons'
import { Progress } from '../../../Progress'
import { VIEW_DATE_FORMAT } from '../../../constants'
import style from './style.m.less'

const cn = classNames.bind(style)

interface IComponentProps {
    dfa: IDfaFront
}

const statusWithPercent = [
    DfaStatusEnum.collection_started,
    DfaStatusEnum.emitment_failure,
    DfaStatusEnum.issue_failure,
]
export const ProgressDfa: FC<IComponentProps> = ({ dfa }) => {
    const showProgress = statusWithPercent.includes(dfa.status)
    if (!showProgress) {
        return null
    }
    const isDynamicPrice = dfa.price_source_type === PriceSource.dynamic
    const dangerPercent =
        dfa.status === DfaStatusEnum.emitment_failure || dfa.status === DfaStatusEnum.issue_failure
    const datePercentClass = cn(style.edcDateWrapper, {
        [style.edcDateWrapperDanger]: dangerPercent,
    })
    const counterDone = isDynamicPrice
        ? ((dfa?.available?.original_price ?? 0) - (dfa.available?.rest_price ?? 0)) / 100
        : (dfa?.available?.original_amount_dfa ?? 0) - (dfa.available?.rest_amount_dfa ?? 0)

    const totalDone = isDynamicPrice
        ? (dfa?.available?.original_price ?? 0) / 100
        : dfa?.available?.original_amount_dfa ?? 0
    return (
        <div className={cn(style.edcHeaderRight, style.edcHeaderRightRow)}>
            <div className={style.edcCounter}>
                <span className={style.edcCounterDone}>
                    {counterDone}
                    {isDynamicPrice && <span className={style.currency}>₽</span>}
                </span>
                <span className={style.edcCounterTotal}>
                    из {totalDone}
                    {isDynamicPrice && <span className={style.currency}>₽</span>}
                </span>
            </div>
            <Progress
                percent={(counterDone / totalDone) * 100}
                size="small"
                showInfo={false}
                strokeColor={dangerPercent ? '#F5222D' : '#52C41A'}
            />
            <div className={datePercentClass}>
                <span>{dayjs(dfa.issue_end_date).format(VIEW_DATE_FORMAT)}</span>
                <ClockIcon />
            </div>
        </div>
    )
}
