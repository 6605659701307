import { createSelector } from '@reduxjs/toolkit'

import IAppState from '@store/states'

const selectBlocking = (state: IAppState) => state.blocking

const selectDataForModal = createSelector(selectBlocking, (state) => {
    return {
        userUuid: state.userUuid,
        assetId: state.assetId,
        modalTitle: state.modalTitle,
        injunctionDFAs: state.injunctionDFAs,
        activeDFAs: state.activeDFAs,
    }
})

export const blockingSelector = {
    selectDataForModal,
}
