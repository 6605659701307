import { Button, EditPenIcon } from '@dltru/dfa-ui'
import { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { putStatementRequestQualify, qualifyStatementRequestQualify } from '@store/qualifyStatement'

import { qualFormToPutRequest, qualFormToRequest } from '@pages/QualificationStatement/utils'

import { IActionProps } from '../../../type'
import { NeedChangeModal } from '../Modals/NeedChangeModal'

export const NeedChangeAction: FC<IActionProps> = memo(({ data }) => {
    const reduxDispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const onOkHandler = (value: string) => {
        if (data) {
            const status = 'under_revision'
            const formValue = qualFormToPutRequest({ ...data, status, comment: value })
            if (formValue) {
                reduxDispatch(putStatementRequestQualify(formValue))
            }
        }
        setIsModalVisible(false)
    }

    return (
        <>
            <Button
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
                icon={<EditPenIcon />}
            >
                На доработку
            </Button>
            <NeedChangeModal
                onOk={onOkHandler}
                onCancel={() => {
                    setIsModalVisible(false)
                }}
                isModalVisible={isModalVisible}
            />
        </>
    )
})
