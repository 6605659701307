import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Edit: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.5505 4.50006L6.09901 8.95153C6.02162 9.02891 5.95747 9.11847 5.9091 9.21664L3.92405 13.2452C3.83961 13.4165 4.0205 13.5974 4.19185 13.513L8.22039 11.5279C8.31855 11.4796 8.40811 11.4154 8.48549 11.338L13.0734 6.75006M10.5505 4.50006L12.4345 2.61604C12.825 2.22552 13.4582 2.22552 13.8487 2.61604L14.821 3.58831C15.2115 3.97884 15.2115 4.612 14.821 5.00253L13.0734 6.75006M10.5505 4.50006L13.0734 6.75006"
                    stroke="currentColor"
                />
                <path
                    d="M2.25 16.875L15.75 16.875"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
