import {
    CreateEncumbranceType,
    EncumbranceFullType,
    EncumbranceShortType,
    ICursorPaginationResponse,
    IDfaOwnerBalance,
    IRequest,
    RemoveEncumbranceType,
    ResponseItem,
    SignTransactionFullType,
    TablePaginationParam,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItems } from '../../types/types'
import { path } from '../../utils'

export async function getWalletDfaBalances(
    userId: string,
): Promise<IRequest<ResponseItems<IDfaOwnerBalance>>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletDfaBalances, userId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getWalletDfaByUserId(
    userId: string,
): Promise<IRequest<ResponseItems<IDfaOwnerBalance>>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletDfaByUserId, userId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getWalletEncumbranceByUser(
    userUid: string,
    params: TablePaginationParam,
): Promise<IRequest<ICursorPaginationResponse<EncumbranceShortType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletEncumbranceGetByUser, userUid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getWalletEncumbranceById(
    encumbranceUid: string,
): Promise<IRequest<ResponseItem<EncumbranceFullType>>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletEncumbranceGetById, encumbranceUid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function setWalletEncumbranceByUserV2(
    data: CreateEncumbranceType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.WalletEncumbranceSetByUserV2,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function removeWalletEncumbranceByUserV2(
    data: RemoveEncumbranceType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.WalletEncumbranceRemoveByUserV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function setWalletEncumbranceByOperatorV2(
    data: CreateEncumbranceType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.WalletEncumbranceSetByOperatorV2,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function removeWalletEncumbranceByOperatorV2(
    data: RemoveEncumbranceType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.WalletEncumbranceRemoveByOperatorV2,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getWalletDfaOwnersCountByDfa(
    asset_id: string,
): Promise<IRequest<ResponseItem<{ count: number }>>> {
    return requestDecorator.call(
        this,
        path(this.paths.WalletDfaOwnersCount, asset_id),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
