import { RbacContext } from '@dltru/dfa-rbac'
import { Button, Card, CardContent, CardField, CardHeader, Space } from '@dltru/dfa-ui'
import { useContext, useState } from 'react'

import { ChangeLoginPasswordModal } from './ChangeLoginPasswordModal'
import { ChangePasswordModal } from './ChangePasswordModal'
import { ChangePhoneModal } from './ChangePhoneModal'
import { EmailBlock } from './EmailBlock'
import styles from './style.m.less'

type AuthDataType = { email: string; phone: string; login: string }
interface IComponentProps<T extends AuthDataType> {
    authData?: T
    userUuid: string
    isEmployee?: boolean
    isActive?: boolean
}

export const AuthInfo = <T extends AuthDataType>({
    authData,
    userUuid,
    isEmployee,
    isActive,
}: IComponentProps<T>) => {
    const { isAllow } = useContext(RbacContext)
    const [loginPasswordModalVisible, setLoginPasswordModalVisible] = useState(false)
    const [phoneModalVisible, setPhoneModalVisible] = useState(false)
    const [passwordModalVisible, setPasswordModalVisible] = useState(false)

    const isActionAllow =
        isActive && (isEmployee ? isAllow(['employee.write.all']) : isAllow(['user.write.all']))

    const LoginComponent = (
        <Space>
            <span className={styles.bold}>{authData?.login}</span>
            {isActionAllow && (
                <Button type="link" onClick={() => setLoginPasswordModalVisible(true)}>
                    Сбросить логин и пароль
                </Button>
            )}
        </Space>
    )
    const PhoneComponent = (
        <Space>
            <span className={styles.bold}>{authData?.phone}</span>
            {isActionAllow && (
                <Button type="link" onClick={() => setPhoneModalVisible(true)}>
                    Сбросить номер телефона
                </Button>
            )}
        </Space>
    )
    const PasswordComponent = (
        <Space>
            <span className={styles.password}>
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </span>
            {isActionAllow && (
                <Button type="link" onClick={() => setPasswordModalVisible(true)}>
                    Сбросить пароль
                </Button>
            )}
        </Space>
    )

    return (
        <>
            <Card className="boxContent">
                <CardHeader title="Данные авторизации" />
                <CardContent>
                    <CardField
                        title="Логин"
                        value={isEmployee ? <strong>{authData?.login}</strong> : LoginComponent}
                        padding={16}
                    />
                    <CardField title="Телефон" value={PhoneComponent} padding={16} />
                    {isEmployee ? (
                        <CardField
                            title="Корпоративная почта"
                            value={<strong>{authData?.email}</strong>}
                            padding={16}
                        />
                    ) : (
                        <EmailBlock
                            email={authData?.email}
                            user_uuid={userUuid}
                            isEditAllow={isActionAllow}
                        />
                    )}

                    <CardField title="Пароль" value={PasswordComponent} padding={16} />
                </CardContent>
            </Card>
            <ChangeLoginPasswordModal
                visible={loginPasswordModalVisible}
                onCancel={() => setLoginPasswordModalVisible(false)}
                currentUserUuid={userUuid}
            />
            <ChangePhoneModal
                visible={phoneModalVisible}
                onCancel={() => setPhoneModalVisible(false)}
                currentUserUuid={userUuid}
            />
            <ChangePasswordModal
                visible={passwordModalVisible}
                onCancel={() => setPasswordModalVisible(false)}
                currentUserUuid={userUuid}
            />
        </>
    )
}
