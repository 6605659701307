import {
    AddressForm,
    CardDivider,
    Form,
    FormListInput,
    FormSpace,
    IRoleFormStepProps,
    KppInput,
    OgrnInput,
    OkpoInput,
    Step,
    StepperContext,
    TaxRegistrationBlock,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext, useEffect } from 'react'

import { InnInput } from '@components/Form'
import { UploadInputForm } from '@components/Form/components/UploadInputForm'

const STEP_INDEX = 1

export const Step2: FC<IRoleFormStepProps> = ({ isEditAllow, disableFields = [] }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    const okvedListName = 'okveds'
    useEffect(() => {
        if (!values?.[okvedListName]?.length) {
            form.setFieldsValue({ [okvedListName]: [{}] })
        }
    }, [])

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Реквизиты организации"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FormSpace uneditable={isEdit}>
                <div className="formRow">
                    <OgrnInput
                        placeholder=""
                        uneditable={isEdit}
                        required
                        isUl
                        disabled={disableFields.includes('ogrn')}
                    />
                </div>
                <div className="formRow" style={{ marginLeft: 240 }}>
                    <UploadInputForm
                        name="ogrn_file_uuid"
                        uneditable={isEdit}
                        required
                        disabled={disableFields.includes('ogrn_file_uuid')}
                        title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                        maxSizeMb={5}
                        accept=".pdf,.png,.jpeg, .jpg, .tiff"
                    />
                </div>
                <div className="formRow">
                    <InnInput
                        placeholder=""
                        uneditable={isEdit}
                        required
                        isUl
                        disabled={disableFields.includes('inn')}
                    />
                </div>
                <div className="formRow" style={{ marginLeft: 240 }}>
                    <UploadInputForm
                        name="inn_file_uuid"
                        title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                        maxSizeMb={5}
                        accept=".pdf,.png,.jpeg, .jpg, .tiff"
                        uneditable={isEdit}
                        required
                        disabled={disableFields.includes('inn_file_uuid')}
                    />
                </div>
                <div className="formRow">
                    <KppInput placeholder="" uneditable={isEdit} required />
                </div>
                <div className="formRow">
                    <OkpoInput placeholder="" uneditable={isEdit} required />
                </div>
                <FormListInput
                    listName={okvedListName}
                    uneditable={isEdit}
                    label="ОКВЭД"
                    name="okved"
                    className="formComponentItem formComponentItem--lg"
                    required
                />
                <CardDivider />
                <TaxRegistrationBlock isNotCurrentStep={isEdit} disableFields={disableFields} />
                <CardDivider />
                <AddressForm
                    uneditable={isEdit}
                    group="legal_address"
                    addressTitle="Место государственной регистрации (местонахождение)"
                    isLegalAddress
                    noBottomDivider
                />
            </FormSpace>
        </Step>
    )
}
