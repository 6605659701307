import { ClientBlockEventType } from '@dltru/dfa-models'

export const eventTypeMapper = (type: ClientBlockEventType) => {
    switch (type) {
        case 'full_block':
            return 'Полное блокирование'
        case 'partly_block':
            return 'Частичное блокирование'
        case 'full_unblock':
            return 'Снятие полного блокирования'
        case 'partly_unblock':
            return 'Снятие частичного блокирования'
        default:
            return '---'
    }
}
