// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".date_o0sXY {\n  font-family: 'SF Pro Display';\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: #303440;\n  margin: 0;\n}\n.date_o0sXY span {\n  opacity: 0.5;\n}\n.dateWhite_IvTie {\n  color: #ffffff;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/BodyDate/style.m.less"],"names":[],"mappings":"AAGA;EACI,6BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AAFJ;AAJA;EASQ,YAAA;AAFR;AAKI;EACI,cAAA;AAHR","sourcesContent":["@import '/src/assets/style/palette';\n\n// DATE\n.date {\n    font-family: 'SF Pro Display';\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n    color: @gray-10;\n    margin: 0;\n\n    span {\n        opacity: .5;\n    }\n    \n    &White {\n        color: @gray-1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "date_o0sXY",
	"dateWhite": "dateWhite_IvTie"
};
export default ___CSS_LOADER_EXPORT___;
