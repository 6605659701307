import { errorTranslates, openMessage, openNotification } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import api from '@services/api'

import { nominalAccountsSlice } from './index'

function* getItems({ payload }: ReturnType<typeof nominalAccountsSlice.actions.getItems>) {
    try {
        yield* put(nominalAccountsSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getNominalAccounts, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.items) {
            yield* put(nominalAccountsSlice.actions.setItems(data))
        }
    } catch (error) {
        yield* put(nominalAccountsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении списка банковских счетов',
        })
    } finally {
        yield* put(nominalAccountsSlice.actions.setIsLoading(false))
    }
}

function* getItem({ payload }: ReturnType<typeof nominalAccountsSlice.actions.getItem>) {
    try {
        yield* put(nominalAccountsSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getNominalAccountById, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.account_details) {
            yield* put(
                nominalAccountsSlice.actions.setItem({ ...data.account_details, id: data.id }),
            )
        }
        //TODO когда появится ручка запрашивать список связанных САУ
    } catch (error) {
        yield* put(nominalAccountsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении банковского счетоа',
        })
    } finally {
        yield* put(nominalAccountsSlice.actions.setIsLoading(false))
    }
}

function* postItem() {
    try {
        const dataForSend = yield* select(nominalAccountsSelector.selectCurrentItem)
        if (!dataForSend) {
            return
        }
        yield* put(nominalAccountsSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.postNominalAccount, {
            ...dataForSend,
            bank_name: dataForSend.bank,
            bank: undefined,
        })
        if (error || data?.error) {
            throw error ?? data?.error
        }
        openNotification({
            type: 'success',
            message: 'Счёт создан',
            description: 'Банковский счёт успешно создан',
        })
        if (data?.account_details) {
            yield* put(
                nominalAccountsSlice.actions.setItem({ ...data.account_details, id: data.id }),
            )
        }
    } catch (error) {
        yield* put(nominalAccountsSlice.actions.setError(error as string))
        const errorTranslate = error?.error?.code ? errorTranslates[error.error.code] : undefined
        openNotification({
            type: 'error',
            message: 'Счёт не может быть создан',
            description: errorTranslate ? errorTranslate : 'Возникли ошибки при создании счета',
        })
    } finally {
        yield* put(nominalAccountsSlice.actions.setIsLoading(false))
    }
}

function* getRelatedAccountsByNumber({
    payload,
}: ReturnType<typeof nominalAccountsSlice.actions.getRelatedAccountsByNumber>) {
    try {
        yield* put(nominalAccountsSlice.actions.setIsLoading(true))
        const { data, error } = yield* call(api.lib.getRelatedAccountsByNominalAccount, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(nominalAccountsSlice.actions.setRelatedAccounts(data?.items ?? []))
    } catch (error) {
        yield* put(nominalAccountsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении списка связанных САУ',
        })
    } finally {
        yield* put(nominalAccountsSlice.actions.setIsLoading(false))
    }
}

export function* watchNominalAccountsSlice(): Generator<StrictEffect> {
    yield* takeLatest(nominalAccountsSlice.actions.getItems.type, getItems)
    yield* takeLatest(nominalAccountsSlice.actions.getItem.type, getItem)
    yield* takeLatest(nominalAccountsSlice.actions.postItem.type, postItem)
    yield* takeLatest(
        nominalAccountsSlice.actions.getRelatedAccountsByNumber.type,
        getRelatedAccountsByNumber,
    )
}
