import { TransactionStatuses } from '@dltru/dfa-models'
import { FC } from 'react'

import { CardRecievGrey, CardSendGreen, ClockIcon, StatusNegative } from '../../../Icons'

const IconsByType = {
    debit: CardRecievGrey,
    credit: CardSendGreen,
    transfer: CardRecievGrey,
    payment: CardRecievGrey,
} as { [key: string]: FC }

export const getIconByType = (type: string): FC => {
    return IconsByType[type] || null
}

export const transactionDirection = (type: string): boolean => {
    const green = ['credit']
    return green.includes(type)
}

const StatusIconsByStatus = {
    [TransactionStatuses.canceled]: StatusNegative,
    [TransactionStatuses.payment_awaiting]: ClockIcon,
    [TransactionStatuses.pending]: ClockIcon,
    [TransactionStatuses.resolving]: ClockIcon,
} as { [key: string]: FC }

export const getStatusIcon = (status: TransactionStatuses): FC<{ className: string }> => {
    return StatusIconsByStatus[status] || null
}
