// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".warning_GnroH {\n  color: #fa8c16;\n}\n.error_WAKFa {\n  color: #ff4d4f;\n}\n.success_Nv_ak {\n  color: #73d13d;\n}\n.smallIcon_ACa_P .ant-alert-icon {\n  margin-right: 0;\n}\n.smallIcon_ACa_P .ant-alert-icon svg {\n  width: 16px;\n  height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/RegistrationCard/components/ServiceHistory/style.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;AADJ;AAGA;EACI,cAAA;AADJ;AAGA;EACI,cAAA;AADJ;AAGA;EAGY,eAAA;AAHZ;AAAA;EAKgB,WAAA;EACA,YAAA;AAFhB","sourcesContent":["@import 'style/palette';\n\n.warning {\n    color: @orange-6;\n}\n.error {\n    color: @red-5;\n}\n.success {\n    color: @green-5;\n}\n.smallIcon {\n    :global {\n        .ant-alert-icon {\n            margin-right: 0;\n            svg {\n                width: 16px;\n                height: 16px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "warning_GnroH",
	"error": "error_WAKFa",
	"success": "success_Nv_ak",
	"smallIcon": "smallIcon_ACa_P"
};
export default ___CSS_LOADER_EXPORT___;
