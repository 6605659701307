import { EmployeeStatus, IEmployee, UserRoles, userRolesTranslate } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell, Tag } from '@dltru/dfa-ui'
import { getEmployeeStatusTagProps } from '@resources/employeeState'

export const gridConfig: ColumnsType<IEmployee> = [
    {
        title: 'Учетная запись создана',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: DateTimeCell,
    },
    {
        title: 'ФИО сотрудника',
        dataIndex: 'user_uuid',
        key: 'user_uuid',
        sorter: true,
        render: (value: string, record: IEmployee) =>
            LinkCell(`${record.last_name} ${record.first_name} ${record.second_name}`, value),
    },
    {
        title: 'Корпоративная почта',
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
    },
    {
        title: 'Роль',
        dataIndex: 'role',
        key: 'role',
        sorter: true,
        render: (value: UserRoles) => userRolesTranslate[value],
    },
    {
        title: 'Учетную запись создал',
        dataIndex: 'created_by',
        key: 'created_by',
        sorter: true,
        render: (value: string, record: IEmployee) =>
            LinkCell(
                `${record.created_by_full_name || value} ${
                    record.created_by_role ? `(${userRolesTranslate[record.created_by_role]})` : ''
                }`,
                value,
            ),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (value: EmployeeStatus) => {
            const tagProps = getEmployeeStatusTagProps(value)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
