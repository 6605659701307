import { BalanceGroup, Box, TransactionDetailsModal } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'
import { TransactionRow } from '@store/clients/types'

import { BalanceHeader } from './components/BalanceHeader/BalanceHeader'
import { BalanceListHeader } from './components/BalanceList/BalanceListHeader'

export const Balance: FC = () => {
    const reduxDispatch = useDispatch()
    const currentTransactions = useSelector(clientsSelector.selectTransactions)
    const account = useSelector(clientsSelector.selectBalanceInfo)
    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const [selectedRow, setSelectedRow] = useState<TransactionRow>()

    useEffect(() => {
        if (account?.id) {
            reduxDispatch(
                clientsSlice.actions.getTransactions({
                    account_id: account.id,
                }),
            )
        } else if (currentClientId) {
            reduxDispatch(clientsSlice.actions.getMoneyAccount(currentClientId))
        }
    }, [account?.id])

    return (
        <>
            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <BalanceHeader />
                <Box padding={[24, 32]} className="tablebox-gray tablebox--inner">
                    <BalanceListHeader />
                    {currentTransactions?.map((groupData) => (
                        <BalanceGroup
                            key={groupData.summary.dateStr}
                            {...groupData}
                            onSelect={setSelectedRow}
                        />
                    ))}
                </Box>
            </Box>
            <TransactionDetailsModal
                isModal={Boolean(selectedRow)}
                onClose={() => setSelectedRow(undefined)}
                data={selectedRow}
                wallet={account?.number?.toString() ?? ''}
            />
        </>
    )
}
