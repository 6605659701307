export enum ServiceCode {
    RG001 = 'RG001',
    RG101 = 'RG101',
    RG002 = 'RG002',
    RG003 = 'RG003',
    RG004 = 'RG004',
    WT010 = 'WT010',
    WT011 = 'WT011',
    WT009 = 'WT009',
    WT008 = 'WT008',
    WT007 = 'WT007',
    WT006 = 'WT006',
    DC007 = 'DC007',
    DL001 = 'DL001',
    DL003 = 'DL003',
    DL002 = 'DL002',
    WT002 = 'WT002',
    MT002 = 'MT002',
    DC001 = 'DC001',
    WT001 = 'WT001',
    DC002 = 'DC002',
    DC003 = 'DC003',
    DC004 = 'DC004',
    DC005 = 'DC005',
    DC006 = 'DC006',
    MT001 = 'MT001',
}

export const serviceCodeTranslate = {
    [ServiceCode.RG001]: 'Первичная регистрация',
    [ServiceCode.RG101]: 'Первичная регистрация',
    [ServiceCode.RG002]: 'Установление статуса квалифицированного инвестора',
    [ServiceCode.RG003]: 'Закрытие счёта для физических лиц',
    [ServiceCode.RG004]: 'Закрытие счёта для юридических лиц',
    [ServiceCode.WT010]: 'Допуск ЦФА к выпуску',
    [ServiceCode.WT011]: 'Допуск ЦФА к выпуску',
    [ServiceCode.WT009]: 'Выпуск ЦФА со сроком обращения > 30 дней',
    [ServiceCode.WT008]: 'Выпуск ЦФА со сроком обращения <= 30 дней',
    [ServiceCode.WT007]: 'Ежегодная оплата за поддержание ЦФА',
    [ServiceCode.WT006]: 'Погашение ЦФА',
    [ServiceCode.DC007]: 'Консалтинговые услуги по заполнению Решения о Выпуске ЦФА',
    [ServiceCode.DL001]: 'Выставление Заявки на покупку ЦФА инициатором',
    [ServiceCode.DL003]: 'Покупка ЦФА',
    [ServiceCode.DL002]: 'Выставление Заявки на продажу ЦФА',
    [ServiceCode.WT002]: 'Продажа ЦФА',
    [ServiceCode.MT002]: 'Проведение распоряжения на перечисление д. с.',
    [ServiceCode.DC001]:
        'Подготовка копий документов - оснований для блокировок или обременений ЦФА',
    [ServiceCode.WT001]: 'Проведение снятия или наложения обременения на ЦФА',
    [ServiceCode.DC002]:
        'Предоставление квалифицированному инвестору выписки из Реестра в электронном виде, содержащую информацию о данном квалифицированном инвесторе',
    [ServiceCode.DC003]:
        'Организация нотариального заверения копии документа на бумажном носителе с информацией из реестра',
    [ServiceCode.DC004]: 'Внесение в реестр записи о переходе прав на ЦФА в порядке наследования',
    [ServiceCode.DC005]: 'Предоставление списка владельцев ЦФА',
    [ServiceCode.DC006]: 'Предоставление справки о наличии ЦФА',
    [ServiceCode.MT001]: 'Пополнение Счета аналитического учета',
}
