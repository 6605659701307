import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CardAdd: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.66667 7.08331H11.25"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 13.3333H6.66667"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.16667 13.3333H12.5"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 10L10 10"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3333 10.025V13.425C18.3333 16.35 17.5917 17.0834 14.6333 17.0834H5.36667C2.40833 17.0834 1.66667 16.35 1.66667 13.425V6.57502C1.66667 3.65002 2.40833 2.91669 5.36667 2.91669H11.25"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M13.75 5.20831H18.3333" stroke="currentColor" strokeLinecap="round" />
                <path d="M16.0417 7.50002V2.91669" stroke="currentColor" strokeLinecap="round" />
            </svg>
        )}
        {...props}
    />
)
