import { EmployeeStatus } from '@dltru/dfa-models'
import { Alert, Box, Button, Divider, Form, Modal, UploadFile, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { employeeSlice } from '@store/employee'
import { employeeSelector } from '@store/employee/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form'

import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

type FormDatatype = {
    block_link_uuid: UploadFile[]
    reason: string
}
export const BlockEmployeeModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [isReason, setIsReason] = useState(false)
    const data = useSelector(employeeSelector.selectItem)
    const isBlockEmployee = data.status === EmployeeStatus.blocked
    const reduxDispatch = useDispatch()
    const { id } = useParams()
    const [form] = Form.useForm<FormDatatype>()
    const handleCancel = () => {
        setIsModalVisible(false)
        setIsReason(false)
    }
    const handleOk = () => {
        if (id) {
            const values = form.getFieldsValue()
            const data = {
                block_link_uuid: values.block_link_uuid?.[0].uid,
                reason: values.reason,
            }
            if (isBlockEmployee) {
                reduxDispatch(employeeSlice.actions.unBlockItem({ user_uuid: id, data }))
            } else {
                reduxDispatch(employeeSlice.actions.blockItem({ user_uuid: id, data }))
            }
        }
        setIsModalVisible(false)
        form.resetFields()
    }

    const onValuesChange = (changesValues: Partial<FormDatatype>) => {
        if ('reason' in changesValues) {
            setIsReason(Boolean(changesValues.reason))
        }
    }

    return (
        <Modal
            visible={isModalVisible}
            width={522}
            onCancel={handleCancel}
            title={isBlockEmployee ? 'Снятие блокировки' : 'Блокировка сотрудника'}
            wrapClassName={style.modal}
            destroyOnClose
        >
            <Divider margin={[0, 0, 24, 0]} />
            <Form form={form} layout="vertical" onFinish={handleOk} onValuesChange={onValuesChange}>
                <Box margin={[0, 0, 24, 0]}>
                    <h4 className={style.headerBold}>
                        {isBlockEmployee ? 'Основание для разблокировки' : 'Основание блокировки'}
                    </h4>
                </Box>
                <UploadDocumentContainerWrapper
                    name="block_link_uuid"
                    title="Загрузите фотографии или сканы документов"
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="reason"
                        multiline
                        required
                    />
                </UploadDocumentContainerWrapper>
            </Form>
            <Box margin={[24, 0, 0, 0]}>
                <Alert
                    showIcon
                    type="warning"
                    description={
                        isBlockEmployee
                            ? 'Опционально. Текст о том, что является основанием для снятия блокировки.'
                            : 'Опционально. Текст о том, что является основанием для блокировки клиента. Что за безосновательную блокировку преднаписана ответственность.'
                    }
                />
            </Box>
            <div className={style.footer}>
                <Button borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>
                <Button borderRadius={12} type="primary" onClick={form.submit} disabled={!isReason}>
                    {isBlockEmployee ? 'Разблокировать' : 'Заблокировать'}
                </Button>
            </div>
        </Modal>
    )
}
