import {
    ActivityDetector,
    Layout as AntdLayout,
    ContentFooter,
    DEFAULT_ACTIVITY_EVENTS,
} from '@dltru/dfa-ui'
import { isAuthenticated } from '@utils/auth'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { logout } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { ContentHeader } from '../Header'
import { Sidebar } from '../Sidebar'
import { ContentBox } from './ContentBox'

export const Layout: FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const isHasToken = isAuthenticated()
    const sessionTimeout = useSelector(authSelector.selectSessionTimeout)

    if (!isHasToken) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return (
        <AntdLayout>
            <Sidebar />
            <AntdLayout.Content>
                <ContentHeader />
                <ContentBox>
                    <Outlet />
                </ContentBox>
                <ContentFooter />
            </AntdLayout.Content>
            <ActivityDetector
                activityEvents={DEFAULT_ACTIVITY_EVENTS}
                timeToLogout={sessionTimeout}
                logout={() => {
                    dispatch(logout())
                }}
            />
        </AntdLayout>
    )
}
