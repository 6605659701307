import { FC, useContext, useEffect } from 'react'

import { Button } from '../../../button'
import { Form } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import FiltersForm from './FiltersForm'
import style from './style.m.less'

const FiltersPopup: FC = () => {
    const { onReset, totalActiveFilters, onSetForm } = useContext(FiltersContext)
    const [form] = Form.useForm()

    useEffect(() => {
        if (form) {
            onSetForm(form)
        }
        return () => {
            form?.resetFields()
            onSetForm()
        }
    }, [form])

    return (
        <div className={style.filterPopup}>
            <div className={style.filterPopupForm}>
                <FiltersForm />
            </div>
            <div className={style.filterPopupFooter}>
                <Button
                    disabled={!totalActiveFilters}
                    className={style.resetButton}
                    onClick={onReset}
                >
                    Сбросить параметры
                </Button>
                <Button
                    type="primary"
                    onClick={form?.submit}
                    borderRadius={12}
                    style={{ width: 160, justifyContent: 'center' }}
                >
                    Показать
                </Button>
            </div>
        </div>
    )
}

export default FiltersPopup
