import { FC } from 'react'

import { Box } from '../../Box'
import { Form, FormProps } from '../../components/Form'

interface IAuthForm extends FormProps {
    title: string
}

export const AuthForm: FC<IAuthForm> = ({ children, title, ...rest }) => {
    return (
        <Form layout="vertical" {...rest}>
            <Box className="formbox" padding={[32, 40]}>
                <Box className="form-title" padding={[0, 0, 40, 0]}>
                    <span>{title}</span>
                </Box>
                {children}
            </Box>
        </Form>
    )
}
