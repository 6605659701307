import { Select } from 'antd'
import React from 'react'
import { FC, useState } from 'react'

import { ArrowLeftPure, ArrowRightPure } from '../../../Icons'
import { DEFAULT_PER_PAGE } from '../../../constants'
import { Button, DownMini } from '../../../index'
import style from './style.m.less'

const { Option } = Select

export interface CursorPaginatorProps {
    onPerPage: (value: number) => void
    onNext: () => void
    onPrev: () => void
    next?: string
    prev?: string
    defaultPerPage?: number
    currentSize?: number
    isLoading?: boolean
}

const getPageValue = (currentPage: number, perPage: number, currentSize?: number) =>
    `${perPage * (currentPage - 1) || 1} - ${
        currentSize ? perPage * (currentPage - 1) + currentSize : perPage * currentPage
    }`

const CursorPaginator: FC<CursorPaginatorProps> = ({
    onPerPage,
    onNext,
    onPrev,
    prev,
    next,
    defaultPerPage = DEFAULT_PER_PAGE,
    currentSize,
    isLoading,
}) => {
    const [perPage, setPerPage] = useState(defaultPerPage)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const onPaginationOptionsChange = (value: number) => {
        setPerPage(value)
        setCurrentPage(1)
        onPerPage(value)
    }

    return (
        <div className={style['cursor-paginator__container']}>
            <div className={style['cursor-paginator__left']}>
                <Button
                    className={style['cursor-paginator__button']}
                    icon={<ArrowLeftPure />}
                    onClick={() => {
                        setCurrentPage(currentPage - 1)
                        onPrev()
                    }}
                    disabled={isLoading || !prev}
                />
                <span className={style['cursor-paginator__page-value']}>
                    {getPageValue(currentPage, perPage, currentSize)}
                </span>
                <Button
                    className={style['cursor-paginator__button']}
                    icon={<ArrowRightPure />}
                    onClick={() => {
                        setCurrentPage(currentPage + 1)
                        onNext()
                    }}
                    disabled={isLoading || !next}
                />
            </div>
            <div className={style['cursor-paginator__right']}>
                <Select
                    className={style['cursor-paginator__option-button']}
                    onChange={onPaginationOptionsChange}
                    defaultValue={defaultPerPage}
                    suffixIcon={<DownMini className="filter_arrow_icon_dark" />}
                >
                    <Option value={defaultPerPage}>Показывать по {defaultPerPage}</Option>
                    <Option value={50}>Показывать по 50</Option>
                    <Option value={100}>Показывать по 100</Option>
                </Select>
            </div>
        </div>
    )
}

// export default CursorPaginator

export default React.memo(CursorPaginator, (prevProps, nextProps) => {
    if (
        prevProps.next !== nextProps.next ||
        prevProps.prev !== nextProps.prev ||
        prevProps.currentSize !== nextProps.currentSize ||
        prevProps.isLoading !== nextProps.isLoading
    ) {
        return false
    }
    return true
})
