// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".outline__espz {\n  border: 1px solid #d3d5da;\n  border-radius: 12px;\n  margin: 16px -16px 16px -16px;\n  padding: 16px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/components/Markup/Outline/style.m.less"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,mBAAA;EACA,6BAAA;EACA,aAAA;AADJ","sourcesContent":["@import '/src/assets/style/palette';\n\n.outline {\n    border: 1px solid @gray-5;\n    border-radius: 12px;\n    margin: 16px -16px 16px -16px;\n    padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outline": "outline__espz"
};
export default ___CSS_LOADER_EXPORT___;
