import { Box, PageHeader, Tabs, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { nominalAccountsSlice } from '@store/nominalAccounts'
import { nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import { NominalAccountForm } from '../components/NominalAccountForm'
import { Footer } from './Footer'
import { RelatedAccounts } from './RelatedAccounts/RelatedAccounts'
import { UseTabsAndHash } from '../../../hooks/useTabsAndHash'

const { TabPane } = Tabs

enum NominalAccountViewTabName {
    account = 'account',
    sau = 'sau',
}
const defaultTab = NominalAccountViewTabName.account

export const NominalAccountView: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(
        Object.values(NominalAccountViewTabName),
        defaultTab,
    )
    const navigate = useNavigate()
    const data = useSelector(nominalAccountsSelector.selectCurrentItem)
    const reduxDispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            reduxDispatch(nominalAccountsSlice.actions.getItem(id))
        }
        return () => {
            reduxDispatch(nominalAccountsSlice.actions.clearItem())
        }
    }, [id])

    if (!data?.created_at) {
        return null
    }
    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Банковский счёт ${data.recipient}`}
                    subTitle={`Создан ${dayjs.unix(data.created_at).format(VIEW_DATE_FORMAT)}`}
                    onBack={() => navigate('/personal-accounts#bank')}
                />
            </Box>
            <Tabs onChange={onChangeTab} activeKey={activeTab}>
                <TabPane tab="Банковский счет" key={NominalAccountViewTabName.account}>
                    <NominalAccountForm />
                </TabPane>
                <TabPane tab="Связанные САУ" key={NominalAccountViewTabName.sau}>
                    <RelatedAccounts />
                </TabPane>
            </Tabs>
            <Footer />
        </>
    )
}
