import {
    IAgentProfile,
    IBusinessmanProfile,
    IIndividualProfile,
    ILegalEntityProfile,
    ProfileType,
} from '@dltru/dfa-models'
import { Col, RequisitesType, Row } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { InformationAboutCard } from './InformationAboutCard'
import { dataForInformationAboutCard } from './utils'

interface IInformationAboutUserProps {
    profileAgent?: IAgentProfile | undefined
    profile?: IIndividualProfile | ILegalEntityProfile | IBusinessmanProfile | undefined
    profileFullName?: string | null
    profileagentFullName?: string | null
    profileType?: ProfileType.PRSN | ProfileType.LEGL | ProfileType.BUSN
    profileAgentType?: ProfileType.AGNT
}

export const InformationAboutUser: FC<IInformationAboutUserProps> = ({
    profileAgent,
    profile,
    profileFullName,
    profileagentFullName,
    profileType,
    profileAgentType = ProfileType.AGNT,
}) => {
    const authorizedData = useSelector(clientsSelector.selectCurrentAuthData)
    const [profileRows, setProfileRows] = useState<RequisitesType[]>([])
    const [agentRows, setAgentRows] = useState<RequisitesType[]>([])

    useEffect(() => {
        if (profile && profileType) {
            const rows = dataForInformationAboutCard(profile, profileType, authorizedData)
            setProfileRows(rows)
        }
    }, [profile, profileType])

    useEffect(() => {
        if (profileAgent) {
            const rows = dataForInformationAboutCard(profileAgent, profileAgentType)
            setAgentRows(rows)
        }
    }, [profileAgent])

    return (
        <Row gutter={16} style={{ marginTop: 24 }}>
            {profile && (
                <Col span={profileAgent ? 12 : 24}>
                    <InformationAboutCard
                        rows={profileRows}
                        title="Сведения о заявителе"
                        subtitle={profileFullName || ''}
                    />
                </Col>
            )}
            {profileAgent && (
                <Col span={12}>
                    <InformationAboutCard
                        rows={agentRows}
                        title="Сведения о представителе"
                        subtitle={profileagentFullName || ''}
                    />
                </Col>
            )}
        </Row>
    )
}
