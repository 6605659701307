import { FC, useContext } from 'react'

import { Alert } from '../../../../Alert'
import { Step, StepperContext, useEditable } from '../../../../Stepper'
import { Form } from '../../../../components/Form'
import { UploadInputComponentProps } from '../../../../upload'
import { IRoleFormStepProps } from '../types'
import { FinancialPositionForm } from './FinancialPositionForm'

interface IComponentProps extends IRoleFormStepProps {
    stepIndex: number
    UploadInput: FC<Partial<UploadInputComponentProps>>
}

export const FinancialPositionStep: FC<IComponentProps> = ({
    stepIndex,
    isEditAllow,
    UploadInput,
}) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    const description = (
        <>
            <span>Загрузите как минимум один из нижеперечисленных документов:</span>
            <ul className="description-list">
                <li>
                    копия годовой бухгалтерской отчетности (бухгалтерский баланс, отчет о финансовом
                    результате);
                </li>
                <li>
                    копия годовой (либо квартальной) налоговой декларации с отметкой налогового
                    органа о ее принятии или без такой отметки с приложением либо копии квитанции об
                    отправке заказного письма с описью вложения (при направлении по почте), либо
                    копии подтверждения отправки на бумажных носителях (при передаче в электронном
                    виде);
                </li>
                <li>
                    копия аудиторского заключения на годовой отчет за прошедший год, в котором
                    подтверждаются достоверность финансовой (бухгалтерской) отчетности и
                    соответствие порядка ведения бухгалтерского учета законодательству Российской
                    Федерации;
                </li>
                <li>
                    справка об исполнении налогоплательщиком (плательщиком сборов, налоговым
                    агентом) обязанности по уплате налогов, сборов, пеней, штрафов, выданная
                    налоговым органом;
                </li>
                <li>
                    сведения об отсутствии в отношении юридического лица производства по делу о
                    несостоятельности (банкротстве), вступивших в силу решений судебных органов о
                    признании его несостоятельным (банкротом), проведения процедур ликвидации по
                    состоянию на дату представления документов в некредитную финансовую организацию;
                </li>
                <li>
                    сведения об отсутствии фактов неисполнения юридическим лицом своих денежных
                    обязательств по причине отсутствия денежных средств на банковских счетах;
                </li>
                <li>
                    данные о рейтинге юридического лица, размещенные в сети "Интернет" на сайтах
                    международных рейтинговых агентств («Standard & Poor's», «Fitch-Ratings»,
                    «Moody's Investors Service» и другие) и национальных рейтинговых агентств)
                </li>
            </ul>
        </>
    )

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle="Сведения о финансовом положении"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FinancialPositionForm uneditable={isEdit} />
            <div className="formRow">
                <UploadInput
                    className="formComponentItem"
                    name="finance_doc"
                    label="Документы о финансовом положении"
                    labelOnlyUneditable={false}
                    uneditable={isEdit}
                    required
                    title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
            {!isEdit && (
                <Alert type="info" description={description} showIcon banner width={1000} />
            )}
        </Step>
    )
}
