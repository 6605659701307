import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { CopyButton } from '../button'
import { EMPTY_FIELD_VALUE } from '../constants'
import style from './requisitesStyle.m.less'

export type RequisitesType = { label: string; value: ReactNode | undefined }
export type RequisitesProps = {
    requisites: RequisitesType[]
    copyEnabled?: boolean
    size?: 'large' | 'normal'
}

export const Requisites: FC<RequisitesProps> = ({ requisites, copyEnabled, size = 'normal' }) => {
    return (
        <div className={clsx(style.Requisites, style[size])}>
            {requisites.map((data) => (
                <div className={style.RequisitesRow} key={data.label}>
                    <span className={style.RequisitesRowLabel}>{data.label}</span>
                    <div className={style.RequisitesRowValue}>
                        <span>{data.value ?? EMPTY_FIELD_VALUE}</span>
                        {copyEnabled && typeof data.value === 'string' && (
                            <CopyButton text={data.value} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}
