import { openMessage } from '@dltru/dfa-ui'
import { openNotification } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { clientsSelector } from '@store/clients/selectors'
import { signData } from '@store/helper'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import api from '@services/api'

import { walletEncumbranceSlice } from './index'

function* getItems({ payload }: ReturnType<typeof walletEncumbranceSlice.actions.getItems>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const userId = yield* select(clientsSelector.selectCurrentClientId)
        if (userId) {
            const { error, data } = yield* call(api.lib.getWalletEncumbranceByUser, userId, {
                ...payload,
                order: 'asc(id)' as const,
            })

            if (error || data?.error) {
                throw error || data?.error
            }

            yield* put(
                walletEncumbranceSlice.actions.setData({
                    items: data?.items || [],
                    paging: data?.paging,
                }),
            )
        }
    } catch (error: ReturnType<Error>) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка обременений',
        })
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

function* getItem({ payload }: ReturnType<typeof walletEncumbranceSlice.actions.getItem>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getWalletEncumbranceById, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* put(walletEncumbranceSlice.actions.setItem(data.item))
        }
    } catch (error: ReturnType<Error>) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения обременения',
        })
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

function* createEncumbrance({
    payload,
}: ReturnType<typeof walletEncumbranceSlice.actions.createEncumbrance>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const createData = yield* select(encumbranceSelectors.selectCreateData)
        if (createData) {
            const { error, data } = yield* call(api.lib.setWalletEncumbranceByOperatorV2, {
                ...createData,
                skid: payload,
            })

            if (error || data?.error) {
                throw error || data?.error
            }
            if (data?.item) {
                yield* signData(data?.item?.status, payload, data)
                yield* put(
                    walletEncumbranceSlice.actions.setCreateItem({
                        ...createData,
                        uuid: data.item.params.encumbrance,
                    }),
                )
                openNotification({
                    type: 'success',
                    message: 'Запущен процесс наложения обременения',
                })
            }

            yield* put(walletEncumbranceSlice.actions.setError(null))
        }
    } catch (error: ReturnType<Error>) {
        openNotification({
            type: 'error',
            message: 'Операция отклонена',
        })

        yield* put(walletEncumbranceSlice.actions.setError(error))
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}
function* removeEncumbrance({
    payload,
}: ReturnType<typeof walletEncumbranceSlice.actions.removeEncumbrance>) {
    try {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.removeWalletEncumbranceByOperatorV2, payload)

        if (error || data?.error) {
            throw error || data?.error
        }
        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
            openNotification({
                type: 'success',
                message: 'Запрос на снятие обременения успешно отправлен',
            })
        }
        yield* put(walletEncumbranceSlice.actions.setError(null))
    } catch (error: ReturnType<Error>) {
        openMessage({
            type: 'error',
            message: 'Операция отклонена',
        })

        yield* put(walletEncumbranceSlice.actions.setError(error))
    } finally {
        yield* put(walletEncumbranceSlice.actions.setIsLoading(false))
    }
}

export function* watchEncumbrance(): Generator<StrictEffect> {
    yield* takeLatest(walletEncumbranceSlice.actions.getItems.type, getItems)
    yield* takeLatest(walletEncumbranceSlice.actions.getItem.type, getItem)
    yield* takeLatest(walletEncumbranceSlice.actions.createEncumbrance.type, createEncumbrance)
    yield* takeLatest(walletEncumbranceSlice.actions.removeEncumbrance.type, removeEncumbrance)
}
