import { QualifyInfoType } from '@dltru/dfa-models'
import { FC } from 'react'

import { Box } from '../../../Box'
import { InfoCircleOutline } from '../../../Icons'
import { Button } from '../../../button'
import { Modal } from '../../../components/Modal'
import { QualifiedDetails } from './QualifiedDetails'
import styles from './styles.m.less'

export interface IQualifiedDetailsModalProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    qualifyInfo?: QualifyInfoType
    isAdmin?: boolean
}

export const QualifiedDetailsModal: FC<IQualifiedDetailsModalProps> = ({
    isModalVisible,
    setIsModalVisible,
    qualifyInfo,
    isAdmin,
}) => {
    return (
        <Modal
            width={600}
            visible={isModalVisible}
            footer={null}
            onCancel={() => setIsModalVisible(false)}
            closable={false}
        >
            <div>
                <div className="ant-modal-title">
                    <InfoCircleOutline className={styles.headerIcon} />
                    Ограничения по сумме сделок
                </div>
                <Box padding={[0, 0, 0, 36]}>
                    <QualifiedDetails qualifyInfo={qualifyInfo} isAdmin={isAdmin} />
                </Box>
                <Box padding={[24, 0, 0, 0]} direction="row" justify="right">
                    <Button borderRadius={12} onClick={() => setIsModalVisible(false)}>
                        Закрыть
                    </Button>
                </Box>
            </div>
        </Modal>
    )
}
