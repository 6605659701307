import { Box } from '@dltru/dfa-ui'

import style from './style.m.less'

interface IHeaderLinks {
    children?: any[]
}

export const HeaderLinks: React.FC<IHeaderLinks> = ({ children }) => {
    return (
        <Box
            className={style.headerLinksBox}
            direction="row"
            align="center"
            justify="space-between"
            margin={[0, 60, 0, 0]}
        >
            {children}
        </Box>
    )
}
