import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Coins: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.33325 7.59999C5.33325 8.11332 5.73325 8.53332 6.21992 8.53332H7.21992C7.64659 8.53332 7.99325 8.16665 7.99325 7.71999C7.99325 7.23332 7.77992 7.05999 7.46659 6.94665L5.86659 6.38665C5.54659 6.27332 5.33325 6.09999 5.33325 5.61332C5.33325 5.16665 5.67992 4.79999 6.10659 4.79999H7.10659C7.59992 4.80665 7.99992 5.21999 7.99992 5.73332"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M6.66675 8.56668V9.06001V8.56668Z" fill="currentColor" />
                <path
                    d="M6.66675 8.56668V9.06001"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66675 4.27335V4.79335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.65992 11.9867C9.60176 11.9867 11.9866 9.60185 11.9866 6.66001C11.9866 3.71817 9.60176 1.33334 6.65992 1.33334C3.71808 1.33334 1.33325 3.71817 1.33325 6.66001C1.33325 9.60185 3.71808 11.9867 6.65992 11.9867Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.65332 13.2533C9.25332 14.1 10.2333 14.6533 11.3533 14.6533C13.1733 14.6533 14.6533 13.1733 14.6533 11.3533C14.6533 10.2467 14.1067 9.26666 13.2733 8.66666"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
