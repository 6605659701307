import moment, { Moment } from 'moment'
import qs from 'qs'
import { ReactNode } from 'react'

import { AutocompleteFilter } from './AutocompleteFilter'
import { DateFilter } from './DateFilter'
import { NumberFilter } from './NumberFilter'
import { SelectFilter } from './SelectFilter'
import { TextInputFilter } from './TextInputFilter'
import {
    Filter,
    FilterRecord,
    FilterType,
    FilterTypeByName,
    FilterTypeSingle,
    SelectData,
} from './types'

export const filterInput = (filter: Filter): ReactNode => {
    switch (filter.filterType) {
        case FilterType.NUMBER_RANGE: {
            return <NumberFilter {...filter} key={filter.name} />
        }

        case FilterType.DATE_RANGE: {
            return <DateFilter {...filter} key={filter.name} />
        }

        case FilterType.AUTOCOMPLETE: {
            return <AutocompleteFilter key={filter.name} {...filter} />
        }
        case FilterType.SELECT: {
            return <SelectFilter key={filter.name} {...filter} />
        }
        case FilterType.TEXTINPUT: {
            return <TextInputFilter key={filter.name} {...filter} />
        }

        default:
            return null
    }
}

export const filtersFab = (filters: Filter[]) => {
    const inputs = filters.map((f) => {
        return filterInput(f)
    })
    return inputs
}

export const getQueryString = (value: unknown, key: string, filterType: FilterType) => {
    switch (filterType) {
        case FilterType.NUMBER_RANGE: {
            const numberRange = value as [number, number]
            return `${key}=gt(${numberRange[0]})&${key}=lt(${numberRange[1]})`
        }

        case FilterType.DATE_RANGE: {
            const momentRange = value as [Moment, Moment]
            return `${key}=gt(${momentRange[0].startOf('day').unix()})&${key}=lt(${momentRange[1]
                .endOf('day')
                .unix()})`
        }

        case FilterType.DATE: {
            const momentValue = value as Moment
            // return `${key}=${momentValue.startOf('day').unix()}`
            return `${key}=gt(${momentValue.startOf('day').unix()})&${key}=lt(${momentValue
                .endOf('day')
                .unix()})`
        }

        case FilterType.NUMBER:
        case FilterType.SELECT:
        case FilterType.TEXTINPUT: {
            return qs.stringify({ [key]: value }, { arrayFormat: 'brackets', encode: false })
        }
        case FilterType.AUTOCOMPLETE: {
            const val = (value as SelectData[]).map((v) => v.value)
            return qs.stringify({ [key]: val }, { arrayFormat: 'brackets', encode: false })
        }

        default:
            return ''
    }
}

export const toQueryString = (
    filters: FilterRecord,
    filterTypeByName: FilterTypeByName,
): FilterRecord => {
    const queryStrings = Object.keys(filters).reduce((acc, currKey) => {
        if (Array.isArray(filters[currKey]) && !(filters[currKey] as []).length) {
            return acc
        }

        if (!filters[currKey]) {
            return acc
        }

        let filterType = filterTypeByName[currKey]

        if (!Array.isArray(filters[currKey])) {
            filterType = FilterTypeSingle[filterTypeByName[currKey]]
        }

        acc[currKey] = getQueryString(filters[currKey], currKey, filterType)
        return acc
    }, {} as FilterRecord)

    return queryStrings
}

export const getFilterTypeByName = (filters: Filter[]): FilterTypeByName =>
    filters.reduce((acc, curr) => {
        const key = curr.name
        acc[key] = curr.filterType
        return acc
    }, {} as FilterTypeByName)

export const getTotalValues = (values: FilterRecord) =>
    Object.values(values).filter((v) => {
        if (Array.isArray(v)) {
            return v.length
        } else {
            return v
        }
    }).length

const isArrayMoment = (value: unknown): value is Moment[] =>
    Array.isArray(value) && moment.isMoment(value[0])
const isArrayNumber = (value: unknown): value is number[] =>
    Array.isArray(value) && typeof value[0] === 'number'

export const prepareValue = (filters: FilterRecord, filterTypeByName: FilterTypeByName) => {
    const result = { ...filters }
    Object.entries(filters).forEach(([key, value]) => {
        const filterType = filterTypeByName[key]
        if (filterType === FilterType.DATE || filterType === FilterType.DATE_RANGE) {
            if (moment.isMoment(value)) {
                result[key] = value.unix()
            } else if (isArrayMoment(value)) {
                result[key] = [`gte(${value[0].unix()})`, `lte(${value[1].unix()})`]
            }
        }
        if (
            (filterType === FilterType.NUMBER_RANGE || filterType === FilterType.NUMBER) &&
            isArrayNumber(value)
        ) {
            result[key] = [`gte(${value[0]})`, `lte(${value[1]})`]
        }
        if (filterType === FilterType.AUTOCOMPLETE && Array.isArray(value)) {
            result[key] = value.map((element) => element.value)
        }

        if (filterType === FilterType.TEXTINPUT && value) {
            result[key] = `like(${value})`
        }
    })
    return result
}
