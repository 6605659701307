import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

interface IDfaIcon {
    width?: string
    height?: string
    fill?: string
}

export const CaseDel: FC<IconProps & IDfaIcon> = ({
    width = '14px',
    height = '14px',
    ...props
}) => (
    <AntIcon
        component={() => (
            <svg
                width={width}
                height={height}
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.08555 2.30954C5.08555 1.9545 5.37255 1.66669 5.72658 1.66669H14.2736C14.6276 1.66669 14.9146 1.9545 14.9146 2.30954V4.23812H17.6924C18.0464 4.23812 18.3334 4.52593 18.3334 4.88097V10.3334C18.3334 10.6095 18.1096 10.8334 17.8334 10.8334H17.5514C17.2752 10.8334 17.0514 10.6095 17.0514 10.3334V7.50226C16.0909 8.72838 14.5735 9.91369 12.3505 10.4157V11.7381C12.3505 12.0932 12.0635 12.381 11.7094 12.381H8.29064C7.93661 12.381 7.64962 12.0932 7.64962 11.7381V10.4159C5.42684 9.91422 3.90946 8.72942 2.9488 7.5036V15.381L12.002 15.4148C12.2774 15.4159 12.5001 15.6394 12.5001 15.9148V16.1667C12.5001 16.4428 12.2774 16.6667 12.0012 16.6667L2.30777 16.6667C1.95375 16.6667 1.66675 16.3789 1.66675 16.0238V4.88097C1.66675 4.52593 1.95375 4.23812 2.30777 4.23812H5.08555V2.30954ZM8.93167 10.6179V11.0953H11.0684V10.6178C10.7262 10.6498 10.37 10.6667 9.99956 10.6667C9.62946 10.6667 9.27362 10.6499 8.93167 10.6179ZM13.6326 2.9524V4.23812H6.3676V2.9524H13.6326ZM3.22014 5.52383C3.96757 7.12169 5.96538 9.38099 9.99956 9.38099C14.0337 9.38099 16.0315 7.12169 16.779 5.52383H3.22014ZM14.1667 13.7292C14.1667 13.648 14.2376 13.5834 14.323 13.5834H19.0105C19.0959 13.5834 19.1667 13.648 19.1667 13.7292V14.6042C19.1667 14.6834 19.0959 14.7501 19.0105 14.7501H14.323C14.2376 14.7501 14.1667 14.6834 14.1667 14.6042V13.7292Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
