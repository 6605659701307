import { DatePicker } from 'antd'
import { FC } from 'react'

import { LOCALE } from '../config/locale'
import { VIEW_DATE_FORMAT } from '../constants'
import { RUS_DATETIME_FORMAT } from '../utils'
import { datePeriodFilter } from '../utils/datePickerHelper'
import { DateItemProps } from './type'

export const DateItem: FC<DateItemProps> = ({
    disabled,
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyFutureAndToday,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
    getPopupContainer,
    showTime,
    ...rest
}) => {
    const filter = datePeriodFilter({
        onlyWorkDays,
        onlyPast,
        onlyFuture,
        onlyFutureAndToday,
        onlyBeforeDate,
        onlyBeforeDateAndToday,
    })
    const props = {
        ...rest,
        showSecond: false,
        showTime:
            showTime && typeof showTime === 'object' ? { ...showTime, format: 'HH:mm' } : showTime,
    }

    return (
        <DatePicker
            {...props}
            locale={LOCALE}
            disabled={disabled}
            disabledDate={filter}
            getPopupContainer={getPopupContainer}
            placeholder={showTime ? 'дд.мм.гггг чч:мм' : 'дд.мм.гггг'}
            format={showTime ? RUS_DATETIME_FORMAT : VIEW_DATE_FORMAT}
        />
    )
}
