import { ITariffPlane } from '@dltru/dfa-models'
import { DateTimeCell, getTariffCode } from '@dltru/dfa-ui'

export const gridConfig = [
    {
        title: 'Дата назаначения',
        dataIndex: 'archived_at',
        width: '180px',
        render: DateTimeCell,
    },

    {
        title: 'Код ТП',
        dataIndex: 'name',
        width: '110px',
        render: (value: string, record: ITariffPlane) => `${getTariffCode(record)} ${value}`,
    },
    {
        title: 'Название',
        dataIndex: 'name',
        width: '180px',
    },
]
