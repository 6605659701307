import { DocumentsEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    Box,
    Col,
    DateTimeCell,
    Divider,
    EMPTY_FIELD_VALUE,
    Row,
    Space,
    VIEW_DATE_FORMAT,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { RegistrationNominalAccountBlock } from '@pages/ClientCard/components/RegistrationCard/components/RegistrationNominalAccountBlock'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { AlertUserBlock } from '../BlockClient/AlertUserBlock'
import { CardVerticalField } from '../CardVerticalField'
import { QualifiedBlock } from '../QualifiedBlock'
import { CertificatesList } from './CertificatesBlock'

export const CommonInfo: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const registrationDate = useSelector(clientsSelector.currentClientRegistrationDate)
    const applicationDate = useSelector(clientsSelector.currentClientApplicationDate)
    const isCurrentClientBlocked = useSelector(clientsSelector.selectIsCurrentClientBlocked)
    const qualifyInfo = useSelector(clientsSelector.selectQualifyInfoCurrentClient)
    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const showDownloadQualButton = currentClientId && qualifyInfo?.is_qualified && isAllow(['investor.read.all'])

    return (
        <Box margin={[0, 0, 8, 0]} className="boxContent">
            {isCurrentClientBlocked && (
                <Box margin={[16, 40, 0, 40]}>
                    <AlertUserBlock />
                    <Divider margin={[24, 0, 0, 0]} />
                </Box>
            )}
            {/*<AlertClientRemove /> TODO добавтить когда появится возможность получать информацию по удалению */}
            <Box padding={[24, 40]}>
                <Row>
                    <Col span={16}>
                        <Space size={32} align="start">
                            {isAllow(['qualified_investors_application.read.all']) && <QualifiedBlock />}
                            {showDownloadQualButton && (
                                <DownloadBtn
                                    documentType={DocumentsEnum.register_of_qualified_investors}
                                    params={{user_id: currentClientId}}
                                    title="Выписка из реестра"
                                />
                            )}
                        </Space>
                    </Col>
                    {applicationDate ? (
                        <>
                            <Col span={4}>
                                <CardVerticalField
                                    title="Заявка на регистрацию"
                                    value={
                                        registrationDate
                                            ? dayjs.unix(registrationDate).format(VIEW_DATE_FORMAT)
                                            : EMPTY_FIELD_VALUE
                                    }
                                />
                            </Col>
                            <Col span={4}>
                                <CardVerticalField
                                    title="Регистрация в системе"
                                    value={dayjs.unix(applicationDate).format(VIEW_DATE_FORMAT)}
                                />
                            </Col>
                        </>
                    ) : (
                        <Col span={8}>
                            <CardVerticalField
                                title="Заявка на регистрацию от"
                                value={DateTimeCell(registrationDate)}
                            />
                            <RegistrationNominalAccountBlock />
                        </Col>
                    )}
                    <Col span={24}>
                        <Divider margin={[24, 0]} />
                    </Col>
                </Row>

                {isAllow(['certificate.read.all']) && (
                    <Row>
                        <Col span={24}>
                            <CertificatesList />
                        </Col>
                    </Row>
                )}
            </Box>
        </Box>
    )
}
