import IAppState from '../states'

const selectList = (state: IAppState) => state.nominalAccounts.data?.items
const selectPaging = (state: IAppState) => state.nominalAccounts.data?.paging
const selectParams = (state: IAppState) => state.nominalAccounts.params
const selectIsLoading = (state: IAppState) => state.nominalAccounts.isLoading
const selectCurrentItem = (state: IAppState) => state.nominalAccounts.currentItem
const selectRelatedAccount = (state: IAppState) => state.nominalAccounts.currentRelatedAccount

export const nominalAccountsSelector = {
    selectList,
    selectPaging,
    selectIsLoading,
    selectParams,
    selectCurrentItem,
    selectRelatedAccount,
}
