import React from 'react'

import { Col } from '../../../Col'
import { Row } from '../../../Row'
import { BaseType, Typography } from '../../../Typography'

export const LabelRow: React.FC<{
    label: React.ReactNode
    type?: BaseType
    strong?: boolean
    labelSpan?: number
    className?: string
}> = ({ label, type = 'secondary', strong, labelSpan = 12, children, className }) => (
    <Row className={className}>
        <Col span={labelSpan}>
            {typeof label === 'string' ? (
                <Typography.Text type={type} strong={strong}>
                    {label}
                </Typography.Text>
            ) : (
                label
            )}
        </Col>
        <Col span={24 - labelSpan} style={{ textAlign: 'right' }}>
            {children}
        </Col>
    </Row>
)
