import { RbacContext } from '@dltru/dfa-rbac'
import { Button, ClipboardTick } from '@dltru/dfa-ui'
import { FC, memo, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { putStatementRequestQualify } from '@store/qualifyStatement'

import { qualFormToPutRequest } from '@pages/QualificationStatement/utils'

import { IActionProps } from '../../../type'
import { ApplyActionModal } from '../Modals/ApplyActionModal'

export const ApplyAction: FC<IActionProps> = memo(({ data }) => {
    const { isAllow } = useContext(RbacContext)

    const reduxDispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const onOkHandler = () => {
        if (data) {
            const status = data.status === 'lawyer_approval' ? 'approved' : 'lawyer_approval'
            const formValue = qualFormToPutRequest({ ...data, status })
            if (formValue) {
                reduxDispatch(putStatementRequestQualify(formValue))
            }
        }
        setIsModalVisible(false)
    }

    if (!isAllow(['approval.aml', 'approval.lawyer'])) {
        return <></>
    }

    return (
        <>
            <Button
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
                type="primary"
                icon={<ClipboardTick />}
            >
                Согласовать заявление
            </Button>
            <ApplyActionModal
                isModalVisible={isModalVisible}
                onOk={onOkHandler}
                onCancel={() => {
                    setIsModalVisible(false)
                }}
            />
        </>
    )
})
