import { InvestorRegistryApplicationEvent } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell } from '@dltru/dfa-ui'

import { Status } from '@pages/QualificationStatementsList/components/Status'

import { CommentDetail } from '../CommentDetail'

export const gridConfig: ColumnsType<InvestorRegistryApplicationEvent> = [
    {
        title: 'Дата поступления заявки на этап',
        dataIndex: 'created_at',
        key: 'created_at',
        render: DateTimeCell,
    },
    /*{
        title: 'Этап согласования',
        dataIndex: 'previous_status',
        key: 'previous_status',
        align: 'right',
        render: (value, record) => (
            <div style={{ display: 'flex' }}>
                <Status status={value} />
            </div>
        ),
    },*/
    {
        title: 'Дата согласования заявки на выпуск',
        dataIndex: 'created_at',
        key: 'created_at',
        render: DateTimeCell,
    },
    {
        title: 'Статус согласования',
        dataIndex: 'current_status',
        key: 'current_status',
        render: (value) => (
            <div style={{ display: 'flex' }}>
                <Status status={value} />
            </div>
        ),
    },
    {
        title: 'Ответственный сотрудник',
        dataIndex: 'operator_uuid',
        key: 'operator_uuid',
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        render: (value) => <CommentDetail value={value} />,
    },
]
