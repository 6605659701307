// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".secondaryText_jxIg0 {\n  color: #868991;\n}\n.dangerText_x78bq {\n  color: #f5222d;\n}\n.cellWithIcon__iwYA {\n  display: flex;\n  align-items: center;\n}\n.cellWithIcon__iwYA .iconContainer_HJkIf {\n  margin-right: 8px;\n  display: flex;\n  align-items: center;\n}\n.linkCell_pswxR {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #007ef2;\n}\n.linkCell_pswxR:hover {\n  color: #303440 !important;\n}\n.confirmCell_OILzU {\n  color: #52c41a;\n  display: flex;\n  align-items: center;\n}\n.confirmCell_OILzU span {\n  margin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/Table/components/Cell/style.m.less"],"names":[],"mappings":"AAEA;EACC,cAAA;AADD;AAIA;EACC,cAAA;AAFD;AAKA;EACC,aAAA;EACA,mBAAA;AAHD;AACA;EAKE,iBAAA;EACA,aAAA;EACA,mBAAA;AAHF;AAOA;EACC,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AALD;AAMC;EACC,yBAAA;AAJF;AAQA;EACC,cAAA;EACA,aAAA;EACA,mBAAA;AAND;AAQC;EACC,gBAAA;AANF","sourcesContent":["@import '/src/assets/style/palette';\n\n.secondaryText {\n\tcolor: @gray-7;\n}\n\n.dangerText {\n\tcolor: @red-6;\n}\n\n.cellWithIcon {\n\tdisplay: flex;\n\talign-items: center;\n\n\t.iconContainer {\n\t\tmargin-right: 8px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t}\n}\n\n.linkCell {\n\tfont-weight: 600;\n\tfont-size: 14px;\n\tline-height: 22px;\n\tcolor: @primary-6;\n\t&:hover {\n\t\tcolor: @gray-10 !important;\n\t}\n}\n\n.confirmCell {\n\tcolor: @green-6;\n\tdisplay: flex;\n\talign-items: center;\n\n\t& span {\n\t\tmargin-left: 5px;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondaryText": "secondaryText_jxIg0",
	"dangerText": "dangerText_x78bq",
	"cellWithIcon": "cellWithIcon__iwYA",
	"iconContainer": "iconContainer_HJkIf",
	"linkCell": "linkCell_pswxR",
	"confirmCell": "confirmCell_OILzU"
};
export default ___CSS_LOADER_EXPORT___;
