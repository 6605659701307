import classNames from 'classnames/bind'
import { FC } from 'react'

import style from './style.m.less'

export interface IBodyRoundedBlock {
    left?: any
    right?: any
    success?: boolean
}

const cx = classNames.bind(style)

export const BodyRoundedBlock: FC<IBodyRoundedBlock> = ({ left, right, success }) => {
    const className = cx({
        roundedBlock: true,
        roundedBlockSuccess: success,
    })
    return (
        <div className={className}>
            <div className={style.roundedBlockSlice}>{left}</div>

            <div className={style.roundedBlockSlice}>{right}</div>
        </div>
    )
}
