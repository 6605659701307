import {
    DecisionMakingType,
    DfaStatusEnum,
    ExternalRateIndex,
    IssueAvailableInvestorsType,
    IssueTypeDfa,
    PriceSource,
    TypeOfLaw,
    issueAvailableInvestorsTypeTranslate,
    issueTypeDfaTranslate,
    priceSourceTranslate,
    typeOfLawTranslate,
} from '@dltru/dfa-models'
import {
    CardDivider,
    Form,
    IStep,
    IValues,
    PriceSourceDynamic,
    SettlementsFormValue,
    Step,
    StepperContext,
    VIEW_DATE_FORMAT,
    WrappedInput,
    WrappedSelect,
    openMessage,
    useEditable,
} from '@dltru/dfa-ui'
import { SettlementCollectComponent } from '@dltru/dfa-ui'
import { FC, useCallback, useContext, useState } from 'react'

import { getExternalRateHelper } from '@store/helper'

import { TickerInput } from '@components/Form'

import { PriceSourceStatic } from './ConditionStep/PriceSourceStatic'

const STEP_INDEX = 0
const optionsTypeOfLaw = Object.entries(typeOfLawTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsPriceSourceType = Object.entries(priceSourceTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsIssueType = Object.entries(issueTypeDfaTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsIssueAvailableInvestorsType = Object.entries(issueAvailableInvestorsTypeTranslate).map(
    ([key, value]) => ({
        key,
        value: key,
        label: value,
    }),
)

type ComponentProps = Partial<IStep>
export const Step1: FC<ComponentProps> = (props) => {
    const { editable, currentStep, values } = useContext(StepperContext)

    const [form] = Form.useForm()
    const [checkUniqueTicker, setCheckUniqueTicker] = useState(false)
    const [isManualIssue, setIsManualIssue] = useState(values?.issue_type === IssueTypeDfa.manual)
    const [isQualifiedType, setIsQualifiedType] = useState(false)
    const [currentSettlementsType, setCurrentSettlementsType] = useState({
        repayment_settlements_type: values?.repayment_settlements_type,
        issue_settlements_type: values?.issue_settlements_type,
    } as SettlementsFormValue)
    const [isPriceTypeDynamic, setIsPriceTypeDynamic] = useState(
        values?.price_source_type === PriceSource.dynamic,
    )

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const calcRedeemPrice = () => {
        const priceExternal = Number(form.getFieldValue('price_external'))
        const spread = Number(form.getFieldValue('redemption_spread')) || 0
        const redeemPricePerDfa = (priceExternal / 100) * spread + priceExternal

        form.setFieldsValue({
            redeem_price_per_dfa: redeemPricePerDfa.toFixed(2),
        })
    }

    const onChange = useCallback(
        (changedValues: IValues | null) => {
            if (
                changedValues &&
                ('issue_end_date' in changedValues || 'original_amount' in changedValues) &&
                !isManualIssue
            ) {
                let conditionsText = ''
                const amount = form.getFieldValue('original_amount')
                const date = form.getFieldValue('issue_end_date')?.format(VIEW_DATE_FORMAT)
                if (amount && date) {
                    conditionsText = `Заключение договоров о приобретении ЦФА в отношении не менее чем ${amount} ЦФА в срок не позднее ${date}`
                }
                form.setFieldsValue({ issue_conditions: conditionsText })
            }

            if (changedValues && 'ticker_symbol' in changedValues) {
                setCheckUniqueTicker(changedValues.ticker_symbol !== values?.ticker_symbol)
            }

            if (changedValues && 'issue_type' in changedValues) {
                setIsManualIssue(changedValues.issue_type === IssueTypeDfa.manual)
                if (changedValues.issue_type === IssueTypeDfa.manual) {
                    form.setFieldsValue({ issue_end_date: undefined })
                }
            }

            if (changedValues && 'issue_available_investors_type' in changedValues) {
                setIsQualifiedType(
                    changedValues.issue_available_investors_type ===
                        IssueAvailableInvestorsType.qualified,
                )
            }

            if (changedValues && 'price_source_type' in changedValues) {
                if (changedValues.price_source_type === PriceSource.static) {
                    form.setFieldsValue({ redeem_price_per_dfa: '' })
                }
                setIsPriceTypeDynamic(changedValues.price_source_type === PriceSource.dynamic)
            }

            if (changedValues && 'investment_spread' in changedValues) {
                const priceExternal = Number(form.getFieldValue('price_external'))
                const spread = Number(changedValues.investment_spread) || 0
                const pricePerDfa = (priceExternal / 100) * spread + priceExternal

                form.setFieldsValue({
                    price_per_dfa: pricePerDfa.toFixed(2),
                })
            }

            if (changedValues && 'redemption_spread' in changedValues) {
                calcRedeemPrice()
            }
        },
        [checkUniqueTicker, form, isManualIssue],
    )

    const getExternalRate = (index: ExternalRateIndex) => {
        getExternalRateHelper(index)
            .then((result) => {
                form.setFieldsValue({ price_external: result.item.rate / 100 })
                calcRedeemPrice()
            })
            .catch(() => {
                openMessage({ type: 'error', message: 'Возникли проблемы получении курса ' })
            })
    }

    const isTellerApprovalStatus = values?.status === DfaStatusEnum.teller_approval

    return (
        <Step
            stepIndex={STEP_INDEX}
            onChangeValues={onChange}
            form={form}
            stepTitle="Условия выпуска ЦФА"
            {...props}
        >
            <div className="formRow">
                <WrappedInput
                    label="Наименование выпуска"
                    className="formComponentItem"
                    name="title"
                    placeholder="Предложите уникальное название выпуска"
                    uneditable={isEdit}
                    required
                    maxLength={100}
                    disabled={isTellerApprovalStatus}
                />
            </div>
            <div className="formRow">
                <TickerInput
                    uneditable={isEdit}
                    required
                    checkUnique={checkUniqueTicker}
                    placeholder="Предложите краткое название из 1-5 символов"
                />
            </div>
            <CardDivider />
            <div className="formRow">
                <WrappedSelect
                    className="formComponentItem"
                    label="Вид прав, удостоверенных ЦФА"
                    name="type_of_law"
                    uneditable={isEdit}
                    required
                    options={optionsTypeOfLaw}
                    disabled
                    initialValue={values?.type_of_law ? undefined : TypeOfLaw.monetary_claim}
                />
            </div>
            <div className="formRow">
                <WrappedSelect
                    className="formComponentItem"
                    label="Источник цены"
                    name="price_source_type"
                    uneditable={isEdit}
                    required
                    disabled={values?.decision_making_type === DecisionMakingType.platform}
                    options={optionsPriceSourceType}
                    // initialValue={PriceSource.static}
                />
            </div>

            <CardDivider />
            <div className="formRow">
                <WrappedSelect
                    className="formComponentItem"
                    label="Способ осуществления выпуска"
                    name="issue_type"
                    uneditable={isEdit}
                    required
                    disabled={isTellerApprovalStatus}
                    options={optionsIssueType}
                />
            </div>
            <CardDivider />
            <SettlementCollectComponent
                radioName="issue_settlements_type"
                radioLabel="Способ получения денежных средств в рамках эмиссии"
                selectName="payment_collect_type"
                uneditable={isEdit}
                formValues={currentSettlementsType}
                isAllowDirect={isManualIssue}
                disabled={isTellerApprovalStatus}
            />
            <CardDivider />
            <SettlementCollectComponent
                radioName="repayment_settlements_type"
                radioLabel="Способ получения денежных средств при погашении выпуска"
                selectName="repayment_collect_type"
                uneditable={isEdit}
                formValues={currentSettlementsType}
                isAllowDirect={isManualIssue}
                disabled={isTellerApprovalStatus}
                onChange={setCurrentSettlementsType}
            />
            <CardDivider />
            <div className="formRow">
                <WrappedSelect
                    className="formComponentItem"
                    label="ЦФА доступны для приобретения"
                    name="issue_available_investors_type"
                    uneditable={isEdit}
                    required
                    options={optionsIssueAvailableInvestorsType}
                />
            </div>

            {!isPriceTypeDynamic && values && (
                <PriceSourceStatic
                    isEdit={isEdit}
                    isQualifiedType={isQualifiedType}
                    isTellerApprovalStatus={isTellerApprovalStatus}
                    isManualIssue={isManualIssue}
                    repaymentSettlementsType={currentSettlementsType.repayment_settlements_type}
                />
            )}

            {isPriceTypeDynamic && (
                <PriceSourceDynamic
                    isEdit={isEdit}
                    getExternalRate={getExternalRate}
                    settlementsType={currentSettlementsType.repayment_settlements_type}
                />
            )}
        </Step>
    )
}
