import { Button, Form, Modal, WrappedInput, contactValidators } from '@dltru/dfa-ui'
import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { storeLink } from '@store/dfa/details'
import { IDfaLink } from '@store/dfa/types'
import IAppState from '@store/states'

export interface IAddLinkModal {
    isModalVisible: boolean
    type: keyof IDfaLink
    setIsModalVisible: (val: boolean) => void
    linkOriginForTitle: string
}

const AddLinkModal: FC<IAddLinkModal> = ({
    isModalVisible,
    setIsModalVisible,
    type,
    linkOriginForTitle,
}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { ois_link, emitter_link } = useSelector((store: IAppState) => store.dfaDetails)
    const links = { ois_link, emitter_link }

    let initialValue = 'http://'
    const [isError, setIsError] = useState(false)

    if (links && links[type]) {
        initialValue = links[type] || '' //TS why u do this with us...
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const setErrorIfInvalidFields = (fields: FieldData[]) => {
        const field = fields.find(
            (element) => Array.isArray(element.name) && element.name[0] === type,
        )
        if (field) {
            setIsError(Boolean(field.errors && field.errors.length > 0))
        }
    }

    return (
        <Modal
            title={`Публикация Решения о выпуске ЦФА на сайте ${linkOriginForTitle}`}
            visible={isModalVisible}
            onCancel={handleCancel}
            width={600}
            footer={[
                <Button borderRadius={16} onClick={() => handleCancel()}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="primary"
                    disabled={isError}
                    onClick={() => {
                        const formData = form.getFieldsValue() as IDfaLink
                        dispatch(storeLink(formData))
                        setIsModalVisible(false)
                    }}
                >
                    Сохранить
                </Button>,
            ]}
        >
            <div>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ [type]: initialValue }}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onFinish={() => {}}
                    onFieldsChange={setErrorIfInvalidFields}
                >
                    <p>
                        Перед публикацией на витрине предложений, укажите URL-адрес, где размещено
                        Решение о выпуске ЦФА.
                    </p>
                    <WrappedInput
                        label=""
                        name={type}
                        className="modal-text-area"
                        placeholder="http://"
                        rules={[{ validator: contactValidators.webSite }]}
                        required
                    />
                </Form>
            </div>
        </Modal>
    )
}

export default AddLinkModal
