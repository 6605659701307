import {
    Form,
    KppInput,
    Step,
    StepperContext,
    WrappedInput,
    commonValidators,
    onlyDigits,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { InnInput } from '@components/Form'

interface IComponentProps {
    stepIndex: number
    isEditAllow: boolean
    topText?: string
}

export const BankDetailsStep: FC<IComponentProps> = ({ stepIndex, isEditAllow, topText }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle="Банковские реквизиты"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            {topText && !isEdit && <p>{topText}</p>}
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Номер счета (расчетный счет)"
                    name="account_number"
                    placeholder="Состоит из 20 цифр"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: commonValidators.number(20) }]}
                    maxLength={20}
                    normalize={onlyDigits}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Наименование банка"
                    name="bank"
                    placeholder="Полное наименование банка"
                    uneditable={isEdit}
                    required
                    maxLength={200}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="БИК банка"
                    name="bank_bic"
                    placeholder="Состоит из 9 цифр"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: commonValidators.number(9) }]}
                    maxLength={9}
                    normalize={onlyDigits}
                />
            </div>
            <div className="formRow">
                <InnInput label="ИНН банка" name="bank_inn" uneditable={isEdit} required isUl />
            </div>
            <div className="formRow">
                <KppInput label="КПП банка" name="bank_kpp" uneditable={isEdit} required />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Корреспондентский счёт"
                    name="correspondent_account"
                    placeholder="Состоит из 20 цифр"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: commonValidators.number(20) }]}
                    maxLength={20}
                    normalize={onlyDigits}
                />
            </div>
        </Step>
    )
}
