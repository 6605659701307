import React, { useEffect } from 'react'
import { useState } from 'react'

import { IStepper, IValues, StepperContext } from './StepperContext'

type outOfValuesMergeStatusArrayType = Record<number, boolean>

export const StepperC = <T extends Record<string, unknown>>({
    initialValues,
    initialStep = 0,
    onStepperFinish,
    outOfValuesMerge = [],
    children,
}: IStepper<T> & { children: React.ReactNode }): JSX.Element => {
    const [stepsValues, setStepsValues] = useState(initialValues)
    const [currentStep, setCurrentStep] = useState(initialStep)
    const stepsTotal = React.Children.toArray(children).length
    const [editable, setEditable] = useState<number[]>([])

    const outOfValuesMergeStatusArray = outOfValuesMerge
        .sort()
        .reduce((total: outOfValuesMergeStatusArrayType, value) => {
            total[value] = false
            return total
        }, {})
    const [outOfValuesMergeStatus, setOutOfValuesMergeStatus] =
        useState<outOfValuesMergeStatusArrayType>(outOfValuesMergeStatusArray)

    useEffect(() => {
        setCurrentStep(initialStep)
    }, [initialStep])

    function mergeValuesIntoState(values: IValues) {
        setStepsValues((prevState) => ({ ...prevState, ...values }))
    }

    function stepBack(values?: IValues) {
        if (values) {
            mergeValuesIntoState(values)
        }

        if (currentStep > 0) {
            if (
                !(
                    Object.keys(outOfValuesMergeStatus).includes(String(currentStep - 1)) &&
                    outOfValuesMergeStatus[currentStep - 1]
                )
            ) {
                setCurrentStep((step) => step - 1)
            } else {
                let tempStep = currentStep - 1
                while (outOfValuesMergeStatus[tempStep] && tempStep >= 0) {
                    tempStep--
                }
                setCurrentStep(tempStep < 0 ? currentStep : tempStep)
            }
        }
    }

    function stepForward(values: IValues) {
        mergeValuesIntoState(values)

        if (outOfValuesMergeStatus[currentStep] === false) {
            setOutOfValuesMergeStatus((previousValues) => ({
                ...previousValues,
                [currentStep]: true,
            }))
        }

        if (currentStep < stepsTotal) {
            if (
                !(
                    Object.keys(outOfValuesMergeStatus).includes(String(currentStep + 1)) &&
                    outOfValuesMergeStatus[currentStep + 1]
                )
            ) {
                setCurrentStep((step) => step + 1)
            } else {
                let tempStep = currentStep + 1
                while (outOfValuesMergeStatus[tempStep] && tempStep < stepsTotal) {
                    tempStep++
                }
                setCurrentStep(tempStep)
            }
        }

        if (currentStep + 1 >= stepsTotal) {
            onStepperFinish({ ...stepsValues, ...values })
        }
    }

    function turnToFirstStep() {
        setCurrentStep(0)
    }

    function turnToFirstStepAndClear() {
        setStepsValues(initialValues)
        turnToFirstStep()
    }

    function setEditHandler(val: number) {
        if (editable.includes(val)) {
            setEditable(editable.filter((v) => v !== val))
        } else {
            setEditable([...editable, val])
        }
    }

    return (
        <StepperContext.Provider
            value={{
                stepBack,
                stepForward,
                turnToFirstStep,
                turnToFirstStepAndClear,
                setEdit: setEditHandler,
                mergeValuesIntoState,
                stepsTotal,
                currentStep,
                values: stepsValues,
                editable: editable,
            }}
        >
            {React.Children.toArray(children).slice(0, currentStep + 1)}
        </StepperContext.Provider>
    )
}
