import { Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import style from './style.m.less'

interface IRevisionModal {
    visible: boolean
    setVisible: (visible: boolean) => void
    action: (comment: string) => void
}
type FormDataType = {
    comment: string
}
export const RevisionModal: FC<IRevisionModal> = ({ visible, setVisible, action }) => {
    const [comment, setComment] = useState('')
    const [form] = Form.useForm<FormDataType>()

    const onCancel = () => {
        setVisible(false)
        setComment('')
        form.resetFields()
    }

    const onValuesChange = (changedValues: Partial<FormDataType>) => {
        if ('comment' in changedValues) {
            setComment(changedValues.comment ?? '')
        }
    }
    const onOk = (values: FormDataType) => {
        action(values.comment)
    }

    return (
        <Modal
            title="Отправить на доработку"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={form.submit} disabled={!comment}>
                    Да, отправить
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onOk} onValuesChange={onValuesChange}>
                <span>Вы действительно хотите отправить Анкету на доработку?</span>
                <Divider />
                <h4 className={style.headerBold}>Замечания и предложения для клиента</h4>
                <WrappedInput
                    name="comment"
                    placeholder="Ваш комментарий"
                    multiline
                    rows={5}
                    required
                />
            </Form>
        </Modal>
    )
}
