import { DfaStatusEnum } from '@dltru/dfa-models'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'

import IAppState from '@store/states'

const selectDfaDetails = (state: IAppState) => state.dfaDetails
const selectDfaDetailsStatus = (state: IAppState) => state.dfaDetails.status

const selectIsShowApplication = createSelector(selectDfaDetailsStatus, (status) => {
    return ['collection_started'].includes(status)
})

const selectIsShowDeals = createSelector(selectDfaDetailsStatus, (status) => {
    return ['redeem_started', 'redeemed', 'emitment_success'].includes(status)
})
const selectIsShowTransfer = createSelector(selectDfaDetailsStatus, (status) => {
    return ['redeem_started', 'emitment_success'].includes(status)
})

const selectIsShowOwners = createSelector(selectDfaDetailsStatus, (status) => {
    return [
        DfaStatusEnum.emitment_success,
        DfaStatusEnum.mature,
        DfaStatusEnum.mature_confirmed,
        DfaStatusEnum.mature_preparation,
        DfaStatusEnum.redeemed,
    ].includes(status)
})

const selectIsForRepayment = createSelector(
    (state: IAppState) => state.dfaDetails.emitter_id,
    (state: IAppState) => state.dfaDetails.redeem_date,
    (state: IAppState) => state.auth.uuid,
    (emitter_id, redeem_date, uuid) => {
        if (emitter_id !== uuid) {
            return false
        }

        const _redeem_date = dayjs(redeem_date)

        return _redeem_date.isValid() && _redeem_date.isBefore(dayjs())
    },
)

const selectApprovalHistory = (state: IAppState) => state.dfaDetails.approvalHistory
const selectIsLoading = (state: IAppState) => state.dfaDetails.isLoading

export const dfaDetailsSelector = {
    selectDfaDetailsStatus,
    selectIsShowApplication,
    selectIsShowDeals,
    selectIsShowTransfer,
    selectIsShowOwners,
    selectIsForRepayment,
    selectDfaDetails,
    selectApprovalHistory,
    selectIsLoading,
}
