import { MemberStatusType } from '@dltru/dfa-models'
import { FC, Fragment, useContext, useEffect, useState } from 'react'

import { Alert } from '../../../../Alert'
import { Box } from '../../../../Box'
import { Divider } from '../../../../Divider'
import { Space } from '../../../../Space'
import { Step, StepperContext, useEditable } from '../../../../Stepper'
import { Button } from '../../../../button'
import { CardDivider } from '../../../../card'
import { Form, WrappedInput, WrappedSelect } from '../../../../components/Form'
import { FioForm } from '../../FioForm'
import { memberStatusOptions } from '../../resources'
import { IRoleFormStepProps } from '../types'
import './style.less'
import { commonValidators } from '../../../../utils'

const STEP_INDEX = 6
const maxCount = 100

export const LegalEntityStep7: FC<IRoleFormStepProps> = ({ isEditAllow }) => {
    const [form] = Form.useForm()
    const listName = 'members'
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const [memberStatus, setMemberStatus] = useState<MemberStatusType[]>(
        (values?.[listName] as Array<{ status: MemberStatusType }>)?.map(({ status }) => status) ??
            [],
    )
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    useEffect(() => {
        if (!(values?.[listName] as Array<{ status: MemberStatusType }>)?.length) {
            form.setFieldsValue({ [listName]: [{}] })
        }
    }, [])

    const changeMemberStatus = (key: number) => (value: MemberStatusType) => {
        setMemberStatus((prevState) => {
            prevState[key] = value
            return [...prevState]
        })
    }

    const description = (
        <>
            <span>Для акционерного общества:</span>
            <ul className="description-list">
                <li>общее собрание акционеров акционерного общества;</li>
                <li>совет директоров (наблюдательный совет) акционерного общества;</li>
                <li>
                    единоличный исполнительный орган акционерного общества (директор, генеральный
                    директор);
                </li>
                <li>
                    коллегиальный исполнительный орган акционерного общества (правление, дирекция);
                </li>
            </ul>
            <span>Для Общества с ограниченной ответственностью: </span>
            <ul className="description-list">
                <li>общее собрание участников общества с ограниченной ответственностью;</li>
                <li>
                    совет директоров (наблюдательный совет) общества с ограниченной
                    ответственностью;
                </li>
                <li>
                    единоличный исполнительный орган общества с ограниченной ответственностью
                    (генеральный директор, президент и другие);
                </li>
                <li>
                    коллегиальный исполнительный орган общества с ограниченной ответственностью
                    (правление, дирекция и другие).
                </li>
            </ul>
        </>
    )

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Сведения об органах юридического лица"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem--xl"
                    multiline
                    label="Организационная структура юридического лица"
                    name="organizational_structure"
                    uneditable={isEdit}
                    required
                    maxLength={200}
                />
            </div>
            {!isEdit && (
                <Box margin={[0, 0, 0, 240]}>
                    <Alert type="info" showIcon description={description} banner width={600} />
                </Box>
            )}
            <Form.List name={listName}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            const props = {
                                ...restField,
                                listName,
                                uneditable: isEdit,
                                fieldKey: name,
                            }
                            return (
                                <Fragment key={key}>
                                    <Divider orientation="left" margin={[32, 0]}>
                                        <Space>
                                            Участник
                                            {fields.length > 1 && !isEdit && (
                                                <Button
                                                    type="link"
                                                    size="small"
                                                    onClick={() => remove(name)}
                                                >
                                                    Удалить
                                                </Button>
                                            )}
                                        </Space>
                                    </Divider>
                                    <div className="formRow">
                                        <WrappedSelect
                                            {...props}
                                            label="Статус участника"
                                            className="formComponentItem"
                                            name={[name, 'status']}
                                            options={memberStatusOptions}
                                            required
                                            onChange={changeMemberStatus(key)}
                                        />
                                    </div>
                                    {memberStatus[key] === MemberStatusType.MEMBER && (
                                        <div className="formRow">
                                            <WrappedInput
                                                {...props}
                                                className="formComponentItem"
                                                label="Должность"
                                                name={[name, 'job_title']}
                                                required
                                                rules={[
                                                    {
                                                        validator:
                                                            commonValidators.stringWithSpacesAndDash(),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    )}
                                    <FioForm {...props} noBottomDivider />
                                </Fragment>
                            )
                        })}
                        {!isEdit && fields?.length < maxCount && (
                            <>
                                <CardDivider />
                                <Button type="link" onClick={() => add()}>
                                    Добавить Участника
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Form.List>
        </Step>
    )
}
