import {
    ICursorPaginationResponse,
    IEmissionDeal,
    IInvestorDeals,
    IRequest,
    ITransferDeal,
    ITransferDealV2,
    ITransferDealV2Detail,
    UnionDealFront,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function getDfaDeals(query?: {
    user_id?: string
}): Promise<IRequest<IEmissionDeal[]>> {
    return requestDecorator.call(this, this.paths.DfaDeals, HttpMethod.GET, HttpCode.SUCCESS_200, {
        params: { ...query },
    })
}

export async function getSecondaryDfaDeals(query?: {
    user_id?: string
}): Promise<IRequest<IEmissionDeal[]>> {
    return requestDecorator.call(
        this,
        this.paths.SecondaryDfaDeals,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { ...query } },
    )
}

export async function getRepaymentDfaDeals(query?: {
    user_id?: string
}): Promise<IRequest<IEmissionDeal[]>> {
    return requestDecorator.call(
        this,
        this.paths.RepaymentDfaDeals,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { ...query } },
    )
}

export async function transferDealsService(
    params: Partial<ITransferDeal>,
): Promise<IRequest<ICursorPaginationResponse<ITransferDeal>>> {
    return requestDecorator.call(
        this,
        this.paths.TransfersV2,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getUserDeals(user_id: string): Promise<IRequest<UnionDealFront[]>> {
    const [issueDeals, secondaryDeals, repaymentDeals, transferDeals, transferDealsV2] =
        await Promise.all([
            this.lib.getDfaDeals({ user_id }),
            this.lib.getSecondaryDfaDeals({ user_id }),
            this.lib.getRepaymentDfaDeals({ user_id }),
            this.lib.getTransferDfaDeals({ user_id }),
            this.lib.transferDealsService({ user_uuid: user_id, limit: 90000 }),
        ])

    const data = []
        .concat(
            (issueDeals.data?.items ?? []).map((content) => ({
                type: 'emission',
                ...content,
            })),
        )
        .concat(
            (secondaryDeals.data?.items ?? []).map((content) => ({
                type: content.buy_order.user_id === user_id ? 'buy' : 'sell',
                ...content,
            })),
        )
        .concat(
            (repaymentDeals.data?.items ?? []).map((content) => ({
                type: 'repayment',
                ...content,
            })),
        )
        .concat(
            (transferDeals.data?.items ?? []).map((content: ITransferDeal) => {
                const assetData = content.order.seller_asset_data
                return {
                    type: content?.order?.buyer_id === user_id ? 'transfer_buy' : 'transfer_sell',
                    ...content,
                    ...content.order.seller_asset_data,
                    emitter_id: assetData.asset_emitter_id,
                    emitter_full_name: assetData.asset_emitter_full_name,
                }
            }),
        )
        .concat(
            (transferDealsV2.data?.items ?? [])
                .filter((content: ITransferDealV2) => content.type === 'transfer')
                .map((content) => {
                    const created_at = new Date(content.created_at).valueOf() / 1000
                    const any_data = content.any_data ? JSON.parse(content.any_data) : ''
                    return {
                        ...content,
                        amount_dfa: content.amount,
                        created_at,
                        executed_at: created_at,
                        any_data,
                        type: 'transferV2',
                    }
                }),
        )
        // Сортировка по времени по убыванию
        .sort((a, b) => b.executed_at - a.executed_at)

    return { data }
}

export async function getDealsListService(
    queryString: string,
): Promise<IRequest<ICursorPaginationResponse<IInvestorDeals>>> {
    const paramsWithOrder = {
        order: 'desc(id)',
    }
    return requestDecorator.call(
        this,
        `${this.paths.DealsList}${queryString}`,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: paramsWithOrder },
    )
}

export async function getDealByUuidService(
    uuid: string,
): Promise<IRequest<ICursorPaginationResponse<IInvestorDeals>>> {
    return requestDecorator.call(
        this,
        path(this.paths.DealByUuid, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getTransferDealsV2Detals({
    uuid,
    user_uuid,
}: {
    uuid: string
    user_uuid: string
}): Promise<IRequest<ITransferDealV2Detail>> {
    return requestDecorator.call(
        this,
        path(this.paths.TransfersV2DetailsByUuid, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { user_uuid } },
    )
}
