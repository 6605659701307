/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { UnionDealFront } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IBaseInitialStateList } from '../../types'

interface IInitialState extends IBaseInitialStateList {
    data: UnionDealFront[]
}

const initialState: IInitialState = {
    data: [],
    error: null,
    isLoading: false,
    filters: {},
    sort: {},
    range: {},
}

export namespace DfaDealsNS {
    export type GetDfaDealsById = PayloadAction<string>
    export type SetDfaDeals = PayloadAction<UnionDealFront[]>
    export type SetIsLoading = PayloadAction<boolean>
    export type SetError = PayloadAction<string | null>
    export type SetQuery = PayloadAction<Pick<IInitialState, 'filters' | 'sort' | 'range'>>
}

export const dfaDealsListSlice = createSlice({
    name: 'dfaDealsList',
    initialState,
    reducers: {
        setDfaDeals(state, action: DfaDealsNS.SetDfaDeals) {
            state.isLoading = false
            state.error = null
            state.data = action.payload
        },
        setIsLoading(state, action: DfaDealsNS.SetIsLoading) {
            state.isLoading = action.payload
        },
        setError(state, action: DfaDealsNS.SetError) {
            state.error = action.payload
        },
        setQuery(state, action: DfaDealsNS.SetQuery) {
            state.filters = action.payload?.filters
            state.sort = action.payload?.sort
            state.range = action.payload?.range
        },

        clearData(state) {
            state.filters = {}
            state.sort = {}
            state.range = {}
            state.data = []
            state.error = null
        },
        getDfaDealsById(_, action: DfaDealsNS.GetDfaDealsById) {},
    },
})

export default dfaDealsListSlice.reducer
