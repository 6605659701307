import cn from 'classnames'
import { FC, ReactNode } from 'react'

import '../style.less'

type TableFooterProps = {
    classList?: string
    left?: ReactNode
    right?: ReactNode
}

const TableFooter: FC<TableFooterProps> = ({ left, right, classList }) => (
    <div className={cn('table-dfa__footer', classList)}>
        {left && <div className="table-dfa__footer table-dfa__footer--left">{left}</div>}

        {right && <div className="table-dfa__footer table-dfa__footer--right">{right}</div>}
    </div>
)

export default TableFooter
