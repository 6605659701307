/**
 * 

 */
import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const BrifcaseTick: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.99999 15.1667C11.8409 15.1667 13.3333 13.6743 13.3333 11.8333C13.3333 9.99238 11.8409 8.5 9.99999 8.5C8.15904 8.5 6.66666 9.99238 6.66666 11.8333C6.66666 13.6743 8.15904 15.1667 9.99999 15.1667Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.70001 11.9167L9.24168 12.4583C9.40001 12.6167 9.65835 12.6167 9.81668 12.4667L11.3 11.1"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66667 18.3333H13.3333C16.6833 18.3333 17.2833 16.9917 17.4583 15.3583L18.0833 8.69167C18.3083 6.65833 17.725 5 14.1667 5H5.83333C2.275 5 1.69166 6.65833 1.91666 8.69167L2.54166 15.3583C2.71666 16.9917 3.31666 18.3333 6.66667 18.3333Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66666 4.99999V4.33332C6.66666 2.85832 6.66666 1.66666 9.33332 1.66666H10.6667C13.3333 1.66666 13.3333 2.85832 13.3333 4.33332V4.99999"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.0417 9.16666C16.6 10.2167 15 10.95 13.3417 11.3667"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.18332 9.39166C3.57499 10.3417 5.09165 11.0167 6.66665 11.4"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
