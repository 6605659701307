import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const RedoIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.0579 15.2581H7.39128C5.09128 15.2581 3.22461 13.3915 3.22461 11.0915C3.22461 8.79147 5.09128 6.9248 7.39128 6.9248H16.5579"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6426 9.00837L16.7759 6.87503L14.6426 4.7417"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
