import { UneditableUploadedFiles } from '@components/Form'
import { BaseModal } from '@components/Modals/Deals/View/Base'
import {
    BaseDescriptionEnum,
    BaseDescriptionTranslate,
    ITransferDealV2Detail,
} from '@dltru/dfa-models'
import { Amount, BodyTitle, DateTime, Divider, LabelRow as Row } from '@dltru/dfa-ui'
import api from '@services/api'
import { FC, useEffect, useState } from 'react'
import { create, InstanceProps } from 'react-modal-promise'
import { Link } from 'react-router-dom'

interface TransferV2ModalProps extends InstanceProps<boolean> {
    uuid: string
    user_uuid: string
}

const TransferV2Modal: FC<TransferV2ModalProps> = ({ uuid, user_uuid }) => {
    const [deal, setDeal] = useState<
        ITransferDealV2Detail & { anyData: { document_uuid: string; description: string } }
    >()

    useEffect(() => {
        const getDetails = async () => {
            const { data } = await api.lib.getTransferDealsV2Detals({ uuid, user_uuid })

            if (data) {
                const anyData = JSON.parse(data.any_data)

                setDeal({ ...data, anyData, type: 'transferV2' })
            }
        }

        getDetails()
    }, [])

    return (
        <>
            {deal && (
                <BaseModal deal={{ ...deal }}>
                    <BodyTitle title="Переход прав на ЦФА" id={deal.uuid} />
                    <Row label="Дата и время сделки" className="label-row__space">
                        {deal?.created_at && (
                            <DateTime value={new Date(deal.created_at).valueOf() / 1000} />
                        )}
                    </Row>
                    {/*<Row label="Оферта" className="label-row__space">
                <Button className="link-button" type="link" onClick={linkToTransfer}>
                    <span>ID {deal.order.global_id}</span>
                </Button>
            </Row>*/}
                    <Divider />
                    {deal?.emitter_id && (
                        <Row label="Прежний владелец" className="label-row__space">
                            <Link to={`/clients/${deal?.from_uuid}`}>{deal?.from_full_name}</Link>
                        </Row>
                    )}

                    {
                        <Row label="Новый владелец" className="label-row__space">
                            <Link to={`/clients/${deal?.to_uuid}`}>{deal?.to_full_name}</Link>
                        </Row>
                    }
                    <Divider />
                    {deal?.amount && (
                        <Row label="Количество, ед." className="label-row__space">
                            <Amount value={deal.amount} />
                        </Row>
                    )}
                    <Divider />
                    {deal.any_data && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Row label="Основание" className="label-row__space"></Row>
                            <p>
                                {
                                    BaseDescriptionTranslate[
                                        deal.anyData.description as BaseDescriptionEnum
                                    ]
                                }
                            </p>
                        </div>
                    )}
                    {deal.any_data && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Row label="Файлы" className="label-row__space"></Row>
                            <UneditableUploadedFiles
                                files={[{ uid: deal.anyData.document_uuid }]}
                            />
                        </div>
                    )}
                </BaseModal>
            )}
        </>
    )
}

export const transferV2Modal = create(TransferV2Modal)
