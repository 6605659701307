import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ArchiveMinus: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.0832 8.875H7.9165"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.0166 1.66663H5.98327C4.20827 1.66663 2.7666 3.11663 2.7666 4.88329V16.625C2.7666 18.125 3.8416 18.7583 5.15827 18.0333L9.22493 15.775C9.65827 15.5333 10.3583 15.5333 10.7833 15.775L14.8499 18.0333C16.1666 18.7666 17.2416 18.1333 17.2416 16.625V4.88329C17.2333 3.11663 15.7916 1.66663 14.0166 1.66663Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
