import { Saga } from '@redux-saga/types'
import { all, fork } from 'typed-redux-saga'

import * as accountSau from './accountSau/sagas'
import * as authSagas from './auth/sagas'
import * as blockingSagas from './blocking/sagas'
import * as certificatesSagas from './certificates/sagas'
import * as clientSagas from './clients/sagas'
import * as clientsRegistrySagas from './clientsRegistry/clientsRegistrySagas'
import * as dfaDealsSagas from './dfa/deals/sagas'
import * as dfaDetailsSagas from './dfa/details/sagas'
import * as dfaListSagas from './dfa/list/sagas'
import * as dfaOwnersListSagas from './dfa/owners/sagas'
import * as dfaOwnershipTransfer from './dfa/ownershipTransfer/sagas'
import * as documentsSagas from './documents/sagas'
import * as employeeSagas from './employee/sagas'
import * as investorsDealsSagas from './investorsDeals/sagas'
import * as moneyAccountsSagas from './money/accounts/sagas'
import * as nominalAccountsSagas from './nominalAccounts/sagas'
import * as notificationCounterSagas from './notificationCounter/sagas'
import * as orderListSagas from './orders/list/sagas'
import * as transfersOrderSagas from './orders/transfer/sagas'
import * as paymentOrdersSagas from './paymentOrders/sagas'
import * as qualifyStatementSagas from './qualifyStatement/sagas'
import * as registrationsRequestSagas from './registrationRequests/sagas'
import * as secondaryDfaDealsListSagas from './secondaryDfa/deals/sagas'
import * as secondaryDfaListSagas from './secondaryDfa/list/sagas'
import * as tariff from './tariff/sagas'
import * as tariffRelatedAssetsSagas from './tariffRelatedAssets/sagas'
import * as tariffRelatedUsersSagas from './tariffRelatedUsers/sagas'
import * as walletAssetsSagas from './wallet/assets/sagas'
import * as walletEncumbranceSagas from './wallet/encumbrance/sagas'
import * as walletHistorySagas from './wallet/history/sagas'
import * as walletLocksSagas from './wallet/locks/sagas'

function* sagas(): Generator<unknown, any, unknown> {
    const watchers = Object.values({
        ...authSagas,
        ...dfaListSagas,
        ...dfaDealsSagas,
        ...dfaDetailsSagas,
        ...orderListSagas,
        ...employeeSagas,
        ...secondaryDfaListSagas,
        ...secondaryDfaDealsListSagas,
        ...registrationsRequestSagas,
        ...clientSagas,
        ...dfaOwnersListSagas,
        ...moneyAccountsSagas,
        ...walletAssetsSagas,
        ...walletHistorySagas,
        ...blockingSagas,
        ...transfersOrderSagas,
        ...dfaOwnershipTransfer,
        ...paymentOrdersSagas,
        ...walletLocksSagas,
        ...clientsRegistrySagas,
        ...certificatesSagas,
        ...walletEncumbranceSagas,
        ...investorsDealsSagas,
        ...notificationCounterSagas,
        ...documentsSagas,
        ...qualifyStatementSagas,
        ...nominalAccountsSagas,
        ...accountSau,
        ...tariff,
        ...tariffRelatedUsersSagas,
        ...tariffRelatedAssetsSagas,
    }) as Saga[]
    yield* all(watchers.map((watcher) => fork(watcher)))
}

export default sagas
