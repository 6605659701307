export enum AddressElements {
    postIndex = 'postIndex',
    region = 'region',
    district = 'district',
    locality = 'locality',
    street = 'street',
    house = 'house',
    building = 'building',
    buildingPart = 'buildingPart',
    place = 'place',
}
