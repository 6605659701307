import { Box, Divider, Form, WrappedInput } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateExchangeOperatorAction } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import { ChangeStatusModalContainer } from '../../ChangeStatusModalContainer/ChangeStatusModalContainer'
import { ChangeOperatorStatusForm, ChangeOperatorStatusModalResources, ModalType } from '../types'
import style from './changeOperatorStatusModal.m.less'

type ChangeOperatorStatusModalProps = {
    type: ModalType
    isShow: boolean
    onClose: () => void
}

export const ChangeOperatorStatusModal: FC<ChangeOperatorStatusModalProps> = ({
    type,
    isShow,
    onClose,
}) => {
    const reduxDispatch = useDispatch()
    const profile = useSelector(clientsSelector.selectProfile)
    const isNode = useSelector(clientsSelector.selectIsNode)
    const [isClear, setIsClear] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        return () => {
            form.resetFields()
        }
    }, [])

    const onCancelHandler = () => {
        form.resetFields()
        setIsClear(true)
        onClose()
    }

    const onSubmitHandler = (values: ChangeOperatorStatusForm) => {
        if (profile?.user_uuid) {
            reduxDispatch(
                updateExchangeOperatorAction({
                    user_uuid: profile?.user_uuid,
                    is_node: isNode,
                    is_exchange_operator: type !== 'delete',
                    reason_description: values.reasonText,
                    reason_link_uuid: values.reasonDocs[0].uid,
                }),
            )
        }
        onCancelHandler()
    }

    const resourcesModalByType = ChangeOperatorStatusModalResources[type]
    const status = type === 'delete' ? 'Оператор обмена' : ''

    return (
        <ChangeStatusModalContainer
            status={status}
            isShow={isShow}
            onCancel={onCancelHandler}
            onSubmit={() => {
                form.submit()
            }}
            {...resourcesModalByType}
        >
            <Form form={form} layout="vertical" initialValues={{}} onFinish={onSubmitHandler}>
                <Divider />
                <Box margin={[0, 0, 24, 0]}>
                    <h4 className={style.headerBold}>{resourcesModalByType.reasonTitle}</h4>
                </Box>
                <UploadDocumentContainerWrapper
                    name="reasonDocs"
                    title="Загрузите документы"
                    required
                    isClear={isClear}
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="reasonText"
                        multiline
                        required
                    />
                </UploadDocumentContainerWrapper>
            </Form>
        </ChangeStatusModalContainer>
    )
}
