import { Button, Modal } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { toCollectionStartedDfa } from '@store/dfa/details'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const PublicationDfaModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const dispatch = useDispatch()

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleOk = () => {
        dispatch(toCollectionStartedDfa())
        setIsModalVisible(false)
    }

    return (
        <Modal
            title="Опубликовать на Витрине предложений"
            visible={isModalVisible}
            onCancel={handleCancel}
            width={423}
            footer={[
                <Button borderRadius={16} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={handleOk}>
                    Да, опубликовать
                </Button>,
            ]}
        >
            <div>
                <p>Вы действительно хотите опубликовать выпуск на Витрине предложений?</p>
            </div>
        </Modal>
    )
}
