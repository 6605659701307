import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CloseCircleIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 13.6666C10.6819 13.6666 13.6667 10.6818 13.6667 6.99992C13.6667 3.31802 10.6819 0.333252 7 0.333252C3.3181 0.333252 0.333328 3.31802 0.333328 6.99992C0.333328 10.6818 3.3181 13.6666 7 13.6666ZM4.49605 4.49597C4.713 4.27901 5.06476 4.27901 5.28172 4.49597L7 6.21424L8.71827 4.49597C8.93523 4.27901 9.28699 4.27901 9.50394 4.49597C9.7209 4.71293 9.7209 5.06469 9.50394 5.28164L7.78567 6.99992L9.50394 8.71819C9.7209 8.93515 9.7209 9.28691 9.50394 9.50387C9.28699 9.72083 8.93523 9.72083 8.71827 9.50387L7 7.78559L5.28172 9.50387C5.06476 9.72083 4.713 9.72083 4.49605 9.50387C4.27909 9.28691 4.27909 8.93515 4.49605 8.71819L6.21432 6.99992L4.49605 5.28164C4.27909 5.06469 4.27909 4.71293 4.49605 4.49597Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
