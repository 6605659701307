import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ProgrammingArrows: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.0833 9.33333V3.79167C11.0833 3.15 10.5583 2.625 9.91668 2.625H6.70834"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.16666 1.1665L6.41666 2.62484L8.16666 4.08317"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.0833 12.8335C12.0498 12.8335 12.8333 12.05 12.8333 11.0835C12.8333 10.117 12.0498 9.3335 11.0833 9.3335C10.1168 9.3335 9.33334 10.117 9.33334 11.0835C9.33334 12.05 10.1168 12.8335 11.0833 12.8335Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.91666 4.6665V10.2082C2.91666 10.8498 3.44166 11.3748 4.08332 11.3748H7.29166"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83334 12.8332L7.58334 11.3748L5.83334 9.9165"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.91666 4.6665C3.88315 4.6665 4.66666 3.883 4.66666 2.9165C4.66666 1.95001 3.88315 1.1665 2.91666 1.1665C1.95016 1.1665 1.16666 1.95001 1.16666 2.9165C1.16666 3.883 1.95016 4.6665 2.91666 4.6665Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
