import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import { signData } from '@store/helper'

import api from '@services/api'

import { makeDFAInjunction, makeDFALiftingOfInjunction, updateBlocking } from './index'

function* handleDFAInjunction({ payload }: ReturnType<typeof makeDFAInjunction>) {
    try {
        const { error, data } = yield api.lib.makeDFAInjunctionV2Service(payload)
        if (error) {
            throw new Error(error)
        }

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
        }
        openMessage({
            type: 'success',
            message: 'Запрос о блокировкe обработан успешно. Осуществляется процедура блокировки',
        })
    } catch (error: any) {
        openMessage({
            type: 'error',
            message: 'Не удалось установить блокировку ЦФА',
        })
        yield* put(updateBlocking({ error: error.message, isLoading: false }))
    }
}

function* handleDFALiftingOfInjunction({ payload }: ReturnType<typeof makeDFALiftingOfInjunction>) {
    try {
        const { error, data } = yield api.lib.makeDFAInjunctionRemoveV2Service(payload)
        if (error) {
            throw new Error(error)
        }

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
        }
        openMessage({
            type: 'success',
            message:
                'Запрос на снятие блокировки обработан успешно. Осуществляется процедура снятия блокировки',
        })
    } catch (error: any) {
        openMessage({
            type: 'error',
            message: 'Не удалось снять блокировку ЦФА',
        })
        yield* put(updateBlocking({ error: error.message, isLoading: false }))
    }
}

export function* watchBlockingSaga(): Generator<StrictEffect> {
    yield* takeLatest(makeDFAInjunction.type, handleDFAInjunction)
    yield* takeLatest(makeDFALiftingOfInjunction.type, handleDFALiftingOfInjunction)
}
