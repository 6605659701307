import { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'

import { AgentProfileFormValues, DocumentType, IAgentProfile } from '../../models'
import { getDateWithoutTime, getDateWithoutTimeString } from '../../utils'
import { getInitialFile, mapFilesList } from '../common'
import { getMigrationDocFromBackend, getMigrationDocFromForm } from './common'

export const agentFrontToBack = (valuesFromForm: AgentProfileFormValues): IAgentProfile => {
    const location_address =
        valuesFromForm['registration_address.fact'] ||
        valuesFromForm['registration_address.registration']
    const post_address = valuesFromForm['registration_address.index']

    const passportData =
        valuesFromForm.documentType === DocumentType.passport
            ? {
                  document_type: valuesFromForm.documentType,
                  passport: {
                      series: valuesFromForm.PassportSeries,
                      number: valuesFromForm.PassportNumber,
                      issue_date: valuesFromForm.issueDate?.unix(),
                      issued_by: valuesFromForm.PassportIssuer,
                      issuer_department_code: valuesFromForm.PassportCode,
                      file_uuid: valuesFromForm.PassportPages?.map(({ uid }) => uid).join(),
                  },
              }
            : { document_type: valuesFromForm.documentType }

    return {
        person: {
            first_name: valuesFromForm.first_name,
            last_name: valuesFromForm.last_name,
            second_name: valuesFromForm.second_name,
            birthdate: getDateWithoutTime(valuesFromForm.birthdate) as unknown as number,
            place_birth: valuesFromForm.place_birth,
            citizenship: valuesFromForm.citizenship,
            registration_address: valuesFromForm['registration_address.registration'],
            location_address,
            post_address,
            inn: valuesFromForm.inn,
            snils: valuesFromForm.snils,
            snils_file_uuid: valuesFromForm.snils_file_uuid?.[0]?.uid,
            ...passportData,
        },
        approval_document: {
            title: valuesFromForm.approval_document_title,
            number: valuesFromForm.approval_document_number,
            issue_date: valuesFromForm.approval_document_issue_date.unix(),
            expiration_date: valuesFromForm.approval_document_expiration_date.unix(),
            comment: valuesFromForm.approval_document_comment,
            file_uuid: valuesFromForm.approval_document_file_uuid?.[0].uid,
        },
        ...getMigrationDocFromForm(valuesFromForm),
    }
}

export const agentBackToFront = (valuesFromBackend: IAgentProfile): AgentProfileFormValues => {
    const passportData =
        valuesFromBackend.person?.document_type === DocumentType.passport
            ? {
                  documentType: valuesFromBackend.person.document_type,
                  PassportSeries: valuesFromBackend.person.passport.series,
                  PassportNumber: valuesFromBackend.person.passport.number,
                  issueDate: moment.unix(valuesFromBackend.person.passport.issue_date),
                  PassportCode: valuesFromBackend.person.passport?.issuer_department_code,
                  PassportPages: mapFilesList(
                      'PassportPages',
                      valuesFromBackend.person.passport?.file_uuid,
                  ),
                  PassportIssuer: valuesFromBackend.person.passport?.issued_by,
              }
            : {
                  documentType: valuesFromBackend.person.document_type,
              }

    return {
        id: valuesFromBackend.id,
        citizenship: valuesFromBackend.person.citizenship,
        ...getMigrationDocFromBackend(valuesFromBackend),
        approval_document_comment: valuesFromBackend.approval_document.comment,
        approval_document_expiration_date: moment.unix(
            valuesFromBackend.approval_document.expiration_date,
        ),
        approval_document_file_uuid: getInitialFile(valuesFromBackend.approval_document.file_uuid),
        approval_document_issue_date: moment.unix(valuesFromBackend.approval_document.issue_date),
        approval_document_number: valuesFromBackend.approval_document.number,
        approval_document_title: valuesFromBackend.approval_document.title,
        birthdate: getDateWithoutTimeString(valuesFromBackend.person.birthdate),
        ...passportData,
        first_name: valuesFromBackend.person.first_name,
        last_name: valuesFromBackend.person.last_name,
        second_name: valuesFromBackend.person.second_name,
        inn: valuesFromBackend.person.inn,
        snils: valuesFromBackend.person.snils,
        snils_file_uuid: getInitialFile(valuesFromBackend.person.snils_file_uuid) as UploadFile[],
        place_birth: valuesFromBackend.person.place_birth,
        'registration_address.registration': valuesFromBackend.person.registration_address,
        'registration_address.fact': valuesFromBackend.person.location_address,
        'registration_address.index': valuesFromBackend.person.post_address,
    }
}
