import { ITransferDeal } from '@dltru/dfa-models'
import { Amount, BodyTitle, Button, DateTime, Divider, LabelRow as Row } from '@dltru/dfa-ui'
import React from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { Link, useNavigate } from 'react-router-dom'

import { BaseModal } from './Base'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: ITransferDeal
}

const TransferModal: React.FC<IDealViewModal> = ({ deal, onResolve }) => {
    const navigate = useNavigate()
    const linkToTransfer = () => {
        onResolve()
        navigate(`/ownership-transfer-request/${deal.order.global_id}`)
    }
    return (
        <BaseModal deal={{ ...deal, type: 'transfer' }} onResolve={onResolve}>
            <BodyTitle title="Детали передачи ЦФА по Оферте" id={deal.id} />
            <Row label="Дата и время сделки" className="label-row__space">
                <DateTime value={deal.executed_at} />
            </Row>
            <Row label="Оферта" className="label-row__space">
                <Button className="link-button" type="link" onClick={linkToTransfer}>
                    <span>ID {deal.order.global_id}</span>
                </Button>
            </Row>
            <Divider />
            <Row label="Отправитель" className="label-row__space">
                <Link to={`/clients/${deal?.order?.seller_id}`}>
                    {deal?.order?.seller_full_name}
                </Link>
            </Row>

            <Row label="Получатель" className="label-row__space">
                <Link to={`/clients/${deal?.order?.buyer_id}`}>{deal?.order?.buyer_full_name}</Link>
            </Row>
            <Divider />
            <Row label="Количество, ед." className="label-row__space">
                <Amount value={deal.amount_dfa} />
            </Row>
            {/* TODO комисии буду позже */}
            {/*
            <Divider />
            <Row label="Комиссии" />
            <Row label="Размещение заявки на Витрине предложений"><Price /></Row>
            <Row label="Регистрация перехода прав на ЦФА (Владелец)"><Price /></Row>
            <Row label="Регистрация перехода прав на ЦФА (Получатель)"><Price /></Row>*/}
        </BaseModal>
    )
}

export const transferModal = create(TransferModal)
