// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clientTagsWrapper_fkNVg .dfa-tag.ant-tag {\n  position: relative;\n  top: -4px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/ClientTags/clientTags.m.less"],"names":[],"mappings":"AAAA;EAGM,kBAAA;EACA,SAAA;AADN","sourcesContent":[".clientTagsWrapper {\n  :global {\n    .dfa-tag.ant-tag {\n      position: relative;\n      top: -4px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientTagsWrapper": "clientTagsWrapper_fkNVg"
};
export default ___CSS_LOADER_EXPORT___;
