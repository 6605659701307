import { BindBankAccountModel, NominalAccountModel } from '@dltru/dfa-models'
import { MoneyNominalAccount } from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import api from '@services/api'

import { RelateAccountModal } from '../../Footer/modals/RelateAccountModal'

export const RegistrationNominalAccountBlock: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [nominalAccount, setNominalAccount] = useState<NominalAccountModel>()
    const profile = useSelector(clientsSelector.selectProfile)
    const reduxDispatch = useDispatch()

    const getNominalAccount = async () => {
        const { data } = await api.lib.getNominalAccountById(
            profile?.nominal_account_uuid as string,
        )
        if (data) {
            setNominalAccount(data)
        }
    }
    useEffect(() => {
        if (profile?.nominal_account_uuid) {
            getNominalAccount()
        }
    }, [profile?.nominal_account_uuid])
    if (!profile?.nominal_account_uuid) {
        return null
    }

    const saveNominalAccount = (data: BindBankAccountModel) => {
        reduxDispatch(
            clientsSlice.actions.patchCurrentProfile({
                nominal_account_uuid: data.nominal_account_uuid,
            }),
        )
    }

    return (
        <>
            <MoneyNominalAccount
                balanceInfo={{ nominal_account: nominalAccount }}
                changeAction={() => setIsModalVisible(true)}
            />
            <RelateAccountModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={saveNominalAccount}
                balanceInfo={{ nominal_account: nominalAccount }}
            />
        </>
    )
}
