import { Table } from '@dltru/dfa-ui'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { exchangeOperatorsGridConfig } from './exchangeOperatorsGridConfig'

export const ExchangeOperatorsTable = () => {
    const navigate = useNavigate()

    const onRow = (record: any) => ({
        onClick: () => {
            navigate(`/clients/${record.user_uuid}`)
        },
    })

    return (
        <Table
            isLoading={false}
            columns={exchangeOperatorsGridConfig()}
            dataSource={[
                {
                    user_uuid: 'string',
                    status: 'string',
                    status_date: 'string',
                },
            ]}
            scroll={{ y: undefined }}
            onRow={onRow}
            clickableRow
        />
    )
}
