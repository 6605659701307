import { DfaStatusEnum, PriceSource, SettlementsType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CheckCircleOutlineIcon } from '@dltru/dfa-ui'
import { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { ConfirmToIssuerModal } from '../Modals/ConfirmToIssuerModal'

export const ConfirmToIssuerAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const { isAllow } = useContext(RbacContext)
    const [isDisableBtn, setIsDisabledBtn] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const showButton =
        dfaDetails.status === DfaStatusEnum.preliminary_check && isAllow(['approval.operator'])

    useEffect(() => {
        if (dfaDetails.price_source_type === PriceSource.static) {
            setIsDisabledBtn(
                !(
                    typeof dfaDetails.original_amount === 'number' &&
                    typeof dfaDetails.price_per_dfa === 'number' &&
                    typeof dfaDetails.redeem_price_per_dfa === 'number' &&
                    (dfaDetails.repayment_settlements_type !== SettlementsType.platform ||
                        dfaDetails.redeem_date) &&
                    dfaDetails.type_of_law
                ),
            )
        } else {
            setIsDisabledBtn(
                !(
                    typeof dfaDetails.max_supply_invested === 'number' &&
                    typeof dfaDetails.min_supply_invested === 'number' &&
                    typeof dfaDetails.investment_spread === 'number' &&
                    typeof dfaDetails.redemption_spread === 'number' &&
                    (dfaDetails.repayment_settlements_type !== SettlementsType.platform ||
                        dfaDetails.redeem_date)
                ),
            )
        }
    }, [dfaDetails])

    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<CheckCircleOutlineIcon />}
                type="primary"
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
                disabled={isDisableBtn}
            >
                На подтверждение эмитенту
            </Button>
            <ConfirmToIssuerModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
