import { PageHeader as PageHeaderAntd, PageHeaderProps as PageHeaderPropsAntd } from 'antd'
import cn from 'classnames'
import React, { FC } from 'react'

import { ArrowLeft } from '../../Icons'
import './style.less'

export type PageHeaderProps = PageHeaderPropsAntd
export const PageHeader: FC<PageHeaderProps> = (props) => {
    const className = cn('page-header-dfa', {
        'page-header-dfa--with-back': props.onBack,
    })
    return (
        <PageHeaderAntd
            {...props}
            className={className}
            subTitle={undefined}
            footer={props.subTitle}
            backIcon={<ArrowLeft />}
        />
    )
}
