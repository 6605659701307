import { DocumentType } from '@dltru/dfa-models'
import {
    CardDivider,
    DateAndTimeItem,
    WrappedInput,
    WrappedInputProps,
    WrappedSelect,
    commonValidators,
    documentTypeOptions,
    documentValidators,
    onlyDigits,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import { UploadInputForm } from './UploadInputForm'
import './style.less'

type DocumentFormProps = Partial<Omit<WrappedInputProps, 'onChange'>> & {
    group?: string
}

export const DocumentForm: FC<DocumentFormProps> = ({ group, fieldKey, ...restProps }) => {
    const [documentType, setDocumentType] = useState(DocumentType.passport)
    const getNameForField = (fieldName: string) => {
        if (group) {
            return `${group}.${fieldName}`
        }
        if (typeof fieldKey === 'number') {
            return [fieldKey, fieldName]
        }
        return fieldName
    }

    return (
        <>
            <div className="formRow">
                <WrappedSelect
                    {...restProps}
                    fieldKey={fieldKey}
                    onChange={(val) => {
                        setDocumentType(val as DocumentType)
                    }}
                    required
                    label="Документ, удостоверяющий личность"
                    className="formComponentItem"
                    name={getNameForField('documentType')}
                    options={documentTypeOptions}
                    initialValue={DocumentType.passport}
                ></WrappedSelect>
            </div>

            {documentType === DocumentType.passport && (
                <div className="vertical-inputs" style={{ marginLeft: 240 }}>
                    <div className="form-inputs-row">
                        <WrappedInput
                            {...restProps}
                            fieldKey={fieldKey}
                            className="formComponentItem--sm80 no-error-messages"
                            label="Серия"
                            name={getNameForField('PassportSeries')}
                            placeholder="____"
                            required
                            rules={[{ validator: commonValidators.number(4) }]}
                            maxLength={4}
                            normalize={onlyDigits}
                        />
                        <WrappedInput
                            {...restProps}
                            fieldKey={fieldKey}
                            className="formComponentItem--sm136 no-error-messages"
                            label="Номер"
                            name={getNameForField('PassportNumber')}
                            placeholder="______"
                            required
                            rules={[{ validator: commonValidators.number(6) }]}
                            maxLength={6}
                            normalize={onlyDigits}
                        />
                        <DateAndTimeItem
                            {...restProps}
                            fieldKey={fieldKey}
                            className="formComponentItem--sm136 no-error-messages"
                            label="Дата выдачи"
                            name={getNameForField('issueDate')}
                            onlyPast
                            required
                            disableTime
                        />
                        <WrappedInput
                            {...restProps}
                            fieldKey={fieldKey}
                            className="formComponentItem--sm136 no-error-messages"
                            label="Код подразделения"
                            name={getNameForField('PassportCode')}
                            placeholder="___-___"
                            required
                            rules={[{ validator: documentValidators.passportIssuerCode }]}
                            format="###-###"
                            mask="_"
                        />
                    </div>

                    <div className="form-inputs-row">
                        <WrappedInput
                            {...restProps}
                            fieldKey={fieldKey}
                            className="no-error-messages"
                            label="Кем выдан"
                            name={getNameForField('PassportIssuer')}
                            style={{ width: '540px' }}
                            placeholder=""
                            multiline
                            required
                            rules={[{ validator: documentValidators.passportIssuer }]}
                        />
                    </div>
                    <div className="formRow">
                        <UploadInputForm
                            {...restProps}
                            fieldKey={fieldKey}
                            name={getNameForField('PassportPages')}
                            noWrapper
                            required
                            title="Допустимый формат файлов doc, docx, pdf, png, jpeg. Общий вес не более 25 МБ"
                            maxSizeMb={25}
                            accept=".doc,.docx,.pdf,.png,.jpeg"
                        />
                    </div>
                </div>
            )}

            <CardDivider />
        </>
    )
}
