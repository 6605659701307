/* eslint-disable no-console */
import {
    BeneficialOwner,
    IApprovalHistoryItem,
    IRequest,
    MoneyAccountType,
    PrivateInformationLegalEntityResponse,
    ProfileType,
    ResponseItem,
} from '@dltru/dfa-models'
import { errorTranslates, openMessage, openNotification } from '@dltru/dfa-ui'
import { isAxiosError } from '@utils/typeGuard'
import dayjs from 'dayjs'
import { StrictEffect } from 'redux-saga/effects'
import { all, call, put, select, takeLatest } from 'typed-redux-saga'

import { signData } from '@store/helper'

import api from '@services/api'

import {
    bindMoneySpecialAccount,
    checkClientForActiveSession,
    clientsSlice,
    createQualifiedRegistry,
    deleteQualifiedRegistry,
    getPrivateProfile,
    makeBlockAction,
    makeForcedEndOfSessionAction,
    makeFullBlockAction,
    makeFullUnblockAction,
    makePartlyBlockAction,
    makePartlyUnblockAction,
    makeUnblockAction,
    updateExchangeOperatorAction,
    updateRiskLevel,
} from '../index'
import { clientsSelector } from '../selectors'

export function* getClientByUuid(user_uuid?: string) {
    if (user_uuid) {
        const { error, data } = yield* call(api.lib.getFullUser, user_uuid)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.item) {
            yield* put(clientsSlice.actions.setCurrentClient(data.item))
        }
    }
}

function* getCurrentClient({ payload }: ReturnType<typeof clientsSlice.actions.getCurrentClient>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        yield* call(getClientByUuid, payload)
        const { data: statuses } = yield* call(api.lib.getStatusesProfileService, payload)
        yield* put(clientsSlice.actions.setApprovalHistory(statuses?.items ?? []))
    } catch (error) {
        console.error(error)
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getApprovalHistoryRepresentative() {
    try {
        const agent = yield* select(clientsSelector.selectProfileAgent)
        if (agent?.uuid) {
            const { data: statuses } = yield* call(api.lib.getStatusesProfileService, agent.uuid)
            yield* put(clientsSlice.actions.setApprovalHistoryRepresentative(statuses?.items ?? []))
        }
    } catch (error) {
        console.error(error)
    }
}

function* getOldApprovalHistoryRepresentative() {
    try {
        const approvalHistoryAgentOld = yield* select(
            clientsSelector.selectOldCurrentClientApprovalHistoryAgent,
        )
        if (approvalHistoryAgentOld.length) {
            return
        }
        const agent = yield* select(clientsSelector.selectProfileAgent)
        const approvalHistoryProfile = yield* select(
            clientsSelector.selectCurrentClientApprovalHistory,
        )
        const currentAgentUuid = agent?.uuid
        const oldAgentUuids = new Set<string>()
        approvalHistoryProfile.forEach((item) => {
            if (item.agent_uuid && item.agent_uuid !== currentAgentUuid) {
                oldAgentUuids.add(item.agent_uuid)
            }
        })
        if (oldAgentUuids.size) {
            const result = yield* all(
                [...oldAgentUuids].map((uuid) => api.lib.getStatusesProfileService(uuid)),
            )
            yield* put(
                clientsSlice.actions.setOldApprovalHistoryRepresentative(
                    (result as { data: { items: IApprovalHistoryItem[] } }[]).reduce(
                        (acc, item) => {
                            if (item?.data?.items?.length) {
                                acc.push(...item.data.items)
                            }
                            return acc
                        },
                        [] as IApprovalHistoryItem[],
                    ),
                ),
            )
        }
    } catch (error) {
        console.error(error)
    }
}

function* handleCheckClientForActiveSession({
    payload,
}: ReturnType<typeof checkClientForActiveSession>) {
    try {
        const { error, data } = yield* call(api.lib.checkClientForActiveSessionService, payload)

        if (error) {
            throw new Error(error?.error?.code)
        }
        if (data?.code === 'ERR_NOT_FOUND') {
            yield* put(clientsSlice.actions.setIsCurrentClientActive(false))
        } else {
            yield* put(clientsSlice.actions.setIsCurrentClientActive(true))
        }
    } catch (error) {
        console.error(error)
    }
}

function* changeClientStatus({
    payload,
}: ReturnType<typeof clientsSlice.actions.changeClientStatus>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))

        const { error } = yield* call(api.lib.changeAnketaStatusService, {
            user_uuid: payload.profileUuid,
            profile_status: payload.status,
            approval_datetime: dayjs().unix(),
            profile_type: payload.profileType,
            comment: payload.comment,
        })

        if (error) {
            throw error
        }
        yield* put(clientsSlice.actions.getCurrentClient(payload.userUuid))
        if (payload.profileType === ProfileType.AGNT) {
            yield* put(clientsSlice.actions.getApprovalHistoryRepresentative())
        }
        openMessage({
            type: 'success',
            message: 'Статус анкеты успешно изменен',
        })
    } catch (error) {
        yield* put(clientsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при смене статуса анкеты',
        })
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}
function* updateBalance(account?: MoneyAccountType) {
    if (account) {
        const { error, data } = yield* call(api.lib.getMoneyAccountsBalance, account.id)

        if (error || !data) {
            openMessage({
                type: 'error',
                message: 'Не удалось получить данные по балансу',
            })
        } else {
            const oldMoneyAccount = yield* select(clientsSelector.selectBalanceInfo)
            yield* put(clientsSlice.actions.setMoneyAccount({ ...oldMoneyAccount, ...data }))
        }
        yield* put(clientsSlice.actions.getTransactions({ account_id: account.id }))
    }
}

function* transactionCreditCurrentClient({
    payload,
}: ReturnType<typeof clientsSlice.actions.transactionCreditCurrentClient>) {
    try {
        const account = yield* select(clientsSelector.selectBalanceInfo)
        if (account) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error: sendCreditError } = yield* call(api.lib.sendCredit, {
                account_id: account.id,
                ...payload,
            })
            if (sendCreditError) {
                throw sendCreditError
            }
            yield* call(updateBalance, account)
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить пополнение',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* reserveReleaseCurrentClient({
    payload,
}: ReturnType<typeof clientsSlice.actions.reserveReleaseCurrentClient>) {
    try {
        const currentClientId = yield* select(clientsSelector.selectCurrentClientId)
        const account = yield* select(clientsSelector.selectBalanceInfo)
        if (currentClientId) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error, data } = yield* call(api.lib.moneyReserveReleaseTransferSauToBank, {
                ...payload,
                user_id: currentClientId,
            })

            if (error || data?.error) {
                throw error ?? data?.error
            }
            yield* call(updateBalance, account)
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить списание',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* reserveTransferCurrentClient({
    payload,
}: ReturnType<typeof clientsSlice.actions.reserveTransferCurrentClient>) {
    try {
        const currentClientId = yield* select(clientsSelector.selectCurrentClientId)
        const account = yield* select(clientsSelector.selectBalanceInfo)
        if (currentClientId) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error, data } = yield* call(api.lib.moneyReserveTransfer, {
                ...payload,
                sender_id: currentClientId,
            })

            if (error || data?.error) {
                throw error ?? data?.error
            }
            yield* call(updateBalance, account)
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить списание',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getClients({ payload }: ReturnType<typeof clientsSlice.actions.getClients>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getProfilesService, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.item) {
            yield* put(clientsSlice.actions.setClientList(data.item))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getClientsBlockings({
    payload,
}: ReturnType<typeof clientsSlice.actions.getClientsBlockings>) {
    try {
        yield* put(clientsSlice.actions.setIsBlockingsListLoading(true))
        const { error, data } = yield* call(api.lib.getUsersBlockingEvents, {
            ...payload,
            order: 'asc(id)' as const,
        })

        if (error) {
            throw error
        }

        if (data) {
            yield* put(clientsSlice.actions.setClientsBlockingsList(data))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка блокировок',
        })
        yield* put(clientsSlice.actions.setError(error as string))
        yield* put(
            clientsSlice.actions.setClientsBlockingsList({
                items: [],
                paging: {},
            }),
        )
    } finally {
        yield* put(clientsSlice.actions.setIsBlockingsListLoading(false))
    }
}

function* getMoneyAccount({ payload }: ReturnType<typeof clientsSlice.actions.getMoneyAccount>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getMoneyAccounts, payload)

        if (error) {
            throw error
        }

        if (data?.items) {
            const { data: account, error: accountError } = yield api.lib.getMoneyAccountsBalance(
                data.items[0].id,
            )

            if (accountError) {
                throw accountError
            }

            yield* put(clientsSlice.actions.setMoneyAccount({ ...data.items[0], ...account }))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения счёта',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}
function* getTransactions({ payload }: ReturnType<typeof clientsSlice.actions.getTransactions>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getTransactions, {
            ...payload,
            order: 'asc(created_at)' as const,
            limit: 9000, // временное решение https://dltru.atlassian.net/browse/DFA-2676
        })

        if (error) {
            throw error
        }
        if (data?.items) {
            yield* put(clientsSlice.actions.setTransactions(data.items))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка транзакций',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getProfilesBeneficiaries() {
    try {
        const beneficiariesUuids = yield* select(clientsSelector.selectProfileBeneficiariesUuids)
        if (beneficiariesUuids?.length) {
            const result: IRequest<ResponseItem<BeneficialOwner>>[] = yield* all(
                beneficiariesUuids.map(api.lib.getBeneficialOwnerService),
            )
            const errors = result?.filter(({ error }) => Boolean(error))
            if (errors?.length) {
                openMessage({
                    type: 'error',
                    message: 'Возникла ошибка при получении данных о бенефициарных владельцах',
                })
            }
            const profileBeneficiaries = result
                ?.filter(({ data }) => Boolean(data?.item))
                .map(({ data }) => data?.item as BeneficialOwner)

            yield* put(clientsSlice.actions.setProfilesBeneficiaries(profileBeneficiaries))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получении данных о профиле',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* createQualifiedRegistryHandler({ payload }: ReturnType<typeof createQualifiedRegistry>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error } = yield* call(api.lib.createInvestorRegistryService, payload)

        if (error) {
            throw error
        }
        yield* put(clientsSlice.actions.getQualifyInfo(payload.user_uuid))
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* deleteQualifiedRegistryHandler({ payload }: ReturnType<typeof deleteQualifiedRegistry>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error } = yield* call(api.lib.deleteInvestorRegistryService, payload)

        if (error) {
            throw error
        }
        yield* put(clientsSlice.actions.getQualifyInfo(payload.user_uuid))
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getQualifyInfo({ payload }: ReturnType<typeof clientsSlice.actions.getQualifyInfo>) {
    try {
        const { error, data } = yield* call(api.lib.getQualifyInfo, payload)

        if (error) {
            if (
                isAxiosError(error) &&
                error?.response?.data?.error?.code === 'ERR_FAILED_TO_GET_INVESTOR'
            ) {
                yield* put(
                    clientsSlice.actions.setError(errorTranslates['ERR_FAILED_TO_GET_INVESTOR']),
                )
                return
            }

            throw error
        }

        if (data?.item) {
            yield* put(clientsSlice.actions.setQualifyInfo(data.item))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получении данных о квалификации',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    }
}

function* makeForcedEndOfSessionHandler({
    payload: uuid,
}: ReturnType<typeof makeForcedEndOfSessionAction>) {
    try {
        const { error, data } = yield* call(api.lib.makeForcedEndOfSessionService, uuid)
        if (error && data !== 'ok') {
            throw error
        }
        openMessage({
            type: 'success',
            message: 'Сессия пользователя успешно прервана',
        })
        yield* put(checkClientForActiveSession(uuid))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при прерывании сессии пользователя',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    }
}

function* makeFullBlockHandler({ payload }: ReturnType<typeof makeFullBlockAction>) {
    try {
        const { error, data } = yield* call(api.lib.makeFullBlock, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
        }
        openMessage({
            type: 'success',
            message: 'Запущена процедура блокирования пользователя',
        })
    } catch (error) {
        console.log(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при блокировании пользователя',
        })
    }
}

function* makePartlyBlockHandler({ payload }: ReturnType<typeof makePartlyBlockAction>) {
    try {
        const { error, data } = yield* call(api.lib.makePartlyBlock, payload)
        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
        }
        openMessage({
            type: 'success',
            message: 'Запущена процедура блокирования пользователя',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при блокировании пользователя',
        })
    }
}

function* makeFullUnblockHandler({ payload }: ReturnType<typeof makeFullUnblockAction>) {
    try {
        const { error, data } = yield* call(api.lib.makeFullUnblock, payload)
        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
        }
        openMessage({
            type: 'success',
            message: 'Запущена процедура разблокирования пользователя',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при разблокировании пользователя',
        })
    }
}

function* makePartlyUnblockHandler({ payload }: ReturnType<typeof makePartlyUnblockAction>) {
    try {
        const { error, data } = yield* call(api.lib.makePartlyUnblock, payload)
        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
        }
        openMessage({
            type: 'success',
            message: 'Запущена процедура разблокирования пользователя',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при разблокировании пользователя',
        })
    }
}

function* makeBlockHandler({ payload }: ReturnType<typeof makeBlockAction>) {
    try {
        const { type, ...payloadForHandler } = payload
        if (type === 'full') {
            yield* put(makeFullBlockAction(payloadForHandler))
        } else {
            yield* put(makePartlyBlockAction(payloadForHandler))
        }
    } catch (error) {
        console.log(error)
    }
}

function* makeUnblockHandler({ payload }: ReturnType<typeof makeBlockAction>) {
    try {
        const { type, ...payloadForHandler } = payload
        if (type === 'full') {
            yield* put(makeFullUnblockAction(payloadForHandler))
        } else {
            yield* put(makePartlyUnblockAction(payloadForHandler))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAuthData({ payload }: ReturnType<typeof clientsSlice.actions.getAuthData>) {
    try {
        const { data, error } = yield* call(api.lib.fetchAuthData, payload)
        if (error) {
            throw error
        }
        if (data?.item) {
            yield* put(clientsSlice.actions.setAuthData(data.item))
        }
    } catch (error) {
        console.log(error)
        openMessage({
            type: 'error',
            message: 'Не удалось получить авторизационные данные',
        })
    }
}

function* terminationUser({ payload }: ReturnType<typeof clientsSlice.actions.terminationUser>) {
    try {
        const { error, data } = yield* call(api.lib.terminationByAdmin, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
            openMessage({
                type: 'success',
                message: 'Запрос на исключение из реестра пользователей успешно отправлен',
            })
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при отправке запроса на исключение из реестра пользователей',
        })
    }
}

function* updateExchangeOperator({ payload }: ReturnType<typeof updateExchangeOperatorAction>) {
    try {
        const { error } = yield* call(api.lib.putProfileUpdateByUuidService, payload)
        if (error) {
            throw error
        }
        openMessage({
            type: 'success',
            message: 'Изменен статус пользователя',
        })

        yield* put(
            clientsSlice.actions.setExchangeOperator({
                is_node: payload.is_node,
                is_exchange_operator: payload.is_exchange_operator,
                reason_description: payload.reason_description,
                reason_link_uuid: payload.reason_link_uuid,
            }),
        )
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при изменении статуса пользователя',
        })
    }
}

function* setIdentificationCurrentClient({
    payload,
}: ReturnType<typeof clientsSlice.actions.setIdentificationCurrentClient>) {
    try {
        const currenClientUuid = yield* select(clientsSelector.selectCurrentClientId)
        if (currenClientUuid) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error } = yield* call(api.lib.putIdentification, currenClientUuid, payload)
            if (error) {
                throw error
            }
            yield* put(clientsSlice.actions.getCurrentClient(currenClientUuid))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при установке статуса идентификации',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* bindMoneyAccount({ payload }: ReturnType<typeof clientsSlice.actions.bindMoneyAccount>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error } = yield* call(api.lib.bindNominalAccountById, payload)

        if (error) {
            throw error
        }

        yield* put(clientsSlice.actions.getMoneyAccount(payload.user_uuid))
        openNotification({
            type: 'success',
            message: 'Счета связаны',
            description: 'Лицевой счёт успешно привязан к банковскому счёту',
        })
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Счета не связаны',
            description: 'Возникли ошибки при попытке связать счета',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getPrivateProfileHandler({ payload }: ReturnType<typeof getPrivateProfile>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))

        const { error, data } = yield* call(
            api.lib.getProfilePrivateInformationByTypeAndIdService,
            payload,
        )

        if (error) {
            throw error
        }

        yield* put(
            clientsSlice.actions.setCurrentClientPrivate(
                data.item as PrivateInformationLegalEntityResponse,
            ),
        )
    } catch (error) {
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* updateRiskLevelHandler({ payload }: ReturnType<typeof updateRiskLevel>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))

        const { error } = yield* call(
            api.lib.patchProfilePrivateInformationByTypeAndIdService,
            payload,
        )

        if (error) {
            throw error
        }

        yield* put(getPrivateProfile({ user_uuid: payload.user_uuid, type: payload.type }))
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Ошибка присвоения уровня риска',
            description: 'Ошибка присвоения уровня риска',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* bindMoneySpecialAccountHandler({ payload }: ReturnType<typeof bindMoneySpecialAccount>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error } = yield* call(api.lib.patchMoneyAccountBindByUuidServie, payload)

        if (error) {
            throw error
        }

        yield* put(clientsSlice.actions.getMoneyAccount(payload.user_uuid))
        openNotification({
            type: 'success',
            message: 'Счета связаны',
            description: 'Лицевой счёт успешно привязан к банковскому счёту',
        })
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Счета не связаны',
            description: 'Возникли ошибки при попытке связать счета',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

export function* clients(): Generator<StrictEffect> {
    yield* takeLatest(clientsSlice.actions.getCurrentClient.type, getCurrentClient)
    yield* takeLatest(checkClientForActiveSession, handleCheckClientForActiveSession)
    yield* takeLatest(clientsSlice.actions.changeClientStatus.type, changeClientStatus)
    yield* takeLatest(
        clientsSlice.actions.transactionCreditCurrentClient.type,
        transactionCreditCurrentClient,
    )
    yield* takeLatest(
        clientsSlice.actions.reserveReleaseCurrentClient.type,
        reserveReleaseCurrentClient,
    )
    yield* takeLatest(
        clientsSlice.actions.reserveTransferCurrentClient.type,
        reserveTransferCurrentClient,
    )
    yield* takeLatest(clientsSlice.actions.getClients.type, getClients)
    yield* takeLatest(clientsSlice.actions.getClientsBlockings.type, getClientsBlockings)
    yield* takeLatest(clientsSlice.actions.getMoneyAccount.type, getMoneyAccount)
    yield* takeLatest(clientsSlice.actions.getTransactions.type, getTransactions)
    yield* takeLatest(clientsSlice.actions.getProfilesBeneficiaries.type, getProfilesBeneficiaries)
    yield* takeLatest(
        clientsSlice.actions.getApprovalHistoryRepresentative.type,
        getApprovalHistoryRepresentative,
    )
    yield* takeLatest(
        clientsSlice.actions.getOldApprovalHistoryRepresentative.type,
        getOldApprovalHistoryRepresentative,
    )
    yield* takeLatest(createQualifiedRegistry, createQualifiedRegistryHandler)
    yield* takeLatest(deleteQualifiedRegistry, deleteQualifiedRegistryHandler)
    yield* takeLatest(clientsSlice.actions.getQualifyInfo.type, getQualifyInfo)
    yield* takeLatest(makeForcedEndOfSessionAction, makeForcedEndOfSessionHandler)
    yield* takeLatest(makeFullBlockAction, makeFullBlockHandler)
    yield* takeLatest(makeFullUnblockAction, makeFullUnblockHandler)
    yield* takeLatest(makePartlyBlockAction, makePartlyBlockHandler)
    yield* takeLatest(makePartlyUnblockAction, makePartlyUnblockHandler)
    yield* takeLatest(makeBlockAction, makeBlockHandler)
    yield* takeLatest(makeUnblockAction, makeUnblockHandler)
    yield* takeLatest(clientsSlice.actions.terminationUser.type, terminationUser)
    yield* takeLatest(clientsSlice.actions.getAuthData.type, getAuthData)

    yield* takeLatest(updateExchangeOperatorAction.type, updateExchangeOperator)
    yield* takeLatest(
        clientsSlice.actions.setIdentificationCurrentClient.type,
        setIdentificationCurrentClient,
    )
    yield* takeLatest(clientsSlice.actions.bindMoneyAccount.type, bindMoneyAccount)

    yield* takeLatest(updateRiskLevel.type, updateRiskLevelHandler)
    yield* takeLatest(getPrivateProfile.type, getPrivateProfileHandler)
    yield* takeLatest(bindMoneySpecialAccount.type, bindMoneySpecialAccountHandler)
}
