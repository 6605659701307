import { EmployeeStatus } from '@dltru/dfa-models'
import {
    Button,
    CONTENT_FOOTER_ID,
    ContentFooterBox,
    DeleteOutlined,
    EditOutlined,
    LockIcon,
    Portal,
    UnLockIcon,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { employeeSelector } from '@store/employee/selectors'

import { ArchiveEmployeeModal } from './ArchiveEmployeeModal'
import { BlockEmployeeModal } from './BlockEmployeeModal'

export const Footer: FC = () => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const navigate = useNavigate()

    const data = useSelector(employeeSelector.selectItem)

    const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false)
    const [isBlockingModalVisible, setIsBlockingModalVisible] = useState(false)

    if (data.status === EmployeeStatus.archived) {
        return null
    }

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox
                        left={
                            <Button
                                icon={<EditOutlined />}
                                type="primary"
                                borderRadius={12}
                                onClick={() => navigate(`/employees/${data.user_uuid}/edit`)}
                            >
                                Редактировать профиль
                            </Button>
                        }
                    >
                        {data.status === EmployeeStatus.blocked ? (
                            <Button
                                icon={<UnLockIcon />}
                                borderRadius={12}
                                onClick={() => setIsBlockingModalVisible(true)}
                            >
                                Разблокировать сотрудника
                            </Button>
                        ) : (
                            <Button
                                icon={<LockIcon />}
                                borderRadius={12}
                                onClick={() => setIsBlockingModalVisible(true)}
                            >
                                Заблокировать сотрудника
                            </Button>
                        )}
                        <Button
                            icon={<DeleteOutlined />}
                            borderRadius={12}
                            onClick={() => setIsArchiveModalVisible(true)}
                        >
                            Отправить в архив
                        </Button>
                    </ContentFooterBox>
                </Portal>
            )}
            <ArchiveEmployeeModal
                isModalVisible={isArchiveModalVisible}
                setIsModalVisible={setIsArchiveModalVisible}
            />
            <BlockEmployeeModal
                isModalVisible={isBlockingModalVisible}
                setIsModalVisible={setIsBlockingModalVisible}
            />
        </>
    )
}
