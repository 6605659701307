// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".conditionModal__qu65 {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  top: 48px;\n  bottom: 100px;\n  left: 50%;\n  transform: translate(-50%, 0);\n  max-width: 100%;\n}\n.conditionModalBody_aiPLb {\n  overflow: auto;\n  height: available;\n  margin: 0 40px;\n}\n.conditionModalTitle_pQRvT {\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 28px;\n  margin: 32px 40px 24px 40px;\n}\n.ant-modal-close_dp24o {\n  color: #d3d5da;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/ConditionsModal/style.m.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,SAAA;EACA,6BAAA;EACA,eAAA;AADJ;AAIA;EACI,cAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,2BAAA;AAHJ;AAMA;EACI,cAAA;AAJJ","sourcesContent":["@import '/src/assets/style/palette';\n\n.conditionModal {\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    top: 48px;\n    bottom: 100px;\n    left: 50%;\n    transform: translate(-50%, 0);\n    max-width: 100%;\n}\n\n.conditionModalBody {\n    overflow: auto;\n    height: available;\n    margin: 0 40px;\n}\n\n.conditionModalTitle {\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 28px;\n    margin: 32px 40px 24px 40px;\n}\n\n.ant-modal-close {\n    color: @gray-5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conditionModal": "conditionModal__qu65",
	"conditionModalBody": "conditionModalBody_aiPLb",
	"conditionModalTitle": "conditionModalTitle_pQRvT",
	"ant-modal-close": "ant-modal-close_dp24o"
};
export default ___CSS_LOADER_EXPORT___;
