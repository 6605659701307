import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CloseCircleOutlineIcon } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { DeclineModal } from '../Modals'

export const RejectIssueAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { isAllow } = useContext(RbacContext)

    const showButton =
        (dfaDetails.status === DfaStatusEnum.teller_approval && isAllow(['approval.operator'])) ||
        (dfaDetails.status === DfaStatusEnum.lawyer_approval && isAllow(['approval.lawyer'])) ||
        (dfaDetails.status === DfaStatusEnum.spec_approval && isAllow(['approval.aml']))

    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<CloseCircleOutlineIcon />}
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
            >
                Отказать в допуске ЦФА к выпуску
            </Button>
            <DeclineModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}
