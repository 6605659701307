import { GetDfasParams, IDfaFront, approvalStatuses } from '@dltru/dfa-models'
import { Box, DEFAULT_PER_PAGE, Filters, PageHeader, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { dropDfaDetails } from '@store/dfa/details'
import { dfaListSlice } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'
import { notificationCounterSlice } from '@store/notificationCounter'

import '../styles/style.less'
import { filters } from './filters'
import { gridConfig } from './gridConfig'

export const Emissions: FC = () => {
    const reduxDispatch = useDispatch()
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const params = useSelector(dfaListSelectors.selectParams)
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(dfaListSlice.actions.loadDfas({ limit, status: approvalStatuses }))
    }

    const onNext = () => {
        reduxDispatch(dfaListSlice.actions.loadDfas({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(dfaListSlice.actions.loadDfas({ cursor: paging?.cursors?.prev }))
    }
    const navigate = useNavigate()

    useEffect(() => {
        reduxDispatch(
            dfaListSlice.actions.loadDfas({ status: approvalStatuses, limit: DEFAULT_PER_PAGE }),
        )
        reduxDispatch(notificationCounterSlice.actions.getRegistrationDfaCount())
        return () => {
            reduxDispatch(dfaListSlice.actions.dropDfaList())
            reduxDispatch(dropDfaDetails())
        }
    }, [])

    const onRow = (record: IDfaFront) => ({
        onClick: () => {
            navigate(`/offers/${record.id}`)
        },
    })

    const onApplyFilters = (filters?: Partial<GetDfasParams>) => {
        const requestParams = {
            ...params,
            ...filters,
        }
        reduxDispatch(
            dfaListSlice.actions.loadDfas({
                ...requestParams,
                status: requestParams.status?.length ? requestParams.status : approvalStatuses,
            }),
        )
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Выпуски ЦФА" />
            </Box>

            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={items ?? []}
                    cursors={paging?.cursors}
                    onPerPage={onPerPageChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    onRow={onRow}
                    clickableRow
                    rowKey="id"
                    headerLeft={
                        <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                    }
                />
            </Box>
        </>
    )
}
