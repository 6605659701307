import { MoneyAccountType } from '@dltru/dfa-models'
import classNames from 'classnames/bind'
import { FC } from 'react'

import { Modal } from '../../../components/Modal'
import { CommonInfoRefill } from './CommonInfoRefill'
import style from './style.m.less'

const cn = classNames.bind(style)

interface IRefillModal {
    isModal: boolean
    setIsModal: (v: boolean) => void
    account?: MoneyAccountType
}

export const RefillModal: FC<IRefillModal> = ({ isModal, setIsModal, account }) => (
    <Modal
        visible={isModal}
        width={600}
        onCancel={() => setIsModal(false)}
        footer={null}
        className={cn(style.modal)}
    >
        <div className={cn(style.wrapper)}>
            <div className={style.header}>Пополнение лицевого счёта банковским переводом</div>
            <div className={cn(style.note)}>
                <p>
                    Для пополнения лицевого счёта совершите банковский перевод по указанным
                    реквизитам. Мы зачислим денежные средства в течение 1 рабочего дня.
                </p>
            </div>
            {account && <CommonInfoRefill account={account} />}
        </div>
    </Modal>
)
