import { ClientAutocomplete, DealsTypeTranslate, InvestorDealsType } from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType } from '@dltru/dfa-ui'

import api from '@services/api'

const typeOptions = ['invest', 'secondary', 'offer', 'transfer', 'repayment'].map((value) => {
    const label = DealsTypeTranslate[value as InvestorDealsType]
    return { label, value }
})

const ClientsOption: React.FC<{ data?: DataProviderResponse }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <p> {data.inn} </p>
        </div>
    )
}

const autoGetClientComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getClientsService({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item: ClientAutocomplete) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Дата и время',
        name: 'executed_at',
        filterType: FilterType.DATE_RANGE,
        divider: true,
    },

    {
        label: 'Сторона 1',
        name: 'from_user_uuid',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientComplete,
        renderElement: <ClientsOption />,
    },
    {
        label: 'Сторона 2',
        name: 'to_user_uuid',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientComplete,
        renderElement: <ClientsOption />,
        divider: true,
    },
    {
        label: 'Тип операции',
        name: 'type',
        filterType: FilterType.SELECT,
        options: typeOptions,
    },
]
