import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CloseCircleOutlineIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.1666 9.99992C19.1666 15.0625 15.0625 19.1666 9.99992 19.1666C4.93731 19.1666 0.833252 15.0625 0.833252 9.99992C0.833252 4.93731 4.93731 0.833252 9.99992 0.833252C15.0625 0.833252 19.1666 4.93731 19.1666 9.99992ZM17.9166 9.99992C17.9166 14.3722 14.3722 17.9166 9.99992 17.9166C5.62766 17.9166 2.08325 14.3722 2.08325 9.99992C2.08325 5.62766 5.62766 2.08325 9.99992 2.08325C14.3722 2.08325 17.9166 5.62766 17.9166 9.99992ZM6.86998 6.86998C7.14118 6.59879 7.58088 6.59879 7.85208 6.86998L9.99992 9.01783L12.1478 6.86998C12.419 6.59879 12.8587 6.59879 13.1299 6.86998C13.4011 7.14118 13.4011 7.58088 13.1299 7.85208L10.982 9.99992L13.1299 12.1478C13.4011 12.419 13.4011 12.8587 13.1299 13.1299C12.8587 13.4011 12.419 13.4011 12.1478 13.1299L9.99992 10.982L7.85208 13.1299C7.58088 13.4011 7.14118 13.4011 6.86998 13.1299C6.59879 12.8587 6.59879 12.419 6.86998 12.1478L9.01783 9.99992L6.86998 7.85208C6.59879 7.58088 6.59879 7.14118 6.86998 6.86998Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
