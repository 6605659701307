import {
    DfaStatusEnum,
    ExternalRateIndex,
    IDfaFront,
    IDfaOwner,
    PriceSource,
    ProfileType,
    dfaBackToFront,
} from '@dltru/dfa-models'
import { getEmitterData, getProfileRequestType } from '@dltru/dfa-ui'

import { getExternalRateHelper } from '@store/helper'

import api from '@services/api'

export async function getDfaByIdHelper(dfaId: number): Promise<IDfaFront> {
    try {
        const { data, error: errorDfa } = await api.lib.getDfaById(dfaId)

        if (errorDfa || !data?.item) {
            throw errorDfa
        }

        const { error: errorEmitterType, data: dataEmitterTypes } =
            await api.lib.getProfileTypeByUserService(data.item.emitter_id)

        if (errorEmitterType) {
            throw new Error(errorEmitterType)
        }

        const emitterProfileTypes = (dataEmitterTypes.items as { type: ProfileType }[]).map(
            (profileType) => profileType.type,
        )

        const publicInfoPayload = getProfileRequestType(emitterProfileTypes)

        const { data: dataEmitterProfile } = await api.lib.getPublicInformation(
            publicInfoPayload,
            data.item.emitter_id,
        )

        let availableItem = {}
        const { data: availableData } = await api.lib.getDfaAvailableDeals(data.item.id)
        availableItem = availableData?.item ?? {}

        let restPriceItem = {}

        if (data.item.price_source_type === PriceSource.dynamic) {
            // TODO: добавить возможность получать цену для разных индексов
            const { item } = await getExternalRateHelper(ExternalRateIndex.RUGOLD)
            data.item.price_external = item.rate / 100

            if (data.item.status === DfaStatusEnum.collection_started) {
                const { data: restPrice } = await api.lib.getDfaAvailableDealsV2(data.item.id)
                restPriceItem = restPrice?.item ?? {}
            }
        }
        const emitterInfo = getEmitterData(emitterProfileTypes, dataEmitterProfile?.item)
        const dfa = dfaBackToFront(data.item, emitterInfo, {
            ...availableItem,
            ...restPriceItem,
        })

        return dfa
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}

export interface IGetDfaBalanceByIdHelperProps {
    uuid: string | undefined
    dfaId: number
    withPayoffAgreements?: boolean
    isInvestorRepaymentCheck: boolean
}

export async function getDfaBalanceByIdHelper(
    payload: IGetDfaBalanceByIdHelperProps,
): Promise<IDfaOwner | null> {
    try {
        const { data, error } = await api.lib.getDfaOwners(payload.dfaId)

        if (error) {
            throw new Error(error)
        }

        if (data?.items?.length) {
            const item = (data.items as IDfaOwner[]).find((item) => item.user_id === payload.uuid)

            if (item) {
                const balance = item.balances[0] ?? {}
                if (payload.withPayoffAgreements && payload.isInvestorRepaymentCheck) {
                    const { data: dataPayoffAgreements } = await api.lib.getPayoffAgreements({
                        asset_id: payload.dfaId,
                        user_uuid: payload.uuid,
                        limit: 1,
                    })

                    balance.is_investor_repayment_confirmed = Boolean(
                        dataPayoffAgreements?.item?.items?.length,
                    )
                }

                return balance as IDfaOwner // костыль, надо разобраться с типизацией от api
            }
        }

        return null
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw error
    }
}
