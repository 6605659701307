import { TransactionStatuses } from '@dltru/dfa-models'
import clsx from 'clsx'
import { FC } from 'react'

import { ClockIcon, StatusNegative, StatusPositive } from '../../../Icons'
import style from './style.m.less'

export interface IStatus {
    type?: TransactionStatuses
}

const Icon = {
    [TransactionStatuses.confirmed]: <StatusPositive />,
    [TransactionStatuses.canceled]: <StatusNegative />,
    [TransactionStatuses.resolving]: <ClockIcon />,
    [TransactionStatuses.pending]: <ClockIcon />,
    [TransactionStatuses.payment_awaiting]: <ClockIcon />,
}

const labelTranslate = {
    [TransactionStatuses.confirmed]: 'Исполнено',
    [TransactionStatuses.canceled]: 'Оменено',
    [TransactionStatuses.resolving]: 'В процессе',
    [TransactionStatuses.pending]: 'В процессе',
    [TransactionStatuses.payment_awaiting]: 'В процессе',
}

export const Status: FC<IStatus> = ({ type }) => {
    if (!type) {
        return null
    }
    return (
        <div className={clsx(style.status, style[`status${type}`])}>
            <div className={style.icon}>{Icon[type]}</div>
            <div className={style.label}>{labelTranslate[type]}</div>
        </div>
    )
}
