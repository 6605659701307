import {
    BlackListStatus,
    BlackListType,
    IBlacklist,
    blackListTypeTranslate,
} from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, Tag } from '@dltru/dfa-ui'

export const gridConfig: ColumnsType<IBlacklist> = [
    {
        title: 'Дата и время проверки',
        dataIndex: 'verification_date',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Проверяющий',
        dataIndex: 'employee_name',
        sorter: true,
        ellipsis: true,
        render: (value: string, record: IBlacklist) =>
            `${value} ${record.employee_uuid ? `(ID ${record.employee_uuid})` : ''}`,
    },
    {
        title: 'Тип проверки',
        dataIndex: 'black_list_type',
        sorter: true,
        ellipsis: true,
        render: (value: BlackListType) => blackListTypeTranslate[value] ?? value,
    },
    {
        title: 'Дата перечня',
        dataIndex: 'black_list_date',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: '№',
        dataIndex: 'black_list_number',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Статус совпадения',
        dataIndex: 'match_status',
        sorter: true,
        ellipsis: true,
        render: (value: BlackListStatus) =>
            value === BlackListStatus.EXISTS_IN_BLACK_LIST ? (
                <Tag color="error">Присутсвует</Tag>
            ) : (
                <Tag color="success">Отсутствует</Tag>
            ),
    },
]
