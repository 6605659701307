/**
 * credit - зачисление ДС на номинальные счет
 * debit - списание ДС с номинального счет
 * transfer - внутренний перевод между САУ (такие транзакции всегда парные: одна для account_id получателя, вторая - account_id отправителя)
 * payment - транзакция-платеж (для случаев, когда ДС уходят с САУ одного клиента на р/с другого клиента, который существует в системе)
 * any_payment - транзакция-платеж (для случаев, когда ДС уходят с САУ одного клиента на произвольный р/с)
 */

export enum TransactionTypes {
    credit = 'credit',
    debit = 'debit',
    transfer = 'transfer',
    payment = 'payment',
    any_payment = 'any_payment',
}

export const TransactionTypesTranslate = {
    credit: ' зачисление',
    debit: ' списание',
    transfer: ' внутренний перевод',
    payment: ' транзакция-платеж',
    any_payment: ' транзакция-платеж',
}
