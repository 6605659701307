import { TransactionType, TransactionTypesTranslate } from '@dltru/dfa-models'
import { ColumnsType, LinkCell, getRublesFromPennies, DateTimeCell } from '@dltru/dfa-ui'

export const gridConfig: ColumnsType<TransactionType> = [
    {
        title: 'Дата и время проверки',
        dataIndex: 'created_at',
        key: 'created_at',
        // sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Пользователь',
        dataIndex: 'sender_full_name',
        //sorter: true,
        render: (value, row) => LinkCell(value, `/clients/${row.sender_uuid}`),
    },
    {
        title: 'Код комиссии',
        dataIndex: 'service_code',
        //sorter: true,
    },
    {
        title: 'Наименование комиссии',
        dataIndex: 'purpose',
    },
    {
        title: 'Сумма комиссии, ₽',
        dataIndex: 'amount',
        render: getRublesFromPennies,
    },

    {
        title: 'Операции с ЦФА',
        dataIndex: 'type',
        render: (value) =>
            TransactionTypesTranslate[value as keyof typeof TransactionTypesTranslate],
    },
]
