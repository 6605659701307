import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { AuthInfo } from '@components/AuthInfo'

import { CommonInfo } from '../Profile/CommonInfo'
import { QuestionnaireInfo } from '../Profile/QuestionnaireInfo'
import { AdditionalInfoForAdmin } from './components/AdditionalInfoForAdmin'
import { ServiceHistory } from './components/ServiceHistory'

export const RegistrationCard: FC = () => {
    const authData = useSelector(clientsSelector.selectCurrentAuthData)
    const currentUserUuid = useSelector(clientsSelector.selectCurrentClientId)
    const isBlocked = useSelector(clientsSelector.selectIsCurrentClientBlocked)

    return (
        <>
            <CommonInfo />
            <AuthInfo
                authData={authData}
                userUuid={currentUserUuid as string}
                isActive={!isBlocked}
            />
            {/*<AdditionalInfo />
            <TerroristCheck />
            <FinancialCheck /> */}
            <QuestionnaireInfo />
            <ServiceHistory />
            <AdditionalInfoForAdmin />
        </>
    )
}
