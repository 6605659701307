import { ExternalRateIndex } from '@dltru/dfa-models'
import moment, { Moment } from 'moment'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

import WrappedDateItem from '../../DateItem'
import { CardDivider } from '../../card'
import { WrappedInput } from '../../components/Form'
import { currentFloatNumber, currentNumber, dfaValidators } from '../../utils'
import { IRedeemDateDfaProps, RedeemDateBlock } from './RedeemDateBlock'
import { RedeemPriceBlock } from './RedeemPriceBlock/RedeemPriceBlock'

interface IPriceSourceDynamicProps
    extends Pick<IRedeemDateDfaProps, 'settlementsType' | 'setRedeemDate'> {
    isEdit: boolean
    getExternalRate: (index: ExternalRateIndex) => void
}

export const PriceSourceDynamic: FC<IPriceSourceDynamicProps> = ({
    isEdit,
    getExternalRate,
    ...propsForRedeemDate
}) => {
    const { getFieldValue, validateFields, isFieldTouched, setFieldsValue } =
        useContext(FieldContext)
    const [maxAmount, setMaxAmount] = useState(getFieldValue('max_supply_invested'))
    const [issueStartDate, setIssueStartDate] = useState<Moment | undefined>()
    const [issueEndDate, setIssueEndDate] = useState<Moment | undefined>()
    useEffect(() => {
        setIssueStartDate(getFieldValue('issue_start_date'))
        setIssueEndDate(getFieldValue('issue_end_date') ?? getFieldValue('issue_start_date'))
    }, [isEdit])
    const onChangeIssueStartDate = (value: Moment | null) => {
        setIssueStartDate(value ?? undefined)
        if (getFieldValue('issue_end_date')) {
            validateFields(['issue_end_date'])
        } else {
            setIssueEndDate(value ?? undefined)
            if (getFieldValue('redeem_date')) {
                validateFields(['redeem_date'])
            }
        }
    }
    const onChangeIssueEndDate = (value?: Moment | null) => {
        setIssueEndDate(value ?? undefined)
        if (getFieldValue('redeem_date')) {
            validateFields(['redeem_date'])
        }
    }

    useEffect(() => {
        getExternalRate(ExternalRateIndex.RUGOLD)

        return () => {
            setFieldsValue({ price_external: undefined })
            setFieldsValue({ price_per_dfa: undefined })
        }
    }, [])

    const onChangeMaxAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaxAmount(event.target.value)
        if (isFieldTouched('min_supply_invested')) {
            validateFields(['min_supply_invested'])
        }
    }

    return (
        <>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Максимальный размер выпуска ЦФА"
                    name="max_supply_invested"
                    placeholder="Укажите количества выпускаемых ЦФА в единицах"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.amount() }]}
                    normalize={currentNumber}
                    onChange={onChangeMaxAmount}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Минимальный размер выпуска ЦФА"
                    name="min_supply_invested"
                    placeholder="Укажите количества выпускаемых ЦФА в единицах"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.amount(maxAmount) }]}
                    normalize={currentNumber}
                />
            </div>
            <CardDivider />

            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Цена актива на бирже, руб."
                    name="price_external"
                    placeholder="Цена единицы"
                    uneditable={isEdit}
                    disabled
                    required
                    rules={[{ validator: dfaValidators.price }]}
                    normalize={currentFloatNumber}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Спред к цене выпуска, %"
                    name="investment_spread"
                    placeholder="Укажите спред к стоимости актива на бирже"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.spread }]}
                    //normalize={currentNumber}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Цена ЦФА, руб."
                    name="price_per_dfa"
                    placeholder="Цена единицы"
                    uneditable={isEdit}
                    required
                    disabled
                    rules={[{ validator: dfaValidators.price }]}
                    normalize={currentFloatNumber}
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Дата начала размещения ЦФА"
                    name="issue_start_date"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.issueStartDate }]}
                    showTime={{ defaultValue: moment('10:00', 'HH:mm') }}
                    onChange={onChangeIssueStartDate}
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Дата окончания размещения ЦФА"
                    name="issue_end_date"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.issueEndDate(issueStartDate) }]}
                    showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
                    onChange={onChangeIssueEndDate}
                />
            </div>
            <CardDivider />

            <div className="formRow">
                <RedeemPriceBlock uneditable={isEdit} disabled />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Спред к цене погашения, %"
                    name="redemption_spread"
                    placeholder="Укажите спред к стоимости актива на бирже"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.spread }]}
                    //normalize={currentNumber}
                />
            </div>
            <div className="formRow">
                <RedeemDateBlock
                    uneditable={isEdit}
                    dateForValidate={issueEndDate}
                    {...propsForRedeemDate}
                />
            </div>
        </>
    )
}
