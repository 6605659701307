import { FC } from 'react'

import { CardDivider } from '../../card'
import { NameInput, SecondNameInput, SurnameInput, WrappedInputProps } from '../../components/Form'

type FioFormProps = Partial<WrappedInputProps> & {
    group?: string
    noBottomDivider?: boolean
}

export const FioForm: FC<FioFormProps> = ({ group, fieldKey, noBottomDivider, ...rest }) => {
    const getNameForField = (fieldName: string) => {
        if (group) {
            return `${group}.${fieldName}`
        }
        if (typeof fieldKey === 'number') {
            return [fieldKey, fieldName]
        }
        return fieldName
    }

    return (
        <>
            <div className="formRow">
                <SurnameInput
                    {...rest}
                    name={getNameForField('last_name')}
                    fieldKey={fieldKey}
                    required
                />
            </div>

            <div className="formRow">
                <NameInput
                    {...rest}
                    name={getNameForField('first_name')}
                    fieldKey={fieldKey}
                    required
                />
            </div>
            <div className="formRow">
                <SecondNameInput
                    {...rest}
                    name={getNameForField('second_name')}
                    fieldKey={fieldKey}
                    required
                />
            </div>
            {!noBottomDivider && <CardDivider />}
        </>
    )
}
