import { RadioProps as RadioAntdProps, RadioChangeEvent, Space } from 'antd'

import { SpaceProps } from '../Space'
import { FormItem, FormItemProps } from '../components/Form'
import { Radio } from './Radio'

type RadioOption<T> = {
    key?: string
    value: T
    label?: React.ReactNode
}
export type RadioProps<T> = FormItemProps &
    Omit<RadioAntdProps, 'onChange'> &
    Pick<SpaceProps, 'direction'> & { options: RadioOption<T>[]; onChange?: (value: T) => void }

export const WrappedRadioItem = <T extends string | number | boolean>({
    renderUneditable,
    onChange,
    direction = 'vertical',
    options,
    disabled,
    ...restProps
}: RadioProps<T>) => {
    const renderUneditableDefault = (value?: T) => {
        const uneditableLabel = options.find((item) => item.value === value)?.label ?? value
        return typeof uneditableLabel === 'string'
            ? uneditableLabel.replace(uneditableLabel[0], uneditableLabel[0].toUpperCase())
            : uneditableLabel
    }
    const handleRadioChange = (e: RadioChangeEvent) => {
        const value = e.target.value
        onChange?.(value)
    }
    return (
        <FormItem
            renderUneditable={
                renderUneditable ?? (renderUneditableDefault as FormItemProps['renderUneditable'])
            }
            {...restProps}
        >
            <Radio.Group onChange={handleRadioChange} disabled={disabled}>
                <Space direction={direction}>
                    {options.map(({ key, value, label }) => (
                        <Radio key={key || String(value)} value={value} id={String(value)}>
                            {label || value}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </FormItem>
    )
}
