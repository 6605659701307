import { DocumentsEnum, IDfaOwnerBalance } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getTableData } from '@store/wallet/assets/selectors'

import { balancesGridConfig } from './balancesGridConfig'
import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

export const BalanceTable: FC<{ userId?: string }> = ({ userId }) => {
    const walletData = useSelector(getTableData)
    const navigate = useNavigate()

    const onRow = (record: IDfaOwnerBalance) => ({
        onClick: () => {
            navigate(`/offers/${record.asset_id}`)
        },
    })

    return (
        <Table
            isLoading={walletData.loading}
            columns={balancesGridConfig({ currentUserId: userId })}
            dataSource={walletData.Rows}
            scroll={{ y: undefined }}
            onRow={onRow}
            clickableRow
            rowKey="id"
            footerLeft={
                <DownloadBtn
                    documentType={DocumentsEnum.user_dfa_wallet}
                    params={{ user_id: userId }}
                    title="Отчёт о ЦФА"
                />
            }
        />
    )
}
