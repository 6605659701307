import { FC } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { AuthInfo } from '@components/AuthInfo'

import { CommonInfo } from './CommonInfo'
import { QuestionnaireInfo } from './QuestionnaireInfo'
import { ServiceHistory } from '../RegistrationCard/components/ServiceHistory'
import { RelatedTariff } from '@components/RelatedTariff/RelatedTariff'

export const Profile: FC = () => {
    const authData = useSelector(clientsSelector.selectCurrentAuthData)
    const currentUserUuid = useSelector(clientsSelector.selectCurrentClientId)
    const isBlocked = useSelector(clientsSelector.selectIsCurrentClientBlocked)

    return (
        <>
            <CommonInfo />
            <AuthInfo
                authData={authData}
                userUuid={currentUserUuid as string}
                isActive={!isBlocked}
            />
            <QuestionnaireInfo />
            <ServiceHistory />
            <RelatedTariff />
        </>
    )
}
