import { ClientAutocomplete, IEmployee } from '@dltru/dfa-models'
import { DataProviderResponse, WrappedAutocomplete } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import api from '@services/api'

export interface IOption extends ClientAutocomplete {
    label: string
    value: string
}

interface IUsersAutocomplete {
    isEdit: boolean
    name: string | string[]
    onSelect: (users: IOption[]) => void
}

const UserOption: FC<{ data?: DataProviderResponse & IEmployee }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <span>{data.inn}</span>
        </div>
    )
}

export const UsersAutocomplete: FC<IUsersAutocomplete> = ({ isEdit, name, onSelect }) => {
    const [selected, setSelected] = useState<DataProviderResponse[]>([])

    const onSelectHandler = (selectedOptions: DataProviderResponse[]) => {
        const options = selectedOptions as unknown as IOption[]
        onSelect(options)

        setSelected(selectedOptions)
    }

    const getData = async (value: unknown) => {
        try {
            if (!value) {
                return []
            }

            const { data } = await api.lib.getClientsService({
                full_name: `like(${value})`,
            })

            return data?.items?.map((item) => ({
                ...item,
                label: item.full_name,
                value: item.user_uuid,
            })) as DataProviderResponse[]
        } catch (error) {
            return []
        }
    }

    return (
        <WrappedAutocomplete
            style={{ width: '100%' }}
            className="formComponentItem"
            dataProvider={getData}
            onSelect={onSelectHandler}
            defaultOptions={selected}
            renderElement={<UserOption />}
            name={name}
            label=""
            selectProps={{
                mode: 'multiple',
                size: 'large',
                placeholder: 'Укажите имя',
            }}
            uneditable={isEdit}
        />
    )
}
