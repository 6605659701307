import { Box, Button, Divider, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

export const BlockClientWalletModal: FC<{
    onBlock: () => void
    onClose: () => void
    visible: boolean
}> = ({ onBlock, onClose, visible }) => {
    return (
        <Modal
            title="Блокировка кошелька пользователя"
            visible={visible}
            closable
            footer={[
                <Button borderRadius={12} onClick={onClose}>
                    Отмена
                </Button>,
                <Button type="primary" danger borderRadius={12} onClick={onBlock}>
                    Заблокировать кошелёк
                </Button>,
            ]}
        >
            <Box>
                <span>Для пользователя будут недоступны любые операции с ЦФА.</span>
            </Box>
            <Divider />
            <div className="ant-modal-title">Основания блокировки</div>
            <Box direction="column" margin={[24, 0, 0, 0]}>
                <UploadDocumentContainerWrapper
                    name="approval_document_file_uuid"
                    title="Загрузите фотографии или сканы документов"
                    required
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="approval_document_comment"
                        multiline
                        maxLength={200}
                    />
                </UploadDocumentContainerWrapper>
            </Box>
        </Modal>
    )
}
