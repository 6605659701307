import { IEmissionOrder, getOrderTypeTranslate } from '@dltru/dfa-models'
import {
    ColoredTag,
    ColumnsType,
    DangerPercent,
    DateTimeCell,
    LinkCell,
    Tag,
    getOrderStatusTagProps,
} from '@dltru/dfa-ui'

const AmountCell = (_, record: IEmissionOrder) => {
    if (record.amount_dfa !== undefined) {
        return record.amount_dfa // TODO заменить на ColoredTag когда будет цвет
    }
    return (
        <ColoredTag type={record.order_type || 'gray'}>
            {record.original_amount_dfa?.toLocaleString()}
        </ColoredTag>
    )
}

export const gridConfig: ColumnsType<IEmissionOrder> = [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Тип заявки',
        dataIndex: 'order_type',
        key: 'order_type',
        sorter: true,
        ellipsis: true,
        render: getOrderTypeTranslate,
    },
    {
        title: 'Кол-во, ед.',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: AmountCell,
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Стоимость, ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Выкуплено',
        dataIndex: 'repurchase_percent',
        key: 'repurchase_percent',
        sorter: true,
        ellipsis: true,
        render: (value) => DangerPercent(value),
    },
    {
        title: 'Инициатор',
        dataIndex: 'user_id',
        key: 'user_id',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(record?.user_name || value, `/clients/${value}`),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        fixed: 'right',
        render: (_, record) => {
            const tagProps = getOrderStatusTagProps(
                record.status,
                record.order_type,
                record.cancel_initiator,
            )
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
