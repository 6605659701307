import { TransactionDataFront, TransactionRow } from '@dltru/dfa-models'
import { FC } from 'react'

import { Divider } from '../../../Divider'
import { BalanceRow } from '../BalanceRow'
import { DateSummaryRow } from '../DateSummaryRow'
import style from './style.m.less'

interface IComponentProps {
    summary: TransactionDataFront['summary']
    rows: TransactionDataFront['rows']
    onSelect: (row: TransactionRow) => void
}

export const BalanceGroup: FC<IComponentProps> = ({ summary, rows, onSelect }) => {
    return (
        <div className={style.BalanceListGroup}>
            <DateSummaryRow {...summary} />
            {rows.map((row) => (
                <BalanceRow key={row.id} {...row} onClick={() => onSelect(row)} />
            ))}
            <Divider />
        </div>
    )
}
