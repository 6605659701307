import {
    CertificateType,
    ChangeLoginType,
    ChangePhoneType,
    IEmployee,
    ILoginServiceParams,
    IPagingResponse,
    IResetPasswordInitParams,
    ProfileStatus,
    UserRolesByScope,
} from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'

interface IUserCertificates {
    items: CertificateType[]
    error: string | null
    isLoaded: boolean
    paging?: IPagingResponse
}

type Status2fa = 'await' | 'reject' | 'confirmed' | 'pass' | 'ready' | null
export enum TokenStatusEnum {
    verify = 'verify',
}
type AuthDataChange = {
    temporaryJWT?: string
    lastPhoneDigits?: string
    phoneVerificationStatus?: Status2fa
    tokenStatus?: TokenStatusEnum | string
    newEmail?: string
}

export interface IAuthState {
    isAuth: boolean
    isLoading: boolean
    isEmailConfirmed?: boolean
    waitEmail?: boolean
    user?: string
    role?: UserRolesByScope
    uuid?: string
    email?: string
    error?: string
    is2Fa: boolean
    phone?: string
    isFirstTime?: boolean
    profileStatus: ProfileStatus | null
    registrationPhoneLastDigits: string
    status2Fa: Status2fa
    statusSetPhone: Status2fa
    setPhoneError: boolean
    isFraud: boolean
    fraudRemainingTimeInSec: number
    certificates: IUserCertificates
    profile: IEmployee | null
    changeAuthState: AuthDataChange
    session_time_out: number | null
}

const initialState = {
    isAuth: false,
    isLoading: true,
    is2Fa: false,
    status2Fa: null,
    statusSetPhone: null,
    setPhoneError: false,
    profileStatus: null,
    isFraud: false,
    fraudRemainingTimeInSec: 0,
    registrationPhoneLastDigits: '',
    certificates: {
        items: [],
        error: null,
        isLoaded: false,
    },
    changeAuthState: {},
    profile: null,
    session_time_out: null,
} as IAuthState

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        getAuthState: (state) => {
            return state
        },
        setRole: (state, action) => {
            const roleData = action.payload
            return { ...state, ...roleData }
        },
        updateAuth(state, action) {
            const authData = action.payload
            return { ...state, ...authData }
        },
        updateVerify(state, action) {
            state.isEmailConfirmed = action.payload
        },
        setAuthError(state, action) {
            state.error = action.payload
        },
        dropPhoneError(state) {
            state.setPhoneError = false
        },
        setFraudAttempt(state, action) {
            state.isFraud = true
            state.fraudRemainingTimeInSec = action.payload
        },
        setCertificates(state, action) {
            state.certificates.items = action.payload
        },
        updateCertificates(state, action) {
            state.certificates = { ...state.certificates, ...action.payload }
        },
        dropAuth() {
            return { ...initialState, isLoading: false }
        },
        setChangeAuthData(state, action: PayloadAction<Partial<AuthDataChange>>) {
            state.changeAuthState = { ...state.changeAuthState, ...action.payload }
        },
        clearChangeAuthData(state) {
            state.changeAuthState = {}
        },
        setProfile(state, action: PayloadAction<IEmployee>) {
            state.profile = action.payload
        },
    },
})

export const login = createAction<
    (ILoginServiceParams & { navigate: NavigateFunction }) | undefined
>('auth/login')
export const logout = createAction('auth/logout')
export const confirmEmail = createAction('auth/confirmEmail')
export const verifyHash = createAction<unknown | undefined>('auth/verifyHash')
export const confirmCall = createAction<string>('auth/confirmCall')
export const requestCall = createAction('auth/requestCall')
export const setPhoneRequest = createAction<string>('auth/setPhoneRequest')
export const setPhoneConfirm = createAction<string>('auth/setPhoneConfirm')
export const getUserInfoAction = createAction('auth/getUserInfo')
export const getUsersCertificates = createAction('auth/getUsersCertificates')
export const checkConfirmToken = createAction<string>('auth/checkConfirmToken')
export const resetPasswordInit = createAction<IResetPasswordInitParams>('auth/resetPasswordInit')
export const changeEmailInitAdminConfirm = createAction<string>('auth/changeEmailInitAdminConfirm')
export const changePhoneRequest = createAction<ChangePhoneType>('auth/changePhoneRequest')
export const changePhoneConfirm = createAction<string>('auth/changePhoneConfirm')
export const getAuthProfile = createAction('auth/getAuthProfile')
export const removeUsersCertificate = createAction<string>('auth/removeUsersCertificate')
type ChangePasswordConfirmType = {
    code: string
    isAdminInitial?: boolean
}
export const resetPasswordConfirm = createAction<ChangePasswordConfirmType>(
    'auth/resetPasswordConfirm',
)
export const changeLoginPasswordRequest = createAction<ChangeLoginType>(
    'auth/changeLoginPasswordRequest',
)
export const changeLoginPasswordConfirm = createAction<string>('auth/changeLoginPasswordConfirm')
export const {
    updateAuth,
    getAuthState,
    setAuthError,
    setRole,
    dropAuth,
    dropPhoneError,
    setFraudAttempt,
    setCertificates,
    updateCertificates,
} = authSlice.actions

export default authSlice.reducer
