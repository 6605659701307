import { Box, Button, Divider, Form, Modal, Space, UploadFile, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createQualifiedRegistry, deleteQualifiedRegistry } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import { ActionModalType } from './QualifiedBlock'
import styles from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    type: keyof typeof ActionModalType
}
type FormValueType = {
    reason_description: string
    documents?: UploadFile[]
}
const ActionModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible, type }) => {
    const [form] = Form.useForm<FormValueType>()
    const dispatch = useDispatch()
    const [isEmptyDocuments, setIsEmptyDocuments] = useState(true)
    const [isEmptyReason, setIsEmptyReason] = useState(true)

    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const modalClose = () => {
        form.resetFields()
        setIsModalVisible(false)
        setIsEmptyDocuments(true)
        setIsEmptyReason(true)
    }

    const handleOk = () => {
        const { reason_description, documents } = form.getFieldsValue()
        if (currentClientId && reason_description) {
            const payload = {
                user_uuid: currentClientId,
                reason_description,
                initiator_type: 'operator' as const,
                documents: documents?.map(({ uid }) => uid),
            }
            if (type === ActionModalType.delete) {
                dispatch(deleteQualifiedRegistry(payload))
            } else {
                dispatch(createQualifiedRegistry(payload))
            }
            modalClose()
        }
    }
    const onValuesChange = (_, allValues: FormValueType) => {
        setIsEmptyReason(!allValues.reason_description)
    }
    const onChangeDocuments = (value?: UploadFile[]) => {
        setIsEmptyDocuments(!value || !value.length)
    }

    const isBtnDisable =
        type === ActionModalType.delete ? isEmptyReason : isEmptyReason || isEmptyDocuments

    return (
        <Modal width={520} visible={isModalVisible} onCancel={modalClose} destroyOnClose>
            <div>
                <Box direction="row" align="center" padding={[0, 0, 24, 0]}>
                    {type === ActionModalType.delete ? (
                        <div className="ant-modal-title">
                            Исключение из Реестра квалифицированных инвесторов
                        </div>
                    ) : (
                        <div className="ant-modal-title">
                            Включение в Реестр квалифицированных инвесторов
                        </div>
                    )}
                </Box>
                <Box
                    direction="column"
                    align="center"
                    justify="right"
                    padding={[24, 0, 0, 0]}
                    className={styles.preLine}
                >
                    {type === ActionModalType.delete
                        ? 'Пользователь будет лишён статуса квалифицированного инвестора.\n' +
                          'В Реестре квалифицированных инвесторов автоматически будет создана запись об исключении ' +
                          'из Реестра, а пользователю на электронную почту будет выслано соответствующее уведомление.'
                        : 'Пользователю будет присвоен статус квалифицированного инвестора.\n' +
                          'В Реестре квалифицированных инвесторов автоматически будет создана запись о присвоении квалификации, ' +
                          'а пользователю на электронную почту будет выслано соответствующее уведомление.'}
                </Box>
                <Divider />
                <h4 className={styles.subTitle}>Основание</h4>
                <Box margin={[24, 0]}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleOk}
                        onValuesChange={onValuesChange}
                    >
                        <UploadDocumentContainerWrapper
                            name="documents"
                            title={`Загрузите фотографии документов ${
                                type === ActionModalType.delete ? '(при необходимости)' : ''
                            }  Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ`}
                            required={type !== ActionModalType.delete}
                            onChange={onChangeDocuments}
                            maxSizeMb={5}
                            accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                        >
                            <WrappedInput
                                className="formComponentItem"
                                placeholder="Ваш комментарий"
                                name="reason_description"
                                multiline
                                maxLength={200}
                                required
                            />
                        </UploadDocumentContainerWrapper>
                    </Form>
                </Box>
                <Box padding={[24, 0, 0, 0]} direction="row" justify="right">
                    <Space>
                        <Button borderRadius={12} onClick={modalClose}>
                            Отмена
                        </Button>
                        {type === ActionModalType.delete ? (
                            <Button
                                borderRadius={12}
                                type="danger"
                                onClick={form.submit}
                                disabled={isBtnDisable}
                            >
                                Исключить из Реестра
                            </Button>
                        ) : (
                            <Button
                                borderRadius={12}
                                type="primary"
                                onClick={form.submit}
                                disabled={isBtnDisable}
                            >
                                Включить в Реестр
                            </Button>
                        )}
                    </Space>
                </Box>
            </div>
        </Modal>
    )
}

export default ActionModal
