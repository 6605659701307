import { Select } from 'antd'
import { FC, useContext, useState } from 'react'

import { Col } from '../../../Col'
import { DateItem } from '../../../DateItem'
import WrappedDateRangeItem from '../../../DateRangeItem'
import { Divider } from '../../../Divider'
import { Row } from '../../../Row'
import { FormItem } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import style from './style.m.less'
import { Filter } from './types'

enum DateFilterType {
    DATE = 'date',
    DATE_RANGE = 'date_range',
}

type DateFilterProps = Omit<Filter, 'filterType'> & { rules?: unknown[] }

export const DateFilter: FC<DateFilterProps> = ({ label, name, divider = false }) => {
    const { form } = useContext(FiltersContext)
    const [currentType, setCurrentType] = useState(DateFilterType.DATE_RANGE)

    const onFilterTypeChange = (type: DateFilterType): void => {
        form?.resetFields([`${name}`])
        setCurrentType(type)
    }

    return (
        <FormItem className={style.filtersFormItem}>
            <Row gutter={10} align="middle">
                <Col span={6}>
                    <span className={style.filtersItemLabel}>{label}</span>
                </Col>
                <Col span={6}>
                    <Select
                        className={style.filtersTypeSelect}
                        bordered={false}
                        onChange={onFilterTypeChange}
                        defaultValue={DateFilterType.DATE_RANGE}
                        size="middle"
                    >
                        <Select.Option value="date">Дата</Select.Option>
                        <Select.Option value="date_range">Период</Select.Option>
                    </Select>
                </Col>
                <Col span={12}>
                    {currentType === DateFilterType.DATE && (
                        <FormItem name={name}>
                            <DateItem style={{ width: '100%' }} name={name} />
                        </FormItem>
                    )}
                    {currentType === DateFilterType.DATE_RANGE && (
                        <FormItem name={name}>
                            <WrappedDateRangeItem
                                name={name}
                                onlyFuture
                                style={{ width: '100%' }}
                            />
                        </FormItem>
                    )}
                </Col>
            </Row>
            {divider && <Divider padding={[16, 0, 0, 0]} margin={0} />}
        </FormItem>
    )
}
