export const enum TariffPlaneEnum {
    BASE = 'BASE',
    INDIVIDUAL = 'INDIVIDUAL',
    ASSET = 'ASSET',
}

export const tariffPlaneTypeShort = {
    [TariffPlaneEnum.BASE]: 'B',
    [TariffPlaneEnum.INDIVIDUAL]: 'I',
    [TariffPlaneEnum.ASSET]: 'E',
}

export const tariffPlaneTypeTranslate = {
    [TariffPlaneEnum.BASE]: 'Базовый',
    [TariffPlaneEnum.INDIVIDUAL]: 'Индивидуальный',
    [TariffPlaneEnum.ASSET]: 'На выпуск',
}
