import { ITransferOrder, ITransferParams } from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

interface ITransferOrderListState {
    isLoading: boolean
    data: ITransferOrder[]
    error?: string
}
const initialState: ITransferOrderListState = {
    isLoading: false,
    data: [],
    error: '',
}

const orderListSlice = createSlice({
    name: 'transferOrderList',
    initialState,
    reducers: {
        getTransferOrderList: (state) => {
            return state
        },
        updateTransferOrderList: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateOrderById: (state, action) => {
            state.data[action.payload.id] = action.payload
        },
        addOrderById: (state, action) => {
            state.data.push(action.payload)
        },
        dropTransferOrderList: () => {
            return { ...initialState }
        },
        updateErrorTransferOrderList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingTransferOrderList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadTransferOrders = createAction<Partial<ITransferParams>>(
    'dfaOrders/loadTransferOrders',
)
// delete after on BE sort filter realisation
export const loadTransferOrdersDfa = createAction<Partial<ITransferParams>>(
    'dfaOrders/loadTransferOrdersDfa',
)
export const {
    getTransferOrderList,
    updateTransferOrderList,
    updateOrderById,
    addOrderById,
    dropTransferOrderList,
    updateErrorTransferOrderList,
    updateLoadingTransferOrderList,
} = orderListSlice.actions
export default orderListSlice.reducer
