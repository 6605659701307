import {
    KeysMatching,
    RateBaseFrontType,
    RateBaseType,
    TariffPlaneEnum,
    rateBackToFront,
} from '@dltru/dfa-models'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { tariffSelector } from '@store/tariff/selectors'

const initialRate = {
    minimum: 0,
    annual_percentage: 0,
    amount_percentage: 0,
}

export const useBaseRates = (type: TariffPlaneEnum, initialRatesByGrid?: RateBaseType[]) => {
    const fees = useSelector(tariffSelector.selectFees)
    const baseRates = useSelector(tariffSelector.selectBaseRates)

    const feeList = useMemo(() => {
        const initialRates = initialRatesByGrid
            ? initialRatesByGrid.reduce((acc: Record<string, RateBaseFrontType>, rate) => {
                  acc[rate.fee_code] = rateBackToFront(rate)
                  return acc
              }, {})
            : baseRates

        if (type === TariffPlaneEnum.ASSET) {
            return fees
                .filter((fee) => fee.is_asset)
                .map(({ code, name }) => ({
                    ...(initialRates[code] || initialRate),
                    name,
                    fee_code: code,
                }))
        } else if (type === TariffPlaneEnum.BASE && !initialRatesByGrid) {
            return fees.map(({ code, name }) => ({ ...initialRate, name, fee_code: code }))
        }
        return fees.map(({ code, name }) => ({
            ...(initialRates[code] || initialRate),
            name,
            fee_code: code,
        }))
    }, [type, fees, baseRates, initialRatesByGrid])

    const initialValues = useMemo(
        () =>
            feeList.reduce((acc: Record<string, number>, rate) => {
                ;(Object.keys(initialRate) as Array<KeysMatching<RateBaseType, number>>).forEach(
                    (fieldKey) => {
                        acc[`${rate.fee_code}${fieldKey}`] = rate[fieldKey]
                    },
                )
                return acc
            }, {}),
        [feeList],
    )
    return { feeList, initialValues }
}
