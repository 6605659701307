import { TariffPlaneEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    ArchiveMinus,
    Button,
    CONTENT_FOOTER_ID,
    ContentFooterBox,
    Portal,
    SaveAdd,
} from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { tariffSelector } from '@store/tariff/selectors'

import { ArchivePlaneModal } from '@pages/Tariff/View/components/Footer/ArchivePlaneModal'

export const Footer: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const allowAction = isAllow(['tariff.write.all'])
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const data = useSelector(tariffSelector.selectItem)
    const showArchiveBtn = allowAction && !data.archived_at && data.type !== TariffPlaneEnum.BASE // TODO После добавить проверку на роль
    const showCreateBtn = allowAction && data.type !== TariffPlaneEnum.BASE // TODO После добавить проверку на  роль
    const navigate = useNavigate()

    const onCreate = () => {
        navigate('/tariff/create', { state: data })
    }

    const onArchive = () => {
        setIsModalVisible(true)
    }

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {showCreateBtn && (
                            <Button
                                icon={<SaveAdd />}
                                borderRadius={12}
                                onClick={onCreate}
                                disabled={!data?.id}
                            >
                                Создать на основе
                            </Button>
                        )}

                        {showArchiveBtn && (
                            <Button icon={<ArchiveMinus />} borderRadius={12} onClick={onArchive}>
                                Архивировать тарифный план
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
            <ArchivePlaneModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                data={data}
            />
        </>
    )
}
