import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const OpenButton: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="22"
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1307 11.4919L7.47062 15.172C7.19934 15.4447 6.75794 15.4447 6.48666 15.172C6.21746 14.9013 6.21746 14.464 6.48666 14.1933L9.66269 11L6.48666 7.80659C6.21746 7.53592 6.21746 7.09863 6.48666 6.82796C6.75794 6.5552 7.19934 6.5552 7.47062 6.82796L11.1307 10.508C11.4009 10.7797 11.4009 11.2202 11.1307 11.4919Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
