import { IIndexRate, IRequest, ResponseItem } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function getExternalRates(
    index: IIndexRate['index'],
): Promise<IRequest<ResponseItem<IIndexRate>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ExternalRates, index),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
