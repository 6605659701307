import { openMessage } from '@dltru/dfa-ui'
import qs from 'qs'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { getinvestorsDeals, investorsDealsSlice } from './index'
import { getinvestorsDealByUuid, investorsDealSlice } from './investorDeal'

function* getinvestorsDealsHandler({ payload }: ReturnType<typeof getinvestorsDeals>) {
    try {
        yield* put(investorsDealsSlice.actions.setIsLoading(true))

        const _payload = { ...payload }
        const filters = { ..._payload.filters }
        delete _payload.filters

        const payloadStringify = qs.stringify(_payload, { arrayFormat: 'brackets', encode: false })

        let queryString = `?${payloadStringify}`
        if (filters) {
            queryString = `?${Object.values(filters).join('&')}&${payloadStringify}`
        }

        const { data, error } = yield* call(api.lib.getDealsListService, queryString)

        if (!data) {
            yield* put(investorsDealsSlice.actions.setInvestorsDeals({ items: [] }))
            // throw new Error()
            return
        }

        yield* put(investorsDealsSlice.actions.setInvestorsDeals(data))

        if (error) {
            throw error
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось получить список операций с ЦФА',
        })
        yield* put(investorsDealsSlice.actions.setError(error as string))
    } finally {
        yield* put(investorsDealsSlice.actions.setIsLoading(false))
    }
}

function* getinvestorsDealByUuidHandler({ payload }: ReturnType<typeof getinvestorsDeals>) {
    try {
        yield* put(investorsDealSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getDealByUuidService, payload)

        if (!data) {
            throw new Error()
        }

        yield* put(investorsDealSlice.actions.setInvestorsDeal(data.item))

        if (error) {
            throw error
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось получить информацию по сделке',
        })
        yield* put(investorsDealSlice.actions.setError(error as string))
    } finally {
        yield* put(investorsDealSlice.actions.setIsLoading(false))
    }
}

export function* watchInvestorsDeals(): Generator<StrictEffect> {
    yield* takeLatest(getinvestorsDeals.type, getinvestorsDealsHandler)
    yield* takeLatest(getinvestorsDealByUuid.type, getinvestorsDealByUuidHandler)
}
