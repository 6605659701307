import {
    ISecondaryDeal,
    ISecondaryOrder,
    ISecondaryOrderShort,
    unionTypeBackToFront,
} from '@dltru/dfa-models'
import { BodyTitle, Button, DateTime, Divider, LabelRow as Row } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { Link } from 'react-router-dom'

import { SecondaryOrderDetailsModal } from '../../Orders/View/Secondary'
import { BaseModal } from './Base'
import { CommonInfoBlock } from './CommonInfoBlock'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: ISecondaryDeal
}

const SecondaryModal: FC<IDealViewModal> = ({ deal, onResolve }) => {
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<ISecondaryOrder>()
    const openOrderModal = (order: ISecondaryOrderShort) => {
        setCurrentOrder(
            unionTypeBackToFront({
                ...order,
                asset_ticker_symbol: deal.asset_ticker_symbol,
                asset_title: deal.asset_title,
                emitter_full_name: deal.emitter_full_name,
                emitter_id: deal.emitter_id,
            }),
        )
        setIsCardModalVisible(true)
    }

    return (
        <>
            <BaseModal deal={deal} onResolve={onResolve}>
                <BodyTitle title="Детали купли-продажи" id={deal.id} />
                <Row label="Дата и время сделки" className="label-row__space">
                    <DateTime value={deal.executed_at} />
                </Row>
                <Row label="Покупатель" className="label-row__space">
                    <Link to={`/clients/${deal.buy_order.user_id}`}>
                        {deal.buy_order.user_full_name}
                    </Link>
                </Row>
                <Row label="Продавец" className="label-row__space">
                    <Link to={`/clients/${deal.sale_order.user_id}`}>
                        {deal.sale_order.user_full_name}
                    </Link>
                </Row>
                <Row label="Заявка на покупку" className="label-row__space">
                    <Button
                        className="link-button"
                        type="link"
                        onClick={() => openOrderModal(deal.buy_order)}
                    >
                        ID {deal.buy_order.id}
                    </Button>
                </Row>
                <Row label="Заявка на продажу" className="label-row__space">
                    <Button
                        className="link-button"
                        type="link"
                        onClick={() => openOrderModal(deal.sale_order)}
                    >
                        ID {deal.sale_order.id}
                    </Button>
                </Row>
                <Divider />
                <CommonInfoBlock deal={deal} />
                {/* TODO комисии буду позже */}
                {/*
                    <Divider />
                    <Row label="Комиссии" />
                    <Row label="Размещение заявки на Витрине предложений"><Price /></Row>
                    <Row label="Проведение сделки (Покупатель)"><Price /></Row>
                    <Row label="Проведение сделки (Продавец)"><Price /></Row>*/}
            </BaseModal>
            <SecondaryOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={currentOrder}
                secondModal
            />
        </>
    )
}

export const secondaryModal = create(SecondaryModal)
