import { Case, ClockIcon, LockIcon } from '../../../Icons'
import { Tooltip } from '../../../Tooltip'
import './style.less'

export const DetailsAmountHeader = () => (
    <span className="header-сell">
        из них
        <Tooltip title="Количество ЦФА, доступных для проведения операций">
            <Case className="header-сell__icon header-сell__icon--active" />
        </Tooltip>
        <Tooltip title="Количество ЦФА, зарезервированных для проведения операций">
            <ClockIcon className="header-сell__icon header-сell__icon--passive" />
        </Tooltip>
        <Tooltip title="Количество ЦФА, заблокированных обремененных ЦФА по судебным и исполнительным производствам">
            <LockIcon className="header-сell__icon header-сell__icon--danger" />
        </Tooltip>
    </span>
)
