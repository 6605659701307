import { IAuthData, IRequest } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

export async function fetchAuthData(userUUID: string): Promise<IRequest<ResponseItem<IAuthData>>> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersAuthorizationDataById, userUUID),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
