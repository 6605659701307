/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { MoneyAccountFull } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IMoneyAccountsInitialState {
    isLoading: boolean
    error: null | string
    items: MoneyAccountFull[] | null
}

const initialState: IMoneyAccountsInitialState = {
    isLoading: false,
    error: null,
    items: [],
}

const accounts = createSlice({
    name: 'moneyAccounts',
    initialState,
    reducers: {
        setLoading(state, _action: PayloadAction) {
            state.isLoading = true
            state.error = null
            state.items = null
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
            state.isLoading = false
            state.items = null
        },
        setItems(state, action: PayloadAction<MoneyAccountFull[]>) {
            state.items = action.payload
            state.isLoading = false
            state.error = null
        },
        clear(state, _action: PayloadAction) {
            state.isLoading = false
            state.error = null
            state.items = null
        },
        load: (_, action: PayloadAction<unknown>) => {},
    },
})

export const { actions } = accounts

export default accounts.reducer
