import { IUsersRegistryListItem, UserRoles, UsersRegistryStatus } from '@dltru/dfa-models'
import { createSelector } from '@reduxjs/toolkit'

import IAppState from '../states'
import { allowStatuses, weightStatuses } from './const'

const selectDataList = (state: IAppState) => state.clientsRegistry.items
const selectIsLoading = (state: IAppState) => state.clientsRegistry.isLoading
const selectError = (state: IAppState) => state.clientsRegistry.error
const selectPaging = createSelector(
    (state: IAppState) => state.clientsRegistry.paging,
    (state: IAppState) => state.clientsRegistry.limit,
    (paging, limit) => ({
        paging,
        limit,
    }),
)

const clientsUsersRegistry = createSelector(selectDataList, (items): IUsersRegistryListItem[] => {
    const filteredItems = items.filter((item) => {
        if (!item.is_emitter && !item.is_exchange_operator && !item.is_owner) {
            return false
        }

        if (item.user_role === UserRoles.CLIENT && item.user_identification_date) {
            return true
        }

        if (
            item.user_role === UserRoles.CLIENT_FULL_BLOCK ||
            item.user_role === UserRoles.CLIENT_PARTLY_BLOCK
        ) {
            return true
        }

        return false
    })

    const itemsWithStatuses = filteredItems.map((item) => {
        const status = (Object.keys(item) as Array<keyof typeof item>)
            .filter((key) => {
                if (allowStatuses.includes(key) && item[key]) {
                    return true
                }

                return false
            })
            .sort(
                (a, b) =>
                    weightStatuses[a as UsersRegistryStatus] -
                    weightStatuses[b as UsersRegistryStatus],
            ) as UsersRegistryStatus[]

        if (!status.length) {
            status.push(UsersRegistryStatus.is_member)
        }

        let block_type = undefined

        if (
            item.user_role === UserRoles.CLIENT_FULL_BLOCK ||
            item.user_role === UserRoles.CLIENT_PARTLY_BLOCK
        ) {
            block_type = item.user_role
        }

        return { ...item, status, block_type }
    }) as IUsersRegistryListItem[]

    return itemsWithStatuses
})

export const clientsRegistrySelector = {
    selectDataList,
    selectIsLoading,
    selectError,
    clientsUsersRegistry,
    selectPaging,
}
