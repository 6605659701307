import { Button, ButtonProps } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'

import style from './style.m.less'

interface ITabButton extends ButtonProps {
    isActiveTab: boolean
}

export const TabButton: FC<ITabButton> = ({ children, isActiveTab, ...props }) => {
    return (
        <Button className={clsx(style.tabButton, isActiveTab && style.tabButtonActive)} {...props}>
            {children}
        </Button>
    )
}
