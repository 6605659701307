import { Box, Col, InfoCardsDfa, Row } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAcceptorDFA, getOfferentDFA } from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

import { EmissionDecisionCardForOwnershipTransfer } from '@components/EmissionDecisionCard'

import style from './dfaInfoCard.m.less'

interface IDfasInfoCard {
    dfaId: number
    value: number
    type: 'acceptor' | 'offerent'
}

export const DfasInfoCard: FC<IDfasInfoCard> = ({ dfaId, type, value }) => {
    const dispatch = useDispatch()
    const currentOffer = useSelector((state) => dfaTransferSelector.selectOfferByType(state, type))

    useEffect(() => {
        if (type === 'acceptor' && dfaId) {
            dispatch(getAcceptorDFA(dfaId))
        }

        if (type === 'offerent' && dfaId) {
            dispatch(getOfferentDFA(dfaId))
        }
    }, [dfaId, type])

    return (
        <Box
            padding={[0, 24, 24, 24]}
            margin={[0, 0, 8, 0]}
            className="tablebox tablebox--auto-height tablebox--shadow"
        >
            <Row gutter={16} style={{ marginTop: 16 }}>
                <Col span={24}>
                    {currentOffer?.dfa && (
                        <EmissionDecisionCardForOwnershipTransfer dfa={currentOffer.dfa} />
                    )}
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 16 }}>
                <Col span={20}>
                    <Row gutter={16} style={{ marginTop: 16 }}>
                        <InfoCardsDfa data={currentOffer?.dfa.emitter_info} />
                    </Row>
                </Col>
                <Col className={style.value} span={4}>
                    {value} ед
                </Col>
            </Row>
        </Box>
    )
}
