export enum IssueAvailableInvestorsType {
    qualified = 'qualified',
    qualified_and_unqualified = 'qualified_and_unqualified',
    qualified_and_unqualified_without_limit = 'qualified_and_unqualified_without_limit',
    qualified_and_unqualified_with_limit = 'qualified_and_unqualified_with_limit',
}

export const issueAvailableInvestorsTypeTranslate = {
    [IssueAvailableInvestorsType.qualified]: 'Только квалифицированным инвесторам',
    [IssueAvailableInvestorsType.qualified_and_unqualified]:
        'Квалифицированным и неквалифицированным инвесторам',
}

export const issueAvailableInvestorsTypeLimitsTranslate = {
    [IssueAvailableInvestorsType.qualified_and_unqualified_without_limit]:
        'без учета годового лимита',
    [IssueAvailableInvestorsType.qualified_and_unqualified_with_limit]: 'в рамках годового лимита',
}
