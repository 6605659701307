import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ArrowRightPure: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.73933 7.43049L5.37013 11.8177C5.13272 12.0561 4.74672 12.056 4.50946 11.8175C4.2738 11.5805 4.27394 11.1977 4.50977 10.9609L8.45487 7L4.50977 3.03912C4.27394 2.80234 4.2738 2.41949 4.50946 2.18254C4.74672 1.94399 5.13272 1.94386 5.37013 2.18225L9.73933 6.56951C9.97579 6.80726 9.97579 7.19274 9.73933 7.43049Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
