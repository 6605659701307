import { IClientBlockListItem } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { BlocksDetailModal } from '../BlocksDetailModal'
import { gridConfig } from './gridConfig'

export const BlocksTable: FC = () => {
    const reduxDispatch = useDispatch()
    const [isDetailsVisible, setIsDetailsVisible] = useState(false)
    const [currentBlock, setCurrentBlock] = useState<IClientBlockListItem>()
    const { data, paging, isLoading, limit } = useSelector(clientsSelector.selectBlockings)

    const onRow = (block: IClientBlockListItem) => ({
        onClick: () => {
            setCurrentBlock(block)
            setIsDetailsVisible(true)
        },
    })

    const onPerPageChange = (limit: number) => {
        reduxDispatch(clientsSlice.actions.getClientsBlockings({ limit }))
    }

    const onNext = () => {
        reduxDispatch(clientsSlice.actions.getClientsBlockings({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(clientsSlice.actions.getClientsBlockings({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(clientsSlice.actions.getClientsBlockings({ limit }))
        return () => {
            reduxDispatch(clientsSlice.actions.clearData())
        }
    }, [])

    return (
        <>
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                onRow={onRow}
                clickableRow
            />
            <BlocksDetailModal
                isModalVisible={isDetailsVisible}
                setIsModalVisible={setIsDetailsVisible}
                blockInfo={currentBlock}
            />
        </>
    )
}
