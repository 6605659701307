import { UserRolesByScope } from '@dltru/dfa-models'
import { isTokenTemporary, parseToken } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

export const isValidToken = (token: string) => {
    const exp = parseToken(token)?.[1]?.exp
    const diffMinutes = dayjs.unix(exp).diff(dayjs(), 'minutes')
    if (diffMinutes < 0) {
        localStorage.removeItem('accessToken')
    }
    return diffMinutes > 0
}

export const isAuthenticated = () => {
    const token = localStorage.getItem('accessToken')
    return token && !isTokenTemporary(token) && isValidToken(token)
}

export const getRoleFromScope = (scopes: string): UserRolesByScope => {
    const _scopes = scopes.split(' ')
    const result = ['super', 'teller', 'lawyer', 'spec', 'infosec'].reduce((acc, curr) => {
        if (_scopes.includes(curr)) {
            acc = curr
        }

        return acc
    }, UserRolesByScope.DEFAULT)

    if (!result) {
        return UserRolesByScope.DEFAULT // костыль
    }

    return result as UserRolesByScope
}
