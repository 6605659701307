import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CopyMini: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.50175 4C10.159 4.06437 10.9521 4.97901 11.0018 7.5V10.5C11.0414 12.7099 10.4828 13.941 8.00195 14H6.00195C3.52108 14.0536 3.04165 12.7045 3.00195 10.5L3.00194 7.5C2.95233 4.97901 3.85463 4.13409 5.50193 4H8.50175Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.9992 11V6C12.9496 3.47901 11.1582 2.06437 9.50098 2H7"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
