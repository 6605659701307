/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    BankAccountDetailsModel,
    GetNominalAccountsParams,
    ICursorPaginationResponse,
    MoneyAccountByNominal,
    NominalAccountModel,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type CurrentItemType = BankAccountDetailsModel & {
    id?: string
    created_at?: number
}
interface IInitialState {
    isLoading: boolean
    data: Partial<ICursorPaginationResponse<NominalAccountModel>>
    currentItem: CurrentItemType | null
    currentRelatedAccount: MoneyAccountByNominal[]
    error: string
    params: GetNominalAccountsParams
}
const initialState: IInitialState = {
    isLoading: false,
    error: '',
    data: {},
    params: { limit: DEFAULT_PER_PAGE },
    currentItem: null,
    currentRelatedAccount: [],
}

export const nominalAccountsSlice = createSlice({
    name: 'nominalAccounts',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        clearItems: (state) => {
            state.error = ''
            state.isLoading = false
            state.data = {}
            state.params = initialState.params
        },
        getItems: (state, action: PayloadAction<GetNominalAccountsParams | undefined>) => {
            state.params = isEmptyValue(action.payload)
                ? initialState.params
                : (action.payload as GetNominalAccountsParams)
        },
        setItems: (
            state,
            action: PayloadAction<ICursorPaginationResponse<NominalAccountModel>>,
        ) => {
            state.data.items = action.payload.items
            state.data.paging = action.payload.paging
        },
        clearItem: (state) => {
            state.error = ''
            state.isLoading = false
            state.currentItem = null
            state.currentRelatedAccount = []
        },
        getItem: (_, action: PayloadAction<string>) => {},
        setItem: (state, action: PayloadAction<CurrentItemType>) => {
            state.currentItem = action.payload
        },
        getRelatedAccountsByNumber: (_, action: PayloadAction<string>) => {},
        setRelatedAccounts: (state, action: PayloadAction<MoneyAccountByNominal[]>) => {
            state.currentRelatedAccount = action.payload
        },
        postItem: () => {},
    },
})

export default nominalAccountsSlice.reducer
