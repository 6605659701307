import { ISecondaryOrder, secondaryOrderStatusCancelable } from '@dltru/dfa-models'
import { Button, DeleteIcon } from '@dltru/dfa-ui'
import React, { FC } from 'react'

import './style.less'

interface IComponentProps {
    order: ISecondaryOrder
    actionCancel: (order: ISecondaryOrder) => void
}
export const SecondaryOrderActionButton: FC<IComponentProps> = ({ order, actionCancel }) => {
    const onClickCancel = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        actionCancel(order)
    }

    return (
        <div className="visible-only-row-hover">
            {secondaryOrderStatusCancelable.includes(order.status) && (
                <Button
                    onClick={onClickCancel}
                    className="grid-action-button"
                    size="small"
                    borderRadius={8}
                >
                    <DeleteIcon />
                </Button>
            )}
        </div>
    )
}
