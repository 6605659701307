import { TransferOrderStatus } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { transferDfaCancelByOperator } from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

import { TransferCancelModal } from './TransferCancelModal'

export const Footer: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const dispatch = useDispatch()
    const dfaOwnershipTransferData = useSelector(dfaTransferSelector.selectData)
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const showCancelBtn =
        dfaOwnershipTransferData?.status === TransferOrderStatus.placed &&
        !dfaOwnershipTransferData?.noCancel &&
        isAllow(['exchange:status'])

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {showCancelBtn && (
                            <Button borderRadius={12} onClick={() => setIsCancelModalVisible(true)}>
                                Отменить оферту
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
            <TransferCancelModal
                isModalVisible={isCancelModalVisible}
                setIsModalVisible={setIsCancelModalVisible}
                onOk={(skid: string) => {
                    dispatch(
                        transferDfaCancelByOperator({
                            external_deal_id: `${dfaOwnershipTransferData.uuid}`,
                            skid,
                        }),
                    )
                }}
            />
        </>
    )
}
