import { ProfileStatus } from '@dltru/dfa-models'
import { Button, KeyIcon } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { RemoveUserModal } from '../../RemoveClient'

export const RemoveClientAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const currentStatus = useSelector(clientsSelector.selectCurrentStatus)
    const isCurrentClientPartlyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientPartlyBlocked,
    )
    const isCurrentClientFullyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientFullyBlocked,
    )
    const isVisibleUnblockClient = isCurrentClientPartlyBlocked || isCurrentClientFullyBlocked
    const isVisibleBtn = !isVisibleUnblockClient && currentStatus === ProfileStatus.APPROVED
    if (!isVisibleBtn) {
        return null
    }

    return (
        <>
            <Button icon={<KeyIcon />} borderRadius={12} onClick={() => setIsModalVisible(true)}>
                Исключить из реестра пользователей
            </Button>
            <RemoveUserModal visible={isModalVisible} setVisible={setIsModalVisible} />
        </>
    )
}
