import IAppState from '../states'

const selectUserUid = (state: IAppState) => state.auth.uuid
const selectUserInfo = (state: IAppState) => state.auth
const selectUserCertificates = (state: IAppState) => state.auth.certificates.items
const selectUserCertificatesLoaded = (state: IAppState) => state.auth.certificates.isLoaded
const selectChangeAuthState = (state: IAppState) => state.auth.changeAuthState
const selectRole = (state: IAppState) => state.auth.role
const selectIsAuth = (state: IAppState) => state.auth.isAuth
const selectUserName = (state: IAppState) => state.auth.user
const selectProfile = (state: IAppState) => state.auth.profile
const selectSessionTimeout = (state: IAppState) => state.auth.session_time_out

export const authSelector = {
    selectUserUid,
    selectUserInfo,
    selectUserCertificates,
    selectUserCertificatesLoaded,
    selectChangeAuthState,
    selectRole,
    selectIsAuth,
    selectUserName,
    selectProfile,
    selectSessionTimeout,
}
