import { TransactionRow, serviceCodeTranslate } from '@dltru/dfa-models'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC } from 'react'

import { Divider } from '../../../Divider'
import { Wallet } from '../../../Icons'
import { ColorModal } from '../../../components/Modal'
import { RUS_DATETIME_SEC_FORMAT, getFactorialPart, getIntegerPart } from '../../../utils'
import { Status } from './Status'
import TypedValue from './Value'
import style from './style.m.less'
import { getDetailByType } from './types'

export interface ITransactionDetailsModalProps {
    isModal: boolean
    onClose: () => void
    data?: TransactionRow
    wallet: string
}

export const TransactionDetailsModal: FC<ITransactionDetailsModalProps> = ({
    isModal,
    onClose,
    wallet,
    data,
}) => {
    const props = {
        wallet,
        requisites: data?.requisites || [],
        dealsRequisites: undefined,
        title: data?.title ?? '',
        subtitle: data?.serviceCode ? serviceCodeTranslate[data.serviceCode] : '',
        total: data?.total || 0,
        type: getDetailByType(data?.type),
        special: dayjs.unix(data?.timestamp ?? 0)?.format(RUS_DATETIME_SEC_FORMAT),
        id: data?.id ?? '',
    }
    const Header = (
        <div className={style.header}>
            <div className={clsx(style.subtitle, style[`subtitle${props.type}`])}>
                {props.subtitle}
            </div>
            <div className={clsx(style.title, style[`title${props.type}`])}>{props.title}</div>
            <div className={clsx(style.special, style[`special${props.type}`])}>
                {props.special}
            </div>
        </div>
    )
    const Body = (
        <div className={style.body}>
            <Status type={data?.status} />
            <div className={style.fiatDelta}>
                {getIntegerPart(props.total, true)}
                <span className={style.shadowed}>{getFactorialPart(props.total)}</span>
            </div>
            <div className={style.wallet}>
                <div className={style.icon}>
                    <Wallet />
                </div>
                <div className={style.title}>{props.wallet}</div>
            </div>
            <Divider />
            <div className={style.requisites}>
                {props.requisites?.map((data) => (
                    <TypedValue key={data.value} {...data} />
                ))}
            </div>
            {props.dealsRequisites && (
                <>
                    <Divider />
                    <div className={style.requisites}>
                        {props.requisites?.map((data) => (
                            <TypedValue key={data.value} {...data} />
                        ))}
                    </div>
                </>
            )}
        </div>
    )

    return (
        <ColorModal
            isModalVisible={isModal}
            setIsModalVisible={onClose}
            header={Header}
            body={Body}
            width={540}
            headerColor={props.type === 'positive' ? 'green' : 'gray'}
        />
    )
}
