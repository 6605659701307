import { DfaStatusEnum } from '@dltru/dfa-models'
import { Button, CaseAdd } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { ResumptionIssueModal } from '../Modals'

export const ResumptionIssueAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    if (dfaDetails.status !== DfaStatusEnum.issue_stopped) {
        return null
    }

    return (
        <>
            <Button
                icon={<CaseAdd />}
                borderRadius={16}
                type="primary"
                onClick={() => setIsModalVisible(true)}
            >
                Возобновить выпуск
            </Button>
            <ResumptionIssueModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
