import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC } from 'react'

interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    action: () => void
}

export const ToLawyerModal: FC<IComponentProps> = ({ visible, setVisible, action }) => {
    const onCancel = () => {
        setVisible(false)
    }

    return (
        <MiniModal visible={visible} onCancel={onCancel} width={423}>
            <div>
                <Box direction="row" align="start">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">Отправить анкету на правовую экспертизу</div>
                </Box>
                <Box margin={[24, 0, 24, 40]}>
                    Вы действительно хотите отправить Анкеты на правовую экспертизу юристу?
                </Box>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[0, 8, 8, 8]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button borderRadius={12} type="primary" onClick={action}>
                        Да, отправить
                    </Button>
                </Box>
            </div>
        </MiniModal>
    )
}
