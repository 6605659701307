// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".action-button_dj6ej {\n  border: none;\n  padding: 0;\n  font: inherit;\n  outline: inherit;\n  background: white;\n  display: flex;\n  width: 24px;\n  height: 24px;\n  align-items: center;\n  justify-content: center;\n  color: #303440;\n  cursor: pointer;\n  border-radius: 8px;\n}\n.action-button_dj6ej.inner_dO_U3 {\n  color: #303440;\n}\n.action-button_dj6ej:hover {\n  color: #007ef2;\n  border: 1px solid #007ef2;\n  border-radius: 8px;\n}\n.action-button_dj6ej:hover.inner_dO_U3 {\n  color: #007ef2;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ActionButton/style.m.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,UAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;AADF;AAGE;EACE,cAAA;AADJ;AAIE;EACE,cAAA;EACA,yBAAA;EACA,kBAAA;AAFJ;AAII;EACE,cAAA;AAFN","sourcesContent":["@import 'style/palette';\n\n.action-button {\n  border: none;\n  padding: 0;\n  font: inherit;\n  outline: inherit;\n  background: white;\n  display: flex;\n  width: 24px;\n  height: 24px;\n  align-items: center;\n  justify-content: center;\n  color: @gray-10;\n  cursor: pointer;\n  border-radius: 8px;\n\n  &.inner {\n    color: @gray-10;\n  }\n\n  &:hover {\n    color: @primary-6;\n    border: 1px solid @primary-6;\n    border-radius: 8px;\n\n    &.inner {\n      color: @primary-6;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-button": "action-button_dj6ej",
	"inner": "inner_dO_U3"
};
export default ___CSS_LOADER_EXPORT___;
