import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const SignIn: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="currentColor"
                    d="M13.248 12.8008C13.7206 12.5469 14.2616 12.4023 14.8378 12.4023H14.8398C14.8984 12.4023 14.9257 12.332 14.8827 12.293C14.2835 11.7552 13.5991 11.3209 12.8573 11.0078C12.8495 11.0039 12.8417 11.002 12.8339 10.998C14.0468 10.1172 14.8359 8.68555 14.8359 7.07031C14.8359 4.39453 12.6718 2.22656 10.0019 2.22656C7.33196 2.22656 5.16985 4.39453 5.16985 7.07031C5.16985 8.68555 5.95891 10.1172 7.17376 10.998C7.16594 11.002 7.15813 11.0039 7.15032 11.0078C6.27727 11.377 5.49407 11.9062 4.82024 12.582C4.1503 13.2508 3.61695 14.0435 3.24993 14.916C2.88882 15.7704 2.69393 16.6859 2.67571 17.6133C2.67519 17.6341 2.67884 17.6549 2.68646 17.6743C2.69408 17.6937 2.70551 17.7114 2.72007 17.7263C2.73463 17.7412 2.75203 17.7531 2.77125 17.7612C2.79046 17.7693 2.81111 17.7734 2.83196 17.7734H4.00188C4.08586 17.7734 4.15618 17.7051 4.15813 17.6211C4.19719 16.1133 4.80071 14.7012 5.86907 13.6309C6.97258 12.5234 8.44133 11.9141 10.0038 11.9141C11.1113 11.9141 12.1738 12.2207 13.0898 12.7949C13.1133 12.8097 13.1403 12.818 13.1681 12.8191C13.1959 12.8201 13.2234 12.8138 13.248 12.8008ZM10.0038 10.4297C9.1093 10.4297 8.26751 10.0801 7.63274 9.44531C7.32043 9.13381 7.07283 8.76359 6.90422 8.35597C6.73561 7.94836 6.64932 7.51142 6.65032 7.07031C6.65032 6.17383 6.99993 5.33008 7.63274 4.69531C8.26555 4.06055 9.10735 3.71094 10.0038 3.71094C10.9003 3.71094 11.7402 4.06055 12.3749 4.69531C12.6872 5.00682 12.9348 5.37704 13.1034 5.78465C13.2721 6.19226 13.3583 6.62921 13.3574 7.07031C13.3574 7.9668 13.0077 8.81055 12.3749 9.44531C11.7402 10.0801 10.8984 10.4297 10.0038 10.4297Z"
                />
                <path
                    fill="currentColor"
                    d="M18.4507 15.9636H12.3179V14.4792C12.3179 14.3483 12.1655 14.2741 12.064 14.3562L9.2925 16.5437C9.27382 16.5583 9.25872 16.5769 9.24833 16.5983C9.23794 16.6196 9.23254 16.643 9.23254 16.6667C9.23254 16.6904 9.23794 16.7138 9.24833 16.7351C9.25872 16.7565 9.27382 16.7751 9.2925 16.7897L12.064 18.9772C12.1675 19.0593 12.3179 18.9851 12.3179 18.8542V17.3698H18.4507C18.5366 17.3698 18.6069 17.2995 18.6069 17.2136V16.1198C18.6069 16.0339 18.5366 15.9636 18.4507 15.9636Z"
                />
            </svg>
        )}
        {...props}
    />
)
