import {
    BeneficialOwner,
    BusinessmanProfileChangeModel,
    ClientAutocomplete,
    GetProfileApplicationsParams,
    GetProfilesParams,
    GetUidsByInnTypeParams,
    IAgentProfile,
    IApprovalHistoryItem,
    IBusinessmanProfile,
    IBusinessmanProfilePublic,
    IChangeProfile,
    IClientBlockListItem,
    ICursorPaginationResponse,
    IDataForUserBlocking,
    IDataForUserBlockingEvents,
    IFullUser,
    IIndividualProfile,
    IIndividualProfilePublic,
    ILegalEntityProfile,
    ILegalEntityProfilePublic,
    IProfileApplication,
    IRegistrationApplication,
    IRequest,
    IUsersRegistry,
    IdentificationType,
    IndividualProfileChangeModel,
    LegalEntityProfileChangeModel,
    NodeAndExchangeOperatorUid,
    PatchPrivateProfileType,
    PatchProfileType,
    PrivateInformationLegalEntityResponse,
    ProfileCoreRequestType,
    ProfileListType,
    ProfileRequestType,
    ProfileType,
    ProfileTypeData,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem, ResponseItems } from '../../types/types'
import { path } from '../../utils'

export async function getProfileTypeByUserService(
    uuid: string,
): Promise<IRequest<ResponseItems<ProfileTypeData>>> {
    return requestDecorator.call(
        this,
        `${this.paths.ProfileType}?user_uuids[]=${uuid}`,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

type ReturnTypeProfile<T> = T extends ProfileRequestType.LEGAL_ENTITY
    ? ILegalEntityProfile
    : T extends ProfileRequestType.INDIVIDUAL
    ? IIndividualProfile
    : T extends ProfileRequestType.BUSINESSMAN
    ? IBusinessmanProfile
    : T extends ProfileRequestType.AGENT
    ? IAgentProfile
    : never
// не используется
export async function getProfileService<T extends Exclude<ProfileRequestType, 'beneficial_owner'>>(
    profileType: T,
    uuid: string,
): Promise<IRequest<ResponseItem<ReturnTypeProfile<T>>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileByTypeAndId, profileType, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

type ReturnTypePublicProfile<T> = T extends ProfileRequestType.LEGAL_ENTITY
    ? ILegalEntityProfilePublic
    : T extends ProfileRequestType.INDIVIDUAL
    ? IIndividualProfilePublic
    : T extends ProfileRequestType.BUSINESSMAN
    ? IBusinessmanProfilePublic
    : never

export async function getPublicInformation<
    T extends Exclude<ProfileRequestType, 'agent' | 'beneficial_owner'>,
>(profileType: T, uuid: string): Promise<IRequest<ResponseItem<ReturnTypePublicProfile<T>>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfilePublicInformationByTypeAndId, profileType, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function patchProfileService<
    T extends Exclude<ProfileRequestType, 'beneficial_owner'>,
>(
    profileType: T,
    uuid: string,
    data: PatchProfileType,
): Promise<IRequest<ResponseItem<ReturnTypeProfile<T>>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileByTypeAndId, profileType, uuid),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function postApproveAnketaServiceV2(user_uuid: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.ProfileChangeStatusV2,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}

export async function changeAnketaStatusService(data?: IChangeProfile): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.ProfileChangeStatus,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function checkExistenceOfInnService(
    profile_type: ProfileType,
    inn: string,
): Promise<IRequest<ResponseItem<boolean>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileCheckInn,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        {
            params: {
                profile_type,
                inn,
            },
        },
    )
}
type CheckActiveSessionResponse =
    | {
          code: string
          message: string
      }
    | undefined
export async function checkClientForActiveSessionService(
    uuid: string,
): Promise<IRequest<CheckActiveSessionResponse>> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersCheckSessionById, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getProfileStatuses(): Promise<
    IRequest<ResponseItems<IRegistrationApplication>>
> {
    return requestDecorator.call(
        this,
        this.paths.ProfileClientStatuses,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getStatusesProfileService(
    uid: string,
): Promise<IRequest<ResponseItems<IApprovalHistoryItem>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileApprovalHistory, uid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        null,
        false,
    )
}

export async function makeForcedEndOfSessionService<T>(user_uuid: string): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        this.paths.UsersBlockSession,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}

export async function getProfilesService(
    params: GetProfilesParams,
): Promise<IRequest<ResponseItem<ICursorPaginationResponse<ProfileListType>>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileCommon,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        {
            params,
        },
    )
}

export async function getBeneficialOwnerService(
    uuid: string,
): Promise<IRequest<ResponseItem<BeneficialOwner>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileBeneficialOwnerByProfileId, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function makeFullBlock(data: IDataForUserBlocking): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersMakeFullBlock),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function makePartlyBlock(data: IDataForUserBlocking): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersMakePartlyBlock),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function makeFullUnblock(data: IDataForUserBlocking): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersMakeFullUnblock),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function makePartlyUnblock(data: IDataForUserBlocking): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersMakePartlyUnblock),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getUserBlockingEventByUuid(
    uuid: string,
): Promise<IRequest<ResponseItem<IClientBlockListItem>>> {
    return requestDecorator.call(
        this,
        path(this.paths.GetUserBlockingEventByUuid, uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}

export async function getUsersBlockingEvents(
    params: IDataForUserBlockingEvents,
): Promise<IRequest<ICursorPaginationResponse<IClientBlockListItem>>> {
    return requestDecorator.call(
        this,
        path(this.paths.GetUsersBlockingEvents),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

type GetUidByInnParams = {
    client_type: ProfileType
    inn: string
}
export async function getUidByInn(
    params: GetUidByInnParams,
): Promise<IRequest<ResponseItem<string>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileGetUidByInn,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getUidsByInnType(
    params: GetUidsByInnTypeParams,
): Promise<IRequest<ResponseItems<string>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileGetUidsByInnType,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

type GetClientsParamsType = {
    full_name: string
}
export async function getClientsService(
    params: GetClientsParamsType,
): Promise<IRequest<ResponseItems<ClientAutocomplete>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileClients,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function putProfileUpdateByUuidService<ClientAutocomplete>({
    user_uuid,
    ...data
}: NodeAndExchangeOperatorUid): Promise<IRequest<ResponseItem<ClientAutocomplete>>> {
    return requestDecorator.call(
        this,
        path(this.paths.PutProfileUpdateByUuid, user_uuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getFullUser(uid: string): Promise<IRequest<ResponseItem<IFullUser>>> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersFullProfile, uid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getUsersRegistry(
    params: IDataForUserBlockingEvents,
): Promise<IRequest<ICursorPaginationResponse<IUsersRegistry>>> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersRegistry),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function putBusinessmanProfile(
    data: BusinessmanProfileChangeModel,
): Promise<IRequest<ResponseItem<never>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileBusinessmanByProfileId, data.businessman.user_uuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putIndividualProfile(
    data: IndividualProfileChangeModel,
): Promise<IRequest<ResponseItem<never>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileIndividualByProfileId, data.individual.user_uuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putLegalEntityProfile(
    data: LegalEntityProfileChangeModel,
): Promise<IRequest<ResponseItem<never>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileLegalEntityByProfileId, data.legal_entity.user_uuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putAgentProfile(data: IAgentProfile): Promise<IRequest<ResponseItem<never>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileAgentByProfileId, data.uuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function putIdentification(
    userUuid: string,
    data: IdentificationType,
): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileIdentification, userUuid),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function getIdentificationInfo(
    userUuid: string,
): Promise<IRequest<IdentificationType>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileIdentification, userUuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function patchProfilePrivateInformationByTypeAndIdService({
    type,
    user_uuid,
    data,
}: {
    type: ProfileCoreRequestType
    user_uuid: string
    data: PatchPrivateProfileType
}): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfilePrivateInformationByTypeAndId, type, user_uuid),
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: data },
    )
}

export async function getProfilePrivateInformationByTypeAndIdService({
    type,
    user_uuid,
}: {
    type: ProfileCoreRequestType
    user_uuid: string
}): Promise<IRequest<ResponseItem<PrivateInformationLegalEntityResponse>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfilePrivateInformationByTypeAndId, type, user_uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getProfileShortInfo(
    params: GetProfileApplicationsParams,
): Promise<IRequest<ResponseItem<ResponseItems<IProfileApplication>>>> {
    return requestDecorator.call(
        this,
        this.paths.ProfileApplications,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getProfileAgentAgentsByCreatorIdService(
    user_uuid: string,
): Promise<IRequest<ResponseItems<IAgentProfile>>> {
    return requestDecorator.call(
        this,
        path(this.paths.ProfileAgentAgentsByCreatorId, user_uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
