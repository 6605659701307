import {
    UnionDealFront,
    getDealTypeTranslateOperator,
    isEmissionDeal,
    isRepaymentDeal,
    isSecondaryDeal,
    isTransferDeal,
} from '@dltru/dfa-models'
import {
    ColoredTag,
    ColumnsType,
    DateTimeCell,
    EMPTY_FIELD_VALUE,
    LinkCell,
    getRublesFromPennies,
} from '@dltru/dfa-ui'

const InitiatorLinkCell = (value: string, record: UnionDealFront) => {
    if (record.type === 'emission') {
        return LinkCell('Эмитент', `/clients/${record.emitter_id}`)
    }
    if (isSecondaryDeal(record)) {
        return LinkCell(record.sale_order.user_full_name, `/clients/${record.sale_order.user_id}`)
    }
    if (isTransferDeal(record)) {
        return LinkCell(record.order.seller_full_name, `/clients/${record.order.seller_id}`)
    }
    if (isRepaymentDeal(record)) {
        return LinkCell(record.user_full_name, `/clients/${record.user_id}`)
    }
    return null
}
const AcceptorLinkCell = (value: string, record: UnionDealFront) => {
    if (isEmissionDeal(record)) {
        return LinkCell(record.user_full_name, `/clients/${record.user_id}`)
    }
    if (isSecondaryDeal(record)) {
        return LinkCell(record.buy_order.user_full_name, `/clients/${record.buy_order.user_id}`)
    }
    if (isTransferDeal(record)) {
        return LinkCell(record.order.buyer_full_name, `/clients/${record.order.buyer_id}`)
    }
    if (isRepaymentDeal(record)) {
        return LinkCell('Эмитент', `/clients/${record.emitter_id}`)
    }
    return null
}

const AmountCell = (value: number, record: UnionDealFront) => (
    <ColoredTag type={record.type === 'repayment' ? 'gray' : 'blue'}>
        {value.toLocaleString()}
    </ColoredTag>
)
const DateByTypeCell = (_, record: UnionDealFront) => {
    if (isTransferDeal(record)) {
        return DateTimeCell(record.created_at)
    }

    return DateTimeCell(record.executed_at)
}
const TotalPriceCell = (_: number, record: UnionDealFront) => {
    if (record.type === 'repayment') {
        return record.total_price ? getRublesFromPennies(record.total_price) : 0
    }
    if (isTransferDeal(record)) {
        return record.total_price ? getRublesFromPennies(record.total_price) : EMPTY_FIELD_VALUE
    }
    return getRublesFromPennies(record.price_per_dfa * record.amount_dfa)
}

export const gridConfig: ColumnsType<UnionDealFront> = [
    {
        title: 'Дата и время',
        dataIndex: 'date_time',
        key: 'date_time',
        sorter: true,
        ellipsis: true,
        width: '14%',
        render: DateByTypeCell,
    },
    {
        title: 'Контрагент 1',
        dataIndex: 'initiator',
        key: 'initiator',
        sorter: true,
        ellipsis: true,
        width: '18%',
        render: InitiatorLinkCell,
    },
    {
        title: 'Контрагент 2',
        dataIndex: 'acceptor',
        key: 'acceptor',
        sorter: true,
        ellipsis: true,
        width: '18%',
        render: AcceptorLinkCell,
    },
    {
        title: 'Тип сделки',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        width: '10%',
        render: getDealTypeTranslateOperator,
    },
    {
        title: 'Кол-во, ед.',
        dataIndex: 'amount_dfa',
        key: 'amount_dfa',
        sorter: true,
        width: '10%',
        render: AmountCell,
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        render: (value: number, record: UnionDealFront) => {
            if (record.type === 'repayment') {
                return value ? getRublesFromPennies(value) : 0
            }
            return value ? getRublesFromPennies(value) : EMPTY_FIELD_VALUE
        },
        width: '10%',
    },
    {
        title: 'Сумма сделки, ₽',
        dataIndex: 'sum',
        key: 'sum',
        sorter: true,
        width: '10%',
        render: TotalPriceCell,
    },
]
