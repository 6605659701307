import { IDfaFront } from '@dltru/dfa-models'
import { Box, DEFAULT_PER_PAGE, PageHeader, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { dropDfaList, loadDfas } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'
import { notificationCounterSlice } from '@store/notificationCounter'

import { dropDfaDetails } from '../../store/dfa/details'
import '../styles/style.less'
import { gridConfig } from './gridConfig'

const Registry: FC = () => {
    const reduxDispatch = useDispatch()
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(loadDfas({ limit }))
    }

    const onNext = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.prev }))
    }
    const navigate = useNavigate()

    useEffect(() => {
        reduxDispatch(loadDfas({ limit: DEFAULT_PER_PAGE }))
        reduxDispatch(dropDfaDetails())
        reduxDispatch(notificationCounterSlice.actions.getRegistrationDfaCount())
        return () => {
            reduxDispatch(dropDfaList())
        }
    }, [])

    const onRow = (order: IDfaFront) => ({
        onClick: () => {
            navigate(`/offers/${order.id}`)
        },
    })

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Реестр ЦФА" />
            </Box>

            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={items ?? []}
                    cursors={paging?.cursors}
                    onPerPage={onPerPageChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    onRow={onRow}
                    clickableRow
                    rowKey="id"
                />
            </Box>
        </>
    )
}

export default Registry
