import { Box, FormItemProps } from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadInputForm } from '../UploadInputForm'

export const UploadDocumentWrapper: FC<{
    name: FormItemProps['name']
    title: string
    uneditable?: boolean
    ordinaryOrder?: boolean
    noWrapper?: boolean
    noFormShift?: boolean
    required?: boolean
}> = ({ name, title, uneditable, ordinaryOrder, noWrapper, noFormShift, children, required }) => {
    return (
        <>
            <Box direction="column">
                <Box margin={[0, 0, 0, uneditable || ordinaryOrder ? 0 : 240]}>{children}</Box>
                <Box margin={[0, 0, 0, noFormShift && uneditable ? 0 : 240]}>
                    <div className="formRow">
                        <UploadInputForm
                            name={name}
                            title={title}
                            uneditable={uneditable}
                            noWrapper={noWrapper}
                            required={required}
                        />
                    </div>
                </Box>
            </Box>
        </>
    )
}
