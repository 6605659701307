import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ArrowUpSmall: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.50802 5.53597L3.82801 9.196C3.55525 9.46728 3.55525 9.90869 3.82801 10.18C4.09868 10.4492 4.53597 10.4492 4.80664 10.18L8.00001 7.00393L11.1934 10.18C11.4641 10.4492 11.9013 10.4492 12.172 10.18C12.4448 9.90869 12.4448 9.46728 12.172 9.196L8.492 5.53597C8.22028 5.26573 7.77974 5.26573 7.50802 5.53597Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
