import { XOR } from '@dltru/dfa-models'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Export } from '../../../Icons'
import { Tooltip } from '../../../Tooltip'
import { ColorModal, IColorModalProps } from '../../../components/Modal'
import { VIEW_DATE_FORMAT } from '../../../constants'
import { CaseBadge } from '../../../tags'
import { HeaderBadge, Price } from './Details'
import './style.less'

type HeaderData = {
    linkToEmission: string
    linkToEmissionID: number
    linkToInvestor?: string
    linkToInvestorID?: string
    title: string
    price: number
    isDynamicPrice: boolean
    textForRedemption?: string
    date: number
} & XOR<{ valueInCase: number }, { percentInCase: number }>

export interface ICardModal extends Omit<IColorModalProps, 'setIsModalVisible'> {
    headerData: HeaderData
    BodyRenderProps?: any
    buying?: boolean
    selling?: boolean
    isEmission?: boolean
    mini?: boolean
    secondModal?: boolean
    setIsModalVisible: (val: boolean) => void
}

export const CardModal: FC<ICardModal> = ({
    isModalVisible,
    setIsModalVisible,
    headerData,
    BodyRenderProps,
    buying,
    selling,
    isEmission,
    mini,
    secondModal,
    closable = false,
    width = 600,
    ...restProps
}) => {
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const navigate = useNavigate()

    const clickEmissionHandler = (e: React.SyntheticEvent): void => {
        e.preventDefault()
        navigate(`/offers/${headerData.linkToEmissionID}`)
        setIsModalVisible(false)
    }

    const clickInvestorHandler = (e: React.SyntheticEvent): void => {
        e.preventDefault()
        navigate(`/clients/${headerData.linkToInvestorID}`)
        setIsModalVisible(false)
    }

    const headerInfoBlockClassName = clsx({
        'card-modal-header__info-block': true,
        'card-modal-header__info-block--with-margin-bottom': !buying && !selling,
    })

    const percentInCase = typeof headerData.percentInCase === 'number'

    const isNoBuyOrNoSell = !buying && !selling
    const headerClassName = clsx({
        'card-modal__margin-bottom': mini,
    })
    const redeemTextClassName = clsx('card-modal-header__block--header', {
        'card-modal-header__block--white': !isNoBuyOrNoSell,
    })

    const Header = (
        <div className={headerClassName}>
            <a
                href="/"
                className="card-modal-header__link-to--emission"
                onClick={clickEmissionHandler}
            >
                {headerData.linkToEmission} {isNoBuyOrNoSell && <Export />}
            </a>
            <p className="card-modal-header__title">{headerData.title}</p>
            {headerData.linkToInvestor && (
                <a
                    href="/"
                    className="card-modal-header__link-to--investor"
                    onClick={clickInvestorHandler}
                >
                    {headerData.linkToInvestor} {isNoBuyOrNoSell && <Export />}
                </a>
            )}
            {!mini && (
                <div className={headerInfoBlockClassName}>
                    <div className="card-modal-header__block">
                        {headerData.isDynamicPrice ? (
                            <>
                                <p className="card-modal-header__block--header">
                                    ЦФА будут погашены&nbsp;
                                    {dayjs(headerData.date).format(VIEW_DATE_FORMAT)}
                                </p>
                                <Tooltip title={headerData.textForRedemption} width={400}>
                                    <p className={redeemTextClassName}>
                                        {headerData.textForRedemption}
                                    </p>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <p className="card-modal-header__block--header">Погашение</p>
                                <Price white={buying || selling} price={headerData.price} />
                                <p className="card-modal-header__block--date">
                                    {dayjs(headerData.date).format(VIEW_DATE_FORMAT)}
                                </p>
                            </>
                        )}
                    </div>

                    <div className="card-modal-header__block--case">
                        <p className="card-modal-header__block--header">
                            {percentInCase ? 'Процент выкупа' : 'В кошельке'}
                        </p>
                        <CaseBadge
                            count={headerData.percentInCase ?? headerData.valueInCase}
                            percentInCase={percentInCase}
                            buying={buying}
                            selling={selling}
                        />
                    </div>
                </div>
            )}
            {!mini && (buying || selling) && (
                <HeaderBadge buying={buying} selling={selling} isEmission={isEmission} />
            )}
        </div>
    )

    return (
        <ColorModal
            {...restProps}
            isModalVisible={isModalVisible}
            style={secondModal ? { left: 240 } : {}}
            header={Header}
            body={BodyRenderProps}
            width={width}
            headerColor={buying ? 'buying' : selling ? 'selling' : undefined}
            centered
            closable={closable}
            onCancel={handleCancel}
        />
    )
}
