import { Badge, Box } from '@dltru/dfa-ui'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import style from '../style.m.less'

interface ISidebarLink {
    to: string
    count?: number
}

export const SidebarLink: FC<ISidebarLink> = ({ children, to, count }) => {
    return (
        <Box direction="row" justify="space-between" align="center">
            <div style={{ width: '137px', lineHeight: '22px' }}>
                <Link className={style.sidebarLink} to={to}>
                    {children}
                </Link>
            </div>
            {count && (
                <Badge
                    style={{ background: 'rgb(0 126 242 / 50%)' }}
                    count={count}
                    size="default"
                />
            )}
        </Box>
    )
}
