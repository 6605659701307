import { IDfaBack, dfaBackToFront } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { dfaListSlice, loadDfasEmission } from './index'

function* handleLoadDfas({ payload }: ReturnType<typeof dfaListSlice.actions.loadDfas>) {
    try {
        yield* put(dfaListSlice.actions.updateDfaList({ error: '', isLoading: true }))
        const { data, error } = yield* call(api.lib.getDfaList, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }

        if (data?.items) {
            data.items = data.items.map((dfa) => dfaBackToFront(dfa))
            yield* put(dfaListSlice.actions.updateDfaList({ isLoading: false, data }))
        } else {
            yield* put(
                dfaListSlice.actions.updateDfaList({ isLoading: false, data: { items: [] } }),
            )
        }
    } catch (error) {
        yield* put(
            dfaListSlice.actions.updateDfaList({ error, isLoading: false, data: { items: [] } }),
        )
    }
}

function* handleLoadDfasEmission({ payload }: ReturnType<typeof loadDfasEmission>) {
    try {
        yield* put(dfaListSlice.actions.updateDfaList({ error: '', isLoading: true }))
        const { data, error } = yield api.lib.getDfaList<{ items: IDfaBack[] }>(payload)

        if (error) {
            throw error
        }

        data.items = data.items.map((dfa: IDfaBack) => dfaBackToFront(dfa))

        yield* put(dfaListSlice.actions.updateDfaList({ isLoading: false, data }))
    } catch (error) {
        yield* put(dfaListSlice.actions.updateDfaList({ error, isLoading: false, data: [] }))
    }
}

export function* watchDfaList(): Generator<StrictEffect> {
    yield* takeLatest(loadDfasEmission.type, handleLoadDfasEmission)
    yield* takeLatest(dfaListSlice.actions.loadDfas.type, handleLoadDfas)
}
