import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const StampIcon: FC<IconProps> = (props) => (
    <AntIcon
        {...props}
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.8325 5.83382V7.70055M6.64899 12.8341H5.24894C2.33217 12.8341 1.16547 11.6674 1.16547 8.75059V5.25047C1.16547 2.3337 2.33217 1.16699 5.24894 1.16699H8.16571"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.8321 5.83382H10.4986C8.74858 5.83382 8.16522 5.25047 8.16522 3.50041V1.16699L12.8321 5.83382Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.2002 11.1997V9.09961M11.2002 11.1997C10.1503 11.1997 9.10004 11.1997 9.10013 11.7247C9.10004 12.2497 9.10017 12.5738 9.10017 12.7747C9.62569 12.7747 12.2503 12.7747 13.3007 12.7747C13.3007 12.5632 13.3002 12.2497 13.3002 11.7247C13.3002 11.1997 12.2502 11.1997 11.2002 11.1997Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
