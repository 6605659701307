import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const StatusNegative: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.6668 16C30.6668 24.1002 24.1003 30.6666 16.0002 30.6666C7.89999 30.6666 1.3335 24.1002 1.3335 16C1.3335 7.8998 7.89999 1.33331 16.0002 1.33331C24.1003 1.33331 30.6668 7.8998 30.6668 16ZM28.6668 16C28.6668 22.9956 22.9958 28.6666 16.0002 28.6666C9.00456 28.6666 3.3335 22.9956 3.3335 16C3.3335 9.00437 9.00456 3.33331 16.0002 3.33331C22.9958 3.33331 28.6668 9.00437 28.6668 16ZM10.9923 10.9921C11.4262 10.5582 12.1297 10.5582 12.5636 10.9921L16.0002 14.4286L19.4367 10.9921C19.8706 10.5582 20.5741 10.5582 21.0081 10.9921C21.442 11.426 21.442 12.1295 21.0081 12.5634L17.5715 16L21.0081 19.4365C21.442 19.8704 21.442 20.574 21.0081 21.0079C20.5741 21.4418 19.8706 21.4418 19.4367 21.0079L16.0002 17.5713L12.5636 21.0079C12.1297 21.4418 11.4262 21.4418 10.9923 21.0079C10.5584 20.574 10.5584 19.8704 10.9923 19.4365L14.4288 16L10.9923 12.5634C10.5584 12.1295 10.5584 11.426 10.9923 10.9921Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
