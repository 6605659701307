import { DealsTypeTranslate, IInvestorDeals, InvestorDealsType } from '@dltru/dfa-models'
import {
    ColoredTag,
    ColumnsType,
    DateTimeCell,
    LinkCell,
    Lock16,
    getRublesFromPennies,
} from '@dltru/dfa-ui'

const UserCell = (value: string, clientId: string, isBlocked: boolean) => {
    if (isBlocked) {
        return LinkCell(value, `/clients/${clientId}`, undefined, <Lock16 />)
    }
    return LinkCell(value, `/clients/${clientId}`)
}

export const gridConfig: ColumnsType<IInvestorDeals> = [
    {
        title: 'Дата и время',
        dataIndex: 'executed_at',
        key: 'executed_at',
        render: DateTimeCell,
    },
    {
        title: 'Тип операции',
        dataIndex: 'type',
        key: 'type',
        align: 'right',
        render: (value) => DealsTypeTranslate[value as InvestorDealsType],
    },
    {
        title: 'Актив',
        dataIndex: 'asset_ticker_symbol',
        key: 'asset_ticker_symbol',
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: 'Сторона 1',
        dataIndex: 'from_user_full_name',
        key: 'from_user_full_name',
        render: (value, record) =>
            UserCell(value, record.from_user_uuid, record.is_from_user_blocked),
    },
    {
        title: 'Количество ЦФА, ед.',
        dataIndex: 'amount_dfa',
        key: 'amount_dfa',
        render: (text, record) => {
            return <ColoredTag type="gray_right">{record.amount_dfa}</ColoredTag>
        },
    },
    {
        title: 'Сторона 2',
        dataIndex: 'to_user_full_name',
        key: 'to_user_full_name',
        render: (value, record) => UserCell(value, record.to_user_uuid, record.is_to_user_blocked),
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        render: (value, record) => {
            if (value === null) {
                return '-'
            }
            return getRublesFromPennies(value)
        },
    },
    {
        title: 'Сумма операции, ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        render: (value, record) => {
            if (value === null) {
                return '-'
            }
            return getRublesFromPennies(value)
        },
    },
]
