import { Select, SelectProps, Spin } from 'antd'
import cn from 'classnames'
import debounce from 'lodash.debounce'
import React, { FC, ReactElement, useEffect, useState } from 'react'

import { DownMini } from '../../../Icons'
import { DataProvider, DataProviderResponse } from '../../../Table'
import { FormItem, FormItemProps } from '../FormItem'
import './styles.less'

const DEBOUNCE_TIMEOUT = 500

export type AutoCompleteProps = FormItemProps & {
    dataProvider?: DataProvider
    renderElement?: ReactElement
    selectProps?: SelectProps
    onSelect?: (v: DataProviderResponse[]) => void
    defaultOptions?: DataProviderResponse[]
}

const defaultSelectProps: SelectProps = {
    style: { width: '100%' },
    placeholder: 'Начните ввод...',
    showSearch: true,
    size: 'middle',
}

const renderOptions = (options: DataProviderResponse[], renderElement?: ReactElement) => {
    if (!options.length) {
        return null
    }

    return options.map((data) => {
        if (!renderElement) {
            return (
                <Select.Option value={data.value} key={data.key ?? data.value} label={data.label}>
                    {data.label}
                </Select.Option>
            )
        }

        return (
            <Select.Option value={data.value} key={data.key ?? data.value} label={data.label}>
                {React.cloneElement(renderElement, { data })}
            </Select.Option>
        )
    })
}

export const WrappedAutocomplete: FC<AutoCompleteProps> = ({
    selectProps,
    dataProvider,
    renderElement,
    onSelect,
    defaultOptions = [],
    ...restProps
}) => {
    const props = { ...defaultSelectProps, ...selectProps }
    const isMultiply = props.mode === 'multiple'
    const [options, setOptions] = useState<DataProviderResponse[]>([])
    const [selected, setSelected] = useState<string[] | string>(isMultiply ? [] : '')
    const [fetching, setFetching] = useState(false)

    const onSearchHandler = async (v?: unknown) => {
        setOptions([])
        if (dataProvider) {
            setFetching(true)
            let dataOptions = await dataProvider(v)
            dataOptions = dataOptions.filter((op) => !selected?.includes(op.value))
            setFetching(false)
            setOptions([...defaultOptions, ...dataOptions])
        }
    }

    useEffect(() => {
        setOptions(defaultOptions)
        setSelected(defaultOptions.map((op) => op.value))
        return () => {
            setOptions([])
            setSelected(isMultiply ? [] : '')
        }
    }, [JSON.stringify(defaultOptions)])

    return (
        <FormItem {...restProps}>
            <Select
                {...props}
                className={cn('dfa-select', props.size)}
                suffixIcon={<DownMini />}
                optionLabelProp="label"
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : 'нет совпадений'}
                value={selected}
                onSearch={debounce(onSearchHandler, DEBOUNCE_TIMEOUT)}
                onDropdownVisibleChange={(open) => {
                    if (!open) {
                        const selectedOptions = options.filter((op) => selected?.includes(op.value))
                        setOptions(selectedOptions)
                    }
                }}
                onChange={(newValue: string[] | string) => {
                    const selectedOptions = options.filter((op) => newValue?.includes(op.value))
                    setSelected(newValue)
                    onSelect?.(selectedOptions)
                }}
            >
                {renderOptions(options, renderElement)}
            </Select>
        </FormItem>
    )
}
