import { Dispatch, FC, ReactElement, SetStateAction } from 'react'
import { createPortal } from 'react-dom'

import { Box } from '../Box'
import { Space } from '../Space'

export const CONTENT_FOOTER_ID = 'content-footer'

export const ContentFooter: FC = () => {
    return <div id={CONTENT_FOOTER_ID} />
}

export const Portal: FC<{ container: Element }> = ({ container, children }) => {
    return createPortal(children, container)
}
export interface IFooter {
    showModal?: () => void
    status: 'precreate' | 'initial' | 'ready-to-new' | 'success'
    setFooterStatus: Dispatch<SetStateAction<IFooter['status']>>
    permitOperation?: boolean
}

type IContentFooterBox = FC<{ left?: ReactElement }>

export const ContentFooterBox: IContentFooterBox = ({ children, left }) => {
    return (
        <Box padding={[24, 8, 0, 24]} direction="row" justify="space-between" align="center">
            <Box direction="row" align="center">
                {left}
            </Box>
            <Box direction="row" align="center">
                <Space size={[16, 0]}>{children}</Space>
            </Box>
        </Box>
    )
}
