import { ITariffUserLink } from '@dltru/dfa-models'
import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { tariffRelatedUsersSlice } from '@store/tariffRelatedUsers'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    usersList: ITariffUserLink[]
    setUserList: (userList: React.Key[]) => void
}

export const DropRelateModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    usersList,
    setUserList,
}) => {
    const reduxDispatch = useDispatch()

    const removeCertificate = () => {
        reduxDispatch(tariffRelatedUsersSlice.actions.unlinkUsers(usersList))
        setIsModalVisible(false)
        setUserList([])
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            width={423}
            title={
                <Space size={16}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Отвязать пользователей от тарифного плана
                </Space>
            }
            footer={[
                <Button onClick={() => setIsModalVisible(false)} borderRadius={12} key="cancel">
                    Отмена
                </Button>,
                <Button type="primary" onClick={removeCertificate} borderRadius={12} key="ok">
                    Да, отвязать
                </Button>,
            ]}
        >
            <Box margin={[24, 0, 24, 40]}>
                <p>
                    Выбранные пользователи будут отвязаны от данного тарифного плана. Комиссии по
                    всем операциям будут расчитываться по базовому тарифному плану.
                </p>
                <p>Вы действительно хотите отвязать пользователей от тарифного плана?</p>
            </Box>
        </MiniModal>
    )
}
