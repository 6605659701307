/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ESpecialAccountType, GetTransactionParams } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, Divider, Filters, Table } from '@dltru/dfa-ui'
import { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TopLine } from './TopLine'
import { gridConfig } from './gridConfig'
import style from './style.m.less'
import { accountSauSlice, getSauAccountsTxs, getSauAccountSummary } from '@store/accountSau'
import { accountSauSelector } from '@store/accountSau/selectors'
import { filters } from './filters'

export const TechSauTable: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()

    const items = useSelector(accountSauSelector.selectList)
    const paging = useSelector(accountSauSelector.selectPaging)
    const isLoading = useSelector(accountSauSelector.selectIsLoading)
    const params = useSelector(accountSauSelector.selectParams)
    const summary = useSelector(accountSauSelector.selectSummary)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(getSauAccountsTxs({ ...params, limit }))
    }

    const onNext = () => {
        reduxDispatch(getSauAccountsTxs({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(getSauAccountsTxs({ cursor: paging?.cursors?.prev }))
    }

    const onApplyFilters = (filters?: Partial<GetTransactionParams>) => {
        let amount = undefined
        if (filters?.amount && typeof filters?.amount === 'number') {
            amount = filters.amount * 100
        }

        if (filters?.amount && typeof filters?.amount === 'object') {
            amount = [
                `gte(${Number(filters.amount.gte) * 100})`,
                `lte(${Number(filters.amount.lte) * 100})`,
            ]
        }

        reduxDispatch(
            getSauAccountsTxs({
                ...params,
                ...filters,
                amount,
            }),
        )
    }

    /*const onRow = (record: TransactionType) => ({
        onClick: () => {
            //navigate(`/clients/${record.user_uuid}#account`)
        },
    })*/

    useEffect(() => {
        if (summary?.id) {
            reduxDispatch(getSauAccountsTxs({ ...params, account_id: [summary?.id] }))
        }
    }, [summary])

    useEffect(() => {
        reduxDispatch(getSauAccountSummary(ESpecialAccountType.TECHSAU))

        return () => {
            reduxDispatch(accountSauSlice.actions.clearItems())
        }
    }, [])

    /*const propsForModal = {
        /*wallet: account?.number?.toString() ?? '',
        requisites: selectedRow?.requisites || [],
        title: selectedRow?.title ?? '',
        subtitle: selectedRow?.serviceCode ? serviceCodeTranslate[selectedRow.serviceCode] : '',
        total: selectedRow?.total || 0,
        type: getDetailByType(selectedRow?.type),
        special: dayjs.unix(selectedRow?.timestamp ?? 0)?.format('DD.MM.YYYY HH:mm:ss'),
        id: selectedRow?.id ?? '',
    }*/

    // const showDownloadNominalBtn = isAllow(['account.read.all'])
    // const showDownloadBenefBtn = isAllow(['account.read.all', 'user.read.all'])

    return (
        <Box padding={[8, 24, 24, 24]} className="boxContent">
            <div className={style.PAHeader}>{summary?.id && <TopLine />}</div>
            <Divider padding={[0, 0]} margin={[0, 0]} />
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items ?? []}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                //onRow={onRow}
                clickableRow
                headerLeft={
                    <Filters filters={filters} onApplyFilters={onApplyFilters} isQs={false} />
                }
            />

            {/*<TransactionDetailsModal
                isModal={Boolean(selectedRow)}
                onClose={() => setSelectedRow(undefined)}
                {...propsForModal}
            />*/}
        </Box>
    )
}
