import classNames from 'classnames/bind'
import { FC } from 'react'

import style from './style.m.less'

export interface IBodyTag {
    tag: any
}

const cx = classNames.bind(style)

export const BodyTag: FC<IBodyTag> = ({ tag }) => {
    const className = cx({
        tag: true,
        tagSuccess: tag?.color === 'success',
        tagError: tag?.color === 'error',
    })

    return (
        <p className={className}>{tag?.name !== 'in_progress' ? tag?.title : 'будет исполнено'}</p>
    )
}
