import clsx from 'clsx'
import { FC } from 'react'

import { Wallet } from '../../Icons'
import { EMPTY_FIELD_VALUE } from '../../constants'
import { Price } from '../modals'
import styles from './styles.m.less'

export interface IMoneyAccountBlockProps {
    accountNumber?: number
    balance?: number
    width?: number | string
    title?: string
    error?: string
}

export const MoneyAccountBlock: FC<IMoneyAccountBlockProps> = ({
    accountNumber,
    balance = 0,
    width,
    title = 'Счёт',
    error,
}) => {
    const balanceInfoClassName = clsx(styles.balanceInfo, {
        [styles.balanceInfoError]: Boolean(error),
    })
    return (
        <div className={balanceInfoClassName} style={{ width }}>
            <div className={styles.balanceInfoWrapper}>
                <h6 className={styles.balanceInfoTitle}>{title}</h6>
                {error && <span className={styles.error}>{error}</span>}
            </div>
            <div className={styles.balanceInfoWrapper}>
                <div className={styles.wallet}>
                    <div className={styles.icon}>
                        <Wallet />
                    </div>
                    <div className={styles.bold}>{accountNumber ?? EMPTY_FIELD_VALUE}</div>
                </div>
                <div className={styles.balance}>
                    <Price price={balance ? balance / 100 : 0} />
                </div>
            </div>
        </div>
    )
}
