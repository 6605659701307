// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_space_pLxhu .ant-space-vertical {\n  width: 100%;\n}\n.form_space_pLxhu .ant-space-horizontal {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/components/Form/FormSpace/styles.m.less"],"names":[],"mappings":"AAAA;EAKM,WAAA;AAHN;AAFA;EASM,WAAA;AAJN","sourcesContent":[".form_space {\n\n  :global {\n\n    .ant-space-vertical {\n      width: 100%;\n    }\n\n    .ant-space-horizontal {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_space": "form_space_pLxhu"
};
export default ___CSS_LOADER_EXPORT___;
