import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ExclamationCircle: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.99967 13.6663C10.6816 13.6663 13.6663 10.6816 13.6663 6.99967C13.6663 3.31778 10.6816 0.333008 6.99967 0.333008C3.31778 0.333008 0.333008 3.31778 0.333008 6.99967C0.333008 10.6816 3.31778 13.6663 6.99967 13.6663ZM6.62466 3.49967C6.55591 3.49967 6.49966 3.55592 6.49966 3.62467V7.87467C6.49966 7.94342 6.55591 7.99967 6.62466 7.99967H7.37466C7.44341 7.99967 7.49966 7.94342 7.49966 7.87467V3.62467C7.49966 3.55592 7.44341 3.49967 7.37466 3.49967H6.62466ZM6.47914 10.2747C6.61652 10.4149 6.8034 10.4957 6.99966 10.4997C7.19592 10.4957 7.38279 10.4149 7.52017 10.2747C7.65756 10.1345 7.7345 9.94598 7.7345 9.74967C7.7345 9.55337 7.65756 9.36489 7.52017 9.22468C7.38279 9.08446 7.19592 9.00368 6.99966 8.99967C6.8034 9.00368 6.61652 9.08446 6.47914 9.22468C6.34176 9.36489 6.26481 9.55337 6.26481 9.74967C6.26481 9.94598 6.34176 10.1345 6.47914 10.2747Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
