import { ChangeEvent, FC, useContext } from 'react'

import { Col } from '../../../Col'
import { Divider } from '../../../Divider'
import { Row } from '../../../Row'
import { FormItem, WrappedInput } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import style from './style.m.less'
import { Filter } from './types'

type TextInputFilterProps = Omit<Filter, 'filterType'>

export const TextInputFilter: FC<TextInputFilterProps> = ({
    label,
    name,
    divider = false,
    ...props
}) => {
    const { form } = useContext(FiltersContext)

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        form?.setFieldsValue({ [name]: e.currentTarget.value })
    }

    return (
        <FormItem className={style.filtersFormItem}>
            <Row gutter={10} align="middle">
                <Col span={6}>
                    <span className={style.filtersItemLabel}>{label}</span>
                </Col>

                <Col span={18}>
                    <WrappedInput name={name} onChange={onChangeHandler} {...props} size="middle" />
                </Col>
            </Row>
            {divider && <Divider padding={[16, 0, 0, 0]} margin={0} />}
        </FormItem>
    )
}
