import { ITransferDeal } from '@dltru/dfa-models'
import { ColoredTag, ColumnsType, LinkCell, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

const AmountCell = (initiator_id: string, record: ITransferDeal) => {
    const type = initiator_id === record.order.seller_id ? 'sell' : 'buy'
    return <ColoredTag type={type}>{record.amount_dfa}</ColoredTag>
}

export const getGridConfig = (initiator_id: string): ColumnsType<ITransferDeal> => [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: (value) => dayjs.unix(value).format(VIEW_DATE_FORMAT),
    },
    {
        title: 'Актив',
        dataIndex: 'asset_ticker_symbol:',
        key: 'asset_ticker_symbol',
        sorter: true,
        render: (value, record) => {
            if (record.order.seller_asset_data.asset_id) {
                return LinkCell(
                    `${record.order.seller_asset_data.asset_title}`,
                    `/offers/${record.order.seller_asset_data.asset_id}`,
                )
            }

            return ''
        },
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        render: (value, record) => {
            if (record.order.seller_asset_data.asset_emitter_id) {
                return LinkCell(
                    `${record.order.seller_asset_data.asset_emitter_full_name}`,
                    `/clients/${record.order.seller_asset_data.asset_emitter_id}`,
                )
            }
            return ''
        },
    },
    {
        title: 'Отправитель',
        dataIndex: 'seller_full_name',
        key: 'seller_full_name',
        sorter: true,
        render: (value, record) => LinkCell(value, `/clients/${record.order.seller_id}`),
    },
    {
        title: 'Кол-во',
        dataIndex: 'amount_dfa',
        key: 'amount_dfa',
        align: 'right',
        sorter: true,
        render: (_: unknown, record: ITransferDeal) => AmountCell(initiator_id, record),
    },
    {
        title: 'Получатель',
        dataIndex: 'buyer_full_name',
        key: 'buyer_full_name',
        sorter: true,
        render: (value, record) => LinkCell(value, `/clients/${record.order.buyer_id}`),
    },
]
