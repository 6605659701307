import { IValues } from '../../../Stepper'
import { AddressElements } from './types'

// example: main_group.
export const getPartPrefixAttribute = (val?: string) => (val ? `${val}.` : '')

export const getCleanedAddress = (val: string) => {
    if (!val) {
        return ''
    }

    return val
        .split(',')
        .map((el) => el.trim())
        .filter((val) => val)
        .join(',')
}

export const setForBothContexts = (
    payload: Record<string, string>,
    mergeValuesIntoState: (stepsValues: IValues) => void,
    setFieldsValue: (values: any) => void,
) => {
    mergeValuesIntoState(payload)
    setFieldsValue(payload)
}

const fieldNameDictionary = [
    AddressElements.postIndex,
    AddressElements.region,
    AddressElements.district,
    AddressElements.locality,
    AddressElements.street,
    AddressElements.house,
    AddressElements.building,
    AddressElements.buildingPart,
    AddressElements.place,
]

export const setAddressDetails = (
    values: IValues | null,
    group: string,
    mergeValuesIntoState: (stepsValues: IValues) => void,
    setFieldsValue: (values: any) => void,
) => {
    const factAddress = values?.[`${group}.fact`] as string

    factAddress?.split(',').forEach((value: string, index) => {
        const _value = value ? value.trim() : ''
        const payload = { [`${group}.${'Fact'}.${fieldNameDictionary[index]}`]: _value }
        setForBothContexts(payload, mergeValuesIntoState, setFieldsValue)
    })

    const registrationAddress = values?.[`${group}.registration`] as string
    registrationAddress?.split(',').forEach((value: string, index) => {
        const _value = value ? value.trim() : ''
        const payload = { [`${group}.${'Registration'}.${fieldNameDictionary[index]}`]: _value }
        setForBothContexts(payload, mergeValuesIntoState, setFieldsValue)
    })

    const indexAddress = values?.[`${group}.index`] as string
    indexAddress?.split(',').forEach((value: string, index) => {
        const _value = value ? value.trim() : ''
        const payload = { [`${group}.${'Index'}.${fieldNameDictionary[index]}`]: _value }
        setForBothContexts(payload, mergeValuesIntoState, setFieldsValue)
    })
}
