import { useEffect, useState } from 'react'

export const useEditable = (
    currentStep: number,
    stepIndex: number,
    editable: number[],
): boolean => {
    const [isEdit, setIsEdit] = useState(false)
    useEffect(() => {
        if (currentStep === stepIndex) {
            setIsEdit(true)
        } else if (currentStep !== stepIndex && editable.includes(stepIndex)) {
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [currentStep, editable, stepIndex])

    return !isEdit
}
