import { ITariffPlane, tariffPlaneTypeShort, tariffPlaneTypeTranslate } from '@dltru/dfa-models'
import React, { FC, useMemo } from 'react'

import { Box } from '../../Box'
import { InfoCircleOutline } from '../../Icons'
import { DateTimeCell, Table } from '../../Table'
import { Button } from '../../button'
import { Modal } from '../../components/Modal'
import { QualifiedDetails } from '../modals'
import styles from '../modals/Qualify/styles.m.less'
import { getTariffGridConfig } from './TariffGridConfig'
import { getTariffCode } from './helper'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    data: ITariffPlane
    feeNames: Record<string, string>
}
export const TariffInfoModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    data,
    feeNames,
}) => {
    const gridConfig = useMemo(() => getTariffGridConfig({ feeNames }), [feeNames])
    return (
        <Modal
            width={1136}
            visible={isModalVisible}
            footer={[
                <Button borderRadius={12} onClick={() => setIsModalVisible(false)}>
                    Закрыть
                </Button>,
            ]}
            title={
                <>
                    {tariffPlaneTypeTranslate[data.type]} тарифный план {getTariffCode(data)}{' '}
                    действует с {DateTimeCell(data.active_grid?.active_from)}
                </>
            }
            onCancel={() => setIsModalVisible(false)}
        >
            <Table
                columns={gridConfig}
                dataSource={data?.active_grid?.rates ?? []}
                rowKey="fee_code"
                uneditable
                noPagination
            />
        </Modal>
    )
}
