import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { InstanceProps, create } from 'react-modal-promise'

import '../style.less'

export const ChangeEmailModal: FC<InstanceProps<boolean>> = ({ onResolve }) => {
    const [visible, setVisible] = useState(true)

    const sendEmailForChange = () => {
        setVisible(false)
        onResolve(true)
    }

    const onCancel = () => {
        setVisible(false)
        onResolve(false)
    }

    return (
        <MiniModal
            width={423}
            visible={visible}
            onCancel={onCancel}
            title={
                <Space size={16}>
                    <QuestionCircle className="auth-modal__gray-icon" />
                    Отправить ссылку для подтверждения
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={sendEmailForChange}>
                    Да, отправить
                </Button>,
            ]}
        >
            <Box margin={[0, 0, 0, 40]}>
                <Space direction="vertical" size={18}>
                    <div>
                        На электронную почту пользователя будет отправлено письмо со ссылкой для
                        подтверждения новой электронной почты.
                    </div>
                    <div>Вы действительно хотите отправить ссылку?</div>
                </Space>
            </Box>
        </MiniModal>
    )
}

export const changeEmailModal = create(ChangeEmailModal)
