import { IApprovalHistoryUI } from '@dltru/dfa-models'
import {
    Box,
    Button,
    DateTimeCell,
    Divider,
    InfoCircleOutlined,
    MiniModal,
    Space,
} from '@dltru/dfa-ui'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

export const CommentView = (data: IApprovalHistoryUI) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const currentClientUuid = useSelector(clientsSelector.selectCurrentClientId)
    if (!data?.comment) {
        return null
    }
    const closeModal = () => setIsModalVisible(false)
    return (
        <>
            <Button type="link" onClick={() => setIsModalVisible(true)}>
                Посмотреть
            </Button>
            <MiniModal
                visible={isModalVisible}
                onCancel={closeModal}
                destroyOnClose
                title={
                    <Space size={0}>
                        <InfoCircleOutlined className="modal-icon modal-info-icon" />
                        Комментарий&nbsp;{data.comment_who}
                    </Space>
                }
                width={600}
                closable
                footer={[
                    <Button onClick={closeModal} borderRadius={12}>
                        Закрыть
                    </Button>,
                ]}
            >
                <Box margin={[0, 40]}>
                    <strong>{data.name_operator}</strong>
                    <div>{DateTimeCell(data.approval_datetime)}</div>
                    <Divider />
                    <strong>
                        Анкета {currentClientUuid === data.user_uuid ? 'клиента' : 'представителя'}
                    </strong>
                    {data.comment}
                </Box>
            </MiniModal>
        </>
    )
}
