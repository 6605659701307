// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timerBoxRed_w7cr5 {\n  color: #f5222d;\n  border: 1px solid #f5222d;\n  border-radius: 16px;\n  padding: 10px 16px;\n  display: flex;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  width: fit-content;\n}\n.labelUnderInput_Fyh_J {\n  color: #868991;\n  font-size: 12px;\n}\n.labelUnderInput_Fyh_J.labelCenter_iPElY {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/Timer/style.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADJ;AAIA;EACI,cAAA;EACA,eAAA;AAFJ;AAII;EACI,kBAAA;AAFR","sourcesContent":["@import '/src/assets/style/palette';\n\n.timerBoxRed {\n    color: @red-6;\n    border: 1px solid @red-6;\n    border-radius: 16px;\n    padding: 10px 16px;\n    display: flex;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n    width: fit-content;\n  }\n  \n.labelUnderInput {\n    color: @gray-7;\n    font-size: 12px;\n\n    &.labelCenter {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timerBoxRed": "timerBoxRed_w7cr5",
	"labelUnderInput": "labelUnderInput_Fyh_J",
	"labelCenter": "labelCenter_iPElY"
};
export default ___CSS_LOADER_EXPORT___;
