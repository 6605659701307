export enum EmissionOrderStatus {
    in_progress = 'in_progress',
    draft = 'draft',
    executed = 'executed',

    not_executed = 'not_executed', // фактически отсутсвуют в ПМИ, есть в целевом
    refused = 'refused', // фактически отсутсвуют в ПМИ, есть в целевом
    revoked = 'revoked',
    accepted = 'accepted',

    waiting_for_emitter_accept = 'waiting_for_emitter_accept',
    accepted_by_emitter = 'accepted_by_emitter',
    refused_by_emitter = 'refused_by_emitter',
}

export enum SecondaryOrderStatus {
    in_progress = 'in_progress',
    draft = 'draft',
    executed = 'executed',

    not_executed = 'not_executed', // фактически отсутсвуют в ПМИ, есть в целевом
    refused = 'refused', // фактически отсутсвуют в ПМИ, есть в целевом
    accepted = 'accepted',
    revoked = 'revoked',
    rejected = 'rejected',
    canceled = 'canceled',
    partly_executed = 'partly_executed',
    canceled_by_system = 'canceled_by_system',
}

export enum TransferOrderStatus {
    draft = 'draft',
    placed = 'placed',
    accepted = 'accepted',
    ready = 'ready',
    executed = 'executed',
    canceled = 'canceled',
    in_progress = 'in_progress',
}

export type UnionOrderStatus = EmissionOrderStatus | SecondaryOrderStatus | TransferOrderStatus

export enum OrderTypeEnum {
    emission = 'emission',
    secondary = 'secondary',
    buy = 'buy',
    sell = 'sell',
    transfer = 'transfer',
    transfer_buy = 'transfer_buy',
    transfer_sell = 'transfer_sell',
}

export const finalEmissionStatuses = [
    EmissionOrderStatus.refused,
    EmissionOrderStatus.refused_by_emitter,
    EmissionOrderStatus.executed,
    EmissionOrderStatus.not_executed,
]
export const notFinalSecondaryStatuses = [
    SecondaryOrderStatus.in_progress,
    SecondaryOrderStatus.accepted,
]

export const finalSecondaryStatuses = [
    SecondaryOrderStatus.executed,
    SecondaryOrderStatus.canceled_by_system,
    SecondaryOrderStatus.canceled,
    SecondaryOrderStatus.rejected,
    SecondaryOrderStatus.not_executed,
]

export const secondaryOrderStatusCancelable = [
    SecondaryOrderStatus.accepted,
    SecondaryOrderStatus.partly_executed,
]
