import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const LockGrey: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="16" fill="#B9BAC1" />
                <path
                    d="M12 14.6666V13.3333C12 11.1266 12.6667 9.33331 16 9.33331C19.3333 9.33331 20 11.1266 20 13.3333V14.6666"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 20.3333C16.9205 20.3333 17.6667 19.5871 17.6667 18.6667C17.6667 17.7462 16.9205 17 16 17C15.0795 17 14.3333 17.7462 14.3333 18.6667C14.3333 19.5871 15.0795 20.3333 16 20.3333Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.3333 22.6667H12.6666C9.99998 22.6667 9.33331 22 9.33331 19.3334V18C9.33331 15.3334 9.99998 14.6667 12.6666 14.6667H19.3333C22 14.6667 22.6666 15.3334 22.6666 18V19.3334C22.6666 22 22 22.6667 19.3333 22.6667Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
