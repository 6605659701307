import { Tag as TagAntd, TagProps as TagPropsAntd } from 'antd'
import cn from 'classnames'

import './style.less'

export type TagProps = TagPropsAntd & {
    transparent?: boolean
}

const Tag = ({ children, color, transparent, ...props }: TagProps) => (
    <TagAntd
        className={cn('dfa-tag', { 'dfa-tag--transparent': transparent })}
        color={color}
        {...props}
    >
        {children}
    </TagAntd>
)

Tag.CheckableTag = TagAntd.CheckableTag

export { Tag }
