import { TransferOrderStatus } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'
import { signData } from '@store/helper'

import api from '@services/api'

import { getDfaBalanceByIdHelper, getDfaByIdHelper } from '../details/helpers'
import '../ownershipTransfer'
import {
    checkLimitRecipientTransferAction,
    createDFAOwnershipTransferRequestAction,
    dfaOwnershipTransferSlice,
    getAcceptorDFA,
    getDFAOwnershipTransfer,
    getOfferentDFA,
    getTransferDeals,
    setAccepterOffer,
    setDFAOwnershipTransfer,
    setDFAOwnershipTransferError,
    setDFAOwnershipTransferLoading,
    setOfferentOffer,
    setTransferDeals,
    setTransferDealsIsLoading,
    transferDfaCancelByOperator,
    transferReject,
} from './index'
import { dfaTransferSelector } from './selectors'

function* loadDFAOwnershipTransfer(action: ReturnType<typeof getDFAOwnershipTransfer>) {
    yield* put(setDFAOwnershipTransferLoading(true))

    try {
        //const uuid = yield* select(authSelector.selectUserUid)
        const { data, error } = yield api.lib.getDFAOwnershipTransferByGlobalIdRequest(
            action.payload.globalId,
        )

        if (error) {
            throw new Error(error)
        }
        if (data?.items) {
            const offer = data.items.find((item) => !item.is_reply)

            const replyOffer = data.items.find((item) => item.is_reply)

            let inProgressStatus = null

            if (offer && replyOffer) {
                inProgressStatus =
                    (offer?.status === TransferOrderStatus.executed &&
                        replyOffer.status !== TransferOrderStatus.executed) ||
                    (offer?.status !== TransferOrderStatus.executed &&
                        replyOffer.status === TransferOrderStatus.executed)
                        ? TransferOrderStatus.ready
                        : null
            }

            offer.status = inProgressStatus || offer.status

            /**
             * факт наличия 2 оферт найденных по глобал айди уже говорит о том что невозможно отменить ни первую ни вторую
             */
            if (data?.items.length > 1) {
                offer.noCancel = true
            }

            yield* put(setDFAOwnershipTransfer({ data: offer }))
        }
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
    }
}

function* createDFAOwnershipTransferRequest({
    payload,
}: ReturnType<typeof createDFAOwnershipTransferRequestAction>) {
    yield* put(setDFAOwnershipTransferLoading(true))
    try {
        const { error, data } = yield* call(
            api.lib.createDFAOwnershipTransferRequestByOperatorV2,
            payload,
        )
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.item) {
            yield* signData(data?.item?.status, payload?.skid, data)
            openMessage({
                type: 'success',
                message: 'Запрос на передачу прав успешно отправлен',
            })
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отправке запроса на передачу прав',
        })
    }
}

function* transferRejectHandler(action: ReturnType<typeof transferReject>) {
    yield* put(setDFAOwnershipTransferLoading(true))

    try {
        const { data, error } = yield api.lib.transferRejectService(action)
        if (error) {
            throw new Error(error)
        }
        if (data?.item) {
            yield* put(setDFAOwnershipTransfer({ data: data.item }))
        }
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
    }
}

function* checkLimitRecipientTransfer({
    payload,
}: ReturnType<typeof checkLimitRecipientTransferAction>) {
    yield* put(setDFAOwnershipTransferLoading(true))
    try {
        const { error: buyerTypeError, data: buyerTypeData } = yield* call(
            api.lib.getProfileTypeByUserService,
            payload,
        )
        if (buyerTypeError || buyerTypeData?.error) {
            throw buyerTypeError || buyerTypeData?.error
        }
        if (buyerTypeData?.items) {
            yield* put(
                dfaOwnershipTransferSlice.actions.setBuyerDataTransfer({
                    type: buyerTypeData.items?.[0]?.type,
                }),
            )
        }
        const { data: qualifyData, error: qualifyError } = yield* call(
            api.lib.getQualifyInfo,
            payload,
        )
        if (qualifyError || qualifyData?.error) {
            throw qualifyError || qualifyData?.error
        }
        if (qualifyData?.item) {
            yield* put(
                dfaOwnershipTransferSlice.actions.setBuyerDataTransfer({
                    is_qualified: qualifyData.item?.is_qualified,
                    quota: qualifyData.item?.quota,
                }),
            )
        }
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
        openMessage({
            type: 'error',
            message: 'Не удалось проверить лимит Акцептанта',
        })
    }
}

function* transferDfaCancelByOperatorHandler(
    action: ReturnType<typeof transferDfaCancelByOperator>,
) {
    yield* put(setDFAOwnershipTransferLoading(true))
    try {
        const { data, error } = yield api.lib.transferDfaCancelByOperatorServiceV2(action.payload)

        if (error) {
            throw new Error(error)
        }

        if (data?.item) {
            yield* signData(data?.item?.status, action.payload.skid, data)
            openMessage({
                type: 'success',
                message: 'Запрос на акцепт оферты успешно отправлен',
            })
        }

        if (data?.item) {
            yield* put(setDFAOwnershipTransfer({ data: data.item }))
        } else {
            yield* put(setDFAOwnershipTransfer({ data: {} }))
        }

        openMessage({
            type: 'success',
            message: 'Через некоторое время ЦФА появятся в вашем кошельке',
        })
    } catch (error) {
        yield* put(setDFAOwnershipTransferError(error))
        openMessage({
            type: 'error',
            message: 'Ошибка принятия ЦФА. Что-то пошло не так',
        })
    }
}

function* loadOfferentDFA({ payload }: ReturnType<typeof getOfferentDFA>) {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const dfa = yield* call(getDfaByIdHelper, payload)

        if (!dfa) {
            throw new Error()
        }

        const balancePayload = {
            uuid,
            dfaId: Number(dfa.id),
            withPayoffAgreements: true,
            isInvestorRepaymentCheck: true,
        }

        const balance = yield* call(getDfaBalanceByIdHelper, balancePayload)

        dfa.balance = balance

        yield* put(setOfferentOffer({ dfa }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении оферты',
        })
    }
}

function* loadAcceptorDFA({ payload }: ReturnType<typeof getAcceptorDFA>) {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const dfa = yield* call(getDfaByIdHelper, payload)

        if (!dfa) {
            throw new Error()
        }

        const balancePayload = {
            uuid,
            dfaId: Number(dfa.id),
            withPayoffAgreements: true,
            isInvestorRepaymentCheck: true,
        }

        const balance = yield* call(getDfaBalanceByIdHelper, balancePayload)

        dfa.balance = balance

        yield* put(setAccepterOffer({ dfa }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении оферты',
        })
    }
}

function* handleLoadTransferDeals() {
    try {
        const { global_id, seller_id } = yield* select(dfaTransferSelector.selectData)
        yield* put(setTransferDealsIsLoading(true))

        const { data, error } = yield api.lib.getTransferDfaDeals({ user_id: seller_id })

        const items = data?.items
            ?.filter((item) => {
                return item.order.global_id === global_id
            })
            .map((item) => {
                return {
                    ...item,
                    asset_ticker_symbol: item.order?.seller_asset_data?.asset_ticker_symbol,
                    seller_full_name: item.order?.seller_full_name,
                    buyer_full_name: item.order?.buyer_full_name,
                    amount_dfa: item.order?.seller_asset_data?.amount_dfa,
                }
            })

        if (error) {
            throw error
        }

        yield* put(setTransferDeals(items ?? []))
    } catch (error) {
        yield* put(setTransferDeals([]))
    } finally {
        yield* put(setTransferDealsIsLoading(false))
    }
}

export function* watchTransferSaga(): Generator<StrictEffect> {
    yield* takeLatest(getDFAOwnershipTransfer.type, loadDFAOwnershipTransfer)
    yield* takeLatest(
        createDFAOwnershipTransferRequestAction.type,
        createDFAOwnershipTransferRequest,
    )
    yield* takeLatest(transferReject.type, transferRejectHandler)
    yield* takeLatest(checkLimitRecipientTransferAction.type, checkLimitRecipientTransfer)
    yield* takeLatest(transferDfaCancelByOperator.type, transferDfaCancelByOperatorHandler)

    yield* takeLatest(getOfferentDFA.type, loadOfferentDFA)
    yield* takeLatest(getAcceptorDFA.type, loadAcceptorDFA)
    yield* takeLatest(getTransferDeals.type, handleLoadTransferDeals)
}
