import { PaperClipOutlined } from '@ant-design/icons'
import { IRequest, ResponseItem, UploadResult } from '@dltru/dfa-models'
import { FC } from 'react'

import { Box } from '../Box'
import { downLoadFile } from '../utils'
import './styles.less'

export interface IUneditableFileComponentProps {
    uid?: string
    getBase64File: (uid: string) => Promise<IRequest<ResponseItem<UploadResult>>>
}
export const defaultFileName = 'Скачать'
export const UneditableFileComponent: FC<IUneditableFileComponentProps> = ({
    uid,
    getBase64File,
}) => {
    if (!uid) {
        return null
    }

    return (
        <div className="ant-upload-list-item ant-upload-list-item-info">
            <Box className="ant-upload-span ant-upload-list-text" direction="row" align="center">
                <div className="ant-upload-text-icon">
                    <PaperClipOutlined />
                </div>
                <span
                    className="ant-upload-list-item-name"
                    onClick={() => downLoadFile(uid, getBase64File)}
                >
                    {defaultFileName}
                </span>
            </Box>
        </div>
    )
}
