import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const PapperclipSmall: FC<IconProps> = ({ ...props }) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.19126 3.42511L7.19093 3.42543L3.36659 7.24684L3.43527 7.31551L7.25896 3.49475C7.25901 3.4947 7.25905 3.49466 7.2591 3.49461C7.85936 2.89443 8.66002 2.56393 9.50783 2.56393C10.3553 2.56393 11.1562 2.8942 11.7551 3.49475L7.19126 3.42511ZM7.19126 3.42511C8.46616 2.14885 10.5463 2.14858 11.823 3.42529C12.4419 4.04417 12.7826 4.86525 12.7813 5.74128V5.74205C12.7813 6.61683 12.4405 7.4382 11.8231 8.05721C11.8229 8.05735 11.8228 8.0575 11.8226 8.05764L6.99189 12.8853L6.99177 12.8854C6.56149 13.3157 5.99672 13.5311 5.4297 13.5311C4.86297 13.5311 4.29799 13.3158 3.86589 12.8852C3.44846 12.4677 3.21876 11.9151 3.21877 11.3249L3.21876 11.3232C3.21781 11.0332 3.27445 10.7459 3.38539 10.478C3.49632 10.21 3.65935 9.96673 3.86503 9.76227L3.86599 9.76131L7.58787 6.04099L7.58863 6.04023L7.98751 5.63979C7.98769 5.63961 7.98786 5.63944 7.98804 5.63926C8.43565 5.1933 9.16276 5.19315 9.60896 5.63936C9.82459 5.85499 9.94376 6.14189 9.94376 6.4483C9.94376 6.75508 9.8259 7.04031 9.60896 7.25725L6.49455 10.3717L6.42599 10.3031L9.54247 7.18952L9.54263 7.18936C9.73791 6.99408 9.85001 6.73005 9.85001 6.44987C9.85001 6.17146 9.74185 5.90647 9.54263 5.70725C9.3479 5.51252 9.08565 5.39987 8.80314 5.39987H8.80158C8.51996 5.39987 8.25738 5.51177 8.06163 5.7046L8.06162 5.70459L8.05903 5.70718L3.93559 9.82906L3.93552 9.82912C3.53678 10.2279 3.3172 10.7599 3.3172 11.3233C3.3172 11.8868 3.53678 12.4187 3.93552 12.8175C4.76047 13.6424 6.10049 13.6424 6.92544 12.8175L7.59881 12.1441L7.59888 12.144L11.7551 7.98942L11.7551 7.98936C12.3552 7.3893 12.686 6.59023 12.686 5.74205C12.686 4.89561 12.3553 4.09496 11.7552 3.4948L7.19126 3.42511ZM2.85935 7.24678L2.75939 7.34674L2.85935 7.24678Z"
                    fill="currentColor"
                    stroke="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
