import { Box, HierarchyIcon, ProgrammingArrows, Space, Tag } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

export const NodeTags: FC = () => {
    const nodeAndOperatorData = useSelector(clientsSelector.selectIsNodeAndExchangeOperator)
    if (!(nodeAndOperatorData?.is_node || nodeAndOperatorData?.is_exchange_operator)) {
        return null
    }
    return (
        <Box margin={[12, 0, 0, 0]}>
            <Space>
                {nodeAndOperatorData.is_node && (
                    <Tag color="green" transparent>
                        <HierarchyIcon /> Узел
                    </Tag>
                )}
                {nodeAndOperatorData?.is_exchange_operator && (
                    <Tag color="green" transparent>
                        <ProgrammingArrows /> Оператор обмена
                    </Tag>
                )}
            </Space>
        </Box>
    )
}
