import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Arrows: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.52 6.96665L14 4.48663L11.52 2.00665"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2 4.48663H14"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.47998 9.03333L2 11.5133L4.47998 13.9933"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 11.5133H2"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
