import { addSnilsMaskToString, documentValidators, onlyDigits } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & { name?: WrappedInputProps['name'] }
export const SnilsInput = ({
    className,
    label,
    name,
    placeholder,
    ...restProps
}: ComponentProps) => {
    return (
        <WrappedInput
            {...restProps}
            className={className ?? 'formComponentItem'}
            label={label ?? 'СНИЛС'}
            name={name ?? 'snils'}
            placeholder={placeholder || '___-___-___ __'}
            rules={[{ validator: documentValidators.snils }]}
            normalize={onlyDigits}
            format="###-###-### ##"
            mask="_"
            renderUneditable={addSnilsMaskToString as WrappedInputProps['renderUneditable']}
        />
    )
}
