import { CertificateType } from '@dltru/dfa-models'
import { Box, Button, EditSquare, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'

import { UserCertificateList } from '@components/UserCertificateList'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    onSubscribe: (cert: CertificateType) => void
}
export const CertificateSelectionModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    onSubscribe,
}) => {
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateType>()

    const closeModal = () => {
        setIsModalVisible(false)
        setSelectedCertificate(undefined)
    }

    const addCertificate = () => {
        closeModal()
        if (selectedCertificate) {
            onSubscribe(selectedCertificate)
        }
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={closeModal}
            destroyOnClose
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Выбор сертификата
                </Space>
            }
            width={680}
            closable
            footer={[
                <Button onClick={closeModal} borderRadius={12} key="cancel">
                    Отмена
                </Button>,
                <Button
                    key="ok"
                    type="primary"
                    onClick={addCertificate}
                    borderRadius={12}
                    disabled={!selectedCertificate}
                >
                    <EditSquare /> Подписать
                </Button>,
            ]}
        >
            <Box margin={[0, 40]}>
                <UserCertificateList
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                />
            </Box>
        </MiniModal>
    )
}
