import { BindBankAccountModel } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { MoneyNominalAccount, getRublesFromPennies } from '@dltru/dfa-ui'
import React, { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { RelateAccountModal } from '@pages/ClientCard/components/Footer/modals/RelateAccountModal'

import { Debit } from '../Debit/Debit'
import InfoWidget from '../InfoWidget/InfoWidget'
import Refill from '../Refill/Refill'
import style from './style.m.less'

export interface IBalanceHeader {
    onAdd?: () => void
    onWithdrawal?: () => void
}

export const BalanceHeader: FC<IBalanceHeader> = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { isAllow } = useContext(RbacContext)
    const balanceInfo = useSelector(clientsSelector.selectBalanceInfo)
    const reduxDispatch = useDispatch()

    const bindAccount = (data: BindBankAccountModel) => {
        reduxDispatch(clientsSlice.actions.bindMoneyAccount(data))
    }

    return (
        <>
            <div className={style.BalanceHeader}>
                <div className={style.mainWidgetWrapper}>
                    <MoneyNominalAccount
                        balanceInfo={balanceInfo}
                        changeAction={() => setIsModalVisible(true)}
                    />
                </div>
                <div className={style.widgetsWrapper}>
                    <InfoWidget
                        label="Свободных средств"
                        value={getRublesFromPennies(balanceInfo?.balance.available)}
                        special=" ₽"
                    />
                    <div className={style.BalanceHeaderDivider}></div>
                    <InfoWidget
                        label="Зарезервировано"
                        value={getRublesFromPennies(balanceInfo?.balance.reserved)}
                        special=" ₽"
                    />
                    <div className={style.BalanceHeaderDivider}></div>
                    <InfoWidget
                        label="Всего"
                        value={getRublesFromPennies(balanceInfo?.balance.total)}
                        special=" ₽"
                    />
                </div>
                <div className={style.extraWrapper}>
                    {isAllow(['account.write.all']) && <Refill />}
                    {isAllow(['account.write.all']) && <Debit />}
                </div>
            </div>
            <RelateAccountModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={bindAccount}
                balanceInfo={balanceInfo}
            />
        </>
    )
}
