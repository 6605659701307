import { FC } from 'react'

import { Space } from '../Space'
import { Typography } from '../Typography'
import { Button } from '../button'

type StepButtonsProps = {
    stepIndex: number
    currentStep: number
    stepsTotal: number
    onBack: (values: Record<string, any>) => void
    onForward: (values: Record<string, any>) => void
    backFromFirstStep?: () => void
    forwardCaption?: string
    backCaption?: string
    info?: string
    disableNextBtn?: boolean
}

export const StepButtons: FC<StepButtonsProps> = ({
    stepIndex,
    currentStep,
    stepsTotal,
    onBack,
    onForward,
    forwardCaption = 'Далее',
    backCaption = 'Назад',
    backFromFirstStep,
    info,
    disableNextBtn,
}) => {
    const showBackBtn = stepIndex === currentStep && (stepIndex > 0 || backFromFirstStep)

    return (
        <Space size="small">
            {showBackBtn && (
                <Button
                    borderRadius={12}
                    size="middle"
                    onClick={stepIndex > 0 ? onBack : backFromFirstStep}
                >
                    {backCaption}
                </Button>
            )}
            {stepIndex === currentStep && stepIndex < stepsTotal && (
                <Button
                    borderRadius={12}
                    size="middle"
                    type="primary"
                    onClick={onForward}
                    disabled={disableNextBtn}
                >
                    {forwardCaption}
                </Button>
            )}
            {stepIndex === currentStep && info && <Typography.Text>{info}</Typography.Text>}
        </Space>
    )
}
