import {
    Base64File,
    ICSPCertificateResponse,
    IRequest,
    UploadDocx,
    UploadResult,
    UploadResultDocx,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

export async function storeBase64FileService(
    file: Partial<Base64File>,
): Promise<IRequest<ResponseItem<{ id: string; check_sum: string }>>> {
    return requestDecorator.call(
        this,
        this.paths.FilesUpload,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: file },
    )
}

export async function getBase64FileService(
    documentId: string,
): Promise<IRequest<ResponseItem<UploadResult>>> {
    return requestDecorator.call(
        this,
        path(this.paths.FilesById, documentId),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function getDfaDecisionFileService(
    data: UploadDocx,
): Promise<IRequest<ResponseItem<UploadResultDocx>>> {
    return requestDecorator.call(
        this,
        this.paths.FilesDocx,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function storeOctetStreamFileService(
    data: ArrayBuffer | string,
    userUuid?: string,
): Promise<IRequest<ICSPCertificateResponse>> {
    return requestDecorator.call(
        this,
        this.paths.CertificatesUserCertificates,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        {
            data,
            headers: { 'Content-Type': 'application/octet-stream', 'X-USER-UUID': userUuid },
        },
    )
}
