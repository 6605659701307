import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CardSendGreen: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="16" fill="#52C41A" />
                <path
                    d="M9.33331 13.6667H17.6666"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 19H13.3333"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 19H17.6667"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.6666 17.3533V18.74C22.6666 21.08 22.0733 21.6667 19.7066 21.6667H12.2933C9.92665 21.6667 9.33331 21.08 9.33331 18.74V13.26C9.33331 10.92 9.92665 10.3333 12.2933 10.3333H17.6666"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.3333 14.3333V10.3333L22.6667 11.6667"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.3333 10.3333L20 11.6667"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
