import { DfaStatusEnum, IApprovalHistoryDfa } from '@dltru/dfa-models'
import {
    CheckCircleOutlineIcon,
    CloseCircleOutlineIcon,
    ColumnsType,
    DateTimeCell,
    ExclamationCircleOutline,
    Space,
} from '@dltru/dfa-ui'

import { CommentView } from './CommentView'
import styles from './style.m.less'

const SolutionCell = (solution: IApprovalHistoryDfa['solution']) => {
    switch (solution) {
        case 'approved':
            return (
                <Space className={styles.success} size={5}>
                    <CheckCircleOutlineIcon />
                    <span>Согласовано</span>
                </Space>
            )
        case 'modification':
            return (
                <Space className={styles.warning} size={5}>
                    <ExclamationCircleOutline />
                    <span>На доработку</span>
                </Space>
            )
        case 'rejected':
            return (
                <Space className={styles.error} size={5}>
                    <CloseCircleOutlineIcon />
                    <span>Отказано</span>
                </Space>
            )
        default:
            return <span>--</span>
    }
}
const StatusCell = (status: DfaStatusEnum) => {
    switch (status) {
        case DfaStatusEnum.teller_approval:
            return 'Согласование операционистом'
        case DfaStatusEnum.lawyer_approval:
            return 'Согласование юристом'
        case DfaStatusEnum.spec_approval:
            return 'Проверка по ПОД/ФТ'
        default:
            return '--'
    }
}
export const gridConfig: ColumnsType<IApprovalHistoryDfa> = [
    {
        title: 'Дата поступления заявки на этап',
        dataIndex: 'set_status_date',
        key: 'set_status_date',
        render: DateTimeCell,
    },
    {
        title: 'Этап согласования',
        dataIndex: 'status',
        key: 'status',
        render: StatusCell,
    },
    {
        title: 'Дата принятия решения',
        dataIndex: 'remove_status_date',
        key: 'remove_status_date',
        render: DateTimeCell,
    },
    {
        title: 'Решение',
        dataIndex: 'solution',
        key: 'solution',
        render: SolutionCell,
    },
    {
        title: 'Ответственный сотрудник',
        dataIndex: 'employee_full_name',
        key: 'employee_full_name',
        sorter: true,
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        fixed: 'right',
        render: (_, record: IApprovalHistoryDfa) => CommentView(record),
    },
]
