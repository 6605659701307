import { ITransferOrder } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell } from '@dltru/dfa-ui'

export const gridConfig: ColumnsType<ITransferOrder> = [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: DateTimeCell,
    },
    {
        title: 'Оферта',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        render: (value, record) =>
            LinkCell(`Оферта №${value}`, `/ownership-transfer-request/${record.global_id}`),
    },
    {
        title: 'Тип оферты',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        render: (_, record) => (record.is_public ? 'Публичная' : 'Индивидуальная'),
    },
    {
        title: 'Оферент',
        dataIndex: 'seller_full_name',
        key: 'seller_full_name',
        sorter: true,
        render: (value, record) => LinkCell(value, `/clients/${record.seller_id}`),
    },
    {
        title: 'Акцептант',
        dataIndex: 'buyer_full_name',
        key: 'buyer_full_name',
        sorter: true,
        render: (value, record) =>
            record.buyer_id ? LinkCell(value, `/clients/${record.buyer_id}`) : '',
    },
    {
        title: 'Срок действия предложения',
        dataIndex: 'expired_at',
        key: 'expired_at',
        sorter: true,
        render: DateTimeCell,
    },
]
