import { RateType, getPennies, stringToNumber } from '@dltru/dfa-models'

export const prepareGridFormValue = (
    checkedRates: string[],
    formValues: Record<string, string>,
    oldRates?: RateType[],
) => {
    const oldRateIds =
        oldRates?.reduce((acc, rate) => {
            acc[rate.fee_code] = rate.id
            return acc
        }, {} as Record<string, string>) ?? {}
    return checkedRates.map((fee_code) => ({
        id: oldRateIds[fee_code],
        fee_code,
        minimum: getPennies(stringToNumber(formValues[`${fee_code}minimum`])) || undefined,
        annual_percentage: formValues[`${fee_code}annual_percentage`]
            ? (stringToNumber(formValues[`${fee_code}annual_percentage`]) * 100).toFixed(2)
            : undefined,
        amount_percentage: formValues[`${fee_code}amount_percentage`]
            ? (stringToNumber(formValues[`${fee_code}amount_percentage`]) * 100).toFixed(2)
            : undefined,
    }))
}
