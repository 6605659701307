import { getStatusesDfaByToken } from '@utils/roles'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import { notificationCounterSlice } from '@store/notificationCounter/index'

import api from '@services/api'

function* getRegistrationDfaCount() {
    try {
        const statuses = getStatusesDfaByToken()
        if (!statuses) {
            throw 'empty statuses'
        }
        const { data, error } = yield* call(api.lib.getCountDfa, { statuses })

        if (error) {
            throw error
        }
        yield* put(
            notificationCounterSlice.actions.updateCounter({
                registrationDfaCount: data?.item ?? null,
            }),
        )
    } catch (error: unknown) {
        yield* put(notificationCounterSlice.actions.updateCounter({ registrationDfaCount: null }))
    }
}

function* getAllCount() {
    yield* put(notificationCounterSlice.actions.getRegistrationDfaCount())
}

export function* watchNotificatorCounter(): Generator<StrictEffect> {
    yield* takeLatest(
        notificationCounterSlice.actions.getRegistrationDfaCount.type,
        getRegistrationDfaCount,
    )
    yield* takeLatest(notificationCounterSlice.actions.getAllCount.type, getAllCount)
}
