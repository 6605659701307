import { BusinessmanFormValues } from '@dltru/dfa-models'
import {
    FinancialPositionStep,
    IRoleFormStepProps,
    IStepper,
    PublicPersonStep,
    StepperC,
} from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadInput } from '@components/Form'

import { BankDetailsStep } from '../components/BankDetailsStep'
import { BusinessReputationStep } from '../components/BusinessReputationStep'
import { FinancialOperationsStep } from '../components/FinancialOperationsStep'
import { LicensesStep } from '../components/LicensesStep'
import { BasicInformationStep, BeneficiaryStep, BusinessInformationStep } from './steps'

export const BusinessmanStepper: FC<
    Omit<IStepper<BusinessmanFormValues>, 'children'> & IRoleFormStepProps
> = ({ disableFields, isEditAllow, ...props }) => (
    <StepperC {...props}>
        <BasicInformationStep isEditAllow={isEditAllow} disableFields={disableFields} />
        <BusinessInformationStep isEditAllow={isEditAllow} disableFields={disableFields} />
        <PublicPersonStep stepIndex={2} isEditAllow={isEditAllow} />
        <LicensesStep
            isEditAllow={isEditAllow}
            stepIndex={3}
            stepTitle="Сведения о лицензируемой деятельности"
        />

        <FinancialPositionStep stepIndex={4} isEditAllow={isEditAllow} UploadInput={UploadInput} />
        <BusinessReputationStep isEditAllow={isEditAllow} stepIndex={5} />
        <BeneficiaryStep isEditAllow={isEditAllow} />
        <BankDetailsStep
            stepIndex={7}
            isEditAllow={isEditAllow}
            topText={`
                    Укажите, пожалуйста, банковские реквизиты, получателем платежа по которым
                    является ИП ${props?.initialValues?.last_name} ${props?.initialValues?.first_name} ${props?.initialValues?.second_name}`}
        />
        <FinancialOperationsStep stepIndex={8} isEditAllow={isEditAllow} />
    </StepperC>
)
