import { FilterRes } from '@dltru/dfa-models'
import { createSelector } from '@reduxjs/toolkit'

import IAppState from '../../states'

const currentPage = (state: IAppState): FilterRes => {
    if (state.dfaOwnersList.isLoading) {
        return { Rows: [], Total: 0, loading: true }
    }

    const { data, page, limit } = state.dfaOwnersList

    return {
        Rows: data,
        Total: (page + 1) * limit,
        loading: false,
    }
}

const selectData = (state: IAppState) => state.dfaOwnersList.data
const selectIsLoading = (state: IAppState) => state.dfaOwnersList.isLoading
const selectDfaTotalForRepayment = createSelector(selectData, (data) => {
    return data.reduce((acc, curr) => {
        const curretnTotal =
            curr.active_amount_dfa +
            curr.injunction_amount_dfa +
            curr.blocked_amount_dfa +
            curr.encumbrance_amount_dfa

        acc = acc + curretnTotal

        return acc
    }, 0)
})

export const ownersSelector = {
    currentPage,
    selectData,
    selectIsLoading,
    selectDfaTotalForRepayment,
}
