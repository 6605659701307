import { Button } from 'antd'
import { FC, useContext, useEffect, useState } from 'react'

import { ArrowDown, ArrowUp, TableFilter } from '../../../Icons'
import { FiltersContext } from './FiltersContext'
import style from './style.m.less'

type FiltersToggleProps = {
    disabled?: boolean
    onClick: () => void
    closed: boolean
}

export const FiltersToggle: FC<FiltersToggleProps> = ({ disabled, onClick, closed }) => {
    const { totalActiveFilters } = useContext(FiltersContext)
    const [isOpen, setIsOpen] = useState(() => closed)

    useEffect(() => {
        if (closed) {
            setIsOpen(false)
        }
    }, [closed])

    return (
        <Button
            className={style.filtersToggle}
            onClick={() => {
                onClick()
                setIsOpen(!isOpen)
            }}
            icon={<TableFilter />}
            disabled={disabled}
        >
            {totalActiveFilters ? `Фильтр ${totalActiveFilters}` : 'Фильтр'}
            {isOpen ? (
                <ArrowUp />
            ) : disabled ? null : (
                <ArrowDown className="filter_arrow_icon_dark" />
            )}
        </Button>
    )
}
