import { FC, useState } from 'react'

import { Box } from '../../Box'
import { CheckboxChangeEvent } from '../../Checkbox'
import { CheckboxItem, CheckboxItemProps } from '../../components/Form'

type ComponentProps = Partial<CheckboxItemProps> & {
    ordinaryOrder?: boolean
    showOnFalse?: boolean
    initialValue?: boolean
}

export const ShowHideCheckboxWrapper: FC<ComponentProps> = ({
    checkboxTitle,
    uneditable,
    ordinaryOrder,
    showOnFalse = false,
    children,
    initialValue = false,
    ...restProps
}) => {
    const [checked, setChecked] = useState(initialValue)
    const onChange = (event: CheckboxChangeEvent) => {
        setChecked(event.target.checked)
    }
    return (
        <>
            <Box margin={[0, 0, 0, ordinaryOrder ? 0 : 240]}>
                <CheckboxItem
                    {...restProps}
                    checkboxTitle={checkboxTitle}
                    className={ordinaryOrder ? 'formComponentItem' : ''}
                    uneditable={uneditable}
                    onChange={onChange}
                />
            </Box>
            <Box margin={[0, 0, 0, uneditable ? 240 : 0]}>
                {(showOnFalse ? !checked : checked) && children}
            </Box>
        </>
    )
}
