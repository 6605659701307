import { ITransferDeal, ITransferOrderShort } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTransferDeals } from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

import { transferModal } from '@components/Modals/Deals'

import { getGridConfig } from './gridConfig'

interface ITransferOrderData {
    data: ITransferOrderShort
}

export const TransferOrdersTable: FC<ITransferOrderData> = ({ data }) => {
    const reduxDispatch = useDispatch()

    const transferDeals = useSelector(dfaTransferSelector.selectTransferDeals)
    const isLoading = useSelector(dfaTransferSelector.selectDealsIsLoading)

    const onRow = (row: ITransferDeal) => ({
        onClick: async () => transferModal({ deal: row }),
    })

    useEffect(() => {
        reduxDispatch(getTransferDeals(null))
    }, [data.global_id])

    const gridConfig = getGridConfig(data.initiator_id)

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={transferDeals}
            clickableRow
            onRow={onRow}
        />
    )
}
