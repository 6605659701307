import { checkTokenErrors, openMessage, openNotification } from '@dltru/dfa-ui'
import { sha256 } from 'js-sha256'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import {
    TokenStatusEnum,
    authSlice,
    changeEmailInitAdminConfirm,
    changeLoginPasswordConfirm,
    changeLoginPasswordRequest,
    changePhoneConfirm,
    changePhoneRequest,
    checkConfirmToken,
    resetPasswordConfirm,
    resetPasswordInit,
} from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import api from '@services/api'

function* checkConfirmTokenSaga({ payload }: ReturnType<typeof checkConfirmToken>) {
    const { data, error } = yield api.lib.emailConfirmForResettingPasswordRequest(payload)

    if (error) {
        yield* put(
            authSlice.actions.setChangeAuthData({
                tokenStatus: checkTokenErrors[error.response.data.error.message],
            }),
        )
    }

    if (data.item.is_valid) {
        yield* put(authSlice.actions.setChangeAuthData({ tokenStatus: TokenStatusEnum.verify }))
    } else if (data.item.invalid_reason !== undefined) {
        if (data.item.invalid_reason) {
            yield* put(
                authSlice.actions.setChangeAuthData({
                    tokenStatus: checkTokenErrors[data.item.invalid_reason],
                }),
            )
        }
    } else {
        yield* put(
            authSlice.actions.setChangeAuthData({
                tokenStatus: 'Внутрисерверная ошибка. Некорректный ответ',
            }),
        )
    }
}
function* resetPasswordInitSaga({ payload }: ReturnType<typeof resetPasswordInit>) {
    try {
        const { data, error } = yield api.lib.resetPasswordInitService(payload)

        if (error) {
            throw error
        }

        yield* put(
            authSlice.actions.setChangeAuthData({
                temporaryJWT: data.item.pre_access_jwt_token,
                lastPhoneDigits: data.item.phone_last_four_digits,
                phoneVerificationStatus: 'await',
            }),
        )
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Ошибка инциализации установки нового пароля',
        })
    }
}

function* resetPasswordConfirmSaga({ payload }: ReturnType<typeof resetPasswordConfirm>) {
    try {
        const changeAuthData = yield* select(authSelector.selectChangeAuthState)
        if (changeAuthData.temporaryJWT) {
            const { code, isAdminInitial } = payload
            const apiForSend = isAdminInitial
                ? api.lib.changePasswordByAdminConfirm
                : api.lib.resetPasswordConfirmService
            const { error } = yield* call(apiForSend, code, changeAuthData.temporaryJWT)
            if (error) {
                throw error
            }
        } else {
            throw new Error('отсутствует JWT токен')
        }
        yield* put(authSlice.actions.setChangeAuthData({ phoneVerificationStatus: 'confirmed' }))

        openNotification({
            type: 'success',
            message: 'Пароль успешно изменён',
            description: 'Войдите в систему с новым паролем.',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Неверный код, попробуйте ещё раз.',
        })
    }
}
function* changeEmailInitAdminConfirmSaga({
    payload,
}: ReturnType<typeof changeEmailInitAdminConfirm>) {
    try {
        yield* put(
            authSlice.actions.setChangeAuthData({ tokenStatus: 'Выполняется проверка ссылки...' }),
        )
        const { error, data } = yield* call(api.lib.changeEmailInitAdminConfirm, payload)
        if (error) {
            throw error
        }
        if (data?.item?.email) {
            yield* put(
                authSlice.actions.setChangeAuthData({
                    newEmail: data.item.email,
                    tokenStatus: TokenStatusEnum.verify,
                }),
            )
        }
    } catch (error) {
        yield* put(authSlice.actions.setChangeAuthData({ tokenStatus: 'Ссылка недействительна' }))
    }
}
function* changePhoneRequestSaga({ payload }: ReturnType<typeof changePhoneRequest>) {
    try {
        const { data, error } = yield* call(api.lib.changePhoneSet, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        if (data?.item) {
            yield* put(
                authSlice.actions.setChangeAuthData({
                    phoneVerificationStatus: 'await',
                    temporaryJWT: data.item.pre_access_token,
                }),
            )
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли проблемы при сохранении номера телефона' })
    }
}

function* changePhoneConfirmSaga({ payload }: ReturnType<typeof changePhoneConfirm>) {
    try {
        const { temporaryJWT } = yield* select(authSelector.selectChangeAuthState)
        if (temporaryJWT) {
            const { error } = yield* call(api.lib.changePhoneConfirm, payload, temporaryJWT)
            if (error) {
                throw error
            }

            yield* put(
                authSlice.actions.setChangeAuthData({ phoneVerificationStatus: 'confirmed' }),
            )
            openNotification({
                type: 'success',
                message: 'Номер телефона успешно изменён',
                description: 'При авторизации используйте новый номер телефона.',
            })
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли проблемы при отправке кода подтверждения' })
    }
}

function* changeLoginPasswordRequestSaga({
    payload,
}: ReturnType<typeof changeLoginPasswordRequest>) {
    try {
        const { data, error } = yield* call(api.lib.changeLoginPasswordSet, {
            ...payload,
            password: sha256(payload.password).toString(),
        })
        if (error) {
            throw error
        }
        if (data?.item) {
            yield* put(
                authSlice.actions.setChangeAuthData({
                    phoneVerificationStatus: 'await',
                    temporaryJWT: data.item.pre_access_token,
                    lastPhoneDigits: data.item.phone?.slice(-4),
                }),
            )
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли проблемы при сохранении логина и пароля' })
    }
}

function* changeLoginPasswordConfirmSaga({
    payload,
}: ReturnType<typeof changeLoginPasswordConfirm>) {
    try {
        const { temporaryJWT } = yield* select(authSelector.selectChangeAuthState)
        if (temporaryJWT) {
            const { error } = yield* call(api.lib.changeLoginPasswordConfirm, payload, temporaryJWT)
            if (error) {
                throw error
            }

            yield* put(
                authSlice.actions.setChangeAuthData({ phoneVerificationStatus: 'confirmed' }),
            )
            openNotification({
                type: 'success',
                message: 'Логин и пароль успешно изменены',
                description: 'Войдите в систему с новым логином и паролем.',
            })
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли проблемы при отправке кода подтверждения' })
    }
}
export function* changeAuthData(): Generator<StrictEffect> {
    yield* takeLatest(checkConfirmToken.type, checkConfirmTokenSaga)
    yield* takeLatest(resetPasswordInit.type, resetPasswordInitSaga)
    yield* takeLatest(resetPasswordConfirm.type, resetPasswordConfirmSaga)
    yield* takeLatest(changeEmailInitAdminConfirm.type, changeEmailInitAdminConfirmSaga)
    yield* takeLatest(changePhoneRequest.type, changePhoneRequestSaga)
    yield* takeLatest(changePhoneConfirm.type, changePhoneConfirmSaga)
    yield* takeLatest(changeLoginPasswordRequest.type, changeLoginPasswordRequestSaga)
    yield* takeLatest(changeLoginPasswordConfirm.type, changeLoginPasswordConfirmSaga)
}
