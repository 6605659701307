import { Box, PageHeader, Tabs } from '@dltru/dfa-ui'
import { FC } from 'react'

import { NominalAccountsTable } from '@pages/NominalAccount'

import { UseTabsAndHash } from '../../hooks/useTabsAndHash'
import { PersonalAccountsTable } from './components/PersonalAccountsTable'
import { SauTable } from './components/SauTable'
import { TechSauTable } from './components/TechSauTable'

const { TabPane } = Tabs
enum TabName {
    personal = 'personal',
    bank = 'bank',
    sauOis = 'sau-ois',
    techSau = 'tech-sau',
}
const tabNames = Object.values(TabName)
const defaultTab = TabName.personal

export const PersonalAccounts: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(tabNames, defaultTab)

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Реестр счетов" />
            </Box>
            <Tabs activeKey={activeTab} onChange={onChangeTab}>
                <TabPane tab="Лицевые счета" key={TabName.personal}>
                    <PersonalAccountsTable />
                </TabPane>

                <TabPane tab="Банковские счета" key={TabName.bank}>
                    <NominalAccountsTable />
                </TabPane>

                <TabPane tab="САУ ОИС" key={TabName.sauOis}>
                    {activeTab === 'sau-ois' && <SauTable />}
                </TabPane>

                <TabPane tab="Технический САУ" key={TabName.techSau}>
                    {activeTab === 'tech-sau' && <TechSauTable />}
                </TabPane>
            </Tabs>
        </>
    )
}
