import { API } from '@dltru/api-request'

import { Agent } from './agent'

const BaseUrl = process.env.REACT_APP_API || ''

const agent = Agent(BaseUrl)

const api = new API({ agent })

export default api
