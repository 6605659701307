import { frontUrl } from '@constants/frontUrl'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'

import { Businessman, ClientRepresentative, Individual, LegalEntity } from '@pages/RoleForms'

import { ClientCard } from './ClientCard'

export const ClientRoutes = () => {
    const { id } = useParams()

    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (id) {
            reduxDispatch(clientsSlice.actions.getCurrentClient(id))
        }
        return () => {
            reduxDispatch(clientsSlice.actions.clearCurrentClientData())
        }
    }, [id])
    return (
        <Routes>
            <Route path={frontUrl.questionnaireRepresentative} element={<ClientRepresentative />} />
            <Route path={frontUrl.questionnaireIndividual} element={<Individual />} />
            <Route path={frontUrl.questionnaireLegalEntity} element={<LegalEntity />} />
            <Route path={frontUrl.questionnaireBusinessman} element={<Businessman />} />
            <Route index element={<ClientCard />} />
        </Routes>
    )
}
