import { PaymentOrder } from '@dltru/dfa-models'
import {
    ColumnsType,
    DateTimeCell,
    LinkCell,
    Tag,
    Tooltip,
    getRublesFromPennies,
} from '@dltru/dfa-ui'
import { getTagProps } from '@resources/paymentOrderState'

export const gridConfig: ColumnsType<PaymentOrder> = [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Номер ПП',
        dataIndex: 'number',
        key: 'number',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Поручение пользователя',
        dataIndex: 'transaction_uuid',
        key: 'transaction_uuid',
        sorter: true,
        ellipsis: true,
        render: (value) => LinkCell(value, value),
    },
    {
        title: 'Пользователь',
        dataIndex: 'transaction_account_owner_full_name',
        key: 'transaction_account_owner_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => (
            <Tooltip title={`ИНН ${record.payer_inn}`} placement="top">
                {value}
            </Tooltip>
        ),
    },
    {
        title: 'Получатель',
        dataIndex: 'recipient',
        key: 'recipient',
        sorter: true,
        ellipsis: true,
        render: (value, record) => (
            <Tooltip title={`ИНН ${record.recipient_inn}`} placement="top">
                {value}
            </Tooltip>
        ),
    },
    {
        title: 'Сумма, ₽',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: getRublesFromPennies,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        render: (value) => {
            const tagProps = getTagProps(value)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
