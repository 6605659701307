import { IOrderDetailsState, IOrderListState } from './types'

export const initialState = {
    id: 0,
    user_id: '',
    asset_id: 0,
    status: 'in_progress',
    created_at: 0,
    canceled_at: 0,
    executed_at: 0,
    amount_dfa: 0,
    price_per_dfa: 0,
    total_price: 0,
    isLoading: false,
    error: '',
    uuid: '',
    cancel_reason: '',
    repurchase_percent: 0,
    deal_fee: 0,
} as IOrderDetailsState

export const initialStateList = {
    error: '',
    isLoading: false,
    data: [],
} as IOrderListState
