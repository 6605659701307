import { ISecondaryOrder, SecondaryOrderStatus } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { loadSecondaryDfas, loadSecondaryDfasByAssetId, updateSecondaryDfaList } from './index'

function* handleLoadSecondaryDfas() {
    try {
        yield* put(updateSecondaryDfaList({ error: '', isLoading: true }))
        const { data, error } = yield api.lib.getSecondaryDfaOrdersService()
        if (error) {
            throw error
        }

        const _data = (data?.items ?? [])
            .filter((order: ISecondaryOrder) =>
                [SecondaryOrderStatus.accepted].includes(order.status),
            )
            .map((order: ISecondaryOrder) => {
                return {
                    ...order,
                    price_per_dfa: order.price_per_dfa ? order.price_per_dfa / 100 : 0,
                    total_price: order.total_price ? order.total_price / 100 : 0,
                    rest_price: order.rest_price ? order.rest_price / 100 : 0,
                    repurchase_percent: order.repurchase_percent
                        ? order.repurchase_percent / 100
                        : 0,
                }
            })

        yield* put(updateSecondaryDfaList({ isLoading: false, data: _data }))
    } catch (error) {
        yield* put(updateSecondaryDfaList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadSecondaryDfasByAssetId({
    payload,
}: ReturnType<typeof loadSecondaryDfasByAssetId>) {
    try {
        yield* put(updateSecondaryDfaList({ error: '', isLoading: true }))

        const { data, error } = yield api.lib.getSecondaryDfaOrdersService()

        if (error) {
            throw error
        }

        const _data = (data?.items ?? [])
            .filter((order: ISecondaryOrder) => {
                return ![
                    SecondaryOrderStatus.rejected,
                    SecondaryOrderStatus.executed,
                    SecondaryOrderStatus.canceled,
                    SecondaryOrderStatus.in_progress,
                ].includes(order.status)
            })
            .filter((order: ISecondaryOrder) => `${order.asset_id}` === `${payload}`)
            .map((order: ISecondaryOrder) => {
                return {
                    ...order,
                    price_per_dfa: order.price_per_dfa ? order.price_per_dfa / 100 : 0,
                    total_price: order.total_price ? order.total_price / 100 : 0,
                    rest_price: order.rest_price ? order.rest_price / 100 : 0,
                    repurchase_percent: order.repurchase_percent
                        ? order.repurchase_percent / 100
                        : 0,
                }
            })

        yield* put(updateSecondaryDfaList({ isLoading: false, data: _data }))
    } catch (error) {
        yield* put(updateSecondaryDfaList({ error, isLoading: false, data: [] }))
    }
}

export function* watchLoadSecondaryDfas(): Generator<StrictEffect> {
    yield* takeLatest(loadSecondaryDfas.type, handleLoadSecondaryDfas)
}

export function* watchLoadSecondaryDfasByAssetId(): Generator<StrictEffect> {
    yield* takeLatest(loadSecondaryDfasByAssetId.type, handleLoadSecondaryDfasByAssetId)
}
