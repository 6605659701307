import { Box, Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { rejectApplication } from '@store/dfa/details'

import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

type FormValuesType = {
    reason?: string
}

export const DeclineModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [form] = Form.useForm<FormValuesType>()

    const [isFilledField, setIsFilledField] = useState(false)
    const reduxDispatch = useDispatch()

    const handleCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }
    const handleOk = (values: FormValuesType) => {
        reduxDispatch(rejectApplication(values))
        handleCancel()
    }

    const onValuesChange = (changedValues: FormValuesType) => {
        setIsFilledField(Boolean(changedValues.reason))
    }

    return (
        <Modal
            title="Отказать в допуске ЦФА к выпуску"
            visible={isModalVisible}
            onCancel={handleCancel}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="danger"
                    onClick={form.submit}
                    disabled={!isFilledField}
                >
                    Да, отказать
                </Button>,
            ]}
        >
            <div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOk}
                    onValuesChange={onValuesChange}
                >
                    <Box padding={[24, 0, 16, 0]}>
                        Вы действительно хотите отказать в допуске ЦФА к выпуску?
                    </Box>
                    <Divider />
                    <h3 className={style.header}>Причина отказа</h3>
                    <WrappedInput
                        name="reason"
                        multiline
                        placeholder="Ваш комментарий"
                        rows={5}
                        required
                    />
                </Form>
            </div>
        </Modal>
    )
}
