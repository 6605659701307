import { ITariffGrid } from '@dltru/dfa-models'
import { Box, Button, PlusSquare, Table, getTariffGridConfig } from '@dltru/dfa-ui'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { tariffSelector } from '@store/tariff/selectors'

import { ActivateGridModal } from './ActionCell/ActivateGridModal'
import { RemoveGridModal } from './ActionCell/RemoveGridModal'
import { StopActivateGridModal } from './ActionCell/StopActivateGridModal'
import { EditGridModal, PropsForModalType } from './EditGridModal'
import { getGridConfig } from './gridConfig'
import { RbacContext } from '@dltru/dfa-rbac'

export const HistoryTariffGrid: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const [isEditModalVisible, setIsEditModalVisible] = useState(false)
    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
    const [isActivateModalVisible, setIsActivateModalVisible] = useState(false)
    const [isStopActivateModalVisible, setIsStopActivateModalVisible] = useState(false)
    const [propsForModal, setPropsForModal] = useState<PropsForModalType>({ isCreate: true })
    const data = useSelector(tariffSelector.selectItem)
    const feeNames = useSelector(tariffSelector.selectFeeNames)

    const showCreateBtn = !data.archived_at && isAllow(['tariff.write.all'])

    const expandable = useMemo(() => {
        const gridConfig = getTariffGridConfig({ feeNames })
        return {
            expandedRowRender: (record: ITariffGrid) => (
                <Table
                    columns={gridConfig}
                    dataSource={record.rates}
                    rowKey="fee_code"
                    uneditable
                    noPagination
                    inner
                />
            ),
            rowExpandable: (record: ITariffGrid) => record.rates?.length,
        }
    }, [feeNames])

    const addTariffGrid = useCallback(() => {
        setPropsForModal({ isCreate: true })
        setIsEditModalVisible(true)
    }, [])

    const gridConfig = useMemo(() => {
        const createFromTariffGrid = (gridId: string) => {
            setPropsForModal({ isCreate: true, gridId })
            setIsEditModalVisible(true)
        }
        const editTariffGrid = (gridId: string) => {
            setPropsForModal({ gridId })
            setIsEditModalVisible(true)
        }
        const removeTariffGrid = (gridId: string) => {
            setPropsForModal({ gridId })
            setIsRemoveModalVisible(true)
        }
        const activateTariffGrid = (gridId: string) => {
            setPropsForModal({ gridId })
            setIsActivateModalVisible(true)
        }
        const stopActivateTariffGrid = (gridId: string) => {
            setPropsForModal({ gridId })
            setIsStopActivateModalVisible(true)
        }
        return getGridConfig({
            createFromTariffGrid,
            editTariffGrid,
            removeTariffGrid,
            activateTariffGrid,
            stopActivateTariffGrid,
        })
    }, [])

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                columns={gridConfig}
                dataSource={data?.grids || []}
                expandable={expandable}
                rowKey="id"
                footerLeft={
                    showCreateBtn && (
                        <Button icon={<PlusSquare />} borderRadius={12} onClick={addTariffGrid}>
                            Создать тарифную сетку
                        </Button>
                    )
                }
            />
            <EditGridModal
                isModalVisible={isEditModalVisible}
                setIsModalVisible={setIsEditModalVisible}
                props={propsForModal}
            />
            <RemoveGridModal
                isModalVisible={isRemoveModalVisible}
                setIsModalVisible={setIsRemoveModalVisible}
                props={propsForModal}
            />
            <ActivateGridModal
                isModalVisible={isActivateModalVisible}
                setIsModalVisible={setIsActivateModalVisible}
                props={propsForModal}
            />
            <StopActivateGridModal
                isModalVisible={isStopActivateModalVisible}
                setIsModalVisible={setIsStopActivateModalVisible}
                props={propsForModal}
            />
        </Box>
    )
}
