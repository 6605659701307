// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nameButton_wHNQP {\n  font-weight: normal !important;\n}\n.grayButton_LKBoq {\n  color: #b9bac1 !important;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/certifacate/CertificateButton/CertificateButton.m.less"],"names":[],"mappings":"AAEA;EACE,8BAAA;AADF;AAGA;EACE,yBAAA;AADF","sourcesContent":["@import 'style/palette';\n\n.nameButton {\n  font-weight: normal !important;\n}\n.grayButton {\n  color: @gray-6 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameButton": "nameButton_wHNQP",
	"grayButton": "grayButton_LKBoq"
};
export default ___CSS_LOADER_EXPORT___;
