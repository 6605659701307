import IAppState from '../states'

const selectList = (state: IAppState) => state.employee.data?.items
const selectItem = (state: IAppState) => state.employee.currentEmployee
const selectAuthDataItem = (state: IAppState) => state.employee.currentAuthDataEmployee
const selectPaging = (state: IAppState) => state.employee.data?.paging
const selectParams = (state: IAppState) => state.employee.params
const selectIsLoading = (state: IAppState) => state.employee.isLoading
const selectBlockingEvent = (state: IAppState) => state.employee.currentBlockingEvent

export const employeeSelector = {
    selectList,
    selectItem,
    selectAuthDataItem,
    selectPaging,
    selectIsLoading,
    selectBlockingEvent,
    selectParams,
}
