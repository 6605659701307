import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const AuditIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.78125 4.88281C5.69531 4.88281 5.625 4.95312 5.625 5.03906V5.97656C5.625 6.0625 5.69531 6.13281 5.78125 6.13281H13.2812C13.3672 6.13281 13.4375 6.0625 13.4375 5.97656V5.03906C13.4375 4.95312 13.3672 4.88281 13.2812 4.88281H5.78125ZM9.375 7.69531H5.78125C5.69531 7.69531 5.625 7.76562 5.625 7.85156V8.78906C5.625 8.875 5.69531 8.94531 5.78125 8.94531H9.375C9.46094 8.94531 9.53125 8.875 9.53125 8.78906V7.85156C9.53125 7.76562 9.46094 7.69531 9.375 7.69531ZM8.4375 16.6406H4.0625V2.89062H15V9.14062C15 9.22656 15.0703 9.29688 15.1562 9.29688H16.25C16.3359 9.29688 16.4062 9.22656 16.4062 9.14062V2.10938C16.4062 1.76367 16.127 1.48438 15.7812 1.48438H3.28125C2.93555 1.48438 2.65625 1.76367 2.65625 2.10938V17.4219C2.65625 17.7676 2.93555 18.0469 3.28125 18.0469H8.4375C8.52344 18.0469 8.59375 17.9766 8.59375 17.8906V16.7969C8.59375 16.7109 8.52344 16.6406 8.4375 16.6406ZM17.0312 14.9219H14.2188V14.207C15.123 13.9375 15.7812 13.1016 15.7812 12.1094C15.7812 10.9004 14.8027 9.92188 13.5938 9.92188C12.3848 9.92188 11.4062 10.9004 11.4062 12.1094C11.4062 13.0996 12.0645 13.9375 12.9688 14.207V14.9219H10.1562C9.98438 14.9219 9.84375 15.0625 9.84375 15.2344V18.2031C9.84375 18.375 9.98438 18.5156 10.1562 18.5156H17.0312C17.2031 18.5156 17.3438 18.375 17.3438 18.2031V15.2344C17.3438 15.0625 17.2031 14.9219 17.0312 14.9219ZM12.6172 12.1094C12.6172 11.5703 13.0547 11.1328 13.5938 11.1328C14.1328 11.1328 14.5703 11.5703 14.5703 12.1094C14.5703 12.6484 14.1328 13.0859 13.5938 13.0859C13.0547 13.0859 12.6172 12.6484 12.6172 12.1094ZM16.1328 17.3047H11.0547V16.1328H16.1328V17.3047Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
