import {
    ProfileRequestType,
    ProfileStatus,
    ProfileType,
    TransactionDataFront,
    TransactionType,
    UserBlocks,
    ValueTypes,
} from '@dltru/dfa-models'
import { BeneficialFormValues } from '@dltru/dfa-models'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

export const getProfileRequestType = (
    profileTypes: ProfileType[],
): Exclude<ProfileRequestType, 'beneficial_owner'> => {
    if (profileTypes.includes(ProfileType.LEGL)) {
        return ProfileRequestType.LEGAL_ENTITY
    }
    if (profileTypes.includes(ProfileType.BUSN)) {
        return ProfileRequestType.BUSINESSMAN
    }
    if (profileTypes.includes(ProfileType.AGNT)) {
        return ProfileRequestType.AGENT
    }
    return ProfileRequestType.INDIVIDUAL
}

export const getPathByProfileType: Record<
    Exclude<ProfileType, 'AGNT' | 'BENF' | 'EMPL'>,
    Exclude<ProfileRequestType, 'agent' | 'beneficial_owner'>
> = {
    [ProfileType.BUSN]: ProfileRequestType.BUSINESSMAN,
    [ProfileType.LEGL]: ProfileRequestType.LEGAL_ENTITY,
    [ProfileType.PRSN]: ProfileRequestType.INDIVIDUAL,
}

export const profileTypeTranslate = {
    [ProfileType.LEGL]: 'Юр. лицо',
    [ProfileType.BUSN]: 'ИП',
    [ProfileType.PRSN]: 'Физ. лицо',
}

const getTitleFromDay = (date: number) => {
    const dateObject = dayjs.unix(date).locale('ru')
    const isToday = dayjs().isSame(dateObject, 'day')
    const isYesterday = dayjs().subtract(1, 'day').isSame(dateObject, 'day')
    if (isToday || isYesterday) {
        return {
            title: isToday ? 'Сегодня' : 'Вчера',
            subtitle: `, ${dateObject.format('DD MMMM[,] dddd')}`,
        }
    }
    return {
        title: dateObject.format('DD MMMM'),
        subtitle: `, ${dateObject.format('dddd')}`,
    }
}
const getAmountFromItem = (item: TransactionType) =>
    item.type === 'debit' && item.amount !== 0 ? item.amount * -1 : item.amount

const getRowDetails = (item: TransactionType) => {
    return {
        title: item.purpose,
        subtitle: dayjs.unix(item.created_at).format('HH:mm:ss'),
        total: getAmountFromItem(item),
        type: item.type as string,
        status: item.status,
        id: item.uuid,
        timestamp: item.created_at,
        serviceCode: item.service_code,
        requisites: [
            {
                type: ValueTypes.copied,
                value: item.uuid,
                label: 'ID операции',
            },
        ],
    }
}

const getCurrentType = (transaction: TransactionType, userId?: string) => {
    switch (transaction.type) {
        case 'transfer': {
            if (transaction.sender_uuid === userId) {
                return 'debit'
            } else {
                return 'credit'
            }
        }
        case 'payment': {
            if (transaction.sender_uuid === userId) {
                return 'debit'
            } else {
                return 'credit'
            }
        }
        case 'any_payment': {
            return 'debit'
        }
        default:
            return transaction.type
    }
}
const availableTypesForTransaction = ['debit', 'credit', 'transfer', 'payment', 'any_payment']

export const groupTransactionsByDate = (transactions: TransactionType[], userId?: string) => {
    const transactionsWithDateStr = transactions
        .filter(({ type }) => availableTypesForTransaction.includes(type))
        .map((transaction) => ({
            ...transaction,
            dateStr: dayjs.unix(transaction.created_at).format('DD.MM.YYYY'),
            type: getCurrentType(transaction, userId),
        }))
    return transactionsWithDateStr.reduceRight((acc, item) => {
        const summaryLength = acc.length
        if (summaryLength && acc[summaryLength - 1]?.summary?.dateStr === item.dateStr) {
            acc[summaryLength - 1].summary.total += getAmountFromItem(item)
            acc[summaryLength - 1].rows.push(getRowDetails(item))
        } else {
            acc.push({
                summary: {
                    ...getTitleFromDay(item.created_at),
                    total: getAmountFromItem(item),
                    dateStr: item.dateStr,
                },
                rows: [getRowDetails(item)],
            })
        }
        return acc
    }, [] as TransactionDataFront[])
}

export const getFullName = (data: any) => {
    if (!data) {
        return ''
    }
    if (data?.person) {
        return `${data?.business_info ? 'ИП ' : ''}${data.person?.last_name} ${
            data.person?.first_name
        }${data.person.second_name ? ` ${data.person.second_name}` : ''}`
    }
    return `${data.legal_form ? `${data.legal_form} ` : ''}${data.title}`
}

export const getClientLockTitle = (lockInfo: UserBlocks) => {
    if (lockInfo.block && lockInfo.is_full_blocked) {
        return 'Полное блокирование'
    }
    if (!lockInfo.block && lockInfo.is_full_blocked) {
        return 'Снятие полного блокирования'
    }
    if (lockInfo.block && lockInfo.is_partly_blocked) {
        return 'Частичное блокирование'
    }
    if (!lockInfo.block && lockInfo.is_partly_blocked) {
        return 'Снятие частичного блокирования'
    }
}

const weightStatuses = {
    [ProfileStatus.REJECTED]: 1,
    [ProfileStatus.ARCHIVED]: 2,
    [ProfileStatus.DRAFT]: 3,
    [ProfileStatus.NEW]: 4,
    [ProfileStatus.REWORK]: 5,
    [ProfileStatus.OPERATOR_APPROVAL]: 6,
    [ProfileStatus.LAWYER_APPROVAL]: 7,
    [ProfileStatus.PODFT_APPROVAL]: 8,
    [ProfileStatus.IDENTIFICATION]: 9,
    [ProfileStatus.IDENTIFIED]: 10,
    [ProfileStatus.APPROVED]: 11,
}

export const calcMainProfileStatus = (statusClient: ProfileStatus, statusAgent?: ProfileStatus) => {
    if (!statusAgent || statusClient === statusAgent) {
        return statusClient
    }

    if (weightStatuses[statusClient] < weightStatuses[statusAgent]) {
        return statusClient
    }

    return statusAgent
}

export const checkBeneficialUuids = (
    beneficialUuids: string[] | undefined | null,
    beneficiaries: BeneficialFormValues[] | undefined,
) => {
    if (!beneficialUuids?.length) {
        return true
    }
    return beneficialUuids.every((uuid) => beneficiaries?.find((item) => item.uuid === uuid))
}
