// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".warning__TyUl {\n  color: #fa8c16;\n}\n.warning__TyUl svg {\n  width: 16px;\n  height: 16px;\n}\n.error__ZaAe {\n  color: #ff4d4f;\n}\n.error__ZaAe svg {\n  width: 16px;\n  height: 16px;\n}\n.success__Zbdc {\n  color: #73d13d;\n}\n.success__Zbdc svg {\n  width: 16px;\n  height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmissionCardPage/components/ServiceHistory/style.m.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAAA;EAMM,WAAA;EACA,YAAA;AAHN;AAQA;EACE,cAAA;AANF;AAKA;EAKM,WAAA;EACA,YAAA;AAPN;AAYA;EACE,cAAA;AAVF;AASA;EAMM,WAAA;EACA,YAAA;AAZN","sourcesContent":["@import 'style/palette';\n\n.warning {\n  color: @orange-6;\n\n  :global {\n\n    svg {\n      width: 16px;\n      height: 16px;\n    }\n  }\n}\n\n.error {\n  color: @red-5;\n\n  :global {\n    svg {\n      width: 16px;\n      height: 16px;\n    }\n  }\n}\n\n.success {\n  color: @green-5;\n\n  :global {\n\n    svg {\n      width: 16px;\n      height: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "warning__TyUl",
	"error": "error__ZaAe",
	"success": "success__Zbdc"
};
export default ___CSS_LOADER_EXPORT___;
