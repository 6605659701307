import { Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsRegistrySlice, getClientsRegistry } from '@store/clientsRegistry'
import { clientsRegistrySelector } from '@store/clientsRegistry/selectors'

import { gridConfig } from './gridConfig'

export const ISClientsTable: FC = () => {
    const reduxDispatch = useDispatch()

    const data = useSelector(clientsRegistrySelector.clientsUsersRegistry)
    const { paging, limit } = useSelector(clientsRegistrySelector.selectPaging)
    const isLoading = useSelector(clientsRegistrySelector.selectIsLoading)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(getClientsRegistry({ limit }))
    }

    const onNext = () => {
        reduxDispatch(getClientsRegistry({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(getClientsRegistry({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(getClientsRegistry({ limit }))
        return () => {
            reduxDispatch(clientsRegistrySlice.actions.clear())
        }
    }, [])

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={data}
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            clickableRow
            defaultPerPage={300}
            rowKey="user_uuid"
        />
    )
}
