import { TariffPlaneEnum } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { tariffSelector } from '@store/tariff/selectors'

import api from '@services/api'

import { tariffSlice } from './index'

function* getItems({ payload }: ReturnType<typeof tariffSlice.actions.getItems>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getTariffPlane, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.items) {
            yield* put(tariffSlice.actions.setItems(data))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении списка тарифных планов',
        })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* getCurrentTariff({ payload }: ReturnType<typeof tariffSlice.actions.getCurrentTariff>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.getTariffPlane, { tariff_id: payload })
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.items?.length) {
            const item = data.items[0]
            yield* put(tariffSlice.actions.setCurrentTariff(item))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при получении тарифного плана' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* getBaseTariff() {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))
        const baseTariff = yield* select(tariffSelector.selectBaseTariff)
        let baseTariffId = baseTariff?.id
        if (!baseTariff?.id) {
            const { data, error } = yield* call(api.lib.getTariffPlane, {
                tariff_type: TariffPlaneEnum.BASE,
            })
            if (error || data?.error) {
                throw error ?? data?.error
            }
            baseTariffId = data.items[0].id
        }

        if (baseTariffId) {
            const { data: fullData, error: fullDataError } = yield* call(api.lib.getTariffPlane, {
                tariff_id: baseTariffId,
            })
            if (fullDataError || fullData?.error) {
                throw fullDataError ?? fullData?.error
            }
            yield* put(tariffSlice.actions.setBaseTariff(fullData?.items?.[0]))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении базового тарифного плана',
        })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* getFees() {
    try {
        const fees = yield* select(tariffSelector.selectFees)
        if (!fees?.length) {
            yield* put(tariffSlice.actions.setIsLoading(true))

            const { data, error } = yield* call(api.lib.getAllFees)
            if (error || data?.error) {
                throw error ?? data?.error
            }
            if (data?.items?.length) {
                yield* put(tariffSlice.actions.setFees(data.items))
            }
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении комиссий',
        })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* postTariffPlane({ payload }: ReturnType<typeof tariffSlice.actions.postTariffPlane>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.postTariffPlane, payload.data)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.id) {
            payload.callback(data.id)
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при создании тарифного плана' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* archiveTariffPlane({
    payload,
}: ReturnType<typeof tariffSlice.actions.archiveTariffPlane>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.archiveTariffPlane, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.id) {
            delete data.active_grid
            delete data.grids
            yield* put(tariffSlice.actions.updateTariffPlane(data))
        }
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при архивировании тарифного плана',
        })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* postTariffGrid({ payload }: ReturnType<typeof tariffSlice.actions.postTariffGrid>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))

        const { data, error } = yield* call(api.lib.postTariffGrid, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(tariffSlice.actions.getCurrentTariff(payload.tariff_id))
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при создании тарифной сетки' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* putTariffGrid({ payload }: ReturnType<typeof tariffSlice.actions.putTariffGrid>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))
        const tariff = yield* select(tariffSelector.selectItem)

        const { data, error } = yield* call(api.lib.putTariffGrid, payload.grid_id, payload.data)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(tariffSlice.actions.getCurrentTariff(tariff.id))
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при изменении тарифной сетки' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* deleteTariffGrid({ payload }: ReturnType<typeof tariffSlice.actions.deleteTariffGrid>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))
        const tariff = yield* select(tariffSelector.selectItem)

        const { data, error } = yield* call(api.lib.deleteTariffGrid, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(tariffSlice.actions.getCurrentTariff(tariff.id))
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при удалении тарифной сетки' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* activateTariffGrid({
    payload,
}: ReturnType<typeof tariffSlice.actions.activateTariffGrid>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))
        const tariff = yield* select(tariffSelector.selectItem)

        const { data, error } = yield* call(api.lib.activateTariffGrid, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(tariffSlice.actions.getCurrentTariff(tariff.id))
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при активации тарифной сетки' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

function* deactivateTariffGrid({
    payload,
}: ReturnType<typeof tariffSlice.actions.deactivateTariffGrid>) {
    try {
        yield* put(tariffSlice.actions.setIsLoading(true))
        const tariff = yield* select(tariffSelector.selectItem)

        const { data, error } = yield* call(api.lib.deactivateTariffGrid, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(tariffSlice.actions.getCurrentTariff(tariff.id))
    } catch (error) {
        yield* put(tariffSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при деактивации тарифной сетки' })
    } finally {
        yield* put(tariffSlice.actions.setIsLoading(false))
    }
}

export function* watchTariff(): Generator<StrictEffect> {
    yield* takeLatest(tariffSlice.actions.getItems.type, getItems)
    yield* takeLatest(tariffSlice.actions.getCurrentTariff.type, getCurrentTariff)
    yield* takeLatest(tariffSlice.actions.getBaseTariff.type, getBaseTariff)
    yield* takeLatest(tariffSlice.actions.getFees.type, getFees)
    yield* takeLatest(tariffSlice.actions.postTariffPlane.type, postTariffPlane)
    yield* takeLatest(tariffSlice.actions.archiveTariffPlane.type, archiveTariffPlane)
    yield* takeLatest(tariffSlice.actions.postTariffGrid.type, postTariffGrid)
    yield* takeLatest(tariffSlice.actions.putTariffGrid.type, putTariffGrid)
    yield* takeLatest(tariffSlice.actions.deleteTariffGrid.type, deleteTariffGrid)
    yield* takeLatest(tariffSlice.actions.activateTariffGrid.type, activateTariffGrid)
    yield* takeLatest(tariffSlice.actions.deactivateTariffGrid.type, deactivateTariffGrid)
}
