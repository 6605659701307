import { FC } from 'react'
import { useState } from 'react'

import { FilterOverlay } from './FilterOverlay'
import FilterContextProvider from './FiltersContext'
import FiltersPopup from './FiltersPopup'
import { FiltersReset } from './FiltersReset'
import { FiltersToggle } from './FiltersToggle'
import style from './style.m.less'
import { FiltersProps } from './types'

export const Filters: FC<FiltersProps> = ({ onApplyFilters, filters, isQs = true }) => {
    const [isFiltersPopupShow, setIsFiltersPopupShow] = useState(false)

    return (
        <FilterContextProvider
            filters={filters}
            togglePopupShow={(v) => {
                setIsFiltersPopupShow(v)
            }}
            onApplyFilters={onApplyFilters}
            isQs={isQs}
        >
            <>
                {isFiltersPopupShow && <FilterOverlay />}
                <div className={style.filtersContainer}>
                    <FiltersToggle
                        closed={!isFiltersPopupShow}
                        onClick={() => {
                            setIsFiltersPopupShow(!isFiltersPopupShow)
                        }}
                    />
                    <FiltersReset />
                    {isFiltersPopupShow && <FiltersPopup />}
                </div>
            </>
        </FilterContextProvider>
    )
}
