import { FC } from 'react'

import style from './style.m.less'

export interface IHeaderBadge {
    buying?: boolean
    selling?: boolean
    isEmission?: boolean
}

export const HeaderBadge: FC<IHeaderBadge> = ({ buying, selling, isEmission }) => (
    <>
        {buying && (
            <div className={style.badgeBuying}>
                <p>Заявка на {isEmission ? 'приобретение' : 'покупку'}</p>
            </div>
        )}

        {selling && (
            <div className={style.badgeSelling}>
                <p>Заявка на продажу</p>
            </div>
        )}
    </>
)
