/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    AgentProfileChangeModel,
    AvailableOrderUserBlocking,
    BeneficialOwner,
    BindBankAccountModel,
    BusinessmanProfileChangeModel,
    GetProfilesParams,
    GetTransactionParams,
    IApprovalHistoryItem,
    IAuthData,
    IBlacklist,
    IClientBlockListItem,
    ICursorPaginationResponse,
    IDataForUserBlocking,
    IFullUser,
    IResetEmailInitParams,
    IdentificationType,
    IndividualProfileChangeModel,
    LegalEntityProfileChangeModel,
    MoneyAccountType,
    NodeAndExchangeOperator,
    NodeAndExchangeOperatorUid,
    PatchProfileType,
    ProfileListType,
    ProfileStatus,
    ProfileType,
    QualifiedRegistryChangesType,
    QualifyInfoType,
    ReserveReleasePostData,
    ReserveTransferPostData,
    TablePaginationParam,
    TransactionType,
    UserTerminateInitType,
    ProfileRequestType,
    PrivateInformationLegalEntityResponse,
    PatchPrivateProfileType,
    BindSpecialBankAccountModel,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, getPathByProfileType, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    error: string | null
    currentClient: IFullUser | null
    isCurrentClientActive: boolean
    currentClientQualifyInfo: QualifyInfoType | undefined
    currentClientAdditionalInfo: {
        blacklist?: IBlacklist[]
    }
    currentClientAccount: MoneyAccountType | undefined
    currentClientTransactions: TransactionType[]
    currentClientApprovalHistory: IApprovalHistoryItem[]
    currentClientApprovalHistoryRepresentative: {
        old?: IApprovalHistoryItem[]
        current?: IApprovalHistoryItem[]
    }
    profileBeneficiaries: BeneficialOwner[]
    data: Partial<ICursorPaginationResponse<ProfileListType>>
    params: GetProfilesParams
    blockings: {
        data: IClientBlockListItem[]
        paging?: any
        isLoading: boolean
        limit: number
    }
}
const initialState: IInitialState = {
    isLoading: false,
    error: null,
    currentClient: null,
    isCurrentClientActive: false,
    currentClientQualifyInfo: undefined,
    currentClientAdditionalInfo: {},
    currentClientAccount: undefined,
    currentClientTransactions: [],
    currentClientApprovalHistory: [],
    currentClientApprovalHistoryRepresentative: {},
    profileBeneficiaries: [],
    data: {},
    params: {
        limit: DEFAULT_PER_PAGE,
        order: 'desc(created_at)' as const,
    },
    blockings: {
        data: [],
        limit: DEFAULT_PER_PAGE,
        isLoading: false,
    },
}

namespace ClientsNS {
    export type ChangeStatus = PayloadAction<{
        profileUuid: string
        profileType: ProfileType
        userUuid: string
        comment?: string
        status?: ProfileStatus
    }>
    export type TransactionCreditCurrentClient = PayloadAction<{
        amount: number
        purpose: string
        attachment_uuid: string
    }>
}

export const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload ?? null
        },
        setCurrentClient(state, action: PayloadAction<IFullUser>) {
            state.currentClient = action.payload
        },
        setCurrentClientPrivate(
            state,
            action: PayloadAction<PrivateInformationLegalEntityResponse>,
        ) {
            if (state.currentClient) {
                state.currentClient.private = action.payload
            }
        },
        setApprovalHistory(state, action: PayloadAction<IApprovalHistoryItem[]>) {
            state.currentClientApprovalHistory = action.payload
        },
        setApprovalHistoryRepresentative(state, action: PayloadAction<IApprovalHistoryItem[]>) {
            state.currentClientApprovalHistoryRepresentative.current = action.payload
        },
        setOldApprovalHistoryRepresentative(state, action: PayloadAction<IApprovalHistoryItem[]>) {
            state.currentClientApprovalHistoryRepresentative.old = action.payload
        },
        setIsCurrentClientActive(state, action: PayloadAction<boolean>) {
            state.isCurrentClientActive = action.payload
        },

        setClientList(
            state,
            action: PayloadAction<Partial<ICursorPaginationResponse<ProfileListType>>>,
        ) {
            state.data.items = action.payload?.items ?? []
            state.data.paging = action.payload?.paging
        },

        setClientsBlockingsList(
            state,
            action: PayloadAction<ICursorPaginationResponse<IClientBlockListItem>>,
        ) {
            state.blockings.data = action.payload.items ?? []
            state.blockings.paging = action.payload.paging
        },

        setIsBlockingsListLoading(state, action: PayloadAction<boolean>) {
            state.blockings.isLoading = action.payload
        },

        clearData(state) {
            state.currentClient = null
            state.error = null
            state.data = {}
            state.params = initialState.params
            state.blockings = {
                data: [],
                isLoading: false,
                limit: DEFAULT_PER_PAGE,
            }
        },
        clearCurrentClientData(state) {
            state.currentClient = null
            state.currentClientQualifyInfo = undefined
            state.currentClientAccount = undefined
            state.currentClientTransactions = []
            state.currentClientApprovalHistory = []
            state.currentClientAdditionalInfo = {}
            state.currentClientApprovalHistoryRepresentative = {}
            state.profileBeneficiaries = []
            state.isCurrentClientActive = false
            state.error = null
        },
        getCurrentClient(_, action: PayloadAction<string>) {},
        getClients(state, action: PayloadAction<GetProfilesParams>) {
            if (isEmptyValue(action.payload)) {
                state.params = initialState.params
            } else {
                state.params = action.payload
            }
        },
        getClientsBlockings(
            _,
            action: PayloadAction<TablePaginationParam<AvailableOrderUserBlocking>>,
        ) {},
        changeClientStatus(_, action: ClientsNS.ChangeStatus) {},
        transactionCreditCurrentClient(_, action: ClientsNS.TransactionCreditCurrentClient) {},
        reserveReleaseCurrentClient(
            _,
            action: PayloadAction<Omit<ReserveReleasePostData, 'user_id'>>,
        ) {},
        reserveTransferCurrentClient(
            _,
            action: PayloadAction<Omit<ReserveTransferPostData, 'sender_id'>>,
        ) {},
        getMoneyAccount(_, action: PayloadAction<string>) {},
        setMoneyAccount(state, action: PayloadAction<MoneyAccountType>) {
            state.currentClientAccount = action.payload
        },
        getTransactions(_, action: PayloadAction<GetTransactionParams>) {},
        setTransactions(state, action: PayloadAction<TransactionType[]>) {
            state.currentClientTransactions = action.payload
        },
        getProfilesBeneficiaries() {},
        setProfilesBeneficiaries(state, action: PayloadAction<BeneficialOwner[]>) {
            state.profileBeneficiaries = action.payload
        },
        getQualifyInfo(_, action: PayloadAction<string>) {},
        setQualifyInfo(state, action: PayloadAction<QualifyInfoType>) {
            state.currentClientQualifyInfo = action.payload
        },

        changeLogin(_, action: PayloadAction<string>) {},
        changePassword(_, action: PayloadAction<string>) {},
        changePhone(_, action: PayloadAction<string>) {},
        changeEmail(_, action: PayloadAction<IResetEmailInitParams>) {},
        terminationUser(_, action: PayloadAction<UserTerminateInitType>) {},
        getAuthData(_, action: PayloadAction<string>) {},
        setAuthData(state, action: PayloadAction<IAuthData>) {
            if (state.currentClient) {
                state.currentClient.authorize_data = action.payload
            }
        },
        setExchangeOperator(state, action: PayloadAction<NodeAndExchangeOperator>) {
            const profile =
                state.currentClient?.profile_data?.[
                    getPathByProfileType[state.currentClient.profile_data.type]
                ]
            if (profile) {
                if (state.currentClient?.profile_data.type === ProfileType.BUSN) {
                    profile.node = action.payload
                } else {
                    profile.node_and_exchange_operator = action.payload
                }
            }
        },
        updateBusinessmanProfile(_, action: PayloadAction<BusinessmanProfileChangeModel>) {},
        updateIndividualProfile(_, action: PayloadAction<IndividualProfileChangeModel>) {},
        updateLegalEntityProfile(_, action: PayloadAction<LegalEntityProfileChangeModel>) {},
        updateAgentProfile(_, action: PayloadAction<AgentProfileChangeModel>) {},
        setIdentificationCurrentClient(_, action: PayloadAction<IdentificationType>) {},
        getApprovalHistoryRepresentative() {},
        getOldApprovalHistoryRepresentative() {},
        bindMoneyAccount(_, action: PayloadAction<BindBankAccountModel>) {},
        patchCurrentProfile(_, action: PayloadAction<PatchProfileType>) {},
        putBlacklist(_, action: PayloadAction<IBlacklist[]>) {},
        getCurrentClientAdditionalInfo() {},
        updateCurrentClientAdditionalInfo(
            state,
            action: PayloadAction<Partial<IInitialState['currentClientAdditionalInfo']>>,
        ) {
            state.currentClientAdditionalInfo = {
                ...state.currentClientAdditionalInfo,
                ...action.payload,
            }
        },
    },
})

export const createQualifiedRegistry = createAction<QualifiedRegistryChangesType>(
    'clients/createQualifiedRegistry',
)
export const deleteQualifiedRegistry = createAction<QualifiedRegistryChangesType>(
    'clients/deleteQualifiedRegistry',
)
export const checkClientForActiveSession = createAction<string>(
    'clients/checkClientForActiveSession',
)
export const makeForcedEndOfSessionAction = createAction<string>('clients/makeForcedEndOfSession')

export interface IPayloadForUserBlocking extends IDataForUserBlocking {
    type: 'full' | 'partial'
}

export const makeFullBlockAction = createAction<IDataForUserBlocking>('clients/makeFullBlock')
export const makePartlyBlockAction = createAction<IDataForUserBlocking>('clients/makePartlyBlock')
export const makeFullUnblockAction = createAction<IDataForUserBlocking>('clients/makeFullUnblock')
export const makePartlyUnblockAction = createAction<IDataForUserBlocking>(
    'clients/makePartlyUnblock',
)
export const makeBlockAction = createAction<IPayloadForUserBlocking>('clients/makeBlockAction')
export const makeUnblockAction = createAction<IPayloadForUserBlocking>('clients/makeUnblockAction')

export const getCurrentUserBlockingEvent = createAction<string>(
    'clients/getCurrentUserBlockingEvent',
)

export const updateExchangeOperatorAction = createAction<NodeAndExchangeOperatorUid>(
    'clients/updateExchangeOperator',
)

export interface IUpdateRiskLevelPayload {
    type:
        | ''
        | ProfileRequestType.INDIVIDUAL
        | ProfileRequestType.LEGAL_ENTITY
        | ProfileRequestType.BUSINESSMAN
    user_uuid: string
    data: PatchPrivateProfileType
}

export const updateRiskLevel = createAction<IUpdateRiskLevelPayload>('clients/updateRiskLevel')

export interface IGetPrivateProfilePayload {
    type:
        | ''
        | ProfileRequestType.INDIVIDUAL
        | ProfileRequestType.LEGAL_ENTITY
        | ProfileRequestType.BUSINESSMAN
    user_uuid: string
}

export const getPrivateProfile = createAction<IGetPrivateProfilePayload>(
    'clients/getPrivateProfile',
)

export const bindMoneySpecialAccount = createAction<BindSpecialBankAccountModel>(
    'client/bindMoneySpecialAccount',
)

export default clientsSlice.reducer
