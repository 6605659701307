import { IEmissionOrder, IEmissionOrderDeal, isEmissionOrderDeal } from '@dltru/dfa-models'
import { CardModal, getModalTradeHeaderData } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDfaById } from '@store/dfa/details'
import IAppState from '@store/states'

import { EmissionOrderDetailsModalBody } from './EmissionOrderDetailsModalBody'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    order?: IEmissionOrder | IEmissionOrderDeal
    secondModal?: boolean
}

export const EmissionOrderDetailsModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    order,
    secondModal,
}) => {
    const dfaDetails = useSelector((store: IAppState) => store.dfaDetails)

    const reduxDispatch = useDispatch()
    useEffect(() => {
        if (isModalVisible && order?.asset_id && order.asset_id !== dfaDetails?.id) {
            reduxDispatch(getDfaById(order.asset_id))
        }
    }, [isModalVisible, order?.asset_id])

    return (
        <CardModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            secondModal={secondModal}
            width={540}
            headerData={getModalTradeHeaderData(
                {
                    ...dfaDetails,
                    emitter_full_name: order?.emitter_full_name,
                    emitter_id: order?.emitter_id,
                },
                order && isEmissionOrderDeal(order)
                    ? order.repurchase_percent / 100
                    : order?.repurchase_percent ?? 0,
            )}
            BodyRenderProps={order && <EmissionOrderDetailsModalBody orderDetails={order} />}
        />
    )
}
