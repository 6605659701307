import { DfaStatusEnum, preStatuses, SettlementsType } from '@dltru/dfa-models'
import { Box, PageHeader, Tabs, Tag } from '@dltru/dfa-ui'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { dropDfaDetails, getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import IAppState from '@store/states'

import { AboutDfa } from '@pages/DFAEmissionCardPage/components/AboutDfa'

import { getTagProps } from '../../resources/dfaState'
import { DealsTable } from './components/DealsTable'
import { Footer } from './components/Footer'
import { IndividualOffersTable } from './components/IndividualOffersTable'
import MyOrdersTable from './components/MyOrdersTable'
import OrdersDfaTable from './components/OrdersDfaTable'
import { OwnersTable } from './components/OwnersTable'
import './style.less'
import { getPage } from '@store/dfa/owners'

const { TabPane } = Tabs

const EmissionCardPage: React.FC = () => {
    const reduxDispatch = useDispatch()
    const { dfa: urlDfaParam } = useParams()
    const dfaDetails = useSelector((store: IAppState) => store.dfaDetails)

    const isShowApplication = useSelector(dfaDetailsSelector.selectIsShowApplication)
    const isShowDeals = useSelector(dfaDetailsSelector.selectIsShowDeals)
    const isShowTransfer = useSelector(dfaDetailsSelector.selectIsShowTransfer)
    const isShowOwners = useSelector(dfaDetailsSelector.selectIsShowOwners)
    const [tabActiveKey, setTabActiveKey] = useState('1')

    const withPayoffAgreements = dfaDetails.repayment_settlements_type === SettlementsType.direct

    const claimCount = 0

    const tagProps = getTagProps(dfaDetails.status)

    useLayoutEffect(() => {
        reduxDispatch(dropDfaDetails())
    }, [urlDfaParam])

    useEffect(() => {
        if (dfaDetails.id) {
            reduxDispatch(getPage({ dfaId: '' + dfaDetails.id, withPayoffAgreements }))
        }
    }, [dfaDetails.id])

    useEffect(() => {
        if (urlDfaParam) {
            reduxDispatch(getDfaById(Number(urlDfaParam)))
        }
    }, [urlDfaParam])

    useEffect(() => {
        if (dfaDetails.status && tabActiveKey !== '1') {
            setTabActiveKey('1')
        }
    }, [dfaDetails.status])

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={dfaDetails.ticker_symbol}
                    subTitle={dfaDetails.title}
                    onBack={() => window.history.back()}
                    tags={tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined}
                />
            </Box>
            {preStatuses.includes(dfaDetails.status) ? (
                <AboutDfa />
            ) : (
                <Tabs onChange={setTabActiveKey} activeKey={tabActiveKey}>
                    <TabPane tab="Условия выпуска" key={1}>
                        <AboutDfa />
                    </TabPane>
                    {isShowApplication && (
                        <TabPane tab={`Заявки ${claimCount || ''}`} key={2}>
                            {tabActiveKey === '2' && (
                                <Box padding={[0, 24, 24, 24]} className="tablebox">
                                    <MyOrdersTable />
                                </Box>
                            )}
                        </TabPane>
                    )}
                    {dfaDetails.status === DfaStatusEnum.emitment_success && (
                        <TabPane tab="Вторичное обращение" key={5}>
                            {tabActiveKey === '5' && (
                                <Box padding={[0, 24, 24, 24]} className="tablebox">
                                    <OrdersDfaTable dfa={`${dfaDetails.id ?? ''}`} />
                                </Box>
                            )}
                        </TabPane>
                    )}
                    {isShowTransfer && (
                        <TabPane tab="Оферты" key={6}>
                            {tabActiveKey === '6' && (
                                <Box padding={[0, 24, 24, 24]} className="tablebox">
                                    <IndividualOffersTable
                                        params={{ asset_id: `${dfaDetails.id ?? ''}` }}
                                    />
                                </Box>
                            )}
                        </TabPane>
                    )}
                    {isShowDeals && (
                        <TabPane tab="Сделки" key={3}>
                            {tabActiveKey === '3' && (
                                <Box padding={[0, 24, 24, 24]} className="tablebox">
                                    <DealsTable />
                                </Box>
                            )}
                        </TabPane>
                    )}
                    {isShowOwners && (
                        <TabPane tab="Владельцы" key={4}>
                            {tabActiveKey === '4' && (
                                <Box padding={[0, 24, 24, 24]} className="tablebox">
                                    <OwnersTable dfa={dfaDetails} />
                                </Box>
                            )}
                        </TabPane>
                    )}
                </Tabs>
            )}
            <Footer />
        </>
    )
}

export default EmissionCardPage
