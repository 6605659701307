import { IPagingResponse, IUsersRegistry, TablePaginationParam } from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

const DEFAULT_PER_PAGE = 300

interface IInitialState {
    isLoading: boolean
    error: string | null
    items: IUsersRegistry[]
    paging?: IPagingResponse
    limit: number
}
const initialState: IInitialState = {
    isLoading: false,
    error: null,
    items: [],
    limit: DEFAULT_PER_PAGE,
}

export const clientsRegistrySlice = createSlice({
    name: 'clientsRegistry',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload ?? null
        },
        setData(
            state,
            action: PayloadAction<{ items: IUsersRegistry[]; paging?: IPagingResponse }>,
        ) {
            state.items = action.payload.items
            state.paging = action.payload.paging
        },
        clear(state) {
            state.isLoading = false
            state.error = null
            state.items = []
            state.limit = DEFAULT_PER_PAGE
        },
    },
})

export const getClientsRegistry = createAction<TablePaginationParam>(
    'clientsRegistry/getClientsRegistry',
)

export default clientsRegistrySlice.reducer
