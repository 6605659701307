import classNames from 'classnames/bind'
import React from 'react'

import style from './style.m.less'

const cn = classNames.bind(style)
interface INotePanel {
    title: string
    type: string
}

export const NotePanel: React.FC<INotePanel> = ({ title, type, children }) => {
    return (
        <div className={cn(style.noteWrapper, type)}>
            {title && <h3 className={style.noteTitle}>{title}</h3>}
            {children}
        </div>
    )
}
