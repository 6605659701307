/* eslint-disable unused-imports/no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    IInvestorRegistryApplicationPut,
    InvestorRegistryApplicationDocumentItem,
} from '@dltru/dfa-models'

import { IQualForm } from './type'

export const qualFormToPutRequest = (
    data: Partial<IQualForm>,
): IInvestorRegistryApplicationPut | null => {
    if (!data.application_uuid || !data.operator_uuid || !data.operator_role || !data.status) {
        return null
    }

    const docs: InvestorRegistryApplicationDocumentItem[] =
        data.documents?.map((item) => ({
            link_uuid: item.uid || '',
            name: item.originFileObj?.name || '',
        })) || []

    const request: IInvestorRegistryApplicationPut = {
        application_uuid: data.application_uuid,
        operator_uuid: data.operator_uuid,
        operator_role: data.operator_role,
        status: data.status,
        comment: data?.comment || '',
        documents: docs,
    }
    return request
}
