export const frontUrl = {
    restorePassword: 'restore-password',
    restorePasswordAdmin: 'restore-password-admin',
    changeLogin: 'change-login',
    changePhone: 'change-phone',
    changeEmail: 'change-email',

    questionnaireRepresentative: 'client-representative-form',
    questionnaireIndividual: 'individual-form',
    questionnaireLegalEntity: 'legal-entity-form',
    questionnaireBusinessman: 'businessman-form',
}
