import { IdentificationType, ProfileStatus } from '@dltru/dfa-models'
import {
    Button,
    Divider,
    Form,
    Modal,
    Typography,
    UploadFile,
    WrappedDateItem,
    WrappedInput,
    WrappedSelect,
} from '@dltru/dfa-ui'
import { Moment } from 'moment'
import { FC, useState } from 'react'

import { UploadDocumentContainerWrapper } from '@components/Form'

import style from './style.m.less'

const identificationStatusOptions = [
    { value: ProfileStatus.IDENTIFIED, label: 'Пройдена' },
    { value: ProfileStatus.REJECTED, label: 'Не пройдена', disabled: true },
]
interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    action: (data: IdentificationType) => void
}
type FormValues = {
    status: ProfileStatus
    user_identification_date: Moment
    user_identification_comment: string
    user_identification_file_uuids: UploadFile[]
}
export const IdentificationStatusModal: FC<IComponentProps> = ({ visible, setVisible, action }) => {
    const [isDisabled, setIsDisabled] = useState(true)
    const [form] = Form.useForm<FormValues>()

    const onCancel = () => {
        setVisible(false)
        form.resetFields()
        setIsDisabled(true)
    }

    const onOk = (values: FormValues) => {
        action({
            user_identification_date: values.user_identification_date.unix(),
            user_identification_comment: values.user_identification_comment,
            user_identification_file_uuids: values.user_identification_file_uuids?.map(
                (file) => file.uid,
            ),
        })
        onCancel()
    }

    const onValuesChange = (_: Partial<FormValues>, allValues: FormValues) => {
        setIsDisabled(
            !allValues.status ||
                !allValues.user_identification_comment ||
                !allValues.user_identification_date,
        )
    }

    return (
        <Modal
            title="Установить статус прохождения идентификации"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button
                    type="primary"
                    borderRadius={12}
                    disabled={isDisabled}
                    onClick={form.submit}
                >
                    Присвоить статус
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onOk} onValuesChange={onValuesChange}>
                <Typography.Text>Прохождение идентификации по 115 ФЗ</Typography.Text>
                <WrappedSelect
                    name="status"
                    options={identificationStatusOptions}
                    size="middle"
                    required
                />
                <Typography.Text>Дата прохождения идентификации</Typography.Text>
                <WrappedDateItem name="user_identification_date" required onlyFutureAndToday />
                <Divider />
                <h4 className={style.headerBold}>Основание присвоения статуса</h4>

                <UploadDocumentContainerWrapper
                    name="user_identification_file_uuids"
                    title="Загрузите фотографии или сканы документов"
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png"
                >
                    <WrappedInput
                        name="user_identification_comment"
                        multiline
                        placeholder="Ваш комментарий"
                        rows={5}
                        required
                    />
                </UploadDocumentContainerWrapper>
            </Form>
        </Modal>
    )
}
