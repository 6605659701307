import { ProfileStatus } from '@dltru/dfa-models'
import { Space, Tag } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import styles from './clientTags.m.less'

export const ClientTags: FC = () => {
    const nodeAndOperatorData = useSelector(clientsSelector.selectIsNodeAndExchangeOperator)
    const walletDfaData = useSelector(clientsSelector.selectWalletDfaData)
    const isUserDelete = useSelector(clientsSelector.selectIsCurrentClientFullyBlocked)
    const statusProfile = useSelector(clientsSelector.selectCurrentStatus)
    const isParticipant =
        statusProfile === ProfileStatus.APPROVED &&
        !isUserDelete &&
        walletDfaData?.is_owner === false &&
        walletDfaData?.is_emitter === false &&
        !nodeAndOperatorData?.is_node &&
        !nodeAndOperatorData?.is_exchange_operator

    return (
        <Space size={6} className={styles.clientTagsWrapper}>
            {/*isUserDelete && <Tag color="red">Удален</Tag>*/}
            {isParticipant && <Tag>Участник</Tag>}
            {walletDfaData?.is_owner && <Tag>Владелец</Tag>}
            {walletDfaData?.is_emitter && <Tag>Эмитент</Tag>}
        </Space>
    )
}
