export const DetailByType = {
    debit: 'negative',
    credit: 'positive',
}

export const getDetailByType = (type?: string): string => {
    if (!type) {
        return 'negative'
    }

    const _type = type as keyof typeof DetailByType
    return DetailByType[_type]
}
