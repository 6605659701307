// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lockClient_fkl4x {\n  color: #303440;\n}\n.lockClientError_fEpVG {\n  color: #f5222d;\n}\n.lockClient_fkl4x .anticon {\n  margin-right: 8px;\n}\n.lockClient_fkl4x .anticon svg {\n  width: 16px;\n  height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientList/components/BlocksTable/style.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;AADJ;AAEI;EACI,cAAA;AAAR;AAHA;EAOY,iBAAA;AADZ;AANA;EASgB,WAAA;EACA,YAAA;AAAhB","sourcesContent":["@import 'style/palette';\n\n.lockClient {\n    color: @gray-10;\n    &Error {\n        color: @red-6;\n    }\n    :global {\n        .anticon {\n            margin-right: 8px;\n            svg {\n                width: 16px;\n                height: 16px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockClient": "lockClient_fkl4x",
	"lockClientError": "lockClientError_fEpVG"
};
export default ___CSS_LOADER_EXPORT___;
