import { Box, PageHeader, Tabs } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { paymentOrdersSlice } from '@store/paymentOrders'

import { UseTabsAndHash } from '../../../hooks/useTabsAndHash'
import { ClientOrdersTable } from './components/ClientOrdersTable'
import { PaymentOrdersTable } from './components/PaymentOrdersTable'

const { TabPane } = Tabs
enum TabName {
    client = 'client',
    payment = 'payment',
}
const tabNames = Object.values(TabName)
const defaultTab = TabName.client

export const PaymentOrderList: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(tabNames, defaultTab)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        return () => {
            reduxDispatch(paymentOrdersSlice.actions.clearData())
        }
    }, [])

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Поручения пользователей" />
            </Box>
            <Tabs activeKey={activeTab} onChange={onChangeTab}>
                <TabPane tab="Поручения пользователей" key={TabName.client}>
                    <Box padding={[0, 24, 24, 24]} className="tablebox">
                        <ClientOrdersTable />
                    </Box>
                </TabPane>
                <TabPane tab="Платежные поручения" key={TabName.payment}>
                    <Box padding={24} className="tablebox">
                        <PaymentOrdersTable />
                    </Box>
                </TabPane>
            </Tabs>
        </>
    )
}
