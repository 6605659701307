import { RbacContext } from '@dltru/dfa-rbac'
import {
    Box,
    Card,
    CardContent,
    CardField,
    CardHeader,
    CopyButton,
    Divider,
    PageHeader,
    StepperC,
    VIEW_DATE_FORMAT,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { clientsSelector } from '@store/clients/selectors'
import {
    getCurrentStatementQualify,
    getStatementQualifyEvents,
    qualifyStatementSlice,
} from '@store/qualifyStatement'
import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'

import { InformationAboutUser } from '@pages/QualificationStatementsList/components/InformationAboutUser'
import { Status } from '@pages/QualificationStatementsList/components/Status'

import { RejectAlert } from './components/Alerts'
import { EventsTable } from './components/EventsTable/EventsTable'
import { Footer } from './components/Footer'
import { QualificationStatementDocs } from './components/QualificationStatementDocs/QualificationStatementDocs'
import { IQualForm } from './type'

export const QualificationStatement: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()
    const { id } = useParams()
    const profileAgent = useSelector(clientsSelector.selectProfileAgent)
    const profile = useSelector(clientsSelector.selectProfile)
    const type = useSelector(clientsSelector.selectCurrentClientTypes)

    const profileFullName = useSelector(clientsSelector.userFullName)
    const profileagentFullName = useSelector(clientsSelector.agentFullName)
    const userFullName = useSelector(clientsSelector.userFullName)

    const qualifyStatement = useSelector(qualifyStatementSelector.selectCurrentQualifyStatement)
    const isLoaded = useSelector(qualifyStatementSelector.selectIsLoaded)
    const userID = useSelector(authSelector.selectUserUid)
    //const [isStatementComplete, setStatementComplete] = useState(false)
    const [formdata, setFormdata] = useState<IQualForm>()

    useEffect(() => {
        reduxDispatch(getCurrentStatementQualify(`?uuid=${id}`))

        return () => {
            reduxDispatch(qualifyStatementSlice.actions.clearCurrentDataAndEvents())
        }
    }, [])

    useEffect(() => {
        if (isLoaded) {
            reduxDispatch(getCurrentStatementQualify(`?uuid=${id}`))
            reduxDispatch(getStatementQualifyEvents(`?application_uuid=${id}`))
        }
    }, [isLoaded])

    useEffect(() => {
        if (qualifyStatement) {
            const formValue = {
                ...qualifyStatement,
                operator_uuid: userID || '',
                operator_role: 'operator',
                application_uuid: id || '',
                documents: qualifyStatement?.documents?.map((doc) => ({ uid: doc.link_uuid })),
            }
            setFormdata(formValue)
        }
    }, [qualifyStatement])

    let isShowFooter = false
    if (qualifyStatement?.status === 'lawyer_approval' && isAllow(['approval.lawyer'])) {
        isShowFooter = true
    }
    if (qualifyStatement?.status === 'operator_approval' && isAllow(['approval.aml'])) {
        isShowFooter = true
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Заявление о признании квалифицированным инвестором"
                    subTitle={
                        <>
                            ID&ensp; {qualifyStatement?.uuid || ''}
                            <CopyButton text={qualifyStatement?.uuid || ''} />
                        </>
                    }
                    onBack={() => window.history.back()}
                    tags={qualifyStatement ? <Status status={qualifyStatement?.status} /> : <></>}
                />
            </Box>

            <div style={{ marginBottom: 8 }}>
                {/*<NoteAlert data={qualifyStatement} />*/}
                {qualifyStatement?.status === 'declined' && <RejectAlert data={qualifyStatement} />}
            </div>

            <Card className="boxContent">
                <CardHeader title="Основная информация" />
                <CardContent>
                    {qualifyStatement?.created_at && (
                        <CardField
                            title="Дата подачи заявки"
                            value={dayjs
                                .unix(qualifyStatement?.created_at)
                                .format(VIEW_DATE_FORMAT)}
                            padding={16}
                        />
                    )}
                    {profile?.user_uuid && (
                        <CardField
                            title="Заявитель"
                            value={
                                <>
                                    <span>{userFullName}</span>&ensp; ID&ensp; {profile?.user_uuid}
                                    {profile?.user_uuid && <CopyButton text={profile.user_uuid} />}
                                </>
                            }
                            padding={16}
                        />
                    )}
                    <Divider />
                    <InformationAboutUser
                        profileAgent={profileAgent}
                        profile={profile}
                        profileFullName={profileFullName}
                        profileagentFullName={profileagentFullName}
                        profileType={type ? type[0] : undefined}
                    />
                </CardContent>
            </Card>

            {formdata && (
                <StepperC
                    initialValues={formdata}
                    initialStep={1}
                    onStepperFinish={(value) => {
                        const formValue = {
                            ...(value as IQualForm),
                            operator_uuid: userID || '',
                            operator_role: 'operator',
                            application_uuid: id || '',
                        }
                        setFormdata(formValue)
                        //setStatementComplete(true)
                    }}
                >
                    <QualificationStatementDocs stepIndex={0} isModification={true} />
                </StepperC>
            )}

            <Card className="boxContent">
                <CardHeader title="История согласования" />
                <CardContent>
                    <EventsTable applicationUuid={id} />
                </CardContent>
            </Card>

            {isShowFooter && <Footer data={formdata} />}
        </>
    )
}
