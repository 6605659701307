import { PublicStatusType } from '@dltru/dfa-models'
import { FC, useContext, useState } from 'react'

import { Step, StepperContext, useEditable } from '../../../Stepper'
import { Form, WrappedInput, WrappedSelect } from '../../../components/Form'
import { commonValidators } from '../../../utils'
import { AddressDetails } from '../addressComponent'
import { publicStatusOptions } from '../resources'

interface IComponentProps {
    stepIndex: number
    isAdmin?: boolean
    isEditAllow?: boolean
}
export const PublicPersonStep: FC<IComponentProps> = ({ stepIndex, isEditAllow }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const [status, setStatus] = useState(values?.status ?? PublicStatusType.notPublic)
    const [country, setCountry] = useState('ru')
    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    const setAddressValue = (value: string) => {
        form.setFieldsValue({
            public_registration: value,
        })
    }

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle="Сведения о публичном должностном лице"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <WrappedSelect
                    onChange={(val) => {
                        setStatus(val as PublicStatusType)
                    }}
                    defaultActiveFirstOption
                    uneditable={isEdit}
                    required
                    label="Статус"
                    className="formComponentItem--xl"
                    name="status"
                    options={publicStatusOptions}
                />
            </div>
            {(status === PublicStatusType.public || status === PublicStatusType.related) && (
                <>
                    {status === PublicStatusType.related && (
                        <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                            <WrappedInput
                                className="formComponentItem--xl"
                                label="Степень родства"
                                name="related_position"
                                placeholder="Например, дочь"
                                uneditable={isEdit}
                                required
                                rules={[{ validator: commonValidators.stringWithSpaces() }]}
                            />
                        </div>
                    )}
                    <div className="formRow-vertical ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedSelect
                            onChange={(val) => {
                                setCountry(val as string)
                            }}
                            initialValue={country}
                            uneditable={isEdit}
                            required
                            disabled
                            label="Государство, резидентом которого является публичное должностное лицо"
                            className="formComponentItem"
                            name="public_country"
                            options={[
                                {
                                    value: 'ru',
                                    label: 'Россия',
                                },
                            ]}
                        />
                    </div>
                    <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedInput
                            className="formComponentItem--xl"
                            label="Наименование организации"
                            name="public_organisation"
                            placeholder=""
                            uneditable={isEdit}
                            maxLength={200}
                            required
                        />
                    </div>
                    <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedInput
                            label="Юридический адрес организации"
                            className="formComponentItem--xl"
                            name="public_registration"
                            placeholder="Введите юридический адрес организации"
                            uneditable={isEdit}
                            required
                            disabled
                        />
                    </div>
                    <AddressDetails
                        uneditable={isEdit}
                        prefix="public"
                        name="public_registration"
                        marginLeft={250}
                        setAddressValue={setAddressValue}
                    />
                    <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedInput
                            className="formComponentItem--xl"
                            label="Должность публичного должностного лица"
                            name="public_position"
                            placeholder=""
                            uneditable={isEdit}
                            required
                            rules={[{ validator: commonValidators.stringWithSpacesAndDash() }]}
                        />
                    </div>
                </>
            )}
        </Step>
    )
}
