// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tradeButton_m5LQO.green_KmX_d {\n  background: #52c41a;\n  color: #ffffff;\n}\n.tradeButton_m5LQO.green_KmX_d:hover,\n.tradeButton_m5LQO.green_KmX_d:focus {\n  background: #73d13d;\n  color: #ffffff;\n  border: 1px solid #73d13d;\n}\n.tradeButton_m5LQO.red_XlR8C {\n  background: #fa541c;\n  color: #ffffff;\n}\n.tradeButton_m5LQO.red_XlR8C:hover,\n.tradeButton_m5LQO.red_XlR8C:focus {\n  background: #ff7a45;\n  color: #ffffff;\n  border: 1px solid #ff7a45;\n}\n.tradeButton_m5LQO:disabled {\n  background: #f1f2f5;\n  color: #b9bac1;\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/TradeButton/style.m.less"],"names":[],"mappings":"AAGE;EACE,mBAAA;EACA,cAAA;AAFJ;AAII;;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;AADN;AAKE;EACE,mBAAA;EACA,cAAA;AAHJ;AAKI;;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;AAFN;AAME;EACE,mBAAA;EACA,cAAA;EACA,oBAAA;AAJJ","sourcesContent":["@import '/src/assets/style/palette';\n\n.tradeButton {\n  &.green {\n    background: @green-6;\n    color: @gray-1;\n\n    &:hover, &:focus {\n      background: @green-5;\n      color: @gray-1;\n      border: 1px solid @green-5;\n    }\n  }\n\n  &.red {\n    background: @volcano-6;\n    color: @gray-1;\n\n    &:hover, &:focus {\n      background: @volcano-5;\n      color: @gray-1;\n      border: 1px solid @volcano-5;\n    }\n  }\n\n  &:disabled {\n    background: @gray-3;\n    color: @gray-6;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tradeButton": "tradeButton_m5LQO",
	"green": "green_KmX_d",
	"red": "red_XlR8C"
};
export default ___CSS_LOADER_EXPORT___;
