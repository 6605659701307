import { IClientBlockListItem } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, EMPTY_FIELD_VALUE, Lock16, UnLockIcon } from '@dltru/dfa-ui'
import cn from 'classnames'

import { eventTypeMapper } from '../../utils'
import styles from './style.m.less'

const ClientCell = (value: string, record: IClientBlockListItem) => (
    <span
        className={cn(styles.lockClient, {
            [styles.lockClientError]:
                record.event_type === 'full_block' || record.event_type === 'partly_block',
        })}
    >
        {record.event_type === 'full_block' || record.event_type === 'partly_block' ? (
            <Lock16 />
        ) : (
            <UnLockIcon />
        )}
        {value}
    </span>
)

export const gridConfig: ColumnsType<IClientBlockListItem> = [
    {
        title: 'Пользователь',
        dataIndex: 'user_full_name',
        sorter: true,
        ellipsis: true,
        render: ClientCell,
    },
    {
        title: 'Действие',
        dataIndex: 'event_type',
        sorter: true,
        ellipsis: true,
        render: eventTypeMapper,
    },
    {
        title: 'ФИО Специалиста',
        dataIndex: 'employee_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => value || record.operator_uuid || EMPTY_FIELD_VALUE,
    },
    {
        title: 'Дата и время блокирования/снятия блокирования',
        dataIndex: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
]
