export interface IBaseInitialState {
    error: string | null
    isLoading: boolean
}

export interface IBaseInitialStateList extends IBaseInitialState {
    filters?: Record<string, unknown>
    sort?: Record<string, unknown>
    range?: { offset?: number; limit?: number }
} // TODO типизировать

export enum OrderDirect {
    asc = 'asc',
    desc = 'desc',
}

export type TableOrder = {
    direct: OrderDirect
    column: string
}

export type CursorPaginationParam = {
    userId?: string
    cursor?: string
    order?: TableOrder
    limit?: number | string
    process_name?: string
}
