import { FC } from 'react'

import { Box } from '../../Box'
import { Space } from '../../Space'
import { RedTimer } from '../../Timer'
import { AuthForm } from './AuthForm'

export interface IFraudViewProps {
    fraudRemainingTimeInSec: number
    onFinish: () => void
}
export const FraudView: FC<IFraudViewProps> = ({ fraudRemainingTimeInSec, onFinish }) => {
    return (
        <>
            <AuthForm title="Доступ временно заблокирован" style={{ maxWidth: '440px' }}>
                <Box width="360px">
                    <Space direction="vertical">
                        <span>Вы 3 раза неверно ввели логин или пароль.</span>
                        <span>Подождите 30 минут и войдите еще раз.</span>
                    </Space>
                </Box>
                <Box padding={[24, 0, 8, 0]} direction="column">
                    <RedTimer secondsRemaining={fraudRemainingTimeInSec} onFinish={onFinish} />
                </Box>
            </AuthForm>
        </>
    )
}
