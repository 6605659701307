import { RateBaseType } from '@dltru/dfa-models'
import { CurrencyInput } from '@dltru/dfa-ui'
import FieldContext from 'rc-field-form/lib/FieldContext'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'

import './style.less'

interface IComponentProps {
    editable: boolean
    children: React.ReactNode
    dataIndex: keyof RateBaseType
    record: RateBaseType
    handleSave?: (record: RateBaseType) => void
}

export const EditableCell: FC<IComponentProps> = ({
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const { validateFields, getFieldValue } = useContext(FieldContext)
    const fieldName = `${record?.fee_code}${dataIndex}`

    useEffect(() => {
        if (editing) {
            inputRef?.current?.focus()
        }
    }, [editing])

    const toggleEdit = async () => {
        await validateFields([fieldName])
        setEditing((prevState) => !prevState)
    }

    const save = useCallback(async () => {
        await validateFields([fieldName])
        const value = getFieldValue(fieldName)
        setEditing(false)
        handleSave?.(value)
    }, [])

    const childNode = editable ? (
        <CurrencyInput
            className={editing ? 'no-label-padding no-error-field' : 'editable-cell-value-wrap'}
            name={fieldName}
            placeholder="0"
            onPressEnter={save}
            onBlur={save}
            ref={inputRef}
            required
            size="middle"
            onClick={toggleEdit}
            uneditable={!editing}
            decimalScale={dataIndex === 'minimum' ? 2 : 3}
        />
    ) : (
        children
    )

    return <td {...restProps}>{childNode}</td>
}
