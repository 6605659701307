import { commonValidators } from '../../../utils'
import { WrappedInputProps, WrappedInputWithDisable } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & {
    name?: WrappedInputProps['name']
}

export const SecondNameInput = ({
    className,
    label,
    name,
    placeholder,
    ...rest
}: ComponentProps) => {
    return (
        <WrappedInputWithDisable
            {...rest}
            className={className ?? 'formComponentItem'}
            label={label ?? 'Отчество'}
            name={name ?? 'second_name'}
            placeholder={placeholder ?? ''}
            checkboxLabel="Нет отчества"
            rules={[{ validator: commonValidators.name() }]}
        />
    )
}
