import { ClientTypeTranslate, IProfileApplication } from '@dltru/dfa-models'
import { Box, Button, InfoCircleOutlined, MiniModal, Space } from '@dltru/dfa-ui'
import React, { FC, Fragment, useEffect, useState } from 'react'

import api from '@services/api'

import styles from './style.m.less'

interface IComponentProps {
    investorUuids: string[]
}
export const InvestorList: FC<IComponentProps> = ({ investorUuids }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [investors, setInvestors] = useState([] as IProfileApplication[])

    const onCloseHandler = () => {
        setIsModalVisible(false)
    }
    const getInvestors = async () => {
        const investors = await api.lib.getProfileShortInfo({ user_uuid: investorUuids })
        setInvestors(investors?.data?.item?.items ?? [])
    }

    useEffect(() => {
        if (isModalVisible && investorUuids.length && !investors.length) {
            getInvestors()
        } else if (!investorUuids.length) {
            setInvestors([])
        }
    }, [investorUuids, isModalVisible])

    return (
        <>
            <Button
                type="linkText"
                onClick={() => setIsModalVisible(true)}
                width="122px"
                size="large"
            >
                Посмотреть список
            </Button>

            <MiniModal
                visible={isModalVisible}
                onCancel={onCloseHandler}
                width={600}
                title={
                    <Space size={0}>
                        <InfoCircleOutlined className="modal-icon modal-question-icon" />
                        Круг лиц, участвующих в сделках размещения
                    </Space>
                }
                footer={[
                    <Button borderRadius={12} onClick={onCloseHandler}>
                        Закрыть
                    </Button>,
                ]}
            >
                <Box padding={[16, 0, 16, 38]} className="modal-content-text">
                    <table className={styles.table}>
                        <tbody>
                            {investors.map((item, index) => (
                                <Fragment key={`${item.inn}${index}`}>
                                    <tr>
                                        <td rowSpan={2} className={styles.index}>
                                            {index + 1}
                                        </td>
                                        <td className={styles.inn}>{item.inn}</td>
                                        <td className={styles.type}>
                                            {ClientTypeTranslate[item.profile_type]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className={styles.name}>
                                            {item.full_name}
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </MiniModal>
        </>
    )
}
