import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CaseDel } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { CancellationIssueModal } from '../Modals'

export const CancellationIssueAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { isAllow } = useContext(RbacContext)
    const showButton =
        isAllow(['asset:status']) && dfaDetails.status === DfaStatusEnum.issue_stopped
    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<CaseDel width="20" height="20" />}
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
            >
                Аннулировать выпуск
            </Button>
            <CancellationIssueModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
