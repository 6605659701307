import { SignUtils } from './SignUtils'

export const signTransaction = async (certificate, transaction, putTransactionApi) => {
    if (certificate && transaction) {
        const [sign, public_key] = await SignUtils.gostAftSign(certificate.sha1, transaction.hash)
        const { data, error } = await putTransactionApi({
            ...transaction,
            sign,
            public_key,
        })

        if (error || data?.error) {
            throw error || data?.error?.code
        }
    }
}

export const signTransactionWithData = async (certificate, payload, putTransactionApi) => {
    if (certificate && payload.transaction) {
        const [sign, public_key] = await SignUtils.gostAftSign(
            certificate.sha1,
            payload.transaction.hash,
        )
        const { data, error } = await putTransactionApi({
            ...payload,
            transaction: {
                ...payload.transaction,
                sign,
                public_key,
            },
        })

        if (error || data?.error) {
            throw error || data?.error?.code
        }
    }
}
