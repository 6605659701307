import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Share: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.1333 5.14166C15.8 6.3 16.95 8.14166 17.1833 10.2667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.90833 10.3083C3.125 8.19165 4.25833 6.34999 5.90833 5.18332"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.825 17.45C7.79167 17.9417 8.89167 18.2167 10.05 18.2167C11.1667 18.2167 12.2167 17.9667 13.1583 17.5083"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.05 6.41666C11.3295 6.41666 12.3667 5.37945 12.3667 4.09999C12.3667 2.82053 11.3295 1.78333 10.05 1.78333C8.77054 1.78333 7.73333 2.82053 7.73333 4.09999C7.73333 5.37945 8.77054 6.41666 10.05 6.41666Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.025 16.6C5.30446 16.6 6.34167 15.5628 6.34167 14.2833C6.34167 13.0039 5.30446 11.9667 4.025 11.9667C2.74554 11.9667 1.70833 13.0039 1.70833 14.2833C1.70833 15.5628 2.74554 16.6 4.025 16.6Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.975 16.6C17.2545 16.6 18.2917 15.5628 18.2917 14.2833C18.2917 13.0039 17.2545 11.9667 15.975 11.9667C14.6955 11.9667 13.6583 13.0039 13.6583 14.2833C13.6583 15.5628 14.6955 16.6 15.975 16.6Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
