import { FC, useContext } from 'react'

import { FiltersContext } from './FiltersContext'
import style from './style.m.less'

export const FilterOverlay: FC = () => {
    const { onReset } = useContext(FiltersContext)
    return (
        <div
            aria-hidden="true"
            className={style.filterOverlay}
            onClick={() => {
                onReset()
            }}
        />
    )
}
