import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

interface IconCustomizableProps extends IconProps {
    width?: number
    height?: number
}

export const LockIcon: FC<IconCustomizableProps> = ({ width = 20, height = 20, ...rest }) => (
    <AntIcon
        component={() => (
            <svg
                width={`${width}`}
                height={`${height}`}
                viewBox={`${-width * 0.12} ${-height * 0.14} 25 25`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5 8.33335V6.66669C5 3.90835 5.83333 1.66669 10 1.66669C14.1667 1.66669 15 3.90835 15 6.66669V8.33335"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.99996 15.4167C11.1506 15.4167 12.0833 14.4839 12.0833 13.3333C12.0833 12.1827 11.1506 11.25 9.99996 11.25C8.84937 11.25 7.91663 12.1827 7.91663 13.3333C7.91663 14.4839 8.84937 15.4167 9.99996 15.4167Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1666 18.3333H5.83329C2.49996 18.3333 1.66663 17.5 1.66663 14.1666V12.5C1.66663 9.16665 2.49996 8.33331 5.83329 8.33331H14.1666C17.5 8.33331 18.3333 9.16665 18.3333 12.5V14.1666C18.3333 17.5 17.5 18.3333 14.1666 18.3333Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...rest}
    />
)
