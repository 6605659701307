import { FC, useContext } from 'react'

import { Form } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import { filtersFab } from './helper'

const FiltersForm: FC = () => {
    const { onApplyFilters, filters, initialValues, form } = useContext(FiltersContext)

    if (!form) {
        return null
    }

    const fields = filtersFab(filters)
    return (
        <Form
            form={form}
            initialValues={initialValues}
            size="large"
            onFinish={() => onApplyFilters()}
        >
            {fields}
        </Form>
    )
}

export default FiltersForm
