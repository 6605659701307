import {
    Button,
    CardDivider,
    Divider,
    Form,
    IRoleFormStepProps,
    Space,
    Step,
    StepperContext,
    WrappedDateItem,
    WrappedInput,
    dateValidators,
    useEditable,
} from '@dltru/dfa-ui'
import { Moment } from 'moment'
import { FC, Fragment, useContext, useState } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

interface IComponentProps extends IRoleFormStepProps {
    stepIndex: number
    stepTitle: string
    maxCount?: number
}

export const LicensesStep: FC<IComponentProps> = ({
    stepIndex,
    stepTitle,
    maxCount = 10,
    isEditAllow,
}) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    const listName = 'lic_activities'

    const [dateForValidate, setDateForValidate] = useState<Moment[]>(
        values?.[listName]?.map(({ date_of_issue }) => date_of_issue) ?? [],
    )

    const onChangeDateOfIssue = (key: number, index: number) => (value: Moment) => {
        setDateForValidate((prevState) => {
            prevState[key] = value
            return [...prevState]
        })
        if (form.getFieldValue([listName])?.[index]?.validity) {
            form.validateFields([[listName, index, 'validity']])
        }
    }

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle={stepTitle}
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <Form.List name={listName}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            const props = {
                                ...restField,
                                listName,
                                uneditable: isEdit,
                                fieldKey: name,
                            }
                            return (
                                <Fragment key={key}>
                                    <Divider orientation="left" margin={[32, 0]}>
                                        <Space>
                                            Лицензия
                                            {!isEdit && (
                                                <Button
                                                    type="link"
                                                    size="small"
                                                    onClick={() => remove(name)}
                                                >
                                                    Удалить
                                                </Button>
                                            )}
                                        </Space>
                                    </Divider>
                                    <div className="formRow">
                                        <WrappedInput
                                            {...props}
                                            className="formComponentItem"
                                            label="Вид деятельности"
                                            name={[name, 'type_activity']}
                                            required
                                        />
                                    </div>
                                    <div className="formRow">
                                        <WrappedInput
                                            {...props}
                                            className="formComponentItem"
                                            label="Номер"
                                            name={[name, 'lic_number']}
                                            required
                                        />
                                    </div>
                                    <div className="formRow">
                                        <WrappedDateItem
                                            {...props}
                                            className="formComponentItem"
                                            label="Дата выдачи"
                                            name={[name, 'date_of_issue']}
                                            onChange={onChangeDateOfIssue(key, name)}
                                            onlyPast
                                            required
                                            disableTime
                                        />
                                    </div>
                                    <div className="formRow">
                                        <WrappedInput
                                            {...props}
                                            className="formComponentItem--xl"
                                            label="Кем выдана"
                                            name={[name, 'issue_by']}
                                            required
                                        />
                                    </div>
                                    <div className="formRow">
                                        <WrappedDateItem
                                            {...props}
                                            className="formComponentItem"
                                            label="Срок действия"
                                            name={[name, 'validity']}
                                            disableTime
                                            rules={[
                                                {
                                                    validator: dateValidators.moreDate(
                                                        dateForValidate?.[key] as Moment,
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="formRow" style={{ marginLeft: 240 }}>
                                        <UploadInputForm
                                            {...props}
                                            name={[name, 'file_uuid']}
                                            required
                                            title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                                            maxSizeMb={5}
                                            accept=".pdf,.png,.jpeg, .jpg, .tiff"
                                        />
                                    </div>
                                </Fragment>
                            )
                        })}
                        {!isEdit && fields?.length > 0 && <CardDivider />}
                        {!isEdit && fields?.length < maxCount && (
                            <Button type="link" onClick={() => add()}>
                                Добавить лицензию
                            </Button>
                        )}
                    </>
                )}
            </Form.List>
        </Step>
    )
}
