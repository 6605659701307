import { DELETE_LAST_ENTER_TIMEOUT } from '@constants/common'
import { TwoFactorForm } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { confirmCall, logout, requestCall } from '@store/auth'
import IAppState from '@store/states'

export const TwoFactorAuthForm: FC = () => {
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const { status2Fa, phone, registrationPhoneLastDigits, isAuth } = useSelector(
        (store: IAppState) => store.auth,
    )

    const handleRedirect = async () => {
        const lastEnter = localStorage.getItem('last-enter') || '/offers'
        setTimeout(() => {
            localStorage.removeItem('last-enter')
        }, DELETE_LAST_ENTER_TIMEOUT)

        navigate(lastEnter)
    }

    return (
        <TwoFactorForm
            title="Двухэтапная аутентификация"
            phone={phone}
            lastPhone4Digits={registrationPhoneLastDigits}
            status2FA={status2Fa}
            initCall={() => reduxDispatch(requestCall())}
            confirmCall={(fourDigits) => reduxDispatch(confirmCall(fourDigits))}
            onFinish={() => handleRedirect()}
            onBack={() => reduxDispatch(logout())}
            isSubmitButtonDisabled={!isAuth}
        />
    )
}
