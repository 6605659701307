import { Box, Button, Space } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { HeaderLinks, LogoBox } from '../Header/components'

export const UnauthorizedHeader: FC = () => {
    const navigate = useNavigate()
    const [authStatus] = useState<'default' | 'loggedIn'>('default')

    const handleRegistrationRedirect = async () => navigate('/registration')
    const handleLoginRedirect = async () => navigate('/login')

    return (
        <Box
            className="unauthorized-header"
            padding={[30, 40]}
            direction="row"
            justify="space-between"
            align="center"
        >
            <LogoBox />
            <Box direction="row">
                <HeaderLinks>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href="https://www.masterchain.ru/products/digital_assets/"
                    >
                        О проекте
                    </a>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href="https://www.masterchain.ru/products/masterchain/"
                    >
                        О платформе
                    </a>
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href="https://www.masterchain.ru/about/"
                    >
                        Об операторе ИС
                    </a>
                </HeaderLinks>
                <Box direction="row" justify="space-between" align="center">
                    <Space size={16}>
                        {authStatus === 'default' && (
                            <>
                                <Button
                                    onClick={handleRegistrationRedirect}
                                    borderRadius={16}
                                    size="large"
                                >
                                    Регистрация
                                </Button>
                                <Button
                                    onClick={handleLoginRedirect}
                                    borderRadius={16}
                                    size="large"
                                    type="primary"
                                >
                                    Вход
                                </Button>
                            </>
                        )}
                    </Space>
                </Box>
            </Box>
        </Box>
    )
}
