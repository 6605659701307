import { FC } from 'react'

import { Box } from '../../../../Box'
import { Divider } from '../../../../Divider'
import { LinkToTariff } from '../LinkToTariff'
import styles from './styles.m.less'

interface IComponentProps {
    feePaymentOrder?: number
}
export const BankCollectCommission: FC<IComponentProps> = ({ feePaymentOrder }) => {
    if (!feePaymentOrder) {
        return null
    }

    return (
        <>
            <Divider margin={0} />
            <Box margin={[16, 0, 32, 0]}>
                <div className={styles.footnote}>
                    Включая плату за исполнение платежного поручения в размере{' '}
                    {(feePaymentOrder / 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })}
                    &nbsp; ₽ согласно <LinkToTariff />
                </div>
            </Box>
        </>
    )
}
