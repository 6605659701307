import { XOR } from '@dltru/dfa-models'
import { Table as AntTable, Empty } from 'antd'
import { TableProps } from 'antd/lib/table'
import cn from 'classnames'

import { Box } from '../Box'
import CursorPaginator from './components/CursorPaginator/CursorPaginator'
import TableFooter from './components/TableFooter'
import TableHeader from './components/TableHeader'
import './style.less'

export { ColumnsType } from 'antd/lib/table'

type BaseType = object

export type ITable<T = BaseType> = Omit<TableProps<T>, 'footer' | 'loading' | 'header'> & {
    containerClassList?: string[]
    isLoading?: boolean
    clickableRow?: boolean
    bordered?: boolean
    uneditable?: boolean
    inner?: boolean

    footerRight?: React.ReactNode
    footerLeft?: React.ReactNode
    headerLeft?: React.ReactNode
    headerRight?: React.ReactNode
} & XOR<
        {
            cursors?: { next?: string; prev?: string }
            onPerPage: (value: number) => void
            onNext: () => void
            onPrev: () => void
            defaultPerPage?: number
        },
        {
            noPagination?: boolean
            onPageChange?: (page: number) => void
        }
    >

export const Table = <T extends BaseType>({
    containerClassList = [],
    headerLeft,
    headerRight,
    footerRight,
    footerLeft,
    isLoading = false,
    noPagination,
    onPageChange,
    showSorterTooltip = true, // TODO возможно в целевом решении все-таки отказаться от этого?
    rowKey = 'uuid',
    clickableRow,
    cursors,
    onPerPage,
    onNext,
    onPrev,
    defaultPerPage,
    dataSource,
    bordered,
    uneditable,
    inner,
    scroll = { y: 'calc(100vh - 360px)' },
    ...otherProps
}: ITable<T>): JSX.Element => {
    const tableClassName = cn('table-dfa', {
        'table-dfa--clickable-row': clickableRow,
    })
    const tableContainerClassName = cn('table-dfa__container', [...containerClassList], {
        'table-dfa__container--bordered': bordered,
        'table-dfa__container--uneditable': uneditable,
        'table-dfa__container--inner': inner,
    })
    const isHeader = Boolean(headerRight || headerLeft)
    const isCursorPagination = onPerPage && onNext && onPrev
    const isAntPagination = !isCursorPagination && !noPagination
    const isFooter = Boolean(footerRight || footerLeft || isCursorPagination)
    return (
        <Box className={tableContainerClassName}>
            {isHeader && <TableHeader left={headerLeft || <div />} right={headerRight} />}
            <AntTable
                locale={{ emptyText: <Empty description="Нет загруженных данных" /> }}
                loading={isLoading}
                className={tableClassName}
                pagination={
                    isAntPagination
                        ? {
                              onChange: (page: number) => {
                                  onPageChange?.(page)
                              },
                          }
                        : false
                }
                rowKey={rowKey}
                showSorterTooltip={showSorterTooltip}
                dataSource={dataSource}
                scroll={scroll}
                {...otherProps}
            />
            {isFooter && (
                <TableFooter
                    right={
                        <>
                            {footerRight}

                            {isCursorPagination && (
                                <CursorPaginator
                                    next={cursors?.next}
                                    prev={cursors?.prev}
                                    onPerPage={onPerPage}
                                    onNext={onNext}
                                    onPrev={onPrev}
                                    defaultPerPage={defaultPerPage}
                                    currentSize={dataSource?.length}
                                    isLoading={isLoading}
                                />
                            )}
                        </>
                    }
                    left={footerLeft}
                />
            )}
        </Box>
    )
}
