import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { actions } from './list'
import { actions as summaryActions } from './summary'

export function* loadMoneyAccounts(): Generator<StrictEffect> {
    yield* takeLatest(actions.load.type, function* load(action: ReturnType<typeof actions.load>) {
        yield* put(summaryActions.setLoading())
        yield* put(actions.setLoading())

        const { data, error } = yield api.lib.getAccounts(action.payload)

        if (error) {
            yield* put(summaryActions.setError(error))
            yield* put(actions.setError(error))
        } else if (!data?.item?.accounts) {
            yield* put(summaryActions.setError('Empty response'))
            yield* put(actions.setError('Empty response'))
        } else {
            const { accounts_count, total_balance, total_reserves, total } = data.item
            yield* put(
                summaryActions.setDetails({ accounts_count, total_balance, total_reserves, total }),
            )
            yield* put(actions.setItems(data.item.accounts))
        }
    })
}
