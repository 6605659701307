/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { IDfaOwnerBalance } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IwalletAssetsInitialState {
    isLoading: boolean
    error: null | string
    items: IDfaOwnerBalance[]
}

const initialState: IwalletAssetsInitialState = {
    isLoading: false,
    error: null,
    items: [],
}

export const walletAssetsSlice = createSlice({
    name: 'walletAssets',
    initialState,
    reducers: {
        setLoading(state, _action: PayloadAction) {
            state.isLoading = true
            state.error = null
            state.items = []
        },
        setError(state, action: PayloadAction<string>) {
            state.isLoading = false
            state.error = action.payload
            state.items = []
        },
        setItems(state, action: PayloadAction<IDfaOwnerBalance[]>) {
            state.isLoading = false
            state.error = null
            state.items = action.payload
        },
        loadWalletAssets: (_, action: PayloadAction<string>) => {},
    },
})

export default walletAssetsSlice.reducer
