import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    Divider,
    EMPTY_FIELD_VALUE,
    Form,
    Modal,
    UserIcon,
    WrappedInput,
    WrappedSelect,
} from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeBlockAction } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { AlertAboutBlock } from '@pages/ClientCard/components/BlockClient/AlertAboutBlock'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

import style from './style.m.less'

interface IComponentProps {
    visible: boolean
    setVisible: (val: boolean) => void
    userUID?: string
    userFullName?: string
}

const BLOCKS_OPTIONS = [
    { label: 'Полное блокирование', value: 'full' },
    { label: 'Частичное блокирование', value: 'partly' },
]
export const BlockClientModal: FC<IComponentProps> = ({
    visible,
    setVisible,
    userUID,
    userFullName,
}) => {
    const [form] = Form.useForm()
    const reduxDispatch = useDispatch()
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const onClose = () => {
        form.resetFields()
        setVisible(false)
    }

    const isCurrentClientPartlyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientPartlyBlocked,
    )
    const isCurrentClientFullyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientFullyBlocked,
    )

    const onSelectCertificateHandler = (certificate: CertificateType) => {
        const values = form.getFieldsValue()
        if (userUID) {
            reduxDispatch(
                makeBlockAction({
                    type: values.type,
                    user_uuid: userUID,
                    block_reason: values.reasonText,
                    block_link_uuid: values.reasonDocs?.[0]?.uid,
                    skid: certificate.skid,
                }),
            )
        }
        onClose()
    }

    useEffect(() => {
        if (isCurrentClientPartlyBlocked || isCurrentClientFullyBlocked) {
            onClose()
        }
    }, [isCurrentClientPartlyBlocked, isCurrentClientFullyBlocked])

    if (!visible) {
        return null
    }

    return (
        <>
            <Modal
                visible={visible}
                width={522}
                onCancel={onClose}
                title="Блокирование пользователя"
                wrapClassName={style.modal}
                destroyOnClose
            >
                <Box margin={[0, 0, 24, 0]}>
                    <div className={style.grayBlock}>
                        <span className={style.caption}>Пользователь</span>
                        <div>
                            <UserIcon />
                            <span className={style.headerBold}>
                                {userFullName ?? EMPTY_FIELD_VALUE}
                            </span>
                        </div>
                    </div>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{}}
                    onFinish={() => {
                        setIsVisibleCertificateModal(true)
                    }}
                >
                    <WrappedSelect
                        label="Тип блокирования"
                        name="type"
                        initialValue="full"
                        options={BLOCKS_OPTIONS}
                        size="large"
                    />
                    <Divider />
                    <Box margin={[0, 0, 24, 0]}>
                        <h4 className={style.headerBold}>Основания для блокирования</h4>
                    </Box>
                    <UploadDocumentContainerWrapper
                        name="reasonDocs"
                        title="Загрузите документы."
                        required
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="reasonText"
                            multiline
                            required
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
                <Box margin={[24, 0]}>
                    <AlertAboutBlock />
                </Box>
                <div className={style.footer}>
                    <Button borderRadius={16} onClick={onClose}>
                        Отмена
                    </Button>
                    <Button
                        borderRadius={16}
                        type="primary"
                        onClick={form.submit}
                        disabled={!userUID}
                    >
                        Заблокировать
                    </Button>
                </div>
            </Modal>
            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSelectCertificateHandler}
            />
        </>
    )
}
