import React from 'react'

import { MedalIcon, MedalStarIcon } from '../../Icons'
import { Space } from '../../Space'
import styles from './style.m.less'

export const Qualified: React.FC = () => {
    return (
        <Space align="start" className={styles.qualifiedBlock}>
            <MedalStarIcon className={styles.isQualifiedIcon} />
            <span>
                Квалифицированный
                <br />
                инвестор
            </span>
        </Space>
    )
}

export const NotQualified: React.FC = () => {
    return (
        <Space align="start" className={styles.qualifiedBlock}>
            <MedalIcon className={styles.isNotQualifiedIcon} />
            <span>
                Неквалифицированный
                <br />
                инвестор
            </span>
        </Space>
    )
}
