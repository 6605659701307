import { ColumnsType, Dropdown, LinkCell, MoreOutlined, Tag } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { selectOpertorToDelete } from '@store/exchangeOperators'
import { IExchangeOperator } from '@store/exchangeOperators/types'

import { ActionButton } from '@components/ActionButton'

const DropdownMenu: FC<{
    record: any
    closeDropdown: () => void
}> = ({ record, closeDropdown }) => {
    const reduxDispatch = useDispatch()

    return (
        <div className="tableCellDropdown">
            <a
                href="/"
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    reduxDispatch(selectOpertorToDelete(record))
                    closeDropdown()
                }}
            >
                <div className="dropdownButton">Отозвать статус</div>
            </a>
        </div>
    )
}

const ActionButtonDropdown: FC<{ record: any }> = ({ record }) => {
    const [isDropdownShown, setIsDropdownShown] = useState(false)

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
                onVisibleChange={setIsDropdownShown}
                visible={isDropdownShown}
                overlay={
                    <DropdownMenu closeDropdown={() => setIsDropdownShown(false)} record={record} />
                }
                placement="bottomLeft"
                trigger={['click']}
            >
                <ActionButton className="action-dropdown" onClick={(e) => e.stopPropagation()}>
                    <MoreOutlined />
                </ActionButton>
            </Dropdown>
        </div>
    )
}

export const exchangeOperatorsGridConfig = (): ColumnsType<IExchangeOperator> => [
    {
        title: 'Клиент',
        dataIndex: 'user_uuid',
        key: 'user_uuid',
        width: '720px',
        render: (value) => LinkCell(value, `/clients/${value}`),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        width: '200px',
        render: (value) => <Tag>{value}</Tag>,
    },
    {
        title: 'Статус присвоен',
        dataIndex: 'status_date',
        width: '130px',
        key: 'status_date',
        render: (value) => dayjs(value).format('DD.MM.YYYY'),
    },
    {
        title: '',
        sorter: false,
        fixed: 'right',
        width: '54px',
        render: (_, record) => <ActionButtonDropdown record={record} />,
    },
]
