import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ClipboardImport: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.33341 5.00002H11.6667C13.3334 5.00002 13.3334 4.16669 13.3334 3.33335C13.3334 1.66669 12.5001 1.66669 11.6667 1.66669H8.33341C7.50008 1.66669 6.66675 1.66669 6.66675 3.33335C6.66675 5.00002 7.50008 5.00002 8.33341 5.00002Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6667 18.3333H7.5C3.33333 18.3333 2.5 16.6666 2.5 13.3333V8.33331C2.5 4.53331 3.89167 3.49998 6.66667 3.34998"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3333 3.34998C16.1083 3.49998 17.4999 4.52498 17.4999 8.33331V12.5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5 15.8333V18.3333H15"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 13.3333L17.4667 18.3"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
