import {
    IApprovalHistoryDfa,
    ICalcFeeRequestProps,
    IDfaFront,
    IIssueStopRequest,
    IIssueUnStopRequest,
    IMakeIssueFailed,
    PostRepaymentByOperatorType,
    PutRepaymentByOperatorType,
    RepaymentMatureByOperatorType,
    UserRoles,
} from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { initialState } from '../resources'
import { IDfaDetailsState, IDfaLink } from '../types'

export const dfaDetailsSlice = createSlice({
    name: 'dfaDetails',
    initialState,
    reducers: {
        updateDfaDetails(state, action) {
            const data = { ...action.payload }
            data.original_amount = data.original_amount
                ? parseInt(data.original_amount, 10)
                : data.original_amount
            data.original_price = data.original_price
                ? parseInt(data.original_price, 10)
                : data.original_price // float ?

            return { ...state, ...data }
        },
        updateLinks(state, action) {
            if (action.payload.Links) {
                state.ois_link = action.payload.Links.ois_link || state.ois_link
                state.emitter_link = action.payload.Links.emitter_link || state.emitter_link
            }
            state.isLoadingLink = action.payload.isLoadingLink
            state.errorLink = action.payload.errorLink
        },
        dropDfaDetails() {
            return { ...initialState }
        },
        setError(state: IDfaDetailsState, action: PayloadAction<string>) {
            state.error = action.payload
        },
        setIsLoading(state: IDfaDetailsState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setDfaDetailsFee(state, action) {
            state.fee = action.payload
        },
        clearDfaDetailsFee(state) {
            state.fee = 0
        },
        setApprovalHistory(state, action: PayloadAction<IApprovalHistoryDfa[]>) {
            state.approvalHistory = action.payload
        },
    },
})

export const storeLink = createAction<IDfaLink>('dfaDetails/storeDfa')
export const putDfa = createAction<IDfaFront>('dfaDetails/putDfa')
export const approveDfa = createAction<UserRoles>('dfaDetails/approveDfa')
export const toCollectionStartedDfa = createAction('dfaDetails/toCollectionStartedDfa')
export const emitmentStop = createAction<IIssueStopRequest>('dfaDetails/emitmentStop')
export const rejectDfa = createAction<Omit<IMakeIssueFailed, 'dfaId'>>('dfaDetails/rejectDfa')
export const getDfaById = createAction<number>('dfaDetails/getDfa')
export const { updateDfaDetails, dropDfaDetails, updateLinks } = dfaDetailsSlice.actions
export const emitmentUnstop = createAction<IIssueUnStopRequest>('dfaDetails/emitmentUnstop')
export const makeDFAIssueFailed = createAction<IMakeIssueFailed>('dfaDetails/makeDfaIssueFailed')
export const forceRepayment = createAction<
    PostRepaymentByOperatorType & Omit<PutRepaymentByOperatorType, 'transaction'>
>('dfaDetails/forceRepayment')
export const repaymentMature = createAction<RepaymentMatureByOperatorType>(
    'dfaDetails/repaymentMature',
)
export const getRepaymentDfaFee = createAction<ICalcFeeRequestProps>(
    'dfaDetails/getRepaymentDfaFee',
)
export const rejectApplication = createAction<{ reason?: string }>('dfaDetails/rejectApplication')
export const toModificationDfa = createAction<{ reason?: string }>('dfaDetails/toModificationDfa')
export const issuerConfirmationDfa = createAction('dfaDetails/issuerConfirmationDfa')
export const getApprovalHistoryDfa = createAction<number>('dfaDetails/getApprovalHistoryDfa')

export default dfaDetailsSlice.reducer
