import { FC, ReactNode } from 'react'

import { Box } from '../Box'
import { Col } from '../Col'
import { Row } from '../Row'
import { EMPTY_FIELD_VALUE } from '../constants'
import './styles.less'

export type CardFieldProps = {
    title: ReactNode
    value?: ReactNode
    padding?: number
}

export const CardField: FC<CardFieldProps> = ({ value, title, padding = 8 }) => (
    <Box padding={[padding, 0]} margin={0}>
        <Row gutter={[16, 16]} className="card-field">
            <Col sm={10} md={10} lg={7} xl={6} xxl={5} className="card-field__title">
                {title}
            </Col>
            <Col sm={14} md={14} lg={17} xl={18} xxl={19} className="card-field__value">
                {value ?? EMPTY_FIELD_VALUE}
            </Col>
        </Row>
    </Box>
)
