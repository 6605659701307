import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CaseAdd } from '@dltru/dfa-ui'
import { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { PublicationDfaModal } from '../Modals'

export const PublicationDfaAction: FC = () => {
    const [isDisabled, setIsDisabled] = useState(true)
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const { isAllow } = useContext(RbacContext)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { ois_link, emitter_link } = dfaDetails

    useEffect(() => {
        if (ois_link && emitter_link) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [ois_link, emitter_link])
    if (dfaDetails.status !== DfaStatusEnum.waiting_for_links || !isAllow(['asset:status'])) {
        return null
    }
    return (
        <>
            <Button
                disabled={isDisabled}
                icon={<CaseAdd width="20px" height="20px" />}
                borderRadius={16}
                type="primary"
                onClick={() => setIsModalVisible(true)}
            >
                Опубликовать на витрине предложений
            </Button>
            <PublicationDfaModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
