import { Card, CardContent, CardHeader, Table } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getApprovalHistoryDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { gridConfig } from './gridConfig'

export const ServiceHistory: FC = () => {
    const reduxDispatch = useDispatch()
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const data = useSelector(dfaDetailsSelector.selectApprovalHistory)
    const isLoading = useSelector(dfaDetailsSelector.selectIsLoading)
    useEffect(() => {
        if (dfa.id) {
            reduxDispatch(getApprovalHistoryDfa(dfa.id))
        }
    }, [dfa.id])
    return (
        <Card className="boxContent">
            <CardHeader title="История согласования выпуска" />
            <CardContent>
                <Table
                    isLoading={isLoading && data?.length === 0}
                    columns={gridConfig}
                    dataSource={data}
                    bordered
                    rowKey="id"
                />
            </CardContent>
        </Card>
    )
}
