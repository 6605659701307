import { IInjunction, IRequest } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function makeDFAInjunctionService(payload: IInjunction): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.LockDfaInjunction,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function makeDFALiftingOfInjunctionService(payload: IInjunction): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.LockDfaLiftingInjunction,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function makeDFAInjunctionV2Service(payload: IInjunction): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.WalletDfaInjunctionSet,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function makeDFAInjunctionRemoveV2Service(payload: IInjunction): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.WalletDfaInjunctionRemove,
        HttpMethod.PATCH,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}
