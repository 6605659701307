import { SettlementsType } from '@dltru/dfa-models'
import moment, { Moment } from 'moment'
import { FC, useContext } from 'react'

import { Box } from '../../Box'
import WrappedDateItem from '../../DateItem'
import { StepperContext } from '../../Stepper'
import { Button } from '../../button'
import { FormItemProps } from '../../components/Form'
import { RUS_DATETIME_FORMAT, dfaValidators } from '../../utils'

export interface IRedeemDateDfaProps {
    uneditable: boolean
    disabled?: boolean
    dateForValidate?: Moment
    settlementsType?: SettlementsType
    setRedeemDate?: () => void
}

export const RedeemDateBlock: FC<IRedeemDateDfaProps> = ({
    uneditable,
    disabled,
    dateForValidate,
    settlementsType,
    setRedeemDate,
}) => {
    const { values } = useContext(StepperContext)
    const currentSettlementsType = settlementsType || values?.repayment_settlements_type
    const isDirectRepayment = currentSettlementsType === SettlementsType.direct

    const renderUneditable = (value?: Moment) => {
        if (!value) {
            return (
                <Box justify="space-between" direction="row">
                    Не установлена
                    {setRedeemDate && (
                        <Button
                            borderRadius={8}
                            type="primary"
                            size="small"
                            onClick={setRedeemDate}
                        >
                            Установить дату погашения
                        </Button>
                    )}
                </Box>
            )
        }
        return <>{value.format(RUS_DATETIME_FORMAT)}</>
    }
    return (
        <WrappedDateItem
            className={
                uneditable ? 'formComponentItem formComponentItem--xxl' : 'formComponentItem'
            }
            label="Дата погашения ЦФА"
            name="redeem_date"
            uneditable={uneditable}
            required={!isDirectRepayment}
            rules={[{ validator: dfaValidators.redeemDate(dateForValidate) }]}
            showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
            disabled={disabled}
            renderUneditable={renderUneditable as FormItemProps['renderUneditable']}
        />
    )
}
