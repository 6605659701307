import { IUsersRegistryListItem } from '@dltru/dfa-models'
import { ColumnsType, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

import { StatusCell } from './StatusCell/StatusCell'
import { UserFullNameCell } from './UserFullNameCell/UserFullNameCell'

export const gridConfig: ColumnsType<IUsersRegistryListItem> = [
    {
        title: 'Дата начала отношений',
        dataIndex: 'user_identification_date',
        key: 'user_identification_date',
        width: 130,
        render: (value) => dayjs(value).format(VIEW_DATE_FORMAT),
    },
    {
        title: 'Пользователь',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        ellipsis: true,
        width: 400,
        render: (_, record) => UserFullNameCell({ ...record }),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        width: 200,
        render: (value, record) => StatusCell({ ...record }),
    },
    {
        title: 'ID-оффчейн',
        dataIndex: 'user_uuid',
        sorter: true,
        width: 160,
    },
    {
        title: 'ID-мастерчейн',
        dataIndex: 'address',
        sorter: true,
        width: 160,
    },
]
