import { DocumentsEnum, GetDocumentsParams } from '@dltru/dfa-models'
import { DownloadButton } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { documentsSlice } from '@store/documents'
import { documentsSelector } from '@store/documents/selectors'

interface IComponentProps {
    documentType: DocumentsEnum
    title: string
    icon?: React.ReactNode
    params?: GetDocumentsParams
}
export const DownloadBtn: FC<IComponentProps> = ({ documentType, params, ...props }) => {
    const isLoading = useSelector(documentsSelector.selectIsLoading(documentType, params?.user_id))
    const reduxDispatch = useDispatch()
    const onClick = () => {
        reduxDispatch(
            documentsSlice.actions.downloadDocument({
                document_name: documentType,
                params}),
        )
    }
    return <DownloadButton disabled={isLoading} onClick={onClick} {...props} />
}
