import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const VuesaxIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.2501 9.99967C11.2501 12.6497 9.10841 14.7913 6.45841 14.7913C3.80841 14.7913 1.66675 12.6497 1.66675 9.99967C1.66675 7.34967 3.80841 5.20801 6.45841 5.20801"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.33325 10C8.33325 7.24167 10.5749 5 13.3333 5C16.0916 5 18.3333 7.24167 18.3333 10C18.3333 12.7583 16.0916 15 13.3333 15"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
