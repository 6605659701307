import { Box, Button, CardField, Divider, EmailInput, Form, Space } from '@dltru/dfa-ui'
import clsx from 'clsx'
import { FieldData } from 'rc-field-form/lib/interface'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import styles from '../style.m.less'
import { changeEmailModal } from './ChangeEmailModal'

interface IComponentProps {
    email?: string
    user_uuid?: string
    isEditAllow?: boolean
}
const enum EditStateEnum {
    'initial',
    'edit',
    'await',
}
const ButtonCaption = {
    [EditStateEnum.initial]: 'Изменить',
    [EditStateEnum.edit]: 'Отмена',
    [EditStateEnum.await]: 'Отправлена ссылка',
}
export const EmailBlock: FC<IComponentProps> = ({ email, user_uuid, isEditAllow }) => {
    const [form] = Form.useForm()
    const [newEmail, setNewEmail] = useState('')
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
    const [emailEditState, setEmailEditState] = useState<EditStateEnum>(EditStateEnum.initial)

    const reduxDispatch = useDispatch()
    const isLoading = useSelector(clientsSelector.selectIsLoading)
    const error = useSelector(clientsSelector.selectError)

    useEffect(() => {
        if (!isLoading && emailEditState === EditStateEnum.edit && !error) {
            setEmailEditState(EditStateEnum.await)
        }
    }, [isLoading])

    const sendEmailForChange = async () => {
        if (!user_uuid) {
            return
        }
        const value = await changeEmailModal()
        if (value) {
            reduxDispatch(clientsSlice.actions.changeEmail({ email: newEmail, user_uuid }))
        }
    }

    const onFieldsChange = (fields: FieldData[]) => {
        setNewEmail(fields[0]?.value ?? '')
        setIsDisabledSubmit(Boolean(fields[0]?.errors?.length))
    }

    const onClickChangeBtn = () => {
        switch (emailEditState) {
            case EditStateEnum.edit:
                setEmailEditState(EditStateEnum.initial)
                form.resetFields()
                setIsDisabledSubmit(true)
                break
            case EditStateEnum.initial:
                setEmailEditState(EditStateEnum.edit)
                break
            default:
                return
        }
    }

    const ValueComponent = (
        <Space direction="vertical" className={styles.mainWrapper}>
            <Space className={styles.rowWrapper}>
                <span className={styles.bold}>{email}</span>
                {isEditAllow && (
                    <Button
                        type="link"
                        danger={emailEditState === EditStateEnum.await}
                        onClick={onClickChangeBtn}
                    >
                        {ButtonCaption[emailEditState]}
                    </Button>
                )}
            </Space>
            {emailEditState === EditStateEnum.edit && (
                <>
                    <Divider margin={[8, 0]} />
                    <Form
                        form={form}
                        layout="vertical"
                        className={styles.formWrapper}
                        onFieldsChange={onFieldsChange}
                    >
                        <EmailInput
                            label="Новый адрес электронной почты"
                            placeholder="Укажите новый адрес электронной почты"
                            size="large"
                            required
                        />
                        <p className={styles.grayText}>
                            Письмо со ссылкой подтверждения будет отправлено пользователю на новую
                            электронную почту.
                        </p>
                        <Divider margin={[24, 0]} />
                        <Box align="end">
                            <Button
                                type="primary"
                                onClick={sendEmailForChange}
                                borderRadius={12}
                                disabled={isDisabledSubmit}
                            >
                                Отправить
                            </Button>
                        </Box>
                    </Form>
                </>
            )}
        </Space>
    )
    const wrapperClassName = clsx(styles.cardFieldWrapper, {
        [styles.cardFieldWrapperGray]: emailEditState === EditStateEnum.edit,
    })

    return (
        <>
            <Box
                margin={[0, -24, emailEditState === EditStateEnum.edit ? 16 : 0, -48]}
                padding={[0, 40, 16, 48]}
                className={wrapperClassName}
            >
                <CardField title="Электронная почта" value={ValueComponent} padding={16} />
            </Box>
            {emailEditState !== EditStateEnum.edit && <Divider margin={[0, 0, 16, 0]} />}
        </>
    )
}
