export function isString(str: any): str is string {
    return typeof str === 'string'
}
export const isSRRUid = (uid: string) => uid === '00000000-0000-0000-0000-000000000000'

export function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !('nodeType' in e)) {
        throw new Error(`Node expected`)
    }
}

export const isEmptyValue = (value: unknown) =>
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value as object).length === 0)
