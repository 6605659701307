import cn from 'classnames'
import { FC, useEffect, useRef } from 'react'

import { Box, BoxProps } from '../Box'
import './styles.less'

export type CardProps = Pick<BoxProps, 'padding' | 'margin' | 'className' | 'width'> & {
    scrollIntoView?: boolean
    uneditable?: boolean
}
export const Card: FC<CardProps> = ({
    className,
    children,
    scrollIntoView = false,
    padding = [8, 24, 24, 24],
    margin = [0, 0, 8, 0],
    uneditable,
    ...rest
}) => {
    const node = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (node.current && scrollIntoView && children) {
            const tid = setTimeout(() => node.current?.scrollIntoView())

            return () => clearTimeout(tid)
        }
    }, [])

    const classNameBox = cn(className, 'card-box', {
        'card-box--uneditable': uneditable,
    })

    return (
        <Box ref={node} padding={padding} margin={margin} className={classNameBox} {...rest}>
            {children}
        </Box>
    )
}
