import { FC } from 'react'

import { Box } from '../Box'
import { Divider } from '../Divider'

export type CardContentProps = {
    divider?: boolean
    className?: string
}
export const CardContent: FC<CardContentProps> = ({ className, divider, children }) => {
    return (
        <>
            {divider && <Divider margin={8} />}
            <Box padding={[8, 0, 8, 24]} margin={0} className={className}>
                {children}
            </Box>
            {divider && <Divider margin={8} />}
        </>
    )
}
