import { Divider as AntDivider, DividerProps as AntDividerProps } from 'antd'
import { FC } from 'react'

import { VHStyle, getStylesPropName } from '../utils/styles'
import './style.less'

export type DividerProps = AntDividerProps & {
    padding?: VHStyle
    margin?: VHStyle
}

export const Divider: FC<DividerProps> = ({ padding, margin, style, ...props }) => (
    <div
        className="dfa-divider__wrapper"
        style={{
            ...(style ?? {}),
            ...getStylesPropName('padding', padding),
            ...getStylesPropName('margin', margin),
        }}
    >
        <AntDivider {...props} style={{ margin: 0 }} />
    </div>
)
