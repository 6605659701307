import IAppState from '../states'

const selectInvestorsDeals = (state: IAppState) => state.investorsDeals
const selectInvestorsDealsIsLoading = (state: IAppState) => state.investorsDeals.isLoading
const selectInvestorsDealDetail = (state: IAppState) => state.investorDeal

export const investorsDealsSelector = {
    selectInvestorsDeals,
    selectInvestorsDealsIsLoading,
    selectInvestorsDealDetail,
}
