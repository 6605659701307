import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Logo: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="9" y="11" width="3" height="27" fill="currentColor" />
                <rect x="12" y="7" width="3" height="24" fill="currentColor" />
                <rect x="15" y="13" width="3" height="32" fill="currentColor" />
                <rect x="18" y="3" width="3" height="40" fill="currentColor" />
                <rect x="21" y="6" width="3" height="29" fill="currentColor" />
                <rect x="24" y="10" width="3" height="23" fill="currentColor" />
                <rect x="27" y="16" width="3" height="29" fill="currentColor" />
                <rect x="30" y="12" width="3" height="22" fill="currentColor" />
                <rect x="33" y="19" width="3" height="19" fill="currentColor" />
            </svg>
        )}
        {...props}
    />
)
