import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const SecurityUser: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.74179 1.8583L4.58346 3.41663C3.62513 3.77496 2.8418 4.90829 2.8418 5.93329V12.1249C2.8418 13.1083 3.49181 14.4 4.28347 14.9916L7.8668 17.6666C9.0418 18.55 10.9751 18.55 12.1501 17.6666L15.7335 14.9916C16.5251 14.4 17.1751 13.1083 17.1751 12.1249V5.93329C17.1751 4.90829 16.3918 3.77496 15.4335 3.41663L11.2751 1.8583C10.5668 1.59997 9.43345 1.59997 8.74179 1.8583Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.9991 9.09985C9.96576 9.09985 9.92409 9.09985 9.89076 9.09985C9.10742 9.07485 8.48242 8.42484 8.48242 7.63318C8.48242 6.82485 9.14076 6.1665 9.9491 6.1665C10.7574 6.1665 11.4158 6.82485 11.4158 7.63318C11.4074 8.43318 10.7824 9.07485 9.9991 9.09985Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.34121 11.4333C7.54121 11.9666 7.54121 12.8416 8.34121 13.375C9.24954 13.9833 10.7412 13.9833 11.6495 13.375C12.4495 12.8416 12.4495 11.9666 11.6495 11.4333C10.7495 10.825 9.25788 10.825 8.34121 11.4333Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
