import { FC, ReactNode, useState } from 'react'

import { ArrowDown, ArrowUp } from '../Icons'
import styles from './openingPanel.m.less'

export interface IPanelTitleProps {
    title: ReactNode
    onOpen?: () => void
    onClose?: () => void
}

export const PanelTitle: FC<IPanelTitleProps> = ({ title, onOpen, onClose }) => {
    const [isOpen, setIsOpen] = useState(false)

    const onClickHandler = () => {
        setIsOpen(!isOpen)
        onOpen && !isOpen && onOpen()
        onClose && isOpen && onClose()
    }

    return (
        <div className={styles.titleContainer}>
            <h5 className="card-header__title">{title}</h5>
            <span onClick={onClickHandler}>{isOpen ? <ArrowUp /> : <ArrowDown />}</span>
        </div>
    )
}
