import { createAction, createSlice } from '@reduxjs/toolkit'

import { CursorPaginationParam } from '@store/types'

import { initialState } from './resources'

const walletLocksSlice = createSlice({
    name: 'walletLocks',
    initialState,
    reducers: {
        setWalletLock: (state, action) => {
            state.data = action.payload.items
            state.paging = action.payload.paging
            state.order = action.payload.order
            state.limit = action.payload.limit
        },
        updateWalletLock: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateLimitsCursor: (state, action) => {
            state.paging = action.payload
        },
        setLimits: (state, action) => {
            state.limit = action.payload
        },
        setOrder: (state, action) => {
            state.order = action.payload
        },
    },
})

export const getWalletLocksData = createAction<CursorPaginationParam | undefined>(
    'walletLocks/load',
)
export const { updateWalletLock, updateLimitsCursor, setWalletLock, setLimits, setOrder } =
    walletLocksSlice.actions

export default walletLocksSlice.reducer
