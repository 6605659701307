// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag__QSHo {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #868991;\n  margin: 0;\n}\n.tagSuccess__z1Fu {\n  color: #52c41a;\n}\n.tagError_tqDbJ {\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/BodyTag/style.m.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AADJ;AAGI;EACI,cAAA;AADR;AAII;EACI,cAAA;AAFR","sourcesContent":["@import '/src/assets/style/palette';\n\n.tag {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    color: @gray-7;\n    margin: 0;\n\n    &Success {\n        color: @green-6;\n    }\n\n    &Error {\n        color: @red-6;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "tag__QSHo",
	"tagSuccess": "tagSuccess__z1Fu",
	"tagError": "tagError_tqDbJ"
};
export default ___CSS_LOADER_EXPORT___;
