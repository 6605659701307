/* eslint-disable unused-imports/no-unused-vars */
import { DfaStatusEnum, UserRoles, dfaFrontToBack } from '@dltru/dfa-models'
import { openMessage, openNotification } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { getDfaByIdHelper } from '@store/dfa/details/helpers'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { getCalculateFeeHelper, signCustomTransaction } from '@store/helper'
import { notificationCounterSlice } from '@store/notificationCounter'
import IAppState from '@store/states'

import api from '@services/api'

import {
    approveDfa,
    dfaDetailsSlice,
    emitmentStop,
    emitmentUnstop,
    forceRepayment,
    getApprovalHistoryDfa,
    getDfaById,
    getRepaymentDfaFee,
    issuerConfirmationDfa,
    makeDFAIssueFailed,
    putDfa,
    rejectApplication,
    rejectDfa,
    repaymentMature,
    storeLink,
    toCollectionStartedDfa,
    toModificationDfa,
    updateDfaDetails,
    updateLinks,
} from './index'

function* handlePutDfa({ payload }: ReturnType<typeof putDfa>) {
    try {
        yield* put(updateDfaDetails({ error: '', isLoading: true }))

        const { error, data } = yield* call(
            api.lib.updateDfaService,
            payload.id as number,
            dfaFrontToBack(payload),
        )
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(updateDfaDetails({ error: '', isLoading: false, ...payload }))
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли ошибки при отправке запроса' })
        yield* put(updateDfaDetails({ error: 'update dfa error', isLoading: false }))
    }
}

function* handleDfaById({ payload }: ReturnType<typeof getDfaById>) {
    try {
        yield* put(updateDfaDetails({ error: '', isLoading: true }))

        const dfa = yield* call(getDfaByIdHelper, payload)

        yield* put(updateDfaDetails({ error: '', isLoading: false, ...dfa }))
    } catch (error) {
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}

function* handleRejectDfa({ payload }: ReturnType<typeof rejectDfa>) {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))

        if (!dfa.id) {
            return
        }

        const { error } = yield* call(api.lib.rejectDfaService, dfa.id, payload)
        if (error) {
            throw error
        }

        openMessage({
            type: 'success',
            message: 'Запрос на аннулирование выпуска успешно отправлен',
        })
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли ошибки при аннулировании выпуска' })
        yield* put(dfaDetailsSlice.actions.setError('dfa error'))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleApproveDfa({ payload: role }: ReturnType<typeof approveDfa>) {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))

        if (!dfa.id) {
            throw new Error('lost id')
        }
        let apiName
        if (role === UserRoles.TELLER) {
            apiName = api.lib.confirmToLawyerDfaService
        } else if (role === UserRoles.LAWYER) {
            apiName = api.lib.confirmToSpecDfaService
        } else if (role === UserRoles.SPEC) {
            apiName = api.lib.confirmDfaService
        }

        if (apiName) {
            const { error, data } = yield* call(apiName, dfa.id)
            if (error || data?.error) {
                throw error || data?.error
            }

            yield* put(getDfaById(dfa.id))
            yield* put(notificationCounterSlice.actions.getRegistrationDfaCount())
            yield* put(getApprovalHistoryDfa(dfa.id))

            if (role === UserRoles.SPEC) {
                openMessage({
                    type: 'success',
                    message: 'Запрос на согласование успешно отправлен',
                })
            }
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Возникли ошибки при согласовании' })
        yield* put(dfaDetailsSlice.actions.setError('dfa error'))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleStoreDfaLink({ payload }: ReturnType<typeof storeLink>) {
    try {
        const dfa = yield* select((store: IAppState) => store.dfaDetails)
        yield* put(updateLinks({ errorLink: '', isLoadingLink: true }))

        if (!dfa?.id) {
            throw new Error('lost id')
        }

        const { error } = yield api.lib.patchDfaLinkService(`${dfa.id}`, { ...payload })
        if (error) {
            throw error
        }

        yield* put(updateLinks({ Links: payload, errorLink: '', isLoadingLink: false }))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не получилось установить ссылку',
        })
        yield* put(updateLinks({ errorLink: error ?? '', isLoadingLink: false }))
    }
}

function* handleToCollectionStartedDfa() {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))

        if (!dfa?.id) {
            throw new Error('lost id')
        }
        const { error } = yield* call(api.lib.toCollectionStartedDfaService, dfa.id)
        if (error) {
            throw error
        }
        yield* put(getDfaById(dfa.id))
        openMessage({
            type: 'success',
            message: 'Выпуск успешно опубликован на витрине',
        })
    } catch (error) {
        yield* put(dfaDetailsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при публикации на витрине',
        })
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleEmitmentStop({ payload }: ReturnType<typeof emitmentStop>) {
    try {
        const { error } = yield api.lib.emitmentStopByOperatorService(payload)
        if (error) {
            throw error
        }

        yield* put(
            updateDfaDetails({
                error: '',
                isLoading: false,
                status: DfaStatusEnum.issue_stopped,
            }),
        )
    } catch (error) {
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}

function* handleEmitmentUnstop({ payload }: ReturnType<typeof emitmentUnstop>) {
    try {
        const { error } = yield api.lib.emitmentUnstopByOperatorService(payload)
        if (error) {
            throw error
        }

        yield* put(getDfaById(+payload.asset_id))
    } catch (error) {
        yield* put(updateDfaDetails({ error: 'dfa error', isLoading: false }))
    }
}

function* handleMakeIssueFailed({ payload }: ReturnType<typeof makeDFAIssueFailed>) {
    try {
        const { error } = yield* call(api.lib.makeDFAIssueFailed, payload)

        if (error) {
            throw new Error(error)
        }

        openMessage({
            type: 'success',
            message: 'Эмитент признал выпуск ЦФА несостоявшимся',
        })
        yield* put(getDfaById(payload.dfaId))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось признать выпуск несостоявшимся',
        })
    }
}

function* handleForceRepayment({ payload }: ReturnType<typeof forceRepayment>) {
    try {
        yield* put(updateDfaDetails({ error: '', isLoading: true }))
        const { error, data } = yield* call(api.lib.postRepaymentByOperator, {
            asset_id: payload.asset_id,
            skid: payload.skid,
            force_repayment_base: payload.force_repayment_base,
            force_repayment_document_uuid: payload.force_repayment_document_uuid,
        })
        if (error || data?.error) {
            throw error || data?.error
        }
        if (data?.item) {
            const dataForSign = {
                asset_id: payload.asset_id,
                repayment_collect_type: payload.repayment_collect_type,
                force_repayment_base: payload.force_repayment_base,
                force_repayment_document_uuid: payload.force_repayment_document_uuid,
                redeem_price_per_dfa: payload.redeem_price_per_dfa,
                repayment_fee: payload.repayment_fee,
                is_making_fee: payload.is_making_fee,
                redemption_spread: payload.redemption_spread,
                transaction: data.item,
            }
            yield* signCustomTransaction(payload.skid, dataForSign, api.lib.putRepaymentByOperator)
            openNotification({
                type: 'success',
                message: 'Погашение выпуска ЦФА запущено',
            })
            yield* put(getDfaById(payload.asset_id))
        }
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Возникли ошибки при отправке запроса на погашение',
        })
    } finally {
        yield* put(updateDfaDetails({ isLoading: false }))
    }
}

function* handleRepaymentDfaFee({ payload }: ReturnType<typeof getRepaymentDfaFee>) {
    try {
        const data = yield* call(getCalculateFeeHelper, payload)

        yield* put(dfaDetailsSlice.actions.setDfaDetailsFee(data.item.FeeAmount))
    } catch (error) {
        yield* put(dfaDetailsSlice.actions.setDfaDetailsFee(undefined))
    }
}

function* handleRejectApplication({ payload }: ReturnType<typeof rejectApplication>) {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        if (dfa.id) {
            yield* put(dfaDetailsSlice.actions.setIsLoading(true))
            const { error } = yield api.lib.rejectDfaServiceV2(dfa.id, payload)
            if (error) {
                throw error
            }
            yield* put(getDfaById(dfa.id))
            yield* put(notificationCounterSlice.actions.getRegistrationDfaCount())
            yield* put(getApprovalHistoryDfa(dfa.id))
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось отклонить заявку' })
        yield* put(dfaDetailsSlice.actions.setError(error))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleIssuerConfirmationDfa() {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        if (dfa.id) {
            yield* put(dfaDetailsSlice.actions.setIsLoading(true))
            const { error } = yield api.lib.issuerConfirmationDfaService(dfa.id)
            if (error) {
                throw error
            }
            yield* put(getDfaById(dfa.id))
            yield* put(notificationCounterSlice.actions.getRegistrationDfaCount())
            yield* put(getApprovalHistoryDfa(dfa.id))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось отправить заявку на подтверждение эмитенту',
        })
        yield* put(dfaDetailsSlice.actions.setError(error))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleToModificationDfa({ payload }: ReturnType<typeof toModificationDfa>) {
    try {
        const dfa = yield* select(dfaDetailsSelector.selectDfaDetails)
        if (dfa.id) {
            yield* put(dfaDetailsSlice.actions.setIsLoading(true))
            const { error } = yield api.lib.toModificationDfaService(dfa.id, payload)
            if (error) {
                throw error
            }
            yield* put(getDfaById(dfa.id))
            yield* put(notificationCounterSlice.actions.getRegistrationDfaCount())
            yield* put(getApprovalHistoryDfa(dfa.id))
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось отправить заявку на доработку' })
        yield* put(dfaDetailsSlice.actions.setError(error))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleApprovalHistoryDfa({ payload }: ReturnType<typeof getApprovalHistoryDfa>) {
    try {
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getApprovalHistoryDfaForAdmin, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(dfaDetailsSlice.actions.setApprovalHistory(data ?? []))
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось получить истрию согласования выпуска' })
        yield* put(dfaDetailsSlice.actions.setError(error))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

function* handleRepaymentMature({ payload }: ReturnType<typeof repaymentMature>) {
    try {
        yield* put(dfaDetailsSlice.actions.setIsLoading(true))
        const apiForRequest = payload.user_uuid
            ? api.lib.repaymentMatureInvestor
            : api.lib.repaymentMatureAsset
        const { error, data } = yield* call(apiForRequest, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        openNotification({
            type: 'success',
            message: 'Запрос на погашение успешно отправлен',
        })
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось отправить запрос на погашение' })
        yield* put(dfaDetailsSlice.actions.setError(error))
    } finally {
        yield* put(dfaDetailsSlice.actions.setIsLoading(false))
    }
}

export function* watchDfaDetails(): Generator<StrictEffect> {
    yield* takeLatest(rejectDfa.type, handleRejectDfa)
    yield* takeLatest(approveDfa.type, handleApproveDfa)
    yield* takeLatest(putDfa.type, handlePutDfa)
    yield* takeLatest(getDfaById.type, handleDfaById)
    yield* takeLatest(storeLink.type, handleStoreDfaLink)
    yield* takeLatest(toCollectionStartedDfa.type, handleToCollectionStartedDfa)
    yield* takeLatest(emitmentStop.type, handleEmitmentStop)
    yield* takeLatest(emitmentUnstop.type, handleEmitmentUnstop)
    yield* takeLatest(makeDFAIssueFailed.type, handleMakeIssueFailed)
    yield* takeLatest(forceRepayment.type, handleForceRepayment)
    yield* takeLatest(getRepaymentDfaFee.type, handleRepaymentDfaFee)
    yield* takeLatest(rejectApplication.type, handleRejectApplication)
    yield* takeLatest(issuerConfirmationDfa.type, handleIssuerConfirmationDfa)
    yield* takeLatest(toModificationDfa.type, handleToModificationDfa)
    yield* takeLatest(getApprovalHistoryDfa.type, handleApprovalHistoryDfa)
    yield* takeLatest(repaymentMature.type, handleRepaymentMature)
}
