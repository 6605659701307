import { DfaStatusEnum, ForceRepaymentResponse } from '@dltru/dfa-models'
import { BrifcaseTick, Button } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { RepaymentModal } from '../Modals'

export const BlockUserMessage = (items?: ForceRepaymentResponse[]) => {
    return (
        <>
            Осуществление расчетов за погашение ЦФА невозможно в связи с блокированием
            пользователя(ей):
            {items?.map((item) => (
                <>
                    <br />
                    <a
                        key={item.user_uuid}
                        href={`/clients/${item.user_uuid}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {item.full_name}
                    </a>
                </>
            ))}
        </>
    )
}
export const RepaymentAction: FC = () => {
    const status = useSelector(dfaDetailsSelector.selectDfaDetailsStatus)
    const [isModalVisible, setIsModalVisible] = useState(false)

    if (status !== DfaStatusEnum.emitment_success) {
        return null
    }

    return (
        <>
            <Button
                borderRadius={12}
                icon={<BrifcaseTick />}
                onClick={() => setIsModalVisible(true)}
            >
                Погасить выпуск
            </Button>
            <RepaymentModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}
