import { Box, Button, Divider, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

export const UnblockClientWalletModal: FC<{
    onUnblock: () => void
    onClose: () => void
    visible: boolean
}> = ({ onUnblock, onClose, visible }) => {
    return (
        <Modal
            title="Снятие блокировки с кошелька пользователя"
            visible={visible}
            closable
            footer={[
                <Button borderRadius={12} onClick={onClose}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={onUnblock}>
                    Снять блокировку с кошелька
                </Button>,
            ]}
        >
            <Box>
                <span>Блокировка будет снята, пользователю будут доступны операции с ЦФА.</span>
            </Box>
            <Divider />
            <div className="ant-modal-title">Основания для снятия блокировки</div>
            <Box direction="column" padding={[24, 0, 0, 0]}>
                <UploadDocumentContainerWrapper
                    name="approval_document_file_uuid"
                    title="Загрузить фотографии документа"
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="approval_document_comment"
                        multiline
                        maxLength={200}
                    />
                </UploadDocumentContainerWrapper>
            </Box>
        </Modal>
    )
}
