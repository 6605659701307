import { IIsAllow } from '@dltru/dfa-models'
import { FC, useContext } from 'react'

import { RbacContext } from './RbacContext'

export const IsAllow: FC<IIsAllow> = ({ scope, children }) => {
    const { isAllow } = useContext(RbacContext)

    if (!isAllow) {
        return <></>
    }

    if (isAllow && !isAllow(scope)) {
        return <></>
    }

    return <>{children}</>
}
