import { Button, LockIcon, getFullName } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { BlockClientModal } from '../../BlockClient'

export const BlockClientAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const profile = useSelector(clientsSelector.selectProfile)
    const isCurrentClientPartlyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientPartlyBlocked,
    )
    const isCurrentClientFullyBlocked = useSelector(
        clientsSelector.selectIsCurrentClientFullyBlocked,
    )
    const isVisibleBlockClientBtn = !(isCurrentClientPartlyBlocked || isCurrentClientFullyBlocked)
    if (!isVisibleBlockClientBtn) {
        return null
    }
    return (
        <>
            <Button icon={<LockIcon />} borderRadius={12} onClick={() => setIsModalVisible(true)}>
                {isCurrentClientPartlyBlocked
                    ? 'Заблокировать полностью'
                    : 'Заблокировать пользователя'}
            </Button>
            <BlockClientModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                userFullName={getFullName(profile)}
                userUID={profile?.user_uuid}
            />
        </>
    )
}
