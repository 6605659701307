import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { clientsSelector } from '@store/clients/selectors'

import api from '@services/api'

import { clientsSlice } from '../index'

function* getCurrentClientAdditionalInfo() {
    try {
        const clientUid = yield* select(clientsSelector.selectCurrentClientId)
        if (clientUid) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error, data } = yield* call(api.lib.getBlacklistByUser, clientUid)

            if (error || data?.error) {
                throw error || data?.error
            }

            yield* put(
                clientsSlice.actions.updateCurrentClientAdditionalInfo({
                    blacklist: data.items ?? [],
                }),
            )
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить дополнительные сведения',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}
function* putBlacklist({ payload }: ReturnType<typeof clientsSlice.actions.putBlacklist>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error } = yield* call(api.lib.putBlacklist, payload)

        if (error) {
            throw error
        }

        const { data } = yield* call(api.lib.getBlacklistByUser, payload[0].user_uuid)
        yield* put(
            clientsSlice.actions.updateCurrentClientAdditionalInfo({
                blacklist: data.items ?? [],
            }),
        )
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось добавить проверку по перечню',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

export function* clientAdditionalInfoSagas(): Generator<StrictEffect> {
    yield* takeLatest(
        clientsSlice.actions.getCurrentClientAdditionalInfo.type,
        getCurrentClientAdditionalInfo,
    )
    yield* takeLatest(clientsSlice.actions.putBlacklist.type, putBlacklist)
}
