export const enum DfaStatusEnum {
    draft = 'draft', // фактически отсутствует на бэке
    waiting_for_links = 'waiting_for_links',
    waiting_for_collection_started = 'waiting_for_collection_started',
    collection_started = 'collection_started',
    rejected = 'rejected',
    revoked = 'revoked',
    emitment_success = 'emitment_success',
    issue_stopped = 'issue_stopped',
    issue_failure = 'issue_failure',
    mature = 'mature',
    mature_preparation = 'mature_preparation',
    mature_confirmed = 'mature_confirmed',
    redeemed = 'redeemed',

    waiting_for_emission = 'waiting_for_emission',
    waiting_for_emitment = 'waiting_for_emitment', // будет убран
    not_redeemed = 'not_redeemed',

    preliminary_check = 'preliminary_check',
    issuer_confirmation = 'issuer_confirmation',
    teller_approval = 'teller_approval',
    lawyer_approval = 'lawyer_approval',
    spec_approval = 'spec_approval',
    waiting_for_mature = 'waiting_for_mature',
    modification = 'modification', // пока что отсутствует на бэке
    emitment_failure = 'emitment_failure',
}

export const finalDfaStatuses = [
    DfaStatusEnum.rejected,
    DfaStatusEnum.revoked,
    DfaStatusEnum.issue_failure,
    DfaStatusEnum.redeemed,
]

export const beforeEmitmentStatuses = [
    DfaStatusEnum.emitment_success,
    DfaStatusEnum.mature,
    DfaStatusEnum.mature_confirmed,
    DfaStatusEnum.not_redeemed,
    DfaStatusEnum.redeemed,
]
export const afterEmitmentStatuses = [
    DfaStatusEnum.rejected,
    DfaStatusEnum.revoked,
    DfaStatusEnum.waiting_for_links,
    DfaStatusEnum.waiting_for_collection_started,
    DfaStatusEnum.collection_started,
    DfaStatusEnum.waiting_for_emission,
    DfaStatusEnum.issue_failure,
]

export const preStatuses = [
    DfaStatusEnum.rejected,
    DfaStatusEnum.revoked,
    DfaStatusEnum.modification,
    DfaStatusEnum.preliminary_check,
    DfaStatusEnum.teller_approval,
    DfaStatusEnum.lawyer_approval,
    DfaStatusEnum.spec_approval,
]

export const approvalStatuses = [
    DfaStatusEnum.preliminary_check,
    DfaStatusEnum.teller_approval,
    DfaStatusEnum.lawyer_approval,
    DfaStatusEnum.spec_approval,
]

export const dfaApprovalStatusTranslate = {
    [DfaStatusEnum.preliminary_check]: 'На предварительной проверке',
    [DfaStatusEnum.teller_approval]: 'На согласовании у Операциониста',
    [DfaStatusEnum.lawyer_approval]: 'На согласовании у Юриста',
    [DfaStatusEnum.spec_approval]: 'На согласовании у Специалиста ПОД/ФТ',
}
