import { FC } from 'react'

import { DeleteIcon } from '../../Icons'
import { Space } from '../../Space'
import { Button } from '../../button'
import { Form, WrappedInput } from '../../components/Form'

export interface IFormListInputProps {
    listName: string
    uneditable?: boolean
    required?: boolean
    disabled?: boolean
    label: string
    name: string
    className?: string
    InputComponent?: React.FC
    maxCount?: number
}
export const FormListInput: FC<IFormListInputProps> = ({
    listName,
    uneditable,
    required,
    disabled,
    label,
    name: fieldName,
    className,
    InputComponent = WrappedInput,
    maxCount = 5,
}) => {
    return (
        <Form.List name={listName}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index) => (
                        <Space align="start" key={key}>
                            <InputComponent
                                {...restField}
                                listName={listName}
                                className={className}
                                label={index === 0 ? label : ''}
                                name={[name, fieldName]}
                                uneditable={uneditable}
                                required={required && index === 0}
                                disabled={disabled}
                                size="large"
                            />
                            {fields.length > 1 && !uneditable && (
                                <Button
                                    type="link"
                                    onClick={() => remove(name)}
                                    style={{ padding: '6px 0' }}
                                    disabled={disabled}
                                >
                                    <DeleteIcon />
                                </Button>
                            )}
                        </Space>
                    ))}
                    {!uneditable && fields?.length < maxCount && (
                        <Button
                            type="link"
                            onClick={() => add()}
                            size="small"
                            style={{ marginLeft: 240 }}
                            disabled={disabled}
                        >
                            Добавить ещё
                        </Button>
                    )}
                </>
            )}
        </Form.List>
    )
}
