import { FC } from 'react'

import style from './style.m.less'

export interface ITwoColumns {
    ratio?: string
    gap?: string
    alignItems?: string
    justifyItems?: string
    textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
}

export const TwoColumns: FC<ITwoColumns> = ({
    ratio,
    children,
    gap,
    alignItems,
    justifyItems,
    textAlign,
}) => (
    <div
        className={style.twoColumns}
        style={{
            gridTemplateColumns: ratio,
            gap: gap,
            alignItems: alignItems,
            justifyItems: justifyItems,
            textAlign: textAlign,
        }}
    >
        {children}
    </div>
)
