import { Form, IStep, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

const STEP_INDEX = 4

export const Step5: FC<Partial<IStep>> = (props) => {
    const { editable, currentStep } = useContext(StepperContext)
    const [form] = Form.useForm()

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    return (
        <Step stepIndex={STEP_INDEX} form={form} stepTitle="Иные документы" {...props}>
            <div className="formRow">
                <UploadInputForm
                    className="formComponentItem"
                    name="attachments"
                    label="Прилагаемые документы"
                    uneditable={isEdit}
                    title="Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                    maxSizeMb={5}
                    maxCount={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                />
            </div>
        </Step>
    )
}
