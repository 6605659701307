import { DocumentsEnum, beforeEmitmentStatuses } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { ApproveIssueAction } from './Actions/ApproveIssueAction'
import { CancellationIssueAction } from './Actions/CancellationIssueAction'
import { ConfirmToIssuerAction } from './Actions/ConfirmToIssuerAction'
import { FailedIssueAction } from './Actions/FailedIssueAction'
import { PausedIssueAction } from './Actions/PausedIssueAction'
import { PublicationDfaAction } from './Actions/PublicationDfaAction'
import { RejectApplicationAction } from './Actions/RejectApplicationAction'
import { RejectIssueAction } from './Actions/RejectIssueAction'
import { RepaymentAction } from './Actions/RepaymentAction'
import { RepaymentMatureAction } from './Actions/RepaymentMatureAction'
import { ResumptionIssueAction } from './Actions/ResumptionIssueAction'
import { SendToEditAction } from './Actions/SendToEditAction'

export const Footer: FC = () => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const { isAllow } = useContext(RbacContext)
    const showDownLoadButton =
        dfaDetails?.id &&
        isAllow(['asset.read.all']) &&
        beforeEmitmentStatuses.includes(dfaDetails.status)
    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox
                        left={
                            showDownLoadButton ? (
                                <DownloadBtn
                                    documentType={DocumentsEnum.dfa_release_result}
                                    params={{
                                        dfa_id: dfaDetails.id,
                                        ticker: dfaDetails?.ticker_symbol,
                                    }}
                                    title="Отчёт об итогах выпуска ЦФА"
                                />
                            ) : undefined
                        }
                    >
                        <RejectIssueAction />
                        <RejectApplicationAction />
                        <SendToEditAction />
                        <ConfirmToIssuerAction />
                        <PausedIssueAction />
                        <FailedIssueAction />
                        <ApproveIssueAction />
                        <PublicationDfaAction />
                        <CancellationIssueAction />
                        <ResumptionIssueAction />
                        <RepaymentAction />
                        <RepaymentMatureAction />
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
