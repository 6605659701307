import { createSlice } from '@reduxjs/toolkit'

import { initialState } from '../resources'

const orderDetailsSlice = createSlice({
    name: 'orderDetails',
    initialState,
    reducers: {
        getOrderDetails: (state) => {
            return state
        },
        updateOrderDetails(state, action) {
            const data = action.payload
            data.Sum = data.CPE && data.Amount ? data.CPE * data.Amount : 0
            return { ...state, ...data }
        },
        dropDfaDetails() {
            return { ...initialState }
        },
        setOrderError(state, action) {
            state.error = action.payload
        },
    },
})

export const { updateOrderDetails, getOrderDetails, dropDfaDetails, setOrderError } =
    orderDetailsSlice.actions
export default orderDetailsSlice.reducer
