import {
    DocumentsEnum,
    UnionDealFront,
    isEmissionDeal,
    isRepaymentDeal,
    isSecondaryDeal,
    isTransferDeal,
} from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Table } from '@dltru/dfa-ui'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'
import { getTableData } from '@store/wallet/history/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'
import {
    emissionModal,
    repaymentModal,
    secondaryModal,
    transferModal,
} from '@components/Modals/Deals'

import { gridConfig } from './historyGridConfig'
import { transferV2Modal } from './modals/TransferV2Modal'

const showDealViewModal = async (deal: UnionDealFront, user_uuid?: string) => {
    if (isEmissionDeal(deal)) {
        return emissionModal({ deal })
    }
    if (isSecondaryDeal(deal)) {
        return secondaryModal({ deal })
    }
    if (isRepaymentDeal(deal)) {
        return repaymentModal({ deal })
    }

    if (isTransferDeal(deal)) {
        return transferModal({ deal })
    }

    if (deal.type === 'transferV2' && user_uuid) {
        transferV2Modal({ uuid: deal.uuid, user_uuid })
    }
}

export const DealsTable: FC = () => {
    const historyData = useSelector(getTableData)
    const currentUserId = useSelector(clientsSelector.selectCurrentClientId)
    const { isAllow } = useContext(RbacContext)

    const onRow = (record: UnionDealFront) => ({
        onClick: () => {
            showDealViewModal(record, currentUserId)
        },
    })

    const showDownLoadButton = isAllow(['deal.read.all'])

    return (
        <Table
            isLoading={historyData.loading}
            columns={gridConfig}
            dataSource={historyData.Rows}
            scroll={{ y: undefined }}
            onRow={onRow}
            clickableRow
            rowKey={(record: UnionDealFront) => `${record.id}${record.type}`}
            footerLeft={
                showDownLoadButton ? (
                    <DownloadBtn
                        documentType={DocumentsEnum.user_dfa_operations_history}
                        params={{ user_id: currentUserId }}
                        title="Скачать историю операций"
                    />
                ) : undefined
            }
        />
    )
}
