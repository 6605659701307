import {
    ICursorPaginationResponse,
    IDfa,
    IGetTariffAssetParams,
    ITariffAssetLink,
    TablePaginationParam,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE } from '@dltru/dfa-ui'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: Partial<ICursorPaginationResponse<ITariffAssetLink>>
    params: TablePaginationParam
    error: string
}
const initialState: IInitialState = {
    isLoading: false,
    error: '',
    data: {},
    params: { limit: DEFAULT_PER_PAGE },
}

export const tariffRelatedAssetsSlice = createSlice({
    name: 'tariffRelatedAssets',
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.data.items = action.payload.items
            state.data.paging = action.payload.paging
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        clearItems: (state) => {
            state.error = ''
            state.isLoading = false
            state.data = {}
            state.params = { limit: DEFAULT_PER_PAGE }
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function, unused-imports/no-unused-vars
        getRelatedAssets: (_, action: PayloadAction<IGetTariffAssetParams>) => {},
        linkAssets: (
            _,
            // eslint-disable-next-line unused-imports/no-unused-vars
            action: PayloadAction<{ assets: IDfa[]; tariff_id: string }>,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
        ) => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function, unused-imports/no-unused-vars
        unlinkAssets: (_, action: PayloadAction<ITariffAssetLink[]>) => {},
    },
})

export default tariffRelatedAssetsSlice.reducer
