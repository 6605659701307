import { ProfileStatus, UserRolesByScope } from '@dltru/dfa-models'
import { QualificationInfo } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import ActionModal from './ActionModal'
import { authSelector } from '@store/auth/selectors'

export enum ActionModalType {
    add = 'add',
    delete = 'delete',
}

export const QualifiedBlock: FC = () => {
    //const { isAllow } = useContext(RbacContext)
    const role = useSelector(authSelector.selectRole)
    const statusProfile = useSelector(clientsSelector.selectCurrentStatus)
    const profileTypes = useSelector(clientsSelector.selectCurrentClientTypes)
    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const qualifyInfo = useSelector(clientsSelector.selectQualifyInfoCurrentClient)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (currentClientId) {
            reduxDispatch(clientsSlice.actions.getQualifyInfo(currentClientId))
        }
    }, [currentClientId])

    const title =
        (role===UserRolesByScope.INFOSEC||role===UserRolesByScope.LAWYER) && qualifyInfo?.is_qualified
            ? 'Изменить'
            : undefined

    const actionModalOpen = () => {
        setIsModalVisible(true)
    }
    return (
        <>
            <QualificationInfo
                qualifyInfo={qualifyInfo}
                actionTitle={title}
                action={statusProfile === ProfileStatus.APPROVED ? actionModalOpen : undefined}
                isAdmin
                profileType={profileTypes?.[0]}
            />
            <ActionModal
                type={ActionModalType.delete}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
