import { DfaStatusEnum, IDfaFront } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { AccessibilityStep, IStep, IStepper, StepperC } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { InvestorsBlock } from './InvestorsBlock/InvestorsBlock'
import { Step1 } from './Step1'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'

type ComponentProps = Omit<IStepper<IDfaFront>, 'children'> & Pick<IStep, 'saveAfterEdit'>
export const DFADetailsStepper: FC<ComponentProps> = ({
    saveAfterEdit,
    initialValues,
    ...props
}) => {
    const { isAllow } = useContext(RbacContext)
    const allowEditStepsOne =
        isAllow(['asset.write.all']) &&
        (initialValues.status === DfaStatusEnum.preliminary_check ||
            initialValues.status === DfaStatusEnum.teller_approval)

    const allowEditStepsTwo =
        initialValues.status === DfaStatusEnum.preliminary_check && isAllow(['asset.write.all'])

    return (
        <StepperC {...props} initialValues={{ ...initialValues }}>
            <Step1 allowEdit={allowEditStepsOne} saveAfterEdit={saveAfterEdit} />
            <AccessibilityStep
                isModification={allowEditStepsTwo}
                saveAfterEdit={saveAfterEdit}
                CustomInvestorsBlock={InvestorsBlock}
            />
            <Step3 />
            <Step4 />
            <Step5 />
        </StepperC>
    )
}
