import { FC } from 'react'

import { WrappedInput, WrappedInputProps } from '../Input'

export const IntegerInput: FC<WrappedInputProps> = (props) => {
    return (
        <WrappedInput
            {...props}
            currencyProps={{
                decimalScale: 0,
                allowNegative: false,
                thousandSeparator: ' ',
            }}
        />
    )
}
