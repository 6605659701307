import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const MasterchainLogo: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="56"
                height="28"
                viewBox="0 0 56 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="14" cy="14" r="13" stroke="#000514" strokeWidth="2" />
                <rect x="28" width="28" height="28" fill="#000514" />
            </svg>
        )}
        {...props}
    />
)
