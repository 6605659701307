export const palette = {
    'layout-bg': '#E2EAF1',
    brand: '#446CAA',

    // palette
    'primary-1': '#E9F0F7',
    'primary-2': '#CCDDF0',
    'primary-3': '#A4BEDE',
    'primary-4': '#799ED1',
    'primary-5': '#527EC4',
    'primary-6': '#305FB7',
    'primary-7': '#1F4391',
    'primary-8': '#112B6B',
    'primary-9': '#081745',
    'primary-10': '#03091F',
    'grey-1': '#FFFFFF',
    'grey-2': '#FAFAFA',
    'grey-3': '#F5F5F5',
    'grey-4': '#F0F0F0',
    'grey-5': '#D9D9D9',
    'grey-6': '#BFBFBF',
    'grey-7': '#8C8C8C',
    'grey-8': '#595959',
    'grey-9': '#434343',
    'grey-10': '#262626',
    'grey-11': '#1F1F1F',
    'grey-12': '#141414',
    'grey-13': '#000000',
    'red-1': '#FFF1F0',
    'red-2': '#FFCCC7',
    'red-3': '#FFA39E',
    'red-4': '#FF7875',
    'red-5': '#FF4D4F',
    'red-6': '#F5222D',
    'red-7': '#CF1322',
    'red-8': '#A8071A',
    'red-9': '#820014',
    'red-10': '#5C0011',
    'green-1': '#F6FFED',
    'green-2': '#D9F7BE',
    'green-3': '#B7EB8F',
    'green-4': '#95DE64',
    'green-5': '#73D13D',
    'green-6': '#52C41A',
    'green-7': '#389E0D',
    'green-8': '#237804',
    'green-9': '#135200',
    'green-10': '#092B00',
    'orange-1': '#FFF7E6',
    'orange-2': '#FFE7BA',
    'orange-3': '#FFD591',
    'orange-4': '#FFC069',
    'orange-5': '#FFA940',
    'orange-6': '#FA8C16',
    'orange-7': '#D46B08',
    'orange-8': '#AD4E00',
    'orange-9': '#873800',
    'orange-10': '#612500',
    'lime-1': '#FCFFE6',
    'lime-2': '#F4FFB8',
    'lime-3': '#EAFF8F',
    'lime-4': '#D3F261',
    'lime-5': '#BAE637',
    'lime-6': '#A0D911',
    'lime-7': '#7CB305',
    'lime-8': '#5B8C00',
    'lime-9': '#3F6600',
    'lime-10': '#254000',
    'blue-1': '#E6F7FF',
    'blue-2': '#BAE7FF',
    'blue-3': '#91D5FF',
    'blue-4': '#69C0FF',
    'blue-5': '#40A9FF',
    'blue-6': '#1890FF',
    'blue-7': '#096DD9',
    'blue-8': '#0050B3',
    'blue-9': '#003A8C',
    'blue-10': '#002766',
}
