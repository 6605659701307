import { Box, Button, MiniModal, ModalProps, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { clientsSlice } from '@store/clients'

import './style.less'

interface IComponentProps extends Pick<ModalProps, 'visible' | 'onCancel'> {
    currentUserUuid: string
}
export const ChangeLoginPasswordModal: FC<IComponentProps> = ({
    visible,
    onCancel,
    currentUserUuid,
}) => {
    const reduxDispatch = useDispatch()

    const sendEmailForChange = (e: React.MouseEvent<HTMLElement>) => {
        if (currentUserUuid) {
            reduxDispatch(clientsSlice.actions.changeLogin(currentUserUuid))
            onCancel?.(e)
        }
    }

    return (
        <MiniModal
            width={423}
            visible={visible}
            onCancel={onCancel}
            title={
                <Space size={16}>
                    <QuestionCircle className="auth-modal__gray-icon" />
                    Сбросить логин и пароль
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={sendEmailForChange}>
                    Да, сбросить
                </Button>,
            ]}
        >
            <Box margin={[0, 0, 0, 40]}>
                <Space direction="vertical" size={18}>
                    <div>
                        На электронную почту пользователя будет отправлено письмо со ссылкой для
                        установления нового логина и пароля.
                    </div>
                    <div>Вы действительно хотите сбросить логин и пароль пользователя?</div>
                </Space>
            </Box>
        </MiniModal>
    )
}
