import { IClientBlockListItem } from '@dltru/dfa-models'
import { Box, ColorModal, DateTime, Divider, EMPTY_FIELD_VALUE, Export, Space } from '@dltru/dfa-ui'
import cn from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { UneditableUploadedFiles } from '@components/Form'

import { eventTypeMapper } from '../../utils'
import style from './style.m.less'

export interface IDealDetailModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    blockInfo?: IClientBlockListItem
}

export const BlocksDetailModal: FC<IDealDetailModal> = ({
    isModalVisible,
    setIsModalVisible,
    blockInfo = {} as IClientBlockListItem,
}) => {
    const isUnBlock =
        blockInfo.event_type === 'full_unblock' || blockInfo.event_type === 'partly_unblock'

    const Header = (
        <Space direction="vertical">
            <h3 className={style.headerSmallTitle}>
                {eventTypeMapper(blockInfo.event_type) ?? EMPTY_FIELD_VALUE}
            </h3>
            <h3 className={style.headerTitle}>{blockInfo.user_full_name ?? EMPTY_FIELD_VALUE}</h3>
            <Link
                to={`/clients/${blockInfo.user_uuid}`}
                target="_blank"
                className={style.headerLink}
            >
                Перейти в профиль <Export />
            </Link>
        </Space>
    )
    const Body = (
        <>
            <h4 className={style.headerBody}>Детали {isUnBlock ? 'снятия ' : ''}блокирования</h4>
            <Box padding={[0, 16]}>
                <div className={cn(style.infoRow, style.secondaryText)}>
                    <div>Дата и время {isUnBlock ? 'снятия ' : ''}блокирования</div>
                    <div>
                        {blockInfo.created_at ? (
                            <DateTime value={blockInfo.created_at} />
                        ) : (
                            EMPTY_FIELD_VALUE
                        )}
                    </div>
                </div>
                <div className={cn(style.infoRow, style.secondaryText)}>
                    <div>ФИО Специалиста</div>
                    <div>
                        {blockInfo.employee_full_name ||
                            blockInfo.operator_uuid ||
                            EMPTY_FIELD_VALUE}
                    </div>
                </div>
                {blockInfo.block_link_uuid?.length > 0 && (
                    <div className={cn(style.infoRow, style.secondaryText)}>
                        <div>Файлы основания {isUnBlock ? 'снятия ' : ''}блокирования</div>
                        <div className={style.fitBlock}>
                            <UneditableUploadedFiles
                                files={blockInfo.block_link_uuid.map((uid) => ({
                                    uid,
                                }))}
                            />
                        </div>
                    </div>
                )}

                <Divider margin={[16, 0]} />

                <Space direction="vertical">
                    <div className={style.secondaryText}>
                        Основание {isUnBlock ? 'снятия ' : ''}блокирования
                    </div>
                    <div className={style.boldText}>
                        {blockInfo.block_reason ?? EMPTY_FIELD_VALUE}
                    </div>
                </Space>
            </Box>
        </>
    )
    return (
        <ColorModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            header={Header}
            body={Body}
            width={540}
            headerColor={isUnBlock ? 'gray' : 'red'}
        />
    )
}
