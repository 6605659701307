import { FC } from 'react'

import { Box } from '../../../Box'
import { InfoCircleOutline, QuestionCircle } from '../../../Icons'
import { Space } from '../../../Space'
import { Tooltip } from '../../../Tooltip'
import { Button } from '../../../button'
import { MiniModal, ModalProps } from '../../../components/Modal'
import style from './style.m.less'

interface ErrorModalProps extends ModalProps {
    onOk?: () => void
}

export const CheckEmailModal: FC<ErrorModalProps> = ({ onOk, ...rest }) => {
    return (
        <MiniModal centered {...rest} width={423}>
            <Space size={16}>
                <InfoCircleOutline className={style.iconBlue} />
                <div className={style.bodyText16}>Проверьте почту</div>
            </Space>
            <Box margin={[8, 40, 24, 40]}>
                <Space direction="vertical">
                    <div className={style.bodyText14}>
                        Мы отправили вам письмо со ссылкой на создание пароля.
                    </div>
                    <Tooltip
                        title={
                            <>
                                <span>Не получили письмо?</span>
                                <br />
                                <ol className={style.tooltipOrderedList}>
                                    <li>
                                        Убедитесь, что на указанный адрес электронной почты
                                        доставляются письма или проверьте папку Спам.
                                    </li>
                                    <li>
                                        Проверьте, не блокируются ли письма с подтверждением вашей
                                        почтовой службой.
                                    </li>
                                </ol>
                            </>
                        }
                        placement="top"
                        overlayInnerStyle={{
                            width: 390,
                        }}
                    >
                        <Space className="link-color">
                            <span>Я не получил(а) письмо</span>
                            <QuestionCircle className={style.iconAlign} />
                        </Space>
                    </Tooltip>
                </Space>
            </Box>
            <Box direction="row" justify="right">
                <Button borderRadius={12} type="primary" onClick={() => onOk?.()}>
                    OK
                </Button>
            </Box>
        </MiniModal>
    )
}
