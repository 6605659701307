import {
    IInvestorRegistryApplicationPost,
    IInvestorRegistryApplicationPut,
    InvestorRegistryApplication,
    InvestorRegistryApplicationEvent,
    Paging,
    TablePaginationParam,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE } from '@dltru/dfa-ui'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

interface IQualifyStatement {
    isLoading: boolean
    error: string | null
    items: InvestorRegistryApplication[]
    currentData?: InvestorRegistryApplication
    events: InvestorRegistryApplicationEvent[]
    paging: Paging | undefined
    limit: number
    filters?: unknown
}

const initialState: IQualifyStatement = {
    isLoading: false,
    error: null,
    items: [],
    events: [],
    filters: {},
    paging: undefined,
    limit: DEFAULT_PER_PAGE,
}

export const qualifyStatementSlice = createSlice({
    name: 'qualifyStatement',
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setData(state, action: PayloadAction<Partial<IQualifyStatement>>) {
            state.isLoading = false
            state.error = null
            state.items = action.payload.items || []
            state.paging = action.payload.paging
        },
        clearData(state) {
            state.error = null
            state.items = []
        },
        setEvents(state, action: PayloadAction<InvestorRegistryApplicationEvent[]>) {
            state.events = action.payload
        },
        setCurrentData(state, action: PayloadAction<InvestorRegistryApplication>) {
            state.isLoading = false
            state.error = null
            state.currentData = action.payload
        },
        clearCurrentDataAndEvents(state) {
            state.error = null
            state.currentData = undefined
            state.events = []
        },
    },
})

export const qualifyStatementRequestQualify = createAction<IInvestorRegistryApplicationPost>(
    'register/qualifyStatementRequestQualify',
)
export const getCurrentStatementQualify = createAction<string>(
    'register/getCurrentStatementQualify',
)
export const getStatementQualify = createAction<TablePaginationParam>(
    'register/getStatementQualify',
)
export const getStatementQualifyEvents = createAction<string>('register/getStatementQualifyEvents')
export const putStatementRequestQualify = createAction<IInvestorRegistryApplicationPut>(
    'register/putStatementRequestQualify',
)

export default qualifyStatementSlice.reducer
