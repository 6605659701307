import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    CurrencyInput,
    Divider,
    Form,
    Modal,
    WrappedInput,
    commonValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeDFAInjunction } from '@store/blocking'
import { blockingSelector } from '@store/blocking/selectors'
import IAppState from '@store/states'

import { DisableWrapper } from '@components/Form/components/wrappers'
import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

interface IDFAInjunctionModal {
    onClose: () => void
    visible: boolean
}

export const DFAInjunctionModal: FC<IDFAInjunctionModal> = ({ onClose, visible }) => {
    const { userUuid, assetId, modalTitle, activeDFAs } = useSelector(
        blockingSelector.selectDataForModal,
    )
    const uuid = useSelector((store: IAppState) => store.auth.uuid)
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()

    const onSelectCertificateHandler = (certificate: CertificateType) => {
        const values = form.getFieldsValue()
        reduxDispatch(
            makeDFAInjunction({
                user_uuid: userUuid,
                amount_dfa: isCheckboxDisabled ? Number(activeDFAs) : Number(values.dfa_count),
                asset_id: assetId,
                executor_uuid: uuid,
                attachment_uuid: values.approval_document_file_uuid[0].uid,
                description: values.approval_document_comment,
                skid: certificate.skid,
            }),
        )
        onClose()
    }

    return (
        <Modal
            title="Блокировка ЦФА в кошельке пользователя"
            visible={visible}
            onCancel={onClose}
            closable
            footer={[
                <Button borderRadius={12} onClick={onClose}>
                    Отмена
                </Button>,
                <Button type="danger" borderRadius={12} onClick={form.submit}>
                    Заблокировать ЦФА
                </Button>,
            ]}
        >
            <Form
                form={form}
                initialValues={{ dfa_count: String(activeDFAs) }}
                onFinish={() => {
                    setIsVisibleCertificateModal(true)
                }}
            >
                <Box>
                    <span>Будут заблокированы любые операции для указанного количества ЦФА</span>
                </Box>
                <Box padding={[8, 0, 0, 0]}>
                    <span className="blue-span">{modalTitle}</span>
                </Box>
                <Divider />
                <span>Количество блокируемых ЦФА</span>
                <Box direction="row" justify="left" align="center">
                    <DisableWrapper
                        checkboxLabel="Все ЦФА данной позиции в кошельке пользователя"
                        defaultDisabled
                        required
                        name="dfa_count"
                        onDisable={(state) => setIsCheckboxDisabled(state)}
                    >
                        <CurrencyInput
                            placeholder={`Макcимум ${activeDFAs}`}
                            name="dfa_count"
                            required
                            rules={[{ validator: commonValidators.positive() }]}
                        />
                    </DisableWrapper>
                </Box>
                <Divider />
                <div className="ant-modal-title">Основания блокировки</div>
                <Box direction="column" margin={[24, 0, 0, 0]}>
                    <UploadDocumentContainerWrapper
                        name="approval_document_file_uuid"
                        title="Загрузите фотографии или сканы документов"
                        required
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="approval_document_comment"
                            multiline
                            required
                            maxLength={200}
                        />
                    </UploadDocumentContainerWrapper>
                </Box>
            </Form>

            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSelectCertificateHandler}
            />
        </Modal>
    )
}
