/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {  RbacContext } from '@dltru/dfa-rbac'
import {
     Button,
     CONTENT_FOOTER_ID,
     ContentFooterBox,
     DeleteOutlined,
     Portal,
} from '@dltru/dfa-ui'
import {  FC,  useContext,  useState } from 'react'
import {  useSelector } from 'react-redux'

import {  nominalAccountsSelector } from '@store/nominalAccounts/selectors'

import {  RemoveNominalAccount } from './RemoveNominalAccount'

export const Footer: FC = () => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const data = useSelector(nominalAccountsSelector.selectCurrentItem)
    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
    const { isAllow } = useContext(RbacContext)

    const showBtn = data && isAllow(['nominal_account.write.all'])

    if (!showBtn) {
        return null
    }

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {/*<Button
                            icon={<DeleteOutlined />}
                            borderRadius={12}
                            onClick={() => setIsRemoveModalVisible(true)}
                        >
                            Удалить счёт
            </Button>*/}
                    </ContentFooterBox>
                </Portal>
            )}
            <RemoveNominalAccount
                isModalVisible={isRemoveModalVisible}
                setIsModalVisible={setIsRemoveModalVisible}
            />
        </>
    )
}
