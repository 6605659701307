import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ProfileAdd: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.6667 16.6667H11.6667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1667 19.1667V14.1667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.1333 9.05832C10.05 9.04999 9.95 9.04999 9.85833 9.05832C7.875 8.99166 6.3 7.36666 6.3 5.36666C6.29167 3.32499 7.95 1.66666 9.99167 1.66666C12.0333 1.66666 13.6917 3.32499 13.6917 5.36666C13.6917 7.36666 12.1083 8.99166 10.1333 9.05832Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.99167 18.175C8.475 18.175 6.96667 17.7917 5.81667 17.025C3.8 15.675 3.8 13.475 5.81667 12.1333C8.10833 10.6 11.8667 10.6 14.1583 12.1333"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
