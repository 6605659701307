import { CertificateType, finalDfaStatuses } from '@dltru/dfa-models'
import {
    Alert,
    Box,
    Button,
    CheckUl,
    Divider,
    Form,
    Space,
    UploadFile,
    WrappedInput,
} from '@dltru/dfa-ui'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import api from '@services/api'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

import style from './style.m.less'

interface IComponentProps {
    setVisible: (visible: boolean) => void
}

type FormValues = {
    block_link_uuid: UploadFile[]
    reason?: string // TODO не отправлеятся т.к некуда
}
export const RemoveUserModalBody: FC<IComponentProps> = ({ setVisible }) => {
    const [form] = Form.useForm<FormValues>()
    const reduxDispatch = useDispatch()
    const user_uuid = useSelector(clientsSelector.selectCurrentClientId) as string
    const account = useSelector(clientsSelector.selectBalanceInfo)
    const isEmitter = useSelector(clientsSelector.isEmitter)
    const isCheckBlocked = useSelector(clientsSelector.selectIsCurrentClientBlocked)
    const [isFormComplete, setIsFormComplete] = useState(false)
    const [isCheckWallet, setIsCheckWallet] = useState(false)
    const [isCheckEmitter, setIsCheckEmitter] = useState(!isEmitter)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const isMount = useRef<boolean>()

    useEffect(() => {
        const checkWallet = async () => {
            const wallet = await api.lib.getWalletDfaBalances(user_uuid)
            if (isMount.current) {
                const walletData = wallet?.data?.items?.[0]
                setIsCheckWallet(
                    wallet?.data?.items === null ||
                        (walletData !== undefined &&
                            walletData.active_amount_dfa === 0 &&
                            walletData.blocked_amount_dfa === 0 &&
                            walletData.injunction_amount_dfa === 0),
                )
            }
        }
        const checkEmitter = async () => {
            const dfas = await api.lib.getDfaList({ emitter_id: user_uuid, limit: 9000 })
            if (isMount.current) {
                const notRedeemedDfa = dfas?.data?.items?.some(
                    ({ status }) => !finalDfaStatuses.includes(status),
                )
                setIsCheckEmitter(dfas.data === null || notRedeemedDfa === false)
            }
        }

        checkWallet()
        if (isEmitter) {
            checkEmitter()
        }
    }, [user_uuid, isEmitter])

    useEffect(() => {
        isMount.current = true
        reduxDispatch(clientsSlice.actions.getMoneyAccount(user_uuid))
        return () => {
            isMount.current = false
        }
    }, [])

    const hide = () => {
        setVisible(false)
        form.resetFields()
    }
    const onSelectCertificateHandler = (certificate: CertificateType) => {
        const values = form.getFieldsValue()
        if (user_uuid) {
            reduxDispatch(
                clientsSlice.actions.terminationUser({
                    user_uuid,
                    block_link_uuid: values.block_link_uuid.map(({ uid }) => uid).join(),
                    skid: certificate.skid,
                }),
            )
            hide()
        }
    }

    const alertDescription = (
        <div>
            Пользователь получит уведомление о том, что он будет исключен из реестра пользователей
            через 10 рабочих дней. <br />
            После исключения из реестра пользователей доступ Пользователя к Платформе будет
            прекращен и это действие нельзя будет отменить.
        </div>
    )

    const checkItems = [
        {
            title: 'на Кошельке Пользователя не должно быть непогашенных ЦФА,',
            isCheck: isCheckWallet,
        },
        {
            title: 'у Пользователя не должно быть неисполненных обязательств по выпущенным ЦФА,',
            isCheck: isCheckEmitter,
        },
        {
            title: 'в отношении Пользователя должно быть введено полное или частичное Блокирование,',
            isCheck: !isCheckBlocked,
        },
        {
            title: 'на лицевом счете Пользователя не должно быть денежных средств.',
            isCheck: account?.balance.available === 0,
        },
    ]

    const disableBtn = !isFormComplete || checkItems.some(({ isCheck }) => !isCheck)

    const onChange = (files?: UploadFile[]) => {
        setIsFormComplete(Boolean(files && files.length > 0))
    }

    return (
        <>
            <div>
                Для исключения Пользователя из реестра пользователей должны быть соблюдены следующие
                условия:
                <Box margin={[16, 0, 24, 0]}>
                    <CheckUl items={checkItems} />
                </Box>
            </div>
            <Alert type="warning" showIcon description={alertDescription} />
            <Divider margin={[24, 0]} />
            <Form form={form} layout="vertical" onFinish={() => setIsVisibleCertificateModal(true)}>
                <h4 className={style.modalTitle}>Основание исключения из реестра пользователей</h4>
                <UploadDocumentContainerWrapper
                    name="block_link_uuid"
                    title="Загрузите фотографии или сканы документов"
                    onChange={onChange}
                    required
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                >
                    <WrappedInput
                        className="formComponentItem"
                        placeholder="Ваш комментарий"
                        name="reason"
                        multiline
                        rows={4}
                    />
                </UploadDocumentContainerWrapper>
            </Form>
            <Box margin={[48, 0, 0, 0]} align="end">
                <Space>
                    <Button borderRadius={12} onClick={hide}>
                        Закрыть
                    </Button>
                    <Button
                        danger
                        borderRadius={12}
                        type="primary"
                        onClick={form.submit}
                        disabled={disableBtn}
                    >
                        Исключить из реестра пользователей
                    </Button>
                </Space>
            </Box>
            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSelectCertificateHandler}
            />
        </>
    )
}
