import { TransactionStatuses } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    Button,
    CONTENT_FOOTER_ID,
    ContentFooterBox,
    Exclude,
    FileAddIcon,
    Portal,
} from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { paymentOrdersSlice } from '@store/paymentOrders'
import { paymentOrdersSelector } from '@store/paymentOrders/selectors'

import { RejectPaymentOrderModal } from './components/RejectPaymentOrderModal/RejectPaymentOrderModal'

const transactionProcessTypeWithReject = [
    'debit_transfer',
    // 'issue_dfa',
    'debit_transfer_operator',
    // 'secondary_dfa',
]
const transactionStatusWithReject = [TransactionStatuses.pending, TransactionStatuses.resolving]
export const Footer: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isViewFooter = contentFooter && typeof contentFooter !== null
    const [isRejectModalShow, setIsRejectModalShow] = useState(false)

    const transactionInfo = useSelector(paymentOrdersSelector.selectCurrentTransaction)
    if (!transactionInfo) {
        return null
    }
    const isShowRejectButton =
        transactionStatusWithReject.includes(transactionInfo.status) &&
        transactionProcessTypeWithReject.includes(transactionInfo.process_type) &&
        isAllow(['order:status'])

    const isShowCreateButton =
        [
            TransactionStatuses.pending,
            TransactionStatuses.resolving,
            TransactionStatuses.payment_awaiting,
        ].includes(transactionInfo.status) &&
        !(
            transactionInfo.type === 'any_payment' &&
            transactionInfo.status !== TransactionStatuses.resolving
        ) &&
        isAllow(['payment.create.all'])

    const createPaymentOrderForCurrentTransaction = () => {
        if (transactionInfo) {
            reduxDispatch(paymentOrdersSlice.actions.createPaymentOrder(transactionInfo.uuid))
        }
    }

    return (
        <>
            {isViewFooter && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {isShowRejectButton && (
                            <Button
                                icon={<Exclude />}
                                borderRadius={12}
                                onClick={() => setIsRejectModalShow(true)}
                            >
                                Отказать в исполнении поручения
                            </Button>
                        )}
                        {isShowCreateButton && (
                            <Button
                                type="primary"
                                icon={<FileAddIcon />}
                                borderRadius={12}
                                disabled={
                                    transactionInfo.status === TransactionStatuses.payment_awaiting
                                }
                                onClick={createPaymentOrderForCurrentTransaction}
                            >
                                Сформировать платежное поручение
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
            <RejectPaymentOrderModal
                transactionUuid={transactionInfo?.uuid}
                visible={isRejectModalShow}
                setVisible={setIsRejectModalShow}
            />
        </>
    )
}
