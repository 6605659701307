import { Button, PlusCircle } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import { IDfaLink } from '../../../../store/dfa/types'
import AddLinkModal from './AddlinkModal'
import style from './style.m.less'

interface IAddLinkButton {
    onClick?: () => void
    type: keyof IDfaLink
    linkOriginForTitle: string
}

const AddLinkButton: FC<IAddLinkButton> = ({ type, linkOriginForTitle }) => {
    const [isShowAddLink, setIsShowAddLink] = useState(false)

    return (
        <>
            <Button
                className={style.addLinkButton}
                icon={<PlusCircle width="11px" height="11px" />}
                borderRadius={8}
                type="primary"
                size="small"
                onClick={() => {
                    setIsShowAddLink(true)
                }}
            >
                Добавить URL-адрес
            </Button>

            <AddLinkModal
                type={type}
                isModalVisible={isShowAddLink}
                setIsModalVisible={setIsShowAddLink}
                linkOriginForTitle={linkOriginForTitle}
            />
        </>
    )
}

export default AddLinkButton
