// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoWidget_aF6sX {\n  display: flex;\n  flex-direction: column;\n  width: 150px;\n  margin: 0 24px;\n  padding: 8px 0;\n  flex: auto;\n}\n.infoWidget_aF6sX:first-child {\n  margin: 0 24px 0 0;\n}\n.infoWidget_aF6sX .infoWidgetLabel_pwEiw {\n  font-family: SF Pro Display;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  text-align: right;\n  color: #868991;\n}\n.infoWidget_aF6sX .infoWidgetValue_Vv34z {\n  width: 100%;\n  text-align: right;\n  font-family: SF Pro Display;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.infoWidget_aF6sX .infoWidgetValue_Vv34z .special_sdmLn {\n  color: #868991;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/Balance/components/InfoWidget/style.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,UAAA;AADF;AAGE;EACE,kBAAA;AADJ;AARA;EAaI,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAjBA;EAuBI,WAAA;EACA,iBAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHJ;AA3BA;EAiCM,cAAA;AAHN","sourcesContent":["@import 'style/palette';\n\n.infoWidget {\n  display: flex;\n  flex-direction: column;\n  width: 150px;\n  margin: 0 24px;\n  padding: 8px 0;\n  flex: auto;\n\n  &:first-child {\n    margin: 0 24px 0 0;\n  }\n\n  .infoWidgetLabel {\n    font-family: SF Pro Display;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    text-align: right;\n    color: @gray-7;\n  }\n\n  .infoWidgetValue {\n    width: 100%;\n    text-align: right;\n    font-family: SF Pro Display;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n\n    .special {\n      color: @gray-7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoWidget": "infoWidget_aF6sX",
	"infoWidgetLabel": "infoWidgetLabel_pwEiw",
	"infoWidgetValue": "infoWidgetValue_Vv34z",
	"special": "special_sdmLn"
};
export default ___CSS_LOADER_EXPORT___;
