import { Tooltip as TooltipAnt, TooltipProps as TooltipPropsAntd } from 'antd'
import { FC } from 'react'

import './style.less'

export type TooltipProps = TooltipPropsAntd & {
    width?: number
}

export const Tooltip: FC<TooltipProps> = ({ width, ...restProps }) => (
    <TooltipAnt
        {...restProps}
        overlayClassName="tooltip-dfa"
        overlayStyle={{ maxWidth: width }}
        className="tooltip-dfa-block"
    />
)
