import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useState } from 'react'

import { Button, commonValidators, onlyDigits } from '../../../'
import { Box } from '../../../Box'
import { Space } from '../../../Space'
import { Form, PhoneInput, prefixSelectorFactory, WrappedInput } from '../../../components/Form'
import { AuthForm } from '../AuthForm'
import './style.less'

type FormValuesType = {
    phone: string
    code: string
}
interface IComponentProps {
    confirmCall: (code: string) => void
    sendNewPhone: (phone: string) => void
    phoneVerificationStatus?: string | null
}
export const ChangePhoneForm: FC<IComponentProps> = ({
    confirmCall,
    sendNewPhone,
    phoneVerificationStatus,
}) => {
    const [form] = Form.useForm<FormValuesType>()
    const [phoneIsComplete, setPhoneIsComplete] = useState(false)
    const [codeIsComplete, setCodeIsComplete] = useState(false)
    const [prefix, setPrefix] = useState('7')

    const onFieldsChange = (fields: FieldData[]) => {
        const phoneChanges = fields.find((item) => (item.name as string[]).includes('phone'))
        if (phoneChanges) {
            setPhoneIsComplete(Boolean(phoneChanges.touched && !phoneChanges.errors?.length))
            return
        }
        const codeChanges = fields.find((item) => (item.name as string[]).includes('code'))
        if (codeChanges) {
            setCodeIsComplete(Boolean(codeChanges.touched && !codeChanges.errors?.length))
        }
    }

    const sendNewPhoneHandler = () => {
        sendNewPhone(prefix + form.getFieldValue('phone'))
    }

    const confirmCallHandler = (values: FormValuesType) => {
        confirmCall(values.code)
    }
    return (
        <AuthForm
            title="Задайте новый номер телефона"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={confirmCallHandler}
            className="change-phone-form"
        >
            <Box margin={[0, 0, 24, 0]} className="change-phone-form__gray-text">
                Укажите новый номер телефона, на него поступит смс с кодом подтверждения. Введите
                код из смс в соответствующее поле.
            </Box>
            <Space size={16}>
                <PhoneInput
                    addonBefore={prefixSelectorFactory(prefix, setPrefix)}
                    label="Новый номер телефона"
                    name="phone"
                    required
                    maxLength={11 - prefix.length}
                    disabled={phoneVerificationStatus !== 'ready'}
                    prefix={prefix}
                />
                {phoneVerificationStatus === 'ready' && (
                    <Button
                        borderRadius={12}
                        disabled={!phoneIsComplete}
                        type="primary"
                        onClick={sendNewPhoneHandler}
                        width="122px"
                    >
                        Получить код
                    </Button>
                )}
                {phoneVerificationStatus === 'await' && (
                    <WrappedInput
                        name="code"
                        label="Код"
                        placeholder="4 цифры"
                        maxLength={4}
                        required
                        normalize={onlyDigits}
                        rules={[{ validator: commonValidators.number(4) }]}
                        className="change-phone-form__code-input"
                        size="large"
                    />
                )}
            </Space>
            <Box direction="row" justify="right" margin={[24, 0, 0, 0]}>
                <Button
                    onClick={form.submit}
                    borderRadius={16}
                    type="primary"
                    size="large"
                    disabled={!codeIsComplete}
                >
                    Сохранить номер
                </Button>
            </Box>
        </AuthForm>
    )
}
