import {
    IDfa,
    IDfaAvailable,
    IDfaAvailablePrice,
    PriceSource,
    ProfileType,
    RedemptionTypeDfa,
    XOR,
} from '@dltru/dfa-models'

import { EMPTY_FIELD_VALUE } from '../../../constants'
import { ICardModal } from './CardModal'

type BaseTypeDfa = {
    ticker_symbol: string
    id: number
    emitter_full_name?: string
    emitter_id: string
    title?: string
    redeem_price_per_dfa: number
    redeem_date: number
    balance?: { active_amount_dfa: number }
    emitter_info?: { types: ProfileType[]; short_name: string }
    available?: { original_amount_dfa: number; rest_amount_dfa: number }
} & Pick<IDfa, 'redemption_type' | 'redemption_description' | 'price_source_type'>

const dynamicRedeemPriceText =
    'Каждый ЦФА удостоверяет получение суммы\nденежных средств, определяемой в соответствии\nс решением о выпуске ЦФА'

export const getModalTradeHeaderData = <T extends BaseTypeDfa>(
    data: T,
    repurchasePercent?: number,
): ICardModal['headerData'] => {
    let caseData: XOR<{ valueInCase: number }, { percentInCase: number }> = { valueInCase: 0 }

    caseData =
        repurchasePercent === undefined
            ? {
                  valueInCase: data.balance?.active_amount_dfa ?? 0,
              }
            : {
                  percentInCase: repurchasePercent,
              }

    return {
        linkToEmission: data.ticker_symbol,
        linkToEmissionID: data.id,
        linkToInvestor: data?.emitter_full_name ?? data?.emitter_info?.short_name,
        linkToInvestorID: data.emitter_id,
        title: data.title || EMPTY_FIELD_VALUE,
        price: data.redeem_price_per_dfa,
        date: data.redeem_date,
        isDynamicPrice:
            data.price_source_type === PriceSource.dynamic ||
            data.redemption_type === RedemptionTypeDfa.flexible,
        textForRedemption:
            data.price_source_type === PriceSource.dynamic
                ? dynamicRedeemPriceText
                : data.redemption_description,
        ...caseData,
    }
}

export const getRepurchasePercent = (
    priceSourceType: PriceSource,
    available?: IDfaAvailable & IDfaAvailablePrice,
) => {
    let counterDone = 0
    let totalDone = 0
    if (priceSourceType === PriceSource.dynamic) {
        counterDone = (available?.original_price ?? 0) - (available?.rest_price ?? 0)
        totalDone = available?.original_price || 1
    } else {
        counterDone = (available?.original_amount_dfa ?? 0) - (available?.rest_amount_dfa ?? 0)
        totalDone = available?.original_amount_dfa || 1
    }
    return (counterDone / totalDone) * 100
}
