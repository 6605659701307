import clsx from 'clsx'
import { FC } from 'react'

import style from './style.m.less'

export type AlignType = 'left' | 'right' | 'center'
export type FormatType = 'money' | 'base'

export interface IInfoWidgetProps {
    label: string
    value: string
    format?: FormatType
    labelAlign?: AlignType
    valueAlign?: AlignType
    symbol?: string
    addedStyle?: React.CSSProperties
}

export const InfoWidget: FC<IInfoWidgetProps> = ({
    label,
    symbol = '₽',
    value,
    labelAlign,
    valueAlign,
    format = 'base',
    addedStyle,
}) => {
    if (format === 'money') {
        const [integer, cents] = value.split(',')
        return (
            <div className={style.infoWidget} style={addedStyle}>
                <div
                    className={clsx(style.infoWidgetLabel, {
                        [style.leftAlign]: labelAlign === 'left',
                    })}
                >
                    {label}
                </div>
                <div
                    className={clsx(style.infoWidgetValueMoney, {
                        [style.leftAlign]: valueAlign === 'left',
                    })}
                >
                    <span className={clsx(style.integer)}>{integer}</span>
                    <span className={style.cents}>{`,${cents}`}</span>
                    <span className={style.cents}>{` ${symbol}`}</span>
                </div>
            </div>
        )
    }

    return (
        <div className={style.infoWidget} style={addedStyle}>
            <div
                className={clsx(style.infoWidgetLabel, {
                    [style.leftAlign]: labelAlign === 'left',
                })}
            >
                {label}
            </div>
            <div
                className={clsx(style.infoWidgetValue, {
                    [style.leftAlign]: valueAlign === 'left',
                })}
            >
                {value}
            </div>
        </div>
    )
}
