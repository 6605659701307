import {
    CardDivider,
    FormItemProps,
    ShowHideCheckboxWrapper,
    WrappedDateItem,
    WrappedInput,
} from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadDocumentWrapper } from '@components/Form/components/wrappers'

type ComponentProps = Partial<FormItemProps> & {
    initialHasMigrationCard?: boolean
    initialHasMigrationPermit?: boolean
    group?: string
}

export const MigrationCardAndPermitBlock: FC<ComponentProps> = ({
    uneditable,
    initialHasMigrationCard,
    initialHasMigrationPermit,
    group,
    fieldKey,
}) => {
    const getNameForField = (fieldName: string) => {
        if (group) {
            return `${group}.${fieldName}`
        }
        if (typeof fieldKey === 'number') {
            return [fieldKey, fieldName]
        }
        return fieldName
    }

    return (
        <>
            <CardDivider />
            <ShowHideCheckboxWrapper
                name={getNameForField('HasMigrationCard')}
                checkboxTitle="Есть иммиграционная карта"
                uneditable={uneditable}
                initialValue={initialHasMigrationCard}
            >
                <UploadDocumentWrapper
                    name={getNameForField('MigrationCardDocuments')}
                    title="Загрузите фотографии документа"
                    noWrapper
                    noFormShift
                    uneditable={uneditable}
                >
                    <div className="vertical-inputs">
                        <div className="form-inputs-row">
                            <WrappedInput
                                className="formComponentItem"
                                style={{ width: uneditable ? 'auto' : '400px' }}
                                label="Номер"
                                name={getNameForField('MigrationCardNumber')}
                                uneditable={uneditable}
                                required
                                maxLength={100}
                            />
                        </div>
                        <div className="form-inputs-row">
                            <WrappedDateItem
                                name={getNameForField('MigrationCardArrivalDate')}
                                label="Дата начала срока пребывания в РФ"
                                style={{ width: uneditable ? 'auto' : '300px' }}
                                uneditable={uneditable}
                                required
                            />
                            <WrappedDateItem
                                label="Дата окончания срока пребывания в РФ"
                                style={{ width: uneditable ? 'auto' : '300px' }}
                                name={getNameForField('MigrationCardDepartureDate')}
                                uneditable={uneditable}
                                required
                            />
                        </div>
                    </div>
                </UploadDocumentWrapper>
            </ShowHideCheckboxWrapper>
            <CardDivider />
            <ShowHideCheckboxWrapper
                name={getNameForField('HasMigrationPermit')}
                checkboxTitle="Есть разрешение на пребывание в РФ"
                uneditable={uneditable}
                initialValue={initialHasMigrationPermit}
            >
                <UploadDocumentWrapper
                    name={getNameForField('MigrationPermitDocuments')}
                    title="Загрузите фотографии документа"
                    noWrapper
                    noFormShift
                    uneditable={uneditable}
                >
                    <div className="vertical-inputs">
                        <div className="form-inputs-row">
                            <WrappedInput
                                className="formComponentItem"
                                label="Наименование"
                                name={getNameForField('MigrationPermitName')}
                                uneditable={uneditable}
                                required
                                maxLength={100}
                            />
                        </div>
                        <div className="form-inputs-row">
                            <WrappedInput
                                className="formComponentItem"
                                label="Серия"
                                style={{ width: uneditable ? 'auto' : '140px' }}
                                name={getNameForField('MigrationPermitSeries')}
                                uneditable={uneditable}
                                required
                                maxLength={20}
                            />
                            <WrappedInput
                                className="formComponentItem"
                                label="Номер"
                                style={{ width: uneditable ? 'auto' : '180px' }}
                                name={getNameForField('MigrationPermitNumber')}
                                uneditable={uneditable}
                                required
                                maxLength={20}
                            />
                        </div>
                        <div className="form-inputs-row">
                            <WrappedDateItem
                                label="Дата начала срока пребывания в РФ"
                                style={{ width: uneditable ? 'auto' : '300px' }}
                                name={getNameForField('MigrationPermitArrivalDate')}
                                uneditable={uneditable}
                                required
                            />
                            <WrappedDateItem
                                label="Дата окончания срока пребывания в РФ"
                                style={{ width: uneditable ? 'auto' : '300px' }}
                                name={getNameForField('MigrationPermitDepartureDate')}
                                uneditable={uneditable}
                                required
                            />
                        </div>
                    </div>
                </UploadDocumentWrapper>
            </ShowHideCheckboxWrapper>
        </>
    )
}
