// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleBlock_L9Ltv {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-right: 16px;\n  font-family: 'SF Pro Display';\n}\n.titleBlock_L9Ltv h3 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.titleBlock_L9Ltv p {\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  color: #868991;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/BodyTitle/style.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;AADJ;AAJA;EAQQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADR;AAVA;EAeQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AAFR","sourcesContent":["@import '/src/assets/style/palette';\n\n.titleBlock {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-right: 16px;\n    font-family: 'SF Pro Display';\n\n    h3 {\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        color: @gray-10;\n    }\n\n    p {\n        font-weight: 400;\n        font-size: 12px;\n        line-height: 16px;\n        color: @gray-7;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBlock": "titleBlock_L9Ltv"
};
export default ___CSS_LOADER_EXPORT___;
