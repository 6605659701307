import { ProfileStatus, ProfileType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CheckIcon } from '@dltru/dfa-ui'
import React, { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'

import { ApprovedModal } from '../modals/ApprovedModal'

interface IComponentProps {
    profileType: ProfileType
    profileStatus: ProfileStatus
    profileUuid: string
}
export const SendToApprovedAction: FC<IComponentProps> = ({
    profileStatus,
    profileUuid,
    profileType,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { id } = useParams()
    const { isAllow } = useContext(RbacContext)

    const reduxDispatch = useDispatch()

    const isVisibleBtn =
        id &&
        ((profileStatus === ProfileStatus.OPERATOR_APPROVAL && isAllow(['approval.operator'])) ||
            (profileStatus === ProfileStatus.LAWYER_APPROVAL && isAllow(['approval.lawyer'])) ||
            (profileStatus === ProfileStatus.PODFT_APPROVAL && isAllow(['approval.aml'])))

    if (!isVisibleBtn) {
        return null
    }
    const changeStatus = (comment: string, isCheckLawyer: boolean) => {
        let status = ProfileStatus.PODFT_APPROVAL
        if (
            profileStatus === ProfileStatus.OPERATOR_APPROVAL &&
            (isCheckLawyer || profileType === ProfileType.LEGL)
        ) {
            status = ProfileStatus.LAWYER_APPROVAL
        } else if (
            profileStatus === ProfileStatus.PODFT_APPROVAL &&
            profileType !== ProfileType.AGNT
        ) {
            status = ProfileStatus.IDENTIFICATION
        } else if (
            profileStatus === ProfileStatus.PODFT_APPROVAL &&
            profileType === ProfileType.AGNT
        ) {
            status = ProfileStatus.APPROVED
        }

        reduxDispatch(
            clientsSlice.actions.changeClientStatus({
                profileType,
                profileUuid,
                userUuid: id,
                comment,
                status,
            }),
        )
        setIsModalVisible(false)
    }

    return (
        <>
            <Button
                type="primary"
                icon={<CheckIcon />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                Согласовать
            </Button>
            <ApprovedModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                profileStatus={profileStatus}
                profileType={profileType}
                action={changeStatus}
            />
        </>
    )
}
