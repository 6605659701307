import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { AuditIcon, Button } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { ConfirmModal } from '../Modals'

export const ApproveIssueAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const { isAllow } = useContext(RbacContext)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const showButton =
        (dfaDetails.status === DfaStatusEnum.teller_approval && isAllow(['approval.operator'])) ||
        (dfaDetails.status === DfaStatusEnum.lawyer_approval && isAllow(['approval.lawyer'])) ||
        (dfaDetails.status === DfaStatusEnum.spec_approval && isAllow(['approval.aml']))
    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<AuditIcon />}
                borderRadius={16}
                type="primary"
                onClick={() => setIsModalVisible(true)}
            >
                Допустить ЦФА к выпуску
            </Button>
            <ConfirmModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}
