import { FC } from 'react'

import style from './style.m.less'

export interface IBodyTitle {
    title: string
    id?: number | string
}

export const BodyTitle: FC<IBodyTitle> = ({ title, id }) => (
    <div className={style.titleBlock}>
        <h3>{title}</h3>
        {id && <p>ID {id}</p>}
    </div>
)
