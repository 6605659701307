import { DfaStatusEnum, IDfaOwnerBalance } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    CaseBadge,
    ColumnsType,
    DetailsAmountCell,
    DetailsAmountHeader,
    Dropdown,
    LinkCell,
    MoreOutlined,
    getFullName,
} from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavigateFunction } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { setInjunctionModal, setLiftingOfInjunctionModal, updateBlocking } from '@store/blocking'
import { clientsSelector } from '@store/clients/selectors'

import { ActionButton } from '@components/ActionButton'

import './style.less'

const DropdownMenu: FC<{
    isInjunction: boolean
    closeDropdown: () => void
    linkToOwnershipTransferParams?: Parameters<NavigateFunction>
    linkToCreateEncumbranceParams?: Parameters<NavigateFunction>
    modalTitle: string
    activeDFAs: number
    injunctionDFAs: number
    userUuid: string
    assetId: number
}> = ({
    linkToOwnershipTransferParams,
    linkToCreateEncumbranceParams,
    isInjunction,
    modalTitle,
    activeDFAs,
    injunctionDFAs,
    userUuid,
    assetId,
    closeDropdown,
}) => {
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()

    const openSpecificModal = (event: React.MouseEvent<HTMLElement>, openModal: () => void) => {
        event.preventDefault()
        reduxDispatch(
            updateBlocking({
                modalTitle,
                activeDFAs,
                injunctionDFAs,
                userUuid,
                assetId,
            }),
        )
        closeDropdown()
        openModal()
    }

    return (
        <div className="tableCellDropdown">
            {isAllow(['injunction.write.all']) && isInjunction && (
                <a
                    href="/"
                    onClick={(e) => {
                        e.stopPropagation()
                        openSpecificModal(e, () => reduxDispatch(setLiftingOfInjunctionModal(true)))
                    }}
                >
                    <div className="dropdownButton">Снять блокировку с ЦФА</div>
                </a>
            )}
            {isAllow(['injunction.write.all']) && !isInjunction && (
                <a
                    href="/"
                    onClick={(e) => {
                        e.stopPropagation()
                        openSpecificModal(e, () => reduxDispatch(setInjunctionModal(true)))
                    }}
                >
                    <div className="dropdownButton">Заблокировать ЦФА пользователя</div>
                </a>
            )}
            {isAllow(['transfer']) && linkToOwnershipTransferParams && (
                <a
                    href="/"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        navigate(...linkToOwnershipTransferParams)
                    }}
                >
                    <div className="dropdownButton">Передать права на ЦФА</div>
                </a>
            )}

            {isAllow(['encumbrance.write.all']) && linkToCreateEncumbranceParams && (
                <a
                    href="/"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        navigate(...linkToCreateEncumbranceParams)
                    }}
                >
                    <div className="dropdownButton">Наложить обременение на ЦФА</div>
                </a>
            )}
        </div>
    )
}

const ActionButtonDropdown: FC<{ record: any; currentUserId: string | undefined }> = ({
    record,
    currentUserId,
}) => {
    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const profile = useSelector(clientsSelector.selectProfile)
    const linkToOwnershipTransferParams =
        record.active_amount_dfa > 0
            ? [
                  `/offers/${record.asset_id}/ownership-transfer-request/?amount=${record.active_amount_dfa}`,
                  {
                      state: { user_id: currentUserId, user_full_name: getFullName(profile) },
                  },
              ]
            : undefined
    const linkToCreateEncumbranceParams =
        record.active_amount_dfa > 0
            ? [
                  `/offers/${record.asset_id}/encumbrance/?amount=${record.active_amount_dfa}`,
                  {
                      state: { user_id: currentUserId, user_full_name: getFullName(profile) },
                  },
              ]
            : undefined

    const [isDropdownShown, setIsDropdownShown] = useState(false)

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {currentClientId && record.dfaStatus !== DfaStatusEnum.mature && (
                <Dropdown
                    onVisibleChange={setIsDropdownShown}
                    visible={isDropdownShown}
                    overlay={
                        <DropdownMenu
                            isInjunction={record.injunction_amount_dfa > 0}
                            closeDropdown={() => setIsDropdownShown(false)}
                            linkToOwnershipTransferParams={linkToOwnershipTransferParams}
                            linkToCreateEncumbranceParams={linkToCreateEncumbranceParams}
                            modalTitle={record.asset_title}
                            activeDFAs={record.active_amount_dfa}
                            injunctionDFAs={record.injunction_amount_dfa}
                            userUuid={currentClientId}
                            assetId={record.asset_id}
                        />
                    }
                    placement="bottomLeft"
                    trigger={['click']}
                >
                    <ActionButton className="action-dropdown" onClick={(e) => e.stopPropagation()}>
                        <MoreOutlined />
                    </ActionButton>
                </Dropdown>
            )}
        </div>
    )
}

const CaseCell = (value: number) => <CaseBadge count={value.toLocaleString()} />
export const balancesGridConfig = ({
    currentUserId,
}: {
    currentUserId: string | undefined
}): ColumnsType<IDfaOwnerBalance> => [
    {
        title: 'Тикер',
        dataIndex: 'asset_ticker_symbol',
        key: 'asset_ticker_symbol',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
    },
    {
        title: 'Всего ЦФА, ед.',
        dataIndex: 'total_amount_dfa',
        key: 'total_amount_dfa',
        align: 'right',
        sorter: true,
        render: (_, record) =>
            CaseCell(
                record.active_amount_dfa +
                    record.blocked_amount_dfa +
                    record.encumbrance_amount_dfa +
                    record.injunction_amount_dfa,
            ),
    },
    {
        title: DetailsAmountHeader,
        dataIndex: 'details_amount_dfa',
        key: 'details_amount_dfa',
        render: DetailsAmountCell,
    },
    /*    // TODO: вернуть позже
        {
           title: 'Средняя цена покупки, ₽',
           dataIndex: 'price_per_dfa',
           key: 'price_per_dfa',
           align: 'right',
           sorter: true,
           render: CurrencyCell,
        },
        {
            title: 'Стоимость, ₽',
            dataIndex: 'total_price',
            key: 'total_price',
            align: 'right',
            sorter: true,
            render: CurrencyCell,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            fixed: 'right',
            render: TagCell,
        },*/
    {
        title: '',
        sorter: false,
        fixed: 'right',
        width: '54px',
        render: (_, record) => (
            <ActionButtonDropdown record={record} currentUserId={currentUserId} />
        ),
    },
]
