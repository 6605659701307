export type Paging = {
    cursors?: {
        next?: string
        prev?: string
    }
}

export enum OrderDirect {
    asc = 'asc',
    desc = 'desc',
}

export type TableOrder = {
    direct: OrderDirect
    column: string
}
