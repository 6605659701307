import { ProfileStatus, profileStatusTranslate } from '@dltru/dfa-models'

export const getAnketaTagProps = (
    status: ProfileStatus,
    isAllow?: (scopes: string[]) => boolean,
) => {
    switch (status) {
        case ProfileStatus.LAWYER_APPROVAL:
            return {
                color: isAllow?.(['approval.lawer']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.LAWYER_APPROVAL],
            }
        case ProfileStatus.OPERATOR_APPROVAL:
            return {
                color: isAllow?.(['approval.operator']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.OPERATOR_APPROVAL],
            }
        case ProfileStatus.PODFT_APPROVAL:
            return {
                color: isAllow?.(['approval.aml']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.PODFT_APPROVAL],
            }
        case ProfileStatus.IDENTIFICATION:
        case ProfileStatus.IDENTIFIED:
        case ProfileStatus.APPROVED:
            return { color: 'success', title: 'Проверена' }
        case ProfileStatus.NEW:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.NEW] }
        case ProfileStatus.DRAFT:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.DRAFT] }
        case ProfileStatus.REWORK:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.REWORK] }
        case ProfileStatus.REJECTED:
            return { color: 'error', title: profileStatusTranslate[ProfileStatus.REJECTED] }
        case ProfileStatus.ARCHIVED:
            return { color: 'error', title: profileStatusTranslate[ProfileStatus.ARCHIVED] }
        default:
            return { color: 'default', title: status }
    }
}

export const getProfileTagProps = (
    status: ProfileStatus,
    isAllow?: (scopes: string[]) => boolean,
) => {
    switch (status) {
        case ProfileStatus.LAWYER_APPROVAL:
            return {
                color: isAllow?.(['approval.lawyer']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.LAWYER_APPROVAL],
            }
        case ProfileStatus.OPERATOR_APPROVAL:
            return {
                color: isAllow?.(['approval.operator']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.OPERATOR_APPROVAL],
            }
        case ProfileStatus.PODFT_APPROVAL:
            return {
                color: isAllow?.(['approval.aml']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.PODFT_APPROVAL],
            }
        case ProfileStatus.APPROVED:
            return { color: 'success', title: profileStatusTranslate[ProfileStatus.APPROVED] }
        case ProfileStatus.NEW:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.NEW] }
        case ProfileStatus.DRAFT:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.DRAFT] }
        case ProfileStatus.REWORK:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.REWORK] }
        case ProfileStatus.REJECTED:
            return { color: 'error', title: profileStatusTranslate[ProfileStatus.REJECTED] }
        case ProfileStatus.ARCHIVED:
            return { color: 'error', title: profileStatusTranslate[ProfileStatus.ARCHIVED] }
        case ProfileStatus.IDENTIFICATION:
            return {
                color: isAllow?.(['approval.operator']) ? 'processing' : 'default',
                title: profileStatusTranslate[ProfileStatus.IDENTIFICATION],
            }
        case ProfileStatus.IDENTIFIED:
            return { color: 'default', title: profileStatusTranslate[ProfileStatus.IDENTIFIED] }
        default:
            return { color: 'default', title: status }
    }
}
