import classNames from 'classnames/bind'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Export } from '../../../Icons'
import { Space } from '../../../Space'
import style from './style.m.less'

type modalHeaderTextColor = 'default' | 'white'

interface IModalTitle {
    title: string
    color?: modalHeaderTextColor
}

interface IModalHeaderLink {
    linkText: string
    link: string
    color?: modalHeaderTextColor
    noIcon?: boolean
}

const cx = classNames.bind(style)

export const HeaderModalContainer: FC = ({ children }) => {
    return <div className={style.headerModalContainer}>{children}</div>
}

export const ModalTitle: FC<IModalTitle> = ({ title, color = 'default' }) => {
    const modaTitleClassName = cx({
        modalHeaderTitle: true,
        whiteText: color === 'white',
    })

    return <h3 className={modaTitleClassName}>{title}</h3>
}

export const ModalHeaderLink: FC<IModalHeaderLink> = ({
    link,
    linkText,
    noIcon = false,
    color = 'default',
}) => {
    const ModalHeaderLinkClassName = cx({
        modalHeaderLink: true,
        whiteText: color === 'white',
    })

    return (
        <Space direction="vertical">
            <Link to={link} className={ModalHeaderLinkClassName} target="_blank">
                <div style={{ display: 'flex' }}>
                    {linkText} {!noIcon && <Export />}
                </div>
            </Link>
        </Space>
    )
}
