import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { certificateSlice } from './index'

function* getItemsByUser({ payload }: ReturnType<typeof certificateSlice.actions.getItemsByUser>) {
    try {
        yield* put(certificateSlice.actions.setIsLoading(true))
        const { data, error } = yield* call(api.lib.getCertificates, {
            order: 'desc(id)' as const,
            limit: 100,
            user_uuid: payload,
        })

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* put(
                certificateSlice.actions.setData({
                    items: data.item.items || [],
                    paging: data.item.paging,
                }),
            )
        }
    } catch (error) {
        yield* put(certificateSlice.actions.setError(String(error)))
    } finally {
        yield* put(certificateSlice.actions.setIsLoading(false))
    }
}

function* removeUsersCertificateHandler({
    payload,
}: ReturnType<typeof certificateSlice.actions.removeCertificate>) {
    try {
        const { error } = yield* call(api.lib.removeCertificateByUid, payload.uuid)

        if (error) {
            throw error
        }
        yield* put(certificateSlice.actions.getItemsByUser(payload.user_uuid))
    } catch (error) {
        yield* put(certificateSlice.actions.setError(String(error)))
    }
}

export function* watchCertificatesSaga(): Generator<StrictEffect> {
    yield* takeLatest(certificateSlice.actions.getItemsByUser.type, getItemsByUser)
    yield* takeLatest(certificateSlice.actions.removeCertificate, removeUsersCertificateHandler)
}
