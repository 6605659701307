import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import accountSau from './accountSau'
import auth from './auth'
import blocking from './blocking'
import certificates from './certificates'
import clients from './clients'
import clientsRegistry from './clientsRegistry'
import dfaDealsList from './dfa/deals'
import dfaDetails from './dfa/details'
import dfaList from './dfa/list'
import dfaOwnersList from './dfa/owners'
import dfaOwnershipTransfer from './dfa/ownershipTransfer'
import documents from './documents'
import employee from './employee'
import exchangeOperators from './exchangeOperators'
import investorsDeals from './investorsDeals'
import investorDeal from './investorsDeals/investorDeal'
import moneyAccounts from './money/accounts'
import nominalAccounts from './nominalAccounts'
import notificationCounter from './notificationCounter'
import orderDetails from './orders/details'
import orderList from './orders/list'
import transferOrderList from './orders/transfer'
import paymentOrders from './paymentOrders'
import qualifyStatement from './qualifyStatement'
import registrationRequests from './registrationRequests'
import sagas from './sagas'
import secondaryDfaDealsList from './secondaryDfa/deals'
import secondaryDfaList from './secondaryDfa/list'
import tariff from './tariff'
import walletUserAssetsList from './wallet/assets'
import walletEncumbrance from './wallet/encumbrance'
import walletUserHistoryList from './wallet/history'
import walletLocks from './wallet/locks'
import tariffRelatedUsers from './tariffRelatedUsers'
import tariffRelatedAssets from './tariffRelatedAssets'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
    auth,
    dfaDetails,
    orderList,
    dfaList,
    dfaDealsList,
    orderDetails,
    secondaryDfaList,
    secondaryDfaDealsList,
    registrationRequests,
    employee,
    clients,
    blocking,
    dfaOwnersList,
    moneyAccounts,
    walletUserAssetsList,
    walletUserHistoryList,
    transferOrderList,
    dfaOwnershipTransfer,
    paymentOrders,
    walletLocks,
    exchangeOperators,
    clientsRegistry,
    certificates,
    walletEncumbrance,
    investorsDeals,
    investorDeal,
    notificationCounter,
    documents,
    qualifyStatement,
    nominalAccounts,
    accountSau,
    tariff,
    tariffRelatedUsers,
    tariffRelatedAssets,
})

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})
sagaMiddleware.run(sagas)

export type AppStore = typeof store
export type AppState = ReturnType<AppStore['getState']>
export default store
