import { Box } from '@dltru/dfa-ui'
import { FC } from 'react'

import './style.less'

interface IContentBox {
    type?: 'unauthorized' | 'default'
}

export const ContentBox: FC<IContentBox> = ({ children, type = 'default' }) => {
    return (
        <Box
            padding={[0, 24, 16, 24]}
            className={'contentbox ' + (type === 'unauthorized' ? 'unauthorized-contentbox' : '')}
        >
            {children}
        </Box>
    )
}
