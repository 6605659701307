import { Base64File, DownloadDocuments, IRequest } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'

export async function downloadDocuments(
    data: DownloadDocuments,
): Promise<IRequest<ResponseItem<{ file: Base64File }>>> {
    return requestDecorator.call(
        this,
        this.paths.Documents,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
