import { PaymentOrderStatuses } from '@dltru/dfa-models'

import { StatusDetails } from './commonState'

const transactionStatus: StatusDetails<PaymentOrderStatuses> = {
    [PaymentOrderStatuses.created]: { color: 'blue', title: 'Новое' },
    [PaymentOrderStatuses.rejected]: { color: 'error', title: 'Не исполнено' },
    [PaymentOrderStatuses.confirmed]: { color: 'success', title: 'Исполнено' },
}

export const getTagProps = (tag: PaymentOrderStatuses) =>
    transactionStatus[tag] ?? { color: 'default', title: tag }
