import { FC, SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import style from './style.m.less'

export interface IActionButton {
    onClick?: (event: SyntheticEvent) => void
    linkTo?: string
    className?: string
}

export const ActionButton: FC<IActionButton> = ({ onClick, linkTo, children, className }) => {
    const navigate = useNavigate()

    const clickHandler = (event: SyntheticEvent) => {
        if (linkTo) {
            navigate(linkTo)
        } else if (onClick) {
            onClick(event)
        }
    }

    return (
        <button className={style['action-button'] + ' ' + className} onClick={clickHandler}>
            {children}
        </button>
    )
}
