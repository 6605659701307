import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const FileAddIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.6914 5.63672L12.4883 1.43359C12.3711 1.31641 12.2129 1.25 12.0469 1.25H3.75C3.4043 1.25 3.125 1.5293 3.125 1.875V18.125C3.125 18.4707 3.4043 18.75 3.75 18.75H16.25C16.5957 18.75 16.875 18.4707 16.875 18.125V6.08008C16.875 5.91406 16.8086 5.75391 16.6914 5.63672ZM15.4336 6.36719H11.7578V2.69141L15.4336 6.36719ZM15.4688 17.3438H4.53125V2.65625H10.4297V6.875C10.4297 7.09256 10.5161 7.30121 10.67 7.45505C10.8238 7.60889 11.0324 7.69531 11.25 7.69531H15.4688V17.3438ZM10.625 9.21875C10.625 9.13281 10.5547 9.0625 10.4688 9.0625H9.53125C9.44531 9.0625 9.375 9.13281 9.375 9.21875V11.3281H7.26562C7.17969 11.3281 7.10938 11.3984 7.10938 11.4844V12.4219C7.10938 12.5078 7.17969 12.5781 7.26562 12.5781H9.375V14.6875C9.375 14.7734 9.44531 14.8438 9.53125 14.8438H10.4688C10.5547 14.8438 10.625 14.7734 10.625 14.6875V12.5781H12.7344C12.8203 12.5781 12.8906 12.5078 12.8906 12.4219V11.4844C12.8906 11.3984 12.8203 11.3281 12.7344 11.3281H10.625V9.21875Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
