import { Requisites, RequisitesType } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from './informationAboutUser.m.less'

interface IInformationAboutCardProps {
    title: string
    subtitle: string
    rows: RequisitesType[]
}

export const InformationAboutCard: FC<IInformationAboutCardProps> = ({ title, subtitle, rows }) => {
    return (
        <div className={style.informationAboutUserCard}>
            <h4 className={style.informationAboutUserCardTitle}>{title}</h4>
            <h4 className={style.informationAboutUserCardSubtitle}>{subtitle}</h4>
            <Requisites requisites={rows} />
        </div>
    )
}
