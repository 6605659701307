import { Button, Convert } from '@dltru/dfa-ui'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkClientForActiveSession, makeForcedEndOfSessionAction } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

export const ForcedEndOfSessionAction: FC = () => {
    const reduxDispatch = useDispatch()
    const isCurrentClientActive = useSelector(clientsSelector.selectIsCurrentClientActive)
    const currentUuid = useSelector(clientsSelector.selectCurrentClientId)

    useEffect(() => {
        if (currentUuid) {
            reduxDispatch(checkClientForActiveSession(currentUuid))
        }
    }, [currentUuid])

    if (!currentUuid) {
        return null
    }
    return (
        <Button
            icon={<Convert />}
            borderRadius={12}
            disabled={!isCurrentClientActive}
            onClick={() => {
                reduxDispatch(makeForcedEndOfSessionAction(currentUuid))
            }}
        >
            Прервать сессию
        </Button>
    )
}
