export const errorTranslates = {
    ERR_CORE_MONEY_IS_NOT_ENOUGH: 'недостаточно средств на САУ',
    ERR_CORE_INVALID_WALLET_REQUIREMENTS: 'Не соблюдены условия запуска погашения',
    ERR_CSP_CERTS_CERTIFICATE_ALREADY_EXISTS: 'Загружаемый сертификат уже используется в системе',
    ERR_MONEY_BALANCE_IS_NOT_ENOUGH: 'недостаточно средств на САУ',
    ERR_ASSETS_MISMATCH_BALANCE_ACTIVE_AMOUNT: 'недостаточно ЦФА на балансе',
    ERR_ASSETS_BALANCE_NOT_FOUND: 'отсутствуют ЦФА на балансе',
    ERR_MONEY_NOMINAL_ACCOUNT_IS_EXIST: 'Указанный банковский счёт не уникален',
    ERR_MONEY_BANK_ACCOUNT_IS_DUPLICATE: 'Указанный банковский счёт не уникален',
    ERR_MONEY_BANK_ACCOUNT_DETAILS_ARE_DUPLICATE: 'указанный банковский счёт не уникален',
    ERR_INVALID_PASSWORD: 'Не подходит пароль',
    ERR_PHONE_EXISTS: 'Телефон уже существует',
    ERR_LOGIN_EXISTS: 'Логин уже существует',
    ERR_EMAIL_EXISTS: 'Емайл уже существует',
    ERR_PARAMETERS_INVALID:
        'Дата погашения должна быть не ранее, чем через 5 рабочих дней от текущей даты',
    ERR_FAILED_TO_GET_INVESTOR: 'Инвестор не найден',
}

export type ErrorCodes = keyof typeof errorTranslates
