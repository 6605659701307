import { CertificateType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CONTENT_FOOTER_ID, ContentFooterBox, IFooter, Portal } from '@dltru/dfa-ui'
import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { walletEncumbranceSlice } from '@store/wallet/encumbrance'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { CertificateSelectionModal } from '@components/Modals/Certificate'

import { ModalEncumbranceRemove } from './ModalEncumbranceRemove'

export const Footer: FC<Pick<IFooter, 'status'>> = ({ status }) => {
    const { isAllow } = useContext(RbacContext)
    const [openRemoveEncumbrance, setOpenRemoveEncumbrance] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const navigate = useNavigate()
    const data = useSelector(encumbranceSelectors.selectCreateData)
    const reduxDispatch = useDispatch()
    const onSubscribe = (cert: CertificateType) => {
        reduxDispatch(walletEncumbranceSlice.actions.createEncumbrance(cert.skid))
    }
    useEffect(() => {
        if (data?.uuid) {
            navigate(-1)
        }
    }, [data?.uuid])
    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {status === 'precreate' && isAllow(['encumbrance.write.all']) && (
                            <Button
                                type="primary"
                                borderRadius={12}
                                onClick={() => setIsVisibleCertificateModal(true)}
                            >
                                Наложить обременение
                            </Button>
                        )}
                        {status === 'success' && isAllow(['encumbrance:status']) && (
                            <Button
                                type="primary"
                                borderRadius={12}
                                onClick={() => setOpenRemoveEncumbrance(true)}
                            >
                                Снять обременение
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
            <ModalEncumbranceRemove
                visible={openRemoveEncumbrance}
                onCancel={() => setOpenRemoveEncumbrance(false)}
            />
            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
            />
        </>
    )
}
