import { ProfileListType, ProfileStatus } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell, Tag, Tooltip } from '@dltru/dfa-ui'
import { getProfileTagProps } from '@resources/anketaStatus'

const ClientLinkWithTooltip = (value: string, record: ProfileListType) => {
    const tooltipText = (
        <>
            ИНН {record.inn} <br /> {record.full_name}
        </>
    )
    return (
        <Tooltip title={tooltipText} placement="top">
            {LinkCell(value, `/clients/${record.user_uuid}`)}
        </Tooltip>
    )
}

export const getGridConfig = (
    isAllow?: (scopes: string[]) => boolean,
): ColumnsType<ProfileListType> => [
    {
        title: 'Дата и время подачи заявки',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: DateTimeCell,
    },
    {
        title: 'Заявитель',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        ellipsis: true,
        render: ClientLinkWithTooltip,
    },
    {
        title: 'ID Заявителя',
        dataIndex: 'user_uuid',
        key: 'user_uuid',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Статус регистрации',
        dataIndex: 'profile_status',
        key: 'profile_status',
        render: (value: ProfileStatus) => {
            const tagProps = getProfileTagProps(value, isAllow)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
