import { Alert, CardField, openMessage, CertificatesListItem } from '@dltru/dfa-ui'
import React, { FC, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { certificateSlice } from '@store/certificates'
import { certificateSelectors } from '@store/certificates/selectors'
import { clientsSelector } from '@store/clients/selectors'

import { UploadCertificate } from './UploadCertificate/UploadCertificate'

export const CertificatesList: FC = () => {
    const currentUserUuid = useSelector(clientsSelector.selectCurrentClientId)
    const certificates = useSelector(certificateSelectors.selectItems)
    const isLoadingCertificate = useSelector(certificateSelectors.selectIsLoading)
    const reduxDispatch = useDispatch()

    const removeCertificateHandler = (uuid: string) => {
        if (uuid && currentUserUuid) {
            reduxDispatch(
                certificateSlice.actions.removeCertificate({ uuid, user_uuid: currentUserUuid }),
            )
        }
    }

    useLayoutEffect(() => {
        if (currentUserUuid) {
            reduxDispatch(certificateSlice.actions.getItemsByUser(currentUserUuid))
        }
        return () => {
            reduxDispatch(certificateSlice.actions.clearData())
        }
    }, [currentUserUuid])

    if (isLoadingCertificate) {
        return null
    }

    if (!certificates?.length) {
        return (
            <>
                <Alert
                    description="У пользователя отсутствуют сертификаты подписи"
                    type="warning"
                    width={500}
                    style={{ marginBottom: 10 }}
                />
                <UploadCertificate
                    userUuid={currentUserUuid}
                    onAddCertificate={() => {
                        if (currentUserUuid) {
                            reduxDispatch(certificateSlice.actions.getItemsByUser(currentUserUuid))
                        }
                    }}
                    onError={() => {
                        openMessage({ type: 'error', message: 'Не удалось загрузить сертификат' })
                    }}
                />
            </>
        )
    }
    const valueComponent = (
        <>
            {certificates.map((item) => (
                <CertificatesListItem
                    certificate={item}
                    key={item.uuid}
                    removeCertificateAction={removeCertificateHandler}
                />
            ))}

            <UploadCertificate
                userUuid={currentUserUuid}
                onAddCertificate={() => {
                    if (currentUserUuid) {
                        reduxDispatch(certificateSlice.actions.getItemsByUser(currentUserUuid))
                    }
                }}
                onError={() => {
                    openMessage({ type: 'error', message: 'Не удалось загрузить сертификат' })
                }}
            />
        </>
    )
    return <CardField title="Используемые УНЭП" value={valueComponent} />
}
