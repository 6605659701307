import {
    IBusinessmanProfile,
    IIndividualProfile,
    ILegalEntityProfile,
    ProfileStatus,
    ProfileType,
    UserRoles,
    agentBackToFront,
    blockedRoles,
    businessmanBackToFront,
    individualBackToFront,
    legalEntityBackToFront,
} from '@dltru/dfa-models'
import {
    calcMainProfileStatus,
    getPathByProfileType,
    getProfileRequestType,
    groupTransactionsByDate,
} from '@dltru/dfa-ui'
import { createSelector } from '@reduxjs/toolkit'
import { getDataForApprovalHistory, getUserFullNameByType } from '@utils/profile'

import IAppState from '../states'

const selectCurrentClient = (state: IAppState) => state.clients.currentClient
const selectCurrentAuthData = (state: IAppState) => state.clients.currentClient?.authorize_data
const selectWalletDfaData = (state: IAppState) => state.clients.currentClient?.wallet_dfa_data
const selectIsCurrentClientPartlyBlocked = (state: IAppState) =>
    state.clients.currentClient?.authorize_data?.role === UserRoles.CLIENT_PARTLY_BLOCK
const selectIsCurrentClientFullyBlocked = (state: IAppState) =>
    state.clients.currentClient?.authorize_data?.role === UserRoles.CLIENT_FULL_BLOCK
const selectIsCurrentClientBlocked = createSelector(
    selectCurrentAuthData,
    (authData) => authData?.role !== undefined && blockedRoles.includes(authData.role),
)
const selectCurrentStatus = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.[
        getPathByProfileType[state.clients.currentClient.profile_data.type]
    ]?.profile_status

const selectBalanceInfo = (state: IAppState) => state.clients.currentClientAccount
const selectCurrentClientId = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.[
        getPathByProfileType[state.clients.currentClient.profile_data.type]
    ]?.user_uuid
const selectItems = (state: IAppState) => state.clients.data?.items
const selectPaging = (state: IAppState) => state.clients.data?.paging
const selectParams = (state: IAppState) => state.clients.params
const selectBlockingsList = (state: IAppState) => state.clients.blockings.data
const selectBlockings = (state: IAppState) => state.clients.blockings
const selectIsLoading = (state: IAppState) => state.clients.isLoading
const selectIsCurrentClientActive = (state: IAppState) => state.clients.isCurrentClientActive
const selectError = (state: IAppState) => state.clients.error
const selectCurrentClientTypes = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.type
        ? [state.clients.currentClient?.profile_data?.type]
        : []
const getRoleForm = createSelector(selectCurrentClientTypes, getProfileRequestType)
const selectTransactionsData = (state: IAppState) => state.clients.currentClientTransactions
const selectTransactions = createSelector(
    selectTransactionsData,
    selectCurrentClientId,
    groupTransactionsByDate,
)

const isEmitter = createSelector(
    selectCurrentClientTypes,
    (types) => types.includes(ProfileType.BUSN) || types.includes(ProfileType.LEGL),
)
const selectCurrentClientApprovalHistory = (state: IAppState) =>
    state.clients.currentClientApprovalHistory
const selectCurrentClientApprovalHistoryAgent = (state: IAppState) =>
    state.clients.currentClientApprovalHistoryRepresentative?.current ?? []
const selectOldCurrentClientApprovalHistoryAgent = (state: IAppState) =>
    state.clients.currentClientApprovalHistoryRepresentative?.old ?? []
const currentClientRegistrationDate = createSelector(
    selectCurrentClientApprovalHistory,
    (statuses) =>
        statuses.find((status) => status.profile_status === ProfileStatus.OPERATOR_APPROVAL)
            ?.approval_datetime,
)
const currentClientApplicationDate = createSelector(
    selectCurrentClientApprovalHistory,
    (statuses) =>
        statuses.find((status) => status.profile_status === ProfileStatus.APPROVED)
            ?.approval_datetime,
)
const selectProfile = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.[
        getPathByProfileType[state.clients.currentClient.profile_data.type]
    ]

const selectProfilePath = (state: IAppState) =>
    state.clients?.currentClient?.profile_data?.type
        ? getPathByProfileType[state.clients?.currentClient?.profile_data?.type]
        : ''

const selectProfileBeneficiariesUuids = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.legal_entity?.beneficial_owners_uuids ??
    state.clients.currentClient?.profile_data?.individual?.beneficiaries ??
    state.clients.currentClient?.profile_data?.businessman?.beneficial_owners_uuids

const selectProfileAgent = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.agents?.[0]

const selectMainStatus = createSelector(
    selectCurrentStatus,
    selectProfileAgent,
    (clientStatus, agentProfile) =>
        clientStatus
            ? calcMainProfileStatus(clientStatus, agentProfile?.application_status)
            : undefined,
)
const selectProfileBeneficiaries = (state: IAppState) => state.clients.profileBeneficiaries
const selectQualifyInfoCurrentClient = (state: IAppState) => state.clients.currentClientQualifyInfo
const selectIsExchangeOperator = (state: IAppState) => {
    if (state.clients.currentClient?.profile_data.type === ProfileType.BUSN) {
        return state.clients.currentClient.profile_data.businessman?.node?.is_exchange_operator
    }
    return state.clients.currentClient?.profile_data?.[
        getPathByProfileType[state.clients.currentClient.profile_data.type]
    ]?.node_and_exchange_operator?.is_exchange_operator
}

const selectIsNode = (state: IAppState) => {
    if (state.clients.currentClient?.profile_data.type === ProfileType.BUSN) {
        return state.clients.currentClient.profile_data.businessman?.node?.is_node
    }
    return state.clients.currentClient?.profile_data?.[
        getPathByProfileType[state.clients.currentClient.profile_data.type]
    ]?.node_and_exchange_operator?.is_node
}
const selectIsNodeAndExchangeOperator = (state: IAppState) => {
    if (state.clients.currentClient?.profile_data.type === ProfileType.BUSN) {
        return state.clients.currentClient.profile_data.businessman?.node
    }
    return state.clients.currentClient?.profile_data?.[
        getPathByProfileType[state.clients.currentClient.profile_data.type]
    ]?.node_and_exchange_operator
}

const selectAgentFormValue = createSelector(selectProfileAgent, (anketaFromBackend) =>
    anketaFromBackend ? agentBackToFront(anketaFromBackend) : undefined,
)

const selectIndividualFormValue = createSelector(
    selectProfile,
    selectProfileBeneficiaries,
    (anketaFromBackend, beneficialOwners) =>
        anketaFromBackend
            ? individualBackToFront(
                  anketaFromBackend as IIndividualProfile,
                  beneficialOwners ?? undefined,
              )
            : undefined,
)
const selectLegalEntityFormValue = createSelector(
    selectProfile,
    selectProfileBeneficiaries,
    (anketaFromBackend, beneficialOwners) =>
        anketaFromBackend
            ? legalEntityBackToFront(
                  anketaFromBackend as ILegalEntityProfile,
                  beneficialOwners ?? undefined,
              )
            : undefined,
)
const selectBusinessmanFormValue = createSelector(
    selectProfile,
    selectProfileBeneficiaries,
    (anketaFromBackend, beneficialOwners) =>
        anketaFromBackend
            ? businessmanBackToFront(
                  anketaFromBackend as IBusinessmanProfile,
                  beneficialOwners ?? undefined,
              )
            : undefined,
)

const denyExchangeOperatorSelector = createSelector(
    selectCurrentClientTypes,
    (types) => !(types.includes(ProfileType.BUSN) || types.includes(ProfileType.LEGL)),
)

const selectProfileTypes = (state: IAppState) =>
    state.clients.currentClient?.profile_data?.type
        ? [state.clients.currentClient?.profile_data?.type]
        : null

const userFullName = createSelector(selectProfileTypes, selectProfile, (types, profile) =>
    types && profile ? getUserFullNameByType(types[0], profile) : null,
)

const agentFullName = createSelector(selectProfileAgent, (profile) =>
    profile
        ? `${profile.person.last_name} ${profile.person.first_name} ${profile.person.second_name}`
        : null,
)
const selectApprovalHistoryUI = createSelector(
    selectCurrentClientApprovalHistory,
    selectOldCurrentClientApprovalHistoryAgent,
    selectCurrentClientApprovalHistoryAgent,
    (historyOfProfile, historyOfAgentOld, historyOfAgent) =>
        getDataForApprovalHistory(historyOfProfile, [...historyOfAgentOld, ...historyOfAgent]),
)
const selectBlacklist = (state: IAppState) => state.clients.currentClientAdditionalInfo.blacklist

const selectPrivateRisks = (state: IAppState) =>
    state.clients.currentClient?.private?.risk_levels || []

export const clientsSelector = {
    selectItems,
    selectPaging,
    selectParams,
    selectBlockingsList,
    selectBlockings,
    selectCurrentClient,
    selectCurrentStatus,
    selectBalanceInfo,
    selectIsLoading,
    selectError,
    getRoleForm,
    selectTransactions,
    isEmitter,
    selectCurrentClientId,
    currentClientRegistrationDate,
    currentClientApplicationDate,
    selectProfile,
    selectProfileAgent,
    selectIsCurrentClientBlocked,
    selectIsCurrentClientPartlyBlocked,
    selectIsCurrentClientFullyBlocked,
    selectQualifyInfoCurrentClient,
    selectCurrentAuthData,
    selectIsExchangeOperator,
    selectIsNode,
    selectIsNodeAndExchangeOperator,
    selectAgentFormValue,
    selectIndividualFormValue,
    selectLegalEntityFormValue,
    selectBusinessmanFormValue,
    denyExchangeOperatorSelector,
    selectCurrentClientTypes,
    selectProfileBeneficiariesUuids,
    selectWalletDfaData,
    selectIsCurrentClientActive,
    selectCurrentClientApprovalHistory,
    selectCurrentClientApprovalHistoryAgent,
    selectOldCurrentClientApprovalHistoryAgent,
    selectProfileTypes,
    userFullName,
    agentFullName,
    selectMainStatus,
    selectApprovalHistoryUI,
    selectBlacklist,
    selectProfilePath,
    selectPrivateRisks,
}
