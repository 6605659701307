import { Store } from 'antd/lib/form/interface'
import { FC, useContext } from 'react'

import { Edit } from '../Icons'
import { Button } from '../button'
import { Card, CardContent, CardFooter, CardHeader } from '../card'
import { Form, FormInstance, FormSpace } from '../components/Form'
import { StepButtons } from './StepButtons'
import { StepperContext } from './StepperContext'
import './style.less'

interface IValues {
    [code: string]: string | number | null | unknown
}

export interface IStep {
    stepIndex: number
    form: FormInstance<any>
    onChangeValues?: (changedValues: IValues | null) => void
    stepTitle: string
    allowEdit?: boolean
    saveAfterEdit?: boolean
    disabledHeaderStepCounter?: boolean
    preValidation?: (values?: IValues) => boolean
    makeSideEffect?: (values: IValues) => void
    backFromFirstStep?: () => void
    backCaption?: string
    footerCaption?: string
    disableNextBtn?: boolean
}

export const Step: FC<IStep> = ({
    stepIndex,
    onChangeValues,
    children,
    form,
    stepTitle,
    allowEdit,
    saveAfterEdit,
    disabledHeaderStepCounter = false,
    makeSideEffect,
    backFromFirstStep,
    preValidation,
    backCaption,
    footerCaption,
    disableNextBtn,
}) => {
    const {
        stepBack,
        stepForward,
        stepsTotal,
        currentStep,
        values,
        editable,
        setEdit,
        mergeValuesIntoState,
    } = useContext(StepperContext)

    const onChangeValuesHandler = (changedValues: Partial<typeof values>) => {
        onChangeValues && onChangeValues(changedValues)
    }

    const isEdit = editable.includes(stepIndex) && allowEdit

    const isNotCurrentStep = currentStep !== stepIndex

    const applyHandler = () => {
        form.validateFields().then(() => {
            form.submit()
            setEdit(stepIndex)
        })
    }
    const cancelHandler = () => {
        form.resetFields()
        form.setFieldsValue(values)
        setEdit(stepIndex)
    }

    const onFinish = async (stepsValues: IValues) => {
        if (makeSideEffect) {
            await makeSideEffect(stepsValues)
        }
        if (!isEdit || saveAfterEdit) {
            stepForward(stepsValues)
        } else {
            mergeValuesIntoState(stepsValues)
        }
    }

    return (
        <Form
            form={form}
            size="large"
            initialValues={values as Store}
            onFinish={onFinish}
            onValuesChange={onChangeValuesHandler}
        >
            <Card scrollIntoView={!isNotCurrentStep} uneditable={isNotCurrentStep && !isEdit}>
                <CardHeader
                    title={stepTitle}
                    stepIndex={stepIndex}
                    disabledHeaderStepCounter={disabledHeaderStepCounter}
                    stepsTotal={stepsTotal}
                    isNotCurrentStep={isNotCurrentStep}
                    className="position-relative"
                >
                    {isNotCurrentStep && allowEdit && (
                        <div style={{ position: 'absolute', right: 0 }}>
                            <Button
                                className="stepper__action--edit"
                                icon={<Edit />}
                                borderRadius={8}
                                type="link"
                                onClick={cancelHandler}
                            />
                        </div>
                    )}
                </CardHeader>
                <CardContent divider={!isNotCurrentStep || isEdit}>
                    <FormSpace uneditable={isNotCurrentStep && !isEdit}>{children}</FormSpace>
                </CardContent>
                {!isNotCurrentStep && (
                    <CardFooter>
                        <StepButtons
                            stepIndex={stepIndex}
                            currentStep={currentStep}
                            stepsTotal={stepsTotal}
                            onForward={() => {
                                if (preValidation) {
                                    const isPassPostValidation = preValidation()

                                    if (isPassPostValidation) {
                                        form.submit()
                                        return
                                    }

                                    return
                                }

                                form.submit()
                            }}
                            backFromFirstStep={backFromFirstStep}
                            onBack={() => {
                                stepBack(form.getFieldsValue(true))
                            }}
                            backCaption={backCaption}
                            info={footerCaption}
                            disableNextBtn={disableNextBtn}
                        />
                    </CardFooter>
                )}
                {isNotCurrentStep && isEdit && (
                    <CardFooter>
                        <div className="stepper__footer">
                            <Button
                                className="stepper__action"
                                onClick={cancelHandler}
                                borderRadius={12}
                            >
                                Отмена
                            </Button>
                            <Button
                                className="stepper__action"
                                onClick={applyHandler}
                                borderRadius={12}
                                type="primary"
                                disabled={disableNextBtn}
                            >
                                Применить
                            </Button>
                            {footerCaption && <div>{footerCaption}</div>}
                        </div>
                    </CardFooter>
                )}
            </Card>
        </Form>
    )
}
