import {
    Form,
    IRoleFormStepProps,
    Step,
    StepperContext,
    WrappedDateItem,
    WrappedInput,
    dateValidators,
    useEditable,
} from '@dltru/dfa-ui'
import { Moment } from 'moment'
import { FC, useContext, useState } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

const STEP_INDEX = 1

export const ConfirmingDocumentStep: FC<IRoleFormStepProps> = ({ isEditAllow }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    const [dateForValidate, setDateForValidate] = useState(values?.approval_document_issue_date)
    const onChangeValues = (changedValues: Partial<typeof values>) => {
        if (changedValues && 'approval_document_issue_date' in changedValues) {
            setDateForValidate(changedValues.approval_document_issue_date)
            if (form.getFieldValue('approval_document_expiration_date')) {
                form.validateFields(['approval_document_expiration_date'])
            }
        }
    }

    return (
        <Step
            form={form}
            onChangeValues={onChangeValues}
            stepIndex={STEP_INDEX}
            stepTitle="Документ, подтверждающий полномочия представителя"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Наименование документа"
                    name="approval_document_title"
                    uneditable={isEdit}
                    required
                    maxLength={100}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Номер документа"
                    name="approval_document_number"
                    uneditable={isEdit}
                    required
                    maxLength={50}
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Дата выдачи"
                    name="approval_document_issue_date"
                    uneditable={isEdit}
                    disableTime
                    required
                    onlyPast
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Срок действия"
                    name="approval_document_expiration_date"
                    uneditable={isEdit}
                    disableTime
                    required
                    rules={[
                        {
                            validator: dateValidators.moreDate(dateForValidate as Moment),
                        },
                    ]}
                />
            </div>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Комментарий"
                    name="approval_document_comment"
                    uneditable={isEdit}
                    multiline
                    maxLength={200}
                />
            </div>
            <div className="formRow" style={{ marginLeft: 240 }}>
                <UploadInputForm
                    name="approval_document_file_uuid"
                    uneditable={isEdit}
                    required
                    title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
        </Step>
    )
}
