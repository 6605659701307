import { ITransferOrder, ITransferParams } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppState } from '@store/index'
import { loadTransferOrdersDfa } from '@store/orders/transfer'

import { gridConfig } from '../../../Offers/components/IndividualOffersTable/gridConfig'

interface IComponentProps {
    params: Partial<ITransferParams>
}
export const IndividualOffersTable: FC<IComponentProps> = ({ params }) => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const { data, isLoading } = useSelector((store: AppState) => store.transferOrderList)

    useEffect(() => {
        reduxDispatch(loadTransferOrdersDfa(params))
    }, [])

    const onRow = (record: ITransferOrder) => ({
        onClick: () => {
            navigate(`/ownership-transfer-request/${record.global_id}`)
        },
    })

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={data}
            onRow={onRow}
            clickableRow
        />
    )
}
