const getNumEnding = (words: string[]) => (count: number) => {
    const rem = Math.abs(count) % 100
    if (rem >= 11 && rem <= 19) {
        return words[2]
    }
    switch (rem % 10) {
        case 1:
            return words[0]
        case 2:
        case 3:
        case 4:
            return words[1]
        default:
            return words[2]
    }
}

export const daysLabel = getNumEnding(['день', 'дня', 'дней'])
export const rublesLabel = getNumEnding(['рубль', 'рубля', 'рублей'])
export const commissionLabel = getNumEnding(['комиссия', 'комиссии', 'комиссий'])
