import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    CurrencyInput,
    Divider,
    Form,
    Modal,
    WrappedInput,
    commonValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeDFALiftingOfInjunction } from '@store/blocking'
import { blockingSelector } from '@store/blocking/selectors'
import IAppState from '@store/states'

import { DisableWrapper } from '@components/Form/components/wrappers'
import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

interface IDFALiftingOfInjunctionModal {
    visible: boolean
    onClose: () => void
}

export const DFALiftingOfInjunctionModal: FC<IDFALiftingOfInjunctionModal> = ({
    visible,
    onClose,
}) => {
    const { userUuid, assetId, modalTitle, injunctionDFAs } = useSelector(
        blockingSelector.selectDataForModal,
    )
    const uuid = useSelector((store: IAppState) => store.auth.uuid)
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()

    const onSelectCertificateHandler = (certificate: CertificateType) => {
        const values = form.getFieldsValue()

        reduxDispatch(
            makeDFALiftingOfInjunction({
                user_uuid: userUuid,
                amount_dfa: isCheckboxDisabled
                    ? Number(injunctionDFAs)
                    : Number(values.injunction_dfa_count),
                asset_id: assetId,
                executor_uuid: uuid,
                attachment_uuid: values.approval_document_file_uuid[0].uid,
                description: values.approval_document_comment,
                skid: certificate.skid,
            }),
        )
        onClose()
    }

    return (
        <Modal
            title="Снятие блокировки с кошелька пользователя"
            visible={visible}
            onCancel={onClose}
            closable
            footer={[
                <Button borderRadius={12} onClick={onClose}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={form.submit}>
                    Снять блокировку ЦФА
                </Button>,
            ]}
        >
            <Form
                form={form}
                initialValues={{ injunction_dfa_count: String(injunctionDFAs) }}
                onFinish={() => {
                    setIsVisibleCertificateModal(true)
                }}
            >
                <Box>
                    <span>
                        Блокировка будет снята, пользователю будут доступны операции для указанного
                        количества ЦФА
                    </span>
                </Box>
                <Box padding={[8, 0, 0, 0]}>
                    <span className="blue-span">{modalTitle}</span>
                </Box>
                <Divider />
                <span>Количество ЦФА к разблокировке</span>
                <Box direction="row" justify="left" align="center">
                    <DisableWrapper
                        checkboxLabel="Все заблокированнные ЦФА"
                        defaultDisabled
                        required
                        name="injunction_dfa_count"
                        onDisable={(state) => setIsCheckboxDisabled(state)}
                    >
                        <CurrencyInput
                            placeholder={`Макcимум ${injunctionDFAs}`}
                            name="injunction_dfa_count"
                            required
                            rules={[{ validator: commonValidators.positive() }]}
                        />
                    </DisableWrapper>
                </Box>
                <Divider />
                <div className="ant-modal-title">Основания для снятия блокировки</div>
                <Box direction="column" padding={[24, 0, 0, 0]}>
                    <UploadDocumentContainerWrapper
                        name="approval_document_file_uuid"
                        title="Загрузите фотографии или сканы документов"
                        required
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="approval_document_comment"
                            multiline
                            required
                            maxLength={200}
                        />
                    </UploadDocumentContainerWrapper>
                </Box>
            </Form>

            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSelectCertificateHandler}
            />
        </Modal>
    )
}
