import { FC } from 'react'

import { Alert } from '../../../Alert'
import { Box } from '../../../Box'
import { FullScreenModal } from '../../../components/Modal'
import style from './style.m.less'

export interface IConditionsModal {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    title?: string | React.ReactNode
    text?: string | React.ReactNode
    buyByIssuer?: boolean
    width?: number
}
const description =
    'Приобретенные вами ЦФА будут автоматически погашены через один год, если за этот период вы не перестанете ' +
    'быть их владельцем и если иное не предусмотрено Решением о выпуске ЦФА, или в дату погашения выпуска ЦФА, ' +
    'если она наступит раньше истечения указанного периода.'
export const ConditionsModal: FC<IConditionsModal> = ({
    isModalVisible,
    setIsModalVisible,
    title,
    text,
    buyByIssuer = false,
    width = 670,
}) => {
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <FullScreenModal
            visible={isModalVisible}
            onCancel={handleCancel}
            bodyStyle={{ padding: 0 }}
        >
            <div className={style.conditionModal} style={{ width }}>
                <h3 className={style.conditionModalTitle}>{title}</h3>
                {buyByIssuer && (
                    <Box margin={[0, 24, 24, 24]}>
                        <Alert
                            showIcon
                            type="warning"
                            message="Вы являетесь Эмитентом данного выпуска ЦФА"
                            description={description}
                        />
                    </Box>
                )}
                <div className={style.conditionModalBody}>{text}</div>
            </div>
        </FullScreenModal>
    )
}
