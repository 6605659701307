import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const EmptyWalletAdd: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.0335 11.2916C14.6835 11.6333 14.4835 12.125 14.5335 12.65C14.6085 13.55 15.4335 14.2083 16.3335 14.2083H17.9168V15.2C17.9168 16.925 16.5085 18.3333 14.7835 18.3333H6.35849C6.61683 18.1166 6.84183 17.85 7.01683 17.55C7.32516 17.05 7.50016 16.4583 7.50016 15.8333C7.50016 13.9916 6.0085 12.5 4.16683 12.5C3.3835 12.5 2.6585 12.775 2.0835 13.2333V9.59165C2.0835 7.86665 3.49183 6.45831 5.21683 6.45831H14.7835C16.5085 6.45831 17.9168 7.86665 17.9168 9.59165V10.7917H16.2335C15.7668 10.7917 15.3418 10.975 15.0335 11.2916Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.0835 10.3416V6.53336C2.0835 5.54169 2.69183 4.65832 3.61683 4.30832L10.2335 1.80832C11.2668 1.41666 12.3752 2.18335 12.3752 3.29168V6.45834"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.7992 11.6418V13.3585C18.7992 13.8168 18.4325 14.1918 17.9658 14.2085H16.3325C15.4325 14.2085 14.6075 13.5501 14.5325 12.6501C14.4825 12.1251 14.6825 11.6335 15.0325 11.2918C15.3408 10.9751 15.7658 10.7918 16.2325 10.7918H17.9658C18.4325 10.8085 18.7992 11.1834 18.7992 11.6418Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.8335 10H11.6668"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.50016 15.8333C7.50016 16.4583 7.32516 17.05 7.01683 17.55C6.84183 17.85 6.61683 18.1167 6.35849 18.3333C5.77516 18.8583 5.0085 19.1667 4.16683 19.1667C2.95016 19.1667 1.89183 18.5167 1.31683 17.55C1.0085 17.05 0.833496 16.4583 0.833496 15.8333C0.833496 14.7833 1.31683 13.8417 2.0835 13.2333C2.6585 12.775 3.3835 12.5 4.16683 12.5C6.0085 12.5 7.50016 13.9917 7.50016 15.8333Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.41009 15.8162H2.92676"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.16699 14.5996V17.0913"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
