import { OrderTypeEnum } from '@dltru/dfa-models'
import { FC } from 'react'

import { QuestionCircle } from '../../../Icons'
import { Button } from '../../../button'
import { MiniModal } from '../../../components/Modal'
import style from './revokeConfirm.m.less'

export interface IRevokeConfilrmModal {
    isModalVisible: boolean
    onOk: () => void
    onCancel: () => void
    type?: OrderTypeEnum
    isAdmin: boolean
}

export const RevokeConfirmModal: FC<IRevokeConfilrmModal> = ({
    isModalVisible,
    onOk,
    onCancel,
    type,
    isAdmin,
}) => {
    const handleOk = () => {
        onOk()
    }

    const handleCancel = () => {
        onCancel()
    }

    return (
        <MiniModal visible={isModalVisible} onCancel={handleCancel}>
            <div className={style.confirmModal}>
                <div className={style.confirmModalTitle}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    <h4 className="ant-modal-title">Отозвать заявку</h4>
                </div>
                <div className={style.confirmModalNote}>
                    Вы действительно хотите отозвать заявку?
                </div>
                <div className={style.confirmModalFooter}>
                    <Button borderRadius={12} onClick={handleCancel}>
                        Нет
                    </Button>
                    <Button
                        borderRadius={12}
                        type="primary"
                        onClick={() => {
                            handleOk()
                        }}
                    >
                        Да, отозвать
                    </Button>
                </div>
            </div>
        </MiniModal>
    )
}
