import { Box, Col, EMPTY_FIELD_VALUE, Row, Typography } from '@dltru/dfa-ui'
import { FC, ReactNode } from 'react'

type CardFieldProps = {
    title: ReactNode
    value?: ReactNode
    labelType?: 'secondary' | 'primary'
}

const { Text } = Typography

export const CardVerticalField: FC<CardFieldProps> = ({
    title,
    value = EMPTY_FIELD_VALUE,
    labelType = 'secondary',
}) => (
    <Box padding={[8, 0]}>
        <Row>
            <Col>
                <Text type={labelType === 'primary' ? undefined : 'secondary'}>{title}</Text>
            </Col>
        </Row>
        <Row>
            <Col>
                <Text>{value !== '' ? value : EMPTY_FIELD_VALUE}</Text>
            </Col>
        </Row>
    </Box>
)
