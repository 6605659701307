import { frontUrl } from './frontUrl'

export const CURRENT_USER_IS_FULLY_BLOCKED = 'CURRENT_USER_IS_FULLY_BLOCKED'
export const NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN = 'NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN'
export const INVALID_PASSWORD = 'INVALID_PASSWORD'
export const IS_NOT_ADMIN_ERROR = 'IS_NOT_ADMIN_ERROR'
export const LOGIN_RESPONSE_ERROR = 'LOGIN_RESPONSE_ERROR'
export const FRAUD_ATTEMPT = 'FRAUD_ATTEMPT'
export const errorsWithoutMessage = [
    CURRENT_USER_IS_FULLY_BLOCKED,
    NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN,
    INVALID_PASSWORD,
    LOGIN_RESPONSE_ERROR,
    FRAUD_ATTEMPT,
]

export const frontUrlWithLogout = [
    frontUrl.changeLogin,
    frontUrl.restorePasswordAdmin,
    frontUrl.changeEmail,
    frontUrl.changePhone,
]
