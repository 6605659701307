import { Box, CopyButton, PageHeader, Tag, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import { getTagProps } from '@resources/transactionState'
import dayjs from 'dayjs'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { paymentOrdersSlice } from '@store/paymentOrders'
import { paymentOrdersSelector } from '@store/paymentOrders/selectors'

import { Footer } from './Footer'
import { TransactionDetails } from './components/TransactionDetails'
import { TransactionPaymentOrders } from './components/TransactionPaymentOrders'

export const PaymentOrderDetails: FC = () => {
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const transactionInfo = useSelector(paymentOrdersSelector.selectCurrentTransaction)

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            reduxDispatch(paymentOrdersSlice.actions.getCurrentTransaction(id))
        }
        return () => {
            reduxDispatch(paymentOrdersSlice.actions.clearCurrentTransaction())
        }
    }, [id])

    if (!transactionInfo) {
        return null
    }

    const tagInfo = getTagProps(transactionInfo.status)

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Поручение пользователя от ${dayjs
                        .unix(transactionInfo.created_at)
                        .format(VIEW_DATE_FORMAT)}`}
                    onBack={() => navigate(-1)}
                    subTitle={
                        <>
                            ID {transactionInfo.uuid} <CopyButton text={transactionInfo.uuid} />
                        </>
                    }
                    tags={<Tag color={tagInfo.color}>{tagInfo.title}</Tag>}
                />
                <TransactionDetails />
                <TransactionPaymentOrders />
            </Box>
            <Footer />
        </>
    )
}
