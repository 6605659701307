// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unorderedListIcon_qbzhK {\n  width: 32px;\n}\n.checkedIcon__XRy1 {\n  color: #52c41a !important;\n}\n.blackIcon_lfqjl {\n  color: #000;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/setPassword/style.m.less"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,WAAA;AAHJ","sourcesContent":["@import '/src/assets/style/palette';\n\n.unorderedListIcon {\n    width: 32px;\n  }\n  \n  .checkedIcon {\n    color: @green-6 !important;\n  }\n  \n  .blackIcon {\n    color: #000;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unorderedListIcon": "unorderedListIcon_qbzhK",
	"checkedIcon": "checkedIcon__XRy1",
	"blackIcon": "blackIcon_lfqjl"
};
export default ___CSS_LOADER_EXPORT___;
