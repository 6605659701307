import { FC } from 'react'

import { Radio } from '../../../../Radio'
import { CardDivider } from '../../../../card'
import { FormItem } from '../../../../components/Form'

interface IComponentProps {
    uneditable: boolean
}
export const FinancialPositionForm: FC<IComponentProps> = ({ uneditable }) => {
    return (
        <>
            <div className="formRow">
                <FormItem
                    name="isBankrotInProgress"
                    label="Ведется производство по делу о несостоятельности (банкротстве)"
                    required
                    uneditable={uneditable}
                    column
                >
                    <Radio.Group>
                        <Radio value="Верно">Верно</Radio>
                        <Radio value="Не верно">Не верно</Radio>
                    </Radio.Group>
                </FormItem>
            </div>
            <div className="formRow">
                <FormItem
                    name="isBankrot"
                    label="Имеются вступившие в силу решения судебных органов о признании юридического лица несостоятельным (банкротом)"
                    required
                    uneditable={uneditable}
                    column
                >
                    <Radio.Group>
                        <Radio value="Верно">Верно</Radio>
                        <Radio value="Не верно">Не верно</Radio>
                    </Radio.Group>
                </FormItem>
            </div>
            <div className="formRow">
                <FormItem
                    name="isLiquidationInProgress"
                    label="Проводится процедура ликвидации"
                    required
                    uneditable={uneditable}
                    column
                >
                    <Radio.Group>
                        <Radio value="Верно">Верно</Radio>
                        <Radio value="Не верно">Не верно</Radio>
                    </Radio.Group>
                </FormItem>
            </div>
            <div className="formRow">
                <FormItem
                    name="isNoMoney"
                    label="Есть факты неисполнения своих денежных обязательств по причине отсутствия денежных средств на банковских счетах"
                    required
                    uneditable={uneditable}
                    column
                >
                    <Radio.Group>
                        <Radio value="Верно">Верно</Radio>
                        <Radio value="Не верно">Не верно</Radio>
                    </Radio.Group>
                </FormItem>
            </div>
            <div className="formRow">
                <FormItem
                    name="isDebtGov"
                    label="Есть задолженность по уплате налоговых и иных обязательных платежей в бюджет и внебюджетные фонды Российской Федерации"
                    required
                    uneditable={uneditable}
                    column
                >
                    <Radio.Group>
                        <Radio value="Верно">Верно</Radio>
                        <Radio value="Не верно">Не верно</Radio>
                    </Radio.Group>
                </FormItem>
            </div>
            <CardDivider />
        </>
    )
}
