import clsx from 'clsx'
import { FC, useState } from 'react'

import { DownMini } from '../../Icons'
import { Collapse } from '../Collapse/Collapse'
import styles from './styles.m.less'

const { Panel } = Collapse
const COLLAPSE_KEY = 'default'

export interface ICollapseDownProps {
    headerIsClose?: string
    headerIsOpen?: string
}

export const CollapseDown: FC<ICollapseDownProps> = ({
    headerIsClose = 'Подробнее',
    headerIsOpen = 'Скрыть подробности',
    children,
}) => {
    const [activeKey, setActiveKey] = useState<string | string[]>()
    const isOpen = activeKey?.includes(COLLAPSE_KEY)
    const headerClass = clsx(styles.headerCollapse, { [styles.headerCollapseOpen]: isOpen })

    return (
        <Collapse ghost activeKey={activeKey} onChange={setActiveKey}>
            <Panel
                header={
                    !isOpen && (
                        <span className={headerClass}>
                            {headerIsClose}
                            <DownMini />
                        </span>
                    )
                }
                key={COLLAPSE_KEY}
                className={styles.collapsePanel}
                showArrow={false}
            >
                {children}
                <span className={headerClass} onClick={() => setActiveKey([])}>
                    {headerIsOpen}
                    <DownMini />
                </span>
            </Panel>
        </Collapse>
    )
}
