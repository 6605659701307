import { Box, Button, Card, CardContent, CardHeader, Table } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { AddCheckModal } from './AddCheckModal'
import { gridConfig } from './gridConfig'

export const CheckInList: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const data = useSelector(clientsSelector.selectBlacklist)

    return (
        <>
            <Card className="boxContent">
                <CardHeader title="Проверки по перечням" />
                <CardContent>
                    {Boolean(data?.length) && (
                        <Box margin={[0, 0, 16, 0]}>
                            <Table columns={gridConfig} dataSource={data} bordered />
                        </Box>
                    )}
                    <div>
                        <Button borderRadius={16} onClick={() => setIsModalVisible(true)}>
                            Добавить проверку по перечням
                        </Button>
                    </div>
                </CardContent>
            </Card>
            <AddCheckModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}
