import { Input as InputAntd } from 'antd'
import { InputProps as InputPropsAntd, TextAreaProps } from 'antd/lib/input'
import { ForwardedRef } from 'react'

export type InputProps = Pick<TextAreaProps, 'rows'> &
    Pick<
        InputPropsAntd,
        | 'disabled'
        | 'onChange'
        | 'addonBefore'
        | 'size'
        | 'prefix'
        | 'value'
        | 'style'
        | 'onFocus'
        | 'onBlur'
        | 'onPressEnter'
        | 'autoComplete'
    > & {
        placeholder?: string
        listName?: string
        multiline?: boolean
        maxLength?: number
        minLength?: number
        password?: boolean
        ref?: ForwardedRef<InputAntd>
    }

const Input = (props: InputProps) => {
    return <InputAntd {...props} ref={props.ref} />
}
Input.Password = InputAntd.Password
Input.TextArea = InputAntd.TextArea

export { Input }
