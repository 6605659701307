// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".makeIssueFailedButton_tvn8b {\n  background-color: #ff4d4f !important;\n  border: 1px solid #ff4d4f !important;\n}\n.makeIssueFailedButton_tvn8b:hover {\n  background-color: #ffa39e !important;\n  border: 1px solid #ffa39e !important;\n}\n.header_j77xM {\n  margin: 0 0 24px 0;\n  padding: 0;\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 600;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmissionCardPage/components/Footer/Modals/style.m.less"],"names":[],"mappings":"AAEA;EACI,oCAAA;EACA,oCAAA;AADJ;AAGI;EACI,oCAAA;EACA,oCAAA;AADR;AAIA;EACI,kBAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":["@import 'style/palette';\n\n.makeIssueFailedButton {\n    background-color: @red-5 !important;\n    border: 1px solid @red-5 !important;\n\n    &:hover {\n        background-color: @red-3 !important;\n        border: 1px solid @red-3 !important;\n    }\n}\n.header {\n    margin: 0 0 24px 0;\n    padding: 0;\n    font-size: 16px;\n    line-height: 24px;\n    font-weight: 600;\n    color: @gray-10;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"makeIssueFailedButton": "makeIssueFailedButton_tvn8b",
	"header": "header_j77xM"
};
export default ___CSS_LOADER_EXPORT___;
