import { ProfileListType } from '@dltru/dfa-models'
import {
    ColumnsType,
    EMPTY_FIELD_VALUE,
    LinkCell,
    VIEW_DATE_FORMAT,
    profileTypeTranslate,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'

export const gridConfig: ColumnsType<ProfileListType> = [
    {
        title: 'Пользователь',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        ellipsis: true,
        render: (value: string, record: ProfileListType) =>
            LinkCell(value, `/clients/${record.user_uuid}`),
    },
    {
        title: 'Тип пользователя',
        dataIndex: 'profile_type',
        key: 'profile_type',
        sorter: true,
        ellipsis: true,
        render: (value: ProfileListType['profile_type']) => <>{profileTypeTranslate[value]}</>,
    },
    {
        title: 'Регистрация в системе',
        dataIndex: 'user_identification_date',
        sorter: true,
        render: (value?: number) =>
            value ? dayjs.unix(value).format(VIEW_DATE_FORMAT) : EMPTY_FIELD_VALUE,
    },
]
