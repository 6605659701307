export const enum BlackListType {
    EXTREMIST_ACTIVITIES_OR_TERRORISM = 'EXTREMIST ACTIVITIES OR TERRORISM',
    BLOCKING_FUNDS_OR_PROPERTY = 'BLOCKING FUNDS OR PROPERTY',
    WEAPONS_OF_MASS_DESTRUCTION = 'WEAPONS OF MASS DESTRUCTION',
    SANCTIONS_LISTS = 'SANCTIONS LISTS',
    INVALID_PASSPORTS = 'INVALID PASSPORTS',
    CENTRAL_BANK_MASS_MAILING = 'CENTRAL BANK MASS MAILING',
    UNITED_NATIONS_SECURITY_COUNCIL = 'UNITED NATIONS SECURITY COUNCIL',
}

export const blackListTypeTranslate = {
    [BlackListType.EXTREMIST_ACTIVITIES_OR_TERRORISM]: 'Экстремистcкая деятельность и терроризм',
    [BlackListType.BLOCKING_FUNDS_OR_PROPERTY]: 'Замораживание денежных средств и имущества',
    [BlackListType.WEAPONS_OF_MASS_DESTRUCTION]: 'Оружие массового уничтожения',
    [BlackListType.SANCTIONS_LISTS]: 'Санкционные списки',
    [BlackListType.INVALID_PASSPORTS]: 'Недействительные паспорта',
    [BlackListType.CENTRAL_BANK_MASS_MAILING]: 'Массовая рассылка ЦБ',
    [BlackListType.UNITED_NATIONS_SECURITY_COUNCIL]: 'СовБез ООН',
}
