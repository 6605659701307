import { ITariffPlane, TariffPlaneEnum } from '@dltru/dfa-models'
import { Dropdown, Menu, MoreOutlined } from '@dltru/dfa-ui'
import { FC, useContext, useMemo } from 'react'

import { ActionButton } from '@components/ActionButton'

import style from './style.m.less'
import { RbacContext } from '@dltru/dfa-rbac'

type EventType = {
    domEvent: React.MouseEvent<HTMLElement>
}

export interface IActionCellProps {
    record: ITariffPlane
    archiveTariffPlane: (plane: ITariffPlane) => void
    createFromTariffPlane: (planeId: string) => void
}

export const ActionCell: FC<IActionCellProps> = ({
    record,
    createFromTariffPlane,
    archiveTariffPlane,
}) => {
    const { isAllow } = useContext(RbacContext)
    const handleDuplicate = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        createFromTariffPlane(record.id)
    }
    const handleArchive = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        archiveTariffPlane(record)
    }
    const stopProp = (e: React.SyntheticEvent) => {
        e.stopPropagation()
    }

    const showArchiveBtn = !record.archived_at && record.type !== TariffPlaneEnum.BASE

    // TODO добавить разграничение по правам
    const menu = useMemo(
        () => (
            <Menu>
                <Menu.Item key="duplicate" onClick={handleDuplicate}>
                    Создать на основе
                </Menu.Item>
                {showArchiveBtn && (
                    <Menu.Item key="archive" onClick={handleArchive}>
                        Архивировать тарифный план
                    </Menu.Item>
                )}
            </Menu>
        ),
        [record.archived_at],
    )

    if (!isAllow(['tariff.write.all'])) {
        return null
    }

    return (
        <Dropdown overlay={menu} trigger={['click']} overlayClassName={style.menu}>
            <ActionButton className="action-dropdown" onClick={stopProp}>
                <MoreOutlined />
            </ActionButton>
        </Dropdown>
    )
}
