import cn from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'

import { Box } from '../../../../Box'
import { ArrowDownSmall, ArrowUpSmall, LockIcon } from '../../../../Icons'
import { Requisites, RequisitesType } from '../../../../Requisites'
import { assertIsNode } from '../../../../utils'
import style from './style.m.less'

interface IInfoCardProps {
    title: string
    heading: string
    data: RequisitesType[]
    isUserBlocked?: boolean
}

export const InfoCard: FC<IInfoCardProps> = ({ title, heading, data, isUserBlocked }) => {
    const [isOpen, setIsOpen] = useState(false)
    const openToggle = (e: React.SyntheticEvent) => {
        e.preventDefault()
        setIsOpen(!isOpen)
    }
    const wrapperRef = useRef<HTMLDivElement>(null)
    const handleClickOutside = (event: Event) => {
        assertIsNode(event.target)
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const openButtonLabel = isOpen ? 'Свернуть информацию' : 'Подробнее об организации'
    const openButtonArrow = isOpen ? <ArrowUpSmall /> : <ArrowDownSmall />

    return (
        <div className={style.icWrapper} ref={wrapperRef}>
            <div className={cn(style.icContainer, { [style.open]: isOpen })}>
                <span className={style.icLabel}>{title}</span>
                <Box direction="row" padding={[8, 0, 0, 0]}>
                    {isUserBlocked && <LockIcon className={style.icBlockIcon} />}
                    <h5 className={style.icTitle}>{heading}</h5>
                </Box>
                {isOpen && (
                    <Box margin={[0, 0, 20, 0]}>
                        <Requisites requisites={data} />
                    </Box>
                )}
                <span className={style.icToggler} onClick={openToggle}>
                    {openButtonLabel}
                    {openButtonArrow}
                </span>
            </div>
        </div>
    )
}
