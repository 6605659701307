import { DfaStatusEnum, IApprovalHistoryDfa } from '@dltru/dfa-models'
import {
    Box,
    Button,
    DateTimeCell,
    Divider,
    InfoCircleOutlined,
    MiniModal,
    Space,
} from '@dltru/dfa-ui'
import React, { useState } from 'react'

const getEmployeeTypeByStatus = (status: DfaStatusEnum) => {
    switch (status) {
        case DfaStatusEnum.lawyer_approval:
            return 'юриста'
        case DfaStatusEnum.spec_approval:
            return 'специалиста ПОД/ФТ'
        default:
            return 'операциониста'
    }
}
export const CommentView = (data: IApprovalHistoryDfa) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    if (!data?.comment) {
        return null
    }
    const closeModal = () => setIsModalVisible(false)
    return (
        <>
            <Button type="link" onClick={() => setIsModalVisible(true)}>
                Посмотреть
            </Button>
            <MiniModal
                visible={isModalVisible}
                onCancel={closeModal}
                destroyOnClose
                title={
                    <Space size={0}>
                        <InfoCircleOutlined className="modal-icon modal-info-icon" />
                        Комментарий&nbsp;{getEmployeeTypeByStatus(data.status)}
                    </Space>
                }
                width={600}
                closable
                footer={[
                    <Button onClick={closeModal} borderRadius={12}>
                        Закрыть
                    </Button>,
                ]}
            >
                <Box margin={[0, 40]}>
                    <strong>{data.employee_full_name}</strong>
                    <div>{DateTimeCell(data.remove_status_date)}</div>
                    <Divider />
                    {data.comment}
                </Box>
            </MiniModal>
        </>
    )
}
