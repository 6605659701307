import { frontUrl } from '@constants/frontUrl'
import { ILoginServiceParams } from '@dltru/dfa-models'
import { openMessage, parseToken } from '@dltru/dfa-ui'
import { getRoleFromScope } from '@utils/auth'
import { sha256 } from 'js-sha256'
import { NavigateFunction } from 'react-router-dom'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'
import { notificationCounterSlice } from '@store/notificationCounter'

import api from '@services/api'
import { IRequest } from '@services/types'

import {
    CURRENT_USER_IS_FULLY_BLOCKED,
    FRAUD_ATTEMPT,
    INVALID_PASSWORD,
    IS_NOT_ADMIN_ERROR,
    LOGIN_RESPONSE_ERROR,
    NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN,
    errorsWithoutMessage,
} from '../../../constants/authConstants'
import {
    IAuthState,
    TokenStatusEnum,
    authSlice,
    confirmCall,
    dropAuth,
    getAuthProfile,
    getUserInfoAction,
    getUsersCertificates,
    login as loginAction,
    logout as logoutAction,
    removeUsersCertificate,
    requestCall,
    setFraudAttempt,
    setPhoneConfirm,
    setPhoneRequest,
    updateAuth,
    updateCertificates,
} from '../index'

const LOGIN_ERROR_DEFAULT = LOGIN_RESPONSE_ERROR

function* addAuthDataAndServices(
    uuid: string,
    payload: Partial<IAuthState>,
): Generator<Promise<IRequest<unknown>>, Partial<IAuthState>, { data: any; error?: any }> {
    const { data: authData, error: authDataError } = yield api.lib.fetchAuthData(uuid)

    if (authDataError) {
        openMessage({
            type: 'error',
            message: 'Ошибка подгрузки авторизационных данных',
        })
        throw new Error('authorization data load problem')
    }

    const authDataObj = {
        ...authData.item,
        phone: authData?.item?.phone?.slice(1),
    }

    return {
        ...payload,
        ...authDataObj,
    }
}

function* handleLogin({ payload }: ReturnType<typeof loginAction>) {
    try {
        const { login, password, navigate } = payload as ILoginServiceParams & {
            navigate: NavigateFunction
        }
        const { data, error } = yield api.lib.loginService({
            login,
            password: sha256(password).toString(),
        })

        const loginError = error?.message || ''

        if (loginError === 'user is blocked') {
            throw new Error(CURRENT_USER_IS_FULLY_BLOCKED)
        }

        if (loginError === 'authData not found') {
            throw new Error(NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN)
        }

        if (loginError === 'invalid password') {
            throw new Error(INVALID_PASSWORD)
        }

        if (!data && data?.jwt_token) {
            throw new Error(LOGIN_ERROR_DEFAULT)
        }

        if (loginError === 'fraud block') {
            yield* put(setFraudAttempt(error.seconds_remaining))
            throw new Error(FRAUD_ATTEMPT)
        }

        if (data && !data.is_admin) {
            throw new Error(IS_NOT_ADMIN_ERROR)
        }

        const parsed = parseToken(data.jwt_token)
        const { is_first_login, is_2fa_enabled, is_first_employee_login, token } = data

        localStorage.setItem('accessToken', data.jwt_token)
        localStorage.setItem('refreshToken', data.refresh_token)

        if (!is_first_login && !is_first_employee_login) {
            let authPayload: IAuthState = JSON.parse(localStorage.getItem('user') || '{}')

            if (parsed?.length) {
                const { scope, uuid } = parsed[1]

                const role = getRoleFromScope(scope)

                authPayload = {
                    ...authPayload,
                    user: login,
                    error: '',
                    is2Fa: true,
                    status2Fa: 'ready',
                    isLoading: false,
                    isFirstTime: false,
                    registrationPhoneLastDigits: data.phone_number_digits,
                    role,
                    uuid,
                }
                if (!is_2fa_enabled) {
                    authPayload = yield addAuthDataAndServices(uuid, authPayload)
                }
            }

            localStorage.setItem('user', JSON.stringify(authPayload))

            yield* put(updateAuth(authPayload))

            // check if form fulfilled
            if (!is_2fa_enabled) {
                navigate('/')
            }
        } else if (is_first_login) {
            const authPayload = {
                user: login,
                error: '',
                isLoading: false,
                isFirstTime: true,
                statusSetPhone: 'ready',
                phone: '',
                profileStatus: null,
            }

            localStorage.setItem('user', JSON.stringify(authPayload))

            yield* put(updateAuth(authPayload))
        } else if (is_first_employee_login) {
            yield* put(authSlice.actions.setChangeAuthData({ tokenStatus: TokenStatusEnum.verify }))
            navigate(`/${frontUrl.restorePassword}/${token}`)
        }
    } catch (error: any) {
        if (!errorsWithoutMessage.includes(error.message)) {
            openMessage({
                type: 'error',
                message: 'Что-то пошло не так, попробуйте позднее',
            })
        }
        localStorage.removeItem('accessToken')
        yield* put(
            updateAuth({ error: error.message, isAuth: false, isLoading: false, status2Fa: null }),
        )
    }
}

function* handleLogout() {
    ;['accessToken', 'refreshToken', 'user'].map((name) => localStorage.removeItem(name))
    yield* put(notificationCounterSlice.actions.clear())
    yield* put(dropAuth())
}

function* handleSetPhoneRequest({ payload: phone }: ReturnType<typeof setPhoneRequest>) {
    try {
        if (!phone) {
            throw new Error('no phone received')
        }
        const { error } = yield api.lib.setPhoneRequestService({ phone })

        if (error) {
            throw new Error(error.message)
        }
        yield* put(
            updateAuth({ error: '', statusSetPhone: 'await', isAuth: false, isLoading: false }),
        )
    } catch (error: any) {
        yield* put(
            updateAuth({
                error: error.message,
                setPhoneError: true,
                isAuth: false,
                isLoading: false,
            }),
        )
    }
}

function* handleSetPhoneConfirm({ payload: token }: ReturnType<typeof setPhoneConfirm>) {
    try {
        if (!token) {
            throw new Error('no phone received')
        }

        const { error, data } = yield api.lib.setPhoneConfirmService({
            token,
        })

        if (error) {
            throw new Error(error)
        }

        if (data?.item?.jwt_token) {
            localStorage.setItem('accessToken', data.item.jwt_token)
        }
        if (data?.item?.refresh_token) {
            localStorage.setItem('refreshToken', data.item.refresh_token)
        }

        const parsed = parseToken(data.item.jwt_token)
        yield* put(
            updateAuth({
                isAuth: true,
                isLoading: false,
                statusSetPhone: 'confirmed',
            }),
        )

        if (parsed?.length) {
            const { scope, uuid } = parsed[1]
            const role = getRoleFromScope(scope)

            const authPayload: IAuthState = yield addAuthDataAndServices(uuid, {})

            yield* put(
                updateAuth({
                    role,
                    ...authPayload,
                }),
            )
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Неверный код, попробуйте ещё раз.',
        })
        yield* put(updateAuth({ error: error, isAuth: false, isLoading: false }))
    }
}

function* handleRequest2FA() {
    try {
        yield* put(updateAuth({ error: '', status2Fa: 'await' }))
        const token = localStorage.getItem('accessToken')
        if (!token) {
            throw new Error('no token')
        }
        yield api.lib.request2FaCallService()
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Что-то пошло не так, попробуйте позднее',
        })
        yield* put(updateAuth({ error: error, isAuth: false, isLoading: false }))
    }
}

function* handleconfirmCall2FA({ payload: token }: ReturnType<typeof confirmCall>) {
    try {
        const { error, data } = yield api.lib.confirm2FaCallService(token)

        if (error) {
            throw new Error(error)
        }
        localStorage.setItem('accessToken', data?.item?.jwt_token)
        localStorage.setItem('refreshToken', data?.item?.refresh_token)

        const parsed = parseToken(data?.item?.jwt_token)
        yield* put(
            updateAuth({
                isAuth: true,
                isLoading: false,
                error: '',
                status2Fa: 'confirmed',
            }),
        )
        if (parsed?.length) {
            const { scope, uuid } = parsed[1]
            const role = getRoleFromScope(scope)
            const authPayload: IAuthState = yield addAuthDataAndServices(uuid, {})

            yield* put(
                updateAuth({
                    ...authPayload,
                    role,
                }),
            )
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Неверный код, попробуйте ещё раз.',
        })
        yield* put(updateAuth({ error: error, isAuth: false, isLoading: false }))
    }
}

function* getUserInfo() {
    try {
        const { uuid, user } = yield* select(authSelector.selectUserInfo)
        if (uuid && !user) {
            const { data, error } = yield api.lib.fetchAuthData(uuid)

            if (error) {
                throw new Error()
            }
            yield* put(
                updateAuth({
                    user: data?.item?.login,
                    phone: data?.item?.phone,
                    email: data?.item?.email,
                }),
            )
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить данные по пользователю',
        })
    }
}

function* getUsersCertificatesHandler() {
    try {
        yield* put(
            updateCertificates({
                items: [],
                error: null,
                isLoaded: false,
            }),
        )

        const uuid = yield* select(authSelector.selectUserUid)
        const { data, error } = yield* call(api.lib.getCertificates, {
            order: 'desc(id)' as const,
            limit: 100,
            user_uuid: uuid,
        })

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            const payload = { ...data.item, items: data.item.items ?? [] }
            yield* put(updateCertificates(payload))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить данные по сертификату пользователя',
        })
    } finally {
        yield* put(updateCertificates({ isLoaded: true }))
    }
}
function* getAuthProfileHandler() {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        if (!uuid) {
            return
        }
        yield* put(authSlice.actions.setIsLoading(true))
        const { data, error } = yield* call(api.lib.getEmployeeById, uuid)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.items?.length) {
            yield* put(authSlice.actions.setProfile(data.items[0]))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить данные по профилю',
        })
    } finally {
        yield* put(authSlice.actions.setIsLoading(false))
    }
}

function* removeUsersCertificateHandler({ payload }: ReturnType<typeof removeUsersCertificate>) {
    try {
        yield* put(
            updateCertificates({
                isLoaded: false,
            }),
        )
        const { error } = yield* call(api.lib.removeCertificateByUid, payload)

        if (error) {
            throw error
        }
        yield* put(getUsersCertificates)
        yield* put(
            updateCertificates({
                isLoaded: true,
            }),
        )
    } catch (error) {
        yield* put(
            updateCertificates({
                error,
                isLoaded: true,
            }),
        )
    }
}

export function* watchAuthSaga(): Generator<StrictEffect> {
    yield* takeLatest(loginAction.type, handleLogin)
    yield* takeLatest(logoutAction.type, handleLogout)
    yield* takeLatest(setPhoneRequest.type, handleSetPhoneRequest)
    yield* takeLatest(setPhoneConfirm.type, handleSetPhoneConfirm)
    yield* takeLatest(requestCall.type, handleRequest2FA)
    yield* takeLatest(confirmCall.type, handleconfirmCall2FA)
    yield* takeLatest(getUserInfoAction.type, getUserInfo)
    yield* takeLatest(getUsersCertificates.type, getUsersCertificatesHandler)
    yield* takeLatest(getAuthProfile.type, getAuthProfileHandler)
    yield* takeLatest(removeUsersCertificate.type, removeUsersCertificateHandler)
}
