import { IRequest } from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function getWalletLocksService<T>(
    params: unknown,
    userUUID: string,
): Promise<IRequest<T>> {
    return requestDecorator.call(
        this,
        path(this.paths.LockDfaByUserId, userUUID),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}
