import { Box, ColorModal, IColorModalProps } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { RepaymentModalBody } from './RepaymentModalBody'
import styles from './style.m.less'

export const RepaymentModal: FC<Pick<IColorModalProps, 'isModalVisible' | 'setIsModalVisible'>> = (
    props,
) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const Header = (
        <Box margin={[0, 0, 16, 0]}>
            <Link to={`/offer/${dfaDetails.id}`} target="_blank" className={styles.headerLink}>
                {dfaDetails.ticker_symbol}
            </Link>
            <h3 className={styles.header}>{dfaDetails.title}</h3>
            <Link
                to={`/clients/${dfaDetails.emitter_id}`}
                target="_blank"
                className={styles.headerLink}
            >
                {dfaDetails.emitter_info?.short_name}
            </Link>
        </Box>
    )
    return (
        <ColorModal
            {...props}
            header={Header}
            body={<RepaymentModalBody setIsModalVisible={props.setIsModalVisible} />}
            destroyOnClose
            width={600}
            headerColor="gray"
            closable
        />
    )
}
