import { IEmissionOrder, ISecondaryOrder, UnionOrderType } from '@dltru/dfa-models'
import { Box, Table } from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'
import { dropOrderList, loadAllOrdersByUser } from '@store/orders/list'
import { ordersSelector } from '@store/orders/list/selectors'

import {
    EmissionOrderDetailsModal,
    SecondaryOrderDetailsModal,
    SecondaryRevokeModal,
} from '@components/Modals/Orders'

import { getGridConfig } from './gridConfig'

export const OrdersTab: FC = () => {
    const reduxDispatch = useDispatch()
    const currentClientId = useSelector(clientsSelector.selectCurrentClientId)
    const isLoading = useSelector(ordersSelector.selectIsLoading)
    const data = useSelector(ordersSelector.selectData) as UnionOrderType[]
    const [isEmissionOrderModalVisible, setIsEmissionOrderModalVisible] = useState(false)
    const [isSecondaryOrderModalVisible, setIsSecondaryOrderModalVisible] = useState(false)
    const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<UnionOrderType>()

    useEffect(() => {
        if (currentClientId) {
            reduxDispatch(loadAllOrdersByUser(currentClientId))
        }
        return () => {
            reduxDispatch(dropOrderList())
        }
    }, [currentClientId])

    const onRow = (order: UnionOrderType) => ({
        onClick: () => {
            switch (order.order_type) {
                case 'sell':
                case 'buy': {
                    setCurrentOrder(order)
                    setIsSecondaryOrderModalVisible(true)
                    setIsEmissionOrderModalVisible(false)
                    break
                }
                default: {
                    setCurrentOrder(order)
                    setIsSecondaryOrderModalVisible(false)
                    setIsEmissionOrderModalVisible(true)
                }
            }
        },
    })

    const actionSecondaryCancel = (order: UnionOrderType) => {
        setCurrentOrder(order)
        setIsRevokeModalVisible(true)
    }

    const gridConfig = getGridConfig({ actionSecondaryCancel })

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                onRow={onRow}
                clickableRow
                //headerRight={<FilterByStatus />}
                showSorterTooltip={false}
            />
            <SecondaryOrderDetailsModal
                isModalVisible={isSecondaryOrderModalVisible}
                setIsModalVisible={setIsSecondaryOrderModalVisible}
                order={currentOrder as ISecondaryOrder}
            />
            <EmissionOrderDetailsModal
                isModalVisible={isEmissionOrderModalVisible}
                setIsModalVisible={setIsEmissionOrderModalVisible}
                order={currentOrder as IEmissionOrder}
            />
            <SecondaryRevokeModal
                setIsModalVisible={setIsRevokeModalVisible}
                isModalVisible={isRevokeModalVisible}
                order={currentOrder as ISecondaryOrder}
            />
        </Box>
    )
}
