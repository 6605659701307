import {
    IDFAOwnershipTransferRequest,
    ITransferDeal,
    ITransferOrderShort,
    ITransferRejectRequest,
    ProfileType,
} from '@dltru/dfa-models'
import { TransferDfaInit } from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { IDFAOwnershipTransferRequestIdentificator } from '@store/dfa/types'

import { initialState as initialStateDfa } from '../resources'
import { EntityState } from '../types'

type OfferType = { dfa: typeof initialStateDfa; price: number }
type BuyerDataType = { type: ProfileType; is_qualified: boolean; quota: number }
type InitialStateType = EntityState<Partial<TransferDfaInit> | ITransferOrderShort> & {
    buyerData: BuyerDataType
    offerentOffer?: OfferType
    accepterOffer?: OfferType
    deals: ITransferDeal[]
    dealsIsLoading: boolean
}
const initialState: InitialStateType = {
    isLoading: false,
    dealsIsLoading: false,
    data: {},
    error: '',
    buyerData: {} as BuyerDataType,
    offerentOffer: { dfa: initialStateDfa, price: 0 },
    accepterOffer: { dfa: initialStateDfa, price: 0 },
    deals: [],
}

export const dfaOwnershipTransferSlice = createSlice({
    name: 'dfaOwnershipTransfer',
    initialState,
    reducers: {
        setDFAOwnershipTransfer: (state, action) => {
            state.isLoading = false
            state.data = action.payload.data
            state.error = ''
        },
        setDFAOwnershipTransferLoading: (state, action) => {
            state.isLoading = action.payload
            state.error = ''
        },
        setDFAOwnershipTransferError: (state, action) => {
            state.isLoading = false
            state.data = {}
            state.error = action.payload.data
        },
        setBuyerDataTransfer: (state, action: PayloadAction<Partial<BuyerDataType>>) => {
            state.buyerData = { ...state.buyerData, ...action.payload }
        },
        clearDFAOwnershipBuyerData: (state) => {
            state.buyerData = {} as BuyerDataType
        },
        setOfferentOffer: (state, action: PayloadAction<Partial<OfferType>>) => {
            state.offerentOffer = { ...state.offerentOffer, ...action.payload }
        },

        setAccepterOffer: (state, action: PayloadAction<Partial<OfferType>>) => {
            state.accepterOffer = { ...state.accepterOffer, ...action.payload }
        },
        setTransferDealsIsLoading: (state, action: PayloadAction<boolean>) => {
            state.dealsIsLoading = action.payload
        },
        setTransferDeals: (state, action: PayloadAction<ITransferDeal[]>) => {
            state.deals = action.payload
        },

        clearOwnershipTransfer: (state) => {
            state.offerentOffer = initialState.offerentOffer
            state.accepterOffer = initialState.accepterOffer
            state.data = initialState.data
            state.deals = initialState.deals
        },
    },
})

export const getDFAOwnershipTransfer = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/getDFAOwnershipTransfer',
)

export const createDFAOwnershipTransferRequestAction = createAction<IDFAOwnershipTransferRequest>(
    'dfaOwnershipTransfer/createDFAOwnershipTransferRequest',
)
export const checkLimitRecipientTransferAction = createAction<string>(
    'dfaOwnershipTransfer/checkLimitRecipientTransferAction',
)

export const transferReject = createAction<ITransferRejectRequest>(
    'dfaOwnershipTransfer/transferReject',
)

export const transferDfaCancelByOperator = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/transferDfaCancelByOperator',
)

export const getOfferentDFA = createAction<number>('dfaOwnershipTransfer/getOfferentDFA')

export const getAcceptorDFA = createAction<number>('dfaOwnershipTransfer/getAcceptorDFA')

export const getTransferDeals = createAction<unknown>('dfaOwnershipTransfer/getTransferDeals') // ???

export const {
    setDFAOwnershipTransfer,
    setDFAOwnershipTransferLoading,
    setDFAOwnershipTransferError,
    setOfferentOffer,
    setAccepterOffer,
    setTransferDeals,
    clearOwnershipTransfer,
    setTransferDealsIsLoading,
} = dfaOwnershipTransferSlice.actions

export default dfaOwnershipTransferSlice.reducer
