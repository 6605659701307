import { DfaStatusEnum, SettlementsType } from '@dltru/dfa-models'
import { Button, CaseDel } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { FailedIssueModal } from '../Modals'

export const FailedIssueAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    if (
        dfaDetails.status !== DfaStatusEnum.collection_started ||
        dfaDetails.repayment_settlements_type !== SettlementsType.direct
    ) {
        return null
    }

    return (
        <>
            <Button
                icon={<CaseDel width="20" height="20" />}
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
            >
                Признать выпуск несостоявшимся
            </Button>
            <FailedIssueModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
