import { DocumentsEnum, MoneyAccountFull } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Bill, Box, Divider, Table } from '@dltru/dfa-ui'
import { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { actions as moneyAccountsActions } from '@store/money/accounts/list'
import IAppState from '@store/states'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { TopLine } from './TopLine'
import { gridConfig } from './gridConfig'
import style from './style.m.less'

export const PersonalAccountsTable: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const { items, isLoading } = useSelector((state: IAppState) => state.moneyAccounts.list)

    useEffect(() => {
        dispatch(moneyAccountsActions.load({ page: 0, limit: 0 }))
        return () => {
            dispatch(moneyAccountsActions.clear())
        }
    }, [])

    const onRow = (record: MoneyAccountFull) => ({
        onClick: () => {
            navigate(`/clients/${record.user_uuid}#account`)
        },
    })
    const showDownloadNominalBtn = isAllow(['account.read.all'])
    const showDownloadBenefBtn = isAllow(['account.read.all', 'user.read.all'])

    return (
        <Box padding={[8, 24, 24, 24]} className="boxContent">
            <div className={style.PAHeader}>
                <TopLine />
            </div>
            <Divider />
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items}
                noPagination
                onRow={onRow}
                clickableRow
                rowKey="id"
                headerRight={
                    showDownloadNominalBtn && (
                        <DownloadBtn
                            documentType={DocumentsEnum.nominal_account_operations_history}
                            title="Выписка по операциям"
                            icon={<Bill />}
                        />
                    )
                }
                footerLeft={
                    showDownloadBenefBtn && (
                        <DownloadBtn
                            documentType={DocumentsEnum.beneficiary_nominal_account_balance}
                            title="Выписка в разрезе бенефициаров"
                        />
                    )
                }
            />
        </Box>
    )
}
