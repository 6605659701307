export type ModalType = 'set' | 'delete'

export type ModalResource = {
    title: string
    submitButtonLabel: string
    reasonTitle: string
}

export type ModalResources = {
    set: ModalResource
    delete: ModalResource
}

export const ChangeNodeStatusModalResources = {
    set: {
        title: 'Присвоение статуса Узла',
        submitButtonLabel: 'Присвоить статус',
        reasonTitle: 'Основание присвоения статуса',
    },
    delete: {
        title: 'Отзыв статуса Узла',
        submitButtonLabel: 'Отозвать статус',
        reasonTitle: 'Основание отзыва статуса',
    },
} as ModalResources
