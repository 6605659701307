import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ArrowLeft: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.4376 11.1094H6.72429L14.9321 3.98438C15.0633 3.86953 14.9837 3.65625 14.8102 3.65625H12.736C12.6446 3.65625 12.5579 3.68906 12.4899 3.74766L3.63288 11.4328C3.55176 11.5031 3.4867 11.5901 3.44211 11.6877C3.39753 11.7854 3.37445 11.8915 3.37445 11.9988C3.37445 12.1062 3.39753 12.2123 3.44211 12.3099C3.4867 12.4076 3.55176 12.4945 3.63288 12.5648L12.5415 20.2969C12.5766 20.3273 12.6188 20.3438 12.6633 20.3438H14.8079C14.9813 20.3438 15.061 20.1281 14.9298 20.0156L6.72429 12.8906H20.4376C20.5407 12.8906 20.6251 12.8062 20.6251 12.7031V11.2969C20.6251 11.1937 20.5407 11.1094 20.4376 11.1094Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
