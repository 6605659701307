import { FC, useState } from 'react'

import { Box } from '../../../Box'
import { Checkbox, CheckboxChangeEvent } from '../../../Checkbox'
import { useFormValues } from '../../../hooks'
import { WrappedInput, WrappedInputProps } from './'

export const WrappedInputWithDisable: FC<
    WrappedInputProps & { checkboxLabel: string; defaultDisabled?: boolean }
> = ({ checkboxLabel, required, uneditable, name, defaultDisabled, listName, ...props }) => {
    const [isInputDisabled, setIsInputDisabled] = useState<boolean>(defaultDisabled ?? false)
    const { setFormValue } = useFormValues({ name, listName })
    const onChangeCheckbox = (event: CheckboxChangeEvent) => {
        const isDisabled = event.target.checked
        setIsInputDisabled(isDisabled)
        if (isDisabled) {
            setFormValue(undefined)
        }
    }
    return (
        <>
            {!(uneditable && isInputDisabled) && (
                <WrappedInput
                    {...props}
                    name={name}
                    listName={listName}
                    disabled={isInputDisabled}
                    required={isInputDisabled ? false : required}
                    uneditable={uneditable}
                />
            )}
            {!uneditable && (
                <Box height="40px" margin={[0, 0, 0, 16]} justify="center">
                    <Checkbox
                        className="proper-checkbox-style"
                        defaultChecked={isInputDisabled}
                        onChange={onChangeCheckbox}
                    >
                        {checkboxLabel}
                    </Checkbox>
                </Box>
            )}
        </>
    )
}
