import {
    CardDivider,
    Form,
    FormListInput,
    IRoleFormStepProps,
    OgrnInput,
    Step,
    StepperContext,
    TaxRegistrationBlock,
    WebSiteInput,
    WrappedInput,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext, useEffect } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

const STEP_INDEX = 1

export const BusinessInformationStep: FC<IRoleFormStepProps> = ({
    isEditAllow,
    disableFields = [],
}) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    const websiteListName = 'websites'
    const okvedListName = 'okveds'
    useEffect(() => {
        if (!values?.[websiteListName]?.length) {
            form.setFieldsValue({ [websiteListName]: [{}] })
        }
        if (!values?.[okvedListName]?.length) {
            form.setFieldsValue({ [okvedListName]: [{}] })
        }
    }, [])

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Сведения об Индивидуальном предпринимателе"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <OgrnInput
                    uneditable={isEdit}
                    required
                    disabled={disableFields.includes('ogrnip')}
                />
            </div>
            <TaxRegistrationBlock isNotCurrentStep={isEdit} disableFields={disableFields} />
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Свидетельство о гос. регистрации"
                    name="registration_certificate"
                    uneditable={isEdit}
                    required
                    disabled={disableFields.includes('registration_certificate')}
                />
            </div>
            <div className="formRow formRow--without-label">
                <UploadInputForm
                    name="ogrn_file_uuid"
                    uneditable={isEdit}
                    required
                    disabled={disableFields.includes('ogrn_file_uuid')}
                    title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
            <CardDivider />
            <FormListInput
                listName={websiteListName}
                uneditable={isEdit}
                label="Сайт"
                name="website"
                className="formComponentItem formComponentItem--xl"
                InputComponent={WebSiteInput}
            />
            <CardDivider />
            <FormListInput
                listName={okvedListName}
                uneditable={isEdit}
                label="ОКВЭД"
                name="okved"
                className="formComponentItem formComponentItem--xl"
                required
            />
        </Step>
    )
}
