// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalTitle_zd278 {\n  padding: 0;\n  margin: 0 0 24px 0;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/RemoveClient/RemoveUserModal/style.m.less"],"names":[],"mappings":"AACA;EACE,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF","sourcesContent":["@import 'style/palette';\n.modalTitle {\n  padding: 0;\n  margin: 0 0 24px 0;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: @gray-10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": "modalTitle_zd278"
};
export default ___CSS_LOADER_EXPORT___;
