import {
    Button,
    CardDivider,
    Divider,
    Form,
    IRoleFormStepProps,
    Space,
    Step,
    StepperContext,
    WrappedInput,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, Fragment, useContext, useEffect } from 'react'

import { UploadInputForm } from '@components/Form/components/UploadInputForm'

interface IComponentProps extends IRoleFormStepProps {
    stepIndex: number
    maxCount?: number
}

export const BusinessReputationStep: FC<IComponentProps> = ({
    stepIndex,
    maxCount = 10,
    isEditAllow,
}) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex)) &&
        isEditAllow

    const listName = 'business_reputations'

    useEffect(() => {
        if (!values?.[listName]?.length) {
            form.setFieldsValue({ [listName]: [{}] })
        }
    }, [])

    return (
        <Step
            form={form}
            stepIndex={stepIndex}
            stepTitle="Сведения о деловой репутации"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <Form.List name={listName}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => {
                            const props = {
                                ...restField,
                                listName,
                                uneditable: isEdit,
                                fieldKey: name,
                            }
                            return (
                                <Fragment key={key}>
                                    <Divider orientation="left" margin={[32, 0]}>
                                        <Space>
                                            Отзыв
                                            {fields.length > 1 && !isEdit && (
                                                <Button
                                                    type="link"
                                                    size="small"
                                                    onClick={() => remove(name)}
                                                >
                                                    Удалить
                                                </Button>
                                            )}
                                        </Space>
                                    </Divider>
                                    <div className="formRow">
                                        <WrappedInput
                                            {...props}
                                            className="formComponentItem--xl"
                                            label="Описание"
                                            name={[name, 'business_reputation']}
                                            placeholder=""
                                            required
                                            multiline
                                            maxLength={200}
                                        />
                                    </div>

                                    <div className="formRow" style={{ marginLeft: 240 }}>
                                        <UploadInputForm
                                            {...props}
                                            name={[name, 'file_uuids']}
                                            required
                                            title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                                            maxSizeMb={5}
                                            accept=".pdf,.png,.jpeg, .jpg, .tiff"
                                        />
                                    </div>
                                </Fragment>
                            )
                        })}

                        {!isEdit && fields?.length < maxCount && (
                            <>
                                <CardDivider />
                                <Button type="link" onClick={() => add()}>
                                    Добавить отзыв
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Form.List>
        </Step>
    )
}
