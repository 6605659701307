export const stringToNumber = (value?: string) => {
    if (typeof value === 'string') {
        return parseFloat(value.replace(/,/g, '.').replace(/\s/g, '')) || 0
    }
    return value ? value : 0
}

export const getPennies = (value: string | number | undefined) => {
    if (typeof value === 'number') {
        return Math.round(value * 100)
    }
    if (typeof value === 'string') {
        return Math.round(stringToNumber(value) * 100)
    }
    return 0
}

export const getBasePercent = (value?: number) =>
    value !== undefined ? Math.floor(Number(value) * 1000) : undefined
