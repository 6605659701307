import moment from 'moment'

import { BeneficialFormValues, BeneficialOwner, DocumentType } from '../../models'
import { getDateWithoutTime, getDateWithoutTimeString } from '../../utils'
import { getInitialFile, mapFilesList } from '../common'
import { getMigrationDocFromBackend, getMigrationDocFromForm } from './common'

export const beneficiaryFrontToBack = (valuesFromForm: BeneficialFormValues): BeneficialOwner => {
    const passportData =
        valuesFromForm.documentType === DocumentType.passport
            ? {
                  identity_document: valuesFromForm.documentType,
                  passport: {
                      series: valuesFromForm.PassportSeries,
                      number: valuesFromForm.PassportNumber,
                      issue_date: valuesFromForm.issueDate.unix(),
                      issued_by: valuesFromForm.PassportIssuer,
                      issuer_department_code: valuesFromForm.PassportCode,
                      file_uuid: valuesFromForm.PassportPages?.map(({ uid }) => uid).join(),
                  },
              }
            : { identity_document: valuesFromForm.documentType }

    return {
        uuid: valuesFromForm.uuid,
        status: valuesFromForm.status,
        confirmation_doc: valuesFromForm.confirmation_document,
        confirmation_doc_file_uuid: valuesFromForm.confirmation_document_file?.[0]?.uid,
        first_name: valuesFromForm.first_name,
        last_name: valuesFromForm.last_name,
        second_name: valuesFromForm.second_name,
        birthdate: getDateWithoutTime(String(valuesFromForm.birthdate)) as unknown as number,
        place_birth: valuesFromForm.place_birth,
        citizenship: valuesFromForm.citizenship,
        registration_address: valuesFromForm.registration,
        location_address: valuesFromForm.fact,
        post_address: valuesFromForm.index,
        inn: valuesFromForm.inn,
        phone_number: valuesFromForm.phone_number,
        corporate_mail: valuesFromForm.email,
        ...passportData,
        ...getMigrationDocFromForm(valuesFromForm),
    }
}
export const beneficiariesFrontToBack = (
    valuesFromForm: BeneficialFormValues[],
): BeneficialOwner[] => valuesFromForm.map(beneficiaryFrontToBack)

export const beneficiaryBackToFront = (
    valuesFromBackend: BeneficialOwner,
): BeneficialFormValues => {
    const passportData =
        valuesFromBackend.identity_document === DocumentType.passport || valuesFromBackend.passport
            ? {
                  documentType: valuesFromBackend.identity_document || DocumentType.passport,
                  PassportSeries: valuesFromBackend.passport.series,
                  PassportNumber: valuesFromBackend.passport.number,
                  issueDate: moment.unix(valuesFromBackend.passport.issue_date),
                  PassportCode: valuesFromBackend.passport?.issuer_department_code,
                  PassportPages: mapFilesList(
                      'PassportPages',
                      valuesFromBackend.passport?.file_uuid,
                  ),
                  PassportIssuer: valuesFromBackend.passport?.issued_by,
              }
            : {
                  documentType: valuesFromBackend.identity_document,
              }

    return {
        uuid: valuesFromBackend.uuid,
        status: valuesFromBackend.status,
        confirmation_document: valuesFromBackend.confirmation_doc,
        confirmation_document_file: getInitialFile(valuesFromBackend.confirmation_doc_file_uuid),
        first_name: valuesFromBackend.first_name,
        last_name: valuesFromBackend.last_name,
        second_name: valuesFromBackend.second_name,
        birthdate: getDateWithoutTimeString(valuesFromBackend.birthdate),
        place_birth: valuesFromBackend.place_birth,
        citizenship: valuesFromBackend.citizenship,
        registration: valuesFromBackend.registration_address,
        fact: valuesFromBackend.location_address,
        index: valuesFromBackend.post_address,
        inn: valuesFromBackend.inn,
        phone_number: valuesFromBackend.phone_number,
        email: valuesFromBackend.corporate_mail,
        ...getMigrationDocFromBackend(valuesFromBackend),
        ...passportData,
    }
}
