import { InfoWidget, asMoney } from '@dltru/dfa-ui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions } from '@store/money/accounts/summary'
import IAppState from '@store/states'

import style from './style.m.less'

export const TopLine = () => {
    const dispatch = useDispatch()
    const summary = useSelector((state: IAppState) => state.moneyAccounts.summary)

    useEffect(() => {
        // Данные загружаются вместе со списком САУ
        return () => {
            dispatch(actions.clear())
        }
    }, [])

    return (
        <div className={style.widgetsWrapper}>
            <InfoWidget
                label="Всего лицевых счетов"
                value={`${summary.data?.accounts_count ?? 0}`}
                labelAlign="left"
                valueAlign="left"
                format="base"
                addedStyle={{ width: 200 }}
            />
            <InfoWidget
                label="Всего свободных средств"
                value={asMoney(summary.data?.total_balance ?? 0)}
                format="money"
            />
            <div className={style.BalanceHeaderDivider}></div>
            <InfoWidget
                label="Всего бронировано"
                value={asMoney(summary.data?.total_reserves ?? 0)}
                format="money"
            />
            <div className={style.BalanceHeaderDivider}></div>
            <InfoWidget
                label="Общая сумма средств"
                value={asMoney(summary.data?.total ?? 0)}
                format="money"
            />
            <div className={style.BalanceHeaderDivider}></div>
        </div>
    )
}
