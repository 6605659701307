import { ITransferOrderShort, OrderTypeEnum, TransferOrderStatus } from '@dltru/dfa-models'
import { Box, DateTimeCell, PageHeader, Tabs, Tag, getOrderStatusTagProps } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { clearOwnershipTransfer, getDFAOwnershipTransfer } from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

import { UseTabsAndHash } from '../../../hooks/useTabsAndHash'
import { AlertOfferBlock } from './components/AlertOfferBlock'
import { DFAOwnershipReceiver } from './components/DFAOwnershipReceiver'
import { Footer } from './components/Footer'
import { TransferOrdersTable } from './components/TransferOrdersTable/TransferOrdersTable'

const { TabPane } = Tabs

enum DFAOwnershipTransferRequestPageTabName {
    info = 'info',
    table = 'table',
}
const defaultTab = DFAOwnershipTransferRequestPageTabName.info

export const DFAOwnershipTransferRequestPage: FC = () => {
    const { onChangeTab, activeTab } = UseTabsAndHash(
        Object.values(DFAOwnershipTransferRequestPageTabName),
        defaultTab,
    )
    const reduxDispatch = useDispatch()
    const { request: globalId } = useParams()
    const dfaOwnershipTransferData = useSelector(dfaTransferSelector.selectData)
    const navigate = useNavigate()

    useEffect(() => {
        if (globalId) {
            reduxDispatch(getDFAOwnershipTransfer({ globalId }))
        }
        return () => {
            reduxDispatch(clearOwnershipTransfer())
        }
    }, [globalId])

    const statusTagProps = getOrderStatusTagProps(
        dfaOwnershipTransferData?.status || '',
        OrderTypeEnum.transfer,
        dfaOwnershipTransferData?.cancel_initiator,
    )

    const isShowTab =
        dfaOwnershipTransferData?.status === TransferOrderStatus.accepted ||
        dfaOwnershipTransferData?.status === TransferOrderStatus.executed ||
        dfaOwnershipTransferData?.status === TransferOrderStatus.in_progress ||
        dfaOwnershipTransferData?.status === TransferOrderStatus.ready

    const title = isShowTab
        ? `Сделка  №${dfaOwnershipTransferData?.id}`
        : `Оферта  №${dfaOwnershipTransferData?.id}`

    const tabTitle = isShowTab ? 'Условия сделки' : 'Условия оферты'

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={title}
                    onBack={() => navigate(-1)}
                    tags={<Tag color={statusTagProps.color}>{statusTagProps.title}</Tag>}
                    subTitle={<>от {DateTimeCell(dfaOwnershipTransferData?.created_at)}</>}
                />
            </Box>
            <AlertOfferBlock />
            <Tabs onChange={onChangeTab} activeKey={activeTab}>
                <TabPane tab="Условия сделки" key={DFAOwnershipTransferRequestPageTabName.info}>
                    <DFAOwnershipReceiver data={dfaOwnershipTransferData as ITransferOrderShort} />
                </TabPane>
                {isShowTab && (
                    <TabPane tab={tabTitle} key={DFAOwnershipTransferRequestPageTabName.table}>
                        <Box padding={[0, 24, 24, 24]} className="tablebox">
                            <TransferOrdersTable
                                data={dfaOwnershipTransferData as ITransferOrderShort}
                            />
                        </Box>
                    </TabPane>
                )}
            </Tabs>

            <Footer />
        </>
    )
}
