import { CitizenshipType } from '@dltru/dfa-models'
import { FC } from 'react'

import { CardDivider } from '../../card'
import {
    DateInputMask,
    WrappedInput,
    WrappedInputProps,
    WrappedSelect,
} from '../../components/Form'
import { commonValidators } from '../../utils'
import { citizenshipOptions } from './resources'

type PersonalFormProps = Partial<WrappedInputProps> & {
    group?: string
    onChangeCitizenship?: (value: CitizenshipType) => void
    disableFields?: string[]
}

export const PersonalForm: FC<PersonalFormProps> = ({
    group,
    fieldKey,
    onChangeCitizenship,
    disableFields = [],
    ...rest
}) => {
    const getNameForField = (fieldName: string) => {
        if (group) {
            return `${group}.${fieldName}`
        }
        if (typeof fieldKey === 'number') {
            return [fieldKey, fieldName]
        }
        return fieldName
    }
    return (
        <>
            <div className="formRow">
                <DateInputMask
                    {...rest}
                    fieldKey={fieldKey}
                    className="formComponentItem"
                    label="Дата рождения"
                    name={getNameForField('birthdate')}
                    onlyPast
                    required
                    disabled={disableFields.includes('birthdate')}
                />
            </div>

            <div className="formRow">
                <WrappedInput
                    {...rest}
                    fieldKey={fieldKey}
                    className="formComponentItem"
                    label="Место рождения"
                    name={getNameForField('place_birth')}
                    placeholder=""
                    rules={[{ validator: commonValidators.address() }]}
                    disabled={disableFields.includes('place_birth')}
                />
            </div>
            <div className="formRow">
                <WrappedSelect
                    {...rest}
                    fieldKey={fieldKey}
                    required
                    label="Гражданство"
                    name={getNameForField('citizenship')}
                    initialValue={CitizenshipType.RU}
                    className="formComponentItem"
                    options={citizenshipOptions}
                    onChange={onChangeCitizenship}
                />
            </div>
            <CardDivider />
        </>
    )
}
