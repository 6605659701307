import { FC, useState } from 'react'

import { AddressInput, WrappedInputProps } from '../../../components/Form'
import { addressValidators, commonValidators, onlyDigits } from '../../../utils'
import './style.less'
import { AddressElements } from './types'
import { getPartPrefixAttribute } from './utils'

type AddressDetailsProps = Partial<WrappedInputProps> & {
    prefix: string
    uneditable: boolean
    marginLeft?: number
    setAddressValue?: (address: string) => void
    group?: string
    initialValues?: any // TODO типизировать с учетоь inputList
}

export const AddressDetails: FC<AddressDetailsProps> = ({
    uneditable,
    prefix = '',
    marginLeft = 240,
    setAddressValue,
    group,
    initialValues,
    fieldKey,
    listName,
    ...restProps
}) => {
    const _group = getPartPrefixAttribute(group)
    const _prefix = getPartPrefixAttribute(prefix)

    const getNameForField = (fieldName: string) => {
        if (typeof fieldKey === 'number') {
            return [fieldKey, `${_prefix}${fieldName}`]
        }
        return `${_group}${_prefix}${fieldName}`
    }
    const getValueFromInitialValue = (addressElement: AddressElements) => {
        if (listName && typeof fieldKey === 'number') {
            return initialValues?.[listName]?.[fieldKey]?.[`${_prefix}${addressElement}`] ?? ''
        }

        return initialValues?.[`${_group}${_prefix}${addressElement}`] ?? ''
    }

    const [address, setAddress] = useState(
        Object.values(AddressElements).map((addressElement) => ({
            name: addressElement,
            value: getValueFromInitialValue(addressElement),
        })),
    )

    const changeAddress = (
        partAddress?: AddressElements,
        e?: React.ChangeEvent<HTMLInputElement>,
    ) => {
        let newAddress = address
        if (partAddress) {
            newAddress = newAddress.map((el) =>
                el.name === partAddress ? { ...el, value: e?.target.value || '' } : el,
            )

            setAddress(newAddress)
        }
        setAddressValue?.(newAddress.map(({ value }) => value).join(', '))
    }

    const commonProps = (addressElement: AddressElements) => ({
        ...restProps,
        fieldKey,
        listName,
        placeholder: '',
        uneditable,
        name: getNameForField(addressElement),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => changeAddress(addressElement, e),
    })

    return (
        <div className="vertical-inputs" style={{ marginLeft }}>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.region)}
                    label="Регион"
                    width={292}
                    required
                    rules={[{ validator: commonValidators.address(50) }]}
                />
                <AddressInput
                    {...commonProps(AddressElements.district)}
                    label="Район"
                    width={292}
                    rules={[{ validator: commonValidators.address(50) }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.locality)}
                    label="Населенный пункт: город, село, деревня и др."
                    width={600}
                    required
                    rules={[{ validator: commonValidators.address(50) }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.street)}
                    label="Улица"
                    width={600}
                    required
                    rules={[{ validator: commonValidators.address(50) }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.postIndex)}
                    label="Индекс"
                    className="no-error-messages"
                    width={292}
                    required
                    rules={[{ validator: commonValidators.number(6) }]}
                    maxLength={6}
                    normalize={onlyDigits}
                />
                <AddressInput
                    {...commonProps(AddressElements.house)}
                    label="Дом"
                    className="no-error-messages"
                    width={292}
                    required
                    rules={[{ validator: addressValidators.house }]}
                />
            </div>
            <div className="form-inputs-row">
                <AddressInput
                    {...commonProps(AddressElements.building)}
                    label="Строение"
                    width={190}
                    rules={[{ validator: addressValidators.house }]}
                />
                <AddressInput
                    {...commonProps(AddressElements.buildingPart)}
                    label="Корпус"
                    width={190}
                    rules={[{ validator: addressValidators.house }]}
                />
                <AddressInput
                    {...commonProps(AddressElements.place)}
                    label="Квартира"
                    width={190}
                    rules={[{ validator: addressValidators.house }]}
                />
            </div>
        </div>
    )
}
