import { ITariffGrid } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

import { ActionCell, IActionCellProps } from './ActionCell'
import { getStatusTagGrid } from './helper'

export const getGridConfig = (
    props: Omit<IActionCellProps, 'record'>,
): ColumnsType<ITariffGrid> => [
    {
        title: '№',
        dataIndex: 'code_id',
        width: '54px',
    },
    {
        title: 'Дата активации',
        dataIndex: 'active_from',
        width: '180px',
        render: DateTimeCell,
    },
    {
        title: 'Отправлено в архив',
        dataIndex: 'active_to',
        width: '180px',
        render: (value: string) => (value && dayjs().isAfter(value) ? DateTimeCell(value) : ''),
    },
    {
        title: 'Автор',
        dataIndex: 'created_by',
        ellipsis: true,
        width: '20%',
        render: (value: string, record: ITariffGrid) =>
            LinkCell(record.created_by_name || value, `/employees/${value}`),
    },
    {
        title: 'Дата создания',
        dataIndex: 'created_at',
        width: '180px',
        render: DateTimeCell,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        width: '110px',
        render: (_: string, record: ITariffGrid) => getStatusTagGrid(record),
    },
    {
        title: '',
        fixed: 'right',
        width: '38px',
        render: (_: string, record: ITariffGrid) => <ActionCell record={record} {...props} />,
    },
]
