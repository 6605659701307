import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    OrdinaryCardField,
    OrdinaryCardFieldContentColumn,
} from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { UneditableUploadedFiles } from '@components/Form'

export const DFAEncumbranceViewBody: FC = () => {
    const data = useSelector(encumbranceSelectors.selectItem)

    if (!data) {
        return null
    }

    return (
        <>
            <Card>
                <CardHeader title="Информация об обременении" />
                <CardContent>
                    <OrdinaryCardField label="Залогодатель">
                        <Link to={`/clients/${data.depositor_uuid}`}>
                            {data.depositor_full_name || data.depositor_uuid}
                        </Link>
                    </OrdinaryCardField>
                    <Divider margin={[32, 0]} />
                    <OrdinaryCardField label="Залогодержатель">
                        <Link to={`/clients/${data.pledgee_uuid}`}>
                            {data.pledgee_full_name || data.pledgee_uuid}
                        </Link>
                    </OrdinaryCardField>
                    <Divider margin={[32, 0]} />
                    <OrdinaryCardField label="Количество ЦФА, в отношении которых накладывается обременение">
                        {data.amount_dfa?.toLocaleString()}
                    </OrdinaryCardField>
                    <OrdinaryCardField label="Основание для наложения обременения">
                        <OrdinaryCardFieldContentColumn>
                            {data.set_reason}
                            <UneditableUploadedFiles
                                files={data.set_attachment_uuid?.map((uid) => ({ uid }))}
                            />
                        </OrdinaryCardFieldContentColumn>
                    </OrdinaryCardField>
                </CardContent>
            </Card>
            {data.removal_date && (
                <Card>
                    <CardHeader title="Информация о снятии обременения" />
                    <CardContent>
                        <OrdinaryCardField label="Основание для снятия обременения">
                            <OrdinaryCardFieldContentColumn>
                                {data.removal_reason}

                                <UneditableUploadedFiles
                                    files={data.removal_attachment_uuid?.map((uid) => ({ uid }))}
                                />
                            </OrdinaryCardFieldContentColumn>
                        </OrdinaryCardField>
                    </CardContent>
                </Card>
            )}
        </>
    )
}
