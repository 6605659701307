import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const DocumentCopy: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.1667 11.1667V13.6667C14.1667 17 12.8333 18.3333 9.5 18.3333H6.33333C3 18.3333 1.66667 17 1.66667 13.6667V10.5C1.66667 7.16666 3 5.83333 6.33333 5.83333H8.83333"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1667 11.1667H11.5C9.5 11.1667 8.83334 10.5 8.83334 8.5V5.83333L14.1667 11.1667Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.66667 1.66667H13"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83333 4.16667C5.83333 2.78334 6.95 1.66667 8.33333 1.66667H10.5167"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3333 6.66667V11.825C18.3333 13.1167 17.2833 14.1667 15.9917 14.1667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3333 6.66667H15.8333C13.9583 6.66667 13.3333 6.04167 13.3333 4.16667V1.66667L18.3333 6.66667Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
