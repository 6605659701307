import { NominalAccountModel } from '@dltru/dfa-models'
import { ColumnsType, LinkCell } from '@dltru/dfa-ui'

export const gridConfig: ColumnsType<NominalAccountModel> = [
    {
        title: 'Номер счёта',
        dataIndex: ['account_details', 'account_number'],
        render: (value: string, record: NominalAccountModel) =>
            LinkCell(value, `/nominal-account/${record.id}`),
    },
    {
        title: 'Владелец счёта (получатель)',
        dataIndex: ['account_details', 'recipient'],
        ellipsis: true,
    },
    {
        title: 'ИНН получателя',
        dataIndex: ['account_details', 'recipient_inn'],
        sorter: true,
    },
    {
        title: 'Наименование банка',
        dataIndex: ['account_details', 'bank'],
        sorter: true,
    },
    {
        title: 'ИНН банка',
        dataIndex: ['account_details', 'bank_inn'],
        sorter: true,
    },
]
