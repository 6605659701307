import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const UseTabsAndHash = (tabNames: string[], defaultTab: string | undefined = tabNames[0]) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(defaultTab)
    const onChangeTab = (tabName: string) => {
        setActiveTab(tabName)
        navigate(`${location.pathname}#${tabName}`)
    }
    useEffect(() => {
        const tabFromLocation = location.hash?.replace('#', '')
        if(!tabNames.includes(tabFromLocation)) {
            onChangeTab(defaultTab)
        } else if (activeTab !== tabFromLocation) {
            setActiveTab(tabFromLocation)
        }
    }, [location.hash])

    return {activeTab, onChangeTab }
}