import { Button, CloseCircleIcon } from '@dltru/dfa-ui'
import { FC, memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { putStatementRequestQualify } from '@store/qualifyStatement'

import { qualFormToPutRequest } from '@pages/QualificationStatement/utils'

import { IActionProps } from '../../../type'
import { RejectModal } from '../Modals/RejectModal'

export const RejectAction: FC<IActionProps> = memo(({ data }) => {
    const reduxDispatch = useDispatch()
    const [isShowModal, setIsShowModal] = useState(false)

    const onOkHandler = (value: string) => {
        if (data) {
            const status = 'declined'
            const formValue = qualFormToPutRequest({ ...data, status, comment: value })

            if (formValue) {
                reduxDispatch(putStatementRequestQualify(formValue))
            }
        }
        setIsShowModal(false)
    }

    return (
        <>
            <Button
                icon={<CloseCircleIcon />}
                borderRadius={16}
                onClick={() => {
                    setIsShowModal(true)
                }}
            >
                Отказать
            </Button>
            <RejectModal
                onOk={onOkHandler}
                onCancel={() => {
                    setIsShowModal(false)
                }}
                isModalVisible={isShowModal}
            />
        </>
    )
})
