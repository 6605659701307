import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ArrowUp: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 12 8"
                transform="rotate(180)"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.4108 6.91331L0.978085 2.31214C0.661416 1.98344 0.661416 1.46315 0.978084 1.13445C1.3119 0.787947 1.86671 0.787947 2.20053 1.13445L6.00005 5.07837L9.79957 1.13445C10.1334 0.787947 10.6882 0.787947 11.022 1.13445C11.3387 1.46315 11.3387 1.98344 11.022 2.31214L6.58931 6.91331C6.26387 7.25112 5.73623 7.25112 5.4108 6.91331Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
