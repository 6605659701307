import { DEFAULT_PER_PAGE, FactOfInjunctionModal, Table, downLoadFile } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { OrderDirect } from '@store/types'
import { getWalletLocksData } from '@store/wallet/locks'
import { selectWalletLocks } from '@store/wallet/locks/selectors'
import { WalletLimit } from '@store/wallet/locks/types'

import api from '@services/api'

import { injunctionsGridConfig } from './injunctionsGridConfig'

export const InjunctionsTable: FC<{ userId?: string }> = ({ userId }) => {
    const reduxDispatch = useDispatch()
    const [injunctionModal, setInjunctionModal] = useState(false)
    const [record, setRecord] = useState<Partial<WalletLimit>>({})
    const { data, paging, isLoading } = useSelector(selectWalletLocks)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(
            getWalletLocksData({
                userId,
                limit,
                order: { direct: OrderDirect.asc, column: 'id' },
                process_name: 'injunction',
            }),
        )
    }

    const onNext = () => {
        reduxDispatch(getWalletLocksData({ userId, cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(getWalletLocksData({ userId, cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(
            getWalletLocksData({
                userId,
                limit: DEFAULT_PER_PAGE,
                order: { direct: OrderDirect.asc, column: 'id' },
                process_name: 'injunction',
            }),
        )
    }, [])

    return (
        <>
            <Table
                isLoading={isLoading}
                columns={injunctionsGridConfig}
                dataSource={data}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                onRow={(record: WalletLimit) => {
                    return {
                        onClick: () => {
                            setRecord(record)
                            setInjunctionModal(true)
                        },
                    }
                }}
                scroll={{ y: undefined }}
                rowKey="id"
            />

            <FactOfInjunctionModal
                isModalVisible={injunctionModal}
                record={record}
                setIsModalVisible={setInjunctionModal}
                onDownloadFile={(uuid: string) => {
                    downLoadFile(uuid, api.lib.getBase64FileService)
                }}
            />
        </>
    )
}
