import { CheckOutlined } from '@ant-design/icons'
import { Rule } from 'antd/lib/form'
import clsx from 'clsx'
import { FC } from 'react'

import { Box, UnorderedListDot } from '../..'
import style from './style.m.less'

export type CheckListItem = {
    label: string
    rule: Rule
    checked: boolean
}

export interface ICheckList {
    elements: CheckListItem[]
}

export const CheckList: FC<ICheckList> = ({ elements }) => {
    return (
        <>
            <Box padding={[16, 8]}>
                {elements.map((element) => (
                    <Box direction="row" align="center" justify="left">
                        {element.checked ? (
                            <CheckOutlined
                                className={clsx(style.unorderedListIcon, style.checkedIcon)}
                            />
                        ) : (
                            <UnorderedListDot
                                className={clsx(style.unorderedListIcon, style.blackIcon)}
                            />
                        )}
                        <span>{element.label}</span>
                    </Box>
                ))}
            </Box>
        </>
    )
}
