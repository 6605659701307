import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { registrationApplicationListSlice } from './index'

function* getItems({
    payload,
}: ReturnType<typeof registrationApplicationListSlice.actions.getItems>) {
    try {
        yield* put(registrationApplicationListSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.getProfilesService, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield* put(registrationApplicationListSlice.actions.setItems(data?.item))
    } catch (error) {
        yield* put(registrationApplicationListSlice.actions.setError(error as string))
        openMessage({ type: 'error', message: 'Возникли ошибки при получении списка' })
    } finally {
        yield* put(registrationApplicationListSlice.actions.setIsLoading(false))
    }
}

export function* watchRegistrationRequestsSaga(): Generator<StrictEffect> {
    yield* takeLatest(registrationApplicationListSlice.actions.getItems.type, getItems)
}
