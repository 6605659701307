import { IndividualFormValues } from '@dltru/dfa-models'
import { IRoleFormStepProps, IStepper, PublicPersonStep, StepperC } from '@dltru/dfa-ui'
import { FC } from 'react'

import { BankDetailsStep } from '../components/BankDetailsStep'
import { FinancialOperationsStep } from '../components/FinancialOperationsStep'
import { Beneficiary, MainInfo } from './steps'

export const AnketaIndividualStepper: FC<
    Omit<IStepper<IndividualFormValues>, 'children'> & IRoleFormStepProps
> = ({ disableFields, isEditAllow, ...props }) => (
    <StepperC outOfValuesMerge={[2]} {...props}>
        <MainInfo isEditAllow={isEditAllow} disableFields={disableFields} />
        <PublicPersonStep stepIndex={1} isEditAllow={isEditAllow} />
        <Beneficiary isEditAllow={isEditAllow} />
        <BankDetailsStep stepIndex={3} isEditAllow={isEditAllow} />
        <FinancialOperationsStep stepIndex={4} isEditAllow={isEditAllow} />
    </StepperC>
)
