import { IApprovalHistoryUI } from '@dltru/dfa-models'
import { AlertIcon, ColumnsType, DateTimeCell, Space } from '@dltru/dfa-ui'
import clsx from 'clsx'

import { CommentView } from './CommentView'
import style from './style.m.less'

const State = (state: IApprovalHistoryUI['state']) => {
    if (!state) {
        return '--'
    }
    const wrapperStyle = clsx(style.smallIcon, style[state.type])
    return (
        <Space className={wrapperStyle} size={5}>
            <AlertIcon type={state.type} isBig className={style.smallIcon} />
            <span>{state.title}</span>
        </Space>
    )
}
export const gridConfig: ColumnsType<IApprovalHistoryUI> = [
    {
        title: 'Дата и время',
        dataIndex: 'start_datetime',
        key: 'start_datetime',
        render: DateTimeCell,
    },
    {
        title: 'Этап согласования',
        dataIndex: 'stage',
        key: 'stage',
    },
    {
        title: 'Дата согласования анкеты',
        dataIndex: 'approval_datetime',
        key: 'approval_datetime',
        render: DateTimeCell,
    },
    {
        title: 'Статус согласования',
        dataIndex: 'state',
        key: 'state',
        render: State,
    },
    {
        title: 'Ответственный сотрудник',
        dataIndex: 'name_operator',
        key: 'name_operator',
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        fixed: 'right',
        render: (_, record) => CommentView(record),
    },
]
