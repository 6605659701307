import {
    CollectType,
    IDfaFront,
    SettlementsType,
    SettlementsTypeTranslate,
} from '@dltru/dfa-models'
import FieldContext from 'rc-field-form/lib/FieldContext'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import { Radio, RadioChangeEvent } from '../../../Radio'
import { Space } from '../../../Space'
import { FormItem, FormItemProps, WrappedSelect } from '../../../components/Form'
import { EMPTY_FIELD_VALUE } from '../../../constants'
import './styles.less'

export type SettlementsFormValue = Pick<
    IDfaFront,
    'repayment_settlements_type' | 'issue_settlements_type'
>

interface IComponentProps {
    radioName: 'repayment_settlements_type' | 'issue_settlements_type'
    radioLabel: string
    selectName: 'payment_collect_type' | 'repayment_collect_type'
    formValues: SettlementsFormValue
    uneditable: boolean
    isAllowDirect?: boolean
    disabled?: boolean
    onChange?: React.Dispatch<React.SetStateAction<SettlementsFormValue>>
}

const selectLabel = 'Зачислить средства на'
export const SettlementCollectComponent: FC<IComponentProps> = ({
    radioName,
    selectName,
    radioLabel,
    formValues,
    uneditable,
    isAllowDirect = true,
    disabled,
    onChange,
}) => {
    const [currentSettlementsType, setCurrentSettlementsType] = useState(formValues[radioName])
    const { setFieldsValue, setFields } = useContext(FieldContext)
    const collectTypeOptions = useMemo(
        () => [
            {
                value: CollectType.account,
                label: `Лицевой счёт ${
                    radioName === 'issue_settlements_type' ? 'Эмитента' : 'Владельца'
                }`,
            },
            {
                value: CollectType.bank_account,
                label: `Банковский счёт ${
                    radioName === 'issue_settlements_type' ? 'Эмитента' : 'Владельца'
                }`,
            },
        ],
        [radioName],
    )
    const getUneditableRadio = () => {
        const value = formValues?.[radioName]
        return value ? SettlementsTypeTranslate[value] ?? value : EMPTY_FIELD_VALUE
    }

    const renderUneditableSelect = (value?: string) => {
        if (!value) {
            return null
        }
        const findedValue = collectTypeOptions.find((item) => item.value === value)
        return `${selectLabel} ${findedValue?.label ?? value}`
    }

    const onRadioChange = (e: RadioChangeEvent) => {
        const value = e.target?.value
        if (value !== SettlementsType.platform) {
            setFields([{ name: selectName, value: undefined, errors: undefined }])
        }
        setCurrentSettlementsType(value)
    }

    useEffect(() => {
        if (isAllowDirect === false) {
            setFieldsValue({ [radioName]: SettlementsType.platform })
            setCurrentSettlementsType(SettlementsType.platform)
            onChange?.((values) => ({ ...values, [radioName]: SettlementsType.platform }))
        } else {
            setFieldsValue({ [radioName]: SettlementsType.direct })
            setCurrentSettlementsType(SettlementsType.direct)
            onChange?.((values) => ({ ...values, [radioName]: SettlementsType.direct }))
        }
    }, [isAllowDirect, uneditable])

    useEffect(() => {
        setCurrentSettlementsType(formValues[radioName])
    }, [formValues[radioName]])

    return (
        <div className="formRow">
            {uneditable ? (
                <div className="ant-row ant-form-item form-item formComponentItem form-item--uneditable">
                    <div className="ant-col ant-form-item-label">
                        <label>{radioLabel}</label>
                    </div>
                    <div className="ant-col ant-form-item-control">
                        {getUneditableRadio()}
                        <WrappedSelect
                            name={selectName}
                            options={collectTypeOptions}
                            uneditable={uneditable}
                            disabled={disabled}
                            renderUneditable={
                                renderUneditableSelect as FormItemProps['renderUneditable']
                            }
                        />
                    </div>
                </div>
            ) : (
                <FormItem
                    className="formComponentItem"
                    name={radioName}
                    label={radioLabel}
                    required
                    uneditable={uneditable}
                >
                    <Radio.Group onChange={onRadioChange}>
                        <Space direction="vertical" size={4}>
                            {!isAllowDirect && (
                                <>
                                    <Radio
                                        key={SettlementsType.platform}
                                        value={SettlementsType.platform}
                                        disabled={disabled}
                                    >
                                        {SettlementsTypeTranslate[SettlementsType.platform]}
                                    </Radio>
                                    <WrappedSelect
                                        className="small-collect-select"
                                        label={selectLabel}
                                        name={selectName}
                                        options={collectTypeOptions}
                                        uneditable={uneditable}
                                        disabled={
                                            disabled ||
                                            currentSettlementsType !== SettlementsType.platform
                                        }
                                        required={
                                            currentSettlementsType === SettlementsType.platform
                                        }
                                    />
                                </>
                            )}

                            {isAllowDirect && (
                                <Radio key={SettlementsType.direct} value={SettlementsType.direct}>
                                    {SettlementsTypeTranslate[SettlementsType.direct]}
                                </Radio>
                            )}
                        </Space>
                    </Radio.Group>
                </FormItem>
            )}
        </div>
    )
}
