import { InvestorRegistryApplication } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell, Lock16 } from '@dltru/dfa-ui'

import { Status } from './components/Status'

const UserCell = (value: string, clientId: string, isBlocked: boolean) => {
    if (isBlocked) {
        return LinkCell(value, `/clients/${clientId}`, undefined, <Lock16 />)
    }
    return LinkCell(value, `/clients/${clientId}`)
}

export const gridConfig: ColumnsType<InvestorRegistryApplication> = [
    {
        title: 'Дата подачи заявки',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 200,
        render: DateTimeCell,
    },
    {
        title: 'Пользователь',
        dataIndex: 'user_name',
        key: 'user_name',
        render: (value, record) => UserCell(value, record.user_uuid, false),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        width: 200,
        render: (value) => (
            <div style={{ display: 'flex' }}>
                <Status status={value} />
            </div>
        ),
    },
]
