import {
    AuthForm,
    Box,
    Button,
    CheckCircleOutlined,
    Form,
    FormItem,
    PhoneInput,
    Timer,
    WrappedInput,
    prefixSelectorFactory,
} from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { dropPhoneError, setPhoneConfirm, setPhoneRequest } from '@store/auth'
import IAppState from '@store/states'

import { AuthCodeErrorModal } from './AuthCodeErrorModal'
import { TelephoneAlreadyInUseErrorModal } from './TelephoneAlreadyInUseErrorModal'
import './style.less'

export const TelephoneAuthForm: FC = () => {
    const reduxDispatch = useDispatch()
    const { statusSetPhone, phone, setPhoneError } = useSelector((store: IAppState) => store.auth)
    const [form] = Form.useForm()
    const [prefix, setPrefix] = useState('7')
    const [codeStatus, setCodeStatus] = useState<'timer' | 'callAgain'>('timer')
    const [isPhoneButtonDisabled, setIsPhoneButtonDisabled] = useState(true)
    const [authCodeErrorModalStatus, setAuthCodeErrorModalStatus] = useState(false)
    const [isMainButtonDisabled, setIsMainButtonDisabled] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        if (statusSetPhone === 'pass') {
            handleRedirect()
        }

        if (phone) {
            setIsPhoneButtonDisabled(false)
        }

        if (statusSetPhone === 'confirmed') {
            if (phone) {
                setIsMainButtonDisabled(false)
            }
        }
    }, [statusSetPhone, phone])

    const handleRedirect = async () => {
        navigate('/offers')
    }

    return (
        <>
            <AuthForm
                title="Подтверждение номера телефона"
                form={form}
                style={{ maxWidth: '440px' }}
                onFinish={() => handleRedirect()}
            >
                <Box width="360px">
                    <span>
                        Укажите ваш номер телефона, на него поступит смс с кодом подтверждения.
                        Введите код из смс в соответствующее поле.
                    </span>
                </Box>
                <Box padding={[24, 0]} direction="row" align="top" justify="space-between">
                    <Box width="245px" align="top">
                        <PhoneInput
                            disabled={Boolean(phone)}
                            addonBefore={prefixSelectorFactory(prefix, setPrefix)}
                            name="phone"
                            className="no-label-padding no-error-field"
                            label="Телефон"
                            rules={[{ required: true, message: '' }]}
                            style={{ width: '100%', flex: 23 }}
                            prefix={prefix}
                            maxLength={11 - prefix.length}
                            onChange={(e) =>
                                setIsPhoneButtonDisabled(e.target.value.length < 11 - prefix.length)
                            }
                        />
                        {statusSetPhone === 'await' && (
                            <span className="label-under-input">Введите код из СМС</span>
                        )}
                    </Box>
                    <Box margin={[0, 0, 0, 16]} width="122px">
                        {statusSetPhone === 'ready' && (
                            <div className="phone-button">
                                <Button
                                    size="middle"
                                    width="100%"
                                    borderRadius={12}
                                    disabled={isPhoneButtonDisabled}
                                    className="icon-in-center"
                                    type="primary"
                                    onClick={() => {
                                        const _phone = prefix + form.getFieldValue('phone')
                                        reduxDispatch(setPhoneRequest(_phone))
                                    }}
                                >
                                    Получить код
                                </Button>
                            </div>
                        )}
                        {statusSetPhone === 'await' && (
                            <>
                                <WrappedInput
                                    name="phoneCode"
                                    label="Код"
                                    placeholder="4 цифры"
                                    className="no-label-padding no-error-field"
                                    maxLength={4}
                                    validateStatus={
                                        form.isFieldTouched(['phoneCode']) ? 'error' : 'success'
                                    }
                                    onChange={(event) => {
                                        if (event.target.value.length === 4) {
                                            reduxDispatch(setPhoneConfirm(event.target.value))
                                        }
                                    }}
                                />
                                {codeStatus === 'timer' && (
                                    <Timer
                                        secondsRemaining={30}
                                        onFinish={() => {
                                            setCodeStatus('callAgain')
                                        }}
                                    />
                                )}
                                {codeStatus === 'callAgain' && (
                                    <Button
                                        className="link-color label-under-input label-center btn-as-label"
                                        onClick={() => {
                                            const _phone = prefix + form.getFieldValue('phone')
                                            reduxDispatch(setPhoneRequest(_phone))
                                            setCodeStatus('timer')
                                        }}
                                        type="link"
                                    >
                                        Повторить СМС
                                    </Button>
                                )}
                            </>
                        )}
                        {statusSetPhone === 'confirmed' && (
                            <div className="success-phone-icon-box">
                                <CheckCircleOutlined className="modal-icon modal-success-icon" />
                            </div>
                        )}
                    </Box>
                </Box>
                <FormItem className="no-error-field">
                    <Box direction="row" justify="right">
                        <Button
                            disabled={isMainButtonDisabled}
                            borderRadius={16}
                            type="primary"
                            htmlType="submit"
                            size="large"
                        >
                            Войти
                        </Button>
                    </Box>
                </FormItem>
            </AuthForm>
            <TelephoneAlreadyInUseErrorModal
                visible={setPhoneError}
                onCancel={() => {
                    reduxDispatch(dropPhoneError())
                }}
                onOk={() => {
                    reduxDispatch(dropPhoneError())
                }}
            />
            <AuthCodeErrorModal
                visible={authCodeErrorModalStatus}
                onCancel={() => {
                    form.setFieldsValue({ phoneCode: '' })
                    setAuthCodeErrorModalStatus(false)
                }}
                onOk={() => {
                    form.setFieldsValue({ phoneCode: '' })
                    setAuthCodeErrorModalStatus(false)
                }}
            />
        </>
    )
}
