import AntIcon from '@ant-design/icons'
import { FC, SVGProps } from 'react'

import { IconProps } from './types'

export interface SVGIconProps {
    viewBox?: Pick<SVGProps<SVGElement>, 'viewBox'>
}

export const ArrowDownSmall: FC<SVGIconProps & IconProps> = ({
    viewBox = '0 0 16 16',
    ...props
}) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox={viewBox as string}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.52869 11.1307L3.98252 7.44972C3.72918 7.18675 3.72918 6.77052 3.98252 6.50756C4.24957 6.23036 4.69342 6.23036 4.96047 6.50756L8.00009 9.66269L11.0397 6.50756C11.3068 6.23036 11.7506 6.23036 12.0177 6.50756C12.271 6.77052 12.271 7.18675 12.0177 7.44972L8.4715 11.1307C8.21115 11.4009 7.78904 11.4009 7.52869 11.1307Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
