import cn from 'classnames'
import { FC } from 'react'

import './styles.less'

export type OrdinaryCardFieldProps = { label?: string; direction?: 'row' | 'column' }
export const OrdinaryCardField: FC<OrdinaryCardFieldProps> = ({
    label,
    children,
    direction = 'row',
}) => {
    const className = cn('ordinary-card-field', {
        'ordinary-card-field--column': direction === 'column',
    })
    return (
        <div className={className}>
            <div className="ordinary-card-field__label">{label}</div>
            <div className="ordinary-card-field__content">{children}</div>
        </div>
    )
}
