// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footnote_UYej_ {\n  position: relative;\n  padding-left: 24px;\n  font-size: 12px;\n  line-height: 20px;\n  color: #555861;\n}\n.footnote_UYej_::before {\n  content: '*';\n  position: absolute;\n  left: 12px;\n  top: 0;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/components/BankCollectCommission/styles.m.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADF;AAEE;EACE,YAAA;EACA,kBAAA;EACA,UAAA;EACA,MAAA;EACA,eAAA;AAAJ","sourcesContent":["@import 'style/palette';\n\n.footnote {\n  position: relative;\n  padding-left: 24px;\n  font-size: 12px;\n  line-height: 20px;\n  color: @gray-8;\n  &::before {\n    content: '*';\n    position: absolute;\n    left: 12px;\n    top: 0;\n    font-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footnote": "footnote_UYej_"
};
export default ___CSS_LOADER_EXPORT___;
