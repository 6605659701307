import { Input } from 'antd'
import React, { FC, useCallback } from 'react'
import NumberInput, { NumberFormatProps } from 'react-number-format'

import { NOT_EMPTY } from '../../../utils'
import { FormItem, FormItemProps } from '../FormItem'
import { InputProps } from './Input'

const { TextArea } = Input

export type WrappedInputProps = FormItemProps &
    InputProps & {
        currencyProps?: Partial<NumberFormatProps>
    } & Pick<NumberFormatProps, 'mask' | 'format'>

export const WrappedInput: FC<WrappedInputProps> = React.forwardRef(
    (
        {
            disabled,
            required = false,
            prefix,
            placeholder,
            multiline = false,
            maxLength,
            minLength,
            onChange,
            rules = [],
            format,
            mask,
            addonBefore,
            currencyProps,
            password,
            size,
            rows = 3,
            onBlur,
            onPressEnter,
            autoComplete,
            ...rest
        },
        ref,
    ) => {
        const CustomInput = useCallback(
            (props) => (
                <Input
                    prefix={prefix}
                    addonBefore={addonBefore}
                    size={size}
                    maxLength={maxLength}
                    onPressEnter={onPressEnter}
                    ref={ref}
                    {...props}
                />
            ),
            [addonBefore, size, prefix, ref, onPressEnter],
        )
        const getFormElement = () => {
            if (multiline) {
                return (
                    <TextArea
                        rows={rows}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        minLength={minLength}
                        disabled={disabled}
                    />
                )
            }
            if (password) {
                return (
                    <Input.Password
                        prefix={prefix}
                        addonBefore={addonBefore}
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={onChange}
                        maxLength={maxLength}
                        minLength={minLength}
                        size={size}
                        ref={ref}
                        onBlur={onBlur}
                    />
                )
            }
            if (format || currencyProps) {
                return (
                    <NumberInput
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={onChange}
                        customInput={CustomInput}
                        mask={mask}
                        format={format}
                        onBlur={onBlur}
                        {...(currencyProps || {})}
                    />
                )
            }
            return (
                <Input
                    prefix={prefix}
                    addonBefore={addonBefore}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                    minLength={minLength}
                    size={size}
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                />
            )
        }

        return (
            <FormItem {...rest} required={required} rules={[NOT_EMPTY, ...rules]}>
                {getFormElement()}
            </FormItem>
        )
    },
)
