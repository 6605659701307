export const ApiPaths = {
    Auth: '/auth/v1/auth',
    AuthRegistration: '/auth/v1/registration',
    AuthPassword: '/auth/v1/password',
    AuthLogout: '/api/v1/users/logout/',
    Auth2FAInit: '/api/v1/users/2fa_init/',
    Auth2FAConfirm: '/api/v1/users/2fa_confirm/',
    AuthSetPhone: '/api/v1/users/phone/',
    AuthPhoneConfirm: '/api/v1/users/phone_confirm/',
    AuthResetPasswordRequest: '/api/v1/users/password/recover/',
    AuthResetPasswordInit: '/api/v1/users/password/recover/init/',
    AuthResetPasswordConfirm: '/api/v1/users/password/recover/confirm/',
    AuthEmailConfirmForRegistration: '/auth/v1/confirm/{token}',
    AuthEmailConfirmForResettingPassword: '/api/v1/users/validate_confirm_token/{token}/',
    Users2faInit: '/api/v1/users/2fa_init/',
    Users2faConfirm: '/api/v1/users/2fa_confirm/',
    UsersRefresh: '/api/v1/users/refresh/',
    UsersPhone: '/api/v1/users/phone/',
    UsersBlockSession: '/api/v1/users/block_session/',
    UsersPhoneConfirm: '/api/v1/users/phone_confirm/',
    UsersRole: '/api/v1/users/role/',
    Users: '/api/v1/users/',
    UsersFullProfile: '/api/v1/users/full_information/{id}/',
    UsersAgreements: '/api/v1/users/agreements/',
    UsersAgreementsByUser: '/api/v1/users/agreements/{user_uuid}/',
    UsersMakeFullBlock: '/api/v2/users/full_block/',
    UsersMakePartlyBlock: '/api/v2/users/partly_block/',
    UsersMakeFullUnblock: '/api/v2/users/full_unblock/',
    UsersMakePartlyUnblock: '/api/v2/users/partly_unblock/',
    UsersAuthorizationDataById: '/api/v1/users/authorization_data/{id}/',
    GetUserBlockingEventByUuid: '/api/v1/users/user_block_events/{uuid}/',
    GetUsersBlockingEvents: '/api/v1/users/user_block_events/',
    UsersCheckSessionById: '/api/v1/users/check_session/{id}/',
    UsersChangePhoneInitAdmin: '/api/v1/users/change_auth_data_set_phone_init/',
    UsersChangePasswordInitAdmin: '/api/v1/users/change_auth_data_set_password_init/',
    UsersChangeLoginInitAdmin: '/api/v1/users/change_auth_data_set_login_init/',
    UsersChangeAuthLoginSet: '/api/v1/users/change_auth_data_login_set/',
    UsersChangeAuthLoginConfirm: '/api/v1/users/change_auth_data_login_confirm/',
    UsersChangeEmailInitAdmin: '/api/v1/users/change_auth_data_set_email_init/',
    UsersChangeEmailInitAdminConfirm: '/api/v1/users/change_auth_data_email_confirm/',
    UsersChangeAuthPhoneSet: '/api/v1/users/change_auth_data_set_phone/',
    UsersChangeAuthPhoneConfirm: '/api/v1/users/change_auth_data_phone_confirm/',
    UsersChangePasswordByAdminConfirm: '/api/v1/users/change_auth_data_password_confirm/',
    UsersTerminationByClient: '/api/v1/users/service_termination_by_user/',
    UsersTerminationByAdmin: '/api/v1/users/service_termination_by_operator/',
    UsersChangeLoginInitByUser: '/api/v1/users/user_change_login_init/',
    UsersChangeLoginConfirmByUser: '/api/v1/users/user_change_login_confirm/',
    UsersChangePasswordInitByUser: '/api/v1/users/user_change_password_init/',
    UsersChangePasswordConfirmByUser: '/api/v1/users/user_change_password_confirm/',
    UsersRegistry: '/api/v1/users/registry/',
    Services: `/api/v1/services/{name}`,
    DfaV2: '/api/v2/dfa/',
    Dfa: `/api/v1/dfa/`,
    DfaById: `/api/v1/dfa/{dfaId}/`,
    DfaPatchRedeemDate: '/api/v1/dfa/{dfaId}/entry/redeem_date/',
    DfaAvailable: '/api/v1/dfa/{dfaId}/available/',
    DfaAvailableV2: '/api/v2/dfa/{dfaId}/available/',
    DfaStatus: `/api/v1/dfa/status/{status}/`,
    DfaConfirm: `/api/v1/dfa/{dfaId}/confirm/`,
    DfaReject: `/api/v1/dfa/{dfaId}/reject/`,
    DfaRejectV2: '/api/v2/dfa/{dfaId}/reject/',
    DfaIssuerConfirmation: '/api/v2/dfa/{dfaId}/issuer_confirmation/',
    DfaMakeIssueFailed: '/api/v1/dfa/{dfaId}/issue/failure/',
    DfaEntryEmitterLink: `/api/v1/dfa/{dfaId}/entry/emitter_link/`,
    DfaEntryOisLink: `/api/v1/dfa/{dfaId}/entry/ois_link/`,
    DfaIssue: `/api/v2/dfa/{dfaId}/issue/`,
    DfaConfirmSecondary: '/api/v3/dfa/{dfaId}/confirm/secondary/',
    DfaConfirmToLawyer: '/api/v2/dfa/{asset_id}/lawyer_approval/',
    DfaConfirmToTeller: '/api/v2/dfa/{asset_id}/teller_approval/',
    DfaConfirmToSpec: '/api/v2/dfa/{asset_id}/spec_approval/',
    DfaToModification: '/api/v2/dfa/{asset_id}/modification/',
    DfaToCollectionStarted: '/api/v2/dfa/{asset_id}/collection_started/',
    DfaOrdersByDfaId: `/api/v1/dfa/{dfaId}/orders/`,
    DfaInvestOrdersV3: '/api/v3/dfa/invest_order/',
    DfaOrders: `/api/v1/dfa/orders/`,
    DfaOrdersByOrderId: `/api/v1/dfa/orders/{orderId}/`,
    DfaOrderAcceptedByEmitter: `/api/v2/dfa/order/accepted_by_emitter/`,
    DfaOrderRefusedByEmitter: `/api/v2/dfa/order/refused_by_emitter/`,
    DfaDealsById: `/api/v1/dfa/{dfaId}/deals/`,
    DfaDeals: `/api/v1/dfa/deals/`,
    DfaCounter: `/api/v1/dfa/number/`,
    DfaApprovalHistoryByDfaId: '/api/v1/dfa/approval_history/{dfaId}/',
    DfaApprovalHistoryForAdmin: '/api/v1/dfa/approval_history_by_admin/{dfaId}/',
    DfaDealsByDealId: `/api/v1/dfa/deals/{dealId}/`,
    DfaDevEmission: `/api/v1/dfa/dev/{dfaId}/emission/`,
    DfaCheckTicker: '/api/v1/dfa/{dfaTickerSymbol}/check_ticker/',
    SecondaryDfaOrderSell: '/api/v2/secondary_dfa/order/sell/',
    SecondaryDfaOrderSellReject: '/api/v2/secondary_dfa/order/sell/reject/',
    SecondaryDfaOrderSellCancel: '/api/v2/secondary_dfa/order/sell/cancel/',
    SecondaryDfaOrderBuyByOrderId: '/api/v2/secondary_dfa/order/buy/{sellOrderId}/',
    SecondaryDfaOrderBuy: '/api/v2/secondary_dfa/order/buy/',
    SecondaryDfaOrderBuyReject: '/api/v2/secondary_dfa/order/buy/reject/',
    SecondaryDfaOrderBuyCancel: '/api/v2/secondary_dfa/order/buy/cancel/',
    SecondaryDfaOrderSellByOrderId: '/api/v2/secondary_dfa/order/sell/{buyOrderId}/',
    SecondaryDfaDeals: '/api/v1/secondary_dfa/deals/',
    RepaymentDfaRepaymentV4: '/api/v4/repayment_dfa/repayment/',
    RepaymentDfaDeals: '/api/v1/repayment_dfa/deals/',
    RepaymentDfaConfirmByInvestorV2: '/api/v2/repayment_dfa/confirm/by_investor/',
    RepaymentDfaPayoffAgreements: '/api/v1/repayment_dfa/payoff_agreements/',
    RepaymentDfaByOperator: '/api/v1/repayment_dfa/operator_repayment/',
    RepaymentMatureInvestor: '/api/v1/repayment_dfa/operator_repayment/investor/',
    RepaymentMatureAsset: '/api/v1/repayment_dfa/operator_repayment/asset/',
    SecondaryDfaOrders: '/api/v1/secondary_dfa/orders/',
    WalletDfaOwners: '/api/v1/wallet_dfa/{dfaId}/owners/',
    WalletDfaByUserId: '/api/v1/wallet_dfa/{userId}/',
    WalletDfaBalances: '/api/v1/wallet_dfa/{userId}/balances/',
    WalletEncumbranceGetByUser: '/api/v1/wallet_dfa/encumbrance/locks/{user_uuid}/',
    WalletEncumbranceGetById: '/api/v1/wallet_dfa/encumbrance/{encumbrance_uuid}/',
    WalletEncumbranceSetByUserV2: '/api/v2/wallet_dfa/encumbrance/set_by_user/',
    WalletEncumbranceRemoveByUserV2: '/api/v2/wallet_dfa/encumbrance/remove_by_user/',
    WalletEncumbranceSetByOperator: '/api/v1/wallet_dfa/encumbrance/set_by_operator/',
    WalletEncumbranceSetByOperatorV2: '/api/v2/wallet_dfa/encumbrance/set_by_operator/',
    WalletEncumbranceRemoveByOperator: '/api/v1/wallet_dfa/encumbrance/remove_by_operator/',
    WalletEncumbranceRemoveByOperatorV2: '/api/v2/wallet_dfa/encumbrance/remove_by_operator/',
    FilesUpload: '/api/v1/files/upload/',
    FilesById: '/api/v1/files/{id}/',
    FilesDocx: '/api/v1/files/docx/',
    FilePaymentOrder: '/api/v1/money/payment_orders/file/',
    MoneyTransactionCredit: '/api/v1/money/transaction/credit/',
    MoneyTransactionDebit: '/api/v1/money/transaction/debit/',
    MoneyReserveTransfer: '/api/v1/money/reserve/transfer/',
    MoneyReserveReleaseTransferSauToBank:
        '/api/v1/money/reserve/transfer_init_sau_to_bank_account/',
    MoneyAccountBalance: '/api/v1/money/account/{accountId}/balance/',
    MoneyAccountTransactions: '/api/v1/money/account/{accountId}/transactions/',
    MoneyTransactions: '/api/v1/money/transactions/',
    MoneyTransactionByTransactionId: '/api/v1/money/transaction/{transactionId}/',
    MoneyAccountReserves: '/api/v1/money/account/{accountId}/reserves/',
    MoneyAccountReservesPaging: '/api/v1/money/reserves/',
    MoneyAccountByAccountId: '/api/v1/money/account/{account_id}/',
    MoneyAccountsByAccountId: '/api/v1/money/accounts/{accountId}/',
    MoneyAccountsBalanceByAccountIdV2: '/api/v2/money/accounts/{accountId}/',
    MoneyAccounts: '/api/v1/money/accounts/',
    MoneyAccountCheckBlock: '/api/v1/money/account/{accountId}/check-block/',
    MoneyAccountCheckSum: '/api/v1/money/account/{account_uuid}/check-sum/{sum}',
    MoneyPaymentOrders: '/api/v1/money/payment_orders/',
    MoneyPaymentOrdersByPaymentOrderId: '/api/v1/money/payment_orders/{payment_order_id}/',
    MoneyReserveTransferConfirmSauToBankAccount:
        '/api/v1/money/reserve/transfer_confirm_sau_to_bank_account/',
    MoneyReservePaymentOrderCancelSauToBankAccount:
        '/api/v1/money/reserve/payment_order_cancel_sau_to_bank_account/',
    MoneyReserveTransferCancelSauToBankAccount:
        '/api/v1/money/reserve/transfer_cancel_sau_to_bank_account/',
    MoneyPaymentOrdersGenerate: '/api/v1/money/payment_orders/generate/',
    PaymentOrdersFile: '/api/v1/payment_orders/file/',
    MoneyUserBankAccountsByUserId: '/api/v1/money/user_bank_accounts/{user_uuid}/',
    MoneyNominalAccounts: '/api/v1/money/nominal_account/',
    MoneyNominalAccountById: '/api/v1/money/nominal_account/{nominal_account_uuid}/',
    MoneyNominalAccountBind: '/api/v1/money/account/bind/',
    MoneyRelatedAccountsByNominalAccount:
        '/api/v1/money/nominal_account/{nominal_account_uuid}/accounts/',
    BankAccountsCheckUnique: '/api/v1/money/user_bank_accounts/check-uniqueness/',
    ProfileCommon: '/api/v1/profile/common/',
    ProfileByTypeAndId: '/api/v1/profile/{type}/{uuid}/',
    ProfilePublicInformationByTypeAndId: '/api/v1/profile/{profileType}/public_information/{uuid}/',
    ProfileLegalEntity: '/api/v1/profile/legal_entity/',
    ProfileLegalEntityByProfileId: '/api/v1/profile/legal_entity/{profileId}/',
    ProfileLegalEntityPublicInformation:
        '/api/v1/profile/legal_entity/public_information/{profileId}/',
    ProfileBusinessman: '/api/v1/profile/businessman/',
    ProfileBusinessmanByProfileId: '/api/v1/profile/businessman/{profileId}/',
    businessmanPublicInformation: '/api/v1/businessman/public_information/{profileId}/',
    ProfileBeneficialOwner: '/api/v1/profile/beneficial_owner/',
    ProfileBeneficialOwnerByProfileId: '/api/v1/profile/beneficial_owner/{profileId}/',
    ProfileIndividual: '/api/v1/profile/individual/',
    ProfileIndividualByProfileId: '/api/v1/profile/individual/{profileId}/',
    ProfileIndividualPublicInformation:
        '/api/v1/profile/individual/public_information/{profileId}/',
    ProfileAgent: '/api/v1/profile/agent/',
    ProfileAgentByProfileId: '/api/v1/profile/agent/{profileId}/',
    ProfileAgentAgentsByCreatorId: '/api/v1/profile/agent/agents/{creator_uuid}/',
    ProfileType: '/api/v1/profile/type/',
    ProfileChangeStatus: '/api/v1/profile/change_status/',
    ProfileChangeStatusV2: '/api/v2/adapter/accounts/',
    ProfileApprovalHistory: '/api/v1/profile/approval_history/{user_uuid}/',
    ProfileClientStatuses: '/api/v1/profile/client_statuses/',
    ProfileCheckInn: '/api/v1/profile/check_inn/',
    ProfileClients: '/api/v1/profile/clients/',
    ProfileGetUidByInn: '/api/v1/profile/user/',
    ProfileGetUidsByInnType: '/api/v1/profile/users/',
    ProfileIdentification: '/api/v1/profile/identification_info/{user_uuid}/',
    PutProfileUpdateByUuid: '/api/v1/profile/user/{user_uuid}/',
    ProfileBlacklistByUuid: '/api/v1/blacklists/user/{user_uuid}/',
    ProfileApplications: '/api/v1/profile/applications/',
    AdapterDfaDeploy: '/api/v1/adapter/dfa/deploy/',
    AdapterDfaInvest: '/api/v1/adapter/dfa/invest/',
    AdapterDfaIssue: '/api/v1/adapter/dfa/issue/',
    AdapterDfaMature: '/api/v1/adapter/dfa/mature/',
    AdapterDfaPayoff: '/api/v1/adapter/dfa/payoff/',
    AdapterDfaPaid: '/api/v1/adapter/dfa/paid/',
    AdapterDfaTransferByTransferType: '/api/v1/adapter/dfa/transfer/{transferType}/',
    AdapterDfaInjunction: '/api/v1/adapter/dfa/injunction/',
    AdapterDfaLiftingInjunction: '/api/v1/adapter/dfa/lifting-injunction/',
    TariffPlane: '/api/v2/tariff/tariffs/',
    TariffPlaneArchive: '/api/v2/tariff/{tariff_id}/archive/',
    TariffFees: '/api/v2/tariff/fees/',
    TariffGrids: '/api/v2/tariff/grids/',
    TariffGrid: '/api/v2/tariff/grids/{grid_id}/',
    TariffGridActivate: '/api/v2/tariff/grids/{grid_id}/activate/',
    TariffGridDeactivate: '/api/v2/tariff/grids/{grid_id}/deactivate/',
    TariffCalculateFee: '/api/v1/tariff/calculate-fee/',
    TariffLinkUser: '/api/v2/tariff/link_user/',
    TariffUnlinkUser: '/api/v2/tariff/unlink_user/{link_id}/',
    TariffUsers: '/api/v2/tariff/{tariff_id}/users/',
    TariffAssets: '/api/v2/tariff/{tariff_id}/assets/',
    TariffLinkAsset: '/api/v2/tariff/link_asset/',
    TariffUnlinkAsset: '/api/v2/tariff/unlink_asset/{link_id}/',
    TransferDfaOrderInitV2: '/api/v2/transfer_dfa/order/',
    TransferDfaOrderAcceptV2: '/api/v2/transfer_dfa/order/accept/',
    TransferDfaDealByOperatorV2: '/api/v2/transfer_dfa/order/deal_by_operator/',
    TransferDfaOrderExecuteV2: '/api/v2/transfer_dfa/order/execute/',
    TransferDfaOrderCancelByOperatorV2: '/api/v2/transfer_dfa/order/cancel_by_operator/',
    TransferCancelByOfferorV2: '/api/v2/transfer_dfa/order/cancel_by_offeror/',
    TransferDfaOrderAccept: '/api/v1/transfer_dfa/order/accept/',
    TransferDfaOrderByOrderId: '/api/v1/transfer_dfa/order/{orderId}/',
    TransferDfaOrdersByGlobalId: '/api/v1/transfer_dfa/orders/{globalId}',
    TransferDfaOrders: '/api/v1/transfer_dfa/orders/',
    TransferDfaDeals: '/api/v1/transfer_dfa/deals/',
    TransferDfaDealByOperator: '/api/v1/transfer_dfa/deal_by_operator/',
    TransferCancelByOwner: '/v1/transfer_dfa/order/{uuid}/cancel_by_owner/',
    TransferReject: '/v1/transfer_dfa/order/{uuid}/reject/',
    TransferCancelByRecipient: '/v1/transfer_dfa/order/{uuid}/cancel_by_recipient/',
    QualifyRegistrySet: '/api/v1/investor_registry/set_qualified/',
    QualifyInfo: '/api/v1/investor_registry/investors/{user_uuid}/',
    QualifyRegistryDelete: '/api/v1/investor_registry/set_unqualified/',
    QualifyRegistryRequest: '/api/v1/investor_registry/applications/',
    QualifyRegistryRequestPost: '/api/v1/investor_registry/application/',
    QualifyRegistryEvents: '/api/v1/investor_registry/application_events/',
    LockDfaByUserId: '/api/v1/lock_dfa/{user_id}/',
    LockDfaInjunction: '/api/v1/lock_dfa/injunction/',
    LockDfaLiftingInjunction: '/api/v1/lock_dfa/lifting-injunction/',
    DfaEntry: '/api/v1/dfa/{id}/entry/{endpoint}/',
    EmitmentStop: '/api/v1/dfa/{asset_id}/issue/stop/',
    EmitmentUnstop: '/api/v1/dfa/{asset_id}/issue/unstop/',
    EmitmentRevoke: '/api/v1/dfa/{asset_id}/revoke/',
    CertificatesUserCertificates: '/api/v1/csp_certs/certificates/',
    CertificatesUserCertificateByUid: '/api/v1/csp_certs/certificates/{uuid}/',
    AdapterTransactionV2: '/api/v2/adapter/transaction/',
    AdapterTransactionsV2: '/api/v2/adapter/transactions/',
    WalletDfaInjunctionSet: '/api/v2/wallet_dfa/injunction/set/',
    WalletDfaInjunctionRemove: '/api/v2/wallet_dfa/injunction/remove/',
    CalculateRepaymentFee: '/api/v1/repayment_dfa/calculate_fee/',
    CalculateInvestorRepaymentFee:
        '/api/v1/repayment_dfa/operator_repayment/investor/calculate_fee/',
    CalculateMatureRepaymentFee: '/api/v1/repayment_dfa/operator_repayment/asset/calculate_fee/',
    DealsList: '/api/v1/deals/',
    DealByUuid: '/api/v1/deals/{deal_id}/',
    Employee: '/api/v1/employee/',
    EmployeeEdit: '/api/v1/employee/{user_uuid}/',
    EmployeeBlock: '/api/v1/employee/block/{user_uuid}/',
    EmployeeUnBlock: '/api/v1/employee/unblock/{user_uuid}/',
    EmployeeArchive: '/api/v1/employee/archive/{user_uuid}/',
    Documents: '/api/v1/documents_generator/documents',
    ExternalRates: '/api/v1/external_rates/{index}',
    WalletDfaOwnersCount: '/api/v1/wallet_dfa/owners/{asset_id}/count/',
    GetAccountByType: '/api/v1/money/account/by_type/{accountType}/',
    ProfileLegalEntityPrivateInformation:
        '/api/v1/profile/legal_entity/private_information/{uuid}/',
    ProfileBusinessmanPrivateInformation: '/api/v1/profile/businessman/private_information/{uuid}/',
    ProfileIndividualPrivateInformation: '/api/v1/profile/individual/private_information/{uuid}/',
    ProfilePrivateInformationByTypeAndId: '/api/v1/profile/{type}/private_information/{uuid}/',
    UserChangePhoneInit: '/api/v1/users/user_change_phone_init/',
    UserChangePhoneConfirm: '/api/v1/users/user_change_phone_confirm/',
    TransferDfaOrderSign: '/api/v2/transfer_dfa/order/sign/',
    UserChangeEmailInit: '/api/v1/users/user_change_email_init/',
    UserChangeEmailInitConfirm: '/api/v1/users/user_change_email_init_confirm_email/',
    UserChangeEmailConfirm: '/api/v1/users/user_change_email_confirm/',
    MoneyAccountBindByUuid: '/api/v1/money/account/bind_by_uuid/',
    TransferInitSauByUuidToBankAccount:
        '/api/v1/money/reserve/transfer_init_sau_by_uuid_to_bank_account/',
    TransfersV2: '/api/v2/transfers/',
    TransfersV2DetailsByUuid: '/api/v2/transfers/{uuid}/',
    CalendarWorkday: '/api/v1/calendar/workday/',
}
