import { DownloadOutlined } from '@ant-design/icons'
import { FC } from 'react'

import { Button, ButtonProps } from './Button'

export type DownloadButtonProps = ButtonProps & {
    title?: string
}

export const DownloadButton: FC<DownloadButtonProps> = ({
    title,
    children,
    icon = <DownloadOutlined />,
    ...props
}) => (
    <Button icon={icon} size="middle" type="default" borderRadius={12} {...props}>
        {children || title}
    </Button>
)
