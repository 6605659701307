import { Box, Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { emitmentUnstop } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const ResumptionIssueModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)

    const closeModal = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal
            title="Возобновление выпуска ЦФА"
            visible={isModalVisible}
            onCancel={closeModal}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="primary"
                    onClick={() => {
                        form.submit()
                    }}
                >
                    Возобновить выпуск
                </Button>,
            ]}
        >
            <div>
                <p>Все ограничения работы с выпуском будут сняты.</p>
                <Divider margin={0} />
                <Box padding={[24, 0, 16, 0]}>
                    <h3 className="page-content-header">Основание возобновления выпуска ЦФА</h3>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values: {
                        issue_unstop_reason_documents_uuid: { uid: string }[]
                        issue_unstop_reason: string
                    }) => {
                        if (dfa.id) {
                            dispatch(
                                emitmentUnstop({
                                    asset_id: `${dfa.id}`,
                                    issue_unstop_reason: values.issue_unstop_reason,
                                    issue_unstop_reason_documents_uuid:
                                        values.issue_unstop_reason_documents_uuid.map((v) => v.uid),
                                }),
                            )
                        }
                        setIsModalVisible(false)
                    }}
                >
                    <UploadDocumentContainerWrapper
                        name="issue_unstop_reason_documents_uuid"
                        title="Загрузите фотографии или сканы документов"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc"
                        required
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="issue_unstop_reason"
                            multiline
                            required
                            maxLength={200}
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
            </div>
        </Modal>
    )
}
