import {
    EmissionOrderStatus,
    IEmissionOrder,
    OrderTypeEnum,
    finalEmissionStatuses,
} from '@dltru/dfa-models'
import { BodyDate, BodyRoundedBlock, BodyTag, getOrderStatusTagProps } from '@dltru/dfa-ui'
import { FC } from 'react'

import { statusDescription, statusText } from '../../helper'

export const EmissionOrderStatusBlock: FC<
    Pick<IEmissionOrder, 'created_at' | 'status' | 'executed_at'>
> = ({ created_at, status, executed_at }) => {
    const endDate = executed_at
        ? new Date(executed_at * 1000 || 0)
        : new Date(created_at * 1000 || 0)
    if (!executed_at) {
        endDate.setDate(endDate.getDate() + 5)
    }
    const isFinallyOrder = status && finalEmissionStatuses.includes(status)

    const rightBlock = isFinallyOrder ? (
        <>
            <BodyTag tag={getOrderStatusTagProps(status || '', OrderTypeEnum.emission)} />
            <BodyDate time date={endDate} />
        </>
    ) : (
        <>
            <p className="card-modal__body-text--12">{statusText[status] ?? ''}</p>
            <p className="card-modal__body-text--16">{statusDescription[status] ?? ''}</p>
        </>
    )

    return (
        <BodyRoundedBlock
            success={status === EmissionOrderStatus.executed}
            left={
                <>
                    <p className="card-modal__body-text--12">
                        {isFinallyOrder ? 'Подача заявки' : 'Подача заявки на приобретение'}
                    </p>
                    <BodyDate time date={created_at * 1000 || new Date()} />
                </>
            }
            right={rightBlock}
        />
    )
}
