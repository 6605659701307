import clsx from 'clsx'
import React from 'react'

import style from './style.m.less'

export const Outline: React.FC<{ className?: string; styles?: React.CSSProperties }> = ({
    children,
    className,
    styles,
}) => {
    return (
        <div style={styles} className={clsx(style.outline, className)}>
            {children}
        </div>
    )
}
