/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    registrationDfaCount: number | null
}
const initialState: IInitialState = {
    registrationDfaCount: null,
}

export const notificationCounterSlice = createSlice({
    name: 'notificationCounter',
    initialState,
    reducers: {
        updateCounter(state, action: PayloadAction<Partial<IInitialState>>) {
            return { ...state, ...action.payload }
        },
        clear(state) {
            state.registrationDfaCount = null
        },
        getRegistrationDfaCount: () => {},
        getAllCount: () => {},
    },
})

export default notificationCounterSlice.reducer
