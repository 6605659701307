import React, { FC, useCallback, useReducer } from 'react'

import { FormInstance } from '../../../components/Form'
import filterReducer, { FilterActions, initFilterState } from './filterReducer'
import { getFilterTypeByName, getTotalValues, prepareValue, toQueryString } from './helper'
import { DataProviderResponse, Filter, FiltersContextProps } from './types'

const dummy = () => {
    return
}

export interface IFiltersContext {
    onReset: () => void
    onApplyFilters: () => void
    onSetForm: (form?: FormInstance<any>) => void
    filters: Filter[]
    totalActiveFilters?: number
    initialValues?: Partial<DataProviderResponse>
    form?: FormInstance<any>
}

export const FiltersContext = React.createContext<IFiltersContext>({
    onApplyFilters: dummy,
    onReset: dummy,
    onSetForm: dummy,
    filters: [],
})

const FilterContextProvider: FC<FiltersContextProps> = ({
    children,
    onApplyFilters,
    filters,
    togglePopupShow,
    isQs,
}) => {
    const [filterState, dispatch] = useReducer(filterReducer, initFilterState)

    const applyFiltersHandler = useCallback(() => {
        if (!filterState.form) {
            return
        }

        const fieldsValue = filterState.form.getFieldsValue()

        const filterTypeByName = getFilterTypeByName(filters)
        const readyToQuery = isQs
            ? toQueryString(fieldsValue, filterTypeByName)
            : prepareValue(fieldsValue, filterTypeByName)
        const totalValues = getTotalValues(fieldsValue)
        filterState.form.resetFields()

        dispatch({
            type: FilterActions.show,
            payload: { totalActiveFilters: totalValues, initialValues: fieldsValue },
        })

        togglePopupShow(false)
        onApplyFilters(readyToQuery)
    }, [filterState, filters])

    const resetFiltersHandler = useCallback(() => {
        dispatch({ type: FilterActions.clear })
        onApplyFilters({})
        togglePopupShow(false)
    }, [])

    const setFormHandler = useCallback((form?: FormInstance<any>) => {
        dispatch({ type: FilterActions.setForm, payload: form })
    }, [])

    return (
        <FiltersContext.Provider
            value={{
                filters,
                ...filterState,
                onApplyFilters: applyFiltersHandler,
                onReset: resetFiltersHandler,
                onSetForm: setFormHandler,
            }}
        >
            {children}
        </FiltersContext.Provider>
    )
}

export default FilterContextProvider
