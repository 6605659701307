import {
    EmissionOrderStatus,
    ISecondaryOrderShort,
    finalSecondaryStatuses,
} from '@dltru/dfa-models'

export const statusDescription: Partial<Record<EmissionOrderStatus, string>> = {
    [EmissionOrderStatus.accepted]: 'в момент выпуска ЦФА',
    [EmissionOrderStatus.waiting_for_emitter_accept]:
        'после подтверждения эмитентом в момент выпуска ЦФА',
}
export const statusText: Partial<Record<EmissionOrderStatus, string>> = {
    [EmissionOrderStatus.accepted]: 'будет исполнено',
    [EmissionOrderStatus.waiting_for_emitter_accept]: 'будет исполнено',
    [EmissionOrderStatus.in_progress]: 'заявка размещается',
    [EmissionOrderStatus.accepted_by_emitter]: 'заявка размещается',
}

export const getRestAmountSecondaryOrder = (order?: ISecondaryOrderShort) => {
    if (!order) {
        return 0
    }
    const isFinalStatus = finalSecondaryStatuses.includes(order.status)
    return isFinalStatus ? order.original_amount_dfa - order.rest_amount_dfa : order.rest_amount_dfa
}

export const getRestPriceSecondaryOrder = (order?: ISecondaryOrderShort) => {
    if (!order) {
        return 0
    }
    const isFinalStatus = finalSecondaryStatuses.includes(order.status)
    return isFinalStatus ? order.total_price - order.rest_price : order.rest_price
}
