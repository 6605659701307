import {
    AgentProfileFormValues,
    ProfileStatus,
    ProfileType,
    afterApprovalProfileStatuses,
    agentFrontToBack,
} from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Box, CopyButton, PageHeader, Tag, getFullName } from '@dltru/dfa-ui'
import { getAnketaTagProps } from '@resources/anketaStatus'
import { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { Footer } from '../components/Footer'
import { ClientRepresentativeStepper } from './ClientRepresantativeStepper'
import { disableFields } from './resources'

export const ClientRepresentative: FC = () => {
    const navigate = useNavigate()
    const { isAllow } = useContext(RbacContext)
    const { id } = useParams()
    const initialData = useSelector(clientsSelector.selectAgentFormValue)
    const oldProfile = useSelector(clientsSelector.selectProfileAgent)
    const reduxDispatch = useDispatch()

    const onStepperFinish = (values: AgentProfileFormValues) => {
        const newProfile = agentFrontToBack(values)
        reduxDispatch(
            clientsSlice.actions.updateAgentProfile({
                ...oldProfile,
                ...newProfile,
                user_uuid: id as string,
            }),
        )
    }

    if (!initialData || !oldProfile?.uuid || !oldProfile?.application_status) {
        return null
    }

    const tagProps = getAnketaTagProps(oldProfile.application_status, isAllow)

    const isApprovedStatuses = afterApprovalProfileStatuses.includes(oldProfile.application_status)
    const isEditAllow =
        (oldProfile.application_status === ProfileStatus.OPERATOR_APPROVAL &&
            isAllow(['approval.operator'])) ||
        (oldProfile.application_status === ProfileStatus.LAWYER_APPROVAL &&
            isAllow(['approval.lawyer'])) ||
        (oldProfile.application_status === ProfileStatus.PODFT_APPROVAL &&
            isAllow(['approval.aml'])) ||
        (isApprovedStatuses && isAllow(['user.write.all']))

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title={`Анкета представителя: ${getFullName(oldProfile)}`}
                    tags={<Tag color={tagProps.color}>{tagProps.title}</Tag>}
                    onBack={() => navigate(-1)}
                    subTitle={
                        oldProfile?.uuid ? (
                            <>
                                ID {oldProfile.uuid}&ensp;
                                <CopyButton text={oldProfile.uuid} />
                            </>
                        ) : undefined
                    }
                />
            </Box>
            <ClientRepresentativeStepper
                initialValues={initialData ?? ({} as AgentProfileFormValues)}
                onStepperFinish={onStepperFinish}
                initialStep={20}
                isEditAllow={isEditAllow}
                disableFields={isApprovedStatuses ? disableFields : undefined}
            />
            <Footer
                profileType={ProfileType.AGNT}
                profileUuid={oldProfile.uuid}
                profileStatus={oldProfile.application_status}
            />
        </>
    )
}
