export type VHStyle = number | [number, number] | [number, number, number, number]
export const getStylesPropName = (propName: 'padding' | 'margin', prop?: VHStyle) => {
    if (prop !== undefined) {
        if (Array.isArray(prop)) {
            return {
                [propName]: `${prop[0]}px ${prop[1]}px ${prop[2] ?? prop[0]}px ${
                    prop[3] ?? prop[1]
                }px`,
            }
        } else {
            return { [propName]: `${prop}px` }
        }
    }
    return {}
}
