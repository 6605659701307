import { EmissionOrderStatus } from './statuses'

interface IEmissionOrderCommon {
    id: number
    uuid: string
    user_id: string
    status: EmissionOrderStatus
    amount_dfa: number
    repurchase_percent: number
    asset_id: number
    asset_ticker_symbol: string
    asset_title: string
    created_at: number
    emitter_id: string
    price_per_dfa: number
    total_price: number
    executed_at?: number
    money_lock_id?: string
    emitter_full_name?: string
    canceled_at?: number
}
export interface IEmissionOrder extends IEmissionOrderCommon {
    order_type: ''
    user_name: string
    original_amount_dfa: number
    order_price?: number
}

export interface IEmissionOrderDeal extends IEmissionOrderCommon {
    deal_fee_lock_id: string
    user_full_name: string
}

export const isEmissionOrder = (
    order: IEmissionOrder | IEmissionOrderDeal,
): order is IEmissionOrder => {
    return 'user_name' in order
}
export const isEmissionOrderDeal = (
    order: IEmissionOrder | IEmissionOrderDeal,
): order is IEmissionOrderDeal => {
    return 'user_full_name' in order
}

export type PostEmissionOrderType = {
    asset_id: number
    skid: string
    order_price: number
}
