import { ITariffPlane, TariffPlaneEnum, tariffPlaneTypeTranslate } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, LinkCell, getTariffCode } from '@dltru/dfa-ui'

import { ActionCell, IActionCellProps } from './ActionCell'
import { getStatusTagPlane } from './helper'

export const getGridConfig = (
    props: Omit<IActionCellProps, 'record'>,
): ColumnsType<ITariffPlane> => [
    {
        title: 'Дата и время создания',
        dataIndex: 'created_at',
        width: '160px',
        render: DateTimeCell,
    },
    {
        title: 'Код ТП',
        dataIndex: 'type',
        width: '100px',
        render: (_: TariffPlaneEnum, record: ITariffPlane) => getTariffCode(record),
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        width: '10%',
        ellipsis: true,
        render: (value: TariffPlaneEnum) => tariffPlaneTypeTranslate[value],
    },
    {
        title: 'Наименование ТП',
        dataIndex: 'name',
        width: '20%',
        ellipsis: true,
    },
    {
        title: 'Автор',
        dataIndex: 'created_by',
        ellipsis: true,
        width: '20%',
        render: (value: string, record: ITariffPlane) =>
            LinkCell(record.created_by_name || value, `/employees/${value}`),
    },
    {
        title: 'Дата и время архивирования',
        dataIndex: 'archived_at',
        width: '160px',
        render: (value: string) => (value ? DateTimeCell(value) : ''),
    },
    {
        title: 'Статус ТП',
        dataIndex: 'status',
        width: '110px',
        render: (_: string, record: ITariffPlane) => getStatusTagPlane(record),
    },
    {
        title: '',
        fixed: 'right',
        width: '38px',
        render: (_: string, record: ITariffPlane) =>
            record.type === TariffPlaneEnum.BASE ? undefined : (
                <ActionCell record={record} {...props} />
            ),
    },
]
