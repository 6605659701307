import { DownloadOutlined } from '@ant-design/icons'
import { Base64File, IRequest, ResponseItem, UploadResult } from '@dltru/dfa-models'
import { InputProps } from 'antd/lib/input'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import clsx from 'clsx'
import { FC, useState } from 'react'

import { openMessage } from '../Notification'
import { Button } from '../button'
import { FormItem, FormItemProps } from '../components/Form'
import { useFormValues } from '../hooks'
import { fileValidators } from '../utils'
import { defaultFileName } from './UneditableFileComponent'
import { UneditableUploadedFilesComponent } from './UneditableUploadedFilesComponent'
import { Upload } from './Upload'
import './styles.less'

type AdditionalProps = {
    title?: string
    maxSizeMb?: number
    maxCount?: number
    onChange?: (value?: UploadFile[]) => void
    labelOnlyUneditable?: boolean
    titleBtn?: string
    storeBase64File: (
        file: Partial<Base64File>,
    ) => Promise<IRequest<ResponseItem<{ id: string; check_sum: string }>>>
    getBase64File: (uid: string) => Promise<IRequest<ResponseItem<UploadResult>>>
}
export type UploadInputComponentProps = FormItemProps &
    AdditionalProps &
    Pick<InputProps, 'disabled' | 'placeholder' | 'prefix' | 'accept'>

export const UploadInputComponent: FC<UploadInputComponentProps> = ({
    required,
    label,
    rules = [],
    name,
    listName,
    title,
    uneditable,
    maxSizeMb,
    maxCount,
    onChange,
    labelOnlyUneditable = true,
    titleBtn = 'Загрузить документ',
    disabled,
    accept,
    storeBase64File,
    getBase64File,
    ...rest
}) => {
    const { setFormValue, getFormValue } = useFormValues({ name, listName })

    const fieldValue =
        getFormValue()?.map((file: UploadFile) =>
            file.name ? file : { ...file, name: defaultFileName, status: 'done' },
        ) || []

    const [files, setFiles] = useState(fieldValue)

    const validateFile = (file: RcFile) => {
        let isValid = true

        const fileTypes = accept
            ?.split(',')
            .map((value) => value.replaceAll(/[.,\s]+/gi, ''))
            .filter((v) => v)
        const fileType = file.name.split('.').pop()

        if (!fileType) {
            isValid = false
        }

        if (fileType && fileTypes?.length && !fileTypes.includes(fileType)) {
            isValid = false
            openMessage({
                type: 'error',
                message: `Неверный тип файла`,
            })
        }

        const checkSize = fileValidators.maxSize(file, maxSizeMb)
        if (!checkSize) {
            isValid = false
            openMessage({
                type: 'error',
                message: `Размер файла не должен превышать ${maxSizeMb}Мб`,
            })
        }

        return isValid
    }

    const setFilesOnForm = (fileList: UploadFile[]) => {
        const filesWithoutError = fileList.filter(({ status }) => status === 'done')
        setFormValue(filesWithoutError)
        onChange?.(filesWithoutError)
        setFiles(fileList)
    }

    const setErrorFile = (fileList: UploadFile[], uid: string) => {
        for (const file of fileList) {
            if (file.uid === uid) {
                file.status = 'error'
                setFilesOnForm([...fileList])
                return
            }
        }
    }

    const setUploadingFile = (fileList: UploadFile[], uid: string) => {
        for (const file of fileList) {
            if (file.uid === uid) {
                file.status = 'uploading'
                setFilesOnForm([...fileList])
                return
            }
        }
    }

    const onChangeFile = (info: UploadChangeParam<RcFile>) => {
        if (info?.file && (info.file as RcFile & { status: string }).status !== 'removed') {
            if (validateFile(info.file)) {
                const reader = new FileReader()
                reader.readAsDataURL(info.file)
                reader.onload = async function () {
                    try {
                        setUploadingFile(info.fileList, info.file.uid)
                        const result = await storeBase64File({
                            name: info.file.name,
                            file: `${reader.result}`,
                            uploader_id: '0',
                        })
                        if (result?.data?.item?.id) {
                            for (const file of info.fileList) {
                                const _file = file as RcFile & { status: string; hash: string }
                                if (_file.uid === info.file.uid) {
                                    _file.uid = result.data.item.id
                                    _file.status = 'done'
                                    _file.hash = result.data.item.check_sum
                                    setFilesOnForm(info.fileList)
                                    return
                                }
                            }
                        } else {
                            throw new Error('Ошибка при загрузке')
                        }
                    } catch (error) {
                        setErrorFile(info.fileList, info.file.uid)
                    }
                }
                reader.onerror = function () {
                    setErrorFile(info.fileList, info.file.uid)
                }
            } else {
                setErrorFile(info.fileList, info.file.uid)
            }
        } else if (info?.fileList) {
            setFilesOnForm(info.fileList)
        }
    }

    const isDisabled = disabled || Boolean(maxCount && maxCount <= files?.length)

    const uploadFieldClassName = clsx('upload-field', {
        'upload-field--required': required && !label,
    })

    return (
        <FormItem
            {...rest}
            name={name}
            listName={listName}
            label={uneditable || !labelOnlyUneditable ? label : undefined}
            required={required}
            rules={[...rules]}
            uneditable={uneditable}
            renderUneditable={(value) => {
                return (
                    <UneditableUploadedFilesComponent
                        files={value as UploadFile[]}
                        getBase64File={getBase64File}
                    />
                )
            }}
        >
            <Upload
                beforeUpload={() => false}
                onChange={onChangeFile}
                maxCount={maxCount}
                defaultFileList={files}
                accept={accept}
                disabled={disabled}
                id={name as string}
                className="upload-input"
            >
                <Button
                    size="middle"
                    className="upload-button"
                    icon={<DownloadOutlined />}
                    disabled={isDisabled}
                    borderRadius={12}
                >
                    {titleBtn}
                </Button>
            </Upload>
            {title && files.length === 0 && (
                <div className={uploadFieldClassName}>
                    <span>{title}</span>
                </div>
            )}
        </FormItem>
    )
}
