import { ITariffPlanePost } from '@dltru/dfa-models'
import { Button, CONTENT_FOOTER_ID, ContentFooterBox, PlusSquare, Portal } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { tariffSlice } from '@store/tariff'

interface IComponentProps {
    dataForCreate?: ITariffPlanePost
}
export const Footer: FC<IComponentProps> = ({ dataForCreate }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()

    const postTariffPlane = () => {
        if (!dataForCreate) {
            return
        }
        const callback = (id: string) => {
            navigate(`/tariff/${id}`)
        }
        reduxDispatch(tariffSlice.actions.postTariffPlane({ data: dataForCreate, callback }))
    }

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {dataForCreate && (
                            <Button
                                type="primary"
                                icon={<PlusSquare />}
                                borderRadius={12}
                                onClick={postTariffPlane}
                            >
                                Создать тарифный план
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
