import { DfaStatusEnum, preStatuses } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { EMPTY_FIELD_VALUE, Infotag } from '@dltru/dfa-ui'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import IAppState from '@store/states'

import { IDfaLink } from '../../../../store/dfa/types'
import AddLinkButton from './AddLinkButton'
import ExtraLink from './ExtraLink'
import style from './style.m.less'

const getLinksElement = (
    link: { [key in keyof IDfaLink]: string },
    linkOriginForTitle: string,
    isEditable: boolean,
    isProcessing: boolean,
): React.ReactElement => {
    if (isProcessing) {
        return <div className={style.infotagValue}>{EMPTY_FIELD_VALUE}</div>
    }
    const [key, value] = Object.entries(link)[0] as unknown as [keyof IDfaLink, string]

    if (!value && isEditable) {
        return <AddLinkButton type={key} linkOriginForTitle={linkOriginForTitle} />
    }

    return (
        <ExtraLink
            type={key}
            href={value}
            link={value}
            isEditable={isEditable}
            linkOriginForTitle={linkOriginForTitle}
        />
    )
}

export const Links: FC = () => {
    const { ois_link, emitter_link, status } = useSelector((store: IAppState) => store.dfaDetails)
    const { isAllow } = useContext(RbacContext)
    const isEditable = status === DfaStatusEnum.waiting_for_links && isAllow(['asset.write.all'])
    const isProcessing = preStatuses.includes(status)

    return (
        <>
            <Infotag label="Эмитент">
                {getLinksElement({ emitter_link }, 'эмитента', isEditable, isProcessing)}
            </Infotag>
            <Infotag label="Оператор ИС">
                {getLinksElement({ ois_link }, 'оператора ИС', isEditable, isProcessing)}
            </Infotag>
        </>
    )
}
