import { UnionOrderType } from '../../models'

type TypeForMapper = Partial<
    Pick<
        UnionOrderType,
        | 'price_per_dfa'
        | 'total_price'
        | 'rest_price'
        | 'repurchase_percent'
        | 'original_amount_dfa'
        | 'amount_dfa'
    >
>
export const unionTypeBackToFront = <T extends TypeForMapper>(order: T): T => {
    let amount_dfa = order.amount_dfa
    if (order.amount_dfa === 0 && order.original_amount_dfa !== 0) {
        amount_dfa = order.original_amount_dfa
    }
    return {
        ...order,
        amount_dfa,
        price_per_dfa: order.price_per_dfa ? order.price_per_dfa / 100 : order.price_per_dfa,
        total_price: order.total_price ? order.total_price / 100 : order.total_price,
        rest_price: order.rest_price ? order.rest_price / 100 : order.rest_price,
        repurchase_percent: order.repurchase_percent
            ? order.repurchase_percent / 100
            : order.repurchase_percent,
    }
}
