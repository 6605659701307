import { ITariffAssetLink } from '@dltru/dfa-models'
import { ColumnsType, LinkCell, Tag, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import { getTagProps } from '@resources/dfaState'
import dayjs from 'dayjs'

export const gridConfig: ColumnsType<ITariffAssetLink> = [
    {
        title: 'Актив',
        dataIndex: 'ticker_symbol',
        key: 'ticker_symbol',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.id}`),
    },
    {
        title: 'Наименование ЦФА',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
    },
    {
        title: 'Дата выпуска',
        dataIndex: 'issue_date',
        key: 'issue_date',
        sorter: true,
        ellipsis: true,
        render: (value) => dayjs(value).format(VIEW_DATE_FORMAT),
    },
    {
        title: 'Дата погашения',
        dataIndex: 'redeem_date',
        key: 'redeem_date',
        sorter: true,
        ellipsis: true,
        render: (value) => dayjs(value).format(VIEW_DATE_FORMAT),
    },
    /*{
        title: 'Объем выпуска, ед.',
        dataIndex: 'total_supply_invested',
        key: 'total_supply_invested',
        sorter: true,
        ellipsis: true,
    },*/
    {
        title: 'Цена эмиссии, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Цена погашения, ₽',
        dataIndex: 'redeem_price_per_dfa',
        key: 'redeem_price_per_dfa',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        render: (value) => {
            const tagProps = getTagProps(value)
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
