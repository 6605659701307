import { Box } from '@dltru/dfa-ui'
import { FC } from 'react'

import { PersonalAccountButton } from './components'

export const ContentHeader: FC = () => {
    return (
        <Box className="contentheader" direction="row" justify="right" align="center">
            {/*<NotificationsButton count={5} icon={<CustomerServiceOutlined />} />*/}
            {/*<NotificationsButton count={13} icon={<BellOutlined />} />*/}
            <PersonalAccountButton />
        </Box>
    )
}
