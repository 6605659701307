import {
    ClientAutocomplete,
    ClientTypeTranslate,
    ProfileListType,
    ProfileStatus,
    approvalProfileStatuses,
    profileStatusTranslate,
} from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType, SelectData } from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

const typeOptions: SelectData[] = Object.entries(ClientTypeTranslate).map(([value, label]) => ({
    value,
    label,
}))

const statusOptions: SelectData[] = [...approvalProfileStatuses, ProfileStatus.IDENTIFIED].map(
    (value) => ({
        value,
        label: profileStatusTranslate[value],
    }),
)

const ClientsOption: FC<{ data?: DataProviderResponse }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <p> {data.inn} </p>
        </div>
    )
}

const autoGetClientComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getClientsService({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item: ClientAutocomplete) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

const autoGetOgrnComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getProfilesService({
            ogrn: `like(${value})`,
        })
        return data?.item?.items?.map((item: ProfileListType) => ({
            ...item,
            label: item.ogrn,
            value: item.ogrn,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Дата и время подачи заявки',
        name: 'created_at',
        filterType: FilterType.DATE_RANGE,
        divider: true,
    },

    {
        label: 'Заявитель',
        name: 'user_uuid',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientComplete,
        renderElement: <ClientsOption />,
    },
    {
        label: 'Тип организации Заявителя',
        name: 'profile_type',
        filterType: FilterType.SELECT,
        options: typeOptions,
    },
    {
        label: 'ОГРН',
        name: 'ogrn',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetOgrnComplete,
        divider: true,
    },
    {
        label: 'Статус регистрации',
        name: 'profile_status',
        filterType: FilterType.SELECT,
        options: statusOptions,
    },
]
