import { Box, Button, Divider, Form, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { emitmentStop } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const PausedIssueModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)

    const closeModal = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal
            title="Приостановление выпуска ЦФА"
            visible={isModalVisible}
            onCancel={closeModal}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="primary"
                    className={style.makeIssueFailedButton}
                    onClick={form.submit}
                >
                    Приостановить выпуск
                </Button>,
            ]}
        >
            <div>
                <p>
                    Выпуск будет приостановлен до устранения выявленных нарушений.
                    <br />
                    Вы сможете возобновить процедуру выпуска.
                </p>
                <Divider margin={0} />
                <Box padding={[24, 0, 16, 0]}>
                    <h3 className="page-content-header">Причина приостановки выпуска ЦФА</h3>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values: {
                        document_file_uuid: { uid: string }[]
                        document_comment: string
                    }) => {
                        if (dfa.id) {
                            dispatch(
                                emitmentStop({
                                    asset_id: `${dfa.id}`,
                                    issue_stop_reason: values.document_comment,
                                    issue_stop_reason_documents_uuid: values.document_file_uuid.map(
                                        (v) => v.uid,
                                    ),
                                }),
                            )
                        }
                        setIsModalVisible(false)
                    }}
                >
                    <UploadDocumentContainerWrapper
                        name="document_file_uuid"
                        title="Загрузите фотографии или сканы документов"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                        required
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="document_comment"
                            multiline
                            required
                            maxLength={200}
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
            </div>
        </Modal>
    )
}
