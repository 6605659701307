import { FC } from 'react'

import {
    CheckCircleOutlineIcon,
    CloseCircleOutlineIcon,
    ExclamationCircleOutline,
    InfoCircleOutline,
} from '../Icons'
import { IconType } from './types'

interface NotificationIconProps {
    type: IconType
}
export const NotificationIcon: FC<NotificationIconProps> = ({ type }) => {
    switch (type) {
        case 'success':
            return <CheckCircleOutlineIcon />
        case 'error':
            return <CloseCircleOutlineIcon />
        case 'warning':
            return <ExclamationCircleOutline />
        case 'info':
            return <InfoCircleOutline />
        default:
            return <InfoCircleOutline />
    }
}
