import { ISecondaryOrder } from '@dltru/dfa-models'
import { CardModal, getModalTradeHeaderData } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDfaById } from '@store/dfa/details'
import IAppState from '@store/states'

import { SecondaryOrderDetailsModalBody } from './SecondaryOrderDetailsModalBody'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    order?: ISecondaryOrder
    secondModal?: boolean
}

export const SecondaryOrderDetailsModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    order,
    secondModal,
}) => {
    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector((store: IAppState) => store.dfaDetails)
    useEffect(() => {
        if (isModalVisible && order?.asset_id && order.asset_id !== dfaDetails?.id) {
            reduxDispatch(getDfaById(order.asset_id))
        }
    }, [isModalVisible, order?.asset_id])

    return (
        <CardModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            secondModal={secondModal}
            headerData={getModalTradeHeaderData(
                {
                    ...dfaDetails,
                    emitter_full_name: order?.emitter_full_name,
                    emitter_id: order?.emitter_id,
                },
                order?.repurchase_percent,
            )}
            BodyRenderProps={
                <SecondaryOrderDetailsModalBody
                    setIsModalVisible={setIsModalVisible}
                    secondaryDfaDetails={order}
                />
            }
        />
    )
}
