import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export const getLeftTime = (countDown: number) => {
    const dateInDayJSFormat = dayjs.unix(countDown)

    return [dateInDayJSFormat.hour(), dateInDayJSFormat.minute(), dateInDayJSFormat.second()]
}

export const useCountdown = (
    hours: number,
    minutes: number,
    seconds: number,
    onFinish?: () => void,
) => {
    const countDownDate = dayjs()
        .add(hours, 'hour')
        .add(minutes, 'minute')
        .add(seconds, 'second')
        .unix()

    const [countDown, setCountDown] = useState(countDownDate - dayjs().unix())
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - dayjs().unix())
            if (countDownDate - dayjs().unix() <= 0) {
                if (onFinish) {
                    onFinish()
                }
                clearInterval(interval)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return getLeftTime(countDown)
}
