/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    FeeType,
    GetTariffsParams,
    ICursorPaginationResponse,
    ITariffGridPost,
    ITariffPlane,
    ITariffPlanePost,
    TariffGridPut,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: Partial<ICursorPaginationResponse<ITariffPlane>>
    params: GetTariffsParams
    error: string
    currentTariff: ITariffPlane
    baseTariff: ITariffPlane | null
    fees: FeeType[]
}
const initialState: IInitialState = {
    isLoading: false,
    error: '',
    data: {},
    params: { limit: DEFAULT_PER_PAGE },
    currentTariff: {} as ITariffPlane,
    baseTariff: null,
    fees: [],
}

export const tariffSlice = createSlice({
    name: 'tariff',
    initialState,
    reducers: {
        setItems: (state, action: PayloadAction<ICursorPaginationResponse<ITariffPlane>>) => {
            state.data.items = action.payload.items
            state.data.paging = action.payload.paging
        },
        setCurrentTariff: (state, action: PayloadAction<ITariffPlane>) => {
            state.currentTariff = action.payload
        },
        setFees: (state, action: PayloadAction<FeeType[]>) => {
            state.fees = action.payload
        },
        setBaseTariff: (state, action: PayloadAction<ITariffPlane>) => {
            state.baseTariff = action.payload
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        clearItems: (state) => {
            state.error = ''
            state.isLoading = false
            state.data = {}
            state.params = { limit: DEFAULT_PER_PAGE }
        },
        clearItem: (state) => {
            state.error = ''
            state.isLoading = false
            state.currentTariff = {} as ITariffPlane
        },
        getItems: (state, action: PayloadAction<GetTariffsParams | undefined>) => {
            state.params = isEmptyValue(action.payload)
                ? initialState.params
                : (action.payload as GetTariffsParams)
        },
        updateTariffPlane: (state, action: PayloadAction<ITariffPlane>) => {
            if (state.currentTariff?.id === action.payload.id) {
                state.currentTariff = { ...state.currentTariff, ...action.payload }
            }
            if (state.data?.items) {
                state.data.items = state.data.items.map((item) =>
                    item.id === action.payload.id ? { ...item, ...action.payload } : item,
                )
            }
        },
        getFees: () => {},
        getBaseTariff: () => {},
        getCurrentTariff: (_, action: PayloadAction<string>) => {},
        archiveTariffPlane: (_, action: PayloadAction<string>) => {},
        postTariffPlane: (
            _,
            action: PayloadAction<{ data: ITariffPlanePost; callback: (id: string) => void }>,
        ) => {},
        postTariffGrid: (_, action: PayloadAction<ITariffGridPost>) => {},
        putTariffGrid: (_, action: PayloadAction<{ data: TariffGridPut; grid_id: string }>) => {},

        deleteTariffGrid: (_, action: PayloadAction<string>) => {},
        activateTariffGrid: (_, action: PayloadAction<string>) => {},
        deactivateTariffGrid: (_, action: PayloadAction<string>) => {},
    },
})

export default tariffSlice.reducer
