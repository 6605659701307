// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PAHeader_dotV5 {\n  display: flex;\n  padding: 16px 24px;\n  justify-content: flex-start;\n  align-items: center;\n}\n.PAHeader_dotV5 .widgetsWrapper_jsvXz {\n  display: flex;\n  flex-grow: 1;\n}\n.PAHeader_dotV5 .widgetsWrapper_jsvXz .BalanceHeaderDivider_AMIwD {\n  height: 40px;\n  width: 1px;\n  background: #ebecf0;\n}\n.PABody_IzI41 {\n  flex: 1 1 auto;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PersonalAccounts/components/PersonalAccountsTable/style.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,kBAAA;EACA,2BAAA;EACA,mBAAA;AADF;AAHA;EAOI,aAAA;EACA,YAAA;AADJ;AAPA;EAWM,YAAA;EACA,UAAA;EACA,mBAAA;AADN;AAKA;EACE,cAAA;EACA,aAAA;AAHF","sourcesContent":["@import 'style/palette';\n\n.PAHeader {\n  display: flex;\n  padding: 16px 24px;\n  justify-content: flex-start;\n  align-items: center;\n\n  .widgetsWrapper {\n    display: flex;\n    flex-grow: 1;\n  \n    .BalanceHeaderDivider {\n      height: 40px;\n      width: 1px;\n      background: @gray-4;\n    }\n  }\n}\n.PABody {\n  flex: 1 1 auto;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PAHeader": "PAHeader_dotV5",
	"widgetsWrapper": "widgetsWrapper_jsvXz",
	"BalanceHeaderDivider": "BalanceHeaderDivider_AMIwD",
	"PABody": "PABody_IzI41"
};
export default ___CSS_LOADER_EXPORT___;
