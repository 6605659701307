import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import { FC } from 'react'

import { IQualForm } from '../../type'
import { ApplyAction } from './Actions/ApplyAction'
import { NeedChangeAction } from './Actions/NeedChangeAction'
import { RejectAction } from './Actions/RejectAction'

interface IFooterProps {
    isStatementComplete?: boolean
    data?: IQualForm
}

export const Footer: FC<IFooterProps> = ({ data }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        <RejectAction data={data} />

                        <NeedChangeAction data={data} />
                        <ApplyAction data={data} />
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
