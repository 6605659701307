import { ITariffPlane } from '@dltru/dfa-models'
import { Tag } from '@dltru/dfa-ui'
import React from 'react'

export const getStatusTagPlane = (plane: ITariffPlane) => {
    const color = plane.archived_at ? 'default' : 'success'
    const title = plane.archived_at ? 'В архиве' : 'Действующий'

    return <Tag color={color}>{title}</Tag>
}
