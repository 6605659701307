import { DfaStatusEnum, IDfa, approvalStatuses } from '@dltru/dfa-models'
import { WrappedAutocomplete } from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

export interface IOption extends IDfa {
    label: string
    value: string
}

interface IDfasAutocomplete {
    isEdit: boolean
    name: string | string[]
    onSelect: (dfa: IOption[]) => void
    selected: IOption[]
}
const allowStatuses = [
    DfaStatusEnum.waiting_for_links,
    DfaStatusEnum.waiting_for_collection_started,
    DfaStatusEnum.collection_started,
    DfaStatusEnum.issue_stopped,
    DfaStatusEnum.emitment_success,
    DfaStatusEnum.waiting_for_emission,
    DfaStatusEnum.waiting_for_emitment,
    DfaStatusEnum.issuer_confirmation,
    DfaStatusEnum.modification,
    ...approvalStatuses,
]
export const DfasAutocomplete: FC<IDfasAutocomplete> = ({ isEdit, name, onSelect, selected }) => {
    const onSelectHandler = (selectedOptions: IOption[]) => {
        const options = selectedOptions
        onSelect(options)
    }

    const getData = async (value: unknown) => {
        try {
            if (!value) {
                return []
            }

            const { data } = await api.lib.getDfaList({
                ticker_symbol: `like(${value})`,
                status: allowStatuses,
            })

            return (
                data?.items?.map((item) => ({
                    ...item,
                    label: item.ticker_symbol as string,
                    value: String(item.id),
                })) ?? []
            )
        } catch (error) {
            return []
        }
    }

    return (
        <WrappedAutocomplete
            style={{ width: '100%' }}
            className="formComponentItem"
            dataProvider={getData}
            onSelect={onSelectHandler}
            defaultOptions={selected}
            name={name}
            selectProps={{
                mode: 'multiple',
                size: 'large',
                placeholder: 'Укажите тикер выпуска',
            }}
            uneditable={isEdit}
        />
    )
}
