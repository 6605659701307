import { Box, PageHeader } from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectOperotToDelete } from '@store/exchangeOperators/selectors'

import { ChangeStatusModalContainer } from './components/ChangeStatusModalContainer/ChangeStatusModalContainer'
import { ExchangeOperatorsTable } from './components/ExchangeOperatorsTable/ExchangeOperatorsTable'

export const ExchangeOperators: FC = () => {
    const operatorToDelete = useSelector(selectOperotToDelete)
    const [isShowDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        if (operatorToDelete) {
            setShowDeleteModal(true)
        }
    }, [operatorToDelete])

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Операторы обмена" />
            </Box>
            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <ExchangeOperatorsTable />
            </Box>

            <ChangeStatusModalContainer
                title={''}
                submitButtonLabel={''}
                reasonTitle={''}
                status={''}
                isShow={isShowDeleteModal}
                onCancel={function (): void {
                    throw new Error('Function not implemented.')
                }}
                onSubmit={function (): void {
                    throw new Error('Function not implemented.')
                }}
            />
        </>
    )
}
