import { ISecondaryOrder } from '@dltru/dfa-models'
import { ColoredTag, ColumnsType, DateTimeCell, LinkCell } from '@dltru/dfa-ui'

import { SecondaryOrderActionButton } from '@components/SecondaryOrderActionButton'

interface IGridConfigProps {
    actionCancel: (order: ISecondaryOrder) => void
}

export const getGridConfig = (props: IGridConfigProps): ColumnsType<ISecondaryOrder> => [
    {
        title: 'Дата и время',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Тикер ',
        dataIndex: 'asset_ticker_symbol',
        key: 'asset_ticker_symbol',
        sorter: true,
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: 'Эмитент ',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
    },
    {
        title: 'Инвестор',
        dataIndex: 'user_id',
        key: 'user_id',
        sorter: true,
        render: (value, record) => LinkCell(record.user_full_name, `/clients/${value}`),
    },
    {
        title: 'Кол-во,  ед.',
        dataIndex: 'rest_amount_dfa',
        key: 'rest_amount_dfa',
        sorter: true,
        render: (text, record) => {
            return <ColoredTag type={record.order_type}>{record.rest_amount_dfa}</ColoredTag>
        },
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Стоимость,  ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        ellipsis: true,
        render: (_, record) =>
            (record.rest_amount_dfa * (record.price_per_dfa ?? 0)).toLocaleString(),
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        ellipsis: true,
        width: 80,
        render: (_, record) => <SecondaryOrderActionButton order={record} {...props} />,
    },
]
