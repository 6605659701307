// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabButton_fp6M1 {\n  background: #f8f8fa !important;\n  font-weight: 600 !important;\n  color: #303440 !important;\n  border: 1px solid #f1f2f5 !important;\n  border-radius: 12px !important;\n}\n.tabButtonActive_B0KMm {\n  background: #e3f6ff !important;\n  color: #007ef2 !important;\n  border: 1px solid #ccf0ff !important;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/button/TabButton/style.m.less"],"names":[],"mappings":"AAGA;EACI,8BAAA;EACA,2BAAA;EACA,yBAAA;EACA,oCAAA;EACA,8BAAA;AAFJ;AAII;EACI,8BAAA;EACA,yBAAA;EACA,oCAAA;AAFR","sourcesContent":["@import '/src/assets/style/palette';\n\n//TODO what to do\n.tabButton {\n    background: @gray-2 !important;\n    font-weight: 600 !important;\n    color: @gray-10 !important;\n    border: 1px solid @gray-3 !important;\n    border-radius: 12px !important;\n\n    &Active {\n        background: @primary-1 !important;\n        color: @primary-6 !important;\n        border: 1px solid @primary-2 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabButton": "tabButton_fp6M1",
	"tabButtonActive": "tabButtonActive_B0KMm"
};
export default ___CSS_LOADER_EXPORT___;
