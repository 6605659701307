import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const LeftMini: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.869349 5.49207L4.52938 9.17208C4.80066 9.44484 5.24206 9.44484 5.51334 9.17208C5.78254 8.90141 5.78254 8.46412 5.51334 8.19346L2.33731 5.00008L5.51334 1.80671C5.78254 1.53604 5.78254 1.09875 5.51334 0.828083C5.24206 0.555322 4.80066 0.555323 4.52938 0.828083L0.869349 4.50809C0.599105 4.77981 0.599105 5.22035 0.869349 5.49207Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
