import { DEFAULT_PER_PAGE, Table } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dropDfaDetails } from '@store/dfa/details'
import { dropDfaList, loadDfasEmission } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'

import { getGridConfig } from './gridConfig'
import { getExternalRateHelper } from '@store/helper'
import { ExternalRateIndex } from '@dltru/dfa-models'

const statuses = ['collection_started']

export const EmissionsTable: FC = () => {
    const reduxDispatch = useDispatch()
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const [rate, setRate] = useState(0)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(loadDfasEmission({ limit, status: statuses }))
    }

    const onNext = () => {
        reduxDispatch(loadDfasEmission({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(loadDfasEmission({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(loadDfasEmission({ status: statuses, limit: DEFAULT_PER_PAGE }))

        getExternalRateHelper(ExternalRateIndex.RUGOLD).then(({ item }) => {
            setRate(item.rate)
        })

        return () => {
            reduxDispatch(dropDfaList())
            reduxDispatch(dropDfaDetails())
        }
    }, [])

    const gridConfig = getGridConfig(rate)

    return (
        <Table
            isLoading={isLoading}
            columns={gridConfig}
            dataSource={items ?? []}
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            rowKey="id"
        />
    )
}
