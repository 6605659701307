import IAppState from '../states'

const selectItems = (state: IAppState) => state.registrationRequests.data?.items
const selectPaging = (state: IAppState) => state.registrationRequests.data?.paging
const selectIsLoading = (state: IAppState) => state.registrationRequests.isLoading
const selectParams = (state: IAppState) => state.registrationRequests.params

export const registrationRequestsSelector = {
    selectItems,
    selectPaging,
    selectIsLoading,
    selectParams,
}
