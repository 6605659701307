/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { DownloadDocuments } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: string[]
}
const initialState: IInitialState = {
    isLoading: [],
}
export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<string>) => {
            state.isLoading.push(action.payload)
        },
        unSetLoading: (state, action: PayloadAction<string>) => {
            state.isLoading = state.isLoading.filter((item) => item !== action.payload)
        },
        downloadDocument: (_, action: PayloadAction<DownloadDocuments>) => {},
    },
})

export default documentsSlice.reducer
