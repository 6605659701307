import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CheckIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.6284 0.222093C18.058 0.569114 18.125 1.19873 17.778 1.62837L7.27798 14.6284C7.08665 14.8653 6.79782 15.0021 6.49332 15C6.18882 14.998 5.90185 14.8573 5.71372 14.6179L0.213724 7.61786C-0.127489 7.18358 -0.0520506 6.55493 0.382221 6.21372C0.816493 5.8725 1.44515 5.94794 1.78636 6.38221L6.51083 12.3952L16.2221 0.371697C16.5691 -0.0579483 17.1987 -0.124928 17.6284 0.222093Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
