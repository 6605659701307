import { ClientAutocomplete } from '@dltru/dfa-models'
import {
    ArrowLeft,
    Box,
    Button,
    DefaultOptionType,
    Form,
    LeftMini,
    WrappedAutocomplete,
    WrappedSelect,
} from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'

import api from '@services/api'

import style from './style.m.less'

export interface IDebitUserSelectValues {
    user_uuid: string
    account_number: number
    selectedUser?: ClientAutocomplete[]
    accountUser?: DefaultOptionType[]
}

interface IComponentProps {
    saveUserData: (values: IDebitUserSelectValues) => void
    changeBodyToMain: () => void
    initialValues: Partial<IDebitUserSelectValues>
}

export const DebitUserSelectBody: FC<IComponentProps> = ({
    saveUserData,
    changeBodyToMain,
    initialValues,
}) => {
    const [form] = Form.useForm<IDebitUserSelectValues>()
    const [selectedUser, setSelectedUser] = useState<ClientAutocomplete[]>(
        initialValues?.selectedUser ?? [],
    )
    const [accountUser, setAccountUser] = useState<DefaultOptionType[]>(
        initialValues?.accountUser ?? [],
    )
    const onSubmitForm = (values: IDebitUserSelectValues) => {
        saveUserData({ ...values, selectedUser, accountUser })
        changeBodyToMain()
    }

    const getUserAccounts = async (userUid?: string) => {
        try {
            if (!userUid) {
                setAccountUser([])
                return
            }
            const { data } = await api.lib.getMoneyAccounts(userUid)
            setAccountUser(
                data?.items?.map(({ number }) => ({ value: number, label: number })) ?? [],
            )
        } catch (error) {
            setAccountUser([])
        }
    }

    const onSelectHandler = (selectedOptions: ClientAutocomplete[]) => {
        setSelectedUser(selectedOptions)
        getUserAccounts(selectedOptions?.[0]?.user_uuid)
    }

    const getClients = async (value?: string) => {
        try {
            if (!value) {
                return []
            }
            const { data } = await api.lib.getClientsService({
                full_name: `like(${value})`,
            })
            return (
                data?.items?.map((item) => ({
                    ...item,
                    label: item.full_name,
                    value: item.user_uuid,
                })) ?? []
            )
        } catch (error) {
            return []
        }
    }

    useEffect(() => {
        if (accountUser?.length === 1) {
            form.setFieldsValue({ account_number: accountUser[0].value as number })
        } else {
            form.setFieldsValue({ account_number: undefined })
        }
    }, [accountUser])

    return (
        <div>
            <div className={style.body}>
                <Box padding={[0, 0, 16, 0]}>
                    <Button
                        type="text"
                        icon={<ArrowLeft />}
                        onClick={changeBodyToMain}
                        className={style.bigArrowButton}
                        width="max-content"
                    >
                        Получатель средств
                    </Button>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitForm}
                    initialValues={initialValues}
                >
                    <WrappedAutocomplete
                        dataProvider={getClients}
                        onSelect={onSelectHandler}
                        defaultOptions={selectedUser}
                        name="user_uuid"
                        label="Получатель"
                        selectProps={{
                            size: 'large',
                            placeholder: 'Укажите название организации',
                        }}
                        required
                    />
                    <WrappedSelect
                        label="Номер САУ получателя"
                        placeholder="Укажите номер счёта"
                        name="account_number"
                        options={accountUser}
                        size="large"
                        required
                        disabled={!selectedUser?.length}
                    />
                </Form>
            </div>

            <div>
                <Button
                    type="link"
                    onClick={form.submit}
                    icon={<LeftMini />}
                    className={style.smallLinkButton}
                >
                    Сохранить данные
                </Button>
            </div>
        </div>
    )
}
