// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subTitle_lW4HD {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.preLine__eoeK {\n  white-space: pre-line;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/QualifiedBlock/style.m.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAGA;EACI,qBAAA;AADJ","sourcesContent":["@import 'style/palette';\n\n.subTitle {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n}\n.preLine {\n    white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subTitle": "subTitle_lW4HD",
	"preLine": "preLine__eoeK"
};
export default ___CSS_LOADER_EXPORT___;
