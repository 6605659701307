// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".informationAboutUserCard_gAUwN {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 24px 32px;\n  background: #f8f8fa;\n  border: 1px solid #ebecf0;\n  border-radius: 24px;\n  flex-grow: 1;\n}\n.informationAboutUserCardTitle_QnVFY {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  display: flex;\n  align-items: center;\n  color: #303440;\n}\n.informationAboutUserCardSubtitle_fLfZE {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  display: flex;\n  align-items: center;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/QualificationStatementsList/components/InformationAboutUser/informationAboutUser.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AADJ;AAGI;EACI,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AADR;AAII;EACI,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAFR","sourcesContent":["@import 'style/palette';\n\n.informationAboutUserCard {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 24px 32px;\n    background: @gray-2;\n    border: 1px solid #ebecf0;\n    border-radius: 24px;\n    flex-grow: 1;\n\n    &Title {\n        font-family: 'SF Pro Display';\n        font-style: normal;\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        display: flex;\n        align-items: center;\n        color: @gray-10;\n    }\n\n    &Subtitle {\n        font-family: 'SF Pro Display';\n        font-style: normal;\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        display: flex;\n        align-items: center;\n        color: @gray-10;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"informationAboutUserCard": "informationAboutUserCard_gAUwN",
	"informationAboutUserCardTitle": "informationAboutUserCardTitle_QnVFY",
	"informationAboutUserCardSubtitle": "informationAboutUserCardSubtitle_fLfZE"
};
export default ___CSS_LOADER_EXPORT___;
