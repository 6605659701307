import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialState } from './resources'

const exchangeOperatorsSlice = createSlice({
    name: 'exchangeOperators',
    initialState,
    reducers: {
        setexchangeOperators: (state, action) => {
            return { ...state, data: action.payload, isLoading: false, error: '' }
        },
        setexchangeOperatorsLoading: (state) => {
            return { ...state, isLoading: true, data: [], error: '' }
        },
        setexchangeOperatorsError: (state, action) => {
            return { ...state, error: action.payload, isLoading: false, data: [] }
        },
        selectOpertorToDelete: (state, action) => {
            state.selectedToDelete = action.payload
        },
    },
})

export const getExchangeOperatorsPage = createAction('exchangeOperators/getPage')

export const {
    setexchangeOperators,
    setexchangeOperatorsLoading,
    setexchangeOperatorsError,
    selectOpertorToDelete,
} = exchangeOperatorsSlice.actions

export default exchangeOperatorsSlice.reducer
