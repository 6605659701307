import {
    ArrowLeft,
    Box,
    Button,
    Form,
    KppInput,
    LeftMini,
    WrappedInput,
    commonValidators,
    documentValidators,
    onlyDigits,
} from '@dltru/dfa-ui'
import { FC } from 'react'

import style from './style.m.less'

export interface IDebitBankAccountValues {
    recipient_bank_bic: string
    recipient_account_number: string
    recipient_inn: string
    recipient: string
    recipient_bank_name: string
    recipient_corr_account_number: string
    recipient_kpp: string
}

interface IComponentProps {
    saveBankAccount: (values: IDebitBankAccountValues) => void
    changeBodyToMain: () => void
    initialValues: Partial<IDebitBankAccountValues>
}

export const DebitBankAccountBody: FC<IComponentProps> = ({
    saveBankAccount,
    changeBodyToMain,
    initialValues,
}) => {
    const [form] = Form.useForm()
    const onSubmitForm = (values: IDebitBankAccountValues) => {
        saveBankAccount(values)
        changeBodyToMain()
    }

    return (
        <div>
            <div className={style.body}>
                <Box padding={[0, 0, 16, 0]}>
                    <Button
                        type="text"
                        icon={<ArrowLeft />}
                        onClick={changeBodyToMain}
                        className={style.bigArrowButton}
                        width="max-content"
                    >
                        Реквизиты перевода
                    </Button>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitForm}
                    initialValues={initialValues}
                >
                    <WrappedInput
                        label="БИК банка получателя"
                        name="recipient_bank_bic"
                        placeholder="Состоит из 9 цифр"
                        required
                        rules={[{ validator: commonValidators.number(9) }]}
                        maxLength={9}
                        normalize={onlyDigits}
                        size="large"
                    />
                    <WrappedInput
                        label="Номер счёта получателя"
                        name="recipient_account_number"
                        placeholder="Состоит из 20 цифр"
                        required
                        rules={[{ validator: commonValidators.number(20) }]}
                        maxLength={20}
                        normalize={onlyDigits}
                        size="large"
                    />
                    <WrappedInput
                        label="ИНН"
                        placeholder="Введите ИНН получателя"
                        required
                        name="recipient_inn"
                        size="large"
                        normalize={onlyDigits}
                        rules={[{ validator: documentValidators.inn }]}
                        maxLength={12}
                    />
                    <WrappedInput
                        label="Наименование получателя"
                        name="recipient"
                        placeholder="Введите наименование получателя"
                        required
                        size="large"
                    />

                    <KppInput
                        className=""
                        label="КПП получателя"
                        placeholder="Состоит из 9 цифр"
                        required
                        name="recipient_kpp"
                        size="large"
                    />
                    <WrappedInput
                        label="Наименование банка получателя"
                        name="recipient_bank_name"
                        placeholder="Введите название банка"
                        required
                        size="large"
                    />

                    <WrappedInput
                        label="Корреспондентский счет банка"
                        name="recipient_corr_account_number"
                        placeholder="Состоит из 20 цифр"
                        required
                        rules={[{ validator: commonValidators.number(20) }]}
                        maxLength={20}
                        normalize={onlyDigits}
                        size="large"
                    />
                </Form>
            </div>

            <div>
                <Button
                    type="link"
                    onClick={form.submit}
                    icon={<LeftMini />}
                    className={style.smallLinkButton}
                >
                    Сохранить реквизиты
                </Button>
            </div>
        </div>
    )
}
