import { Box, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getStatementQualifyEvents } from '@store/qualifyStatement'
import { qualifyStatementSelector } from '@store/qualifyStatement/selectors'

import { gridConfig } from './gridConfig'

interface IEventsTableProps {
    applicationUuid?: string
}

export const EventsTable: FC<IEventsTableProps> = ({ applicationUuid }) => {
    const reduxDispatch = useDispatch()

    const events = useSelector(qualifyStatementSelector.selectCurrentQualifyStatementEvents)

    useEffect(() => {
        if (applicationUuid) {
            reduxDispatch(getStatementQualifyEvents(`?application_uuid=${applicationUuid}`))
        }
    }, [applicationUuid])

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={false}
                columns={gridConfig}
                dataSource={events ?? []}
                clickableRow={false}
                rowKey="id"
            />
        </Box>
    )
}
