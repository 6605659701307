import {
    GetProfilesParams,
    ICursorPaginationResponse,
    ProfileListType,
    approvalProfileStatuses,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: Partial<ICursorPaginationResponse<ProfileListType>>
    error: string
    params: GetProfilesParams
}
const initialState: IInitialState = {
    isLoading: false,
    error: '',
    data: {},
    params: {
        limit: DEFAULT_PER_PAGE,
        order: 'desc(created_at)' as const,
        profile_status: approvalProfileStatuses,
    },
}

export const registrationApplicationListSlice = createSlice({
    name: 'registrationApplicationList',
    initialState,
    reducers: {
        setItems: (
            state,
            action: PayloadAction<Partial<ICursorPaginationResponse<ProfileListType>>>,
        ) => {
            state.data.items = action.payload?.items ?? []
            state.data.paging = action.payload?.paging
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        clear: (state) => {
            state.isLoading = false
            state.error = ''
            state.data = {}
            state.params = initialState.params
        },
        getItems: (state, action: PayloadAction<GetProfilesParams>) => {
            state.params = isEmptyValue(action.payload) ? initialState.params : action.payload
        },
    },
})

export default registrationApplicationListSlice.reducer
