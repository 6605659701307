export const DEFAULT_ACTIVITY_EVENTS = [
    'click',
    'mousemove',
    'keydown',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
] as string[]

export const TIME_TO_LOGOUT = 15000
export const COUNTDOWN_TICK = 1000
export const MS = 1000
