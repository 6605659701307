import clsx from 'clsx'
import { FC } from 'react'

import './style.less'

export type ColoredTagsProps = {
    type:
        | 'emission'
        | 'secondary'
        | 'repayment'
        | 'buy'
        | 'sell'
        | 'transfer'
        | 'transfer_buy'
        | 'transfer_sell'
        | 'gray'
        | 'blue'
        | 'gray_right'
}
export const ColoredTag: FC<ColoredTagsProps> = ({ children, type }) => (
    <span className={clsx('dfa-colored-tag', `dfa-colored-tag__${type}`)}>{children}</span>
)
