import { InvestorRegistryApplication } from '@dltru/dfa-models'
import { Alert, EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'

interface IRejectAlertProps {
    data?: InvestorRegistryApplication
}
export const RejectAlert: FC<IRejectAlertProps> = ({ data }) => {
    const message = 'В согласовании отказано'
    const date = data?.created_at
        ? dayjs.unix(data.created_at).locale('ru').format(VIEW_DATE_FORMAT)
        : EMPTY_FIELD_VALUE

    const description = (
        <>
            {date} <br />
            {data?.comment || ''}
        </>
    )
    return <Alert message={message} description={description} type="error" showIcon />
}
