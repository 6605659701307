import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, PauseCircleOutlined } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { PausedIssueModal } from '../Modals'

export const PausedIssueAction: FC = () => {
    const status = useSelector(dfaDetailsSelector.selectDfaDetailsStatus)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { isAllow } = useContext(RbacContext)
    const showButton =
        isAllow(['asset:status']) &&
        (status === DfaStatusEnum.collection_started || status === DfaStatusEnum.waiting_for_links)
    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<PauseCircleOutlined />}
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
            >
                Приостановить выпуск
            </Button>
            <PausedIssueModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
