import { ISecondaryOrder } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadSecondaryDfas } from '@store/secondaryDfa/list'
import IAppState from '@store/states'

import { SecondaryOrderDetailsModal, SecondaryRevokeModal } from '@components/Modals/Orders'

import { getGridConfig } from './gridConfig'

//import { IsAllow } from '@dltru/dfa-rbac'

export const SecondaryOrdersTable: FC = () => {
    const reduxDispatch = useDispatch()
    const { data, isLoading } = useSelector((store: IAppState) => store.secondaryDfaList)
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<ISecondaryOrder>()

    useEffect(() => {
        reduxDispatch(loadSecondaryDfas())
    }, [])

    const onRow = (order: ISecondaryOrder) => ({
        onClick: () => {
            setIsCardModalVisible(true)
            setCurrentOrder(order)
        },
    })
    const actionCancel = (order: ISecondaryOrder) => {
        setCurrentOrder(order)
        setIsRevokeModalVisible(true)
    }

    const gridConfig = getGridConfig({ actionCancel })

    return (
        <>
            {/*<IsAllow>123</IsAllow>*/}
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                onRow={onRow}
                clickableRow
            />
            <SecondaryOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={currentOrder}
            />
            <SecondaryRevokeModal
                setIsModalVisible={setIsRevokeModalVisible}
                isModalVisible={isRevokeModalVisible}
                order={currentOrder}
            />
        </>
    )
}
