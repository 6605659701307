import dayjs from 'dayjs'
import React from 'react'

export const DateTime: React.FC<{
    value?: number
    isUnix?: boolean
}> = ({ value, isUnix = true }) => {
    if (value == null) {
        return null
    }
    const date = isUnix ? dayjs.unix(value) : dayjs(value)
    return <span>{date.format('DD.MM.YYYY, HH:mm:ss')}</span>
}
