import { userRolesTranslate } from '@dltru/dfa-models'
import { DateTimeCell, DocumentText, Space, getTariffCode } from '@dltru/dfa-ui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { tariffSlice } from '@store/tariff'
import { tariffSelector } from '@store/tariff/selectors'

import { getStatusTagPlane } from './helper'
import style from './style.m.less'

export const BaseTariff = () => {
    const baseTariff = useSelector(tariffSelector.selectBaseTariff)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        reduxDispatch(tariffSlice.actions.getBaseTariff())
    }, [])

    if (!baseTariff) {
        return null
    }

    return (
        <div className={style.topCard}>
            <Space>
                <DocumentText />
                <strong>{baseTariff.name}</strong>
                <strong>{getTariffCode(baseTariff)}</strong>
                {getStatusTagPlane(baseTariff)}
            </Space>
            <Space>
                <span>Создан</span>
                <span>{DateTimeCell(baseTariff.created_at)}</span>
                <Link to={`/employees/${baseTariff.created_by}`}>
                    <strong>{baseTariff.created_by_name || baseTariff.created_by}</strong>
                </Link>
                {baseTariff.created_by_role && (
                    <span>({userRolesTranslate[baseTariff.created_by_role]})</span>
                )}
            </Space>
        </div>
    )
}
