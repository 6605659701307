import { ClientAutocomplete } from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType } from '@dltru/dfa-ui'
import { transactionStatusesOptions } from '@resources/transactionState'

import api from '@services/api'

const ClientsOption: React.FC<{ data?: DataProviderResponse }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <p> {data.inn} </p>
        </div>
    )
}

const autoGetClientComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getClientsService({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item: ClientAutocomplete) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Дата и время поручения',
        name: 'created_at',
        filterType: FilterType.DATE_RANGE,
        divider: true,
    },

    {
        label: 'Клиент',
        name: 'sender_id',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientComplete,
        renderElement: <ClientsOption />,
    },
    {
        label: 'Получатель',
        name: 'account_id',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientComplete,
        renderElement: <ClientsOption />,
        divider: true,
    },
    {
        label: 'Статус поручения клиента',
        name: 'status',
        filterType: FilterType.SELECT,
        options: transactionStatusesOptions,
    },
]
