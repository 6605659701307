import { Box, ChangePhoneForm, PageHeader } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import {
    TokenStatusEnum,
    authSlice,
    changePhoneConfirm,
    changePhoneRequest,
    checkConfirmToken,
} from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { AuthSideBox } from '@components/SideBox'

export const ChangePhonePage: FC = () => {
    const { tokenStatus, phoneVerificationStatus } = useSelector(authSelector.selectChangeAuthState)
    const reduxDispatch = useDispatch()
    const { token } = useParams()

    const navigate = useNavigate()
    useEffect(() => {
        reduxDispatch(authSlice.actions.setChangeAuthData({ phoneVerificationStatus: 'ready' }))
    }, [])
    useEffect(() => {
        if (phoneVerificationStatus === 'confirmed') {
            navigate('/login')
        }
    }, [phoneVerificationStatus])

    const confirmCall = (code: string) => {
        reduxDispatch(changePhoneConfirm(code))
    }

    const sendNewPhone = (phone: string) => {
        if (token) {
            reduxDispatch(
                changePhoneRequest({
                    phone,
                    confirm_token: token,
                }),
            )
        }
    }

    useEffect(() => {
        if (token) {
            reduxDispatch(checkConfirmToken(token))
        }
        return () => {
            reduxDispatch(authSlice.actions.clearChangeAuthData())
        }
    }, [token])

    if (tokenStatus !== TokenStatusEnum.verify) {
        return (
            <>
                <Box padding={16}>
                    <PageHeader title="Смена номера телефона" />
                </Box>
                <Box padding={24}>
                    <h3>{tokenStatus}</h3>
                </Box>
            </>
        )
    }
    return (
        <Box direction="row" align="center" justify="center" height="100%">
            <Box direction="row">
                <AuthSideBox />
                <ChangePhoneForm
                    sendNewPhone={sendNewPhone}
                    phoneVerificationStatus={phoneVerificationStatus}
                    confirmCall={confirmCall}
                />
            </Box>
        </Box>
    )
}
