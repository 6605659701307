import { useCallback, useEffect, useRef, useState } from 'react'

import { TIME_TO_LOGOUT } from './consts'

export interface IUseIdleProps {
    activityEvents: string[]
    timeToLogout: number | null
}

const addEventListeners = (activityEvents: string[], handleActivityEvent: () => void) => {
    activityEvents.forEach((eventName) => window.addEventListener(eventName, handleActivityEvent))
}

const removeEventListeners = (activityEvents: string[], handleActivityEvent: () => void) => {
    activityEvents.forEach((eventName) =>
        window.removeEventListener(eventName, handleActivityEvent),
    )
}

export const useIdle = ({ activityEvents, timeToLogout }: IUseIdleProps) => {
    const [isShowCountdownModal, setIsShowCountdownModal] = useState(false)
    const timer = useRef<ReturnType<typeof setTimeout>>()

    const timeToMessage = timeToLogout ? timeToLogout * 60 * 1000 - TIME_TO_LOGOUT : 0
    //const timeToMessage = 5000 //timeToLogout ? timeToLogout - 15000 : 20000

    const timeoutHandler = () => {
        removeEventListeners(activityEvents, activityHandler)
        setIsShowCountdownModal(true)

        timer.current && clearTimeout(timer.current)
    }

    const activityHandler = useCallback(() => {
        timer.current && clearTimeout(timer.current)
        timer.current = setTimeout(timeoutHandler, timeToMessage)
    }, [timeoutHandler, timeToMessage])

    const start = () => {
        stop()
        addEventListeners(activityEvents, activityHandler)
        timer.current = setTimeout(timeoutHandler, timeToMessage)
    }

    const stop = () => {
        isShowCountdownModal && setIsShowCountdownModal(false)

        timer.current && clearTimeout(timer.current)
        removeEventListeners(activityEvents, activityHandler)
    }

    useEffect(() => {
        if (!timeToLogout) {
            return
        }

        start()

        return () => {
            timer.current && clearTimeout(timer.current)
            removeEventListeners(activityEvents, activityHandler)
        }
    }, [timeToLogout])

    return { start, stop, isShowCountdownModal }
}
