import { ITariffGrid } from '@dltru/dfa-models'
import { Dropdown, Menu, MoreOutlined } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useContext, useMemo } from 'react'

import { ActionButton } from '@components/ActionButton'

import style from './style.m.less'
import { RbacContext } from '@dltru/dfa-rbac'

type EventType = {
    domEvent: React.MouseEvent<HTMLElement>
}

export interface IActionCellProps {
    createFromTariffGrid: (gridId: string) => void
    editTariffGrid: (gridId: string) => void
    removeTariffGrid: (gridId: string) => void
    activateTariffGrid: (gridId: string) => void
    stopActivateTariffGrid: (gridId: string) => void
    record: ITariffGrid
}

export const ActionCell: FC<IActionCellProps> = ({
    record,
    createFromTariffGrid,
    editTariffGrid,
    removeTariffGrid,
    activateTariffGrid,
    stopActivateTariffGrid,
}) => {
    const { isAllow } = useContext(RbacContext)
    const handleDuplicate = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        createFromTariffGrid(record.id)
    }
    const handleEdit = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        editTariffGrid(record.id)
    }
    const handleActivate = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        activateTariffGrid(record.id)
    }
    const handleStopActivate = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        stopActivateTariffGrid(record.id)
    }
    const handleDelete = ({ domEvent }: EventType) => {
        domEvent.stopPropagation()
        removeTariffGrid(record.id)
    }
    const stopProp = (e: React.SyntheticEvent) => {
        e.stopPropagation()
    }

    const isDraft = !record.is_active
    const showStopActivate =
        !isDraft && dayjs().isBefore(dayjs(record.active_from)) && !record.deleted_at

    const menu = useMemo(
        () => (
            <Menu>
                {isDraft && (
                    <Menu.Item key="edit" onClick={handleEdit}>
                        Редактировать
                    </Menu.Item>
                )}
                {isDraft && (
                    <Menu.Item key="activate" onClick={handleActivate}>
                        Запланировать активацию
                    </Menu.Item>
                )}
                <Menu.Item key="duplicate" onClick={handleDuplicate}>
                    Создать на основе
                </Menu.Item>
                {showStopActivate && (
                    <Menu.Item key="stop-activate" onClick={handleStopActivate}>
                        Отменить активацию
                    </Menu.Item>
                )}
                {isDraft && (
                    <Menu.Item key="delete" onClick={handleDelete}>
                        Удалить
                    </Menu.Item>
                )}
            </Menu>
        ),
        [record],
    )

    if (!isAllow(['tariff.write.all'])) {
        return null
    }

    return (
        <Dropdown overlay={menu} trigger={['click']} overlayClassName={style.menu}>
            <ActionButton className="action-dropdown" onClick={stopProp}>
                <MoreOutlined />
            </ActionButton>
        </Dropdown>
    )
}
