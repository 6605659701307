import { UserRoles, blockedRoles } from '@dltru/dfa-models'
import { Alert, Box, CollapseDown, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

export const AlertUserBlock: FC = () => {
    const authData = useSelector(clientsSelector.selectCurrentAuthData)

    if (!authData || !blockedRoles.includes(authData.role)) {
        return null
    }
    const message = `Учетная запись ${
        authData.role === UserRoles.CLIENT_PARTLY_BLOCK
            ? 'частично заблокирована'
            : 'полностью заблокирована'
    }`
    const description = (
        <Box margin={[4, 0, 0, 0]}>
            <CollapseDown>
                {authData.block_created_at && (
                    <>
                        <span>
                            Дата блокирования{' '}
                            {dayjs.unix(authData.block_created_at).format(VIEW_DATE_FORMAT)}
                        </span>
                        <br />
                    </>
                )}
                {Boolean(authData.block_reason) && <span>{authData.block_reason}</span>}
                {/* <UneditableUploadedFiles files={[{ uid: 'file' }]} /> */}
            </CollapseDown>
        </Box>
    )
    return <Alert message={message} description={description} type="error" showIcon />
}
