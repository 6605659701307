import { ISecondaryOrder } from '@dltru/dfa-models'
import { ColoredTag, ColumnsType, DateTimeCell, LinkCell } from '@dltru/dfa-ui'

import { SecondaryOrderActionButton } from '@components/SecondaryOrderActionButton'

const AmountCell = (_, record: ISecondaryOrder) => (
    <ColoredTag type={record.order_type}>{record.original_amount_dfa.toLocaleString()}</ColoredTag>
)
interface IGridConfigProps {
    actionCancel: (order: ISecondaryOrder) => void
}

export const getGridConfig = (props: IGridConfigProps): ColumnsType<ISecondaryOrder> => [
    {
        title: 'Подача заявки',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Инвестор',
        dataIndex: 'user_id',
        key: 'user_id',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(record?.user_full_name, `/clients/${value}`),
    },
    {
        title: 'Кол-во, ед.',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: AmountCell,
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Стоимость, ₽',
        dataIndex: 'total_price',
        key: 'total_price',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    /* TODO позже вернуть
   {
        title: 'До снятия',
        dataIndex: 'expired_at',
        key: 'expired_at',
        ellipsis: true,
        render: RestDateCell(),
    },*/
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        ellipsis: true,
        width: 80,
        render: (_, record) => <SecondaryOrderActionButton order={record} {...props} />,
    },
]
