/* eslint-disable unused-imports/no-unused-vars */
import { ITransferDeal, UnionDealFront, UnionDealType } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { DfaDealsNS, dfaDealsListSlice } from './index'

function* loadDfaDeals({ payload }: DfaDealsNS.GetDfaDealsById) {
    try {
        yield* put(dfaDealsListSlice.actions.setIsLoading(true))
        const { data: emissionDealsData, error: emissionDealsError } = yield* call(
            api.lib.getDfaDealsByDfaId,
            payload,
        )
        if (emissionDealsError) {
            throw emissionDealsError
        }
        const emissionDeals: UnionDealFront[] =
            (emissionDealsData?.items as UnionDealType[])?.map((deal) => ({
                ...deal,
                type: 'emission',
            })) ?? []

        const { data: secondaryDealsData, error: secondaryDealsError } = yield* call(
            api.lib.getSecondaryDeals,
            { asset_id: payload },
        )
        if (secondaryDealsError) {
            throw secondaryDealsError
        }
        const secondaryDeals: UnionDealFront[] =
            (secondaryDealsData?.items as UnionDealType[])?.map((deal) => ({
                ...deal,
                type: 'secondary',
            })) ?? []

        const { data: repaymentDealsData, error: repaymentDealsError } = yield* call(
            api.lib.getRepaymentDeals,
            { asset_id: payload },
        )
        if (repaymentDealsError) {
            throw repaymentDealsError
        }

        const repaymentDeals: UnionDealFront[] =
            (repaymentDealsData?.items as UnionDealType[])?.map((deal) => ({
                ...deal,
                type: 'repayment',
            })) ?? []

        const { data: transferDealsData, error: transferDealsError } = yield* call(
            api.lib.getTransferDfaDeals,
            {
                asset_id: payload,
            },
        )
        if (transferDealsError) {
            throw transferDealsError
        }

        const transferDeals: UnionDealFront[] =
            (transferDealsData?.items as ITransferDeal[])
                ?.filter((deal) => +payload === deal.order.seller_asset_data.asset_id)
                .map((deal) => ({
                    ...deal,
                    type: 'transfer',
                })) ?? []

        const _data = [...repaymentDeals, ...secondaryDeals, ...transferDeals, ...emissionDeals]

        yield* put(dfaDealsListSlice.actions.setDfaDeals(_data))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить информацию по сделкам',
        })
        yield* put(dfaDealsListSlice.actions.setError(String(error)))
    } finally {
        yield* put(dfaDealsListSlice.actions.setIsLoading(false))
    }
}

export function* dfaDealsSagas(): Generator<StrictEffect> {
    yield* takeLatest(dfaDealsListSlice.actions.getDfaDealsById.type, loadDfaDeals)
}
