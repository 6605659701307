import { Box, Button, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { tariffSlice } from '@store/tariff'

type PropsForModalType = {
    gridId?: string
}
interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    props: PropsForModalType
}
export const ActivateGridModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    props,
}) => {
    const reduxDispatch = useDispatch()
    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const handleOk = () => {
        if (!props.gridId) {
            return
        }
        reduxDispatch(tariffSlice.actions.activateTariffGrid(props.gridId))
        handleCancel()
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={423}
            title={
                <Space size={0} align="start">
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Запланировать активацию тарифной сетки
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={handleCancel} key="cancel">
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={handleOk} key="ok">
                    Да, запланировать активацию
                </Button>,
            ]}
        >
            <Box padding={[16, 0, 0, 40]}>
                <p>
                    Тарифная сетка начнет действовать для данного тарифного плана в дату активации.
                    Предыдущая действующая тарифная сетка будет отправлена в архив.
                </p>
                <p> Вы действительно хотите запланировать активацию тарифной сетки?</p>
            </Box>
        </MiniModal>
    )
}
