import { ProfileStatus, inProcessProfileStatuses } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    Box,
    CopyButton,
    LockIcon,
    PageHeader,
    Space,
    Tabs,
    Tag,
    UserOutlined,
    getFullName,
} from '@dltru/dfa-ui'
import { getProfileTagProps } from '@resources/anketaStatus'
import { FC, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSelector } from '@store/clients/selectors'

import { ClientTags } from '@pages/ClientCard/components/ClientTags'
import { NodeTags } from '@pages/ClientCard/components/NodeTags'

import { UseTabsAndHash } from '../../hooks/useTabsAndHash'
import { Balance } from './components/Balance'
import { ClientDfas } from './components/ClientDfas'
import { Footer } from './components/Footer'
import { OrdersTab } from './components/OrdersTab'
import { OtherDfaTab } from './components/OtherDfaTab'
import { Profile } from './components/Profile'
import { RegistrationCard } from './components/RegistrationCard'
import { Wallet } from './components/Wallet'

enum TabName {
    profile = 'profile',
    account = 'account',
    wallet = 'wallet',
    dfas = 'dfas',
    orders = 'orders',
    logs = 'logs',
    other = 'other',
}
const defaultTab = TabName.profile

export const ClientCard: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const { id } = useParams()
    const statusProfile = useSelector(clientsSelector.selectMainStatus)
    const isBlocked = useSelector(clientsSelector.selectIsCurrentClientBlocked)
    const profile = useSelector(clientsSelector.selectProfile)
    const isEmitter = useSelector(clientsSelector.isEmitter)
    const isAdmin = isAllow(['super'])
    const isAccountView = isAllow(['account.read.all'])
    const isWalletView = isAllow(['wallet.read.all'])
    const isDfasView = isAllow(['asset.read.all'])
    const isOrdersView = isAllow(['offer.read.all'])
    const isOtherDealView = isAllow(['deal.read.all'])

    const tabNames = useMemo(() => {
        const allowTabNames = [TabName.profile]
        if (isAdmin) {
            allowTabNames.push(TabName.logs)
        }
        if (isAccountView) {
            allowTabNames.push(TabName.account)
        }
        if (isWalletView) {
            allowTabNames.push(TabName.wallet)
        }
        if (isDfasView) {
            allowTabNames.push(TabName.dfas)
        }
        if (isOrdersView) {
            allowTabNames.push(TabName.orders)
        }
        if (isOtherDealView) {
            allowTabNames.push(TabName.other)
        }
        return allowTabNames
    }, [isAllow])
    const { onChangeTab, activeTab } = UseTabsAndHash(tabNames, defaultTab)

    if (!statusProfile) {
        return null
    }
    const tagProps = getProfileTagProps(statusProfile, isAllow)

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    tags={tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined}
                    title={
                        <Space size={12} align="center">
                            {isBlocked ? <LockIcon width={26} height={26} /> : <UserOutlined />}
                            <span>
                                {inProcessProfileStatuses.includes(statusProfile)
                                    ? 'Регистрация: '
                                    : ''}
                                {getFullName(profile)}
                            </span>
                            <ClientTags />
                        </Space>
                    }
                    subTitle={
                        <>
                            ID {profile?.user_uuid}&ensp;
                            {profile?.user_uuid && <CopyButton text={profile.user_uuid} />}
                        </>
                    }
                />
                <NodeTags />
            </Box>
            {statusProfile !== ProfileStatus.APPROVED && <RegistrationCard />}
            {statusProfile === ProfileStatus.APPROVED && (
                <Tabs onChange={onChangeTab} activeKey={activeTab}>
                    <Tabs.TabPane tab="Профиль" key={TabName.profile}>
                        {id && <Profile />}
                    </Tabs.TabPane>
                    {isAccountView && (
                        <Tabs.TabPane tab="Лицевой счёт" key={TabName.account}>
                            <Balance />
                        </Tabs.TabPane>
                    )}
                    {isWalletView && (
                        <Tabs.TabPane tab="Кошелёк" key={TabName.wallet}>
                            <Wallet userId={id} />
                        </Tabs.TabPane>
                    )}
                    {isDfasView && isEmitter && (
                        <Tabs.TabPane tab="Выпуски пользователя" key={TabName.dfas}>
                            <ClientDfas />
                        </Tabs.TabPane>
                    )}
                    {isOrdersView && (
                        <Tabs.TabPane tab="Заявки пользователя" key={TabName.orders}>
                            <OrdersTab />
                        </Tabs.TabPane>
                    )}
                    {isAdmin && <Tabs.TabPane tab="Логи" key={TabName.logs}></Tabs.TabPane>}
                    {isOtherDealView && (
                        <Tabs.TabPane tab="Иные сделки с ЦФА" key={TabName.other}>
                            <OtherDfaTab />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            )}
            <Footer />
        </>
    )
}
