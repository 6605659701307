import { UploadFile } from 'antd/lib/upload/interface'

import { FieldNamesOnlyFile } from '../../models'

export const getInitialFileFromDoc = (uid: string): UploadFile => ({ name: '', uid })
export const getInitialFile = (uid?: string) =>
    uid ? uid.split(',').map(getInitialFileFromDoc) : undefined
export const getInitialFiles = (uids?: string[]) =>
    uids ? uids.map(getInitialFileFromDoc) : undefined

export function getDocumentUid(docs: UploadFile[] | string) {
    if (!docs?.length) {
        return null
    }
    if (typeof docs === 'string') {
        return docs
    }
    return docs[0].uid
}

export const getInitialFileWithIndex =
    (fieldName: FieldNamesOnlyFile) => (uid: string, index: number) => ({
        name: `${fieldName}${index}`,
        uid,
    })

export const mapFilesList = (fieldName: FieldNamesOnlyFile, value?: string) =>
    value ? value.split(',').map(getInitialFileWithIndex(fieldName)) : undefined
