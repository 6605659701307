import {
    IDfaFront,
    IssueTypeDfa,
    afterEmitmentStatuses,
    beforeEmitmentStatuses,
} from '@dltru/dfa-models'
import dayjs from 'dayjs'

import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '../../../constants'

export const IssueDateCell = (_: unknown, value: IDfaFront) => {
    if (beforeEmitmentStatuses.includes(value.status)) {
        return dayjs(value.issue_date).format(VIEW_DATE_FORMAT)
    }
    if (afterEmitmentStatuses.includes(value.status)) {
        return value.issue_type === IssueTypeDfa.auto && value.issue_end_date
            ? dayjs(value.issue_end_date).format(VIEW_DATE_FORMAT)
            : EMPTY_FIELD_VALUE
    }

    return EMPTY_FIELD_VALUE
}
