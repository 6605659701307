import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

interface ICommentDetailProps {
    value: string
}

export const CommentDetail: FC<ICommentDetailProps> = ({ value }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    const onCloseHandler = () => {
        setIsShowModal(false)
    }

    return (
        <>
            <Button
                type="linkText"
                onClick={() => {
                    setIsShowModal(true)
                }}
            >
                Посмотреть
            </Button>

            <MiniModal visible={isShowModal} onCancel={onCloseHandler} width={423}>
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">Комментарий</div>
                    </Box>
                    <Box direction="row" align="center" justify="right">
                        <div className="modal-content-shift">&nbsp;</div>
                        <Box padding={[0, 32, 16, 0]}>
                            <div className="modal-content-text">{value}</div>
                        </Box>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        direction="row"
                        justify="right"
                        padding={0}
                    >
                        <Button borderRadius={12} onClick={onCloseHandler}>
                            Закрыть
                        </Button>
                    </Box>
                </div>
            </MiniModal>
        </>
    )
}
