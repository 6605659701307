import style from './style.m.less'

export interface IInfotagProps {
    label: string
    value?: string
}

export const Infotag: React.FC<IInfotagProps> = ({ label, value, children }) => {
    return (
        <div className={style.infotagWrapper}>
            <div className={style.infotagLabel}>{label}</div>
            {children || <div className={style.infotagValue}>{value}</div>}
        </div>
    )
}
