import { validatorConstructor } from '../../../utils'

export const setPasswordValidators = (error: string, passwordValue: string) => {
    return [
        {
            validator: async (_: unknown, value: string) => {
                if (!value) {
                    return Promise.reject(new Error('Поле обязательно для заполнения'))
                }
                if (passwordValue !== value) {
                    return Promise.reject(new Error('Пароли не совпадают'))
                }
                Promise.resolve()
            },
        },
        validatorConstructor(error === 'EXPIRED_JWT', 'Ошибка: Время создания пароля вышло'),
        validatorConstructor(error === 'CRYPTO_VERIFICATION_ERROR', 'Ошибка верификации токена'),
        validatorConstructor(
            error === 'TOKEN_NOT_VALID',
            'Ошибка: Невалидный токен. Возможно, он отсутствует',
        ),
        validatorConstructor(error === 'PASSWORD_ALREADY_SET', 'Пароль уже установлен'),
        validatorConstructor(error === 'INCORRECT_RESPONSE', 'Некорректный ответ с сервера'),
    ]
}
