/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    GetTransactionParams,
    ICursorPaginationResponse,
    IMoneyReserveTransferSauToBankAccount,
    PaymentOrder,
    PaymentOrderExtensions,
    TransactionType,
    TransactionTypes,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TablePaginationParam } from '@services/types'

interface IInitialState {
    isLoading: boolean
    error: string | null
    currentTransaction: TransactionType | null
    currentPaymentOrders: PaymentOrder[]
    transactionListData: Partial<ICursorPaginationResponse<TransactionType>>
    paymentOrderListData: Partial<ICursorPaginationResponse<PaymentOrder>>
    transactionParams: GetTransactionParams
}
const initialState: IInitialState = {
    isLoading: false,
    error: null,
    currentTransaction: null,
    currentPaymentOrders: [],
    transactionListData: {},
    paymentOrderListData: {},
    transactionParams: {
        limit: DEFAULT_PER_PAGE,
        order: 'desc(created_at)' as const,
        type: [TransactionTypes.debit, TransactionTypes.payment, TransactionTypes.any_payment],
    },
}

type GetFilePaymentOrder = PayloadAction<{ uuid: string; extension: PaymentOrderExtensions }>
type ActionPaymentOrderByTransaction = PayloadAction<IMoneyReserveTransferSauToBankAccount>
type ActionRejectPaymentOrderByTransaction = PayloadAction<IMoneyReserveTransferSauToBankAccount>

export const paymentOrdersSlice = createSlice({
    name: 'paymentOrders',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setError(state, action: PayloadAction<IInitialState['error']>) {
            state.error = action.payload ?? null
        },

        getCurrentTransaction(_, action: PayloadAction<string>) {},
        setCurrentTransaction(state, action: PayloadAction<TransactionType>) {
            state.currentTransaction = action.payload
        },
        clearCurrentTransaction(state) {
            state.currentTransaction = null
            state.currentPaymentOrders = []
            state.error = null
            state.isLoading = false
        },
        clearData(state) {
            state.currentTransaction = null
            state.transactionListData = {}
            state.transactionParams = initialState.transactionParams
            state.paymentOrderListData = {}
            state.error = null
            state.isLoading = false
        },
        getPaymentOrders(_, action: PayloadAction<TablePaginationParam | undefined>) {},
        setPaymentOrders(state, action: PayloadAction<ICursorPaginationResponse<PaymentOrder>>) {
            state.paymentOrderListData.items = action.payload.items
            state.paymentOrderListData.paging = action.payload.paging
        },
        getTransactions(state, action: PayloadAction<GetTransactionParams>) {
            state.transactionParams = isEmptyValue(action.payload)
                ? initialState.transactionParams
                : action.payload
        },
        setTransactions(state, action: PayloadAction<ICursorPaginationResponse<TransactionType>>) {
            state.transactionListData.items = action.payload.items
            state.transactionListData.paging = action.payload.paging
        },
        getPaymentOrdersByTransaction(_, action: PayloadAction<string>) {},
        setPaymentOrdersByTransaction(state, action: PayloadAction<PaymentOrder[]>) {
            state.currentPaymentOrders = action.payload
        },
        createPaymentOrder(_, action: PayloadAction<string>) {},
        rejectPaymentOrder(_, action: PayloadAction<string>) {},
        getFilePaymentOrder(_, action: GetFilePaymentOrder) {},
        sendExecutedPaymentOrder(_, action: ActionPaymentOrderByTransaction) {},
        sendNotExecutedPaymentOrder(_, action: ActionRejectPaymentOrderByTransaction) {},
    },
})

export default paymentOrdersSlice.reducer
