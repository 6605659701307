// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".topCard_zokQF {\n  display: flex;\n  justify-content: space-between;\n  padding: 18px 24px;\n  margin-top: 24px;\n  background-color: #ffffff;\n  border: 1px solid #ebecf0;\n  box-shadow: 0 2px 14px rgba(18, 3, 56, 0.1);\n  border-radius: 16px;\n  color: #303440;\n  line-height: 22px;\n}\n.topCard_zokQF svg {\n  width: 20px;\n  height: 20px;\n}\n.topCard_zokQF .anticon {\n  vertical-align: -0.23em;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SystemManagement/components/TariffTable/style.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,yBAAA;EACA,2CAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;AADJ;AATA;EAYQ,WAAA;EACA,YAAA;AAAR;AAbA;EAiBY,uBAAA;AADZ","sourcesContent":["@import 'style/palette';\n\n.topCard {\n    display: flex;\n    justify-content: space-between;\n    padding: 18px 24px;\n    margin-top: 24px;\n    background-color: @gray-1;\n    border: 1px solid @gray-4;\n    box-shadow: 0 2px 14px rgba(18, 3, 56, 0.1);\n    border-radius: 16px;\n    color: @gray-10;\n    line-height: 22px;\n    svg {\n        width: 20px;\n        height: 20px;\n    }\n    :global {\n        .anticon {\n            vertical-align: -0.23em;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topCard": "topCard_zokQF"
};
export default ___CSS_LOADER_EXPORT___;
