import { BindBankAccountModel, ProfileStatus } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, VuesaxIcon } from '@dltru/dfa-ui'
import React, { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { RelateAccountModal } from '../modals/RelateAccountModal'

export const RelateAccountAction: FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { id } = useParams()
    const profileStatus = useSelector(clientsSelector.selectMainStatus)
    const profileType = useSelector(clientsSelector.selectProfileTypes)
    const profile = useSelector(clientsSelector.selectProfile)
    const reduxDispatch = useDispatch()

    const { isAllow } = useContext(RbacContext)

    const isVisibleBtn =
        profileType &&
        !profile?.nominal_account_uuid &&
        isAllow(['approval.operator']) &&
        profileStatus &&
        profileStatus === ProfileStatus.IDENTIFICATION &&
        id

    if (!isVisibleBtn) {
        return null
    }

    const saveNominalAccount = (data: BindBankAccountModel) => {
        reduxDispatch(
            clientsSlice.actions.patchCurrentProfile({
                nominal_account_uuid: data.nominal_account_uuid,
            }),
        )
    }

    return (
        <>
            <Button
                type="primary"
                icon={<VuesaxIcon />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                Привязать ЛС к банковскому счёту
            </Button>
            <RelateAccountModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={saveNominalAccount}
            />
        </>
    )
}
