import {
    IAgentProfile,
    IAuthData,
    IBusinessmanProfile,
    IIndividualProfile,
    ILegalEntityProfile,
    PersonModelAgent,
    PersonModelBusinessman,
    PersonModelIndividual,
    ProfileType,
} from '@dltru/dfa-models'
import { EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import { getUserFullNameByType } from '@utils/profile'
import dayjs from 'dayjs'

const getWebSites = (websites?: string[]) => {
    if (!websites || !websites.length) {
        return [{ label: 'Сайт', value: EMPTY_FIELD_VALUE }]
    }

    return websites.map((site) => ({
        label: 'Сайт',
        value: (
            <a href={site} rel="noreferrer noopener" target="_blank">
                {site}
            </a>
        ),
    }))
}

const getPhones = (phones?: string[]) => {
    if (!phones || !phones.length) {
        return [{ label: 'Контактный телефон', value: EMPTY_FIELD_VALUE }]
    }

    return phones.map((phone) => ({
        label: 'Контактный телефон',
        value: (
            <a href={`tel: ${phone}`} rel="noreferrer noopener" target="_blank">
                {phone}
            </a>
        ),
    }))
}

const getEmails = (emails?: string[]) => {
    if (!emails || !emails.length) {
        return [{ label: 'Электронная почта', value: EMPTY_FIELD_VALUE }]
    }

    return emails.map((email) => ({
        label: 'Электронная почта',
        value: (
            <a href={`mailto: ${email}`} rel="noreferrer noopener" target="_blank">
                {email}
            </a>
        ),
    }))
}

export const dataForInfoCardPerson = (
    data?: PersonModelBusinessman | PersonModelIndividual | PersonModelAgent,
) => {
    if (!data) {
        return []
    }

    let issueDate = EMPTY_FIELD_VALUE
    if (data.passport?.issue_date) {
        issueDate = dayjs.unix(data.passport?.issue_date).format(VIEW_DATE_FORMAT)
    }

    const rows = [
        {
            label: 'Серия и номер паспорта',
            value: `${data.passport?.series} ${data.passport?.number}`,
        },
        { label: 'Выдан', value: data.passport?.issued_by },
        {
            label: 'Код подразделения',
            value: data.passport?.issuer_department_code || EMPTY_FIELD_VALUE,
        },
        { label: 'Дата выдачи', value: issueDate },
        {
            label: 'Адрес регистрации',
            value: data.registration_address || EMPTY_FIELD_VALUE,
        },
    ]

    return rows
}

export const dataForInfoCardLegal = (data?: ILegalEntityProfile, authorizedData?: IAuthData) => {
    if (!data) {
        return []
    }

    let fullName = EMPTY_FIELD_VALUE
    if (data.legal_form && data.title) {
        fullName = getUserFullNameByType(ProfileType.LEGL, data) || EMPTY_FIELD_VALUE
    }

    const rows = [
        {
            label: 'Полное наименование',
            value: fullName,
        },
        { label: 'Юридический адрес', value: data.legal_address || EMPTY_FIELD_VALUE },
        {
            label: 'ИНН',
            value: data.inn || EMPTY_FIELD_VALUE,
        },
        { label: 'ОГРН', value: data.ogrn || EMPTY_FIELD_VALUE },
        ...getWebSites(data.websites),
        ...getPhones(authorizedData?.phone ? [authorizedData.phone] : undefined),
        ...getEmails(authorizedData?.email ? [authorizedData.email] : undefined),
    ]

    return rows
}

export const dataForInformationAboutCard = (
    data:
        | ILegalEntityProfile
        | IBusinessmanProfile
        | IIndividualProfile
        | IAgentProfile
        | undefined,
    type: ProfileType,
    authorizedData?: IAuthData,
) => {
    switch (type) {
        case ProfileType.LEGL: {
            return dataForInfoCardLegal(data as ILegalEntityProfile, authorizedData)
        }

        case ProfileType.BUSN: {
            const rows = dataForInfoCardPerson((data as IBusinessmanProfile).person)
            const phones = getPhones(authorizedData?.phone ? [authorizedData.phone] : undefined)
            const emails = getEmails(authorizedData?.email ? [authorizedData.email] : undefined)
            return [...rows, ...phones, ...emails]
        }

        case ProfileType.PRSN:
        case ProfileType.AGNT: {
            return dataForInfoCardPerson((data as IAgentProfile | IBusinessmanProfile).person)
        }

        default: {
            return []
        }
    }
}
