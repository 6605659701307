import {
    errorTranslates,
    getProfileRequestType,
    openMessage,
    openNotification,
} from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { getClientByUuid } from '@store/clients/sagas/clientsSagas'
import { clientsSelector } from '@store/clients/selectors'

import api from '@services/api'

import { clientsSlice } from '../index'

function* catchProfileError(error: unknown) {
    const errorTranslate = error?.error?.code ? errorTranslates[error.error.code] : ''
    openMessage({
        type: 'error',
        message: `Возникли ошибки при сохранении анкеты${
            errorTranslate ? `: ${errorTranslate}` : ''
        }`,
    })
}

function* changeLogin(action: ReturnType<typeof clientsSlice.actions.changeLogin>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.changeLoginInitAdmin, action.payload)

        if (error || data?.error) {
            throw error || data?.error
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось отправить письмо на смену авторизационных данных',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}
function* changePassword(action: ReturnType<typeof clientsSlice.actions.changePassword>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.changePasswordInitAdmin, action.payload)

        if (error || data?.error) {
            throw error || data?.error
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось отправить письмо на смену пароля',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}
function* changePhone(action: ReturnType<typeof clientsSlice.actions.changePhone>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.changePhoneInitAdmin, action.payload)

        if (error || data?.error) {
            throw error || data?.error
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось отправить письмо на смену номера телефона',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* changeEmail(action: ReturnType<typeof clientsSlice.actions.changeEmail>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        yield* put(clientsSlice.actions.setError(null))
        const { error, data } = yield* call(api.lib.changeEmailInitAdmin, action.payload)

        if (error || data?.error) {
            throw error || data?.error
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отправке письма на смену электронной почты',
        })
        yield* put(clientsSlice.actions.setError(String(error)))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* updateBusinessmanProfile({
    payload,
}: ReturnType<typeof clientsSlice.actions.updateBusinessmanProfile>) {
    try {
        const { error, data } = yield* call(api.lib.putBusinessmanProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* call(getClientByUuid, payload.businessman.user_uuid)
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* updateIndividualProfile({
    payload,
}: ReturnType<typeof clientsSlice.actions.updateIndividualProfile>) {
    try {
        const { error, data } = yield* call(api.lib.putIndividualProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* call(getClientByUuid, payload.individual.user_uuid)
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}
function* updateLegalEntityProfile({
    payload,
}: ReturnType<typeof clientsSlice.actions.updateLegalEntityProfile>) {
    try {
        const { error, data } = yield* call(api.lib.putLegalEntityProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* call(getClientByUuid, payload.legal_entity.user_uuid)
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* updateAgentProfile({
    payload,
}: ReturnType<typeof clientsSlice.actions.updateAgentProfile>) {
    try {
        const { error, data } = yield* call(api.lib.putAgentProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при обновлении анкеты',
        })
        yield* put(clientsSlice.actions.setError(String(error)))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* patchCurrentProfile({
    payload,
}: ReturnType<typeof clientsSlice.actions.patchCurrentProfile>) {
    try {
        const profileType = yield* select(clientsSelector.selectProfileTypes)
        const profileId = yield* select(clientsSelector.selectCurrentClientId)
        if (!profileId || !profileType?.length) {
            return
        }
        yield* put(clientsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(
            api.lib.patchProfileService,
            getProfileRequestType(profileType),
            profileId,
            payload,
        )
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* call(getClientByUuid, profileId)
        openNotification({
            type: 'success',
            message: 'Счета связаны',
            description: 'Лицевой счёт успешно привязан к банковскому счёту',
        })
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Счета не связаны',
            description: 'Возникли ошибки при попытке связать счета',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

export function* clientChangeData(): Generator<StrictEffect> {
    yield* takeLatest(clientsSlice.actions.changeLogin.type, changeLogin)
    yield* takeLatest(clientsSlice.actions.changePassword.type, changePassword)
    yield* takeLatest(clientsSlice.actions.changePhone.type, changePhone)
    yield* takeLatest(clientsSlice.actions.changeEmail.type, changeEmail)
    yield* takeLatest(clientsSlice.actions.updateBusinessmanProfile.type, updateBusinessmanProfile)
    yield* takeLatest(clientsSlice.actions.updateIndividualProfile.type, updateIndividualProfile)
    yield* takeLatest(clientsSlice.actions.updateLegalEntityProfile.type, updateLegalEntityProfile)
    yield* takeLatest(clientsSlice.actions.updateAgentProfile.type, updateAgentProfile)
    yield* takeLatest(clientsSlice.actions.patchCurrentProfile.type, patchCurrentProfile)
}
