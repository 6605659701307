import { CertificateType, stringToNumber } from '@dltru/dfa-models'
import { Box, Col, InfoCardsDfa, PageHeader, Row } from '@dltru/dfa-ui'
import { IFooter } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { createDFAOwnershipTransferRequestAction } from '@store/dfa/ownershipTransfer'

import { EmissionDecisionCardForOwnershipTransfer } from '@components/EmissionDecisionCard'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

import { DFAOwnershipReceiverForm, FormDataType } from './components/DFAOwnershipReceiverForm'
import { Footer } from './components/Footer'

type locationStateType = {
    user_id: string
}
export const CreateDFAOwnershipTransferRequestPage: FC = () => {
    const location = useLocation()
    const reduxDispatch = useDispatch()
    const uuid = useSelector(authSelector.selectUserUid)
    const { dfa: urlDfaParam } = useParams()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const navigate = useNavigate()
    const [formData, setFormData] = useState<FormDataType>({} as FormDataType)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const [footerStatus, setFooterStatus] = useState<IFooter['status']>('initial')

    useEffect(() => {
        if (urlDfaParam) {
            reduxDispatch(getDfaById(Number(urlDfaParam)))
        }
    }, [urlDfaParam])

    const onSubscribe = (certificate: CertificateType) => {
        const sellerId = (location?.state as locationStateType)?.user_id
        if (sellerId && dfaDetails.id && uuid) {
            reduxDispatch(
                createDFAOwnershipTransferRequestAction({
                    seller_id: sellerId,
                    asset_id: Number(dfaDetails.id),
                    amount_dfa: stringToNumber(formData.amount_dfa),
                    buyer_inn: formData.ownership_receiver,
                    buyer_profile_type: formData.client_type,
                    base_document_uuid: formData?.base_document_uuid?.[0]?.uid,
                    base_description: formData.base_description,
                    skid: certificate.skid,
                    base_document_hash: formData?.base_document_uuid?.[0]?.hash,
                }),
            )
        }
        navigate(-1)
    }

    const saveFormData = (formValues: FormDataType) => {
        setFormData(formValues)
        setFooterStatus('precreate')
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Передача прав на ЦФА" onBack={() => navigate(-1)} />
            </Box>
            <Box
                padding={[0, 24, 24, 24]}
                margin={[0, 0, 8, 0]}
                className="tablebox tablebox--auto-height"
            >
                <Row gutter={16} style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <EmissionDecisionCardForOwnershipTransfer dfa={dfaDetails} />
                    </Col>
                    <InfoCardsDfa data={dfaDetails.emitter_info} />
                </Row>
            </Box>
            <DFAOwnershipReceiverForm onOk={saveFormData} />
            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
            />
            <Footer status={footerStatus} sendOferta={() => setIsVisibleCertificateModal(true)} />
        </>
    )
}
