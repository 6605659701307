import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const EditMini: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.37842 4L5.43454 7.94388C5.35716 8.02126 5.293 8.11082 5.24463 8.20898L3.51717 11.7148C3.43273 11.8861 3.61361 12.067 3.78497 11.9826L7.29075 10.2551C7.38892 10.2067 7.47848 10.1426 7.55586 10.0652L11.6211 6M9.37842 4L10.9745 2.40388C11.3651 2.01336 11.9982 2.01336 12.3887 2.40388L13.0959 3.11099C13.4864 3.50152 13.4864 4.13468 13.0959 4.5252L11.6211 6M9.37842 4L11.6211 6"
                    stroke="currentColor"
                />
                <path
                    d="M2 15L14 15"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
