import {
    IEmissionDeal,
    IInvestorDeals,
    IRepaymentDeal,
    ISecondaryDeal,
    ITransferDeal,
} from '@dltru/dfa-models'
import { Box, DEFAULT_PER_PAGE, Filters, PageHeader, Table } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getinvestorsDeals, investorsDealsSlice } from '@store/investorsDeals'
import { getinvestorsDealByUuid } from '@store/investorsDeals/investorDeal'
import { investorsDealsSelector } from '@store/investorsDeals/selectors'

import { emissionModal } from '@components/Modals/Deals/View/Issue'
import { repaymentModal } from '@components/Modals/Deals/View/Repayment'
import { secondaryModal } from '@components/Modals/Deals/View/Secondary'
import { transferModal } from '@components/Modals/Deals/View/Transfer'

import { filters } from './filters'
import { gridConfig } from './gridConfig'

const InvestersDeals: FC = () => {
    const reduxDispatch = useDispatch()
    const { data, paging } = useSelector(investorsDealsSelector.selectInvestorsDeals)
    const isLoading = useSelector(investorsDealsSelector.selectInvestorsDealsIsLoading)
    const investorsDealDetail = useSelector(investorsDealsSelector.selectInvestorsDealDetail)
    const [selectedRow, setselectedRow] = useState<IInvestorDeals>()

    const onPerPageChange = (limit: number) => {
        reduxDispatch(getinvestorsDeals({ limit }))
    }

    const onNext = () => {
        reduxDispatch(getinvestorsDeals({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(getinvestorsDeals({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(getinvestorsDeals({ limit: DEFAULT_PER_PAGE }))
        return () => {
            reduxDispatch(investorsDealsSlice.actions.dropInvestorsDeals())
        }
    }, [])

    useEffect(() => {
        if (!investorsDealDetail.isLoading && investorsDealDetail.data) {
            if (investorsDealDetail.data.type === 'invest') {
                if (investorsDealDetail.data.meta) {
                    const deal = {
                        emitter_id: investorsDealDetail.data.emitter_id,
                        asset_title: investorsDealDetail.data.asset_title,
                        asset_ticker_symbol: investorsDealDetail.data.asset_ticker_symbol,
                        asset_id: investorsDealDetail.data.asset_id,
                        user_full_name: investorsDealDetail.data.from_user_full_name,
                        user_id: investorsDealDetail.data.from_user_uuid,
                        price_per_dfa: investorsDealDetail.data.price_per_dfa,
                        amount_dfa: investorsDealDetail.data.amount_dfa,
                        executed_at: investorsDealDetail.data.executed_at,
                        order: {
                            ...investorsDealDetail.data.meta.order,
                            emitter_id: investorsDealDetail.data.emitter_id,
                            user_name: investorsDealDetail.data.from_user_full_name,
                            user_id: investorsDealDetail.data.from_user_uuid,
                        },
                    } as unknown as IEmissionDeal
                    emissionModal({ deal })
                }
            }
            if (investorsDealDetail.data.type === 'secondary') {
                if (investorsDealDetail.data.meta) {
                    const users = {
                        [investorsDealDetail.data.from_user_uuid]:
                            investorsDealDetail.data.from_user_full_name,
                        [investorsDealDetail.data.to_user_uuid]:
                            investorsDealDetail.data.to_user_full_name,
                    }
                    const buyOrderUserFullName =
                        users[investorsDealDetail.data.meta.buy_order.user_id]
                    const sellOrderUserFullName =
                        users[investorsDealDetail.data.meta.sell_order.user_id]
                    const deal = {
                        emitter_id: investorsDealDetail.data.emitter_id,
                        emitter_full_name: investorsDealDetail.data.emitter_full_name,
                        asset_title: investorsDealDetail.data.asset_title,
                        asset_ticker_symbol: investorsDealDetail.data.asset_ticker_symbol,
                        asset_id: investorsDealDetail.data.asset_id,
                        user_full_name: investorsDealDetail.data.from_user_full_name,
                        user_id: investorsDealDetail.data.from_user_uuid,
                        price_per_dfa: investorsDealDetail.data.price_per_dfa,
                        amount_dfa: investorsDealDetail.data.amount_dfa,
                        executed_at: investorsDealDetail.data.executed_at,
                        buy_order: {
                            ...investorsDealDetail.data.meta.buy_order,
                            user_full_name: buyOrderUserFullName,
                            rest_amount_dfa:
                                investorsDealDetail.data.meta.buy_order.rest_amount_dfa || 0,
                            rest_price: investorsDealDetail.data.meta.buy_order.rest_price || 0,
                        },
                        sale_order: {
                            ...investorsDealDetail.data.meta.sell_order,
                            user_full_name: sellOrderUserFullName,
                            rest_amount_dfa:
                                investorsDealDetail.data.meta.sell_order.rest_amount_dfa || 0,
                            rest_price: investorsDealDetail.data.meta.sell_order.rest_price || 0,
                        },
                    } as unknown as ISecondaryDeal
                    secondaryModal({ deal })
                }
            }
            if (investorsDealDetail.data.type === 'repayment') {
                if (investorsDealDetail.data.meta) {
                    const deal = {
                        ...selectedRow,
                        id: investorsDealDetail.data.meta.deal_id,
                        executed_at: investorsDealDetail.data.executed_at,
                        emitter_id: investorsDealDetail.data.emitter_id,
                        emitter_full_name: investorsDealDetail.data.emitter_full_name,
                        user_id: investorsDealDetail.data.from_user_uuid,
                        user_full_name: investorsDealDetail.data.from_user_full_name,
                        price_per_dfa: investorsDealDetail.data.price_per_dfa,
                        amount_dfa: investorsDealDetail.data.amount_dfa,
                    } as unknown as IRepaymentDeal
                    repaymentModal({ deal })
                }
            }

            if (
                investorsDealDetail.data.type === 'offer' ||
                investorsDealDetail.data.type === 'accept_offer' ||
                investorsDealDetail.data.type === 'transfer'
            ) {
                if (investorsDealDetail.data.meta) {
                    const deal = {
                        id: investorsDealDetail.data.meta.deal_id,
                        emitter_id: investorsDealDetail.data.emitter_id,
                        asset_title: investorsDealDetail.data.asset_title,
                        asset_ticker_symbol: investorsDealDetail.data.asset_ticker_symbol,
                        asset_id: investorsDealDetail.data.asset_id,
                        user_full_name: investorsDealDetail.data.from_user_full_name,
                        user_id: investorsDealDetail.data.from_user_uuid,
                        price_per_dfa: investorsDealDetail.data.price_per_dfa,
                        amount_dfa: investorsDealDetail.data.amount_dfa,
                        executed_at: investorsDealDetail.data.executed_at,
                        order: {
                            ...investorsDealDetail.data.meta.order,
                            emitter_id: investorsDealDetail.data.emitter_id,
                            user_name: investorsDealDetail.data.from_user_full_name,
                            user_id: investorsDealDetail.data.from_user_uuid,
                            buyer_id: investorsDealDetail.data.to_user_uuid,
                            buyer_full_name: investorsDealDetail.data.to_user_full_name,
                            seller_id: investorsDealDetail.data.from_user_uuid,
                            seller_full_name: investorsDealDetail.data.from_user_full_name,
                            seller_asset_data: {
                                asset_ticker_symbol: investorsDealDetail.data.asset_ticker_symbol,
                                asset_id: investorsDealDetail.data.asset_id,
                            },
                        },
                    } as unknown as ITransferDeal
                    transferModal({ deal })
                }
            }
        }
    }, [investorsDealDetail.isLoading, investorsDealDetail.data])

    const onRow = (data: IInvestorDeals) => ({
        onClick: () => {
            setselectedRow(data)
            reduxDispatch(getinvestorsDealByUuid(data.uuid))
        },
    })

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Операции с ЦФА" />
            </Box>
            <Box padding={[0, 24, 24, 24]} className="tablebox">
                <Table
                    isLoading={isLoading}
                    columns={gridConfig}
                    dataSource={data ?? []}
                    cursors={paging?.cursors}
                    onPerPage={onPerPageChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    onRow={onRow}
                    clickableRow
                    rowKey="id"
                    headerLeft={
                        <Filters
                            filters={filters}
                            onApplyFilters={function (filters) {
                                reduxDispatch(
                                    getinvestorsDeals({
                                        filters,
                                        limit: DEFAULT_PER_PAGE,
                                    }),
                                )
                            }}
                        />
                    }
                />
            </Box>
        </>
    )
}

export default InvestersDeals
