import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const CaseHandle: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="15"
                height="6"
                viewBox="0 0 15 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.5 4.5C2.5 3.49995 3.5 1.5 7.5 1.5C11.5 1.5 12.5 3.49995 13.5 4.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </svg>
        )}
        {...props}
    />
)
