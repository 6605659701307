import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { clientsRegistrySlice, getClientsRegistry } from './index'

function* getClientsRegistryHandler({ payload }: ReturnType<typeof getClientsRegistry>) {
    try {
        yield* put(clientsRegistrySlice.actions.setIsLoading(true))

        const { error, data } = yield* call(api.lib.getUsersRegistry, {
            ...payload,
        })

        if (error) {
            throw error
        }

        if (data) {
            // @oldfox костыль
            if (data?.paging?.cursors?.next) {
                const { data: nextData } = yield* call(api.lib.getUsersRegistry, {
                    cursor: data?.paging?.cursors?.next,
                })

                if (!nextData?.items?.length) {
                    data.paging.cursors.next = undefined
                }
            }
            // *
            yield* put(clientsRegistrySlice.actions.setData(data))
        }
    } catch (error) {
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка клиентов',
        })
        yield* put(clientsRegistrySlice.actions.setError(error as string))
        yield* put(
            clientsRegistrySlice.actions.setData({
                items: [],
            }),
        )
    } finally {
        yield* put(clientsRegistrySlice.actions.setIsLoading(false))
    }
}

export function* watchClientsRegistry(): Generator<StrictEffect> {
    yield* takeLatest(getClientsRegistry.type, getClientsRegistryHandler)
}
