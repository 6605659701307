import { ProfileStatus, ProfileType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CloseCircleOutlined } from '@dltru/dfa-ui'
import React, { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'

import { RefuseModal } from '../modals/RefuseModal'

interface IComponentProps {
    profileType: ProfileType
    profileStatus: ProfileStatus
    profileUuid: string
}
export const SendToRefuseAction: FC<IComponentProps> = ({
    profileStatus,
    profileUuid,
    profileType,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { id } = useParams()
    const { isAllow } = useContext(RbacContext)
    const reduxDispatch = useDispatch()

    const isVisibleBtn =
        id && profileStatus === ProfileStatus.PODFT_APPROVAL && isAllow(['approval.aml'])

    if (!isVisibleBtn) {
        return null
    }

    const changeStatus = (comment: string) => {
        reduxDispatch(
            clientsSlice.actions.changeClientStatus({
                profileType,
                profileUuid,
                userUuid: id,
                comment,
                status: ProfileStatus.REJECTED,
            }),
        )
        setIsModalVisible(false)
    }
    return (
        <>
            <Button
                icon={<CloseCircleOutlined />}
                borderRadius={12}
                onClick={() => setIsModalVisible(true)}
            >
                Отказать в согласовании
            </Button>
            <RefuseModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={changeStatus}
            />
        </>
    )
}
