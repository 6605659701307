import { ProfileStatus, ProfileType } from '@dltru/dfa-models'
import { Button, Checkbox, Divider, Form, FormItem, Modal, WrappedInput } from '@dltru/dfa-ui'
import { FC } from 'react'

import style from './style.m.less'

interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    profileStatus: ProfileStatus
    profileType: ProfileType
    action: (comment: string, isCheckLawyer: boolean) => void
}
type FormValues = {
    comment: string
    lawCheck?: boolean
    proxyCheck?: boolean
}
export const ApprovedModal: FC<IComponentProps> = ({
    visible,
    setVisible,
    profileStatus,
    profileType,
    action,
}) => {
    const [form] = Form.useForm<FormValues>()

    const onCancel = () => {
        form.resetFields()
        setVisible(false)
    }

    const onOk = (values: FormValues) => {
        action(values.comment, Boolean(values.lawCheck || values.proxyCheck))
        onCancel()
    }

    return (
        <Modal
            title="Согласование Анкеты"
            visible={visible}
            onCancel={onCancel}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button type="primary" borderRadius={12} onClick={form.submit}>
                    Да, согласовать
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onOk}>
                <span>Вы действительно хотите согласовать Анкету клиента?</span>
                <Divider />
                <h4 className={style.headerBold}>Ваш комментарий</h4>
                <WrappedInput
                    name="comment"
                    multiline
                    placeholder="Ваш комментарий, на что обратить внимание специалистам при следующих проверках"
                    rows={5}
                />
                {profileStatus === ProfileStatus.OPERATOR_APPROVAL && (
                    <>
                        {(profileType === ProfileType.BUSN || profileType === ProfileType.PRSN) && (
                            <>
                                <Divider margin={[8, 0, 24, 0]} />
                                <FormItem name="lawCheck" valuePropName="checked">
                                    <Checkbox>Требуется правовая экспертиза</Checkbox>
                                </FormItem>
                            </>
                        )}
                        {profileType === ProfileType.AGNT && (
                            <FormItem name="proxyCheck" valuePropName="checked">
                                <Checkbox>
                                    Доверенность представителя не соответствует шаблону
                                </Checkbox>
                            </FormItem>
                        )}
                    </>
                )}
            </Form>
        </Modal>
    )
}
