import React from 'react'

export interface IValues {
    [code: string]: string | number | null | unknown
}

export interface IStepper<T = IValues> {
    initialValues: T
    initialStep: number
    onStepperFinish: (value: T) => void
    outOfValuesMerge?: number[]
    children: React.ReactNode
}

const dummy = () => {
    return
}

export interface IStepperContext {
    stepBack: (stepsValues?: IValues) => void
    stepForward: (stepsValues: IValues) => void
    mergeValuesIntoState: (stepsValues: IValues) => void
    turnToFirstStep?: () => void
    turnToFirstStepAndClear?: () => void
    setEdit: (val: number) => void

    editable: number[]
    stepsTotal: number
    currentStep: number
    values: IValues | null
}

export const StepperContext = React.createContext<IStepperContext>({
    stepBack: dummy,
    stepForward: dummy,
    turnToFirstStep: dummy,
    turnToFirstStepAndClear: dummy,
    setEdit: dummy,
    mergeValuesIntoState: dummy,
    editable: [],
    stepsTotal: 0,
    currentStep: 0,
    values: null,
})
