import {
    CertificateType,
    CollectType,
    PriceSource,
    getBasePercent,
    getPennies,
} from '@dltru/dfa-models'
import {
    Amount,
    Box,
    BrifcaseTick,
    Button,
    Checkbox,
    CurrencyInput,
    Divider,
    EMPTY_FIELD_VALUE,
    Form,
    FormItem,
    IColorModalProps,
    LinkToTariff,
    MoneyAccountBlock,
    Price,
    LabelRow as Row,
    UploadFile,
    WrappedInput,
    WrappedSelect,
    getRublesFromPennies,
} from '@dltru/dfa-ui'
import { FC, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { forceRepayment } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import api from '@services/api'

import { UploadDocumentContainerWrapper } from '@components/Form'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

import {
    ActionTypesEnum,
    IRedeemModalState,
    getBalance,
    redeemModalReducer,
    redeemTypeOptions,
} from './helper'
import styles from './style.m.less'

type FormDataType = {
    redeem_flex_price_per_dfa: string
    redeem_spread: string
    is_making_settlements: CollectType
    is_fee: boolean
    force_repayment_document_uuid: UploadFile[]
    force_repayment_base: string
}

export const RepaymentModalBody: FC<Pick<IColorModalProps, 'setIsModalVisible'>> = ({
    setIsModalVisible,
}) => {
    const [form] = Form.useForm<FormDataType>()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isDynamicPrice = dfaDetails.price_source_type === PriceSource.dynamic
    const amount = dfaDetails.total_supply_invested ?? 0
    const reduxDispatch = useDispatch()

    const [state, dispatch] = useReducer(redeemModalReducer, {
        redeemType: CollectType.none,
        isBalanceError: false,
        fee: dfaDetails.fee,
        isFee: false,
        repaymentSum:
            (dfaDetails?.redeem_price_per_dfa ?? 0) *
            (dfaDetails?.total_supply_invested ?? 0) *
            100,
        repaymentWithCommissionSum: 0,
        redeemSpread: dfaDetails?.redemption_spread ?? 0,
        redeemFlexPrice: dfaDetails.price_external || dfaDetails?.redeem_price_per_dfa || 0,
        amount: dfaDetails?.total_supply_invested ?? 0,
        visibleCertificateModal: false,
    })

    const handleCancel = () => {
        form.resetFields()
        setIsModalVisible?.(false)
    }

    const handleOk = () => {
        form.submit()
    }

    const handleFormFinish = () => {
        dispatch({ type: ActionTypesEnum.setVisibleCertificateModal, state: true })
    }

    const certificateModalVisible = () => {
        dispatch({ type: ActionTypesEnum.setVisibleCertificateModal, state: false })
    }

    const handleRepayment = (cert: CertificateType) => {
        const values = form.getFieldsValue()
        reduxDispatch(
            forceRepayment({
                asset_id: dfaDetails.id as number,
                skid: cert.skid,
                repayment_collect_type: values.is_making_settlements,
                force_repayment_base: values.force_repayment_base,
                force_repayment_document_uuid: values.force_repayment_document_uuid[0].uid,
                redeem_price_per_dfa: getPennies(state.redeemFlexPrice),
                repayment_fee: state.fee ?? 0,
                is_making_fee: state.isFee,
                redemption_spread: getBasePercent(state.redeemSpread),
            }),
        )
        handleCancel()
        certificateModalVisible()
    }

    const isNoteShow = state.isFee && state.redeemType === CollectType.bank_account

    useEffect(() => {
        if (dfaDetails.emitter_id) {
            const setBalance = (balance: IRedeemModalState['balance']) => {
                dispatch({ type: ActionTypesEnum.setBalance, balance })
            }
            getBalance(dfaDetails.emitter_id, setBalance)
        }
    }, [dfaDetails.emitter_id])

    useEffect(() => {
        const calcCommission = async () => {
            if (!dfaDetails.id) {
                return
            }

            if (state.isFee) {
                const feeFromApi = await api.lib.calculateRepaymentFeeService({
                    asset_id: dfaDetails.id,
                    redeem_price_per_dfa: getPennies(state.redeemFlexPrice),
                    repayment_collect_type: state.redeemType,
                    redemption_spread: isDynamicPrice
                        ? getBasePercent(state.redeemSpread)
                        : undefined,
                })

                const feeNumber = feeFromApi?.data?.item?.fee_amount ?? 0
                const feeNumberPayment = feeFromApi?.data?.item?.payment_order_fee_amount ?? 0

                dispatch({
                    type: ActionTypesEnum.setFee,
                    fee: feeNumber,
                    feePaymentOrder: feeNumberPayment,
                })
            }
        }

        calcCommission()
    }, [state.isFee, state.redeemFlexPrice, state.redeemType, state.redeemSpread])

    const onValuesChange = (changedValues: FormDataType) => {
        if ('is_making_settlements' in changedValues) {
            dispatch({
                type: ActionTypesEnum.setType,
                redeemType: changedValues.is_making_settlements,
            })
        }
        if ('redeem_flex_price_per_dfa' in changedValues) {
            dispatch({
                type: ActionTypesEnum.setRedeemFlexPrice,
                price: changedValues.redeem_flex_price_per_dfa,
            })
        }
        if ('redeem_spread' in changedValues) {
            dispatch({
                type: ActionTypesEnum.setRedeemSpread,
                redeemSpread: changedValues.redeem_spread,
            })
        }
        if ('is_fee' in changedValues) {
            dispatch({
                type: ActionTypesEnum.setIsFee,
                isFee: changedValues.is_fee,
            })
        }
    }

    return (
        <>
            <h4 className={styles.headerBody}>Детали погашения</h4>
            <Form
                layout="vertical"
                form={form}
                onFinish={handleFormFinish}
                onValuesChange={onValuesChange}
                scrollToFirstError
            >
                <div className={styles.scrollBody}>
                    <WrappedSelect
                        required
                        label="Способ расчетов с Владельцами за погашение ЦФА"
                        name="is_making_settlements"
                        options={redeemTypeOptions}
                        placeholder="Выберите из списка"
                        size="large"
                        initialValue={state.redeemType}
                    />
                    <Box padding={[0, 16]}>
                        <CurrencyInput
                            className={styles.mediumInput}
                            name="redeem_flex_price_per_dfa"
                            label="Цена погашения ЦФА, руб."
                            placeholder="Укажите цену погашения за единицу ЦФА"
                            size="large"
                            required
                            initialValue={
                                dfaDetails.price_external || dfaDetails.redeem_price_per_dfa
                            }
                        />
                        {isDynamicPrice && (
                            <CurrencyInput
                                className={styles.mediumInput}
                                name="redeem_spread"
                                label="Спред к цене погашения, %"
                                placeholder="Укажите спред к стоимости актива на бирже"
                                size="large"
                                required
                                initialValue={dfaDetails.redemption_spread}
                            />
                        )}
                        <FormItem name="is_fee" valuePropName="checked">
                            <Checkbox>Взимать комиссию за погашение согласно тарифам</Checkbox>
                        </FormItem>
                        <Divider margin={[0, -16, 16, -16]} />
                        <Row label="Количество, ед.">
                            <Amount value={amount} />
                        </Row>
                        <Divider margin={[10, 0]} />
                        <Row label="Стоимость погашаемых активов">
                            <Price price={(state.repaymentSum ?? 0) / 100} />
                        </Row>
                        {state.isFee && (
                            <>
                                <Divider margin={[10, 0]} />
                                <Row
                                    label={`Сумма комиссии за погашение ЦФА ${
                                        isNoteShow ? '*' : ''
                                    }`}
                                >
                                    {state.fee !== undefined ? (
                                        <Price price={state.fee / 100} />
                                    ) : (
                                        EMPTY_FIELD_VALUE
                                    )}
                                </Row>
                            </>
                        )}
                        <Divider margin={[10, 0]} />
                        <Row label="Итого к списанию с Лицевого счета Эмитента">
                            <Price price={state.repaymentWithCommissionSum / 100} />
                        </Row>
                        {isNoteShow && (
                            <>
                                <Divider margin={[8, 0]} />
                                <Box
                                    padding={[0, 0, 0, 20]}
                                    direction="row"
                                    className={styles.grayText}
                                >
                                    *&emsp;
                                    <div>
                                        Включая плату за исполнение каждого платежного поручения в
                                        размере {getRublesFromPennies(state.feePaymentOrder)}&nbsp;₽
                                        согласно <LinkToTariff />. <br />
                                        Количество платежных поручений соответствует количеству
                                        владельцев ЦФА по состоянию на момент погашения.
                                    </div>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box padding={[16, 0, 0, 0]}>
                        <MoneyAccountBlock
                            title="Счёт эмитента"
                            balance={state.balance?.balance}
                            accountNumber={state.balance?.number}
                            error={
                                state.isBalanceError ? 'Недостаточно средств на счёте' : undefined
                            }
                        />
                    </Box>
                    <Divider />
                    <h4 className={styles.headerBody}>Причина погашения</h4>
                    <UploadDocumentContainerWrapper
                        name="force_repayment_document_uuid"
                        title="Загрузите фотографии или сканы документов"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                        required
                    >
                        <WrappedInput
                            name="force_repayment_base"
                            placeholder="Ваш комментарий"
                            multiline
                            required
                        />
                    </UploadDocumentContainerWrapper>
                </div>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={[48, 0, 0, 0]}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={handleCancel}>
                        Отмена
                    </Button>
                    <Button
                        borderRadius={12}
                        type="primary"
                        icon={<BrifcaseTick />}
                        onClick={handleOk}
                        disabled={state.isBalanceError}
                    >
                        Погасить выпуск
                    </Button>
                </Box>
            </Form>
            <CertificateSelectionModal
                isModalVisible={state.visibleCertificateModal}
                setIsModalVisible={certificateModalVisible}
                onSubscribe={handleRepayment}
            />
        </>
    )
}
