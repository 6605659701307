import { ITariffPlane, ITariffPlanePost, TariffPlaneEnum } from '@dltru/dfa-models'
import { Box, PageHeader, StepperC } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { tariffSlice } from '@store/tariff'

import { prepareGridFormValue } from '@pages/Tariff/Create/helper'

import { Footer } from './Footer'
import { CommonInfoStep } from './steps/CommonInfoStep'
import { TariffGridStep } from './steps/TariffGridStep'

type FormValuesType = {
    name: string
    type: TariffPlaneEnum
} & Record<string, string>

export const TariffPlaneCreate: FC = () => {
    const location = useLocation()
    const initialValues = location?.state as ITariffPlane
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()
    const [dataForCreate, setDataForCreate] = useState<ITariffPlanePost>()
    const [checkedRates, setCheckedRates] = useState(
        () =>
            initialValues?.active_grid?.rates?.map(({ fee_code }) => fee_code) ?? ([] as string[]),
    )

    useEffect(() => {
        reduxDispatch(tariffSlice.actions.getBaseTariff())
        reduxDispatch(tariffSlice.actions.getFees())
    }, [])

    const onStepperFinish = (values: FormValuesType) => {
        setDataForCreate({
            name: values.name,
            type: values.type,
            active_grid: {
                rates: prepareGridFormValue(checkedRates, values),
            },
        })
    }
    return (
        <>
            <Box padding={16}>
                <PageHeader title="Новый тарифный план" onBack={() => navigate(-1)} />
            </Box>
            <StepperC initialValues={initialValues} onStepperFinish={onStepperFinish}>
                <CommonInfoStep />
                <TariffGridStep
                    baseRates={initialValues?.active_grid?.rates}
                    currentRates={checkedRates}
                    setCurrentRates={setCheckedRates}
                />
            </StepperC>
            <Footer dataForCreate={dataForCreate} />
        </>
    )
}
