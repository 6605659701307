import { getPennies } from '@dltru/dfa-models'
import {
    Button,
    CurrencyInput,
    Divider,
    Form,
    Modal,
    MoneyAccountBlock,
    UploadFile,
    WrappedInput,
    commonValidators,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { transactionCreditSau } from '@store/accountSau'
import { accountSauSelector } from '@store/accountSau/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

import style from './style.m.less'

export const Refill: FC = () => {
    const [form] = Form.useForm()
    const [isModal, setIsModal] = useState(false)
    const [isFilledField, setIsFilledField] = useState(false)
    const [isAttachment, setIsAttachment] = useState(false)
    const summary = useSelector(accountSauSelector.selectSummary)
    const reduxDispatch = useDispatch()

    const onValuesChange = () => {
        const { creditSum, creditReason } = form.getFieldsValue()
        setIsFilledField(creditSum && creditReason)
    }

    const onAttachmentChange = (value?: UploadFile[]) => {
        setIsAttachment(Boolean(value?.length))
    }

    const onCloseModal = () => {
        setIsModal(false)
        form.resetFields()
        setIsFilledField(false)
        setIsAttachment(false)
    }
    const sendCredit = () => {
        const { creditSum, creditReason, creditDocs } = form.getFieldsValue()
        if (summary?.id) {
            reduxDispatch(
                transactionCreditSau({
                    amount: getPennies(creditSum),
                    purpose: creditReason,
                    attachment_uuid: creditDocs[0]?.uid as string,
                    account_id: summary?.id,
                }),
            )
        }
        onCloseModal()
    }

    return (
        <>
            <Button
                type="primary"
                onClick={() => setIsModal(true)}
                style={{ marginRight: 16, borderRadius: 12 }}
            >
                Пополнить
            </Button>
            <Modal
                visible={isModal}
                width={522}
                onCancel={onCloseModal}
                className={style.modal}
                destroyOnClose
            >
                <div className={style.wrapper}>
                    <div className={style.header}>Пополнение счёта</div>
                    <div className={style.body}>
                        <MoneyAccountBlock
                            accountNumber={summary?.number}
                            balance={summary?.balance.available}
                        />
                        <Form
                            form={form}
                            layout="vertical"
                            style={{ marginBottom: 24, marginTop: 24 }}
                            onValuesChange={onValuesChange}
                        >
                            <CurrencyInput
                                label="Сумма пополнения, ₽"
                                name="creditSum"
                                required
                                rules={[{ validator: commonValidators.positive() }]}
                            />
                            <Divider margin={0} />
                            <h4>Основание пополнения</h4>
                            <UploadDocumentContainerWrapper
                                name="creditDocs"
                                title="Загрузите фотографии или сканы документов"
                                onChange={onAttachmentChange}
                                required
                                maxSizeMb={5}
                                accept=".jpg, .jpeg, .tiff, .pdf, .png"
                            >
                                <WrappedInput
                                    className="formComponentItem"
                                    placeholder="Ваш комментарий"
                                    name="creditReason"
                                    multiline
                                    required
                                    maxLength={200}
                                />
                            </UploadDocumentContainerWrapper>
                        </Form>
                    </div>
                    <div className={style.footer}>
                        <Button
                            style={{ marginRight: 16, borderRadius: 12 }}
                            onClick={onCloseModal}
                        >
                            Отмена
                        </Button>

                        <Button
                            borderRadius={16}
                            type="primary"
                            disabled={!(isFilledField && isAttachment)}
                            onClick={sendCredit}
                        >
                            Пополнить
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Refill
