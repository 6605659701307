import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ShieldSecurity: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.7418 1.8583L4.58346 3.42497C3.62513 3.7833 2.8418 4.91663 2.8418 5.9333V12.125C2.8418 13.1083 3.4918 14.4 4.28346 14.9916L7.8668 17.6666C9.0418 18.55 10.9751 18.55 12.1501 17.6666L15.7335 14.9916C16.5251 14.4 17.1751 13.1083 17.1751 12.125V5.9333C17.1751 4.9083 16.3918 3.77497 15.4335 3.41663L11.2751 1.8583C10.5668 1.59997 9.43346 1.59997 8.7418 1.8583Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0002 10.4167C10.9206 10.4167 11.6668 9.67052 11.6668 8.75004C11.6668 7.82957 10.9206 7.08337 10.0002 7.08337C9.07969 7.08337 8.3335 7.82957 8.3335 8.75004C8.3335 9.67052 9.07969 10.4167 10.0002 10.4167Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 10.4166V12.9166"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
