import { Box, Button, Card, CardContent, CardHeader, Table } from '@dltru/dfa-ui'
import { getPrivateProfile } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AddRiskModal } from './AddRiskModal'
import { gridConfig } from './gridConfig'

export const CheckRisk: FC = () => {
    const dispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const userUid = useSelector(clientsSelector.selectCurrentClientId)
    const profileType = useSelector(clientsSelector.selectProfilePath)
    const data = useSelector(clientsSelector.selectPrivateRisks) // [] as RiskClientType[]

    useEffect(() => {
        if (userUid && profileType) {
            dispatch(getPrivateProfile({ user_uuid: userUid, type: profileType }))
        }
    }, [])

    return (
        <>
            <Card className="boxContent">
                <CardHeader title="Проверки уровня риска" />
                <CardContent>
                    {data.length > 0 && (
                        <Box margin={[0, 0, 16, 0]}>
                            <Table columns={gridConfig} dataSource={data} bordered />
                        </Box>
                    )}
                    <div>
                        <Button borderRadius={16} onClick={() => setIsModalVisible(true)}>
                            Добавить проверку уровня риска
                        </Button>
                    </div>
                </CardContent>
            </Card>
            <AddRiskModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}
