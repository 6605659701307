export const disableFields = [
    'phone_numbers',
    'emails',
    'ogrn_file_uuid',
    'ogrn',
    'inn',
    'inn_file_uuid',
    'ogrn_date',
    'registration_authority',
    'planned_operations',
]
export const clearFields = disableFields.reduce((acc, fieldName) => {
    acc[fieldName] = undefined
    return acc
}, {} as Record<string, undefined>)
