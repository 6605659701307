import { Box, Checkbox, CheckboxChangeEvent, FormInstance, FormItemProps } from '@dltru/dfa-ui'
import { Children, FC, cloneElement, isValidElement, useEffect, useState } from 'react'

interface IDisableWrapper extends FormItemProps {
    checkboxLabel: string
    form?: FormInstance
    defaultDisabled?: boolean
    onDisable: (state: boolean) => void
}

export const DisableWrapper: FC<IDisableWrapper> = ({
    checkboxLabel,
    required,
    uneditable,
    form,
    name,
    defaultDisabled,
    onDisable,
    children,
    ...props
}) => {
    const [isInputDisabled, setIsInputDisabled] = useState<boolean>(defaultDisabled ?? false)
    const onChangeCheckbox = (event: CheckboxChangeEvent) => {
        const isDisabled = event.target.checked
        setIsInputDisabled(isDisabled)
        if (isDisabled) {
            form?.setFieldsValue({ [name as string]: undefined })
        }
    }

    useEffect(() => {
        onDisable(isInputDisabled)
    }, [isInputDisabled])

    const childrenWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                ...props,
                name: name,
                disabled: isInputDisabled,
                required: isInputDisabled ? false : required,
                uneditable: uneditable,
                rules: [],
            })
        }
        return child
    })

    return (
        <>
            {!(uneditable && isInputDisabled) && childrenWithProps}
            {!uneditable && (
                <Box
                    height="45px"
                    margin={[0, 0, 0, 16]}
                    direction="row"
                    padding={[0, 0, 24, 0]}
                    justify="center"
                >
                    <Checkbox
                        className="proper-checkbox-style"
                        defaultChecked={isInputDisabled}
                        onChange={onChangeCheckbox}
                    >
                        {checkboxLabel}
                    </Checkbox>
                </Box>
            )}
        </>
    )
}
