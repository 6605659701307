// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badgeBuying__66_0,\n.badgeSelling_TxNSm {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 16px 0;\n}\n.badgeBuying__66_0 p,\n.badgeSelling_TxNSm p {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  text-transform: uppercase;\n  margin: 0;\n}\n.badgeBuying__66_0 {\n  box-shadow: inset 0px -5px 0px #73d13d;\n}\n.badgeBuying__66_0 p {\n  color: #73d13d;\n}\n.badgeSelling_TxNSm {\n  box-shadow: inset 0px -5px 0px #fa541c;\n}\n.badgeSelling_TxNSm p {\n  color: #fa541c;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CardModal/Details/HeaderBadge/style.m.less"],"names":[],"mappings":"AAGA;;EAEI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAHA;;EAQQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,SAAA;AADR;AAKA;EACI,sCAAA;AAHJ;AAEA;EAIQ,cAAA;AAHR;AAOA;EACI,sCAAA;AALJ;AAIA;EAIQ,cAAA;AALR","sourcesContent":["@import '/src/assets/style/palette';\n\n// HEADER BADGE\n.badgeBuying,\n.badgeSelling {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 16px 0;\n\n    p {\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        text-transform: uppercase;\n        margin: 0;\n    }\n}\n\n.badgeBuying {\n    box-shadow: inset 0px -5px 0px @green-5;\n\n    p {\n        color: @green-5;\n    }\n}\n\n.badgeSelling {\n    box-shadow: inset 0px -5px 0px @volcano-6;\n\n    p {\n        color: @volcano-6;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeBuying": "badgeBuying__66_0",
	"badgeSelling": "badgeSelling_TxNSm"
};
export default ___CSS_LOADER_EXPORT___;
