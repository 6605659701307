import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ArrowLeftPure: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.26069 7.43049L8.62989 11.8177C8.8673 12.0561 9.2533 12.056 9.49055 11.8175C9.72622 11.5805 9.72608 11.1977 9.49024 10.9609L5.54515 7L9.49024 3.03912C9.72608 2.80234 9.72622 2.41949 9.49055 2.18254C9.2533 1.94399 8.8673 1.94386 8.62989 2.18225L4.26069 6.56951C4.02422 6.80726 4.02422 7.19274 4.26069 7.43049Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
