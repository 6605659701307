// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableFooterContainer_HCn2T {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.tableAction_EDjMX:first-child {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Tariff/View/components/RelatedAssets/components/RelatedAssetsTable/styles.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AADJ;AAKI;EACI,kBAAA;AAHR","sourcesContent":["@import 'style/palette';\n\n.tableFooterContainer {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.tableAction {\n    &:first-child {\n        margin-right: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableFooterContainer": "tableFooterContainer_HCn2T",
	"tableAction": "tableAction_EDjMX"
};
export default ___CSS_LOADER_EXPORT___;
