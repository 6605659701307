import { CHECK_TOKEN_TIMEOUT } from '@constants/common'
import { frontUrl } from '@constants/frontUrl'
import { CheckAndRefresh } from '@dltru/api-request'
import { RbacContextProvider, isAllowHelper } from '@dltru/dfa-rbac'
import { getToken, parseToken } from '@dltru/dfa-ui'
import { getRoleFromScope, isAuthenticated } from '@utils/auth'
import { FC, useEffect, useState } from 'react'
import ModalContainer from 'react-modal-promise'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getUserInfoAction, logout, updateAuth } from '@store/auth'
import { authSelector } from '@store/auth/selectors'
import { notificationCounterSlice } from '@store/notificationCounter'

import { tokensRefresh } from '@services/agent'

import { ChangePhonePage } from '@pages/ChangePhone'
import { ClientRoutes } from '@pages/ClientCard'
import ClientList from '@pages/ClientList'
import { CreateDFAOwnershipTransferRequestPage } from '@pages/CreateDFAOwnershipTransferRequestPage'
import EmissionCardPage from '@pages/DFAEmissionCardPage'
import { DFAEncumbranceCreate, DFAEncumbranceView } from '@pages/DFAEncumbrance'
import { EmployeeEdit, EmployeeList, EmployeeView } from '@pages/Employee'
import { ExchangeOperators } from '@pages/ExchangeOperators'
import InvestersDeals from '@pages/InvestersDeals/InvestersDeals'
import Login from '@pages/Login'
import { NominalAccountCreate, NominalAccountView } from '@pages/NominalAccount'
import { Offers } from '@pages/Offers/Offers'
import { DFAOwnershipTransferRequestPage } from '@pages/OwnershipTransfer'
import { PaymentOrderDetails, PaymentOrderList } from '@pages/PaymentOrders'
import { PersonalAccounts } from '@pages/PersonalAccounts'
import { Profile } from '@pages/Profile'
import { QualificationStatement } from '@pages/QualificationStatement'
import { QualificationStatementsList } from '@pages/QualificationStatementsList'
import RegistrationRequestList from '@pages/RegistrationRequests'
import { Emissions } from '@pages/Registry/Emissions'
import Registry from '@pages/Registry/Registry'
import { RestorePasswordPage } from '@pages/RestorePasswordPage'
import { SystemManagement } from '@pages/SystemManagement'
import { TariffPlaneCreate, TariffPlaneView } from '@pages/Tariff'

import { Layout, UnauthorizedLayout } from '@components/Layout'

const App: FC = () => {
    const reduxDispatch = useDispatch()
    const isAuth = useSelector(authSelector.selectIsAuth)
    const isHasToken = isAuthenticated()
    const [scopes, setScopes] = useState('')

    useEffect(() => {
        const storageEventHandler = (event: StorageEvent) => {
            if (event.key === 'accessToken' && event.newValue === null) {
                reduxDispatch(logout())
                // eslint-disable-next-line no-restricted-globals
                location.reload()
            }
        }
        window.addEventListener('storage', storageEventHandler)
        return () => {
            window.removeEventListener('storage', storageEventHandler)
        }
    }, [])

    useEffect(() => {
        let checkAndRefresh: CheckAndRefresh | null = null

        if (!isHasToken) {
            return
        }

        if (isHasToken && !isAuth) {
            reduxDispatch(updateAuth({ isAuth: true }))
        }

        const token = getToken()
        let scope = ''
        let uuid = ''
        if (token && isAuth) {
            const parsed = parseToken(token)
            scope = parsed && parsed[1].scope
            uuid = parsed && parsed[1].uuid

            if (scope && uuid) {
                const roleFromScope = getRoleFromScope(scope)

                reduxDispatch(updateAuth({ role: roleFromScope, uuid }))
                reduxDispatch(getUserInfoAction())
                reduxDispatch(notificationCounterSlice.actions.getAllCount())
            }

            checkAndRefresh = new CheckAndRefresh({
                token,
                timeToCheck: CHECK_TOKEN_TIMEOUT,
                reloadToken: tokensRefresh,
            })

            checkAndRefresh.start()
        }

        setScopes(scope)

        return () => {
            checkAndRefresh && checkAndRefresh.stop()
        }
    }, [isAuth, isHasToken])

    return (
        <RbacContextProvider scopes={scopes}>
            <div className="App">
                <Routes>
                    <Route path="/" element={<UnauthorizedLayout />}>
                        <Route
                            path={`${frontUrl.restorePassword}/:token`}
                            element={<RestorePasswordPage />}
                        />
                        <Route
                            path={`${frontUrl.restorePasswordAdmin}/:token`}
                            element={<RestorePasswordPage />}
                        />
                        <Route
                            path={`${frontUrl.changePhone}/:token`}
                            element={<ChangePhonePage />}
                        />
                        <Route index element={<Navigate to="/login" replace />} />
                        <Route path="login" element={<Login />} />
                    </Route>
                    <Route path="/" element={<Layout />}>
                        <Route path="profile" element={<Profile />} />
                        {isAllowHelper(scopes, ['offer.read.all', 'asset.read.all']) && (
                            <Route path="offers" element={<Offers />} />
                        )}

                        {isAllowHelper(scopes, ['asset.read.all']) && (
                            <Route path="registry" element={<Registry />} />
                        )}

                        <Route path="offers/:dfa">
                            <Route index element={<EmissionCardPage />} />
                            {isAllowHelper(scopes, ['transfer']) && (
                                <Route
                                    path="ownership-transfer-request"
                                    element={<CreateDFAOwnershipTransferRequestPage />}
                                />
                            )}
                            {isAllowHelper(scopes, [
                                'encumbrance.write.all',
                                'encumbrance:status',
                            ]) && <Route path="encumbrance" element={<DFAEncumbranceCreate />} />}
                        </Route>

                        {isAllowHelper(scopes, ['encumbrance.read.all']) && (
                            <Route path="encumbrance/:id" element={<DFAEncumbranceView />} />
                        )}

                        {isAllowHelper(scopes, ['offer.read.all']) && (
                            <Route
                                path="ownership-transfer-request/:request"
                                element={<DFAOwnershipTransferRequestPage />}
                            />
                        )}

                        {isAllowHelper(scopes, [
                            'approval.operator',
                            'approval.lawyer',
                            'approval.aml',
                        ]) && <Route path="emissions" element={<Emissions />} />}

                        {isAllowHelper(scopes, ['user.read.all']) && (
                            <Route path="clients">
                                <Route path=":id/*" element={<ClientRoutes />} />
                                <Route index element={<ClientList />} />
                            </Route>
                        )}

                        {isAllowHelper(scopes, ['account.read.all']) && (
                            <Route path="personal-accounts" element={<PersonalAccounts />} />
                        )}

                        {isAllowHelper(scopes, ['employee.read.all']) && (
                            <Route path="employees">
                                {isAllowHelper(scopes, ['employee.write.all']) && (
                                    <Route path="create" element={<EmployeeEdit />} />
                                )}
                                {isAllowHelper(scopes, ['employee.write.all']) && (
                                    <Route path=":id/edit" element={<EmployeeEdit />} />
                                )}
                                <Route path=":id" element={<EmployeeView />} />
                                <Route index element={<EmployeeList />} />
                            </Route>
                        )}

                        <Route path="nominal-account">
                            {isAllowHelper(scopes, ['nominal_account.write.all']) && (
                                <Route path="create" element={<NominalAccountCreate />} />
                            )}
                            <Route path=":id" element={<NominalAccountView />} />
                        </Route>

                        <Route path="registration-requests" element={<RegistrationRequestList />} />

                        {isAllowHelper(scopes, ['order.read.all', 'payment.read.all']) && (
                            <Route path="payment-orders">
                                <Route path=":id" element={<PaymentOrderDetails />} />
                                <Route index element={<PaymentOrderList />} />
                            </Route>
                        )}

                        <Route path="exchange-operators" element={<ExchangeOperators />} />
                        <Route path="investors-deals" element={<InvestersDeals />} />
                        <Route
                            path="qualification-statements"
                            element={<QualificationStatementsList />}
                        />
                        {
                            //isAllowHelper(scopes, ['qualified_investors_application.read.all']) && (
                            <Route
                                path="qualification-statement/:id"
                                element={<QualificationStatement />}
                            />
                        }
                        <Route path="system-management" element={<SystemManagement />} />
                        {isAllowHelper(scopes, ['tariff.write.all']) && (
                            <Route path="tariff/create" element={<TariffPlaneCreate />} />
                        )}
                        {isAllowHelper(scopes, ['tariff.write.all', 'tariff.read.all']) && (
                            <Route path="tariff/:id" element={<TariffPlaneView />} />
                        )}
                    </Route>
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: '1rem' }}>
                                <p>404</p>
                            </main>
                        }
                    />
                </Routes>

                <ModalContainer />
            </div>
        </RbacContextProvider>
    )
}

export default App
