import { XOR } from '../../utils'
import { CollectType } from '../dfa/collectTypes'
import { BaseDescriptionEnum } from '../transfer'
import { ISecondaryOrder } from './secondary'
import { OrderTypeEnum, UnionOrderStatus } from './statuses'
import { ITransferOrder } from './transfer'

export type UnionOrderType = {
    created_at: number
    id: number
    money_lock_id: string
    uuid: string
    status: UnionOrderStatus | ''
    order_type: OrderTypeEnum | ''
} & XOR<
    {
        emitter_id: string
        price_per_dfa: number
        total_price: number
        asset_id: number
        asset_ticker_symbol: string
        asset_title: string
        amount_dfa: number
        user_name?: string
        user_id: string
        original_amount_dfa?: number
        repurchase_percent: number
        emitter_full_name: string
        investor_yearly_limit_lock_id?: string
        user_full_name?: string
        canceled_at?: number
        deal_fee_lock_id?: string
        executed_at?: number
    },
    XOR<
        {
            emitter_id: string
            price_per_dfa: number
            total_price: number
            asset_id: number
            asset_ticker_symbol: string
            asset_title: string
            original_amount_dfa: number
            expired_at: number
            rest_amount_dfa: number
            rest_price: number
            canceled_at: number
            deal_fee_lock_id: string
            user_id: string
            user_full_name?: string
            repurchase_percent?: number
            executed_at: number
            emitter_full_name?: string
            investor_yearly_limit_lock_id: string
            cancel_reason?: string
            payment_collect_type?: CollectType
            accepted_at: string
            sell_fee_amount?: number
            sell_fee_reserve_uuid?: string
            sell_fee_tariff_uuid?: string
            buy_fee_amount?: number
            buy_fee_reserve_uuid?: string
            buy_fee_tariff_uuid?: string
            fee_payment_amount_order?: number
            fee_payment_order_tariff_uuid?: string
        },
        {
            seller_id: string
            seller_full_name: string
            buyer_id: string
            buyer_full_name: string
            canceled_at: number
            expired_at: number
            cancel_reason: string
            base_description: BaseDescriptionEnum
            base_document_uuid: string
            executed_at: number
            initiator_id: string
            investor_yearly_limit_lock_id: string
            cancel_initiator: ITransferOrder['cancel_initiator']
            create_initiator: ITransferOrder['create_initiator']
            buyer_asset_data: ITransferOrder['buyer_asset_data']
            is_public: boolean
            is_reply: boolean
            seller_asset_data: ITransferOrder['seller_asset_data']
            global_id: string
        }
    >
>

export const isSecondaryOrder = (order: UnionOrderType): order is ISecondaryOrder => {
    return (
        order.order_type === 'buy' ||
        order.order_type === 'sell' ||
        order.order_type === 'secondary'
    )
}

export const getOrderTypeTranslate = (type: string, record: UnionOrderType) => {
    switch (type) {
        case 'buy':
            return 'Покупка'
        case 'sell':
            return 'Продажа'
        case 'transfer':
            return record?.create_initiator === 'operator' ? 'Переход прав' : 'Оферта'
        default:
            return 'Приобретение'
    }
}
