import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    Divider,
    Form,
    MiniModal,
    ModalProps,
    QuestionCircle,
    Space,
    UploadFile,
    WrappedInput,
} from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { walletEncumbranceSlice } from '@store/wallet/encumbrance'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'
import { CertificateSelectionModal } from '@components/Modals/Certificate'

import styles from './ModalEncumbranceRemove.m.less'

type FormDataType = {
    removal_attachment_uuid: UploadFile[]
    removal_reason: string
}

export const ModalEncumbranceRemove: FC<Pick<ModalProps, 'visible' | 'onCancel'>> = ({
    visible,
    onCancel,
}) => {
    const [form] = Form.useForm<FormDataType>()
    const reduxDispatch = useDispatch()
    const encumbrance = useSelector(encumbranceSelectors.selectItem)
    const [documents, setDocuments] = useState([])
    const [isFilled, setIsFilledField] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const onSubscribe = (cert: CertificateType) => {
        const values = form.getFieldsValue()
        if (encumbrance) {
            reduxDispatch(
                walletEncumbranceSlice.actions.removeEncumbrance({
                    ...values,
                    removal_attachment_uuid: values.removal_attachment_uuid?.map(({ uid }) => uid),
                    encumbrance_uuid: encumbrance.uuid,
                    skid: cert.skid,
                }),
            )
        }
        closeModal()
    }
    const closeModal = () => {
        onCancel()
        setIsFilledField(false)
        setDocuments([])
        form.setFieldsValue({ removal_reason: '', removal_attachment_uuid: undefined })
    }

    const isBtnDisabled = !documents?.length || !isFilled

    const onValuesChange = (changedValues: FormDataType) => {
        setIsFilledField(Boolean(changedValues.removal_reason))
    }

    return (
        <MiniModal
            width={600}
            visible={visible}
            onCancel={closeModal}
            destroyOnClose
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Снять обременение на ЦФА
                </Space>
            }
        >
            <Box margin={[0, 40]}>
                <div>
                    Вы действительно хотите снять обременение с ЦФА, наложенного в пользу указанного
                    лица?
                </div>
                <Divider />
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={() => setIsVisibleCertificateModal(true)}
                    onValuesChange={onValuesChange}
                >
                    <h4 className={styles.boldHeader}>Основание для снятия обременения</h4>
                    <UploadDocumentContainerWrapper
                        name="removal_attachment_uuid"
                        onChange={setDocuments}
                        required
                        title="Загрузите фотографии документов-оснований. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Кратко опишите основание для снятия обременения"
                            name="removal_reason"
                            multiline
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
            </Box>
            <div className={styles.footerWrapper}>
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>
                <Button
                    type="primary"
                    borderRadius={12}
                    onClick={() => form.submit()}
                    disabled={isBtnDisabled}
                >
                    Да, снять обременение
                </Button>
            </div>
            <CertificateSelectionModal
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
            />
        </MiniModal>
    )
}
