import { TransactionStatuses, TransactionTypes } from '@dltru/dfa-models'

import { StatusDetails } from './commonState'

export const transactionStatus = {
    [TransactionStatuses.pending]: { color: 'blue', title: 'Новое' },
    [TransactionStatuses.payment_awaiting]: { color: 'default', title: 'В обработке' },
    [TransactionStatuses.resolving]: { color: 'default', title: 'Разрешение  проблем' },
    [TransactionStatuses.confirmed]: { color: 'success', title: 'Исполнено' },
    [TransactionStatuses.canceled]: { color: 'error', title: 'Не исполнено' },
} as StatusDetails<TransactionStatuses>

export const transactionTypes = {
    [TransactionTypes.credit]: 'Зачисление ДС',
    [TransactionTypes.debit]: 'Списание ДС ',
    [TransactionTypes.transfer]: 'Перевод САУ-САУ',
    [TransactionTypes.payment]: 'Транзакция-платеж',
    [TransactionTypes.any_payment]: 'Транзакция-платеж',
}

export const getTagProps = (tag: TransactionStatuses) =>
    transactionStatus[tag] ?? { color: 'default', title: tag }

export const transactionStatusesOptions = Object.entries(transactionStatus).map(([key, value]) => ({
    value: key,
    label: value.title,
}))
