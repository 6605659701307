import { Button, Divider, Form, Modal, Space, Typography, WrappedInput } from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'

import style from './style.m.less'

interface IComponentProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    action: (comment: string) => void
}
type FormDataType = {
    comment: string
}
export const RefuseModal: FC<IComponentProps> = ({ visible, setVisible, action }) => {
    const [comment, setComment] = useState('')
    const [form] = Form.useForm<FormDataType>()

    const onCancel = () => {
        setVisible(false)
        form.resetFields()
        setComment('')
    }
    const onValuesChange = (changedValues: Partial<FormDataType>) => {
        if ('comment' in changedValues) {
            setComment(changedValues.comment ?? '')
        }
    }
    const onOk = (values: FormDataType) => {
        action(values.comment)
    }

    return (
        <Modal
            title="Отказ в согласовании анкеты клиента"
            visible={visible}
            width={522}
            onCancel={onCancel}
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Отмена
                </Button>,
                <Button type="danger" borderRadius={12} disabled={!comment} onClick={form.submit}>
                    Да, отказать
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onOk} onValuesChange={onValuesChange}>
                <Space direction="vertical" className={style.modalBody}>
                    <Typography.Text>
                        Вы действительно хотите отказать в согласовании Анкеты клиента?
                    </Typography.Text>
                    <Divider />
                    <h4 className={style.headerBold}>Причина отказа</h4>
                    <WrappedInput name="comment" placeholder="Ваш комментарий" multiline required />
                </Space>
            </Form>
        </Modal>
    )
}
