// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_zAx1o .headerBold__O7t1 {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.modal_zAx1o .grayBlock_euPef {\n  padding: 8px 16px;\n  background-color: #f8f8fa;\n  border: 1px solid #ebecf0;\n  border-radius: 12px;\n}\n.modal_zAx1o .grayBlock_euPef .anticon {\n  margin-right: 6px;\n}\n.modal_zAx1o .caption__hJBB {\n  display: block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: #868991;\n}\n.modal_zAx1o .footer_SDuot {\n  padding: 16px 0;\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ExchangeOperators/components/ChangeStatusModalContainer/changeStatusModalContainer.m.less"],"names":[],"mappings":"AAEA;EAEQ,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFR;AAJA;EASQ,iBAAA;EACA,yBAAA;EACA,yBAAA;EACA,mBAAA;AAFR;AAVA;EAegB,iBAAA;AAFhB;AAbA;EAoBQ,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJR;AApBA;EA4BQ,eAAA;EACA,aAAA;EACA,yBAAA;EACA,QAAA;AALR","sourcesContent":["@import 'style/palette';\n\n.modal {\n    .headerBold {\n        font-style: normal;\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        color: @gray-10;\n    }\n    .grayBlock {\n        padding: 8px 16px;\n        background-color: @gray-2;\n        border: 1px solid @gray-4;\n        border-radius: 12px;\n        :global {\n            .anticon {\n                margin-right: 6px;\n            }\n        }\n    }\n    .caption {\n        display: block;\n        font-weight: 400;\n        font-size: 12px;\n        line-height: 20px;\n        color: @gray-7;\n    }\n\n    .footer {\n        padding: 16px 0;\n        display: flex;\n        justify-content: flex-end;\n        gap: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_zAx1o",
	"headerBold": "headerBold__O7t1",
	"grayBlock": "grayBlock_euPef",
	"caption": "caption__hJBB",
	"footer": "footer_SDuot"
};
export default ___CSS_LOADER_EXPORT___;
