import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const StatusPositive: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5045 5.62941C28.0774 6.0921 28.1667 6.93158 27.704 7.50444L13.704 24.8378C13.4489 25.1536 13.0638 25.336 12.6578 25.3333C12.2518 25.3306 11.8691 25.143 11.6183 24.8238L4.28497 15.4904C3.83001 14.9114 3.9306 14.0732 4.50963 13.6182C5.08866 13.1633 5.92686 13.2639 6.38181 13.8429L12.6811 21.8602L25.6295 5.82888C26.0922 5.25602 26.9316 5.16671 27.5045 5.62941Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
