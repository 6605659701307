import { ICheckList } from '../CheckList'

const CHECK_STATUSES: ICheckList['elements'] = [
    {
        label: 'Одна цифра',
        rule: { message: 'В пароле необходима хотя бы одна цифра', pattern: /[0-9]+/ },
        checked: false,
    },
    {
        label: 'Один спец. символ',
        rule: {
            message: 'В пароле необходим хотя бы один спец. символ',
            pattern: /(?=.*[!@#$%^&*])/,
        },
        checked: false,
    },
    {
        label: 'Одна строчная буква',
        rule: {
            message: 'В пароле необходима хотя бы одна строчная буква',
            pattern: /[a-z,а-я]+/,
        },
        checked: false,
    },
    {
        label: 'Одна прописная буква',
        rule: {
            message: 'В пароле необходима хотя бы одна прописная буква',
            pattern: /[A-Z,А-Я]+/,
        },
        checked: false,
    },
    {
        label: 'Минимум 10 символов',
        rule: {
            min: 10,
            message: 'В пароле необходима хотя бы 10 символов',
            whitespace: false,
        },
        checked: false,
    },
]

export default CHECK_STATUSES
