import { RiskLevelProfile } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, Tag } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

export const gridConfig: ColumnsType<RiskLevelProfile> = [
    {
        title: 'Дата и время проверки',
        dataIndex: 'verification_datetime',
        key: 'verification_datetime',
        sorter: true,
        ellipsis: true,
        render: (value) => {
            return DateTimeCell(dayjs(value).unix())
        },
    },
    {
        title: 'Проверяющий',
        dataIndex: 'employee_uuid',
        key: 'employee_uuid',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Уровень риска',
        dataIndex: 'risk_level',
        key: 'risk_level',
        sorter: true,
        ellipsis: true,
        render: (value: string) => (
            <Tag color={value === 'Низкий' ? 'error' : 'success'}>{value}</Tag>
        ),
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        sorter: true,
        ellipsis: true,
    },
]
