import { CitizenshipType } from '@dltru/dfa-models'
import {
    AddressForm,
    FioForm,
    Form,
    IRoleFormStepProps,
    PersonalForm,
    SnilsInput,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'

import { InnInput, UploadInputForm } from '@components/Form'
import { DocumentForm } from '@components/Form/components/DocumentForm'

import { MigrationCardAndPermitBlock } from '../../components/MigrationCardAndPermitBlock'

const STEP_INDEX = 0

export const BasicInformationStep: FC<IRoleFormStepProps> = ({
    isEditAllow,
    disableFields = [],
}) => {
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const [form] = Form.useForm()
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    const [migrationBlockView, setMigrationBlockView] = useState(
        values?.citizenship && values.citizenship !== CitizenshipType.RU,
    )

    const onChangeValues = (changedValues: Partial<typeof values>) => {
        if (changedValues && 'citizenship' in changedValues) {
            setMigrationBlockView(
                changedValues.citizenship && changedValues.citizenship !== CitizenshipType.RU,
            )
        }
    }

    return (
        <Step
            form={form}
            onChangeValues={onChangeValues}
            stepIndex={STEP_INDEX}
            stepTitle="Основные сведения"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FioForm uneditable={isEdit} />
            <PersonalForm uneditable={isEdit} disableFields={disableFields} />
            <div className="formRow">
                <AddressForm
                    uneditable={isEdit}
                    addressTitle="Место жительства (адрес регистрации по паспорту)"
                    group="registration_address"
                />
            </div>
            <DocumentForm uneditable={isEdit} />
            <div className="formRow">
                <InnInput uneditable={isEdit} disabled={disableFields.includes('inn')} />
            </div>
            <div className="formRow">
                <SnilsInput uneditable={isEdit} disabled={disableFields.includes('snils')} />
            </div>
            <div className="formRow formRow--without-label">
                <UploadInputForm
                    name="snils_file_uuid"
                    uneditable={isEdit}
                    disabled={disableFields.includes('snils_file_uuid')}
                    title="Загрузите фотографии документа. Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
            {migrationBlockView && (
                <MigrationCardAndPermitBlock
                    uneditable={isEdit}
                    initialHasMigrationCard={values?.HasMigrationCard as boolean}
                    initialHasMigrationPermit={values?.HasMigrationPermit as boolean}
                />
            )}
        </Step>
    )
}
