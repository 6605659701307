import { ProfileStatus, ProfileType } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, RedoIcon } from '@dltru/dfa-ui'
import { checkIsLawyerOfHistory } from '@utils/profile'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clientsSlice } from '@store/clients'
import { clientsSelector } from '@store/clients/selectors'

import { ToLawyerModal } from '../modals/ToLawyerModal'

interface IComponentProps {
    profileType: ProfileType
    profileStatus: ProfileStatus
    profileUuid: string
}
export const SendToLawyerAction: FC<IComponentProps> = ({
    profileStatus,
    profileUuid,
    profileType,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const approvalHistory = useSelector(clientsSelector.selectCurrentClientApprovalHistory)
    const approvalHistoryAgent = useSelector(
        clientsSelector.selectCurrentClientApprovalHistoryAgent,
    )

    useEffect(() => {
        if (!approvalHistoryAgent?.length) {
            reduxDispatch(clientsSlice.actions.getApprovalHistoryRepresentative())
        }
    }, [])
    const { id } = useParams()
    const { isAllow } = useContext(RbacContext)

    const reduxDispatch = useDispatch()

    const isVisibleBtn =
        id &&
        profileStatus === ProfileStatus.PODFT_APPROVAL &&
        isAllow(['approval.aml']) &&
        !checkIsLawyerOfHistory(
            profileType === ProfileType.AGNT ? approvalHistoryAgent : approvalHistory,
        )

    if (!isVisibleBtn) {
        return null
    }
    const changeStatus = () => {
        reduxDispatch(
            clientsSlice.actions.changeClientStatus({
                profileType,
                profileUuid,
                userUuid: id,
                status: ProfileStatus.LAWYER_APPROVAL,
            }),
        )
        setIsModalVisible(false)
    }

    return (
        <>
            <Button icon={<RedoIcon />} borderRadius={12} onClick={() => setIsModalVisible(true)}>
                Отправить на правовую экспертизу
            </Button>
            <ToLawyerModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={changeStatus}
            />
        </>
    )
}
