import { IEmployeeListState } from './types'

export const initialState: IEmployeeListState = {
    isLoading: false,
    filters: {},
    sort: {},
    range: {},
    data: [],
    selectedToDelete: null,
}
