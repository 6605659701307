import { CertificatesListComponent, ErrorCodes, errorTranslates, openMessage } from '@dltru/dfa-ui'
import React, { FC, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUsersCertificates, removeUsersCertificate } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { UploadCertificate } from './UploadCertificate/UploadCertificate'

export const CertificatesList: FC = () => {
    const profileUid = useSelector(authSelector.selectUserUid)
    const certificates = useSelector(authSelector.selectUserCertificates)
    const isLoaded = useSelector(authSelector.selectUserCertificatesLoaded)
    const reduxDispatch = useDispatch()
    useLayoutEffect(() => {
        if (!certificates.length) {
            reduxDispatch(getUsersCertificates())
        }
    }, [])

    const addCertificateHandler = () => {
        reduxDispatch(getUsersCertificates())
    }

    const addCertificateErrorHandler = (errorCode?: string) => {
        const description = errorCode ? errorTranslates[errorCode as ErrorCodes] : undefined
        openMessage({
            type: 'error',
            message: `Не удалось загрузить сертификат${description ? `: ${description}` : ''}`,
        })
    }

    const removeCertificateHandler = (uuid: string) => {
        reduxDispatch(removeUsersCertificate(uuid))
    }

    return (
        <CertificatesListComponent
            isLoadingCertificate={!isLoaded}
            certificates={certificates?.length ? certificates : null}
            addCertificateHandler={addCertificateHandler}
            addCertificateErrorHandler={addCertificateErrorHandler}
            removeCertificateAction={removeCertificateHandler}
            UploadCertificate={UploadCertificate}
            userUuid={profileUid}
        />
    )
}
