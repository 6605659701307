import {
    ClientAutocomplete,
    InvestorRegistryApplicationStatus,
    InvestorRegistryApplicationStatusTranslate,
} from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType } from '@dltru/dfa-ui'

import api from '@services/api'

const typeOptions = [
    'draft',
    'operator_approval',
    'lawyer_approval',
    'declined',
    'approved',
    'under_revision',
].map((value) => {
    const label =
        InvestorRegistryApplicationStatusTranslate[value as InvestorRegistryApplicationStatus]
    return { label, value }
})

const ClientsOption: React.FC<{ data?: DataProviderResponse }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <p> {data.inn} </p>
        </div>
    )
}

const autoGetClientComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getClientsService({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item: ClientAutocomplete) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Дата и время',
        name: 'created_at',
        filterType: FilterType.DATE_RANGE,
        divider: true,
    },

    {
        label: 'Пользователь',
        name: 'user_uuid',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientComplete,
        renderElement: <ClientsOption />,
    },
    {
        label: 'Статус',
        name: 'status',
        filterType: FilterType.SELECT,
        options: typeOptions,
    },
]
