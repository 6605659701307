import {
    FeeType,
    GetFeesParams,
    GetTariffsParams,
    ICursorPaginationResponse,
    IGetTariffAssetParams,
    IGetTariffUsersParams,
    IRequest,
    ITariffAssetLink,
    ITariffGridPost,
    ITariffPlane,
    ITariffPlanePost,
    ITariffUserLink,
    TLinkTariffAsset,
    TLinkTariffUser,
    TUnlinkTariffAsset,
    TUnlinkTariffUser,
    TariffGridPut,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

export async function getTariffPlane(
    params: GetTariffsParams,
): Promise<IRequest<ICursorPaginationResponse<ITariffPlane>>> {
    return requestDecorator.call(
        this,
        this.paths.TariffPlane,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function getAllFees(
    params?: GetFeesParams,
): Promise<IRequest<ICursorPaginationResponse<FeeType>>> {
    return requestDecorator.call(
        this,
        this.paths.TariffFees,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function postTariffPlane(data: ITariffPlanePost): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        this.paths.TariffPlane,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function archiveTariffPlane(tariffPlaneId: string): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffPlaneArchive, tariffPlaneId),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}

export async function postTariffGrid(data: ITariffGridPost): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        this.paths.TariffGrids,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function putTariffGrid(
    grid_id: string,
    data: TariffGridPut,
): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffGrid, grid_id),
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function deleteTariffGrid(grid_id: string): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffGrid, grid_id),
        HttpMethod.DELETE,
        HttpCode.SUCCESS_200,
    )
}
export async function activateTariffGrid(grid_id: string): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffGridActivate, grid_id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}
export async function deactivateTariffGrid(grid_id: string): Promise<IRequest<ITariffPlane>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffGridDeactivate, grid_id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}

export async function getUsersByTariffUuid({
    params,
    tariff_id,
}: IGetTariffUsersParams): Promise<IRequest<ICursorPaginationResponse<ITariffUserLink>>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffUsers, tariff_id),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function tariffLinkUser(
    data: TLinkTariffUser,
): Promise<IRequest<ICursorPaginationResponse<ITariffUserLink>>> {
    return requestDecorator.call(
        this,
        this.paths.TariffLinkUser,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function tariffUnlinkUser(
    data: TUnlinkTariffUser,
): Promise<IRequest<ICursorPaginationResponse<ITariffUserLink>>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffUnlinkUser, data.link_id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}

export async function getAssetsByTariffUuid({
    params,
    tariff_id,
}: IGetTariffAssetParams): Promise<IRequest<ICursorPaginationResponse<ITariffAssetLink>>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffAssets, tariff_id),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function tariffLinkAsset(
    data: TLinkTariffAsset,
): Promise<IRequest<ICursorPaginationResponse<ITariffAssetLink>>> {
    return requestDecorator.call(
        this,
        this.paths.TariffLinkAsset,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function tariffUnlinkAsset(
    data: TUnlinkTariffAsset,
): Promise<IRequest<ICursorPaginationResponse<ITariffAssetLink>>> {
    return requestDecorator.call(
        this,
        path(this.paths.TariffUnlinkAsset, data.link_id),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}
