import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const TableFilter: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="1em"
                height="1em"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.333374 1.66666C0.333374 1.20642 0.70647 0.833328 1.16671 0.833328H12.8334C13.2936 0.833328 13.6667 1.20642 13.6667 1.66666C13.6667 2.1269 13.2936 2.5 12.8334 2.5H1.16671C0.70647 2.5 0.333374 2.1269 0.333374 1.66666ZM2.00004 5C2.00004 4.53976 2.37314 4.16666 2.83337 4.16666H11.1667C11.6269 4.16666 12 4.53976 12 5C12 5.46023 11.6269 5.83333 11.1667 5.83333H2.83337C2.37314 5.83333 2.00004 5.46023 2.00004 5ZM4.50004 7.5C4.0398 7.5 3.66671 7.87309 3.66671 8.33333C3.66671 8.79357 4.0398 9.16666 4.50004 9.16666H9.50004C9.96028 9.16666 10.3334 8.79357 10.3334 8.33333C10.3334 7.87309 9.96028 7.5 9.50004 7.5H4.50004Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
