import { IMoneyAccountsSummary } from '@dltru/dfa-models'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IMoneyAccountsSummaryInitialState {
    isLoading: boolean
    error: null | string
    data: IMoneyAccountsSummary | null
}

const initialState: IMoneyAccountsSummaryInitialState = {
    isLoading: false,
    error: null,
    data: null,
}

const summary = createSlice({
    name: 'moneyAccountsSummary',
    initialState,
    reducers: {
        setLoading(state, _action: PayloadAction) {
            state.isLoading = true
            state.error = null
            state.data = null
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
            state.isLoading = false
            state.data = null
        },
        setDetails(state, action: PayloadAction<IMoneyAccountsSummary>) {
            state.data = action.payload
            state.isLoading = false
            state.error = null
        },
        clear(state, _action: PayloadAction) {
            state.isLoading = false
            state.error = null
            state.data = null
        },
    },
})

export const { actions } = summary

export default summary.reducer
