import { DocumentsEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Bill } from '@dltru/dfa-ui'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import style from './style.m.less'

interface IComponentProps {
    onClick?: () => void
}

export const BalanceListHeader: FC<IComponentProps> = () => {
    const moneyAccount = useSelector(clientsSelector.selectBalanceInfo)
    const { isAllow } = useContext(RbacContext)
    const showDownloadQualButton = moneyAccount?.id && isAllow(['account.read.all'])
    return (
        <div className={style.BalanceListHeader}>
            <div className={style.LeftWrapper}>
                <div className={style.BalanceListTitle}>История операций</div>
            </div>
            {showDownloadQualButton && (
                <div className={style.RightWrapper}>
                    <DownloadBtn
                        documentType={DocumentsEnum.user_account_operations_history}
                        params={{account_id: moneyAccount.id}}
                        icon={<Bill />}
                        title="Сформировать выписку"
                    />
                </div>
            )}
        </div>
    )
}
