import {
    AgreementData,
    ChangeEmailByUserType,
    ChangeLoginByUserType,
    ChangeLoginType,
    ChangePasswordByUserType,
    ChangePhoneByUserType,
    ChangePhoneType,
    ILoginServiceParams,
    IRequest,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'
import { path } from '../../utils'

const createPasswordRequestErrors: Record<string, string> = {
    'jwt-token is expired': 'EXPIRED_JWT',
    'crypto/rsa: verification error': 'CRYPTO_VERIFICATION_ERROR',
    'password already set': 'PASSWORD_ALREADY_SET',
    'token not valid': 'TOKEN_NOT_VALID',
}

export async function loginService<T>(params: ILoginServiceParams): Promise<IRequest<T>> {
    return requestDecorator.call(this, this.paths.Auth, HttpMethod.POST, HttpCode.SUCCESS_200, {
        data: params,
    })
}

export async function registrationService(login: string, email: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.AuthRegistration,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { login, email } },
    )
}

export async function createPasswordService(password: string, jwt: string) {
    try {
        const { status } = await this.requestAgent({
            method: HttpMethod.POST,
            url: this.paths.AuthPassword,
            data: { password },
            headers: { jwt },
        })

        if (status !== HttpCode.SUCCESS_200) {
            throw new Error()
        }

        return { data: true }
    } catch (error: ReturnType<Error>) {
        return {
            error: Object.keys(createPasswordRequestErrors).includes(error.error.message)
                ? createPasswordRequestErrors[error.error.message]
                : 'INCORRECT_RESPONSE',
        }
    }
}

export async function logoutService(): Promise<IRequest> {
    return requestDecorator.call(this, this.paths.AuthLogout, HttpMethod.POST, HttpCode.SUCCESS_200)
}

export async function request2FaCallService() {
    return requestDecorator.call(
        this,
        this.paths.Auth2FAInit,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
    )
}

export async function confirm2FaCallService(token: string) {
    return requestDecorator.call(
        this,
        this.paths.Auth2FAConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { token } },
    )
}

export async function setPhoneRequestService(payload: {
    phone: string
}): Promise<IRequest<{ code: string }>> {
    return requestDecorator.call(
        this,
        this.paths.AuthSetPhone,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function setPhoneConfirmService(payload: { token: string }): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.AuthPhoneConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function resetPasswordRequestService(payload: {
    authorize_data: string
}): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.AuthResetPasswordRequest,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export interface IResetPasswordInitResponse {
    phone_last_four_digits: string
    pre_access_jwt_token: string
}

export async function resetPasswordInitService(payload: {
    confirm_token: string
    password: string
}): Promise<IRequest<{ item: IResetPasswordInitResponse }>> {
    return requestDecorator.call(
        this,
        this.paths.AuthResetPasswordInit,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function resetPasswordConfirmService(code: string, jwt: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.AuthResetPasswordConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        {
            headers: { jwt },
            data: { code },
        },
    )
}

export async function emailConfirmForRegistrationRequest(token: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.AuthEmailConfirmForRegistration, token),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function emailConfirmForResettingPasswordRequest(token: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        path(this.paths.AuthEmailConfirmForResettingPassword, token),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}
type ChangeLoginPasswordResponse = {
    phone: string
    pre_access_token: string
}
export async function changeLoginPasswordSet(
    data: ChangeLoginType,
): Promise<IRequest<ResponseItem<ChangeLoginPasswordResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeAuthLoginSet,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

type ChangePhoneResponse = {
    pre_access_token: string
}
export async function changePhoneSet(
    data: ChangePhoneType,
): Promise<IRequest<ResponseItem<ChangePhoneResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeAuthPhoneSet,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function changePhoneConfirm(code: string, jwt: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeAuthPhoneConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        {
            headers: { jwt },
            data: { code },
        },
    )
}

export async function changeEmailInitAdminConfirm(
    confirm_token: string,
): Promise<IRequest<ResponseItem<{ email: string }>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeEmailInitAdminConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        {
            data: { confirm_token },
        },
    )
}

export async function changePasswordByAdminConfirm(code: string, jwt: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangePasswordByAdminConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        {
            headers: { jwt },
            data: { code },
        },
    )
}

export async function changeLoginPasswordConfirm(code: string, jwt: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeAuthLoginConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        {
            headers: { jwt },
            data: { code },
        },
    )
}

export async function setAgreementsService(data: Partial<AgreementData>): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersAgreements,
        HttpMethod.PUT,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function getAgreementsServiceByUser(
    user_id: string,
): Promise<IRequest<ResponseItem<AgreementData>>> {
    return requestDecorator.call(
        this,
        path(this.paths.UsersAgreementsByUser, user_id),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function changeLoginInitByUser(data: ChangeLoginByUserType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeLoginInitByUser,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function changeLoginConfirmByUser(code: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeLoginConfirmByUser,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { code } },
    )
}

export async function changePasswordInitByUser(data: ChangePasswordByUserType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangePasswordInitByUser,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function changePasswordConfirmByUser(code: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangePasswordConfirmByUser,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { code } },
    )
}

export async function changePhoneInitByUser(data: ChangePhoneByUserType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UserChangePhoneInit,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function changePhoneConfirmByUser(code: string): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UserChangePhoneConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { code } },
    )
}

export async function changeEmailInitByUser(data: ChangeEmailByUserType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UserChangeEmailInit,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function changeEmailInitConfirmByUser(data: ChangeEmailByUserType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UserChangeEmailInitConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function changeEmailConfirmByUser(data: ChangeEmailByUserType): Promise<IRequest> {
    return requestDecorator.call(
        this,
        this.paths.UserChangeEmailConfirm,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
