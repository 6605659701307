// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerCollapse__ucR_ {\n  display: block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: #007ef2;\n  cursor: pointer;\n}\n.headerCollapse__ucR_ .anticon {\n  margin-left: 2px;\n}\n.headerCollapse__ucR_ svg {\n  width: 16px;\n  height: 16px;\n  vertical-align: inherit;\n}\n.headerCollapseOpen_KS6YF {\n  padding-top: 8px;\n}\n.headerCollapseOpen_KS6YF .anticon {\n  transform: rotate(180deg) translateY(-2px);\n}\n.collapsePanel_zm5Mj .ant-collapse-header {\n  padding: 0 !important;\n}\n.collapsePanel_zm5Mj .ant-collapse-content-box {\n  padding: 0 !important;\n}\n.collapsePanel_zm5Mj .ant-collapse-content {\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/collapse/CollapseDown/styles.m.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AADF;AALA;EAUM,gBAAA;AAFN;AARA;EAeI,WAAA;EACA,YAAA;EACA,uBAAA;AAJJ;AAOE;EACE,gBAAA;AALJ;AAIE;EAKM,0CAAA;AANR;AAYA;EAGM,qBAAA;AAZN;AASA;EAOM,qBAAA;AAbN;AAMA;EAWM,gBAAA;AAdN","sourcesContent":["@import '/src/assets/style/palette';\n\n.headerCollapse {\n  display: block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: @primary-6;\n  cursor: pointer;\n\n  :global {\n    .anticon {\n      margin-left: 2px;\n    }\n  }\n\n  svg {\n    width: 16px;\n    height: 16px;\n    vertical-align: inherit;\n  }\n\n  &Open {\n    padding-top: 8px;\n\n    :global {\n      .anticon {\n        transform: rotate(180deg) translateY(-2px);\n      }\n    }\n  }\n}\n\n.collapsePanel {\n  :global {\n    .ant-collapse-header {\n      padding: 0 !important;\n    }\n\n    .ant-collapse-content-box {\n      padding: 0 !important;\n    }\n\n    .ant-collapse-content {\n      overflow: hidden;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCollapse": "headerCollapse__ucR_",
	"headerCollapseOpen": "headerCollapseOpen_KS6YF",
	"collapsePanel": "collapsePanel_zm5Mj"
};
export default ___CSS_LOADER_EXPORT___;
