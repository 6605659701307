import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Warning: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.8751 16.875C10.8751 17.1734 10.9936 17.4595 11.2046 17.6705C11.4156 17.8815 11.7017 18 12.0001 18C12.2985 18 12.5846 17.8815 12.7956 17.6705C13.0066 17.4595 13.1251 17.1734 13.1251 16.875C13.1251 16.5766 13.0066 16.2905 12.7956 16.0795C12.5846 15.8685 12.2985 15.75 12.0001 15.75C11.7017 15.75 11.4156 15.8685 11.2046 16.0795C10.9936 16.2905 10.8751 16.5766 10.8751 16.875ZM11.2501 9.75V14.0625C11.2501 14.1656 11.3345 14.25 11.4376 14.25H12.5626C12.6657 14.25 12.7501 14.1656 12.7501 14.0625V9.75C12.7501 9.64687 12.6657 9.5625 12.5626 9.5625H11.4376C11.3345 9.5625 11.2501 9.64687 11.2501 9.75ZM22.3993 20.0625L12.6493 3.1875C12.504 2.93672 12.2532 2.8125 12.0001 2.8125C11.747 2.8125 11.4939 2.93672 11.3509 3.1875L1.60089 20.0625C1.31261 20.5641 1.67354 21.1875 2.25011 21.1875H21.7501C22.3267 21.1875 22.6876 20.5641 22.3993 20.0625ZM4.03604 19.4086L12.0001 5.62266L19.9642 19.4086H4.03604Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
