import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

interface IDfaIcon {
    width?: string
    height?: string
    fill?: string
}

export const PlusCircle: FC<IconProps & IDfaIcon> = ({
    width = '24px',
    height = '24px',
    ...props
}) => (
    <AntIcon
        component={() => (
            <svg
                width={width}
                height={height}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75333 17.2467 2.50004 12 2.50004C6.75329 2.50004 2.5 6.75333 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
                    fill="currentColor"
                />
                <path
                    d="M16.3125 11.25H12.75V7.6875C12.75 7.58437 12.6656 7.5 12.5625 7.5H11.4375C11.3344 7.5 11.25 7.58437 11.25 7.6875V11.25H7.6875C7.58437 11.25 7.5 11.3344 7.5 11.4375V12.5625C7.5 12.6656 7.58437 12.75 7.6875 12.75H11.25V16.3125C11.25 16.4156 11.3344 16.5 11.4375 16.5H12.5625C12.6656 16.5 12.75 16.4156 12.75 16.3125V12.75H16.3125C16.4156 12.75 16.5 12.6656 16.5 12.5625V11.4375C16.5 11.3344 16.4156 11.25 16.3125 11.25Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
