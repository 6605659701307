import { BindBankAccountModel, MoneyAccountType } from '@dltru/dfa-models'
import { MoneyNominalAccount } from '@dltru/dfa-ui'
import { bindMoneySpecialAccount } from '@store/clients'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RelateAccountModal } from './RelateAccountModal'

export interface IAccountWidget {
    balanceInfo?: Partial<MoneyAccountType>
    userName: string
    userUuid: string
}

export const AccountWidget: FC<IAccountWidget> = ({ balanceInfo, userName, userUuid }) => {
    const reduxDispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const bindAccount = (data: BindBankAccountModel) => {
        if (balanceInfo?.id) {
            reduxDispatch(
                bindMoneySpecialAccount({
                    account_uuid: balanceInfo.id,
                    nominal_account_uuid: data.nominal_account_uuid,
                }),
            )
        }
    }

    return (
        <>
            <MoneyNominalAccount
                balanceInfo={balanceInfo}
                changeAction={() => setIsModalVisible(true)}
            />

            <RelateAccountModal
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                action={bindAccount}
                balanceInfo={balanceInfo}
                userName={userName}
                userUuid={userUuid}
            />
        </>
    )
}
