import { IRequest, ResponseItem, UploadResult } from '@dltru/dfa-models'
import { FC } from 'react'

import { EMPTY_FIELD_VALUE } from '../constants'
import { UneditableFileComponent } from './UneditableFileComponent'

export interface IUneditableUploadedFilesComponentProps {
    files?: { uid: string }[]
    getBase64File: (uid: string) => Promise<IRequest<ResponseItem<UploadResult>>>
}

export const UneditableUploadedFilesComponent: FC<IUneditableUploadedFilesComponentProps> = ({
    files = [],
    getBase64File,
}) => {
    if (!Array.isArray(files)) {
        return null
    }

    return (
        <>
            {files?.map((file: any) => {
                if (!file.uid) {
                    return EMPTY_FIELD_VALUE
                }
                return (
                    <UneditableFileComponent
                        uid={file.uid}
                        key={file.uid}
                        getBase64File={getBase64File}
                    />
                )
            })}
        </>
    )
}
