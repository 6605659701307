import { RevokeByOrderPayload } from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateOrdersList as initialState } from '../resources'

const secondaryDfaDealsListSlice = createSlice({
    name: 'secondaryDfaDealsList',
    initialState,
    reducers: {
        getSecondaryDfaDealsList: (state) => {
            return state
        },
        updateSecondaryDfaDealsList: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateSecondaryDfaDealsById: (state, action) => {
            state.data[action.payload.secondaryDfa.id] = action.payload.secondaryDfa
        },
        dropSecondaryDfaDealsList: () => {
            return { ...initialState }
        },
        updateErrorSecondaryDfaDealsList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingSecondaryDfaDealsList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadSecondaryDfaDeals = createAction('secondaryDfaList/loadSecondaryDfaDeals')
type RevokeActionType = {
    data: Partial<RevokeByOrderPayload>
    withUpdateOrder?: boolean
}
export const revokeSecondaryBuyDfa = createAction<RevokeActionType>(
    'secondaryDfaDetails/revokeSecondaryBuyDfa',
)
export const revokeSecondarySellDfa = createAction<RevokeActionType>(
    'secondaryDfaDetails/revokeSecondarySellDfa',
)
export const {
    updateSecondaryDfaDealsList,
    getSecondaryDfaDealsList,
    dropSecondaryDfaDealsList,
    updateSecondaryDfaDealsById,
    updateErrorSecondaryDfaDealsList,
    updateLoadingSecondaryDfaDealsList,
} = secondaryDfaDealsListSlice.actions
export default secondaryDfaDealsListSlice.reducer
