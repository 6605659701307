import {
    AvailableOrderTransaction,
    GetDfasParams,
    ICursorPaginationResponse,
    IDfaFront,
    ResponseDfa,
    TablePaginationParam,
} from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, isEmptyValue } from '@dltru/dfa-ui'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: Partial<ICursorPaginationResponse<IDfaFront>>
    error?: string
    params: GetDfasParams
}

const initialState: IInitialState = {
    error: '',
    isLoading: false,
    data: {},
    params: { limit: DEFAULT_PER_PAGE },
}
export const dfaListSlice = createSlice({
    name: 'dfaList',
    initialState,
    reducers: {
        loadDfas: (state, action: PayloadAction<GetDfasParams | undefined>) => {
            state.params = isEmptyValue(action.payload) ? initialState.params : action.payload
        },
        updateDfaList: (state, action) => {
            return { ...state, ...action.payload }
        },
        dropDfaList: () => {
            return { ...initialState }
        },
        updateErrorDfaList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingDfaList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadDfasEmission = createAction<
    TablePaginationParam<AvailableOrderTransaction> & Partial<ResponseDfa>
>('dfaList/loadDfasEmission')
export const { updateDfaList, loadDfas, dropDfaList, updateErrorDfaList, updateLoadingDfaList } =
    dfaListSlice.actions
export default dfaListSlice.reducer
