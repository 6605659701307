import { openMessage, openNotification } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import api from '@services/api'
import {
    accountSauSlice,
    bindAccount,
    getSauAccountsTxs,
    getSauAccountSummary,
    reserveReleaseSau,
    reserveTransferSau,
    transactionCreditSau,
} from '.'
import { clientsSlice } from '@store/clients'
import { accountSauSelector } from './selectors'

function* transactionCreditSauHandler({ payload }: ReturnType<typeof transactionCreditSau>) {
    try {
        const { id } = yield* select(accountSauSelector.selectSummary)

        if (id) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error: sendCreditError } = yield* call(api.lib.sendCredit, {
                ...payload,
                //account_id: id,
            })

            if (sendCreditError) {
                throw sendCreditError
            }

            const { data, error: accInfoError } = yield* call(api.lib.getMoneyAccountsBalance, id)
            if (accInfoError) {
                throw accInfoError
            }
            if (data) {
                yield* put(accountSauSlice.actions.setSummary({ ...data }))
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить пополнение',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* reserveReleaseSauHandler({ payload }: ReturnType<typeof reserveReleaseSau>) {
    try {
        const { user_uuid, id } = yield* select(accountSauSelector.selectSummary)

        if (user_uuid) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error } = yield* call(api.lib.transferInitSauByUuidToBankAccount, {
                ...payload,
                account_uuid: id,
                user_id: user_uuid,
            })

            if (error) {
                throw error
            }

            const { data, error: accInfoError } = yield* call(api.lib.getMoneyAccountsBalance, id)
            if (accInfoError) {
                throw accInfoError
            }
            if (data) {
                yield* put(accountSauSlice.actions.setSummary({ ...data }))
            }
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить списание',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* reserveTransferSauHandler({ payload }: ReturnType<typeof reserveTransferSau>) {
    try {
        const { user_uuid, id } = yield* select(accountSauSelector.selectSummary)

        if (user_uuid) {
            yield* put(clientsSlice.actions.setIsLoading(true))
            const { error } = yield* call(api.lib.moneyReserveTransfer, {
                ...payload,
                sender_id: user_uuid,
            })

            if (error) {
                throw error
            }

            const { data, error: accInfoError } = yield* call(api.lib.getMoneyAccountsBalance, id)
            if (accInfoError) {
                throw accInfoError
            }
            if (data) {
                yield* put(accountSauSlice.actions.setSummary({ ...data }))
            }
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить списание',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

function* getSauAccountSummaryHandler({ payload }: ReturnType<typeof getSauAccountSummary>) {
    try {
        yield* put(accountSauSlice.actions.clearItems())
        yield* put(accountSauSlice.actions.setIsLoading(true))

        const { data: uuidData } = yield* call(api.lib.getAccountByTypeServie, payload)

        const { data, error } = yield* call(api.lib.getMoneyAccountsBalance, uuidData.item.id)

        if (error) {
            throw error
        }

        if (data && uuidData) {
            yield* put(
                accountSauSlice.actions.setSummary({
                    ...uuidData.item,
                    ...data,
                    type: payload,
                }),
            )
        }
    } catch (error) {
        yield* put(accountSauSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении банковского счета',
        })
    } finally {
        yield* put(accountSauSlice.actions.setIsLoading(false))
    }
}

function* getSauAccountTxsHandler({ payload }: ReturnType<typeof getSauAccountsTxs>) {
    try {
        yield* put(accountSauSlice.actions.setIsLoading(true))

        const { error, data } = yield* call(api.lib.getTransactions, {
            ...payload,
            order: 'asc(created_at)' as const,
        })

        if (error) {
            throw error
        }

        if (data?.items) {
            yield* put(accountSauSlice.actions.setTsx(data))
        } else if (data === null) {
            yield* put(accountSauSlice.actions.clearItems())
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получения списка транзакций',
        })
        yield* put(accountSauSlice.actions.setError(error as string))
    } finally {
        yield* put(accountSauSlice.actions.setIsLoading(false))
    }
}

function* bindAccountHandler({
    payload,
}: ReturnType<typeof clientsSlice.actions.bindMoneyAccount>) {
    try {
        yield* put(clientsSlice.actions.setIsLoading(true))
        const summary = yield* select(accountSauSelector.selectSummary)
        const { error } = yield* call(api.lib.bindNominalAccountById, payload)

        if (error) {
            throw error
        }

        openNotification({
            type: 'success',
            message: 'Счета связаны',
            description: 'Лицевой счёт успешно привязан к банковскому счёту',
        })

        if (summary?.type) {
            yield* put(getSauAccountSummary(summary.type))
        }
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Счета не связаны',
            description: 'Возникли ошибки при попытке связать счета',
        })
        yield* put(clientsSlice.actions.setError(error as string))
    } finally {
        yield* put(clientsSlice.actions.setIsLoading(false))
    }
}

export function* watchAccountSauSlice(): Generator<StrictEffect> {
    yield* takeLatest(getSauAccountSummary.type, getSauAccountSummaryHandler)
    yield* takeLatest(getSauAccountsTxs.type, getSauAccountTxsHandler)
    yield* takeLatest(reserveTransferSau.type, reserveTransferSauHandler)
    yield* takeLatest(reserveReleaseSau.type, reserveReleaseSauHandler)
    yield* takeLatest(transactionCreditSau.type, transactionCreditSauHandler)
    yield* takeLatest(bindAccount.type, bindAccountHandler)
}
