import { frontUrl } from '@constants/frontUrl'
import { RbacContext } from '@dltru/dfa-rbac'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    RoleFormCard,
    Space,
    Tag,
    getFullName,
} from '@dltru/dfa-ui'
import { getAnketaTagProps } from '@resources/anketaStatus'
import { getQuestionnaireUrlByProfileRequestType } from '@utils/profile'
import React, { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { ChangeNodeStatus } from './ChangeNodeStatus/ConfirmRepayment'
import { ChangeOperatorStatus } from './ChangeOperatorStatus/ChangeOperatorStatus'
import style from './style.m.less'

export const QuestionnaireInfo: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const role = useSelector(clientsSelector.getRoleForm)
    const profile = useSelector(clientsSelector.selectProfile)
    const profileAgent = useSelector(clientsSelector.selectProfileAgent)

    const tagPropsAgent = profileAgent?.application_status
        ? getAnketaTagProps(profileAgent.application_status)
        : undefined
    const tagPropsClient = profile?.profile_status
        ? getAnketaTagProps(profile.profile_status)
        : undefined

    return (
        <Card className="boxContent">
            <CardHeader title="Анкета пользователя" />
            <CardContent>
                <Box padding={[0, 0, 16, 0]}>
                    <Space>
                        <RoleFormCard
                            title="Анкета пользователя"
                            subtitle={getFullName(profile)}
                            to={getQuestionnaireUrlByProfileRequestType[role]}
                            tag={
                                tagPropsClient ? (
                                    <Tag color={tagPropsClient.color}>{tagPropsClient.title}</Tag>
                                ) : undefined
                            }
                            isCompleted
                        />
                        {profileAgent && (
                            <RoleFormCard
                                title="Анкета представителя"
                                subtitle={getFullName(profileAgent)}
                                to={frontUrl.questionnaireRepresentative}
                                tag={
                                    tagPropsAgent ? (
                                        <Tag color={tagPropsAgent.color}>{tagPropsAgent.title}</Tag>
                                    ) : undefined
                                }
                                isCompleted
                            />
                        )}
                    </Space>
                </Box>
                {isAllow(['user.write.all']) && (
                    <div className={style.stastusContainer}>
                        <ChangeOperatorStatus />
                        <ChangeNodeStatus />
                    </div>
                )}
            </CardContent>
        </Card>
    )
}
