import { FC } from 'react'

export const LinkToTariff: FC = () => {
    return (
        <a
            href="https://www.dltru.org/products/digital_assets/#document"
            target="_blank"
            rel="noreferrer noopener"
        >
            Тарифам Оператора ИС
        </a>
    )
}
