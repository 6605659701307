// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_QtoNh .headerBold_HKE62 {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.modal_QtoNh .footer__NhtP {\n  padding: 40px 0 0 0;\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Employee/View/components/BlockEmployeeModal/style.m.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAJA;EAUI,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,QAAA;AAHJ","sourcesContent":["@import 'style/palette';\n\n.modal {\n  .headerBold {\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n  }\n\n  .footer {\n    padding: 40px 0 0 0;\n    display: flex;\n    justify-content: flex-end;\n    gap: 8px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_QtoNh",
	"headerBold": "headerBold_HKE62",
	"footer": "footer__NhtP"
};
export default ___CSS_LOADER_EXPORT___;
