import { ContentFooter } from '@dltru/dfa-ui'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { AuthLiteHeader } from '../Header'
import { ContentBox } from './ContentBox'

export const AuthLayoutLite: FC = () => (
    <>
        <AuthLiteHeader />
        <ContentBox type="unauthorized">
            <Outlet />
        </ContentBox>
        <ContentFooter />
    </>
)
