import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Wallet: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.6667 7.33333V10.6667C14.6667 12.6667 13.3333 14 11.3333 14H4.66668C2.66668 14 1.33334 12.6667 1.33334 10.6667V7.33333C1.33334 5.52 2.42668 4.25333 4.12668 4.04C4.30001 4.01333 4.48001 4 4.66668 4H11.3333C11.5067 4 11.6733 4.00666 11.8333 4.03333C13.5533 4.23333 14.6667 5.50667 14.6667 7.33333Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.8343 4.03332C11.6743 4.00666 11.5076 4 11.3343 4H4.66759C4.48093 4 4.30093 4.01333 4.12759 4.04C4.22093 3.85333 4.35426 3.68 4.51426 3.52L6.68093 1.34666C7.59426 0.43999 9.07426 0.43999 9.98759 1.34666L11.1543 2.52667C11.5809 2.94667 11.8076 3.47999 11.8343 4.03332Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6667 7.66666H12.6667C11.9333 7.66666 11.3333 8.26666 11.3333 8.99999C11.3333 9.73332 11.9333 10.3333 12.6667 10.3333H14.6667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
