import { FC } from 'react'

import { Space, SpaceProps } from '../../../Space'
import styles from './styles.m.less'

type FormSpaceProps = SpaceProps & {
    uneditable?: boolean
}

export const FormSpace: FC<FormSpaceProps> = ({
    children,
    uneditable,
    direction = 'vertical',
    ...rest
}) => (
    <Space {...rest} className={styles.form_space} direction={direction} size={uneditable ? 0 : 16}>
        {children}
    </Space>
)
