import classNames from 'classnames/bind'
import { FC } from 'react'

import style from './style.m.less'

export interface ICaseBadge {
    count: number | string
    buying?: boolean
    selling?: boolean
    percentInCase?: boolean
}

const cx = classNames.bind(style)

export const CaseBadge: FC<ICaseBadge> = ({ count, buying, selling, percentInCase }) => {
    const className = cx({
        caseBadge: true,
        caseBadgePercentage: percentInCase,
        caseBadgeBuying: buying || (count && !percentInCase),
        caseBadgeSelling: selling,
    })

    return (
        <div className={className}>
            <p>{percentInCase ? (count as number).toFixed(2) + '%' : count}</p>
        </div>
    )
}
