import { NominalAccountModel } from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType } from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

const ClientsOption: FC<{ data?: DataProviderResponse }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <p> {data.account_details.recipient_inn} </p>
        </div>
    )
}

const autoGetClientInnComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getNominalAccounts({
            recipient_inn: `like(${value})`,
        })
        return data?.items?.map((item: NominalAccountModel) => ({
            ...item,
            label: item.account_details.recipient,
            value: item.account_details.recipient_inn,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

const autoGetClientNameComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getNominalAccounts({
            recipient: `like(${value})`,
        })
        return data?.items?.map((item: NominalAccountModel) => ({
            ...item,
            label: item.account_details.recipient,
            value: item.account_details.recipient,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Номер счёта',
        name: 'account_number',
        filterType: FilterType.TEXTINPUT,
    },

    {
        label: 'Владелец счёта (получатель)',
        name: 'recipient',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientNameComplete,
        renderElement: <ClientsOption />,
    },
    {
        label: 'ИНН получателя',
        name: 'recipient_inn',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetClientInnComplete, // TODO тут поиск по ИНН
        renderElement: <ClientsOption />,
        divider: true,
    },
    {
        label: 'Наименование банка',
        name: 'bank_name',
        filterType: FilterType.TEXTINPUT,
    },
    {
        label: 'ИНН банка',
        name: 'bank_inn',
        filterType: FilterType.TEXTINPUT,
    },
]
