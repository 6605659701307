// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bodyText14_XN5Ym {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: #303440;\n  margin: 0;\n}\n.bodyText16__OOCo {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n  margin: 0;\n}\n.tooltipOrderedList_sK6Vw {\n  padding: 0 0 0 14px;\n  margin: 0;\n}\n.iconBlue_v_BJf {\n  vertical-align: bottom;\n  color: #007ef2;\n}\n.iconAlign_HGu72 > span {\n  vertical-align: -0.3em;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/CheckEmailModal/style.m.less"],"names":[],"mappings":"AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AAFJ;AAKE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AAHJ;AAOA;EACE,mBAAA;EACA,SAAA;AALF;AASE;EACE,sBAAA;EACA,cAAA;AAPJ;AAWI;EACE,sBAAA;AATN","sourcesContent":["@import '/src/assets/style/palette';\n\n.body {\n  &Text14 {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n    color: @gray-10;\n    margin: 0;\n  }\n\n  &Text16 {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n    margin: 0;\n  }\n}\n\n.tooltipOrderedList {\n  padding: 0 0 0 14px;\n  margin: 0;\n}\n\n.icon {\n  &Blue {\n    vertical-align: bottom;\n    color: @primary-6;\n  }\n\n  &Align {\n    & > span {\n      vertical-align: -0.3em;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyText14": "bodyText14_XN5Ym",
	"bodyText16": "bodyText16__OOCo",
	"tooltipOrderedList": "tooltipOrderedList_sK6Vw",
	"iconBlue": "iconBlue_v_BJf",
	"iconAlign": "iconAlign_HGu72"
};
export default ___CSS_LOADER_EXPORT___;
