import {
    DecisionMakingTypeTranslate,
    DfaStatusEnum,
    IDfaFront,
    IssueAvailableInvestorsType,
    PriceSource,
    preStatuses,
} from '@dltru/dfa-models'
import { Alert, Box, Col, DecisionBlock, InfoCardsDfa, Row, prepareInitialDfa } from '@dltru/dfa-ui'
import { Moment } from 'moment'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { putDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { ServiceHistory } from '@pages/DFAEmissionCardPage/components/ServiceHistory'

import { Links } from '@components/EmissionDecisionCard/components'

import { DFADetailsStepper } from './steps'

/*import { RelatedTariff } from '@components/RelatedTariff/RelatedTariff'*/

export const AboutDfa: FC = () => {
    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)

    const saveDfa = (value: IDfaFront) => {
        if (value.price_source_type === PriceSource.static) {
            value.price_per_dfa = parseFloat(value.price_per_dfa as unknown as string)
            value.redeem_price_per_dfa = parseFloat(value.redeem_price_per_dfa as unknown as string)
        } else {
            value.max_supply_invested = parseFloat(value.max_supply_invested as unknown as string)
            value.min_supply_invested = parseFloat(value.min_supply_invested as unknown as string)
            value.investment_spread = parseFloat(value.investment_spread as unknown as string)
            value.redemption_spread = parseFloat(value.redemption_spread as unknown as string)
        }
        value.issue_end_date = (value.issue_end_date as unknown as Moment)?.valueOf()
        value.issue_start_date = (value.issue_start_date as unknown as Moment)?.valueOf()
        value.redeem_date = (value.redeem_date as unknown as Moment)?.valueOf()

        //TODO убрать после организации нормального state для dfaDetails
        const dataForPut = { ...dfaDetails, ...value }
        delete dataForPut.approvalHistory

        reduxDispatch(putDfa(dataForPut))
    }

    const isDfaProcessing = preStatuses.includes(dfaDetails.status)

    /*const statusToAlertHeading: Record<DfaStatusEnum, string> = {
        rejected: 'Выпуск аннулирован',
        emitment_stoped: 'Выпуск приостановлен',
        emitment_failure: 'Выпуск признан несостоявшимся',
    }*/

    const isShowAlert =
        dfaDetails.status === DfaStatusEnum.collection_started &&
        dfaDetails.issue_available_investors_type === IssueAvailableInvestorsType.qualified

    return (
        <>
            <Box padding={[0, 24, 24, 24]} margin={[0, 0, 8, 0]} className="tablebox">
                {/* TODO make integration properly  */}
                {/*statusToAlertHeading[dfaDetails.status] && (
                    <Box padding={[24, 0, 0, 0]}>
                        <AlertBlock
                            heading={statusToAlertHeading[dfaDetails.status]}
                            reason="Тут какая-то довольно серьезная причина для того чтобы признать выпуск несостоявшимся такого уважаемого пользователя как знаменитый и невероятный Владимир Иванов"
                            date={1668584988}
                        />
                    </Box>
                )*/}

                <Row gutter={[16, 24]} style={{ marginTop: 16 }}>
                    {!isDfaProcessing && (
                        <Col span={24}>
                            <DecisionBlock dfa={dfaDetails} LinksComponent={Links} />
                        </Col>
                    )}

                    <InfoCardsDfa data={dfaDetails.emitter_info} />
                </Row>
            </Box>
            {isDfaProcessing && (
                <Box margin={[0, 0, 8, 0]}>
                    <Alert
                        showIcon
                        type="info"
                        description={DecisionMakingTypeTranslate[dfaDetails.decision_making_type]}
                    />
                </Box>
            )}
            {isShowAlert && (
                <Box margin={[0, 0, 8, 0]}>
                    <Alert
                        showIcon
                        type="warning"
                        description="ЦФА доступны для приобретения только квалифицированным инвесторам"
                    />
                </Box>
            )}
            {/*<RelatedTariff />*/}
            {dfaDetails.id && (
                <DFADetailsStepper
                    initialValues={prepareInitialDfa(dfaDetails)}
                    initialStep={5}
                    onStepperFinish={saveDfa}
                    saveAfterEdit
                />
            )}
            <ServiceHistory />
        </>
    )
}
