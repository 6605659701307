import IAppState from '../states'

const selectList = (state: IAppState) => state.accountSau.data?.items
const selectPaging = (state: IAppState) => state.accountSau.data?.paging
const selectParams = (state: IAppState) => state.accountSau.params
const selectIsLoading = (state: IAppState) => state.accountSau.isLoading
const selectSummary = (state: IAppState) => state.accountSau.summary

export const accountSauSelector = {
    selectList,
    selectPaging,
    selectIsLoading,
    selectParams,
    selectSummary,
}
