// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal__FD6R .headerBold_XoDLP {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.modal__FD6R .caption_iIe8_ {\n  display: block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: #868991;\n}\n.modal__FD6R .footer_Z3B6J {\n  padding: 16px 0;\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PaymentOrders/PaymentOrderDetails/components/RejectPaymentOrderModal/style.m.less"],"names":[],"mappings":"AAEA;EAEQ,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFR;AAJA;EASQ,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFR;AAXA;EAiBQ,eAAA;EACA,aAAA;EACA,yBAAA;EACA,QAAA;AAHR","sourcesContent":["@import 'style/palette';\n\n.modal {\n    .headerBold {\n        font-style: normal;\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 24px;\n        color: @gray-10;\n    }\n    .caption {\n        display: block;\n        font-weight: 400;\n        font-size: 12px;\n        line-height: 20px;\n        color: @gray-7;\n    }\n\n    .footer {\n        padding: 16px 0;\n        display: flex;\n        justify-content: flex-end;\n        gap: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal__FD6R",
	"headerBold": "headerBold_XoDLP",
	"caption": "caption_iIe8_",
	"footer": "footer_Z3B6J"
};
export default ___CSS_LOADER_EXPORT___;
