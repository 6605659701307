import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { CountdownModal } from './CountdownModal'
import { useIdle } from './useIdle'

interface IActivityDetectorProps {
    activityEvents: string[]
    timeToLogout: number | null // ms
    logout: () => void
}

export const ActivityDetector: FC<IActivityDetectorProps> = ({
    activityEvents,
    timeToLogout,
    logout,
}) => {
    const location = useLocation()

    const logoutHandler = () => {
        localStorage.clear()
        localStorage.setItem('last-enter', location.pathname)
        localStorage.setItem('logout-alarm', '1')
        logout()
        document.location.href = '/login'
    }

    const { start, isShowCountdownModal } = useIdle({
        activityEvents,
        timeToLogout,
    })

    if (!isShowCountdownModal) {
        return null
    }

    return <CountdownModal isShow={isShowCountdownModal} onOk={start} logout={logoutHandler} />
}
