// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".documentLinkData_lqCee {\n  color: #007ef2;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/modals/InjunctionModal/style.m.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AADF","sourcesContent":["@import '/src/assets/style/palette';\n\n.documentLinkData {\n  color: @primary-6;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentLinkData": "documentLinkData_lqCee"
};
export default ___CSS_LOADER_EXPORT___;
