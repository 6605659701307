import { Tag, UserIcon, getFullName } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import style from './clientWithStatus.m.less'

type ClientWithStatusBoxProps = {
    status: string
}

export const ClientWithStatusBox: FC<ClientWithStatusBoxProps> = ({ status }) => {
    const profile = useSelector(clientsSelector.selectProfile)
    const userFullName = getFullName(profile)
    return (
        <div className={style.userInfo}>
            <span className={style.userInfoTitle}>Пользователь</span>
            <div className={style.userInfoRow}>
                <div className={style.userInfoName}>
                    <UserIcon />
                    <span className={style.userName}>{userFullName}</span>
                </div>
                {status && <Tag>{status}</Tag>}
            </div>
        </div>
    )
}
