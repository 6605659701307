// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grayText_fdLNK {\n  color: #868991;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/RemoveClient/style.m.less"],"names":[],"mappings":"AACA;EACE,cAAA;AAAF","sourcesContent":["@import 'style/palette';\n.grayText {\n  color: @gray-7\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grayText": "grayText_fdLNK"
};
export default ___CSS_LOADER_EXPORT___;
