import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

interface IconCustomizableProps extends IconProps {
    width?: number
    height?: number
}

export const Exclude: FC<IconCustomizableProps> = ({ ...rest }) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_18344_25272)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.1666 9.99998C19.1666 15.0626 15.0625 19.1666 9.99992 19.1666C4.93731 19.1666 0.833252 15.0626 0.833252 9.99998C0.833252 4.93737 4.93731 0.833313 9.99992 0.833313C15.0625 0.833313 19.1666 4.93737 19.1666 9.99998ZM17.9166 9.99998C17.9166 14.3722 14.3722 17.9166 9.99992 17.9166C5.62766 17.9166 2.08325 14.3722 2.08325 9.99998C2.08325 5.62773 5.62766 2.08331 9.99992 2.08331C14.3722 2.08331 17.9166 5.62773 17.9166 9.99998ZM6.86998 6.87004C7.14118 6.59885 7.58088 6.59885 7.85208 6.87004L9.99992 9.01789L12.1478 6.87004C12.419 6.59885 12.8587 6.59885 13.1299 6.87004C13.4011 7.14124 13.4011 7.58094 13.1299 7.85214L10.982 9.99998L13.1299 12.1478C13.4011 12.419 13.4011 12.8587 13.1299 13.1299C12.8587 13.4011 12.419 13.4011 12.1478 13.1299L9.99992 10.9821L7.85208 13.1299C7.58088 13.4011 7.14118 13.4011 6.86998 13.1299C6.59879 12.8587 6.59879 12.419 6.86998 12.1478L9.01783 9.99998L6.86998 7.85214C6.59879 7.58094 6.59879 7.14124 6.86998 6.87004Z"
                        fill="currentColor"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_18344_25272">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )}
        {...rest}
    />
)
