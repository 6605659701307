export enum EmployeeStatus {
    active = 'active',
    archived = 'archived',
    blocked = 'blocked',
}

export const employeeStatusTranslate = {
    [EmployeeStatus.active]: 'Активен',
    [EmployeeStatus.archived]: 'В архиве',
    [EmployeeStatus.blocked]: 'Заблокирован',
}
