import { InvestorRegistryApplication } from '@dltru/dfa-models'
import { Alert, EMPTY_FIELD_VALUE, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { FC } from 'react'

import './style.less'

interface INoteAlarmProps {
    data?: InvestorRegistryApplication
}

export const NoteAlert: FC<INoteAlarmProps> = ({ data }) => {
    const date = data?.created_at
        ? dayjs.unix(data.created_at).locale('ru').format(VIEW_DATE_FORMAT)
        : EMPTY_FIELD_VALUE

    const description = (
        <>
            {date} <br />
            {data?.comment || ''}
        </>
    )
    return (
        <Alert
            showIcon
            type="warning"
            message="Заявка направлена на доработку"
            description={description}
        />
    )
}
