// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./svg/CheckGray.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./svg/CheckGreen.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkList_xvQ7A {\n  margin: 0;\n  padding: 0;\n  color: #555861;\n  list-style-type: none;\n}\n.checkList_xvQ7A li {\n  position: relative;\n  padding-left: 32px;\n}\n.checkList_xvQ7A li:not(:first-child) {\n  margin-top: 11px;\n}\n.checkList_xvQ7A li::before {\n  position: absolute;\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  left: 2px;\n  top: 2px;\n}\n.checkList_xvQ7A li.checked_ozE6T::before {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/CheckUl/styles.m.less"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,cAAA;EACA,qBAAA;AADF;AAHA;EAMI,kBAAA;EACA,kBAAA;AAAJ;AACI;EACE,gBAAA;AACN;AACI;EACE,kBAAA;EACA,gDAAA;EACA,SAAA;EACA,QAAA;AACN;AAEM;EACE,gDAAA;AAAR","sourcesContent":["@import '/src/assets/style/palette';\n\n.checkList {\n  margin: 0;\n  padding: 0;\n  color: @gray-8;\n  list-style-type: none;\n  li {\n    position: relative;\n    padding-left: 32px;\n    &:not(:first-child) {\n      margin-top: 11px;\n    }\n    &::before {\n      position: absolute;\n      content: url('./svg/CheckGray.svg');\n      left: 2px;\n      top: 2px;\n    }\n    &.checked {\n      &::before {\n        content: url('./svg/CheckGreen.svg');\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkList": "checkList_xvQ7A",
	"checked": "checked_ozE6T"
};
export default ___CSS_LOADER_EXPORT___;
