import { Box, Button, Divider, Form, Modal, UploadFile, WrappedInput } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { rejectDfa } from '@store/dfa/details'

import { UploadDocumentContainerWrapper } from '@components/Form/components/wrappers'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}
type FormValuesType = {
    reject_reason: string
    reject_reason_documents_uuid?: UploadFile[]
}

export const CancellationIssueModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const [form] = Form.useForm<FormValuesType>()
    const [isDisableBtn, setIsDisableBtn] = useState(true)
    const reduxDispatch = useDispatch()

    const closeModal = () => {
        setIsModalVisible(false)
        setIsDisableBtn(true)
        form.resetFields()
    }

    const onFinish = (values: FormValuesType) => {
        reduxDispatch(
            rejectDfa({
                reject_reason: values.reject_reason,
                reject_reason_documents_uuid: values.reject_reason_documents_uuid?.map(
                    ({ uid }) => uid,
                ),
            }),
        )
        closeModal()
    }

    const onValuesChange = (changedValues: Partial<FormValuesType>) => {
        if ('reject_reason' in changedValues) {
            setIsDisableBtn(!changedValues.reject_reason)
        }
    }

    return (
        <Modal
            title="Аннулирование выпуска ЦФА"
            visible={isModalVisible}
            onCancel={closeModal}
            width={522}
            footer={[
                <Button borderRadius={12} onClick={closeModal}>
                    Отмена
                </Button>,
                <Button
                    borderRadius={12}
                    type="danger"
                    onClick={form.submit}
                    disabled={isDisableBtn}
                >
                    Аннулировать выпуск
                </Button>,
            ]}
        >
            <div>
                <p>
                    Выпуск будет анулирован, все размещенные заявки не будут исполнены. Возобновить
                    процедуру выпуска будет невозможно.
                </p>
                <Divider margin={0} />
                <Box padding={[24, 0, 16, 0]}>
                    <h3 className="page-content-header">Основание аннулирования выпуска ЦФА</h3>
                </Box>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                >
                    <UploadDocumentContainerWrapper
                        name="reject_reason_documents_uuid"
                        title="Загрузите фотографии или сканы документов"
                        maxSizeMb={5}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png"
                    >
                        <WrappedInput
                            className="formComponentItem"
                            placeholder="Ваш комментарий"
                            name="reject_reason"
                            multiline
                            required
                        />
                    </UploadDocumentContainerWrapper>
                </Form>
            </div>
        </Modal>
    )
}
