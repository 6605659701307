import clsx from 'clsx'

import { useCountdown } from '../hooks'
import style from './style.m.less'

interface ITimer {
    onFinish: () => void
    secondsRemaining: number
}

const formatWithZeros = (number: number) => {
    const time = String(number)
    return time.length > 1 ? time : '0' + time
}

export const Timer = ({ onFinish, secondsRemaining }: ITimer) => {
    const [, minutes, seconds] = useCountdown(0, 0, secondsRemaining, onFinish)
    return (
        <span className={clsx(style.labelUnderInput, style.labelCenter)}>{`${formatWithZeros(
            minutes,
        )}:${formatWithZeros(seconds)}`}</span>
    )
}

export const RedTimer = ({ secondsRemaining, onFinish }: ITimer) => {
    const [, minutes, seconds] = useCountdown(0, 0, secondsRemaining, onFinish)
    return (
        <div className={style.timerBoxRed}>
            <span className={style.labelCenter}>{`${formatWithZeros(minutes)}:${formatWithZeros(
                seconds,
            )}`}</span>
        </div>
    )
}
