import React from 'react'

export const dataSRR = {
    heading: 'ООО «Системы распределенного реестра»',
    data: [
        {
            label: 'Полное наименование',
            value: 'Общество с ограниченной ответственностью «Системы распределенного реестра»',
        },
        {
            label: 'Юридический адрес',
            value: '121099, г. Москва, ул. Композиторская, д.17, этаж 7',
        },
        {
            label: 'Почтовый адрес',
            value: '121099, г. Москва, ул. Композиторская, д.17, этаж 7',
        },
        { label: 'ОГРН', value: '1217700216360' },
        { label: 'ИНН', value: '9704063885' },
        { label: 'КПП', value: '770401001' },
        { label: 'Дата постановки на учет в налоговом органе', value: '04.05.2021' },
        {
            label: 'Сайт',
            value: (
                <a href="https://www.dltru.org/" rel="noreferrer noopener" target="_blank">
                    www.dltru.org
                </a>
            ),
        },
    ],
}
