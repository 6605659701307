import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { issuerConfirmationDfa } from '@store/dfa/details'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}

export const ConfirmToIssuerModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const reduxDispatch = useDispatch()

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const handleOk = () => {
        reduxDispatch(issuerConfirmationDfa())
        handleCancel()
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={423}
            footer={[
                <Button borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={handleOk}>
                    Да, направить
                </Button>,
            ]}
        >
            <div>
                <Box direction="row" align="center">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">Направить на подтверждение Эмитенту</div>
                </Box>
                <Box margin={[8, 40, 0, 40]}>
                    Вы действительно хотите предоставить Эмитенту возможность направить заявление о
                    допуске ЦФА к выпуску с текущими параметрами?
                </Box>
            </div>
        </MiniModal>
    )
}
