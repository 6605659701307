import { IssueAvailableInvestorsType, ProfileType, TransferOrderStatus } from '@dltru/dfa-models'
import { Alert, Box } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import {
    checkLimitRecipientTransferAction,
    dfaOwnershipTransferSlice,
} from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

export const AlertOfferBlock: FC = () => {
    const offerData = useSelector(dfaTransferSelector.selectData)
    const buyerData = useSelector(dfaTransferSelector.selectBuyerData)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (
            offerData?.status === TransferOrderStatus.accepted &&
            (dfa.issue_available_investors_type === IssueAvailableInvestorsType.qualified ||
                dfa.issue_available_investors_type_limits ===
                    IssueAvailableInvestorsType.qualified_and_unqualified_with_limit)
        ) {
            reduxDispatch(checkLimitRecipientTransferAction(offerData?.buyer_id as string))
        }

        return () => {
            reduxDispatch(dfaOwnershipTransferSlice.actions.clearDFAOwnershipBuyerData())
        }
    }, [
        offerData?.status,
        dfa?.issue_available_investors_type,
        dfa?.issue_available_investors_type_limits,
    ])
    const showLimitAlert =
        offerData?.status === TransferOrderStatus.accepted &&
        dfa?.issue_available_investors_type_limits ===
            IssueAvailableInvestorsType.qualified_and_unqualified_with_limit &&
        buyerData.is_qualified === false &&
        buyerData.type === ProfileType.PRSN &&
        Number.isInteger(offerData?.total_price) &&
        buyerData.quota < Number(offerData?.total_price)

    const showQualAlert =
        offerData?.status === TransferOrderStatus.accepted &&
        dfa?.issue_available_investors_type === IssueAvailableInvestorsType.qualified &&
        buyerData.is_qualified === false

    if (!showLimitAlert && !showQualAlert) {
        return null
    }

    return (
        <Box margin={[0, 0, 8, 0]}>
            {showLimitAlert && (
                <Alert
                    showIcon
                    type="warning"
                    description="Превышен годовой лимит покупок с учетом настоящей Оферты. Оферта не может быть исполнена."
                />
            )}
            {showQualAlert && (
                <Alert
                    showIcon
                    type="warning"
                    description="ЦФА доступны для приобретения только только квалифицированным инвесторам. Оферта не может быть исполнена, так как получатель ЦФА не является квалифицированным инвестором"
                />
            )}
        </Box>
    )
}
