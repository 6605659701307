import clsx from 'clsx'
import { FC } from 'react'

import { Price } from '../index'
import style from './style.m.less'

export interface IBodyCheck {
    label: string
    labelRight?: boolean
    price?: string | number
    currency?: string
    Content?: React.FC
    subText?: string
}

export const BodyCheck: FC<IBodyCheck> = ({
    label,
    price,
    currency,
    Content,
    subText,
    labelRight,
}) => (
    <div className={style.checkBlock}>
        <p className={clsx(style.checkBlockLabel, { [style.checkBlockLabelRight]: labelRight })}>
            {label}
        </p>
        <div className={style.checkBlockTextWrapper}>
            {!Content && <Price price={price} currency={currency} />}
            {Content && <Content />}
            {subText && <p className={style.checkBlockSubText}>{subText}</p>}
        </div>
    </div>
)
