import { Box, Col, DateTimeCell, InfoCardsDfa, PageHeader, Row, Tag } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { walletEncumbranceSlice } from '@store/wallet/encumbrance'
import { encumbranceSelectors } from '@store/wallet/encumbrance/selectors'

import { EmissionDecisionCardForOwnershipTransfer } from '@components/EmissionDecisionCard'

import { DFAEncumbranceViewBody } from './components/DFAEncumbranceViewBody'
import { Footer } from './components/Footer'

export const DFAEncumbranceView: FC = () => {
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const { id: urlId } = useParams()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const encumbrance = useSelector(encumbranceSelectors.selectItem)
    useEffect(() => {
        if (urlId) {
            reduxDispatch(walletEncumbranceSlice.actions.getItem(urlId))
        }
        return () => {
            reduxDispatch(walletEncumbranceSlice.actions.clearData())
        }
    }, [urlId])

    useEffect(() => {
        if (encumbrance?.asset_id) {
            reduxDispatch(getDfaById(encumbrance.asset_id))
        }
    }, [encumbrance?.asset_id])

    if (!dfaDetails?.id || !encumbrance) {
        return null
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Обременение ЦФА"
                    tags={
                        <Tag color="default">{encumbrance.is_active ? 'Действующее' : 'Снято'}</Tag>
                    }
                    onBack={() => navigate(-1)}
                    subTitle={<>от {DateTimeCell(encumbrance.set_date)}</>}
                />
            </Box>

            <Box padding={[0, 24, 24, 24]} className="tablebox tablebox--auto-height">
                <Row gutter={16}>
                    <Col span={24}>
                        <EmissionDecisionCardForOwnershipTransfer dfa={dfaDetails} />
                    </Col>
                    <InfoCardsDfa data={dfaDetails.emitter_info} />
                </Row>
            </Box>
            <DFAEncumbranceViewBody />
            {encumbrance.is_active && <Footer status="success" />}
        </>
    )
}
