import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const Bill: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.05835 2.08334V12.0583C3.05835 12.875 3.44168 13.65 4.10002 14.1417L8.44168 17.3917C9.36668 18.0833 10.6417 18.0833 11.5667 17.3917L15.9083 14.1417C16.5667 13.65 16.95 12.875 16.95 12.0583V2.08334H3.05835Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M1.66663 2.08334H18.3333"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M6.66663 6.66666H13.3333"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66663 10.8333H13.3333"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
