import { CheckCircleOutlined } from '@ant-design/icons'
import { FC, ReactElement, useState } from 'react'

import { Box } from '../../Box'
import { Timer } from '../../Timer'
import { Button } from '../../button'
import { Form, FormItem, WrappedInput } from '../../components/Form'
import { AuthForm } from '../auth'

interface ITwoFactorForm {
    title: string
    recommendationText?: ReactElement
    status2FA: string | null
    phone?: string
    lastPhone4Digits?: string
    onFinish: () => void
    initCall?: () => void
    onBack: () => void
    confirmCall: (fourDigits: string) => void
    isSubmitButtonDisabled: boolean
}

export const TwoFactorForm: FC<ITwoFactorForm> = ({
    title,
    status2FA,
    phone,
    lastPhone4Digits,
    onFinish,
    initCall,
    confirmCall,
    onBack,
    isSubmitButtonDisabled,
}) => {
    const [form] = Form.useForm()
    const [codeStatus, setCodeStatus] = useState<'timer' | 'callAgain'>('timer')

    const splitPhoneDigits = (digits: string) => {
        return digits.slice(0, 2) + '-' + digits.slice(2, digits.length)
    }

    return (
        <>
            <AuthForm
                title={title}
                initialValues={{ phone }}
                form={form}
                onFinish={() => onFinish()}
            >
                <Box width="360px">
                    <span>
                        На ваш телефон поступит смс с кодом подтверждения. Введите код из смс в
                        соответствующее поле.
                    </span>
                </Box>
                <Box padding={[24, 0]} direction="row" align="top" justify="space-between">
                    <Box width="245px" align="top">
                        <WrappedInput
                            disabled={true}
                            name="actual_phone"
                            className="no-label-padding no-error-field"
                            label="Телефон"
                            prefix={`* *** *** ${
                                lastPhone4Digits && splitPhoneDigits(lastPhone4Digits)
                            }`}
                        />
                        {status2FA === 'await' && (
                            <span className="label-under-input">Введите код из СМС</span>
                        )}
                    </Box>
                    <Box margin={[0, 0, 0, 16]} width="122px">
                        {status2FA === 'ready' && initCall && (
                            <div className="phone-button">
                                <Button
                                    size="middle"
                                    width="100%"
                                    borderRadius={12}
                                    className="icon-in-center"
                                    type="primary"
                                    onClick={initCall}
                                >
                                    Получить код
                                </Button>
                            </div>
                        )}
                        {status2FA === 'await' && (
                            <>
                                <WrappedInput
                                    name="phoneCode"
                                    label="Код"
                                    placeholder="4 цифры"
                                    className="no-label-padding no-error-field"
                                    maxLength={4}
                                    validateStatus={
                                        form.isFieldTouched(['phoneCode']) ? 'error' : 'success'
                                    }
                                    onChange={(event) => {
                                        if (event.target.value.length === 4) {
                                            confirmCall(event.target.value)
                                        }
                                    }}
                                />
                                {codeStatus === 'timer' && initCall && (
                                    <Timer
                                        secondsRemaining={30}
                                        onFinish={() => {
                                            setCodeStatus('callAgain')
                                        }}
                                    />
                                )}
                                {codeStatus === 'callAgain' && initCall && (
                                    <Button
                                        className="link-color label-under-input label-center btn-as-label"
                                        onClick={() => {
                                            initCall()
                                            setCodeStatus('timer')
                                        }}
                                        type="link"
                                    >
                                        Повторить СМС
                                    </Button>
                                )}
                            </>
                        )}
                        {status2FA === 'confirmed' && (
                            <div className="success-phone-icon-box">
                                <CheckCircleOutlined className="modal-icon modal-success-icon" />
                            </div>
                        )}
                    </Box>
                </Box>
                <FormItem className="no-error-field">
                    <Box direction="row" justify="space-between" padding={[24, 0, 0, 0]}>
                        <Button borderRadius={16} size="large" onClick={() => onBack()}>
                            Назад
                        </Button>
                        <Button
                            disabled={isSubmitButtonDisabled}
                            borderRadius={16}
                            type="primary"
                            htmlType="submit"
                            size="large"
                        >
                            Далее
                        </Button>
                    </Box>
                </FormItem>
            </AuthForm>
        </>
    )
}
