import { DfaStatusEnum } from '@dltru/dfa-models'
import { RbacContext } from '@dltru/dfa-rbac'
import { Button, CloseCircleOutlined } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { RejectApplicationModal } from '../Modals/RejectApplicationModal'

export const RejectApplicationAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const { isAllow } = useContext(RbacContext)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const showButton =
        dfaDetails.status === DfaStatusEnum.preliminary_check && isAllow(['approval.operator'])

    if (!showButton) {
        return null
    }

    return (
        <>
            <Button
                icon={<CloseCircleOutlined />}
                borderRadius={16}
                onClick={() => setIsModalVisible(true)}
            >
                Отклонить заявку
            </Button>
            <RejectApplicationModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </>
    )
}
