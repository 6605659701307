import { IDfa, IDfaOwner } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ownersSelector } from '@store/dfa/owners/selectors'

import { RepaymentMatureModal } from '../Footer/Modals'
import { getGridConfig } from './gridConfig'

export const OwnersTable: React.FC<{ dfa: IDfa }> = ({ dfa }) => {
    const data = useSelector(ownersSelector.selectData)
    const isLoading = useSelector(ownersSelector.selectIsLoading)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentDfaOwner, setCurrentDfaOwner] = useState<IDfaOwner>()
    const navigate = useNavigate()

    const onRow = (record: IDfaOwner) => ({
        onClick: () => {
            navigate(`/clients/${record.user_id}`)
        },
    })

    const gridConfig = useMemo(() => {
        const onRedeemMature = (data: IDfaOwner) => {
            setIsModalVisible(true)
            setCurrentDfaOwner(data)
        }
        return getGridConfig({ dfa, onRedeemMature })
    }, [dfa])

    return (
        <>
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data}
                onRow={onRow}
                clickableRow
                scroll={{ y: undefined }}
                rowKey="user_id"
            />
            <RepaymentMatureModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                owner={currentDfaOwner}
            />
        </>
    )
}
