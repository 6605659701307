import { IDfaAvailable } from '@dltru/dfa-models'
import { DEFAULT_PER_PAGE, UploadFile } from '@dltru/dfa-ui'

import { IDfaDetailsState, IDfaOwnersListState } from './types'

export const initialState = {
    attachments: [],
    emitter_id: '',
    ois_info: '',
    title: '',
    operator_id: '',
    ticker_symbol: '',
    document: [] as UploadFile[],
    decision: '',
    issue_restriction: '',

    approvalHistory: [],

    error: '',
    isLoading: false,
    errorLink: '',
    isLoadingLink: false,
} as unknown as IDfaDetailsState

export const initialStateAvailableDeals = {
    original_amount_dfa: 0,
    rest_amount_dfa: 0,
    error: '',
    isLoading: false,
} as IDfaAvailable

export const initialStateOwnersList = {
    isLoading: false,
    filters: {},
    sort: {},
    page: 0,
    limit: DEFAULT_PER_PAGE,
    data: [],
    error: '',
} as IDfaOwnersListState
