import classNames from 'classnames/bind'
import { FC, useState } from 'react'

import { CopySmall } from '../../Icons'
import { Tooltip } from '../../Tooltip'
import style from './CopyButton.m.less'

const cn = classNames.bind(style)

interface IComponentProps {
    text: string
    color?: 'primary' | 'volcano'
}
const tooltip = {
    initial: 'Скопировать в буфер',
    success: 'Скопировано',
    error: 'Не удалось скопировать в буфер',
}

export const CopyButton: FC<IComponentProps> = ({ text, color = 'primary' }) => {
    const [currentTooltip, setCurrentTooltip] = useState(tooltip.initial)
    const onClick = async () => {
        try {
            await navigator.clipboard.writeText(text)
            setCurrentTooltip(tooltip.success)
        } catch (e) {
            if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
                const textarea = document.createElement('textarea')
                textarea.textContent = text
                textarea.style.position = 'fixed'
                document.body.appendChild(textarea)
                textarea.select()
                try {
                    document.execCommand('copy')
                    setCurrentTooltip(tooltip.success)
                } catch (e) {
                    setCurrentTooltip(tooltip.error)
                } finally {
                    document.body.removeChild(textarea)
                }
            } else {
                setCurrentTooltip(tooltip.error)
            }
        }
    }
    const onMouseEnter = () => {
        setCurrentTooltip(tooltip.initial)
    }

    return (
        <span className={cn(style.copyButton, color)} onClick={onClick} onMouseEnter={onMouseEnter}>
            <Tooltip title={currentTooltip}>
                <CopySmall />
            </Tooltip>
        </span>
    )
}
