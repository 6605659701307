import { MAX_LOGIN_LENGTH } from '../../../constants'
import { authValidators } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name' | 'maxLength' | 'minLength' | 'multiline'> & {
    name?: WrappedInputProps['name']
}

export const LoginInput = ({ label, name, placeholder, ...rest }: ComponentProps) => {
    return (
        <WrappedInput
            {...rest}
            label={label ?? 'Логин'}
            name={name ?? 'login'}
            placeholder={placeholder ?? 'Например, Vladimir'}
            rules={[{ validator: authValidators.setLogin }]}
            maxLength={MAX_LOGIN_LENGTH}
            required
        />
    )
}
