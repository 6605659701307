import { frontUrl } from '@constants/frontUrl'
import { Box, TwoFactorForm } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { authSlice, dropAuth, resetPasswordConfirm } from '@store/auth'
import { authSelector } from '@store/auth/selectors'

import { AuthSideBox } from '@components/SideBox'

import { RestorePasswordForm } from './RestorePasswordForm'

export const RestorePassword: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const reduxDispatch = useDispatch()
    const { lastPhoneDigits, phoneVerificationStatus } = useSelector(
        authSelector.selectChangeAuthState,
    )

    useEffect(
        () => () => {
            reduxDispatch(authSlice.actions.clearChangeAuthData())
        },
        [],
    )

    const onConfirmCall = (code: string) => {
        const isAdminInitial = location.pathname.includes(frontUrl.restorePasswordAdmin)
        reduxDispatch(resetPasswordConfirm({ code, isAdminInitial }))
    }

    const redirectToLogin = () => {
        reduxDispatch(dropAuth())
        navigate('/login')
    }

    return (
        <>
            <Box direction="row" align="center" justify="center" height="100%">
                <Box direction="row">
                    <AuthSideBox />
                    {lastPhoneDigits ? (
                        <TwoFactorForm
                            title="Подтверждение смены пароля"
                            status2FA={phoneVerificationStatus}
                            onFinish={redirectToLogin}
                            lastPhone4Digits={lastPhoneDigits}
                            isSubmitButtonDisabled={phoneVerificationStatus !== 'confirmed'}
                            confirmCall={onConfirmCall}
                            onBack={redirectToLogin}
                        />
                    ) : (
                        <RestorePasswordForm />
                    )}
                </Box>
            </Box>
        </>
    )
}
