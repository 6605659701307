import { Alert as AlertAntd, AlertProps as AlertPropsAntd } from 'antd'
import cn from 'classnames'
import { FC } from 'react'

import { CloseIcon } from '../Icons'
import { AlertIcon } from './AlertIcon'
import './styles.less'

export type AlertProps = AlertPropsAntd & { width?: number }

export const Alert: FC<AlertProps> = ({ className, type, message, width, ...props }) => {
    const classNames = cn('dfa-alert', className, { 'dfa-alert--big-icon': Boolean(message) })
    return (
        <AlertAntd
            className={classNames}
            style={{
                maxWidth: width ? width : undefined,
            }}
            {...props}
            message={message}
            type={type}
            closeIcon={<CloseIcon />}
            icon={<AlertIcon type={type} isBig={Boolean(message)} />}
        />
    )
}
