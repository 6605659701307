import { IDfaFront, PriceSource, getPennies } from '@dltru/dfa-models'
import { ColumnsType, LinkCell, VIEW_DATE_FORMAT, getRublesFromPennies } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

export const getGridConfig = (rate: number): ColumnsType<IDfaFront> => [
    {
        title: 'Дата',
        dataIndex: 'issue_start_date',
        key: 'issue_start_date',
        sorter: true,
        ellipsis: true,
        width: '17%',
        render: (value) => dayjs(value).format(VIEW_DATE_FORMAT),
    },
    {
        title: 'Тикер',
        dataIndex: 'ticker_symbol',
        key: 'ticker_symbol',
        sorter: true,
        ellipsis: true,
        width: '11%',
        render: (value, record) => LinkCell(value, `/offers/${record.id}`),
    },
    {
        title: 'Наименование ЦФА',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        ellipsis: true,
        width: '11%',
    },
    {
        title: 'Эмитент',
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        ellipsis: true,
        width: '11%',
        render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
    },

    {
        title: 'Доступно, ₽',
        dataIndex: 'rest_amount_dfa',
        key: 'rest_amount_dfa',
        ellipsis: true,
        width: '10%',
        render: (value) => value?.toLocaleString(),
    },
    {
        title: 'Цена за единицу, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        ellipsis: true,
        width: '15%',
        render: (_, record: IDfaFront) => {
            if (record.price_source_type === PriceSource.static) {
                return `${record.price_per_dfa ?? 0}`.toLocaleString()
            }

            if (record.investment_spread !== undefined) {
                return getRublesFromPennies(
                    getPennies(((rate / 100) * record.investment_spread + rate) / 100),
                )
            }

            return ''
        },
    },
    {
        title: 'Стоимость, ₽',
        dataIndex: 'original_amount_dfa',
        key: 'original_amount_dfa',
        ellipsis: true,
        width: '15%',
        render: (value) => value?.toLocaleString(),
    },
]
