import { documentValidators } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name'> & {
    isUl?: boolean
    name?: WrappedInputProps['name']
}
export const OgrnInput = ({
    className,
    label,
    isUl,
    name,
    placeholder,
    ...restProps
}: ComponentProps) => {
    const count = isUl ? 13 : 15
    return (
        <WrappedInput
            {...restProps}
            className={className ?? 'formComponentItem'}
            label={label ?? isUl ? 'ОГРН' : 'ОГРНИП'}
            name={name ?? isUl ? 'ogrn' : 'ogrnip'}
            placeholder={placeholder ?? `Состоит из ${count} цифр`}
            rules={[{ validator: documentValidators.ogrn(count) }]}
            format={isUl ? '#############' : '###############'}
        />
    )
}
