import { ISecondaryOrderShort, OrderTypeEnum, finalSecondaryStatuses } from '@dltru/dfa-models'
import { BankCollectCommission, BodyCheck, Box } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'

import { getRestAmountSecondaryOrder, getRestPriceSecondaryOrder } from '../../helper'

interface IComponentProps {
    data?: ISecondaryOrderShort
}
export const SecondaryCheckList: FC<IComponentProps> = ({ data }) => {
    const uuid = useSelector(authSelector.selectUserUid)
    const isMyOrder = uuid === data?.user_id
    const isBuying = data?.order_type === OrderTypeEnum.sell
    const finalStatus = data?.status && finalSecondaryStatuses.includes(data.status)

    if (!data) {
        return null
    }

    const totalPrice = getRestPriceSecondaryOrder(data)

    const checkListPart = [
        {
            label: data?.order_type === OrderTypeEnum.sell ? 'Продавец' : 'Покупатель',
            Content: () => <Link to={`/clients/${data?.user_id}`}>{data?.user_full_name}</Link>,
        },
        {
            label: 'Цена',
            price: data?.price_per_dfa || 0,
        },
        {
            label: finalStatus
                ? `${isBuying ? 'Доступно к выкупу' : 'Количество'}, ед.`
                : 'Доступно к выкупу, ед.',
            price: getRestAmountSecondaryOrder(data),
            currency: 'unit',
            subText: `из${finalStatus ? '' : ' заявленных'} ${data?.original_amount_dfa || 0}`,
        },
        {
            label: finalStatus ? 'Стоимость активов' : 'Стоимость невыкупленных активов',
            price: totalPrice,
            subText: isMyOrder
                ? `из${finalStatus ? '' : ' заявленных'} ${data?.total_price || 0}`
                : undefined,
        },
    ]
    if (typeof data.sell_fee_amount === 'number') {
        checkListPart.push({
            label: 'Уплачено комиссий за продажу ЦФА',
            price: data.sell_fee_amount / 100,
        })
    }
    if (typeof data.buy_fee_amount === 'number') {
        const fee = (data.buy_fee_amount + (data.fee_payment_amount_order ?? 0)) / 100
        checkListPart.push(
            {
                label: `Комиссия за покупку ЦФА ${data.fee_payment_amount_order ? '*' : ''}`,
                price: fee,
            },
            {
                label: 'Итого',
                price: totalPrice + fee,
            },
        )
    }

    return (
        <>
            <div>
                {checkListPart.map((check) => (
                    <BodyCheck {...check} key={check.label} />
                ))}
            </div>
            <Box padding={[0, 16]}>
                <BankCollectCommission feePaymentOrder={data?.fee_payment_amount_order} />
            </Box>
        </>
    )
}
