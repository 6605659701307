import {
    INVALID_PASSWORD,
    LOGIN_RESPONSE_ERROR,
    NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN,
} from '@constants/authConstants'
import { AuthForm, Box, Button, Form, FormItem, Input, authValidators } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { login, setAuthError } from '@store/auth'
import IAppState from '@store/states'

import { validatorConstructor } from '../../../resources/inputValidators'

export const LoginForm: FC = () => {
    const reduxDispatch = useDispatch()
    const { error: authError } = useSelector((store: IAppState) => store.auth)
    const [loginError, setLoginError] = useState('')
    const navigate = useNavigate()
    const [form] = Form.useForm()

    useEffect(() => {
        setLoginError(authError || '')

        if (authError) {
            form.validateFields(['username', 'password'])
        }
    }, [authError, form])

    const ifLoginAndPasswordAreInvalid = () =>
        loginError === NOT_FOUND_ACCOUNT_WITH_SUCH_LOGIN || loginError === INVALID_PASSWORD

    const ifServerResponseError = () => loginError === LOGIN_RESPONSE_ERROR

    return (
        <AuthForm
            title="Авторизация"
            validateTrigger={['onChange']}
            form={form}
            onFinish={(formValues) => {
                reduxDispatch(
                    login({ login: formValues.username, password: formValues.password, navigate }),
                )
            }}
        >
            <FormItem
                label="Логин"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Это поле обязательно для заполнения',
                    },
                    {
                        validator: authValidators.login,
                    },
                ]}
            >
                <Input
                    size="large"
                    onChange={() => {
                        reduxDispatch(setAuthError(''))
                        form.validateFields(['password'])
                    }}
                    placeholder="Например, Vladimir"
                />
            </FormItem>

            <FormItem
                label="Пароль"
                name="password"
                rules={[
                    { required: true, message: 'Это поле обязательно для заполнения' },
                    validatorConstructor(
                        ifLoginAndPasswordAreInvalid,
                        'Пользователь с таким логином и паролем не найден',
                    ),
                    validatorConstructor(
                        ifServerResponseError,
                        'Ошибка сервера. Некорректный ответ',
                    ),
                ]}
            >
                <Input.Password
                    size="large"
                    onChange={() => {
                        reduxDispatch(setAuthError(''))
                        form.validateFields(['username'])
                    }}
                />
            </FormItem>

            <FormItem className="no-error-field">
                <Box direction="row" padding={[16, 0, 0, 0]} justify="right">
                    <Button borderRadius={16} type="primary" htmlType="submit" size="large">
                        Войти
                    </Button>
                </Box>
            </FormItem>
        </AuthForm>
    )
}
