import { Box, PageHeader, Tabs } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { TariffTable } from '@pages/SystemManagement/components/TariffTable'

import { UseTabsAndHash } from '../../hooks/useTabsAndHash'
import { RbacContext } from '@dltru/dfa-rbac'

const { TabPane } = Tabs
enum TabName {
    tariff = 'tariff',
    service = 'service',
}
const tabNames = Object.values(TabName)
const defaultTab = TabName.tariff

export const SystemManagement: FC = () => {
    const { isAllow } = useContext(RbacContext)
    const { onChangeTab, activeTab } = UseTabsAndHash(tabNames, defaultTab)

    return (
        <>
            <Box padding={16}>
                <PageHeader title="Управление системой" />
            </Box>
            <Tabs activeKey={activeTab} onChange={onChangeTab}>
                <TabPane tab="Техническое обслуживание" key={TabName.service}>
                    <div></div>
                </TabPane>
                {isAllow(['tariff.write.all', 'tariff.read.all']) && (
                    <TabPane tab="Тарифные планы" key={TabName.tariff}>
                        <TariffTable />
                    </TabPane>
                )}
            </Tabs>
        </>
    )
}
