// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unauthorizedHeader_MGyAe {\n  background-color: #ffffff;\n}\n.logo_RVMrG {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #303440;\n  border-radius: 100%;\n  font-size: 20px;\n}\n.logoname_JmgRM {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #000514;\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 28px;\n  letter-spacing: 0.03em;\n  padding: 0 10px;\n  font-family: Montserrat, SF Pro Display, serif;\n}\n.headerLinksBox_EwapC > * {\n  color: #303440;\n}\n.headerLinksBox_EwapC > * + * {\n  margin-left: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/components/style.m.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;AAFF;AAKA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,8CAAA;AAHF;AAMA;EACE,cAAA;AAJF;AAOA;EACE,iBAAA;AALF","sourcesContent":["@import 'style/palette';\n\n.unauthorizedHeader {\n  background-color: @gray-1;\n}\n\n.logo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: @gray-10;\n  border-radius: 100%;\n  font-size: 20px;\n}\n\n.logoname {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: @gray-13;\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 28px;\n  letter-spacing: 0.03em;\n  padding: 0 10px;\n  font-family: Montserrat, SF Pro Display, serif;\n}\n\n.headerLinksBox > * {\n  color: @gray-10;\n}\n\n.headerLinksBox > * + * {\n  margin-left: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unauthorizedHeader": "unauthorizedHeader_MGyAe",
	"logo": "logo_RVMrG",
	"logoname": "logoname_JmgRM",
	"headerLinksBox": "headerLinksBox_EwapC"
};
export default ___CSS_LOADER_EXPORT___;
