import { UnionDealType, isTransferDeal } from '@dltru/dfa-models'

export const getHeaderDealData = (deal: UnionDealType) => {
    if (isTransferDeal(deal)) {
        return {
            linkToEmission: deal.order.seller_asset_data?.asset_ticker_symbol,
            linkToEmissionID: deal.order.seller_asset_data?.asset_id,
            linkToInvestor: deal.order.seller_asset_data?.asset_emitter_full_name,
            linkToInvestorID: deal.order.seller_asset_data?.asset_emitter_id,
            title: deal.order.seller_asset_data?.asset_title,
        }
    }

    return {
        linkToEmission: deal.asset_ticker_symbol,
        linkToEmissionID: deal.asset_id,
        linkToInvestor: deal.emitter_full_name,
        linkToInvestorID: deal.emitter_id,
        title: deal.asset_title,
    }
}
