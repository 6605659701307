import {
    Button,
    CONTENT_FOOTER_ID,
    CaseDel,
    ContentFooterBox,
    IFooter,
    Portal,
} from '@dltru/dfa-ui'
import { FC } from 'react'

interface ICComponentProps {
    status: IFooter['status']
    sendOferta: () => void
}
export const Footer: FC<ICComponentProps> = ({ status, sendOferta }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    {status === 'precreate' && (
                        <ContentFooterBox>
                            <Button
                                icon={<CaseDel width="20px" height="20px" />}
                                borderRadius={16}
                                type="primary"
                                onClick={sendOferta}
                            >
                                Передать права
                            </Button>
                        </ContentFooterBox>
                    )}
                </Portal>
            )}
        </>
    )
}
