import { IUsersRegistryListItem, UsersRegistryStausNameTranslate } from '@dltru/dfa-models'
import { Tag } from '@dltru/dfa-ui'
import { FC } from 'react'

type StatusCellProps = Pick<IUsersRegistryListItem, 'status'>

export const StatusCell: FC<StatusCellProps> = ({ status }) => {
    if (!status?.length) {
        return null
    }

    const translatedStatuses = status.map((value) => (
        <Tag key={value}>{UsersRegistryStausNameTranslate[value]}</Tag>
    ))

    return <>{translatedStatuses}</>
}
