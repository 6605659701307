import { DataProviderResponse } from '@dltru/dfa-ui'
import { FC } from 'react'

type SelectDataType = { inn: string; full_name: string } & DataProviderResponse
export const ClientOption: FC<{ data?: SelectDataType }> = ({ data }) => {
    if (!data) {
        return null
    }
    return (
        <span>
            {data.full_name}
            <br />
            ИНН {data.inn}
        </span>
    )
}
