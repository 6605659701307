import { Box, Button, MiniModal, QuestionCircle } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}
export const RemoveNominalAccount: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const navigate = useNavigate()
    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const handleOk = () => {
        // TODO удалять счет когда появится возможность
        setIsModalVisible(false)
        navigate('/personal-accounts#bank')
    }

    return (
        <MiniModal visible={isModalVisible} onCancel={handleCancel} width={423}>
            <div>
                <Box direction="row" align="center">
                    <Box className="modal-content-shift">
                        <QuestionCircle className="modal-icon modal-question-icon" />
                    </Box>
                    <div className="ant-modal-title">Удалить банковский счёт</div>
                </Box>
                <Box padding={[16, 0, 24, 40]}>
                    Вы действительно хотите удалить банковский счет?
                </Box>
                <Box
                    className="ant-modal-footer ant-modal-footer-no-border"
                    padding={0}
                    direction="row"
                    justify="right"
                >
                    <Button borderRadius={12} onClick={handleCancel}>
                        Отмена
                    </Button>
                    <Button borderRadius={12} type="primary" onClick={handleOk}>
                        Да, удалить
                    </Button>
                </Box>
            </div>
        </MiniModal>
    )
}
