import { UnionOrderType, getOrderTypeTranslate, isSecondaryOrder } from '@dltru/dfa-models'
import {
    ColoredTag,
    ColumnsType,
    DangerPercent,
    DateTimeCell,
    LinkCell,
    Tag,
    TooltipCell,
    getOrderStatusTagProps,
} from '@dltru/dfa-ui'

import { SecondaryOrderActionButton } from '@components/SecondaryOrderActionButton'

const AmountCell = (_, record: UnionOrderType) => {
    if (record.amount_dfa !== undefined) {
        return record.amount_dfa // TODO заменить на ColoredTag когда будет цвет
    }

    return (
        <ColoredTag type={record.order_type || 'gray'}>
            {record?.original_amount_dfa?.toLocaleString()}
        </ColoredTag>
    )
}

interface IGridConfigProps {
    actionSecondaryCancel: (order: UnionOrderType) => void
}

export const getGridConfig = (props: IGridConfigProps): ColumnsType<UnionOrderType> => [
    {
        title: TooltipCell('Дата и время'),
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: TooltipCell('Тикер'),
        dataIndex: 'asset_ticker_symbol',
        key: 'asset_ticker_symbol',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/offers/${record.asset_id}`),
    },
    {
        title: TooltipCell('Эмитент'),
        dataIndex: 'emitter_full_name',
        key: 'emitter_full_name',
        sorter: true,
        ellipsis: true,
        render: (value, record) => LinkCell(value, `/clients/${record.emitter_id}`),
    },
    {
        title: TooltipCell('Тип заявки'),
        dataIndex: 'order_type',
        key: 'order_type',
        sorter: true,
        ellipsis: true,
        render: getOrderTypeTranslate,
    },
    {
        title: TooltipCell('Кол-во, ед.'),
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: AmountCell,
    },
    {
        title: TooltipCell('Цена, ₽'),
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: TooltipCell('Стоимость, ₽'),
        dataIndex: 'total_price',
        key: 'total_price',
        sorter: true,
        ellipsis: true,
        render: (value) => value?.toLocaleString(),
    },
    {
        title: TooltipCell('Выкуплено'),
        dataIndex: 'repurchase_percent',
        key: 'repurchase_percent',
        sorter: true,
        ellipsis: true,
        render: (value) => DangerPercent(value),
    },
    {
        title: TooltipCell('Статус'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        fixed: 'right',
        render: (_, record) => {
            const tagProps = getOrderStatusTagProps(
                record.status,
                record.order_type,
                record.cancel_initiator,
            )
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: 70,
        render: (_, record) =>
            isSecondaryOrder(record) ? (
                <SecondaryOrderActionButton
                    order={record}
                    actionCancel={props.actionSecondaryCancel}
                />
            ) : null,
    },
]
