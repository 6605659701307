// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".value_LcZBs {\n  display: flex !important;\n  justify-content: flex-end;\n  align-items: flex-end;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  text-align: right;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OwnershipTransfer/components/DfasInfoCard/dfaInfoCard.m.less"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,yBAAA;EACA,qBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".value {\n    display: flex !important;\n    justify-content: flex-end;\n    align-items: flex-end;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    text-align: right;\n    color: #303440;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": "value_LcZBs"
};
export default ___CSS_LOADER_EXPORT___;
