import { isAllowHelper } from './rbacHelper'

export class RBAC {
    #scopes: string

    constructor(scopes: string) {
        this.#scopes = scopes
        this.isAllow = this.isAllow.bind(this)
    }

    isAllow(scope: string[]) {
        return isAllowHelper(this.#scopes, scope)
    }
}
