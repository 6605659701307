// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerBold_RiTRl {\n  padding: 0;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 16px;\n}\n.grayBlock_fZFZh {\n  padding: 24px;\n  background: #f8f8fa;\n  border: 1px solid #ebecf0;\n  border-radius: 16px;\n}\n.label_MteHP {\n  display: block;\n  margin: 0 0 16px 0;\n  color: #303440;\n}\n.link_Qxgts {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/components/Footer/modals/style.m.less"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAIA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAFJ;AAKA;EACI,cAAA;EACA,kBAAA;EACA,cAAA;AAHJ;AAOA;EACI,gBAAA;AALJ","sourcesContent":["@import 'style/palette';\n\n.headerBold {\n    padding: 0;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n}\n\n.grayBlock {\n    padding: 24px;\n    background: @gray-2;\n    border: 1px solid @gray-4;\n    border-radius: 16px;\n}\n\n.label {\n    display: block;\n    margin: 0 0 16px 0;\n    color: @gray-10;\n}\n\n\n.link {\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBold": "headerBold_RiTRl",
	"grayBlock": "grayBlock_fZFZh",
	"label": "label_MteHP",
	"link": "link_Qxgts"
};
export default ___CSS_LOADER_EXPORT___;
