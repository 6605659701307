import { contactValidators } from '../../../utils'
import { WrappedInput, WrappedInputProps } from '../Input'

type ComponentProps = Omit<WrappedInputProps, 'name' | 'maxLength' | 'minLength' | 'multiline'> & {
    name?: WrappedInputProps['name']
}

export const EmailInput = ({ className, label, name, placeholder, ...rest }: ComponentProps) => {
    return (
        <WrappedInput
            {...rest}
            className={className ?? 'formComponentItem'}
            label={label ?? 'Электронная почта'}
            name={name ?? 'email'}
            placeholder={placeholder ?? ''}
            rules={[{ validator: contactValidators.email }]}
        />
    )
}
