import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const ExportSmall: FC<IconProps> = ({ ...props }) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M9 7L13 3L9 7Z" fill="currentColor" />
                <path
                    d="M9 7L13 3"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13 6V3H10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.2 4H6.8C4.8 4 4 4.8 4 6.8V9.2C4 11.2 4.8 12 6.8 12H9.2C11.2 12 12 11.2 12 9.2V8.8"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
