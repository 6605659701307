import {
    EmissionOrderStatus,
    OrderTypeEnum,
    SecondaryOrderStatus,
    TransferOrderStatus,
    UnionOrderStatus,
} from '@dltru/dfa-models'

type OrderStatusDetail = { color: string; title: string; name: string }

const emissionTranslates = {
    [EmissionOrderStatus.in_progress]: {
        color: 'default',
        title: 'Размещается',
        name: 'in_progress',
    },
    [EmissionOrderStatus.draft]: { color: 'default', title: 'Новая', name: 'draft' },
    [EmissionOrderStatus.accepted]: { color: 'default', title: 'Активна', name: 'accepted' },
    [EmissionOrderStatus.executed]: { color: 'success', title: 'Исполнена', name: 'executed' },
    [EmissionOrderStatus.refused]: { color: 'error', title: 'Отказано', name: 'refused' },
    [EmissionOrderStatus.revoked]: { color: 'error', title: 'Отменена', name: 'revoked' },
    [EmissionOrderStatus.not_executed]: {
        color: 'error',
        title: 'Не исполнена',
        name: 'not_executed',
    },
    [EmissionOrderStatus.waiting_for_emitter_accept]: {
        color: 'default',
        title: 'Ожидает подтверждения',
        name: 'waiting_for_emitter_accept',
    },
    [EmissionOrderStatus.accepted_by_emitter]: {
        color: 'success',
        title: 'Подтверждена эмитентом',
        name: 'accepted_by_emitter',
    },
    [EmissionOrderStatus.refused_by_emitter]: {
        color: 'error',
        title: 'Отклонена эмитентом',
        name: 'refused_by_emitter',
    },
} as const
const secondaryTranslates = {
    [SecondaryOrderStatus.in_progress]: {
        color: 'default',
        title: 'Размещается',
        name: 'in_progress',
    },
    [SecondaryOrderStatus.draft]: { color: 'default', title: 'Новая', name: 'draft' },
    [SecondaryOrderStatus.executed]: { color: 'success', title: 'Исполнена', name: 'executed' },
    [SecondaryOrderStatus.partly_executed]: {
        color: 'success',
        title: 'Частично исполнена',
        name: 'partly_executed',
    },
    [SecondaryOrderStatus.accepted]: { color: 'default', title: 'Активна', name: 'accepted' },
    [SecondaryOrderStatus.revoked]: { color: 'error', title: 'Отменена', name: 'revoked' },
    [SecondaryOrderStatus.canceled]: { color: 'error', title: 'Отменена', name: 'canceled' },
    [SecondaryOrderStatus.rejected]: { color: 'error', title: 'Отказано', name: 'rejected' },
    [SecondaryOrderStatus.refused]: { color: 'error', title: 'Отказано', name: 'refused' },
    [SecondaryOrderStatus.canceled_by_system]: {
        color: 'error',
        title: 'Отменено',
        name: 'refused',
    },
    [SecondaryOrderStatus.not_executed]: {
        color: 'error',
        title: 'Не исполнена',
        name: 'not_executed',
    },
} as const

const transferTranslates = (status: TransferOrderStatus, additionalInfo?: string) => {
    const statusesTranslate = {
        [TransferOrderStatus.accepted]: {
            color: 'processing',
            title: 'Акцептована',
            name: 'accepted',
        },
        [TransferOrderStatus.draft]: { color: 'default', title: 'Черновик', name: 'draft' },
        [TransferOrderStatus.executed]: { color: 'success', title: 'Исполнена', name: 'executed' },
        [TransferOrderStatus.placed]: { color: 'default', title: 'Размещена', name: 'placed' },
        [TransferOrderStatus.ready]: {
            color: 'default',
            title: 'Готова к исполнению',
            name: 'ready',
        },
        [TransferOrderStatus.canceled]: { color: 'default', title: 'Срок истек', name: 'canceled' },
        [TransferOrderStatus.in_progress]: {
            color: 'default',
            title: 'Размещается',
            name: 'in_progress',
        },
    }
    if (status === TransferOrderStatus.canceled) {
        let title = ''
        switch (additionalInfo) {
            case 'offeror':
                title = 'Отозвана'
                break
            case 'system':
                title = 'Отменена системой'
                break
            case 'expired':
                title = 'Не акцептована'
                break
            default:
                title = 'Отменена'
        }
        return { color: 'default', title, name: 'canceled' }
    }
    return statusesTranslate[status]
}

const getOrderStatus = (
    type: OrderTypeEnum | '',
    status: UnionOrderStatus,
    additionalInfo?: string,
) => {
    if (!status) {
        return undefined
    }
    switch (type) {
        case OrderTypeEnum.secondary:
        case OrderTypeEnum.buy:
        case OrderTypeEnum.sell:
            return secondaryTranslates[status as SecondaryOrderStatus]
        case OrderTypeEnum.transfer:
        case OrderTypeEnum.transfer_buy:
        case OrderTypeEnum.transfer_sell:
            return transferTranslates(status as TransferOrderStatus, additionalInfo)
        default:
            return emissionTranslates[status as EmissionOrderStatus]
    }
}

export const getOrderStatusTagProps = (
    status: SecondaryOrderStatus | EmissionOrderStatus | TransferOrderStatus | '',
    type: OrderTypeEnum | '',
    additionalInfo?: string,
): OrderStatusDetail =>
    getOrderStatus(type, status as UnionOrderStatus, additionalInfo) ?? {
        color: 'default',
        title: status,
        name: status,
    }
