import { FC } from 'react'

import style from './style.m.less'

export interface IInfoWidget {
    label: string
    value: string
    special?: string
}

const InfoWidget: FC<IInfoWidget> = ({ label, special, value }) => {
    return (
        <div className={style.infoWidget}>
            <div className={style.infoWidgetLabel}>{label}</div>
            <div className={style.infoWidgetValue}>
                {value}
                {special && <span className={style.special}>{special}</span>}
            </div>
        </div>
    )
}

export default InfoWidget
