import {
    UneditableFileComponent,
    UneditableUploadedFilesComponent,
    UploadInputComponent,
    UploadInputComponentProps,
} from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

interface IComponentProps {
    uid?: string
}
export const UneditableFile: FC<IComponentProps> = ({ uid }) => (
    <UneditableFileComponent uid={uid} getBase64File={api.lib.getBase64FileService} />
)

export const UneditableUploadedFiles: FC<{ files?: { uid: string }[] }> = ({ files = [] }) => (
    <UneditableUploadedFilesComponent files={files} getBase64File={api.lib.getBase64FileService} />
)

export type UploadInputProps = Omit<UploadInputComponentProps, 'storeBase64File' | 'getBase64File'>
export const UploadInput: FC<UploadInputProps> = (props) => (
    <UploadInputComponent
        {...props}
        getBase64File={api.lib.getBase64FileService}
        storeBase64File={api.lib.storeBase64FileService}
    />
)
