import { Box, Button, Modal } from '@dltru/dfa-ui'
import { FC } from 'react'

import { ClientWithStatusBox } from '@components/ClientWithStatusBox/ClientWithStatusBox'

import style from './changeStatusModalContainer.m.less'

type ChangeOperatorStatusModalProps = {
    title: string
    submitButtonLabel: string
    reasonTitle: string
    status: string
    isShow: boolean
    onCancel: () => void
    onSubmit: (value: unknown) => void
}

export const ChangeStatusModalContainer: FC<ChangeOperatorStatusModalProps> = ({
    children,
    ...props
}) => {
    const { title, submitButtonLabel, status, isShow, onCancel, onSubmit } = props

    return (
        <Modal
            visible={isShow}
            width={522}
            onCancel={onCancel}
            title={title}
            wrapClassName={style.modal}
        >
            <Box margin={[0, 0, 24, 0]}>
                <ClientWithStatusBox status={status} />
            </Box>
            {children}

            <div className={style.footer}>
                <Button borderRadius={16} onClick={onCancel}>
                    Отмена
                </Button>
                <Button borderRadius={16} type="primary" onClick={onSubmit}>
                    {submitButtonLabel}
                </Button>
            </div>
        </Modal>
    )
}
