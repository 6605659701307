import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

interface IDfaIcon {
    width?: string
    height?: string
    fill?: string
}

export const CaseAdd: FC<IconProps & IDfaIcon> = ({
    width = '14px',
    height = '14px',
    ...props
}) => (
    <AntIcon
        component={() => (
            <svg
                width={width}
                height={height}
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.10256 2.77143C6.10256 2.34538 6.44696 2 6.8718 2H17.1282C17.553 2 17.8974 2.34538 17.8974 2.77143V5.08571H21.2308C21.6556 5.08571 22 5.43109 22 5.85714V12.5C22 12.7746 21.7786 12.9975 21.5046 13H20.9569C20.6829 12.9975 20.4615 12.7746 20.4615 12.5V9.00269C19.309 10.474 17.4881 11.8964 14.8205 12.4988V14.0857C14.8205 14.5118 14.4761 14.8571 14.0512 14.8571H9.94867C9.52384 14.8571 9.17944 14.5118 9.17944 14.0857V12.4991C6.51211 11.897 4.69126 10.4753 3.53846 9.0043V18.4571L14.5019 18.4982C14.7773 18.4992 15 18.7227 15 18.9982V19.5C15 19.7762 14.7767 20 14.5006 20L2.76923 20C2.3444 20 2 19.6546 2 19.2286V5.85714C2 5.43109 2.3444 5.08571 2.76923 5.08571H6.10256V2.77143ZM10.7179 12.7414V13.3143H13.282V12.7413C12.8713 12.7798 12.4439 12.8 11.9994 12.8C11.5553 12.8 11.1282 12.7798 10.7179 12.7414ZM16.359 3.54286V5.08571H7.64103V3.54286H16.359ZM3.86408 6.62857C4.76099 8.54601 7.15836 11.2572 11.9994 11.2572C16.8404 11.2572 19.2378 8.54601 20.1347 6.62857H3.86408ZM19.3 17.3V15.1775C19.3 15.08 19.38 15 19.475 15H20.525C20.6225 15 20.7 15.08 20.7 15.1775V17.3H22.8125C22.915 17.3 23 17.3775 23 17.475V18.525C23 18.62 22.915 18.7 22.8125 18.7H20.7V20.8225C20.7 20.92 20.6225 21 20.525 21H19.475C19.38 21 19.3 20.92 19.3 20.8225V18.7H17.1875C17.085 18.7 17 18.62 17 18.525V17.475C17 17.3775 17.085 17.3 17.1875 17.3H19.3Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
