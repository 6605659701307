import { IDfaEmitterInfo } from '@dltru/dfa-models'
import { FC } from 'react'

import { Col } from '../../../Col'
import { InfoCard } from './components/InfoCard'
import { dataForInfoCard } from './helpers'
import { dataSRR } from './resources'

interface IComponentProps {
    data?: IDfaEmitterInfo | null
}

export const InfoCardsDfa: FC<IComponentProps> = ({ data }) => {
    return (
        <>
            <Col span={12}>
                <InfoCard title="Лицо, выпускающее ЦФА" {...dataForInfoCard(data)} />
            </Col>
            <Col span={12}>
                <InfoCard title="Оператор информационной системы" {...dataSRR} />
            </Col>
        </>
    )
}
