import { IRepaymentDeal } from '@dltru/dfa-models'
import { BodyTitle, DateTime, Divider, LabelRow as Row } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { Link } from 'react-router-dom'

import { BaseModal } from './Base'
import { CommonInfoBlock } from './CommonInfoBlock'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: IRepaymentDeal
}

const RepaymentModal: FC<IDealViewModal> = ({ deal, onResolve }) => {
    return (
        <BaseModal deal={deal} onResolve={onResolve}>
            <BodyTitle title="Детали погашения" id={deal.id} />
            <Row label="Дата и время погашения" className="label-row__space">
                <DateTime value={deal.executed_at} />
            </Row>
            <Row label="Эмитент" className="label-row__space">
                <Link to={`/clients/${deal.emitter_id}`}>{deal.emitter_full_name}</Link>
            </Row>
            <Row label="Владелец" className="label-row__space">
                <Link to={`/clients/${deal.user_id}`}>{deal.user_full_name}</Link>
            </Row>
            <Divider />
            <CommonInfoBlock deal={deal} isRepayment />
            {/* TODO комисии буду позже */}
            {/*
            <Divider />
            <Row label="Комиссии" />
            <Row label="Услуги оператора ИС по погашению (Эмитент)"><Price /></Row>*/}
        </BaseModal>
    )
}

export const repaymentModal = create(RepaymentModal)
