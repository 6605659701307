import {
    IRequest,
    IResetEmailInitParams,
    SignTransactionFullType,
    UserTerminateByUserType,
    UserTerminateInitType,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod, ResponseItem } from '../../types/types'

type ChangeAuthAdminResponse = IRequest<ResponseItem<{ phone_last_four_digits: string }>>
export async function changePhoneInitAdmin(user_uuid: string): Promise<ChangeAuthAdminResponse> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangePhoneInitAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}
export async function changePasswordInitAdmin(user_uuid: string): Promise<ChangeAuthAdminResponse> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangePasswordInitAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}
export async function changeLoginInitAdmin(user_uuid: string): Promise<ChangeAuthAdminResponse> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeLoginInitAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: { user_uuid } },
    )
}

export async function changeEmailInitAdmin(
    data: IResetEmailInitParams,
): Promise<ChangeAuthAdminResponse> {
    return requestDecorator.call(
        this,
        this.paths.UsersChangeEmailInitAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function terminationByClient(
    data: UserTerminateByUserType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersTerminationByClient,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}

export async function terminationByAdmin(
    data: UserTerminateInitType,
): Promise<IRequest<ResponseItem<SignTransactionFullType>>> {
    return requestDecorator.call(
        this,
        this.paths.UsersTerminationByAdmin,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
