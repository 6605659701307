import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const RecieptDiscountGreen: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="16" fill="#52C41A" />
                <path
                    d="M12.4866 21.1333C13.0333 20.5467 13.8666 20.5933 14.3466 21.2333L15.02 22.1333C15.56 22.8467 16.4333 22.8467 16.9733 22.1333L17.6466 21.2333C18.1266 20.5933 18.96 20.5467 19.5066 21.1333C20.6933 22.4 21.66 21.98 21.66 20.2067V12.6933C21.6666 10.0067 21.04 9.33334 18.52 9.33334H13.48C10.96 9.33334 10.3333 10.0067 10.3333 12.6933V20.2C10.3333 21.98 11.3066 22.3933 12.4866 21.1333Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 16.6667L18 12.6667"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.9963 16.6667H18.0023"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.9963 13H14.0023"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
