import { QualifyInfoType } from '@dltru/dfa-models'
import { FC } from 'react'

import { Box } from '../../../Box'
import { Divider } from '../../../Divider'
import { Progress } from '../../../Progress'
import { NO_QUALIFY_LIMIT } from '../../../constants'
import { getRublesFromPennies } from '../../../utils'
import styles from './styles.m.less'

export interface IQualifiedDetailsProps {
    qualifyInfo?: QualifyInfoType
    isAdmin?: boolean
}
export const QualifiedDetails: FC<IQualifiedDetailsProps> = ({ qualifyInfo, isAdmin }) => {
    const percent = qualifyInfo?.quota ? qualifyInfo?.quota / NO_QUALIFY_LIMIT : 0

    return (
        <Box direction="column" justify="right" className={styles.text}>
            {!isAdmin && (
                <Box margin={[8, 0, 24, 0]}>
                    Как неквалифицированный инвестор Вы можете проводить операци <br /> с ЦФА на
                    общую сумму сделок не более {NO_QUALIFY_LIMIT.toLocaleString()} рублей в год
                </Box>
            )}
            <Box align="end">
                <span className={styles.textBold}>
                    осталось {getRublesFromPennies(qualifyInfo?.quota)}
                    <span className={styles.textSecondary}> ₽</span>
                </span>
                <Progress
                    percent={percent}
                    showInfo={false}
                    className={styles.progress}
                    strokeWidth={4}
                />
                <span className={styles.textSecondary}>
                    из {NO_QUALIFY_LIMIT.toLocaleString()} ₽
                </span>
            </Box>
            {!isAdmin && (
                <>
                    <Divider />
                    <div className="ant-modal-title">
                        Присвоение статуса квалифицированного инвестора
                    </div>
                    <Box margin={[16, 0, 0, 0]}>
                        Вы можете подать заявку на присвоение статуса квалифицированного инвестора.
                        Порядок принятия решения о признании лица квалифицированным инвестором
                        утверждён Регламентом. <br /> За данную операцию взимается комиссия в
                        соответствии с тарифами.
                    </Box>
                </>
            )}
        </Box>
    )
}
