import { CertificateType } from '@dltru/dfa-models'
import { FC, useState } from 'react'

import { DeleteOutlined } from '../../..'
import { Space } from '../../../Space'
import { Button } from '../../../button'
import { CertificateButton } from '../CertificateButton'
import { CertificateDetailsModal } from './CertificateDetailsModal'
import { CertificateRemoveModal } from './CertificateRemoveModal'
import style from './style.m.less'

interface IComponentProps {
    certificate: CertificateType
    removeCertificateAction: (uuid: string) => void
}

export const CertificatesListItem: FC<IComponentProps> = ({
    certificate,
    removeCertificateAction,
}) => {
    const [isModalDetailsVisible, setIsModalDetailsVisible] = useState(false)
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState(false)
    return (
        <div>
            <Space>
                <CertificateButton
                    onClick={() => setIsModalDetailsVisible(true)}
                    certificate={certificate}
                />
                <Button
                    size="small"
                    type="ghost"
                    icon={<DeleteOutlined />}
                    className={style.noBorderButton}
                    onClick={() => setIsModalRemoveVisible(true)}
                />
            </Space>
            <CertificateDetailsModal
                isModalVisible={isModalDetailsVisible}
                setIsModalVisible={setIsModalDetailsVisible}
                certificate={certificate}
            />
            <CertificateRemoveModal
                isModalVisible={isModalRemoveVisible}
                setIsModalVisible={setIsModalRemoveVisible}
                certificate={certificate}
                removeCertificateAction={removeCertificateAction}
            />
        </div>
    )
}
