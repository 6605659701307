import { IEmployee, userRolesTranslate } from '@dltru/dfa-models'
import { DataProviderResponse, Filter, FilterType, SelectData } from '@dltru/dfa-ui'
import { FC } from 'react'

import api from '@services/api'

const archiveOptions: SelectData[] = [
    { value: 'Активный', label: 'Активный' },
    { value: 'В архиве', label: 'В архиве' },
]

const EmployeeOption: FC<{ data?: DataProviderResponse & IEmployee }> = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <div>
            <p aria-label={data.label}>{data.label} </p>
            <span>
                {data.role ? userRolesTranslate[data.role as IEmployee['role']] : data.role}
            </span>
            <span>{data.email}</span>
        </div>
    )
}

const autoGetEmployeeComplete = async (value?: unknown): Promise<DataProviderResponse[]> => {
    try {
        const { data } = await api.lib.getEmployees({
            full_name: `like(${value})`,
        })
        return data?.items?.map((item) => ({
            ...item,
            label: item.full_name,
            value: item.user_uuid,
        })) as DataProviderResponse[]
    } catch (error) {
        return []
    }
}

export const filters: Filter[] = [
    {
        label: 'Дата выпуска',
        name: 'issue_date',
        filterType: FilterType.DATE_RANGE,
    },
    {
        label: 'Дата погашения',
        name: 'redeem_date',
        filterType: FilterType.DATE_RANGE,
        divider: true,
    },

    {
        label: 'Эмитент',
        name: 'emitter_full_name',
        filterType: FilterType.AUTOCOMPLETE,
        dataProvider: autoGetEmployeeComplete,
        renderElement: <EmployeeOption />,
    },

    {
        label: 'Статус',
        name: 'status',
        filterType: FilterType.SELECT,
        options: archiveOptions,
    },
]
