import React, { FC } from 'react'

import { RBAC } from '../rbac/RBAC'

export interface IRbacContext {
    isAllow: (scope: string[]) => boolean
}

export const RbacContext = React.createContext<IRbacContext>({ isAllow: () => true })

export const RbacContextProvider: FC<{ scopes: string }> = ({ scopes, children }) => {
    const rbac = new RBAC(scopes)

    return (
        <RbacContext.Provider
            value={{
                isAllow: rbac.isAllow,
            }}
        >
            {children}
        </RbacContext.Provider>
    )
}
