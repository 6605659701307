import { Alert, Box, CollapseDown, Space, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { employeeSelector } from '@store/employee/selectors'

import { UneditableUploadedFiles } from '@components/Form'

import styles from './styles.m.less'

export const AlertEmployeeBlock: FC = () => {
    const bockingEvent = useSelector(employeeSelector.selectBlockingEvent)

    if (!bockingEvent) {
        return null
    }

    const description = (
        <Box margin={[4, 0, 0, 0]}>
            <CollapseDown>
                <Space direction="vertical" size={4}>
                    {bockingEvent.created_at && (
                        <span className={styles.grayText}>
                            {dayjs.unix(bockingEvent.created_at).format(VIEW_DATE_FORMAT)}
                        </span>
                    )}
                    {Boolean(bockingEvent.block_reason) && <span>{bockingEvent.block_reason}</span>}
                    {bockingEvent.block_link_uuid?.length > 0 && (
                        <div>
                            <span>Документы-основания:</span>
                            {bockingEvent.block_link_uuid.map((uid) => (
                                <UneditableUploadedFiles files={[{ uid }]} />
                            ))}
                        </div>
                    )}
                </Space>
            </CollapseDown>
        </Box>
    )
    return (
        <Alert
            message="Пользователь заблокирован"
            description={description}
            type="error"
            showIcon
        />
    )
}
