import { frontUrl } from '@constants/frontUrl'
import {
    IApprovalHistoryItem,
    IApprovalHistoryUI,
    IBusinessmanProfile,
    IBusinessmanProfilePublic,
    IIndividualProfile,
    IIndividualProfilePublic,
    ILegalEntityProfile,
    ILegalEntityProfilePublic,
    ProfileRequestType,
    ProfileStatus,
    ProfileType,
} from '@dltru/dfa-models'

export const getQuestionnaireUrlByProfileRequestType = {
    [ProfileRequestType.INDIVIDUAL]: frontUrl.questionnaireIndividual,
    [ProfileRequestType.LEGAL_ENTITY]: frontUrl.questionnaireLegalEntity,
    [ProfileRequestType.BUSINESSMAN]: frontUrl.questionnaireBusinessman,
}

export const getQuestionnaireUrlByProfileType = (profileTypes: ProfileType[]) => {
    if (profileTypes.includes(ProfileType.LEGL)) {
        return `/questionnaire/${frontUrl.questionnaireLegalEntity}`
    }
    if (profileTypes.includes(ProfileType.BUSN)) {
        return `/questionnaire/${frontUrl.questionnaireBusinessman}`
    }
    return `/questionnaire/${frontUrl.questionnaireIndividual}`
}

export const getUserFullNameByType = (
    profileType: ProfileType | undefined,
    profile:
        | IIndividualProfile
        | IBusinessmanProfile
        | ILegalEntityProfile
        | IIndividualProfilePublic
        | IBusinessmanProfilePublic
        | ILegalEntityProfilePublic,
) => {
    switch (profileType) {
        case ProfileType.PRSN: {
            const fioData =
                'person' in profile ? profile.person : (profile as IIndividualProfilePublic)
            return `${fioData.last_name} ${fioData.first_name} ${fioData.second_name}`
        }
        case ProfileType.BUSN: {
            const fioData =
                'person' in profile ? profile.person : (profile as IBusinessmanProfilePublic)
            return `ИП ${fioData.last_name} ${fioData.first_name} ${fioData.second_name}`
        }
        case ProfileType.LEGL: {
            return `${(profile as ILegalEntityProfile).legal_form} ${
                (profile as ILegalEntityProfile).title
            }`
        }
        default:
            return null
    }
}

export const checkIsLawyerOfHistory = (history: IApprovalHistoryItem[] = []) => {
    for (let i = history.length - 1; i >= 0; i--) {
        if (history[i].profile_status === ProfileStatus.LAWYER_APPROVAL) {
            return true
        }
        if (history[i].profile_status === ProfileStatus.NEW) {
            return false
        }
    }
    return false
}
const getNameOperator = (item?: IApprovalHistoryItem) => {
    if (!item) {
        return '--'
    }
    return `${item.full_name_employee.last_name ? item.full_name_employee.last_name : ''} ${
        item.full_name_employee.first_name ? item.full_name_employee.first_name : ''
    } ${item.full_name_employee.second_name ? item.full_name_employee.second_name : ''}`
}

const getResultFromNextState = (nextStatus: ProfileStatus, currentStatus: ProfileStatus) => {
    switch (nextStatus) {
        case ProfileStatus.LAWYER_APPROVAL: {
            return currentStatus === ProfileStatus.PODFT_APPROVAL
                ? { type: 'warning', title: 'Требуется правовая экспертиза' }
                : { type: 'success', title: 'Проверка пройдена' }
        }
        case ProfileStatus.REWORK: {
            return { type: 'warning', title: 'На доработку' }
        }
        case ProfileStatus.ARCHIVED:
        case ProfileStatus.REJECTED: {
            return currentStatus === ProfileStatus.IDENTIFICATION
                ? { type: 'error', title: 'Идентификация не пройдена' }
                : { type: 'error', title: 'Заявка отклонена' }
        }
        default:
            return { type: 'success', title: 'Проверка пройдена' }
    }
}
const getNameState = (currentStatus: ProfileStatus) => {
    switch (currentStatus) {
        case ProfileStatus.OPERATOR_APPROVAL: {
            return 'Проверка операционистом'
        }
        case ProfileStatus.LAWYER_APPROVAL: {
            return 'Проверка юристом'
        }
        case ProfileStatus.PODFT_APPROVAL: {
            return 'Проверка по ПОД/ФТ'
        }
        case ProfileStatus.IDENTIFICATION: {
            return 'Идентификация пользователя'
        }

        default:
            return '--'
    }
}
const getCommentWho = (currentStatus: ProfileStatus) => {
    switch (currentStatus) {
        case ProfileStatus.IDENTIFICATION:
        case ProfileStatus.OPERATOR_APPROVAL: {
            return 'операциониста'
        }
        case ProfileStatus.LAWYER_APPROVAL: {
            return 'юриста'
        }
        case ProfileStatus.PODFT_APPROVAL: {
            return 'специалиста ПОД/ФТ'
        }
        default:
            return ''
    }
}
const prepareApprovalHistory = (
    item: IApprovalHistoryItem,
    all: IApprovalHistoryItem[],
    index: number,
) => {
    const next = index < all.length - 1 ? all[index + 1] : undefined
    switch (item.profile_status) {
        case ProfileStatus.OPERATOR_APPROVAL:
        case ProfileStatus.LAWYER_APPROVAL:
        case ProfileStatus.PODFT_APPROVAL:
        case ProfileStatus.IDENTIFICATION: {
            return {
                ...item,
                comment: next?.comment || '',
                start_datetime: item.approval_datetime,
                approval_datetime: next?.approval_datetime,
                name_operator: getNameOperator(next),
                stage: getNameState(item.profile_status),
                comment_who: getCommentWho(item.profile_status),
                state: next
                    ? getResultFromNextState(next.profile_status, item.profile_status)
                    : null,
            }
        }
        default: {
            return undefined
        }
    }
}
export const getDataForApprovalHistory = (
    clientHistory: IApprovalHistoryItem[],
    agentHistory?: IApprovalHistoryItem[],
) => {
    const result: IApprovalHistoryUI[] = []
    clientHistory?.forEach((item, index) => {
        const prepareItem = prepareApprovalHistory(item, clientHistory, index)
        if (prepareItem) {
            result.push(prepareItem)
        }
    })
    agentHistory?.forEach((item, index) => {
        const prepareItem = prepareApprovalHistory(item, agentHistory, index)
        if (prepareItem) {
            result.push(prepareItem)
        }
    })

    return result.sort((a, b) => a.start_datetime - b.start_datetime)
}
