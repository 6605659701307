import { IEmissionOrder } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dropDfaDetails, updateOrderDetails } from '@store/orders/details'
import { loadOrdersByDfaId } from '@store/orders/list'
import IAppState from '@store/states'

import { EmissionOrderDetailsModal } from '@components/Modals/Orders'

import { gridConfig } from './gridConfig'

export const MyOrdersTable: FC = () => {
    const reduxDispatch = useDispatch()
    const { data, isLoading } = useSelector((store: IAppState) => store.orderList)
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<IEmissionOrder>()

    useEffect(() => {
        reduxDispatch(loadOrdersByDfaId())
        return () => {
            reduxDispatch(dropDfaDetails())
        }
    }, [])

    const onRow = (order: IEmissionOrder) => ({
        onClick: () => {
            reduxDispatch(updateOrderDetails(order))
            setCurrentOrder(order)
            setIsCardModalVisible(true)
        },
    })

    return (
        <>
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={data as IEmissionOrder[]}
                onRow={onRow}
                clickableRow
            />
            <EmissionOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={currentOrder}
            />
        </>
    )
}
