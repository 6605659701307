import {
    AddressForm,
    FioForm,
    Form,
    IRoleFormStepProps,
    PersonalForm,
    SnilsInput,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { InnInput } from '@components/Form'
import { DocumentForm } from '@components/Form/components/DocumentForm'

const STEP_INDEX = 0

export const MainInfo: FC<IRoleFormStepProps> = ({ isEditAllow, disableFields = [] }) => {
    const { editable, stepsTotal, currentStep } = useContext(StepperContext)
    const [form] = Form.useForm()
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)

    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Основные сведения"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FioForm uneditable={isEdit} />
            <PersonalForm uneditable={isEdit} disableFields={disableFields} />
            <AddressForm
                uneditable={isEdit}
                addressTitle="Место жительства (адрес регистрации по паспорту)"
                group="main_address"
            />
            <DocumentForm uneditable={isEdit} />
            <InnInput uneditable={isEdit} disabled={disableFields.includes('inn')} />
            <SnilsInput uneditable={isEdit} disabled={disableFields.includes('snils')} />
        </Step>
    )
}
