import { TransactionRow, TransactionStatuses } from '@dltru/dfa-models'
import classNames from 'classnames/bind'
import { FC } from 'react'

import { getFactorialPart, getIntegerPart } from '../../../utils'
import { getIconByType, getStatusIcon, transactionDirection } from './helper'
import style from './style.m.less'

const cn = classNames.bind(style)

const DEFAULT_SYMBOL = '₽'

type ComponentProps = {
    onClick: (id: string) => void
    symbol?: string
} & TransactionRow

export const BalanceRow: FC<ComponentProps> = ({
    title,
    subtitle,
    total,
    type,
    status,
    id,
    onClick,
    symbol = DEFAULT_SYMBOL,
}) => {
    const Icon = getIconByType(type)
    const StatusIcon = getStatusIcon(status)

    const isGreen = transactionDirection(type)

    return (
        <div className={cn(style.blRow, style.clickable)} onClick={() => onClick(id)}>
            <div className={style.blLeftWrapper}>
                <div className={style.blRowIcon}>
                    <Icon />
                </div>
                <div className={style.blRowTitleWrapper}>
                    <div className={style.blRowTitle}>{title}</div>
                    {subtitle && <div className={style.blRowSubTitle}>{subtitle}</div>}
                </div>
            </div>
            <div className={style.blRightWrapper}>
                <div className={style.blRowTotal}>
                    {StatusIcon && (
                        <StatusIcon
                            className={cn(style.statusIcon, {
                                danger: status === TransactionStatuses.canceled,
                            })}
                        />
                    )}
                    <span className={cn(style.integer, { green: isGreen })}>
                        {getIntegerPart(total, true)}
                    </span>
                    <span className={cn(style.cents, { green: isGreen })}>
                        {getFactorialPart(total, true)}
                    </span>
                    <span className={style.cents}>{` ${symbol}`}</span>
                </div>
            </div>
        </div>
    )
}
