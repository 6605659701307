import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const UserIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.99967 6.99935C8.47243 6.99935 9.66634 5.80544 9.66634 4.33268C9.66634 2.85992 8.47243 1.66602 6.99967 1.66602C5.52692 1.66602 4.33301 2.85992 4.33301 4.33268C4.33301 5.80544 5.52692 6.99935 6.99967 6.99935Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5806 12.3329C11.5806 10.2689 9.5273 8.59961 6.9993 8.59961C4.4713 8.59961 2.41797 10.2689 2.41797 12.3329"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
        {...props}
    />
)
