import dayjs from 'dayjs'
import moment, { Moment } from 'moment-timezone'

const TZ = 'Europe/Moscow'
const RUS_DATE_FORMAT = 'DD.MM.YYYY'
export const RUS_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const RUS_DATETIME_SEC_FORMAT = 'DD.MM.YYYY HH:mm:ss'

export { moment, Moment }

export function unixToDateString(timestamp: number): string {
    return timestamp ? moment.unix(timestamp).tz(TZ).format(RUS_DATE_FORMAT) : ''
}

export function dateToDateString(dateTime: string): string {
    return dateTime ? moment(dateTime).tz(TZ).format(RUS_DATE_FORMAT) : ''
}

export function unixToDateTimeString(timestamp: number): string {
    return timestamp ? moment.unix(timestamp).tz(TZ).format(RUS_DATETIME_FORMAT) : ''
}

export function unixToDateTimeSecString(timestamp: number): string {
    return timestamp ? moment.unix(timestamp).tz(TZ).format(RUS_DATETIME_SEC_FORMAT) : ''
}

export function fromUnix(value: number): Moment | undefined {
    return value === undefined || Number.isNaN(value) ? undefined : moment.unix(value).tz(TZ)
}

export function toRusWeekDay(value: number): string {
    const weekday = moment(value * 1000).format('dd')
    const firstLetter = weekday.slice(0, 1)

    return weekday ? firstLetter.toUpperCase() + weekday.slice(1) : ''
}

export function now(): Moment {
    return moment()
}

export function startOfToday(): number {
    return moment().tz(TZ).startOf('day').unix()
}

export function toDate(value: string) {
    return moment(value).format(RUS_DATE_FORMAT)
}

export function toDateTime(value: string) {
    return moment(value).format(RUS_DATETIME_FORMAT)
}

export function getDateNowPlusYearInUnix() {
    return moment().year(1).unix()
}

export function dateToUnix(date: Date | string) {
    return moment(date).unix()
}
export const addPrevZero = (num: number) => (num < 10 ? `0${num}` : num)
export const getTimeFromSeconds = (seconds: number) => {
    const totalHours = ~~(seconds / (60 * 60))
    const totalMinutes = ~~((seconds % (60 * 60)) / 60)
    const totalSeconds = seconds % 60
    return `${addPrevZero(totalHours)}:${addPrevZero(totalMinutes)}:${addPrevZero(totalSeconds)}`
}

export const getDateHelper = (value?: string | number): string => {
    if (!value) {
        return '---'
    }

    return dayjs(value).format('DD.MM.YYYY')
}
