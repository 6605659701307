import cn from 'classnames'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

import { Checkbox, CheckboxChangeEvent } from '../../../Checkbox'
import { Space } from '../../../Space'
import { IValues, StepperContext } from '../../../Stepper'
import { CardDivider } from '../../../card'
import { WrappedInput, WrappedInputProps } from '../../../components/Form'
import { AddressDetails } from './AddressDetails'
import './style.less'
import {
    getCleanedAddress,
    getPartPrefixAttribute,
    setAddressDetails,
    setForBothContexts,
} from './utils'

export type AddressFormProps = Partial<WrappedInputProps> & {
    uneditable: boolean
    addressTitle?: string
    group?: string
    noActualAddressCheckbox?: boolean
    noRegistrationAddressCheckbox?: boolean
    noBottomDivider?: boolean
    isLegalAddress?: boolean
    isPostAddressRequired?: boolean
}

export const AddressForm: FC<AddressFormProps> = ({
    uneditable,
    addressTitle = 'Место регистрации',
    group,
    noActualAddressCheckbox = false,
    noRegistrationAddressCheckbox = false,
    noBottomDivider = false,
    isLegalAddress,
    fieldKey,
    listName,
    isPostAddressRequired,
    ...restProps
}) => {
    const { values, mergeValuesIntoState } = useContext(StepperContext)
    const { setFieldsValue, getFieldValue } = useContext(FieldContext)
    const [isFactSameAsRegistration, setIsFactSameAsRegistration] = useState(true)
    const [isFactSame, setIsFactSame] = useState(true)
    const [isEmptyPostAddress, setIsEmptyPostAddress] = useState(false)

    const _group = getPartPrefixAttribute(group)

    const setUneditable = (values: IValues | null) => {
        const registrationAddress = getCleanedAddress(values?.[`${_group}registration`] as string)
        const factAddress = getCleanedAddress(values?.[`${_group}fact`] as string)
        const indexAddress = getCleanedAddress(values?.[`${_group}index`] as string)

        const registrationAddressPaylaod = {
            [`${getNameForField('registrationAddress')}`]: registrationAddress,
        }
        const factAddressPayload = { [`${getNameForField('factAddress')}`]: factAddress }
        const indexAddressPayload = { [`${getNameForField('indexAddress')}`]: indexAddress }

        setForBothContexts(registrationAddressPaylaod, mergeValuesIntoState, setFieldsValue)
        setForBothContexts(factAddressPayload, mergeValuesIntoState, setFieldsValue)
        setForBothContexts(indexAddressPayload, mergeValuesIntoState, setFieldsValue)
    }

    useEffect(() => {
        setUneditable(values)
        setAddressDetails(values, `${group}`, mergeValuesIntoState, setFieldsValue)
    }, [])

    const getNameForField = (fieldName: string) => {
        if (group) {
            return `${_group}${fieldName}`
        }
        if (typeof fieldKey === 'number') {
            return [fieldKey, fieldName]
        }
        return fieldName
    }

    const setFieldsForForm = (values: Record<string, string | undefined>) => {
        if (typeof fieldKey === 'number' && listName) {
            const valuesArray = getFieldValue(listName)
            Object.assign(valuesArray[fieldKey], values)
            setFieldsValue({ [listName]: valuesArray })
        } else {
            setFieldsValue(values)
            mergeValuesIntoState(values)
            setUneditable(values)
        }
    }

    const getFieldsFromForm = (fieldName: string) => {
        if (listName && typeof fieldKey === 'number') {
            const valuesArray = getFieldValue(listName)
            return valuesArray[fieldKey]?.[fieldName]
        }
        return getFieldValue(fieldName)
    }

    const setMainAddressValue = (value: string) => {
        const addresses = { [`${_group}registration`]: value }

        if (isFactSameAsRegistration) {
            addresses[`${_group}fact`] = value
        }

        if (isFactSameAsRegistration && isFactSame) {
            addresses[`${_group}index`] = value
        }

        setFieldsForForm(addresses)
    }

    const setFactAddressValue = (value: string) => {
        const addresses = { [`${_group}fact`]: value }
        if (!isFactSameAsRegistration && isFactSame) {
            addresses[`${_group}index`] = value
        }

        setFieldsForForm(addresses)
    }

    const setPostAddressValue = (value: string) => {
        setFieldsForForm({ [`${_group}index`]: value })
    }

    const onChangeIsFactSameAsRegistration = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        setIsFactSameAsRegistration(isChecked)
        if (isChecked) {
            const addressValue = getFieldsFromForm(`${_group}registration`)
            const addresses = { [`${_group}fact`]: addressValue }
            if (isFactSame) {
                addresses[`${_group}index`] = addressValue
            }
            setFieldsForForm(addresses)
        }
    }
    const onChangeIsFactSame = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        setIsFactSame(isChecked)
        if (isChecked) {
            const addressValue = getFieldsFromForm(`${_group}fact`)
            setFieldsForForm({ [`${_group}index`]: addressValue })
        }
    }

    const onChangeIsEmptyPostAddress = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked
        setIsEmptyPostAddress(isChecked)
        if (isChecked) {
            setIsFactSame(false)
            setFieldsForForm({ [`${_group}index`]: undefined })
        }
    }

    const factAddressLabel = isLegalAddress ? 'Адрес юридического лица' : 'Адрес проживания'
    const factSameAsRegistrationLabel =
        noRegistrationAddressCheckbox || isFactSameAsRegistration || uneditable
            ? factAddressLabel
            : ''
    const factSameLabel =
        noActualAddressCheckbox || isFactSame || uneditable ? 'Почтовый адрес' : ''

    const commonPropsForTextItem = {
        ...restProps,
        fieldKey,
        listName,
        placeholder: '',
        uneditable,
        disabled: true,
        className: 'formComponentItem--xl',
    }

    const commonPropsForAddressDetails = {
        ...restProps,
        uneditable,
        fieldKey,
        group,
        listName,
        initialValues: values as Record<string, string>,
    }

    const mainAddressFormRowClassName = cn('formRow', {
        hidden: !uneditable,
    })

    const postAddressLabelClassName = cn('form-label', {
        'form-label--required': isPostAddressRequired,
    })

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={mainAddressFormRowClassName}>
                <WrappedInput
                    {...commonPropsForTextItem}
                    label={`${addressTitle}:`}
                    name={getNameForField('registrationAddress')}
                />
            </div>
            {!uneditable && (
                <div className="formRow">
                    <div className="form-label form-label--required">{addressTitle}:</div>
                    <AddressDetails
                        {...commonPropsForAddressDetails}
                        prefix="Registration"
                        setAddressValue={setMainAddressValue}
                        marginLeft={0}
                    />
                </div>
            )}

            {!noRegistrationAddressCheckbox && !uneditable && (
                <div className="formRow">
                    <div className="form-label form-label--required">{factAddressLabel}:</div>
                    <Checkbox
                        defaultChecked={isFactSameAsRegistration}
                        onChange={onChangeIsFactSameAsRegistration}
                    >
                        Совпадает с {isLegalAddress ? 'юридическим' : 'регистрацией'}
                    </Checkbox>
                </div>
            )}

            <div className="formRow">
                <WrappedInput
                    {...commonPropsForTextItem}
                    label={factSameAsRegistrationLabel}
                    name={getNameForField('factAddress')}
                    hidden={!uneditable}
                />
            </div>
            {!uneditable && !isFactSameAsRegistration && (
                <AddressDetails
                    {...commonPropsForAddressDetails}
                    prefix="Fact"
                    setAddressValue={setFactAddressValue}
                />
            )}

            {!noActualAddressCheckbox && !uneditable && (
                <div className="formRow">
                    <div className={postAddressLabelClassName}>Почтовый адрес:</div>
                    <Space direction="vertical" style={{ width: 'auto' }}>
                        {!isPostAddressRequired && (
                            <Checkbox
                                checked={isEmptyPostAddress}
                                onChange={onChangeIsEmptyPostAddress}
                            >
                                Нет адреса
                            </Checkbox>
                        )}
                        <Checkbox
                            checked={isFactSame}
                            onChange={onChangeIsFactSame}
                            disabled={isEmptyPostAddress}
                        >
                            Совпадает с фактическим
                        </Checkbox>
                    </Space>
                </div>
            )}

            <div className="formRow">
                <WrappedInput
                    {...commonPropsForTextItem}
                    label={factSameLabel}
                    name={getNameForField('indexAddress')}
                    hidden={!uneditable}
                />
            </div>
            {!uneditable && !isFactSame && !isEmptyPostAddress && (
                <AddressDetails
                    {...commonPropsForAddressDetails}
                    prefix="Index"
                    setAddressValue={setPostAddressValue}
                />
            )}

            {!noBottomDivider && <CardDivider />}
        </div>
    )
}
