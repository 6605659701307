import {
    EmissionOrderStatus,
    IEmissionOrder,
    IEmissionOrderDeal,
    isEmissionOrder,
} from '@dltru/dfa-models'
import { BodyCheck, BodyTitle, Box, Button, DeleteIcon, Space } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { EmissionOrderStatusBlock } from './EmissionOrderStatusBlock'

interface IComponentProps {
    orderDetails: IEmissionOrder | IEmissionOrderDeal
}

export const EmissionOrderDetailsModalBody: FC<IComponentProps> = ({ orderDetails }) => {
    const checkList = [
        {
            label: 'Инвестор',
            Content: () => (
                <Link to={`/clients/${orderDetails?.user_id}`}>
                    {isEmissionOrder(orderDetails)
                        ? orderDetails?.user_name
                        : orderDetails?.user_full_name}
                </Link>
            ),
        },
        {
            label: 'Цена',
            price: orderDetails?.price_per_dfa || 0,
        },
        {
            label: 'Количество, ед.',
            price: orderDetails?.amount_dfa || 0,
            currency: 'unit',
        },
        {
            label: 'Стоимость приобретаемых активов',
            price:
                orderDetails?.amount_dfa && orderDetails?.price_per_dfa
                    ? orderDetails.amount_dfa * orderDetails.price_per_dfa
                    : 0,
        },
        {
            label: 'Итого',
            price:
                orderDetails?.amount_dfa && orderDetails?.price_per_dfa
                    ? orderDetails.amount_dfa * orderDetails.price_per_dfa
                    : 0,
        },
    ].map((check) => <BodyCheck {...check} key={check.label} />)

    const isVisibleFooter =
        orderDetails?.status === EmissionOrderStatus.in_progress ||
        orderDetails?.status === EmissionOrderStatus.revoked

    return (
        <>
            <BodyTitle title="Детали покупки" id={orderDetails?.id} />
            <div>{checkList}</div>
            <Box padding={[8, 0, 0, 0]}>
                <EmissionOrderStatusBlock
                    created_at={orderDetails?.created_at}
                    executed_at={orderDetails?.executed_at}
                    status={orderDetails?.status}
                />
            </Box>
            {isVisibleFooter && (
                <Box padding={[24, 0, 0, 0]} align="end">
                    <Space size={16}>
                        {orderDetails?.status === EmissionOrderStatus.in_progress && (
                            <Button
                                size="large"
                                color="default"
                                icon={<DeleteIcon />}
                                borderRadius={16}
                            >
                                Отменить заявку
                            </Button>
                        )}
                    </Space>
                </Box>
            )}
        </>
    )
}
