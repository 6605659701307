import { Button } from '@dltru/dfa-ui'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { clientsSelector } from '@store/clients/selectors'

import { ChangeNodeStatusModal } from './ChangeNodeStatusModal/ChangeNodeStatusModal'
import { ModalType } from './types'

export const ChangeNodeStatus = () => {
    const isNode = useSelector(clientsSelector.selectIsNode)
    const denyExchangeOperatorSelector = useSelector(clientsSelector.denyExchangeOperatorSelector)

    const [modalType, setModalType] = useState<ModalType>('set')
    const [isShow, setIsShow] = useState(false)

    const clickHandler = () => {
        if (isNode) {
            setModalType('delete')
        } else {
            setModalType('set')
        }

        setIsShow(true)
    }

    if (denyExchangeOperatorSelector) {
        return null
    }

    return (
        <>
            <Button type="link" onClick={clickHandler}>
                {isNode ? 'Отозвать статус Узла' : 'Присвоить статус Узла'}
            </Button>
            {isShow && (
                <ChangeNodeStatusModal
                    type={modalType}
                    isShow={isShow}
                    onClose={() => {
                        setIsShow(false)
                    }}
                />
            )}
        </>
    )
}
