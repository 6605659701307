import { UsersRegistryStatus } from '@dltru/dfa-models'

export const allowStatuses: string[] = [
    UsersRegistryStatus.is_emitter,
    UsersRegistryStatus.is_owner,
    UsersRegistryStatus.is_exchange_operator,
    //UsersRegistryStatus.is_member,
]

export const weightStatuses = allowStatuses.reduce((acc, item, index) => {
    acc[item] = index
    return acc
}, {} as Record<keyof typeof UsersRegistryStatus, number>)
