import React, { FC } from 'react'
import { Box } from '../../../../Box'
import { Button } from '../../../../button'
import { MiniModal, ModalProps } from '../../../../components/Modal'
import { QuestionCircle } from '../../../../Icons/QuestionCircle'
import { Space } from '../../../../Space'

export const NameWarningModal: FC<ModalProps> = ({ visible, onCancel }) => {
    return (
        <MiniModal
            width={423}
            visible={visible}
            onCancel={onCancel}
            title={
                <Space size={16}>
                    <QuestionCircle className="auth-modal__gray-icon" />
                    Внимание!
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={onCancel}>
                    Закрыть
                </Button>,
            ]}
        >
            <Box margin={[0, 0, 0, 40]}>
                <Space direction="vertical" size={18}>
                    <div>
                        Обращаем ваше внимание, что информация, указанная в полях <br />
                        "Полное наименование организации" и "Организационно-правовая форма", <br />
                        должна быть строго идентична информации, указанной в ключе электронной
                        подписи и ЕГРЮЛ. В случае различия информации, присоединение ключа станет
                        невозможным.
                    </div>
                </Space>
            </Box>
        </MiniModal>
    )
}
