import { Input } from 'antd'
import React, { useCallback } from 'react'

import { FormItemProps } from '../FormItem'
import { WrappedInput, WrappedInputProps } from '../Input'

interface IComponentProps extends WrappedInputProps {
    decimalScale?: number
}

export const CurrencyInput = React.forwardRef<Input>(
    ({ decimalScale = 2, ...props }: IComponentProps, ref) => {
        const renderUneditable = useCallback(
            (value?: number) => <>{value?.toLocaleString() ?? 0}</>,
            [],
        )
        return (
            <WrappedInput
                {...props}
                ref={ref}
                renderUneditable={renderUneditable as FormItemProps['renderUneditable']}
                currencyProps={{
                    decimalScale,
                    allowNegative: false,
                    decimalSeparator: ',',
                    thousandSeparator: ' ',
                }}
            />
        )
    },
)
