import { KeysMatching, RateBaseType, stringToNumber } from '@dltru/dfa-models'
import {
    ColumnsType,
    Form,
    Step,
    StepperContext,
    Table,
    commissionLabel,
    getTariffGridConfig,
    useEditable,
} from '@dltru/dfa-ui'
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo } from 'react'

import { EditableCell } from '../../../components/EditableCell'
import { useBaseRates } from '../../hooks/useBaseRates'

const components = {
    body: {
        cell: EditableCell,
    },
}

const STEP_INDEX = 1

interface IComponentProps {
    baseRates?: RateBaseType[]
    currentRates: string[]
    setCurrentRates: Dispatch<SetStateAction<string[]>>
}
export const TariffGridStep: FC<IComponentProps> = ({
    baseRates,
    currentRates,
    setCurrentRates,
}) => {
    const { editable, currentStep, stepsTotal } = useContext(StepperContext)
    const [form] = Form.useForm()
    const type = form.getFieldValue('type')
    const { initialValues, feeList } = useBaseRates(type, baseRates)

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [initialValues])

    useEffect(() => {
        const availableCodeList = feeList.map(({ fee_code }) => fee_code)
        setCurrentRates((prevState) => prevState.filter((code) => availableCodeList.includes(code)))
    }, [feeList])

    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(STEP_INDEX))

    const onSelectChange = (newSelectedRowKeys: string[]) => {
        setCurrentRates(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys: currentRates,
        onChange: onSelectChange,
    }

    const columns = useMemo(
        () =>
            getTariffGridConfig({ isEditable: true }).map((col: ColumnsType<RateBaseType>) => {
                if (!col.editable || isEdit) {
                    return col
                }
                const handleSave = (record: RateBaseType) => (value?: string) => {
                    const finedFee = feeList.find(
                        ({ fee_code }) => record.fee_code === fee_code,
                    ) as RateBaseType
                    const fieldName = col.dataIndex as KeysMatching<RateBaseType, number>
                    if (finedFee && fieldName in finedFee) {
                        finedFee[fieldName] = stringToNumber(value)
                    }
                }
                return {
                    ...col,
                    onCell: (record: RateBaseType) => ({
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave: handleSave(record),
                    }),
                }
            }),
        [isEdit],
    )

    const uneditableFeeList = feeList.filter(({ fee_code }) => currentRates.includes(fee_code))

    return (
        <Step
            stepIndex={STEP_INDEX}
            form={form}
            stepTitle="Комиссии тарифной сетки №1"
            allowEdit={allowEdit}
            saveAfterEdit
            disabledHeaderStepCounter
            footerCaption={`Выбрано ${currentRates.length}  ${commissionLabel(
                currentRates.length,
            )}`}
            disableNextBtn={!currentRates.length}
        >
            <Table
                components={!isEdit ? components : undefined}
                columns={columns}
                dataSource={isEdit ? uneditableFeeList : feeList}
                rowSelection={!isEdit ? rowSelection : undefined}
                rowKey="fee_code"
                rowClassName={!isEdit ? () => 'editable-row' : undefined}
                uneditable={isEdit}
                noPagination
            />
        </Step>
    )
}
