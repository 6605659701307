import { CollectType, stringToNumber } from '@dltru/dfa-models'

import api from '@services/api'

export const getBalance = async (
    emitter_id: string,
    setBalance: (value: { balance: number; number: number }) => void,
) => {
    const { data } = await api.lib.getMoneyAccounts(emitter_id)
    if (data && data.items && data.items.length) {
        setBalance(data?.items?.[0] as { balance: number; number: number })
    }
}

export const redeemTypeOptions = [
    { value: CollectType.account, label: 'На Лицевые счета Владельцев' },
    { value: CollectType.bank_account, label: 'На Банковские счета Владельцев' },
    { value: CollectType.none, label: 'Не осуществлять расчеты' },
]
export interface IRedeemModalState {
    balance?: { balance: number; number: number }
    redeemType: CollectType
    isBalanceError: boolean
    redeemFlexPrice?: number
    redeemSpread: number
    amount: number
    repaymentSum?: number
    isFee: boolean
    fee?: number
    feePaymentOrder?: number
    repaymentWithCommissionSum: number
    visibleCertificateModal: boolean
}
export enum ActionTypesEnum {
    'setBalance',
    'setType',
    'setRedeemFlexPrice',
    'setFee',
    'setIsFee',
    'setRedeemSpread',
    'setVisibleCertificateModal',
}
type ActionType =
    | { type: ActionTypesEnum.setBalance; balance: IRedeemModalState['balance'] }
    | {
          type: ActionTypesEnum.setType
          redeemType: CollectType
          redeemSpread?: number
          redeemFlexPrice?: number
      }
    | { type: ActionTypesEnum.setRedeemFlexPrice; price: string }
    | { type: ActionTypesEnum.setFee; fee: number; feePaymentOrder: number }
    | { type: ActionTypesEnum.setIsFee; isFee: boolean }
    | { type: ActionTypesEnum.setRedeemSpread; redeemSpread: string }
    | { type: ActionTypesEnum.setVisibleCertificateModal; state: boolean }

const calcSum = (price: number, amount: number, spread = 0) =>
    (price + (price * spread) / 100) * amount * 100

const calcRepaymentWithCommissionSum = (
    redeemType: CollectType,
    repaymentSum = 0,
    isFee?: boolean,
    fee?: number,
) => {
    if (redeemType === CollectType.none) {
        return isFee && fee ? fee : 0
    }
    return isFee ? repaymentSum + (fee ?? 0) : repaymentSum
}

export function redeemModalReducer(
    state: IRedeemModalState,
    action: ActionType,
): IRedeemModalState {
    switch (action.type) {
        case ActionTypesEnum.setBalance: {
            const isBalanceError =
                state.repaymentWithCommissionSum !== undefined &&
                state.repaymentWithCommissionSum > (action.balance?.balance ?? 0)
            return {
                ...state,
                balance: action.balance,
                isBalanceError,
            }
        }
        case ActionTypesEnum.setType: {
            const isBalanceError =
                action.redeemType === CollectType.none && state.fee
                    ? state.fee > (state.balance?.balance ?? 0)
                    : state.isBalanceError
            const repaymentWithCommissionSum = calcRepaymentWithCommissionSum(
                action.redeemType,
                state.repaymentSum,
                state.isFee,
                state.fee,
            )
            return {
                ...state,
                redeemType: action.redeemType,
                isBalanceError,
                repaymentWithCommissionSum,
            }
        }
        case ActionTypesEnum.setRedeemFlexPrice: {
            const redeemFlexPrice = stringToNumber(action.price)
            const repaymentSum = calcSum(redeemFlexPrice, state.amount, state.redeemSpread)
            const repaymentWithCommissionSum = calcRepaymentWithCommissionSum(
                state.redeemType,
                repaymentSum,
                state.isFee,
                state.fee,
            )
            const isBalanceError = repaymentWithCommissionSum > (state.balance?.balance ?? 0)
            return {
                ...state,
                redeemFlexPrice,
                repaymentSum,
                repaymentWithCommissionSum,
                isBalanceError,
            }
        }
        case ActionTypesEnum.setFee: {
            const repaymentWithCommissionSum = calcRepaymentWithCommissionSum(
                state.redeemType,
                state.repaymentSum,
                state.isFee,
                action.fee,
            )
            const isBalanceError = repaymentWithCommissionSum > (state.balance?.balance ?? 0)
            return {
                ...state,
                repaymentWithCommissionSum,
                fee: action.fee,
                feePaymentOrder: action.feePaymentOrder,
                isBalanceError,
            }
        }
        case ActionTypesEnum.setRedeemSpread: {
            const redeemSpread = stringToNumber(action.redeemSpread)
            const redeemFlexPrice = state.redeemFlexPrice ?? 0
            const repaymentSum = calcSum(redeemFlexPrice, state.amount, redeemSpread)
            const repaymentWithCommissionSum = calcRepaymentWithCommissionSum(
                state.redeemType,
                repaymentSum,
                state.isFee,
                state.fee,
            )
            const isBalanceError = repaymentWithCommissionSum > (state.balance?.balance ?? 0)
            return {
                ...state,
                redeemSpread,
                repaymentSum,
                repaymentWithCommissionSum,
                isBalanceError,
            }
        }
        case ActionTypesEnum.setIsFee: {
            const repaymentWithCommissionSum = calcRepaymentWithCommissionSum(
                state.redeemType,
                state.repaymentSum,
                action.isFee,
                state.fee,
            )
            const isBalanceError = repaymentWithCommissionSum > (state.balance?.balance ?? 0)
            return {
                ...state,
                isFee: action.isFee,
                fee: action.isFee ? state.fee : undefined,
                repaymentWithCommissionSum,
                isBalanceError,
            }
        }
        case ActionTypesEnum.setVisibleCertificateModal: {
            return {
                ...state,
                visibleCertificateModal: action.state,
            }
        }
        default: {
            return state
        }
    }
}
