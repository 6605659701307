import { FC, useState } from 'react'

import { Box } from '../Box'
import { CardContent } from '../card'
import { PanelTitle } from './PanelTitle'

export interface IOpeningPanelProps {
    title: string
}

export const OpeningPanel: FC<IOpeningPanelProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box className="boxContent" padding={26} margin={[8, 0]}>
            <PanelTitle
                title={title}
                onOpen={() => {
                    setIsOpen(true)
                }}
                onClose={() => {
                    setIsOpen(false)
                }}
            />
            {isOpen && <CardContent>{children}</CardContent>}
        </Box>
    )
}
