import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { walletHistorySlice } from './index'

function* loadWalletHistory({
    payload,
}: ReturnType<typeof walletHistorySlice.actions.loadWalletHistory>) {
    yield* put(walletHistorySlice.actions.setLoading())

    const { data, error } = yield api.lib.getUserDeals(payload)

    if (error) {
        yield* put(walletHistorySlice.actions.setError(error))
    } else if (!data) {
        yield* put(walletHistorySlice.actions.setError('Empty response'))
    } else {
        yield* put(walletHistorySlice.actions.setItems(data || []))
    }
}

export function* watchWalletHistory(): Generator<StrictEffect> {
    yield* takeLatest(walletHistorySlice.actions.loadWalletHistory.type, loadWalletHistory)
}
