import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const FileTextIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.6914 5.63672L12.4883 1.43359C12.3711 1.31641 12.2129 1.25 12.0469 1.25H3.75C3.4043 1.25 3.125 1.5293 3.125 1.875V18.125C3.125 18.4707 3.4043 18.75 3.75 18.75H16.25C16.5957 18.75 16.875 18.4707 16.875 18.125V6.08008C16.875 5.91406 16.8086 5.75391 16.6914 5.63672ZM15.4336 6.36719H11.7578V2.69141L15.4336 6.36719ZM15.4688 17.3438H4.53125V2.65625H10.4297V6.875C10.4297 7.09256 10.5161 7.30121 10.67 7.45505C10.8238 7.60889 11.0324 7.69531 11.25 7.69531H15.4688V17.3438ZM9.84375 12.0703H6.25C6.16406 12.0703 6.09375 12.1406 6.09375 12.2266V13.1641C6.09375 13.25 6.16406 13.3203 6.25 13.3203H9.84375C9.92969 13.3203 10 13.25 10 13.1641V12.2266C10 12.1406 9.92969 12.0703 9.84375 12.0703ZM6.09375 9.57031V10.5078C6.09375 10.5938 6.16406 10.6641 6.25 10.6641H13.75C13.8359 10.6641 13.9062 10.5938 13.9062 10.5078V9.57031C13.9062 9.48437 13.8359 9.41406 13.75 9.41406H6.25C6.16406 9.41406 6.09375 9.48437 6.09375 9.57031Z"
                    fill="currentColor"
                />
            </svg>
        )}
        {...props}
    />
)
